import { returnApiCallPromise } from './ApiContent';
import { markSheet } from './APIContainer';
//GET
export function getMarkSheet(request) {
  const requestObj = request ? { params: request } : "";
  return (dispatch) => {
    returnApiCallPromise("GET",markSheet, requestObj)
      .then(response => {
        dispatch({
          type: 'GET_MARK_SHEET_SUCCESS',
          payload: response.data
        })
      }).catch(error => {

        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'GET_MARK_SHEET_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

//POST
export function createMarkSheet(request) {

  return (dispatch) => {
    returnApiCallPromise("POST", markSheet, request)
      .then(response => {
        if (response.data.error) {
          dispatch({
            type: 'CREATE_MARK_SHEET_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'CREATE_MARK_SHEET_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'CREATE_MARK_SHEET_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

export function updateMarkSheet(request, markSheetId) {
  return (dispatch) => {
    returnApiCallPromise("PUT", markSheet, request)
      .then(response => {

        if (response.data.error) {
          dispatch({
            type: 'UPDATE_MARK_SHEET_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'UPDATE_MARK_SHEET_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'UPDATE_MARK_SHEET_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}