import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSidebar } from '../sidebar/sidebarContext';
import adminImage from "app/pages/images/no_profile.png";
import noProfileImage from "app/pages/images/no_profile.png";
import { employeePath, imagePath, unitPath } from "app/utils/constant";
import { useDispatch, useSelector, } from "react-redux";
import { getUnit } from 'app/api/UnitApi';
import { lightTheme } from 'styles/theme';


const LogoWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    transition: left 0.5s ease;
    right:0px;
    width: 262px;
    height:70px;
    display: flex;
    align-items: center;
`;

const LogoText = styled.h6`
  margin: 0;
  padding-left: 10px;
  flex-grow: 1;
  color: ${props => props.theme.primaryColor};
  font-weight: bold;
`;

const LogoImage = styled.img`
  height: 60px; /* Keeps the aspect ratio */
  width: 60px; /* Sets the width */
  margin-left:10px;



`;


const Logo = ({ navigation }) => {
    const dispatch = useDispatch();

    const [token, setToken] = useState();
    const [image, setImage] = useState();
    const [isDropDown, setIsDropDown] = useState(false);
    const [selectedUnit, setSelectedUnit] = useState();

    const getUnitSuccess = useSelector((state) => state.unitReducer.getUnitSuccess);
    const getUnitList = useSelector((state) => state.unitReducer.getUnitList);
    const getUnitFailure = useSelector((state) => state.unitReducer.getUnitFailure);

    const [state, setState] = useState({
        shorcutMenuList: [
            {
                icon: "i-Shop-4",
                link: "#",
                text: "Home",
            },
            {
                icon: "i-Library",
                link: "#",
                text: "Ui Kits",
            },
            {
                icon: "i-Drop",
                link: "#",
                text: "Apps",
            },
            {
                icon: "i-File-Clipboard-File--Text",
                link: "#",
                text: "Form",
            },
            {
                icon: "i-Checked-User",
                link: "#",
                text: "Sessions",
            },
            {
                icon: "i-Ambulance",
                link: "#",
                text: "Support",
            },
        ],
        notificationList: [
            {
                icon: "i-Speach-Bubble-6",
                title: "New message",
                description: "James: Hey! are you busy?",
                time: "2019-10-30T02:10:18.931Z",
                color: "primary",
                status: "New",
            },
            {
                icon: "i-Receipt-3",
                title: "New order received",
                description: "1 Headphone, 3 iPhone",
                time: "2019-03-10T02:10:18.931Z",
                color: "success",
                status: "New",
            },
            {
                icon: "i-Empty-Box",
                title: "Product out of stock",
                description: "1 Headphone, 3 iPhone",
                time: "2019-05-10T02:10:18.931Z",
                color: "danger",
                status: "3",
            },
            {
                icon: "i-Data-Power",
                title: "Server up!",
                description: "Server rebooted successfully",
                time: "2019-03-10T02:10:18.931Z",
                color: "success",
                status: "3",
            },
        ],
        showSearchBox: false,
        unitList: [],
        tempData: false
    });

    useEffect(() => {
        const req = { "treeView": true }
        dispatch(getUnit(req))
    }, [navigation])

    let tempState = ''

    useEffect(() => {
        if (getUnitSuccess) {
            setState({
                ...state,
                unitList: getUnitList
            })
            tempState = getUnitList
            if (tempState.length) {
                let unit_id = ''
                let unitCode = ''
                let unitToken = ''
                let logo = ''
                let arr = tempState
                let check = true
                arr = arr.filter(ele => ele.unitId == global.unitId)
                if (arr.length == 0) {
                    tempState.map(parentUnit => {
                        if (parentUnit?.branches) {
                            setIsDropDown(true)
                        }
                        let branchUnit = (parentUnit?.branches || []).filter(branch => branch.unitId == global.unitId);
                        if (branchUnit.length) {
                            let unitSelect = branchUnit;
                            check = false
                            logo = unitSelect[0]?.image || ''
                            setSelectedUnit(unitSelect[0]?.unitName || '');
                            unit_id = unitSelect[0]?.unitId || ''
                            unitToken = unitSelect[0]?.unitToken || ''
                            setToken(unitSelect[0]?.unitToken || '')
                            return;
                        }
                        else if (check) {
                            logo = tempState[0]?.image || ''
                            unit_id = tempState[0]?.unitId || ''
                            unitCode = tempState[0]?.unitCode || ''
                            unitToken = tempState[0]?.unitToken || ''
                            setToken(unitCode[0]?.unitToken || '')
                            setSelectedUnit(tempState[0]?.unitName || '');
                        }
                    });
                }
                else if (arr.length != 0) {
                    tempState.map(parentUnit => {
                        if (parentUnit?.branches) {
                            setIsDropDown(true)
                        }
                    })
                    unit_id = arr[0]?.unitId || ''
                    unitCode = arr[0]?.unitCode || ''
                    unitToken = arr[0]?.unitToken || ''
                    setToken(arr[0]?.unitToken || '')
                    logo = arr[0]?.image || ''
                    setSelectedUnit(arr[0]?.unitName || '');
                }
                else {
                    logo = tempState[0]?.image || ''
                    setSelectedUnit(tempState[0]?.unitName || '');
                }
                setImage(logo)
                handleUnitId(unit_id, unitCode, unitToken)

            }

            dispatch({ type: "RESET_GET_UNIT" })
        } else if (getUnitFailure) {
            setState({
                ...state,
                unitList: []
            })
            dispatch({ type: "RESET_GET_UNIT" })
        }
    }, [getUnitSuccess, getUnitFailure]);


    let userInfo = window.localStorage.getItem("userInfo");
    let parsedData = JSON.parse(userInfo);

    const handleUnitId = (unitId, unitCode, unitToken) => {
        parsedData.unitId = unitId
        parsedData.unitCode = unitCode
        parsedData.unitToken = unitToken

        localStorage.setItem('unitId', JSON.stringify(unitId));
        localStorage.setItem('unitCode', JSON.stringify(unitCode));
        localStorage.setItem('unitToken', JSON.stringify(unitToken));
    };

    let profile = ''
    let logo = ''
    let schoolName=''
    if (userInfo?.[0]?.roleId == 1) {
        profile = adminImage
        const imageUrl = process.env.PUBLIC_URL + '/assets/images/Media.png'
        logo = imageUrl
    }
    else {
        // logo = ` ${imagePath}${unitPath}${token}/${image ? encodeURI(image) : ''}`
        logo = `${imagePath}${unitPath}${token}/unitinfo/${encodeURI(image)}`
        profile = userInfo?.[0]?.profile && userInfo?.[0]?.employeeToken ? `${imagePath}${employeePath}${userInfo?.[0]?.employeeToken}/${encodeURI(userInfo?.[0]?.profile || '')}` : noProfileImage
    }

    return (
        <LogoWrapper>
          
                <LogoImage src={logo} alt="Logo" />
                <LogoText>{selectedUnit}</LogoText>
            
        </LogoWrapper>
    );
};

export default Logo;
