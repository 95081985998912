import React, { useState } from "react";
import styled from "styled-components";
import { useSidebar } from "../../sidebar/sidebarContext";

// Styled Components for the Tab Switcher with Modern Design
const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: ${({ sidebarOpen }) => (sidebarOpen ? '0px' : '20px')};
  transition: margin-left 0.3s ease;

  max-width: 80rem; /* Mobile-friendly width */
  border-radius: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
`;

const TabHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around; /* Evenly spaced tabs */
  width:20%;
  margin: 0 auto;
`;

const Tab = styled.div`
  flex: 1;
  padding: 14px 0;
  cursor: pointer;
  font-size: 16px;
  color: ${(props) => (props.active ? "#007aff" : "#8e8e93")};
  text-align: center;
  position: relative;
  transition: color 0.3s ease;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 15%;
    right: 15%;
    height: 3px;
    background: ${(props) => (props.active ? "#007aff" : "transparent")};
    border-radius: 2px;
    transition: all 0.3s ease; /* Smooth indicator transition */
  }
`;

const TabContentWrapper = styled.div`
  position: relative;
  overflow: hidden;
  background: #ffffff;
`;

const TabContent = styled.div`
  display: flex;
  transition: transform 0.4s ease-in-out;
  transform: ${(props) => `translateX(-${props.activeIndex * 100}%)`};
`;

const TabItem = styled.div`
  flex-shrink: 0;
  width: 100%;
  padding: 20px;
  line-height: 1.6;
  background: #ffffff;
  color: #333333;
`;

const TabSwitcher = ({ tabs, handleTab }) => {
    const { sidebarOpen, toggleSidebar } = useSidebar();
  
  const [selectedTab, setSelectedTab] = useState(tabs[0].id);

  const handleTabClick = (id) => {
    setSelectedTab(id);
    if (handleTab) {
      handleTab(id);
    }
  };

  const activeIndex = tabs.findIndex((tab) => tab.id === selectedTab);

  return (
    <TabContainer sidebarOpen={sidebarOpen}>
      <TabHeader>
        {tabs.map((tab) => (
          <Tab
            key={tab.id}
            active={selectedTab === tab.id}
            onClick={() => handleTabClick(tab.id)}
          >
            {tab.title}
          </Tab>
        ))}
      </TabHeader>
      <TabContentWrapper>
        <TabContent activeIndex={activeIndex}>
          {tabs.map((tab) => (
            <TabItem key={tab.id}>{tab.content}</TabItem>
          ))}
        </TabContent>
      </TabContentWrapper>
    </TabContainer>
  );
};

export default TabSwitcher;
