import moment from "moment";
import { jsPDF } from "jspdf";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import 'jspdf-autotable'
import { NotificationContainer, NotificationManager, } from "react-notifications";
import { currencySymbol } from "./constant";
import dayjs from 'dayjs';

const showMessage = (type, msg, title = null) => {
  switch (type) {
    case 'info':
      NotificationManager.info(msg, title);
      break;
    case 'success':
      NotificationManager.success(msg, title);
      break;
    case 'warning':
      NotificationManager.warning(msg, title);
      break;
    case 'error':
      NotificationManager.error(msg, title);
      break;
  }
}

function validate(checkData) {
  let isEmpty = false;
  if (Array.isArray(checkData)) {
    (checkData || []).forEach(ele => {
      for (let key in ele) {
        if (ele[key] === undefined || ele[key] === null || ele[key] === '') {
          isEmpty = true;
        }
      }
    });
  }
  return isEmpty;
}


const todayDate = (format = "DD-MM-YYYY") => {
  return moment().format(format);

}
const dateConversion = (date, format = "DD-MM-YYYY") => {
  return date ? moment(new Date(date)).format(format) : "";

}

const decimalAmount = (data) => {
  return data ? `${parseFloat(data).toFixed(2)}` : '0.00';
}


/* const timeConversion = (time, format = "HH:mm:ss") => {
  return time ? moment(time, "HH:mm:ss").format(format) : "";
} */
const timeConversion = (time, format = "HH:mm") => {
  return time ? dayjs(time).format(format) : "";
};


const export2pdf = (headers, data, title, name) => {
  //sample format for PDF
  /*  const headers = [['ID','Name','Age']];
      const data = [
          [1, 'John Doe', 30],
          [2, 'Jane Smith', 25],
          [3, 'Bob Johnson', 40]
      ];
      const title = 'Sample PDF Export';
      const name = 'sample'; */

  const unit = "pt";
  const size = "A4"; // Use A1, A2, A3 or A4
  const orientation = "portrait";
  const marginLeft = 20;
  const doc = new jsPDF(orientation, unit, size);
  doc.setFontSize(15);
  let content = {
    startY: 50,
    head: headers,
    body: data
  };

  doc.text(title, marginLeft, 20);
  doc.autoTable(content);
  doc.save(`${name}.pdf`)
}

const removeNullKeysFromObj = (obj) => {
  let result = Object.fromEntries(
    Object.entries(obj).map(([key, value]) => {
      if (Array.isArray(value)) {
        return [key, value.filter(v => {
          return Object.keys(v).filter((o) => {
            return v[o] === null && delete v[o]
          })
        })];
      } else {
        return [key, value];
      }
    }).filter(([_, v]) => v !== null)
  );
  return result
}

const isBeforeDate = (date, date1) => {
  return moment(date).isBefore(date1);
}

const uploadFileType = () => {
  const req = [
    {
      "fileTypeId": 1,
      "fileTypeName": "Experience Letter"
    },
    {
      "fileTypeId": 2,
      "fileTypeName": "Offer Letter"
    },
    {
      "fileTypeId": 3,
      "fileTypeName": "Reliving Letter"
    },
    {
      "fileTypeId": 4,
      "fileTypeName": "Certificate"
    },
  ]
  return req;
}

const amountFormat = (data) => {
  // return data ? `${currencySymbol} ${parseFloat(data).toFixed(2)}` : '-';
  return data ? `${currencySymbol}${parseFloat(data).toFixed(2)}` : `${currencySymbol}0.00`;
}

const convertTo12HourFormat = (time) => {
  const [hours, minutes] = time.split(':'); // Split time into hours and minutes
  const hoursInt = parseInt(hours, 10);
  const isPM = hoursInt >= 12;
  const hours12 = hoursInt % 12 || 12; // Convert hour to 12-hour format
  const ampm = isPM ? 'PM' : 'AM';
  return `${hours12}:${minutes}`;
}

const experienceCalculator = (date) => {
  var b = moment([dateConversion(date, "YYYY, MM, DD")]);
  var a = moment([dateConversion(new Date(), "YYYY, MM, DD")]);

  var years = a.diff(b, 'year');
  b.add(years, 'years');

  var months = a.diff(b, 'months');
  b.add(months, 'months');

  var days = a.diff(b, 'days');

  const experienceYears = years + ' Yrs '
  return experienceYears;
}

const firstLetterCaptial = (string) => {
  const str = string.toLowerCase();
  return str.replace(/(^\w|\s\w)/g, m => m.toUpperCase());
}

const formatStr = (string) => {
  const str = string.toLowerCase();
  return str.replace(/(\s\w)/g, m => m.toUpperCase()).replace(/\s+/g, '');
}

const removeSpaceBtwStr = (str) => {
  const result = str.replace(/ +/g, "");
  return result;
}

const subtractvalues = (val1, val2) => {
  return val1 && val2 ? parseInt(val1) - parseInt(val2) : "0"
}

const additionvalues = (val1, val2) => {
  return parseInt(val1 ? val1 : 0) + parseInt(val2 ? val2 : 0)
}

const exportToExcel = (apiData, fileName) => {
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const ws = XLSX.utils.json_to_sheet(apiData);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};


const pagePermission = (data, parentName, pageName, subParentName) => {
  const userInfo = window.localStorage.getItem("userInfo")
  let parsedData = JSON.parse(userInfo)
  const roleId = parsedData?.[0]?.roleId || 0
  // alert(roleId)
  let returnAccess = {};
  (parsedData?.[0]?.pages || []).forEach(ele => {
    if (ele.name === parentName && ele.children && ele.children.length) {
      ele.children.forEach(o => {

        if (o.name === subParentName && o.children && o.children.length) {

          o.children.map(itm => {
            if (itm.name === pageName && itm.children && itm.children.length) {
              itm.children.map(acc => {
                returnAccess[acc.accessName] = (roleId == 1) ? true : acc.accessGiven
              })
            }
            else {
              returnAccess[itm.accessName] = (roleId == 1) ? true : itm.accessGiven
            }
          })
          /* data.viewOption = o.children?.[0]?.accessGiven || false
          data.addOption = o.children?.[1]?.accessGiven || false
          data.updateOption = o.children?.[2]?.accessGiven || false
          data.deleteOption = o.children?.[3]?.accessGiven || false */
        }
        else {
          if (o.name === pageName && o.children && o.children.length) {
            o.children.map(acc => {
              returnAccess[acc.accessName] = (roleId == 1) ? true : acc.accessGiven
            })
          }
        }
      });
    }
    else {
      if (ele.name === pageName && ele.children && ele.children.length) {
        ele.children.map(itm => {
          returnAccess[itm.accessName] = (roleId == 1) ? true : itm.accessGiven
        })
        /* data.viewOption = ele.children?.[0]?.accessGiven || false
        data.addOption = ele.children?.[1]?.accessGiven || false
        data.updateOption = ele.children?.[2]?.accessGiven || false
        data.deleteOption = ele.children?.[3]?.accessGiven || false */
      }
    }
  });
  return returnAccess
}

/* function validateEmail(email) {
  var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return re.test(email);
} */

function validatePhoneNo(phone) {
  var phoneNum = phone.replace(/[^\d]/g, '');
  if (phoneNum.length == 10) {
    return true
  }
  else return false
}


const formatTime = (dateTime) => new Date(dateTime).toISOString().split('T')[1].split('.')[0];

function validateEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!email) return 'Email is required';
  if (!emailRegex.test(email)) return 'Invalid email format';
  return null;
}

function validatePassword(password) {
  if (!password) return 'Password is required';
/*   if (password.length < 8) return 'Password must be at least 8 characters';
  if (!/[A-Z]/.test(password)) return 'Password must contain at least one uppercase letter';
  if (!/[a-z]/.test(password)) return 'Password must contain at least one lowercase letter';
  if (!/[0-9]/.test(password)) return 'Password must contain at least one number';
 */  return null;
}

function validateName(name) {
  if (!name) return 'Name is required';
  if (name.length < 2) return 'Name must be at least 2 characters';
  if (!/^[a-zA-Z\s]*$/.test(name)) return 'Name can only contain letters and spaces';
  return null;
}
export {
  validatePassword,
  validateName,
  showMessage,
  // validateEmail,
  validateEmail,
  subtractvalues,
  validatePhoneNo,
  exportToExcel,
  additionvalues,
  formatStr,
  todayDate,
  formatTime,
  firstLetterCaptial,
  isBeforeDate,
  uploadFileType,
  removeNullKeysFromObj,
  removeSpaceBtwStr,
  pagePermission,
  dateConversion,
  experienceCalculator,
  amountFormat,
  timeConversion,
  export2pdf,
  decimalAmount,
  validate,
  convertTo12HourFormat,
};