import React, { useEffect, useState } from 'react';
import './index.css';  // Make sure to import the CSS where Tailwind is set up

export function AuthCard({ children, isVisible }) {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <div
      className={`bg-white/90 backdrop-blur-md p-8 rounded-2xl  w-full max-w-md
        transition-all duration-500 transform relative
        ${mounted ? 'translate-y-0 opacity-100' : 'translate-y-4 opacity-0'}`}
    >
      <div className="absolute inset-0 bg-gradient-to-br from-white/50 to-white/30 rounded-2xl" />
      <div className="relative z-10">{children}</div>
      
      {/* Decorative elements */}
      <div className="absolute -top-10 -left-10 w-20 h-20 bg-blue-500/10 rounded-full animate-float" />
      <div className="absolute -bottom-8 -right-8 w-16 h-16 bg-purple-500/10 rounded-full animate-float" style={{ animationDelay: '-1.5s' }} />
    </div>
  );
}
