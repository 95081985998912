import * as yup from "yup";
import { Formik } from "formik";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import DateTime from "react-datetime";
import { Form, Button } from "react-bootstrap";
import { DateRange } from "react-date-range";
import SimpleCard from 'app/components/atoms/simple-card/SimpleCard'
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { MdEdit, MdDelete, MdAlarmOff, MdAlarmAdd } from "react-icons/md";
import swal from "sweetalert2";
import _ from "lodash";
import { pagePermission, showMessage } from "app/utils/app-functions";
import FormLayout from "app/components/render/formlayout";
import View from "app/components/atoms/modalView/Modalview";
import CustomTable from "app/components/atoms/custom-table/CustomTable";
import formRender from "app/components/render/formcomponent";
import { shiftForm } from "./formData";
import { createShift, getShift, updateShift } from "app/api/EmployeeShiftApi";
import moment from "moment";
import dayjs from 'dayjs';
import { formatTime } from "app/utils/app-functions";
import Alert from "app/components/atoms/alert";
import { FiEdit } from "react-icons/fi";
import { timeConversion } from "app/utils/app-functions";
import { useSidebar } from "app/components/sidebar/sidebarContext";

let editData = false;
let removeData = false;

const Shift = ({ navigation }) => {

    const dispatch = useDispatch();
    const customerFormRef = useRef();

    const getShiftSuccess = useSelector((state) => state.shiftReducer.getShiftSuccess);
    const getShiftList = useSelector((state) => state.shiftReducer.getShiftList);
    const getShiftFailure = useSelector((state) => state.shiftReducer.getShiftFailure);

    const createShiftSuccess = useSelector((state) => state.shiftReducer.createShiftSuccess);
    const createShiftData = useSelector((state) => state.shiftReducer.createShiftData);
    const createShiftFailure = useSelector((state) => state.shiftReducer.createShiftFailure);

    const updateShiftSuccess = useSelector((state) => state.shiftReducer.updateShiftSuccess);
    const updateShiftData = useSelector((state) => state.shiftReducer.updateShiftData);
    const updateShiftFailure = useSelector((state) => state.shiftReducer.updateShiftFailure);

    const shiftErrorMessage = useSelector((state) => state.shiftReducer.errorMessage);

    const [pageItems, setpageItems] = useState({});

    useEffect(() => {
        let data = pagePermission(pageItems, "Masters", "Shift")
        setpageItems(data)

    }, [navigation]);

    const column = [
        {
            "dataField": "sNo",
            "text": "S.No",
            "sort": true,
            formatter: (cellContent, row, index) => {
                console.log({ index });
                return parseInt(index + 1);
            }

        },
        {
            "dataField": "shiftName",
            "text": "Shift Name",
            "sort": true
        },
        {
            "dataField": "shiftStartTime",
            "text": "Start Time ",
            "sort": false
        },
        {
            "dataField": "shiftEndTime",
            "text": "End Time ",
            "sort": false

        },
        {
            dataField: "isActive",
            text: "Status",
            sort: true,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => {
                const conInfo = { inputType: "status", status: cellContent }
                return formRender(null, null, conInfo, null);
            }
        },
        {
            text: "Action",
            sort: false,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => (
                <div className="icon-container">

                    {pageItems.update && <FiEdit
                        className="text-primary cursor-pointer"
                        size={18}
                        onClick={() => onEditForm(row, index)}
                    ></FiEdit>}
                    {/*  <MdDelete
                        className="header-danger cursor-pointer"
                        size={18}
                        onClick={() => {
                            swal
                                .fire({
                                    title: "Are you sure?",
                                    header: "You won't be able to revert this!",
                                    icon: "warning",
                                    type: "question",
                                    showCancelButton: true,
                                    confirmButtonColor: "#3085d6",
                                    cancelButtonColor: "#d33",
                                    confirmButtonText: "Yes, delete it!",
                                    cancelButtonText: "No",
                                })
                                .then((result) => {
                                    if (result.value) {
                                        onDeleteItem(row, index);
                                    } else {
                                        swal.fire({
                                            title: "Cancelled!",
                                            header: "Permission denied.",
                                            type: "error",
                                            icon: "error",
                                            timer: 1500,
                                        });
                                    }
                                });
                        }}
                    ></MdDelete> */}
                    {pageItems.delete && row.isActive ?
                        <MdAlarmOff
                            className="header-danger cursor-pointer"
                            size={18}
                            onClick={() => { actionButtonClick(row, index) }}
                        />
                        :
                        <MdAlarmAdd
                            className="header-success cursor-pointer"
                            size={18}
                            onClick={() => { actionButtonClick(row, index) }}
                        />
                    }
                </div>
            )
        },
    ]

    const actionButtonClick = (row, index) => {
        removeData = row.isActive
        const sts = row.isActive
        setViewModule(sts)
        swal
            .fire({
                title: "Are you sure?",
                header: `You want to ${sts ? "Remove" : "Add"} Shift`,
                icon: "warning",
                type: "question",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: `Yes, ${sts ? "Remove" : "Add"} Shift`,
                cancelButtonText: "No",
            })
            .then((result) => {
                if (result.value) {
                    onDeleteItem(row, index);
                } else {
                    swal.fire({
                        title: "Cancelled!",
                        header: "Permission denied.",
                        type: "error",
                        icon: "error",
                        timer: 1500,
                    });
                }
            });
    }
    const [state, setState] = useState({
        shiftName: "",
        shiftStartTime: "12:30",
        shiftEndTime: "",
    });

    const [createModule, setCreateModule] = useState(false);
    const [loaderModule, setLoaderModule] = useState(false);
    const [editModule, setEditModule] = useState(false);
    const [viewModule, setViewModule] = useState(false);
    const [deleteModule, setDeleteModule] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [selectedItem, setSelectedItem] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);

    useEffect(() => {
        setLoaderModule(true)
        dispatch(getShift())
    }, [navigation]);

    useEffect(() => {
        if (getShiftSuccess) {
            setItemList(getShiftList)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_SHIFT" })
        } else if (getShiftFailure) {
            setItemList([])
            showMessage("warning", shiftErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_SHIFT" })
        }
    }, [getShiftSuccess, getShiftFailure]);

    useEffect(() => {
        if (createShiftSuccess) {
            const temp_state = [...itemList, createShiftData[0]];
            setItemList(temp_state)
            setLoaderModule(false)
            closeModule()
            Alert({ description: 'Created Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            dispatch({ type: "RESET_CREATE_SHIFT" })
        } else if (createShiftFailure) {
            setLoaderModule(false)
            showMessage("warning", shiftErrorMessage?.message)
            dispatch({ type: "RESET_CREATE_SHIFT" })
        }
    }, [createShiftSuccess, createShiftFailure]);


    useEffect(() => {
        if (updateShiftSuccess) {
            updateTable(updateShiftData[0])
            dispatch({ type: "RESET_UPDATE_SHIFT" })
        } else if (updateShiftFailure) {
            setLoaderModule(false)
            showMessage("warning", shiftErrorMessage?.message)
            dispatch({ type: "RESET_UPDATE_SHIFT" })
        }
    }, [updateShiftSuccess, updateShiftFailure]);

    const updateTable = (updatedItem) => {
        const temp_state = [...itemList];
        if (deleteModule) {
            let remainingList = _.remove(temp_state, function (n) {
                return n.shiftId != updatedItem.shiftId;
            });
            Alert({ description: 'Deleted Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(remainingList)
        } else {
            temp_state[selectedIndex] = updatedItem;
            Alert({ description: 'Updated Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(temp_state)
        }
        setLoaderModule(false)
        closeModule()
    }

    const closeModule = () => {
        editData = false;
        setCreateModule(false)
        setDeleteModule(false)
    }

    const resetState = () => {
        const format = 'HH:mm';

        setState({
            ...state,
            shiftName: "",
            shiftStartTime: dayjs('9:00', format),
            shiftEndTime: dayjs('16:00', format)
        })
    }

    const onCreateForm = () => {
        resetState()
        editData = false;
        setCreateModule(true)
    }

    const onEditForm = (data, index) => {
        setState({
            ...state,
            shiftName: data?.shiftName || "",
            shiftStartTime: data?.shiftStartTime || "",
            shiftEndTime: data?.shiftEndTime || "",
        })
        editData = true;
        setSelectedIndex(index)
        setSelectedItem(data)
        setCreateModule(true)
    }
    const onDeleteItem = (data, index) => {
        editData = false;
        setSelectedIndex(index)
        setSelectedItem(data)
        setDeleteModule(true)
    }

    const validateForm = async () => {
        const validatefom = customerFormRef.current.validateForm();
    }

    const onSubmitForm = () => {
        const requestDate = {
            shiftName: shiftName,
            shiftStartTime: shiftStartTime,
            shiftEndTime: shiftEndTime
        };
        const fromTimeDate = new Date(`1970-01-01T${shiftStartTime}:00`);
        const toTimeDate = new Date(`1970-01-01T${shiftEndTime}:00`);

        if (shiftStartTime === shiftEndTime) {
            Alert({ description: 'StartTime and EndTime must not be the same', type: 'warning', placement: 'topRight', duration: 3, });
            return false;
        }
        else if (toTimeDate < fromTimeDate) {
            Alert({ description: 'EndTime cannot be earlier than StartTime..', type: 'warning', placement: 'topRight', duration: 3 });
            return false;
        }

        if (editData) {
            setLoaderModule(true)
            dispatch(updateShift(requestDate, selectedItem.shiftId))
        } else if (deleteModule) {
            setDeleteModule(false)
            if (selectedItem.isActive == true) {
                const deleteRequest = {
                    isActive: "false"
                }
                dispatch(updateShift(deleteRequest, selectedItem.shiftId))
            } else {
                const deleteRequest = {
                    isActive: "true"
                }
                dispatch(updateShift(deleteRequest, selectedItem.shiftId))
            }
        } else {
            setLoaderModule(true)
            dispatch(createShift(requestDate))
        }
    }


    const {
        shiftName,
        shiftStartTime,
        shiftEndTime,
    } = state;

    const handleAddButtonClick = () => {
        setCreateModule(true);
    };

    const handleCloseModal = () => {
        setCreateModule(false);
    };

    const modelTitle = editData ? "Edit " : "Create ";
    const modelButtonLbl = editData ? "" : "Save";

    const handleClick = (buttonName) => {
        if (buttonName == 'Add') {
            onCreateForm();
        }
    };
    const { sidebarOpen } = useSidebar();



    return (
        <div>
        <div style={sidebarOpen ? { marginLeft: "10px", marginTop: "-10px" } : { marginLeft: "10px", marginTop: "-50px" }}>
                <View isOpen={createModule} size="sm" savetitle={modelButtonLbl} onClose={handleCloseModal} onSubmit={validateForm} title={`${modelTitle} Shift`}>
                    <FormLayout ref={customerFormRef} defaultState={state} setDefaultState={setState} dynamicForm={shiftForm} noOfColumn={1} onSubmit={onSubmitForm} />
                </View>


                <View centered={true} size={'sm'} isOpen={deleteModule} onClose={setDeleteModule} savetitle="Yes" onSubmit={onSubmitForm} title="Delete Batch">
                    <h6 className="header-capitalize mb-1">Are you sure ?</h6>
                </View>

                <SimpleCard >
                    <CustomTable buttonData={pageItems.create ? ['add'] : []} handleClick={handleClick} tableName={"Shift"} columns={column} data={itemList} />
                </SimpleCard>

            </div>
        </div>
    )
};

export default Shift;
