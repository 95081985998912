import { Placeholder } from "react-bootstrap";

const unitTypeForm = [
    {
        title: "Basic Information",
        formBody : [
            {
                formField: [
                    {
                        label: "Unit Type Name",
                        inputType: "text",
                        placeholder:"Enter Unit Type Name",
                        fieldName:"unitTypeName",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },                                              
                ]
            },
            {
                formField: [
                    {
                        label: "Unit Type Code",
                        inputType: "text",
                        placeholder:"Enter Unit Type Code",
                        fieldName:"unitTypeCode",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },                                              
                ]
            }
        ]
    }
];
export {
    unitTypeForm
};