import React from 'react';
import { Radio } from 'antd';

const RadioField = ({ options, unqKey, dispKey, info, fieldName, value, onChange }) => {
  const type = info?.type || 'basic';

  // Map options to Ant Design's expected format
  const mappedOptions = options.map((option) => ({
    label: option[dispKey],
    value: option[unqKey],
  }));

  
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {type === 'block' ? (
        <Radio.Group
          optionType="button"
          options={mappedOptions}
          name={fieldName}
          value={value}
          buttonStyle="solid"
          onChange={onChange}
          // defaultValue={info.defaultValue?.toString()}
        />
      ) : (
        <Radio.Group
          options={mappedOptions}
          onChange={onChange}
          name={fieldName}
          value={value}
          defaultValue={info.defaultValue?.toString()}
        />
      )}
    </div>
  );
};

export default RadioField;
