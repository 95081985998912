

const inventoryAddForm = [
    {
        title: "Basic Information",
        formBody: [

          
           
            {
                formField: [
                    {
                        // label:'Warehouse',
                        placeholder: 'Warehouse',
                        inputType: "select",
                        options: "warehouseList",
                        uniqueKey: "id",
                        onRight:"20px",
                        onChange:"handlewarehousechange",
                        displayKey: "warehouseName",
                        fieldName: "warehouseId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                  
                    {
                        // label:'Memo',
                        placeholder: 'Memo',
                        inputType: "textarea",
                        fieldName: "memoData",
                        // onChange:"onSelectAttendanceDate",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },

                ]
            },
            {
                formField: [
                    {
                        // label:'Adjustment Date',
                        placeholder: '',
                        inputType: "date",
                        fieldName: "dateOfAdjustment",
                        // onChange:"onSelectAttendanceDate",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        // label:'Reason',
                        placeholder: 'Reason',
                        inputType: "textarea",
                        fieldName: "reasonData",
                        // onChange:"onSelectAttendanceDate",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },

                   

                ]
            },

        ]
    }
];










/* const innerForm = [{
    title: "Basic Information",
    formBody: [
        {
            formField: [
                {
                    label: "Select Supplier",
                    inputType: "select",
                    placeholder: 'Warehouse',
                    options: "optionsList",
                    uniqueKey: "departmentId",
                    displayKey: "departmentName",
                    multiple: false,
                    fieldName: "Warehouse3",
                    defaultValue: "",
                    config: {
                        required: "",
                    },
                }

            ]
        },

        {
            formField: [
                {
                    label: "",
                    inputType: "select",
                    placeholder: "Receipt Location",
                    fieldName: "selectb",
                    defaultValue: "",
                    config: {
                        required: "required",
                    },
                },

            ]
        },
        {
            formField: [
                {
                    label: "",
                    inputType: "select",
                    placeholder: "Length(mm)",
                    fieldName: "Length",
                    defaultValue: "",
                    config: {
                        required: "required",
                    },
                },

            ]
        },
        {
            formField: [
                {
                    label: "",
                    inputType: "select",
                    placeholder: "Width(mm)",
                    fieldName: "Width",
                    defaultValue: "",
                    config: {
                        required: "required",
                    },
                },

            ]
        },
        {
            formField: [
                {
                    label: "",
                    inputType: "select",
                    options: "qualificationId",
                    uniqueKey: 'id',
                    placeholder: "Thickness",
                    fieldName: "Thickness",
                    defaultValue: "",
                    config: {
                        required: "required",
                    },
                },

            ]
        },

    ]
}] */
const innerClickForm = [{

    title: "Basic Information",
    formBody: [
        {
            formField: [
                {
                    inputType: "text",
                    placeholder: 'Supplier Code',
                    uniqueKey: "departmentId",
                    displayKey: "departmentName",
                    fieldName: "supplierCode",
                    defaultValue: "",
                    config: {
                        required: "",
                    },
                },
                {
                    inputType: "text",
                    placeholder: 'Primary Phone',
                    uniqueKey: "Supplier Code",
                    displayKey: "Primary Phone",
                    fieldName: "primaryNumber",
                    defaultValue: "",
                    config: {
                        required: "",
                    },
                },
                {
                    inputType: "email",
                    placeholder: 'Email',
                    uniqueKey: "Supplier Code",
                    displayKey: "Primary Phone",
                    fieldName: "email",
                    defaultValue: "",
                    config: {
                        required: "",
                    },
                },
                {
                    inputType: "textarea",
                    placeholder: 'address',
                    uniqueKey: "Supplier Code",
                    displayKey: "Primary Phone",
                    fieldName: "address",
                    defaultValue: "",
                    config: {
                        required: "",
                    },
                },
                {
                    inputType: "text",
                    type: "number",
                    placeholder: 'Postcode',
                    uniqueKey: "Supplier Code",
                    displayKey: "Primary Phone",
                    fieldName: "postCode",
                    defaultValue: "",
                    config: {
                        required: "",
                    },
                },

            ]
        },
 {
            formField: [
                {
                    inputType: "text",
                    placeholder: 'Supplier Name',
                    uniqueKey: "Supplier Code",
                    displayKey: "Primary Phone",
                    fieldName: "suplierNameValue",
                    defaultValue: "",
                    config: {
                        required: "",
                    },
                },
                {
                    inputType: "text",
                    placeholder: 'Secondary Phone',
                    uniqueKey: "Supplier Code",
                    displayKey: "Primary Phone",
                    fieldName: "secondaryPhone",
                    defaultValue: "",
                    config: {
                        required: "",
                    },
                },
                {
                    inputType: "text",
                    placeholder: 'Contact  Person',
                    uniqueKey: "Supplier Code",
                    displayKey: "Primary Phone",
                    fieldName: "contact",
                    defaultValue: "",
                    config: {
                        required: "",
                    },
                },
                {
                    inputType: "textarea",
                    placeholder: 'Comment',
                    uniqueKey: "Supplier Code",
                    displayKey: "Primary Phone",
                    fieldName: "comment",
                    defaultValue: "",
                    config: {
                        required: "",
                    },
                },
            ]
        },

    ]
}]


const inner = [
    {
        placeholder: 'Warehouse',
        inputType: "select",
        options: "warehouseList",
        uniqueKey: "id",
        displayKey: "warehouseName",
        fieldName: "receiptWarehouse",
        onChange: "handleTypeChange",
        defaultValue: "",
        config: {
            required: "required",
        },
    },
    {
        placeholder: 'Location',
        inputType: "select",
        options: "locationList",
        uniqueKey: "id",
        displayKey: "name",
        fieldName: "receiptLocation",
        onChange: "handleTypeChange",
        defaultValue: "",
        config: {
            required: "required",
        },
    },
   
   
      {
        label: "",
        inputType: "text",
        type: "number",
        placeholder: "batchNumber",
        fieldName: "batchNumber",
        onChange: "handleChange",
        defaultValue: "",
        config: {
          required: "",
        },
      },
      {
        label: "",
        inputType: "text",
        type: "number",
        placeholder: "serialNumber",
        fieldName: "serialNumber",
        onChange: "handleChange",
        defaultValue: "",
        config: {
          required: "",
        },
      },
    {
      label: "",
      inputType: "text",
      placeholder: "Length",
      fieldName: "itemHeight",
      onChange: "handleChange",
      defaultValue: "",
      config: {
        required: "",
      },
    },
    {
      label: "",
      inputType: "text",
      placeholder: "Width",
      fieldName: "itemWidth",
      onChange: "handleChange",
      defaultValue: "",
      config: {
        required: "",
      },
    },
    {
      label: "",
      inputType: "text",
      placeholder: "Thickness",
      fieldName: "thickness",
      onChange: "handleChange",
      defaultValue: "",
      config: {
        required: "",
      },
    },
    {
        label: "",
        inputType: "icon-table",
        index: 'delete',
        fieldName: "deleteInner",
        onClick:"onDeleteInner",
        defaultValue: "",
        config: {
            required: "required",
        },
    },
  ]
  const subInner = [
    {
        placeholder: 'Warehouse',
        inputType: "select",
        options: "warehouseList",
        uniqueKey: "id",
        onRight:"20px",
        onChange:"handlelocationchange",
        displayKey: "warehouseName",
        fieldName: "receiptWarehouse",
        defaultValue: "",
        config: {
            required: "required",
        },
    },
    {
        placeholder: 'Location',
        inputType: "select",
        options: "locationList",
        onChange:"handlelocationchange",

        uniqueKey: "id",
        displayKey: "name",
        fieldName: "receiptLocation",
        defaultValue: "",
        config: {
            required: "required",
        },
    },
    {
        placeholder: 'Batch Number',
        inputType: "text",
        onChange:"handlelocationchange",

        uniqueKey: "id",
        displayKey: "name",
        fieldName: "batchNumber",
        defaultValue: "",
        config: {
            required: "required",
        },
    },
    {
      label: "Apply to all",
      inputType: "button",
     
      fieldName: "applyButton",
      onClick:"onSubmitItemDetails",
      defaultValue: "",
      config: {
        required: "required",
      },
    },

  
 
   
  ]


const fieldList = [
   
    {
        label: "",
        inputType: "searchableInput",
        placeholder:"Material Code",
        options: "materialCodeList",
        onChange:"getMaterialName",
        uniqueKey: "materialId",
        displayKey: "materialCode",
        fieldName: "materialCodeId",
        defaultValue: "",
        config: {
            required: "required",
        },
    },
    
    {
        label: "",
        inputType: "text",
        placeholder: "MaterialName",
        fieldName: "materialName",
        defaultValue: "",
        config: {
            required: "required",
        },
    },
    {
        label: "",
        inputType: "text",
        placeholder: "Qty*",
        onChange:"onPriceChange",
        fieldName: "qty",
        defaultValue: "",
        config: {
            required: "required",
        },
    },
    {
        placeholder: 'Unit',
        inputType: "select",
        options: "unitsList",
        // onChange: "handleUnitsChange",
        uniqueKey: "unitId",
        displayKey: "unitName",
        fieldName: "unit",
        defaultValue: "",
        config: {
            required: "required",
        },
    },
   
    {
        label: "",
        inputType: "text",
        type:"number",
        placeholder: "Dimension",
        fieldName: "dimension",
        defaultValue: "",
        config: {
            required: "required",
        },
    },
   
    {
        label: "",
        inputType: "text",
        uniqueKey: "poMaterialId",
       
        placeholder: "Unit Cost",
        fieldName: "unitPrice",
      
        defaultValue: "",
        config: {
            required: "required",
        },
    },
    {
        label: "",
        options: "statusList",
        uniqueKey: "id",
        onChange: "handleType",
        displayKey: "typeName",
        inputType: "select",
        placeholder: "Type",
        fieldName: "type",
        defaultValue: "",
        config: {
            required: "required",
        },
    },
    {
        label: "",
        inputType: "text",
        placeholder: "Reason",
        uniqueKey: "taxId",
       
        fieldName: "reason",
        defaultValue: "",
        config: {
            required: "required",
        },
    },
    {
        label: "",
        inputType: "text",
        placeholder: "Remark",
        fieldName: "remark",
        defaultValue: "",
        config: {
            required: "required",
        },
    },
   
    {
        label: "",
        inputType: "icon-table",
       // index: 'add',
        fieldName: "itemIcon",
        onClick:"onAddItem",
        defaultValue: "",
        config: {
            required: "required",
        },
    },
    
    {
        label: "",
        inputType: "icon-table",
        index: 'delete',
        fieldName: "delete",
        onRight:"40px",
        onClick:"onDeletePress",
        defaultValue: "",
        config: {
            required: "required",
        },
    },
]






export {
    // submenuForm,
    /* productInformationForm */
    // dropDownForm,
    inventoryAddForm,
    // purchaseForm,
    // demo,
    inner,
   
    // innerForm,
    innerClickForm,
    fieldList,
    subInner,
};