


import { removeSpaceBtwStr } from "../../../utils/app-functions";
import _ from "lodash";
import React, { useImperativeHandle, Fragment } from "react";
import { useState, useEffect, useRef } from "react";
// import { Tabs, Tab } from "react-tabs-scrollable";
// import "react-tabs-scrollable/dist/rts.css";
import { Select as AntdSelect } from 'antd';
import { useTranslation } from 'react-i18next';

const classNames = require('classnames');


const { Option } = AntdSelect;
const TimeTable = React.forwardRef(({ ...props }, ref) => {

    const { days = [], periodTime = [], existing, data, dynamicForm, noOfColumn, onSubmitForm, btnAlign, btnClickFun, onChangeCallback, optionList = [] } = props


    let temp_arr = {};


    if (existing && data && data.length > 0 && data[0].periodMeta) {
        temp_arr = data[0].periodMeta;
    } else {
        days.forEach((item) => {
            let obj = {};
            periodTime.forEach((ele) => {
                const name = removeSpaceBtwStr(ele.periodTitle);
                obj[name] = "";
            });
            if (!_.isEmpty(obj)) {
                temp_arr[item.dayName] = obj;
            }
        });
    }

    const [timeTableState, setTimeTableState] = useState(temp_arr);
    const [state, setState] = useState({
        periodDays: days,
        periodTime: periodTime
    })

    useEffect(() => {
        if (data && data.length > 0 && data[0].periodMeta) {
            const { periodMeta } = data[0];
            setTimeTableState(prevState => ({
                ...prevState,
                ...periodMeta
            }));
        }
    }, [data]);




    useImperativeHandle(ref, () => ({
        timeTableStateInfo: () => {
            return { timeTable: timeTableState };
        }
    }), [timeTableState]);


    const handleChange = (event, index, name ,eventName) => {
        const temp_state = { ...timeTableState };
        // alert(event + " === " + name + " === " + index)
        // return
        temp_state[name][eventName] = event;

        setTimeTableState(temp_state);
    };


    const [t] = useTranslation('common');
    const className = classNames({
        'ax-select': true,
    });
    const allProps = { className, ...props };

    return (
        <div className="table-responsive mt-2">
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col" style={{ fontWeight: "bold" }} >Days/Time</th>

                        {state.periodTime.map((ele, idx) => (
                            <th key={idx} scope="col" >{ele?.periodTitle || "-"}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {state.periodDays.map((item, index) => {
                        return (
                            <Fragment key={index}>
                                <tr >
                                    {item.status && <th className="py-3">{item?.dayName || "-"}</th>}
                                    {periodTime.map((ele, idx) => {
                                        if (ele.periodTitle != "Days / Time" && item.status) {
                                            if (ele.isBreak != true) {
                                                const name = `${removeSpaceBtwStr(ele.periodTitle)}`
                                                const selectedValue = timeTableState[item?.dayName][name]

                                                return (
                                                    <Fragment key={idx} >
                                                        <td >
                                                            <AntdSelect
                                                                {...allProps}
                                                                style={{ width: '100px' }} // Fixed width ensures no collapse
                                                                id="picker1"
                                                                name={name}
                                                                size="lg"
                                                                placeholder="--Select--"
                                                                value={selectedValue ? Number(selectedValue) : undefined}
                                                                // onChange={(value) => handleChange({ target: { value } }, index, item?.dayName)}
                                                                onChange={(event) => handleChange(event, index, item?.dayName ,name)}

                                                                allowClear
                                                            >
                                                                {optionList.length > 0 &&
                                                                    optionList.map((val) => (
                                                                        <Option key={val.subjectId} value={val.subjectId}>
                                                                            {val.subjectName}
                                                                        </Option>
                                                                    ))}
                                                            </AntdSelect>

                                                        </td>


                                                    </Fragment>
                                                )
                                            } else {
                                                return (
                                                    <Fragment key={idx}>
                                                        <td> Break </td>

                                                    </Fragment>
                                                )
                                            }

                                        }

                                    })}
                                </tr>
                            </Fragment>
                        )
                    })}
                </tbody>
            </table>
        </div>
    );
});

export default TimeTable;