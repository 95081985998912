import React from "react";
import { PDFDownloadLink, PDFViewer, Document, Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import { dateConversion } from "app/utils/app-functions";
import { imagePath, unitPath } from "app/utils/constant";
// import CustomAlignButton from "./CustomAlignButton";

const styles = StyleSheet.create({
  container: {
    maxWidth: 800,
    margin: "0 auto",
    padding: 20,
    border: "1px solid #ccc",
    borderRadius: 8,
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    backgroundColor: "white",
    position : "relative",

  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between", // Align items and add space between
    padding: "10px 0",
    borderBottom: "1px solid #ccc",
  },
  schoolLogo: {
    width: 100,
    height: 100,
    marginLeft: 10,
  },
  schoolDetails: {
    textAlign: "center",
    flex: 1,
    marginTop : -14
  },
  location: {
    textAlign: "center",
    fontSize: 16,
    marginTop:5
  },
  footer: {
    borderTop: "1px solid #ccc",
    borderBottom: "none",
    padding: "20px 0",
    position: "absolute",
    bottom: 0,
    width: "100%",
  },
  content: {
    marginTop: 20,
  },
  title: {
    textAlign: "center",
    margin: "20px 0",
    fontSize: 24,
  },
  date: {
    textAlign: "right",
    marginBottom: 10,
    fontSize: 14,

  },
  bodyHeader: {
    marginTop: 20,
    fontWeight: "bold",
    fontSize: 14,
  },
  body: {
    marginTop: 15,
    fontSize: 15,
  },
  
  h1: {
    fontSize: 28, // H1 size
    fontWeight: "bold",
  },
  h2: {
    fontSize: 24, // H2 size
  },
  signature:{
    position : "absolute",
    marginLeft: 20,
    bottom: 40,
    // bottom: "40%",
    // right: 10
 
  }
});

const CircularDetailsDocument = ({ data, schoolInfo,unitDetails }) => {
  return (
    <Document>
      <Page style={styles.container}>
        <View style={styles.header}>
          <Image style={styles.schoolLogo} src={`${imagePath}${unitPath}${unitDetails?.[0]?.unitToken || ''}${encodeURI(unitDetails?.[0]?.image || '')}`} />
          <View style={styles.schoolDetails}>
            <Text style={styles.h1}>{unitDetails?.[0]?.unitName || ''}</Text>
            <Text style={styles.location}>{schoolInfo[0]?.address1 || ''}, {schoolInfo[0]?.cityName || ''} - {schoolInfo[0]?.pincode || ''}</Text>
          </View>
        </View>
        <View style={styles.content}>
          <Text style={styles.title}>{data.title}</Text>
          <Text style={styles.date}>Date: {dateConversion(data.createdOn)}</Text>
          <Text style={styles.bodyHeader}>To: {data.name}</Text>
          <Text style={styles.body}>{data.description}</Text>
        </View>
        <View style={styles.signature}>
          <Text style={styles.regrads}>Regards,</Text>
          <Text style={styles.principal}>Principal</Text>
        </View >
        <View style={styles.footer}></View>
      </Page>
    </Document>
  );
};

const CircularDetailsPDF = ({ data, schoolInfo,unitDetails, onClick }) => {
  return (
    <div>
      {/* <PDFDownloadLink document={<CircularDetailsDocument data={data} schoolInfo={schoolInfo} />} fileName="circular.pdf">
        {({ blob, url, loading, error }) =>
          loading ? "Loading document..." : <CustomAlignButton btnName="Download" onClick={onClick} />
        }
      </PDFDownloadLink> */}
      <PDFViewer style={{ width: '100%', height: '700px' }}>
        <CircularDetailsDocument data={data} schoolInfo={schoolInfo} unitDetails={unitDetails}/>
      </PDFViewer>
    </div>
  );
};

export default CircularDetailsPDF;
