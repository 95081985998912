import React, { useState } from "react";
import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css'

export default function App({ content, placeholder, handleContentChange }) {
  const [convertedText, setConvertedText] = useState("Type here...");

  const modules = {
    toolbar: {
      container: "#toolbar",
    }
  }
  const formats = [
    'font', 'size',
    'bold', 'italic', 'underline', 'strike',
    'color', 'background',
    'script',
    'header', 'blockquote', 'code-block',
    'indent', 'list',
    'direction', 'align',
    'link', 'image', 'video', 'formula',
  ]

  return (
    <div>
      <ReactQuill
        theme='snow'
        placeholder={placeholder}
        value={content}
        // modules={modules}
        // formats={RichTextEditor.formats}
        // modules={modules}
        // formats={formats}
        onChange={handleContentChange}
        style={{ minHeight: '300px', padding: '2rem 3rem;' }}
      />
    </div>
  );
}