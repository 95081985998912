import { convertTo12HourFormat, removeSpaceBtwStr } from "../../../utils/app-functions";
import React from "react";
// import { Tabs, Tab } from "react-tabs-scrollable";
// import "react-tabs-scrollable/dist/rts.css";

const SimpleBorderTable = (props) => {

    const { heading = [], data = {}, columns = [], days = [], subjectList = [] } = props
console.log(JSON.stringify(heading));

    return (
        <>
            <div className="table-responsive">
                <table
                    id="user_table"
                    className="table table-bordered  text-center"
                >
                    <thead>
                        <tr>
                            <th scope="col" style={{ fontWeight: "bold" }} >Days</th>
                            {heading.map((ele, idx) => {
                                return <th key={idx} scope="col">{ele.periodTitle}</th>
                            })}
                        </tr>
                        <tr>
                        <th scope="col" style={{ fontWeight: "bold" }} >Time</th>
                            {heading.map((ele, idx) => {
                                return <th key={idx} scope="col">{convertTo12HourFormat(ele.fromTime) + " - " +convertTo12HourFormat(ele.toTime)}</th>
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            days.map((item, index) => {
                                // Render only if item.status is true
                                if (item.status) {
                                    return (
                                        <tr key={index}>
                                            <th scope="col">{item?.dayName || ""}</th>

                                            {
                                                heading.map((ele, idx) => {
                                                    if (ele.periodTitle !== "Days / Time") {
                                                        const name = removeSpaceBtwStr(ele.periodTitle);  // Clean up the periodTitle
                                                        if (ele.isBreak !== true) {
                                                            const dayName = item?.dayName || "";
                                                            const subjectData = data[dayName] || "HOLIDAY";  // Use default "HOLIDAY" if no data found

                                                            if (subjectData && subjectData[name]) {
                                                                const subjectId = parseInt(subjectData[name]);
                                                                const filterData = subjectList.filter((v) => parseInt(v.subjectId) === subjectId);

                                                                if (filterData.length > 0) {
                                                                    return <td key={idx} style={{padding:"5px"}}>{filterData[0].subjectName}</td>;
                                                                } else {
                                                                    console.error(`No subject found with ID ${subjectId}`);
                                                                    return <td key={idx}>No Subject</td>;
                                                                }
                                                            } else {
                                                                console.error("Error: Unable to retrieve subject data.");
                                                                return <td key={idx}>No Subject</td>;
                                                            }
                                                        } else {
                                                            return <td key={idx} style={{ color: 'white', backgroundColor: '#00246B' }}>Break</td>;
                                                        }
                                                    }
                                                    return null;  // Ensure something is returned for each iteration
                                                })
                                            }
                                        </tr>
                                    );
                                }
                                return null;  // Skip rendering the row if `item.status` is falsy
                            })
                        }

                    </tbody>
                </table>
            </div>
        </>
    );
};

export default SimpleBorderTable;


