import { returnApiCallPromise } from './ApiContent';
import { studentClassList, studentList } from './APIContainer';
//GET
export function getStudentClassList(request) {
  const requestObj = request ? { params: request } : "";
  return (dispatch) => {
    returnApiCallPromise("GET", studentClassList, requestObj)
      .then(response => {
        dispatch({
          type: 'GET_STUDENT_CLASS_LIST_SUCCESS',
          payload: response.data
        })
      }).catch(error => {


        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'GET_STUDENT_CLASS_LIST_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

export function getStudentList(request) {
  const requestObj = request ? { params: request } : "";
  return (dispatch) => {
    returnApiCallPromise("GET", studentList, requestObj)
      .then(response => {
        dispatch({
          type: 'GET_STUDENT_LIST_SUCCESS',
          payload: response.data
        })
      }).catch(error => {


        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'GET_STUDENT_LIST_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

export function getAsyncStudentClassList(request) {
  const requestObj = request ? { params: request } : "";
  return (
    returnApiCallPromise("GET", studentClassList, requestObj)
      .then(response => {
        const result = {
          getSubjectSuccess: true,
          data: response.data.data
        }

        return result;
      }).catch(error => {

        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }

        const result = {
          getTaxFailed: true,
          errorMessage: errorMessage
        }

        return result;
      })
  )
}

export function asyncGetStudentListInfo(request) {
  const requestObj = request ? { params: request } : "";
  return (
    returnApiCallPromise("GET", studentList, requestObj)
      .then(response => {
        const result = {
          getSubjectSuccess: true,
          data: response.data.data
        }

        return result;
      }).catch(error => {

        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }

        const result = {
          getTaxFailed: true,
          errorMessage: errorMessage
        }

        return result;
      })
  )
}

//POST
export function createStudentList(request) {

  return (dispatch) => {
    returnApiCallPromise("POST", studentList, request)
      .then(response => {
        if (response.data.error) {
          dispatch({
            type: 'CREATE_STUDENT_LIST_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'CREATE_STUDENT_LIST_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'CREATE_STUDENT_LIST_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}


export function updateStudentList(request, markSheetId) {
  return (dispatch) => {
    returnApiCallPromise("PUT", studentList, request)
      .then(response => {


        if (response.data.error) {
          dispatch({
            type: 'UPDATE_STUDENT_LIST_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'UPDATE_STUDENT_LIST_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'UPDATE_STUDENT_LIST_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}