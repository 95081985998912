import { returnApiCallPromise } from './ApiContent';
import { delivery } from './APIContainer';
//GET
export function getDelivery(request) {
  const requestObj = request ? { params: request } : "";
  return (dispatch) => {
    returnApiCallPromise("GET", delivery, requestObj)
      .then(response => {
        dispatch({
          type: 'GET_DELIVERY_SUCCESS',
          payload: response.data
        })
      }).catch(error => {

        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'GET_DELIVERY_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

//POST
export function createDelivery(request) {

  return (dispatch) => {
    returnApiCallPromise("POST",delivery , request)
      .then(response => {
        if (response.data.error) {
          dispatch({
            type: 'CREATE_DELIVERY_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'CREATE_DELIVERY_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'CREATE_DELIVERY_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

//PUT
export function updateDelivery(request, orderId) {
  return (dispatch) => {
    returnApiCallPromise("PUT",delivery+"/"+orderId, request)
      .then(response => {
        if (response.data.error) {
          dispatch({
            type: 'UPDATE_DELIVERY_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'UPDATE_DELIVERY_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'UPDATE_DELIVERY_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}