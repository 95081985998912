import * as yup from "yup";
import { Formik } from "formik";
import { useState, useRef, useImperativeHandle, forwardRef } from "react";
import DateTime from "react-datetime";
import { Form, Button } from "react-bootstrap";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import formRender from "../../components/render/formcomponent";
import _ from "lodash";
import { check0rEmpty } from "../../utils/utils";

const FormLayout = forwardRef((props, ref) => {
    // const { dynamicForm, onSubmit } = props;
    const formRef = useRef()
    const [errorState, setErrorState] = useState({});
    const { options, data, closeModule, updatedData } = props;
    const { defaultState, setDefaultState, dynamicForm, noOfColumn, onSubmit, onSearch, onChangeCallback, iconPress, dataList, layoutType = "form", index } = props;
    const tableIndex = index;
    const validateForm = () => {
        const stateData = { ...errorState, };
        let errorStatus = false;
        (dynamicForm ? dynamicForm : []).map((item, index) => {
            (item?.formBody || []).map((sItem, sIndex) => {
                const formField = (sItem.formField).filter(x => x.config?.required === "required").map((x) => {
                    const stateValueExist = check0rEmpty(defaultState[x.fieldName]) ? true : false;
                    stateData[x.fieldName] = stateValueExist ? false : true;
                    errorStatus = errorStatus ? errorStatus : stateData[x.fieldName];
                });

            })
        })
        setErrorState(stateData);
        if (!errorStatus) onSubmit();

        return !errorStatus ? true : false;
    }
    useImperativeHandle(ref, () => ({
        validateForm() {
            return validateForm();
        }
    }));
    /* useImperativeHandle(ref, () => {
        return {
            validateForm() {
                validateForm();
            }
        };
      }, [defaultState]); */

    const [state, setState] = useState({
        firstName: "",
        lastName: "",
        phone: "",
        select: "",
        birthDay: "",
        cardNumber: "4444444444444444",
        email: "",
        password: "",
        repassword: "",
        website: "",
        switch: false,
        checkbox1: "",
        checkbox2: "",
        radio: "",
        range: {
            startDate: new Date(),
            endDate: (() => {
                let date = new Date();
                date.setDate(date.getDate() + 7);
                return date;
            })(),
        },
    });

    const submitClick = () => {
        if (formRef.current) {
            formRef.current.handleSubmit()
        }
    }

    const handleSubmit = (values, { setSubmitting }) => {
        console.log(values);
    };

    const handleDateRangeChange = (range) => {
        let { startDate, endDate } = range;
        setState((prevState) => ({
            ...prevState,
            range: {
                startDate: startDate.toDate(),
                endDate: endDate.toDate(),
            },
        }));
    };


    return (
        <Formik
            initialValues={state}
            validationSchema={basicFormSchema}
            onSubmit={handleSubmit}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
            }) => (
                <form onSubmit={handleSubmit} /* style={{ width: window.screen.availWidth, height: window.screen.availHeight }} */>
                    {
                        dynamicForm && dynamicForm.length > 0 && dynamicForm.map((el, index) => {
                            const sectionData = el?.formBody || [];
                            const columnCount = noOfColumn ? noOfColumn : sectionData?.length || 0;
                            const colWidth = 12 / columnCount

                            return (
                                <div key={`h_${index}`} className="row" >
                                    {/*    {el?.title && <div className="col-lg-12"><h4>{el?.title}</h4></div>} */}
                                    {
                                        columnCount > 0 && sectionData.map((ele, idx) => {
                                            const formField = ele.formField?.length ? ele.formField : []
                                            return (
                                                <div key={`r_${idx}`} className={`col-md-${colWidth} form-group mb-3`} >
                                                    {ele?.title &&
                                                        (ele?.title === "true" ? <div className="col-lg-12" style={{ height: '40px' }} /> :
                                                            <div className="col-lg-12" style={{ height: '40px' }}>
                                                                <h5 className="title-header title-border" style={{ height: '30px', borderBottom: '3px solid #003473' }}>{ele?.title?.toUpperCase()} <span style={{ borderBottomStyle: "none", fontSize: "12px" }}>{ele?.subTitle || ""}</span></h5>
                                                                {/* <p className="col-lg-12" style={{color:"red"}}>{ele?.content || ""}</p> */}
                                                            </div>
                                                        )
                                                    }
                                                    {
                                                        formField.map((field, index) => {
                                                            const itemIndex = layoutType === "table" ? tableIndex : index;

                                                            const subFieldStatus = field?.subField ? true : false
                                                            if (subFieldStatus) {
                                                                const subFormField = field?.subField || []
                                                                return (
                                                                    <div key={`sub_${index}`} className="row mb-0" >
                                                                        {
                                                                            subFormField.map((subField, index) => {
                                                                                const subColumnCount = subFormField?.length || 1;
                                                                                const subColWidth = 12 / subColumnCount
                                                                                return (
                                                                                    <div key={`sub_r_${idx}`}  className={`col-md-${subColWidth} mb-0`}>
                                                                                        {formRender(defaultState, setDefaultState, subField, onSearch, onChangeCallback, iconPress, itemIndex, layoutType, dataList, null, errorState, setErrorState,)}
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                )
                                                            } else {
                                                                return (
                                                                    formRender(defaultState, setDefaultState, field, onSearch, onChangeCallback, iconPress, itemIndex, layoutType, dataList, null, errorState, setErrorState,)
                                                                )
                                                            }
                                                        })
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </div>

                            )
                        })
                    }
                </form>
            )}
        </Formik>
    )

    return (
        <Formik
            initialValues={state}
            validationSchema={basicFormSchema}
            onSubmit={handleSubmit}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
            }) => (
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-md-6 form-group mb-3">
                            <label htmlFor="firstName">First name</label>
                            <input
                                id="firstName"
                                name="firstName"
                                className="form-control"
                                placeholder="Enter your first name"
                                type="text"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.firstName}
                            />
                            {errors.firstName && touched.firstName && (
                                <div className="text-danger mt-1 ml-2">
                                    {errors.firstName}
                                </div>
                            )}
                        </div>

                        <div className="col-md-6 form-group mb-3">
                            <label htmlFor="lastName">Last name</label>
                            <input
                                id="lastName"
                                name="lastName"
                                className="form-control"
                                placeholder="Enter your last name"
                                type="text"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.lastName}
                            />
                            {errors.lastName && touched.lastName && (
                                <div className="text-danger mt-1 ml-2">
                                    {errors.lastName}
                                </div>
                            )}
                        </div>

                        <div className="col-md-6 form-group mb-3">
                            <label htmlFor="email">Email address</label>
                            <input
                                name="email"
                                id="email"
                                className="form-control"
                                placeholder="xox@xmail.com"
                                type="email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                            />
                            {errors.email && touched.email && (
                                <div className="text-danger mt-1 ml-2">
                                    {errors.email}
                                </div>
                            )}
                            <small id="emailHelp" className="form-text text-muted">
                                We'll never share your email with anyone else.
                            </small>
                        </div>

                        <div className="col-md-6 form-group mb-3">
                            <label htmlFor="phone">Phone</label>
                            <input
                                className="form-control"
                                id="phone"
                                name="phone"
                                placeholder="Enter phone"
                                type="text"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.phone}
                            />
                            {errors.phone && touched.phone && (
                                <div className="text-danger mt-1 ml-2">
                                    {errors.phone}
                                </div>
                            )}
                        </div>

                        <div className="col-md-6 form-group mb-3">
                            <label htmlFor="cardNumber">Cradit card number</label>
                            <input
                                className="form-control"
                                id="cardNumber"
                                name="cardNumber"
                                placeholder="4444-4444-4444-4444"
                                type="number"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.cardNumber}
                            />
                            {errors.cardNumber && touched.cardNumber && (
                                <div className="text-danger mt-1 ml-2">
                                    {errors.cardNumber}
                                </div>
                            )}
                        </div>

                        <div className="col-md-6 form-group mb-3">
                            <label htmlFor="website">Website</label>
                            <input
                                className="form-control"
                                id="website"
                                name="website"
                                placeholder="Web address"
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </div>

                        <div className="col-md-6 form-group mb-3">
                            <label htmlFor="picker2">Birth date</label>
                            <DateTime
                                name="birthDay"
                                onChange={(date) => {
                                    handleChange({
                                        target: {
                                            name: "birthDay",
                                            value: date.toDate(),
                                        },
                                    });
                                }}
                            />
                            {errors.birthDay && touched.birthDay && (
                                <div className="text-danger mt-1 ml-2">
                                    {errors.birthDay}
                                </div>
                            )}
                        </div>

                        <div className="col-md-6 form-group mb-3">
                            <label htmlFor="picker1">Select</label>
                            <select
                                id="picker1"
                                className="form-control"
                                name="select"
                                value={values.select}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            >
                                <option value="opt1">Option 1</option>
                                <option value="opt2">Option 2</option>
                                <option value="opt3">Option 3</option>
                            </select>
                            {errors.select && touched.select && (
                                <div className="text-danger mt-1 ml-2">
                                    {errors.select}
                                </div>
                            )}
                        </div>

                        <div className="col-md-6 form-group mb-3">
                            <Form.Check
                                name="switch"
                                onChange={handleChange}
                                value={values.switch}
                                type="switch"
                                id="custom-switch"
                                label="I accept the terms & conditions"
                            />
                            <Form.Check
                                name="checkbox1"
                                onChange={handleChange}
                                value="check123"
                                checked={
                                    values.checkbox1 &&
                                    values.checkbox1.includes("check123")
                                }
                                type="checkbox"
                                id="check1"
                                label="I accept the policies"
                            />
                            <Form.Check
                                name="checkbox2"
                                onChange={handleChange}
                                value="check321"
                                checked={
                                    values.checkbox2 &&
                                    values.checkbox2.includes("check321")
                                }
                                type="checkbox"
                                id="check2"
                                label="Subscribe for future news letters"
                            />
                        </div>

                        <div className="col-md-6 form-group mb-3">
                            <fieldset onChange={handleChange}>
                                <Form.Group>
                                    <Form.Label as="legend">
                                        Choose any option
                                    </Form.Label>
                                    <Form.Check
                                        type="radio"
                                        label="first radio"
                                        name="radio"
                                        id="formHorizontalRadios1"
                                        value="radio1"
                                    />
                                    <Form.Check
                                        type="radio"
                                        label="second radio"
                                        name="radio"
                                        id="formHorizontalRadios2"
                                        value="radio2"
                                    />
                                    <Form.Check
                                        type="radio"
                                        label="third radio"
                                        name="radio"
                                        id="formHorizontalRadios3"
                                        value="radio3"
                                    />
                                </Form.Group>
                            </fieldset>
                        </div>

                        <div className="col-md-12 form-group mb-3">
                            <label htmlFor="picker2">Birth date</label>
                            <DateRange
                                startDate={new Date(
                                    state.range.startDate
                                ).toLocaleDateString("en-GB")}
                                endDate={new Date(
                                    state.range.endDate
                                ).toLocaleDateString("en-GB")}
                                onChange={handleDateRangeChange}
                            />
                        </div>
                        <div className="col-md-12">
                            <Button type="submit">Submit</Button>
                        </div>
                    </div>
                </form>
            )}
        </Formik>
    )

});

const basicFormSchema = yup.object().shape({
    firstName: yup.string().required("first name is required"),
    lastName: yup.string().required("last name is required"),
    phone: yup.string().required("select any option"),
    select: yup.string().required("select any option"),
    birthDay: yup.string().required("birthDay is required"),
    cardNumber: yup
        .number()
        .test("card-validator", "Invlid card", (value) =>
            value && value.toString().length !== 16 ? false : true
        )
        .required("Card number is required"),
    email: yup.string().email("Invalid email").required("email is required"),
    checkbox1: yup.string().required("Required"),
    checkbox2: yup.string().required("Required"),
    switch: yup.string().required("Required"),
    // password: yup
    //   .string()
    //   .min(8, "Password must be 8 character long")
    //   .required("password is required"),
    // repassword: yup
    //   .string()
    //   .required("repeat password")
    //   .oneOf([yup.ref("password")], "Passwords must match")
});

export default FormLayout;
