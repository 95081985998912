import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const CollapseContainer = styled.div`
  margin: 15px 0;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  background-color: #fff;
`;

const CollapseHeader = styled.button`
  color: white;
  padding: 5px 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  width: 100%;
  text-align: left;
  font-size: 18px;
  font-weight: bold;
  border-radius: 10px 10px 0 0;
  transition: background-color 0.3s ease;

 

  &:focus {
    outline: none;
  }
`;

const CollapseContentWrapper = styled.div`
  max-height: ${({ isOpen, height }) => (isOpen ? `${height}px` : "0")};
  overflow: hidden;
  transition: max-height 0.6s ease-in-out;
`;

const CollapseContent = styled.div`
  padding: 20px;
  background-color: #f9f9f9;
  line-height: 1.6;
`;

const Icon = styled.span`
  transform: ${({ isOpen }) => (isOpen ? "rotate(180deg)" : "rotate(0)")};
  transition: transform 0.3s ease-in-out;
`;

const Badge = styled.span`
  background-color: ${({ grade }) =>
    grade === "A+" ? "#4caf50" : grade === "A" ? "#2196f3" : "#fbc02d"};
  color: white;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 14px;
  margin-left: 10px;
`;

const SubjectRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background: white;
  margin: 5px 0;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const SubjectName = styled.span`
  font-weight: 500;
`;

const Marks = styled.span`
  background-color: #4caf50;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
`;

const Collapse = ({ title, grade, children, isOpen }) => {
    const [contentHeight, setContentHeight] = useState(0);
    const contentRef = useRef(null);
  
    useEffect(() => {
      if (contentRef.current) {
        setContentHeight(contentRef.current.scrollHeight);
      }
    }, [children]);
  
    return (
      <CollapseContainer>
        <CollapseHeader>{title}</CollapseHeader>
        <CollapseContentWrapper isOpen={isOpen} height={contentHeight}>
          <CollapseContent ref={contentRef}>{children}</CollapseContent>
        </CollapseContentWrapper>
      </CollapseContainer>
    );
  };
  
  Collapse.propTypes = {
    title: PropTypes.node.isRequired,
    grade: PropTypes.string,
    children: PropTypes.node.isRequired,
    isOpen: PropTypes.bool.isRequired,
  };
  
export default Collapse;
