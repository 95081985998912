import React from 'react';
import './styles.css';
const noProfile = require("app/pages/images/no_profile.png");
const classNames = require('classnames');

const Image = ({ width, height, size, position }) => {
  
  const className = classNames({
          'ax-image': true,      
          [size]: size,   
          [position]: position,   
      });

  return (
    <img      
      src={noProfile}
      className={className}
    />
  )
};

export default Image;
