import { returnApiCallPromise } from './ApiContent';
import { homeWorkType } from './APIContainer';

//GET
export function getHomeWorkType(request) {
  const requestObj = request ? { params: request } : "";
  return (dispatch) => {
    returnApiCallPromise("GET", homeWorkType, requestObj)
      .then(response => {
        dispatch({
          type: 'GET_HOME_WORK_TYPE_SUCCESS',
          payload: response.data
        })
      }).catch(error => {
        
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'GET_HOME_WORK_TYPE_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}
export function asyncGetHomeWorkTypeInfo(request) {
  const requestObj = request ? { params: request } : "";
  return  returnApiCallPromise("GET", homeWorkType, requestObj)
      .then(response => {
        return {
          error: false,
          message:"Succsss",
          data: response?.data?.data || []
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        return {
          error: true,
          data:[],
          message:errorMessage
        }
      })
}

export function getAsyncHomeWorkType(request) {
  const requestObj = request ? { params: request } : "";
  return (
    returnApiCallPromise("GET", homeWorkType, requestObj)
    .then(response => {
      const result = {
        getHomeworkTypeSuccess: true,
        data: response.data.data
      }
     
      return result;
    }).catch(error => {

      let errorMessage = error;
      if (error.response) {
        if (error.response.data.error) {
          errorMessage = error.response.data.message;
        }
      }

      const result = {
        getHomeworkTypeFailed: true,
        errorMessage: errorMessage
      }
     
      return result;
    })
  )
  
}

//POST
export function createHomeWorkType(request) {

  return (dispatch) => {
    returnApiCallPromise("POST", homeWorkType, request)
      .then(response => {
        if (response.data.error) {
          dispatch({
            type: 'CREATE_HOME_WORK_TYPE_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'CREATE_HOME_WORK_TYPE_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'CREATE_HOME_WORK_TYPE_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

export function updateHomeWorkType(request, typeId) {
  return (dispatch) => {
    returnApiCallPromise("PUT", homeWorkType+"/"+typeId, request)
      .then(response => {

        if (response.data.error) {
          dispatch({
            type: 'UPDATE_HOME_WORK_TYPE_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'UPDATE_HOME_WORK_TYPE_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'UPDATE_HOME_WORK_TYPE_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}