import React from 'react';
import SimpleCard from 'app/components/atoms/simple-card/SimpleCard';
import DashboardNew from 'app/components/atoms/dashboard/Dashboard';
import { useSidebar } from "app/components/sidebar/sidebarContext";

const Dashboard = () => {
    const { sidebarOpen } = useSidebar();

    return (
        <div style={sidebarOpen ? { marginLeft: "10px", marginTop: "40px" } : { marginLeft: "60px", marginTop: "40px",marginBottom:"70px" }}>
            <DashboardNew></DashboardNew>

        </div>



    );
};

export default Dashboard;
