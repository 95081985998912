import React, { useState } from "react";
import StudentExamDashboard from "./ExamView";
import StudentAttendanceChart from "./Attendance";
import AssignmentList from "./Assignment";

const Header = ({ studentFilterData, studentExamData, attendanceData, studentExamChart ,assignmentDetails }) => {

  const segments = [
    {
      title: "Exam Results",
      icon: "📋",
      component: <StudentExamDashboard studentFilterData={studentFilterData} studentExamData={studentExamData} studentExamChart={studentExamChart} />,
    },
    {
      title: "Attendance Rate",
      icon: "📊",
      component: <StudentAttendanceChart attendanceData={attendanceData} />,
    },
    {
      title: "Assignments",
      icon: "✏️", // Updated emoji
      component: <AssignmentList assignments={assignmentDetails} />,
    },
    
    /* {
      title: "Achievements",
      icon: "🏆",
      details: "Coming Soon...",
    }, */
  ];

  const [activeTab, setActiveTab] = useState(segments[0]);
  const [isFading, setIsFading] = useState(false);

  const handleTabChange = (segment) => {
    setIsFading(true);
    setTimeout(() => {
      setActiveTab(segment);
      setIsFading(false);
    }, 300);
  };

  // Styling
  const containerStyle = {
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
    width: "100%",
    margin: "0 auto",
    borderRadius: "15px",
    color: "#333",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };

  const stepContainerStyle = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    marginBottom: "20px",
  };

  const stepStyle = {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    fontSize: "14px",
    color: "#5a5a5a",
    fontWeight: "500",
    cursor: "pointer",
    transition: "all 0.3s ease",
    marginRight: "30px", // Spacing between steps
  };

  const stepCircle = {
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    backgroundColor: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "gray",
    fontSize: "18px",
    transition: "background-color 0.3s ease, color 0.3s ease",
    border: "2px solid #ddd",
    boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
  };

  const activeStepCircle = {
    ...stepCircle,
    backgroundColor: "#4D6D9A",
    color: "white"

  };

  const completedStepCircle = {
    ...stepCircle,
    backgroundColor: "#28a745",
  };

  const stepTextStyle = {
    marginTop: "10px",
    fontSize: "14px",
    fontWeight: "500",
    textAlign: "center",
  };

  const progressBarContainer = {
    width: "80%",
    height: "5px",
    backgroundColor: "#e0e0e0",
    borderRadius: "50px",
    position: "absolute",
    bottom: "10px",
  };

  const progressBar = {
    height: "100%",
    width: `${(segments.indexOf(activeTab) + 1) / segments.length * 100}%`,
    backgroundColor: "#007bff",
    borderRadius: "50px",
    transition: "width 0.3s ease",
  };

  const tabContentStyle = {
    padding: "30px",
    background: "#fff",
    borderRadius: "12px",
    boxShadow: "0 10px 30px rgba(0, 0, 0, 0.1)",
    position: "relative",
    opacity: isFading ? 0 : 1,
    transition: "opacity 0.3s ease",
    color: "#333",
    fontSize: "16px",
    textAlign: "left",
    width: "100%",
  };

  return (
    <div style={containerStyle}>
      {/* Step Indicators */}
      <div style={stepContainerStyle}>
        {segments.map((segment, index) => {
          let stepIndicatorStyle = stepCircle;
          if (activeTab.title === segment.title) {
            stepIndicatorStyle = activeStepCircle;
          } else if (segments.indexOf(activeTab) > index) {
            stepIndicatorStyle = completedStepCircle;
          }

          return (
            <div
              key={index}
              style={stepStyle}
              onClick={() => handleTabChange(segment)}
            >
              <div style={stepIndicatorStyle}>
                {segment.icon}
              </div>

              <div style={stepTextStyle}>{segment.title}</div>
            </div>
          );
        })}
      </div>

     

      {/* Tab Content */}
      <div style={tabContentStyle}>
        <h3 style={{ fontSize: "22px", fontWeight: "600", marginBottom: "15px",color:"#4D6D9A",borderBottom:"0.2px solid #4D6D9A",display:"inline-block" }}>
          {activeTab.title}
        </h3>
        {activeTab.component || <p>{activeTab.details}</p>}
      </div>
    </div>
  );
};

export default Header;
