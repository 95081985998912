import React from 'react';
import View from 'app/components/atoms/modalView/Modalview';
import CustomTable from 'app/components/atoms/custom-table/CustomTable';
import FormLayout from 'app/components/render/formlayout';
import SimpleCard from 'app/components/atoms/simple-card/SimpleCard';
import { FiEdit, FiTrash2 } from "react-icons/fi";
import { Button } from 'react-bootstrap';
import _ from "lodash";
import formRender from "app/components/render/formcomponent";
import { getDepartment } from "app/api/Department";
import { dateConversion, pagePermission, showMessage, timeConversion } from "app/utils/app-functions";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { createEmployeeAttendance, getEmployeeAttendance, getEmployeeAttendanceDetails, updateEmployeeAttendance } from "app/api/EmployeeAttendanceApi";
import { getEmployee } from "app/api/EmployeeApi";
import { getAttendanceStatus } from "app/utils/utils";
import { getHolidays } from "app/api/HolidaysApi";
import { getShift } from "app/api/EmployeeShiftApi";
import { employeeAttendanceForm, employeeAttendanceFilterForm } from "./formData";
import moment from 'moment';
import { asyncGetDepartmentInfo } from 'app/api/Department';
import Alert from 'app/components/atoms/alert';
import { asyncGetShiftInfo } from 'app/api/EmployeeShiftApi';
import noProfile from "app/pages/images/no_profile.png";
import { useSidebar } from "app/components/sidebar/sidebarContext";
import { lightTheme } from 'styles/theme';
import Loading from 'app/components/atoms/loading';
import { asyncGetEmployeeInfo } from 'app/api/EmployeeApi';

const ExcelJS = require('exceljs');

let editData = false;
let searchData = false;

const EmployeeAttendance = ({ navigation }) => {

    const dispatch = useDispatch();
    const customerFormRef = useRef();

    const getEmployeeAttendanceSuccess = useSelector((state) => state.employeeAttendanceReducer.getEmployeeAttendanceSuccess);
    const getEmployeeAttendanceList = useSelector((state) => state.employeeAttendanceReducer.getEmployeeAttendanceList);
    const getEmployeeAttendanceFailure = useSelector((state) => state.employeeAttendanceReducer.getEmployeeAttendanceFailure);

    const getEmployeeAttendanceDetailsSuccess = useSelector((state) => state.employeeAttendanceReducer.getEmployeeAttendanceDetailsSuccess);
    const getEmployeeAttendanceDetailsList = useSelector((state) => state.employeeAttendanceReducer.getEmployeeAttendanceDetailsList);
    const getEmployeeAttendanceDetailsFailure = useSelector((state) => state.employeeAttendanceReducer.getEmployeeAttendanceDetailsFailure);

    const getDepartmentSuccess = useSelector((state) => state.departmentReducer.getDepartmentSuccess);
    const getDepartmentList = useSelector((state) => state.departmentReducer.getDepartmentList);
    const getDepartmentFailure = useSelector((state) => state.departmentReducer.getDepartmentFailure);
    const departmentErrorMessage = useSelector((state) => state.departmentReducer.errorMessage);

    const getHolidaysSuccess = useSelector((state) => state.holidaysReducer.getHolidaysSuccess);
    const getHolidaysList = useSelector((state) => state.holidaysReducer.getHolidaysList);
    const getHolidaysFailure = useSelector((state) => state.holidaysReducer.getHolidaysFailure);
    const holidaysErrorMessage = useSelector((state) => state.holidaysReducer.errorMessage);

    const getShiftSuccess = useSelector((state) => state.shiftReducer.getShiftSuccess);
    const getShiftList = useSelector((state) => state.shiftReducer.getShiftList);
    const getShiftFailure = useSelector((state) => state.shiftReducer.getShiftFailure);
    const shiftErrorMessage = useSelector((state) => state.shiftReducer.errorMessage);

    const createEmployeeAttendanceSuccess = useSelector((state) => state.employeeAttendanceReducer.createEmployeeAttendanceSuccess);
    const createEmployeeAttendanceData = useSelector((state) => state.employeeAttendanceReducer.createEmployeeAttendanceData);
    const createEmployeeAttendanceFailure = useSelector((state) => state.employeeAttendanceReducer.createEmployeeAttendanceFailure);

    const updateEmployeeAttendanceSuccess = useSelector((state) => state.employeeAttendanceReducer.updateEmployeeAttendanceSuccess);
    const updateEmployeeAttendanceData = useSelector((state) => state.employeeAttendanceReducer.updateEmployeeAttendanceData);
    const updateEmployeeAttendanceFailure = useSelector((state) => state.employeeAttendanceReducer.updateEmployeeAttendanceFailure);

    const employeeAttendanceErrorMessage = useSelector((state) => state.employeeAttendanceReducer.errorMessage);

    const getEmployeeSuccess = useSelector((state) => state.employeeReducer.getEmployeeSuccess);
    const getEmployeeList = useSelector((state) => state.employeeReducer.getEmployeeList);
    const getEmployeeFailure = useSelector((state) => state.employeeReducer.getEmployeeFailure);
    const employeeErrorMessage = useSelector((state) => state.employeeReducer.errorMessage);

    const [pageItems, setpageItems] = useState({}
    );

    useEffect(() => {
        let data = pagePermission(pageItems, "Employee", "Attendance")
        setpageItems(data)

    }, [navigation]);

    const column = [
        {
            "dataField": "attendanceStatus",
            "text": "#",
            align: "center",
            // sort: true,
            formatter: (cellContent, row, index, extraData) => {
                let status = (row?.status == 2 && row?.status != null )? true : false;
                if (row?.attenancereport?.checkOutDate != null) {
                    status = true;
                }

                const conInfo = { inputType: "checkbox", status: cellContent, disabled: status };

                return (
                    <div className="icon-container">
                        {formRender(extraData, null, conInfo, null, null, statusChange, index)}
                    </div>
                );
            }

        },
        {
            "dataField": "employeeName",
            "text": "Employee Name",
            "sort": true
        },
        {
            "dataField": "departmentName",
            "text": "Department Name",
            "sort": true
        },

        {
            dataField: "checkOut",
            text: "Status",
            sort: true,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => {
                let status = row.status === 2 ? true : false;
                if (status === true) {
                    const conInfo = { inputType: "checkInStatus", statusName: "On Leave" }
                    return formRender(null, null, conInfo, null);
                } else {
                    const conInfo = { inputType: "checkInStatus", statusName: getAttendanceStatus(row.attenancereport), status: cellContent }
                    return formRender(null, null, conInfo, null);
                }
            }
        },
    ]

    if ((pageItems.create /* || pageItems.updateOption */)) {
        column.unshift(/* {
            "dataField": "attendanceStatus",
            "text": "#",
            "sort": true,
            formatter: (cellContent, row, index, extraData) => {
                let status = row.status === 1 ? true : false;
                if (row?.attenancereport?.checkOutDate != null) {
                    return status = true;
                }
                const conInfo = { inputType: "checkbox", status: cellContent, disable: status }
                return formRender(extraData, null, conInfo, null, null, statusChange, index);
            }
        } */)
    }

    const [state, setState] = useState({
        departmentList: [],
        employeePositionList: [],
        employeeAttendanceList: [],
        employeeList: [],
        holidaysList: [],
        shiftList: [],
        departmentId: "",
        attendanceDate: moment(dateConversion(new Date(), "YYYY-MM-DD")),
        employeeAttendanceDetailsList: [],
        profilePic: noProfile

    });

    const [createModule, setCreateModule] = useState(false);
    const [loaderModule, setLoaderModule] = useState(false);
    const [editModule, setEditModule] = useState(false);
    const [viewModule, setViewModule] = useState(false);
    const [deleteModule, setDeleteModule] = useState(false);
    const [dateModule, setDateModule] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [selectedItem, setSelectedItem] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);

    let userInfo = window.localStorage.getItem("userInfo");

    let parsedData = JSON.parse(userInfo);


    const listFetching = async () => {
        const req1 = {
            isActive: "true",
        };
        if (parsedData[0].roleId == 5 || parsedData[0].roleId == 4) {
            req1.empCode = parsedData[0]?.userCode || "";
        }
    
        const requestData = {
            isActive: "true",
        };
    
        let departmentList = await asyncGetDepartmentInfo(requestData);
        let shiftList = await asyncGetShiftInfo(requestData);
        let employeeList = await asyncGetEmployeeInfo(req1);
    
        let shiftId = "";
        let departmentId = "";
        console.log(parsedData[0].roleId);
        console.log(parsedData[0].roleId == 5 || parsedData[0].roleId == 4);

    console.log((parsedData[0].roleId == 5 || parsedData[0].roleId == 4) && employeeList.data.length);
    
        if ((parsedData[0].roleId == 5 || parsedData[0].roleId == 4) && employeeList.data.length) {
            
            const req = {
                shiftId: employeeList.data[0]?.shiftId || "",
                departmentId: employeeList.data[0]?.departmentId || "",
                isActive: true,
                attendanceDate: dateConversion(new Date(), "YYYY-MM-DD"),
            };
    
            shiftId = employeeList.data[0]?.shiftId || "";
            departmentId = employeeList.data[0]?.departmentId || "";
    
            req.empCode = parsedData[0]?.userCode || "";
            if((parsedData[0].roleId == 5 || parsedData[0].roleId == 4)){
                employeeAttendanceFilterForm[0].formBody[2].formField[0].disabled = true;
                employeeAttendanceFilterForm[0].formBody[0].formField[0].disabled = true;
            }
            else{
                employeeAttendanceFilterForm[0].formBody[2].formField[0].disabled = false;
                employeeAttendanceFilterForm[0].formBody[0].formField[0].disabled = false;
            }
            setLoaderModule(true);
            dispatch(getEmployeeAttendanceDetails(req));

            
        } else{
            employeeAttendanceFilterForm[0].formBody[2].formField[0].disabled = false;
            employeeAttendanceFilterForm[0].formBody[0].formField[0].disabled = false;
        }
    
        setState({
            ...state,
            departmentList: departmentList?.data || [],
            shiftList: shiftList?.data || [],
            employeeList: employeeList?.data || [],
            shiftId: shiftId,
            departmentId: departmentId,
        });
    };
    


    useEffect(() => {
        setLoaderModule(true)
        const req = {
            isActive: "true"
        }
        const req1 = {
            isActive: "true"
        }
        if (parsedData[0].roleId == 5 || parsedData[0].roleId == 4) {
            req1.empCode = parsedData[0]?.userCode || '';
        }
        // dispatch(getEmployeeAttendance())
        // dispatch(getDepartment(req))
        // dispatch(getEmployee(req1))
        dispatch(getHolidays(req))
        // dispatch(getShift(req))
        listFetching()
    }, [navigation]);

    useEffect(() => {
        if (getEmployeeSuccess) {
            // setItemList(getEmployeeList)
            setState({
                ...state,
                employeeList: getEmployeeList
            })
            if ((global.roleId == 5 || global.roleId == 4) && getEmployeeList.length) {
                employeeAttendanceFilterForm[0].formBody[2].formField[0].disable = true
                employeeAttendanceFilterForm[0].formBody[0].formField[0].disable = true
                const req = {
                    "shiftId": getEmployeeList[0]?.shiftId || "",
                    "departmentId": getEmployeeList[0]?.departmentId || "",
                    "isActive": true,
                    "attendanceDate": moment(dateConversion(new Date(), "YYYY-MM-DD"))
                }
                req.empCode = global.UserInfo?.userCode || '';
                setState({
                    ...state,
                    shiftId: getEmployeeList[0]?.shiftId || "",
                    departmentId: getEmployeeList[0]?.departmentId || "",
                    // attendanceDate:   dateConversion(new Date(), "YYYY-MM-DD")
                })

                setLoaderModule(true);
                dispatch(getEmployeeAttendanceDetails(req))

            }
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_EMPLOYEE" })
        } else if (getEmployeeFailure) {
            showMessage("warning", employeeErrorMessage?.message)
            setLoaderModule(false)
            setState({
                ...state,
                employeeList: []
            })
            setItemList([])
            dispatch({ type: "RESET_GET_EMPLOYEE" })
        }
    }, [getEmployeeSuccess, getEmployeeFailure]);

    useEffect(() => {
        if (getEmployeeAttendanceDetailsSuccess) {
            setState({
                ...state,
                employeeAttendanceDetailsList: getEmployeeAttendanceDetailsList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_EMPLOYEE_DETAILS_ATTENDANCE" })
        } else if (getEmployeeAttendanceDetailsFailure) {
            setState({
                ...state,
                employeeAttendanceDetailsList: []
            })
            showMessage("warning", employeeAttendanceErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_EMPLOYEE_DETAILS_ATTENDANCE" })
        }
    }, [getEmployeeAttendanceDetailsSuccess, getEmployeeAttendanceDetailsFailure]);

    useEffect(() => {
        if (getEmployeeAttendanceSuccess) {
            setState({
                ...state,
                employeeAttendanceList: getEmployeeAttendanceList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_EMPLOYEE_ATTENDANCE" })
        } else if (getEmployeeAttendanceFailure) {
            setState({
                ...state,
                employeeAttendanceList: []
            })
            showMessage("warning", employeeAttendanceErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_EMPLOYEE_ATTENDANCE" })
        }
    }, [getEmployeeAttendanceSuccess, getEmployeeAttendanceFailure]);

    useEffect(() => {
        if (getShiftSuccess) {
            setState({
                ...state,
                shiftList: getShiftList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_SHIFT" })
        } else if (getShiftFailure) {
            setState({
                ...state,
                shiftList: []
            })
            showMessage("warning", shiftErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_SHIFT" })
        }
    }, [getShiftSuccess, getShiftFailure]);

    useEffect(() => {
        if (getHolidaysSuccess) {
            setState({
                ...state,
                holidaysList: getHolidaysList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_HOLIDAYS" })
        } else if (getHolidaysFailure) {
            setState({
                ...state,
                holidaysList: []
            })
            showMessage("warning", holidaysErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_HOLIDAYS" })
        }
    }, [getHolidaysSuccess, getHolidaysFailure]);

    useEffect(() => {
        if (getDepartmentSuccess) {
            setState({
                ...state,
                departmentList: getDepartmentList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_DEPARTMENT" })
        } else if (getDepartmentFailure) {
            setState({
                ...state,
                departmentList: []
            })
            showMessage("warning", departmentErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_DEPARTMENT" })
        }
    }, [getDepartmentSuccess, getDepartmentFailure]);

    useEffect(() => {
        if (createEmployeeAttendanceSuccess) {
            const temp_state = [...itemList, createEmployeeAttendanceData[0]];
            setState({
                ...state,
                employeeAttendanceDetailsList: [],
                shiftId: "",
                departmentId: "",
            })
            setItemList(temp_state)
            setLoaderModule(false)
            closeModule()
            dispatch({ type: "RESET_CREATE_EMPLOYEE_ATTENDANCE" })
        } else if (createEmployeeAttendanceFailure) {
            setLoaderModule(false)
            showMessage("warning", employeeAttendanceErrorMessage?.message)
            dispatch({ type: "RESET_CREATE_EMPLOYEE_ATTENDANCE" })
        }
    }, [createEmployeeAttendanceSuccess, createEmployeeAttendanceFailure]);


    useEffect(() => {
        if (updateEmployeeAttendanceSuccess) {
            setState({
                ...state,
                employeeAttendanceDetailsList: [],
                shiftId: "",
                departmentId: "",
            })
            updateTable(updateEmployeeAttendanceData[0])
            dispatch({ type: "RESET_UPDATE_EMPLOYEE_ATTENDANCE" })
        } else if (updateEmployeeAttendanceFailure) {
            setLoaderModule(false)
            showMessage("warning", employeeAttendanceErrorMessage?.message)
            dispatch({ type: "RESET_UPDATE_EMPLOYEE_ATTENDANCE" })
        }
    }, [updateEmployeeAttendanceSuccess, updateEmployeeAttendanceFailure]);

    const updateTable = (updatedItem) => {
        const temp_state = [...itemList];
        if (deleteModule) {
            let remainingList = _.remove(temp_state, function (n) {
                return n.employeeAttendanceId != updatedItem.employeeAttendanceId;
            });
            showMessage("success", "Deleted Successfully")
            setItemList(remainingList)
        } else {
            temp_state[selectedIndex] = updatedItem;
            setItemList(temp_state)
        }
        setLoaderModule(false)
        closeModule()
    }

    const closeModule = () => {
        editData = false;
        setCreateModule(false)
        setDeleteModule(false)
    }

    const statusChange = (event, index, extraData) => {
        // if (pageItems.create && pageItems.updateOption) {
        let items = [...state.employeeAttendanceDetailsList]
        if (items[index].status === 2) {
            Alert({ description: 'This Employee is on Leave.', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });

            return false;
        }
        items[index].attendanceStatus = event.target.checked
        setState({
            ...state,
            employeeAttendanceDetailsList: items
        })
        // }
    }

    const onSearch = () => {
        if (state.attendanceDate < 0 || state.shiftId <= 0 || state.shiftId == undefined || state.departmentId == "" || state.departmentId == null || state.departmentId == undefined) {
            Alert({ description: 'Please Select Shift Timing And Department.', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setState({
                ...state,
                employeeAttendanceDetailsList: []
            })
        } else if (state.attendanceDate == null) {
            Alert({ description: 'Please Select Date.', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setState({
                ...state,
                employeeAttendanceDetailsList: []
            })
        }
        else {
            const req = {
                "shiftId": state?.shiftId || "",
                "departmentId": state?.departmentId || "",
                "isActive": true,
                "attendanceDate": state.attendanceDate ? dateConversion(state.attendanceDate, "YYYY-MM-DD") : ""
            }
            if (parsedData?.[0]?.roleId == 5 || parsedData?.[0]?.roleId == 4 || parsedData?.[0]?.roleId == 6) {
                req.empCode = parsedData?.[0]?.userCode || ''
            }

            setLoaderModule(true);
            dispatch(getEmployeeAttendanceDetails(req))
        }
    }

    const resetState = () => {
        setState({
            ...state,
            employeeAttendanceName: "",
            description: ""
        })
    }

    const onSelectAttendanceDate = (date, name) => {
        let selectedDate = date
        let selectedDateConverstion = dateConversion(date, "YYYY-MM-DD")
        let resDate = "";
        state.holidaysList.map((item) => {
            if (item.holidayDate == selectedDateConverstion) {
                resDate = true
            }
        })
        if (resDate) {
            setDateModule(true)
        }
        setState({
            ...state,
            attendanceDate: selectedDate,
        })
    }

    const onCreateForm = () => {
        resetState()
        editData = false;
        setCreateModule(true)
    }

    const onCheckIn = (name) => {
        const temp_state = state.employeeAttendanceDetailsList
        if (temp_state.length) {

            let filterAttendance = _.filter(temp_state, function (n) {
                return (n?.attendanceStatus || false) == true
            })
            if (filterAttendance.length) {
                const tempArr = [];
                let temp_var = true

                filterAttendance.map((item) => {
                    if (item?.attenancereport?.checkIn && item?.attenancereport?.checkInDate === dateConversion(new Date(), "YYYY-MM-DD")) {
                        temp_var = false;
                    }
                })

                if (name === "checkIn" && temp_var === true) {
                    filterAttendance.map((itm) => {
                        const req = {
                            checkInTime: timeConversion(new Date(), "hh:mm:ss"),
                            checkInDate: dateConversion(state.attendanceDate, "YYYY-MM-DD"),
                            employeeId: itm.employeeId,
                            shiftId: state.shiftId
                        }
                        tempArr.push(req)
                    })
                    setLoaderModule(true);
                    dispatch(createEmployeeAttendance(tempArr))
                    Alert({ description: 'Checked In Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });

                } else if (name === "checkOut") {
                    let temp_var = true
                    filterAttendance.map((item) => {
                        if (item?.attenancereport?.checkIn || false) {
                            return temp_var = temp_var
                        } else {
                            return temp_var = false
                        }
                    })
                    if (temp_var === false) {
                        showMessage("warning", "Please CheckIn The Employee")
                        Alert({ description: 'Please CheckIn The Employee.', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });

                    } else {
                        filterAttendance.map((itm, idx) => {
                            const req = {
                                empAttendanceId: itm.attenancereport.empAttendanceId,
                                checkOutTime: timeConversion(new Date(), "hh:mm:ss"),
                                checkOutDate: dateConversion(state.attendanceDate, "YYYY-MM-DD"),
                                employeeId: itm.employeeId,
                                shiftId: state.shiftId
                            }
                            tempArr.push(req)
                        })
                        setLoaderModule(true);
                        dispatch(updateEmployeeAttendance(tempArr))
                        showMessage("success", "Checked Out Successfully")
                        Alert({ description: 'Checked Out Successfully.', type: 'success', placement: 'topRight', duration: 3 });
                    }
                } else {
                    showMessage("warning", "Employee Is Already Checked-In")
                    Alert({ description: 'Employee Is Already Checked-In.', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
                }
            }
            else {
                showMessage("warning", "Please Select Employee")
                Alert({ description: 'Please Select Employee.', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            }
        } else {
            showMessage("warning", "Please Select Employee details")
            Alert({ description: 'Please Select Employee details', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
        }
    }

    const onEditForm = (data, index) => {
        setState({
            ...state,
            employeeAttendanceName: data?.employeeAttendanceName || "",
            description: data?.description || ""
        })
        editData = true;
        setSelectedIndex(index)
        setSelectedItem(data)
        setCreateModule(true)
    }

    const onClear = () => {
        setState({
            ...state,
            departmentId: "",
            shiftId: "",
            employeeAttendanceDetailsList: [],
            //   attendanceTypeId: "",
            attendanceDate: ''
        })
        // dispatch(getEmployee())
    }

    const onCheckDate = () => {
        showMessage("warning", "Your Putting Attendance For Holiday")
        setDateModule(false)
    }

    const onDeleteItem = (data, index) => {
        editData = false;
        setSelectedIndex(index)
        setSelectedItem(data)
        setDeleteModule(true)
    }

    const validateForm = async () => {
        const validatefom = customerFormRef.current.validateForm();
    }

    const onSubmitForm = () => {
        const requestDate = {
            employeeAttendanceName: employeeAttendanceName,
            description: description
        }
        if (editData) {
            setLoaderModule(true)
            dispatch(updateEmployeeAttendance(requestDate, selectedItem.employeeAttendanceId))
        } else if (deleteModule) {
            const deleteRequest = {
                isActive: "false"
            }
            setLoaderModule(true)
            dispatch(updateEmployeeAttendance(deleteRequest, selectedItem.employeeAttendanceId))
        } else {
            setLoaderModule(true)
            dispatch(createEmployeeAttendance(requestDate))
        }
    }

    const {
        employeeAttendanceName,
        description
    } = state;

    const modelTitle = editData ? "Edit " : "Create ";
    const modelButtonLbl = editData ? "" : "Save";
    const handleClick = (buttonName) => {
        if (buttonName == 'CheckIn') {
            onCheckIn("checkIn")
        }
        else if (buttonName == 'CheckOut') {
            onCheckIn("checkOut")
        }
    };
    const { sidebarOpen } = useSidebar();

    return (
        <div style={sidebarOpen ? { marginLeft: "10px", marginTop: "-10px" } : { marginLeft: "10px", marginTop: "-10px" }}>
            <Loading status={loaderModule} />
            <View size="md" isOpen={createModule} onClose={setCreateModule} onSubmit={validateForm} title={`${modelTitle} Employee Leave`}>
                {/* <DynamicCheckBox options={options} onSelectionChange={handleSelectionChange} /> */}
                <FormLayout ref={customerFormRef} defaultState={state} setDefaultState={setState} dynamicForm={employeeAttendanceForm} noOfColumn={1} onSubmit={onSubmitForm} />

            </View>
            <View size="md" isOpen={deleteModule} onClose={setDeleteModule} onSubmit={validateForm} title="Delete Permannent">
                <h6 className="text-capitalize mb-1">Are you sure ?</h6>

            </View>
            <div style={sidebarOpen ? { marginLeft: "50px", marginTop: "50px", marginBottom: "-20px" } : { marginLeft: "90px", marginTop: "50px", marginBottom: "-50px" }}>
                <FormLayout defaultState={state} setDefaultState={setState} onChangeCallback={{ "onSelectAttendanceDate": onSelectAttendanceDate }} dynamicForm={employeeAttendanceFilterForm} noOfColumn={4} onSubmit={onSubmitForm} />
                <Button onClick={onSearch} className='mr-2' style={{ backgroundColor: lightTheme.primaryColor, border: "none", fontSize: "12px", marginTop: "-20px" }}>Search</Button>
                <Button onClick={onClear} style={{ backgroundColor: "white", color: "gray", border: "1px solid gray", fontSize: "12px", marginTop: "-20px" }}>Clear</Button>
            </div>




            <SimpleCard >
                {/* <div className="d-flex justify-content-end" style={{ marginTop: "-20px"  ,marginBottom:"-25px" }}>
                    <div >
                        {pageItems.create ? <Button style={{ backgroundColor: "rgb(147,112,219)", border: "none",fontSize:"12px", marginBottom:"-10px"}} onClick={() => onCheckIn("checkIn")} className="mx-1">CheckIn</Button> : ""}
                        {pageItems.create ? <Button style={{ backgroundColor: "white", color: "gray", border: "1px solid gray",fontSize:"12px" ,marginBottom:"-10px"}} onClick={() => onCheckIn("checkOut")} className="mx-1">CheckOut</Button> : ""}
                    </div>
                </div> */}

                <CustomTable columns={column} handleClick={handleClick} data={state?.employeeAttendanceDetailsList || []} tableName={"Employee Attendance"} buttonData={pageItems.create ? ['checkOut', 'checkIn'] : []} state={state} />
            </SimpleCard>


        </div>
    )
}

export default EmployeeAttendance;