import { returnApiCallPromise } from './ApiContent';
import { enquiry, examList } from './APIContainer';

//GET
export function getEnquiry(request) {
  const requestObj = request ? { params: request } : "";
  return (dispatch) => {
    returnApiCallPromise("GET", enquiry, requestObj)
      .then(response => {
        dispatch({
          type: 'GET_ENQUIRY_SUCCESS',
          payload: response.data
        })
      }).catch(error => {

        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'GET_ENQUIRY_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}
//GET enquiry LIST
export function getEnquiryListData(request) {
  const requestObj = request ? { params: request } : "";
  return (dispatch) => {
    returnApiCallPromise("GET", examList, requestObj)
      .then(response => {
        dispatch({
          type: 'GET_ENQUIRY_LIST_SUCCESS',
          payload: response.data
        })
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'GET_ENQUIRY_LIST_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

//GET
export function deleteEnquiryDetails(request) {
  const requestObj = request ? { params: request } : "";
  return (dispatch) => {
    returnApiCallPromise("DELETE", enquiry, requestObj)
      .then(response => {
        dispatch({
          type: 'DELETE_ENQUIRY_DETAILS_SUCCESS',
          payload: response.data
        })
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'DELETE_ENQUIRY_DETAILS_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

//POST
export function createEnquiry(request) {

  return (dispatch) => {
    returnApiCallPromise("POST", enquiry, request)
      .then(response => {
        if (response.data.error) {
          dispatch({
            type: 'CREATE_ENQUIRY_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'CREATE_ENQUIRY_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'CREATE_ENQUIRY_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

export function updateEnquiry(request, enquiryId) {
  return (dispatch) => {
    returnApiCallPromise("PUT", enquiry + "/" + enquiryId, request)
      .then(response => {
        if (response.data.error) {
          dispatch({
            type: 'UPDATE_ENQUIRY_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'UPDATE_ENQUIRY_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'UPDATE_ENQUIRY_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}