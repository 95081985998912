import * as yup from "yup";
import { Formik } from "formik";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import DateTime from "react-datetime";
import { Form, Button } from "react-bootstrap";
import { DateRange } from "react-date-range";
import SimpleCard from 'app/components/atoms/simple-card/SimpleCard'
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { MdEdit, MdDelete } from "react-icons/md";
import swal from "sweetalert2";
import _ from "lodash";
import { pagePermission, showMessage } from "app/utils/app-functions";
import FormLayout from "app/components/render/formlayout";
import View from "app/components/atoms/modalView/Modalview";
import CustomTable from "app/components/atoms/custom-table/CustomTable";
import formRender from "app/components/render/formcomponent";
import { subjectFilterForm, subjectTeachersForm } from "./formData";
import { createSubjectTeachers, getSubjectTeachers, updateSubjectTeachers } from "app/api/SubjectTeachersApi";
import { getBatch, asyncGetBatchInfo } from "app/api/BatchApi";
import { getEnrollmentDetails, asyncGetEnrollmenetDetailsInfo } from "app/api/EnrollementDetailsApi";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import Alert from "app/components/atoms/alert";
import { getAsyncStudentClassList } from "app/api/StudentListApi";
import { getSubject, getAsyncSubject } from "app/api/SubjectApi";
import { getEmployee, asyncGetEmployeeInfo } from "app/api/EmployeeApi";
import FilterComponent from 'app/components/FilterComponent';
import { useSidebar } from "app/components/sidebar/sidebarContext";

let editData = false;

const SubjectTeachers = ({ navigation }) => {

    const dispatch = useDispatch();
    const customerFormRef = useRef();

    const getSubjectTeachersSuccess = useSelector((state) => state.subjectTeachersReducer.getSubjectTeachersSuccess);
    const getSubjectTeachersList = useSelector((state) => state.subjectTeachersReducer.getSubjectTeachersList);
    const getSubjectTeachersFailure = useSelector((state) => state.subjectTeachersReducer.getSubjectTeachersFailure);

    const getBatchSuccess = useSelector((state) => state.batchReducer.getBatchSuccess);
    const getBatchList = useSelector((state) => state.batchReducer.getBatchList);
    const getBatchFailure = useSelector((state) => state.batchReducer.getBatchFailure);
    const batchErrorMessage = useSelector((state) => state.batchReducer.errorMessage);

    const getEmployeeSuccess = useSelector((state) => state.employeeReducer.getEmployeeSuccess);
    const getEmployeeList = useSelector((state) => state.employeeReducer.getEmployeeList);
    const getEmployeeFailure = useSelector((state) => state.employeeReducer.getEmployeeFailure);
    const employeeErrorMessage = useSelector((state) => state.employeeReducer.errorMessage);

    const getSubjectSuccess = useSelector((state) => state.subjectReducer.getSubjectSuccess);
    const getSubjectList = useSelector((state) => state.subjectReducer.getSubjectList);
    const getSubjectFailure = useSelector((state) => state.subjectReducer.getSubjectFailure);
    const subjectErrorMessage = useSelector((state) => state.subjectReducer.errorMessage);

    const getEnrollmentDetailsSuccess = useSelector((state) => state.enrollmentDetailsReducer.getEnrollmentDetailsSuccess);
    const getEnrollmentDetailsList = useSelector((state) => state.enrollmentDetailsReducer.getEnrollmentDetailsList);
    const getEnrollmentDetailsFailure = useSelector((state) => state.enrollmentDetailsReducer.getEnrollmentDetailsFailure);
    const enrollmentDetailsErrorMessage = useSelector((state) => state.enrollmentDetailsReducer.errorMessage);

    const createSubjectTeachersSuccess = useSelector((state) => state.subjectTeachersReducer.createSubjectTeachersSuccess);
    const createSubjectTeachersData = useSelector((state) => state.subjectTeachersReducer.createSubjectTeachersData);
    const createSubjectTeachersFailure = useSelector((state) => state.subjectTeachersReducer.createSubjectTeachersFailure);

    const updateSubjectTeachersSuccess = useSelector((state) => state.subjectTeachersReducer.updateSubjectTeachersSuccess);
    const updateSubjectTeachersData = useSelector((state) => state.subjectTeachersReducer.updateSubjectTeachersData);
    const updateSubjectTeachersFailure = useSelector((state) => state.subjectTeachersReducer.updateSubjectTeachersFailure);

    const subjectTeachersErrorMessage = useSelector((state) => state.subjectTeachersReducer.errorMessage);


    const [pageItems, setpageItems] = useState({});

    useEffect(() => {
        let data = pagePermission(pageItems, "Masters", "Subject Teachers")
        setpageItems(data)

    }, [navigation]);

    const column = [
        {
            "dataField": "index",
            "text": "S.No",
            "sort": true,
            formatter: (cellContent, row, index) => {
                return index + 1
            }
        },
        {
            "dataField": "employeeName",
            "text": "Staff Name",
            "sort": true
        },
        {
            "dataField": "",
            "text": "Class-Section",
            "sort": true,
            formatter: (cellContent, row, index) => (
                row.classList.map((item, idx) => {
                    return idx === 0 ? item.className + " - " + item.sectionName : " , " + item.className + " - " + item.sectionName
                })
            )
        },
        {
            "dataField": "",
            "text": "Subjects",
            "sort": true,
            formatter: (cellContent, row, index) => (
                (row?.subjectList || []).map((item, idx) => {
                    return idx === 0 ? item.subjectName : " , " + item.subjectName
                })
            )
        },
        {
            dataField: "isActive",
            text: "Status",
            sort: true,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => {
                const conInfo = { inputType: "status", status: cellContent }
                return formRender(null, null, conInfo, null);
            }
        },
        /* {
            text: "Action",
            sort: false,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => (
                <div >
                    <MdEdit
                        className="text-success cursor-pointer"
                        size={18}
                        onClick={() => onEditForm(row, index)}
                    ></MdEdit>
                    <MdDelete
                        className="text-danger cursor-pointer"
                        size={18}
                        onClick={() => {
                            swal
                                .fire({
                                    title: "Are you sure?",
                                    text: "You won't be able to revert this!",
                                    icon: "warning",
                                    type: "question",
                                    showCancelButton: true,
                                    confirmButtonColor: "#3085d6",
                                    cancelButtonColor: "#d33",
                                    confirmButtonText: "Yes, delete it!",
                                    cancelButtonText: "No",
                                })
                                .then((result) => {
                                    if (result.value) {
                                        onDeleteItem(row, index);
                                    } else {
                                        swal.fire({
                                            title: "Cancelled!",
                                            text: "Permission denied.",
                                            type: "error",
                                            icon: "error",
                                            timer: 1500,
                                        });
                                    }
                                });
                        }}
                    ></MdDelete>
                </div>
            )
        }, */
    ]

    if (pageItems.delete || pageItems.update) {
        column.push({
            text: "Action",
            sort: false,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => (
                <div className="icon-container">
                    {pageItems.update && <FiEdit
                        className="text-primary cursor-pointer"
                        size={18}
                        onClick={() => onEditForm(row, index)}
                    ></FiEdit>}
                    {pageItems.delete && <FiTrash2
                        className="text-danger cursor-pointer"
                        size={18}
                        onClick={() => {
                            swal
                                .fire({
                                    title: "Are you sure?",
                                    text: "You won't be able to revert this!",
                                    icon: "warning",
                                    type: "question",
                                    showCancelButton: true,
                                    confirmButtonColor: "#3085d6",
                                    cancelButtonColor: "#d33",
                                    confirmButtonText: "Yes, delete it!",
                                    cancelButtonText: "No",
                                })
                                .then((result) => {
                                    if (result.value) {

                                        onDeleteItem(row, index);
                                    } else {
                                        swal.fire({
                                            title: "Cancelled!",
                                            text: "Permission denied.",
                                            type: "error",
                                            icon: "error",
                                            timer: 1500,
                                        });
                                    }
                                });
                        }}
                    ></FiTrash2>}
                </div>
            )
        })
    }
    const [state, setState] = useState({
        subjectTeachersName: "",
    });

    const [createModule, setCreateModule] = useState(false);
    const [loaderModule, setLoaderModule] = useState(false);
    const [editModule, setEditModule] = useState(false);
    const [viewModule, setViewModule] = useState(false);
    const [deleteModule, setDeleteModule] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [selectedItem, setSelectedItem] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);

    useEffect(() => {
        setLoaderModule(true)
        const requestData = {
            isActive: "true"
        }
        /*  dispatch(getBatch(requestData))
         dispatch(getEmployee(requestData))
         dispatch(getSubject(requestData)) */
        //  dispatch(getEnrollmentDetails(requestData))

        listFetching()
    }, [navigation]);

    // let userInfo = window.localStorage.getItem("userInfo");
    // let parsedData = JSON.parse(userInfo);

    const listFetching = async () => {
        const requestData = {
            isActive: "true",
        }
        const req = {
            isActive: "true",
        };
        const reqq = {
            examVariantId: 1
        };
        /*  if (parsedData?.[0]?.roleId == 5) {
             req.empCode = parsedData?.[0]?.userCode || ''
         } */

        let batchList = await asyncGetBatchInfo(requestData)
        let employeeList = await asyncGetEmployeeInfo(requestData)
        let enrollData = await asyncGetEnrollmenetDetailsInfo(req);
        let subjectList = await getAsyncSubject(requestData)

        setState({
            ...state,
            enrollmentDetailsList: enrollData?.data || [],
            batchList: batchList?.data || [],
            employeeList: employeeList?.data || [],
            subjectList: subjectList?.data || [],
        })

    }

    useEffect(() => {
        if (getSubjectTeachersSuccess) {
            if (getSubjectTeachersList.length <= 0) {
                showMessage("warning", "Data Not Founded...!")
            }
            setItemList(getSubjectTeachersList)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_SUBJECT_TEACHERS" })
        } else if (getSubjectTeachersFailure) {
            setItemList([])
            setLoaderModule(false)
            showMessage("warning", subjectTeachersErrorMessage?.message)
            dispatch({ type: "RESET_GET_SUBJECT_TEACHERS" })
        }
    }, [getSubjectTeachersSuccess, getSubjectTeachersFailure, getSubjectTeachersList]);

    useEffect(() => {
        if (getEnrollmentDetailsSuccess) {
            setState({
                ...state,
                enrollmentDetailsList: getEnrollmentDetailsList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_ENROLLMENT_DETAILS" })
        } else if (getEnrollmentDetailsFailure) {
            setState({
                ...state,
                enrollmentDetailsList: []
            })
            showMessage("warning", enrollmentDetailsErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_ENROLLMENT_DETAILS" })
        }
    }, [getEnrollmentDetailsSuccess, getEnrollmentDetailsFailure]);

    useEffect(() => {
        if (getBatchSuccess) {
            setState({
                ...state,
                batchList: getBatchList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_BATCH" })
        } else if (getBatchFailure) {
            setState({
                ...state,
                batchList: []
            })
            showMessage("warning", batchErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_BATCH" })
        }
    }, [getBatchSuccess, getBatchFailure]);

    useEffect(() => {
        if (getEmployeeSuccess) {
            setState({
                ...state,
                employeeList: getEmployeeList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_EMPLOYEE" })
        } else if (getEmployeeFailure) {
            setState({
                ...state,
                employeeList: []
            })
            showMessage("warning", employeeErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_EMPLOYEE" })
        }
    }, [getEmployeeSuccess, getEmployeeFailure]);

    useEffect(() => {
        if (getSubjectSuccess) {
            setState({
                ...state,
                subjectList: getSubjectList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_SUBJECT" })
        } else if (getSubjectFailure) {
            setState({
                ...state,
                subjectList: []
            })
            showMessage("warning", subjectErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_SUBJECT" })
        }
    }, [getSubjectSuccess, getSubjectFailure]);

    useEffect(() => {
        if (createSubjectTeachersSuccess) {
            const temp_state = [...itemList, createSubjectTeachersData[0]];
            setItemList(temp_state)
            closeModule()
            setLoaderModule(false)
            Alert({ description: 'Created Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            dispatch({ type: "RESET_CREATE_SUBJECT_TEACHERS" })
        } else if (createSubjectTeachersFailure) {
            setLoaderModule(false)
            showMessage("warning", subjectTeachersErrorMessage)
            Alert({ description: subjectTeachersErrorMessage, type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });

            dispatch({ type: "RESET_CREATE_SUBJECT_TEACHERS" })
        }
    }, [createSubjectTeachersSuccess, createSubjectTeachersFailure]);

    useEffect(() => {
        if (updateSubjectTeachersSuccess) {
            const requestData = {
                isActive: "true"
            }

            dispatch(getSubject(requestData))
            updateTable(updateSubjectTeachersData[0])
            dispatch({ type: "RESET_UPDATE_SUBJECT_TEACHERS" })
        } else if (updateSubjectTeachersFailure) {
            setLoaderModule(false)
            showMessage("warning", subjectTeachersErrorMessage)
            Alert({ description: subjectTeachersErrorMessage, type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });

            dispatch({ type: "RESET_UPDATE_SUBJECT_TEACHERS" })
        }
    }, [updateSubjectTeachersSuccess, updateSubjectTeachersFailure]);

    const updateTable = (updatedItem) => {
        const temp_state = [...itemList];
        if (deleteModule) {

            let remainingList = _.remove(temp_state, function (n) {
                return n.subjectTeacherId != updatedItem.subjectTeacherId;
            });
            Alert({ description: 'Deleted Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(remainingList)
        } else {
            temp_state[selectedIndex] = updatedItem;
            Alert({ description: 'Updated Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(temp_state)
        }
        setLoaderModule(false)
        closeModule()
    }

    const closeModule = () => {
        editData = false;
        setCreateModule(false)
        setDeleteModule(false)
    }

    const resetState = () => {
        setState({
            ...state,
            employeeId: "",
            batchId: "",
            enrollmentId: "",
            subjectId: "",
        })
    }

    const onCreateForm = () => {
        resetState()
        editData = false;
        setCreateModule(true)
    }

    const onEditForm = (data, index) => {
        const selectedIdsEnroll = state?.enrollmentDetailsList || []
        const selectedIdsSub = state?.subjectList || []
        const enrollmentIdsData = data?.enrollmentIds.split(',').map(Number) || []
        const subjectIdsData = data?.subjectIds.split(',').map(Number) || []

        // const temp_enrollment_arr = selectedIdsEnroll.filter(obj => enrollmentIdsData.includes(obj.enrollmentId.toString()));
        // const temp_subject_arr = selectedIdsSub.filter(obj => subjectIdsData.includes(obj.subjectId.toString()));

        setState({
            ...state,
            employeeId: data?.employeeId || "",
            classTeacher: data.classTeacher,
            batchId: data?.batchId || "",
            enrollmentId: enrollmentIdsData,
            subjectId: subjectIdsData,
        })
        editData = true;
        setSelectedIndex(index)
        setSelectedItem(data)
        setCreateModule(true)
    }

    const onDeleteItem = (data, index) => {
        editData = false;
        setSelectedIndex(index)
        setSelectedItem(data)
        setDeleteModule(true)
    }

    const onSearch = () => {
        if (state.addEmployeeId) {

            const selectedEmpId = state?.addEmployeeId || "";

            if (!state.addBatchId || !selectedEmpId) {
                Alert({ description: 'Please fill in all the required fields.', type: 'warning', placement: 'topRight', duration: 3, });
                return;
            }

            const requestData = {
                isActive: "true",
                batchId: state.addBatchId,
                employeeId: selectedEmpId
            };

            setLoaderModule(true);
            dispatch(getSubjectTeachers(requestData));

        } else {
            setItemList([])
            Alert({ description: 'No employees were chosen..', type: 'error', placement: 'topRight', duration: 3, });

        }
    };



    const validateForm = async () => {
        const validatefom = customerFormRef.current.validateForm();
    }

    const onSubmitForm = () => {
        const selectedSubjectId = state?.subjectId || [];
        const selectedEnrollmentId = state.enrollmentId || [];
        if (state?.classTeacher && selectedEnrollmentId.length != 1) {
            Alert({ description: 'Select a single class to assign a teacher.', type: 'warning', placement: 'topRight', duration: 3 });
            return false
        }
        const subjectIds = (selectedSubjectId ? selectedSubjectId : [])
        const enrollmentIds = (selectedEnrollmentId ? selectedEnrollmentId : [])
        if (!deleteModule) {
            if (!employeeId || !batchId || (subjectIds.length === 0 && !state.classTeacher) || enrollmentIds.length === 0) {
                Alert({ description: 'Please fill in all the required fields..', type: 'warning', placement: 'topRight', duration: 3 });

                return; // Exit the function early if validation fails
            }
        }

        const requestDate = {
            employeeId: parseInt(employeeId),
            batchId: parseInt(batchId),
            classTeacher: state?.classTeacher || false,
            enrollmentIds: enrollmentIds.join(","),
            subjectIds: subjectIds.join(","),
        };

        if (editData) {
            setLoaderModule(true);
            dispatch(updateSubjectTeachers(requestDate, selectedItem.subjectTeacherId));
        } else if (deleteModule) {
            const deleteRequest = {
                isActive: false
            };

            setLoaderModule(true);
            dispatch(updateSubjectTeachers(deleteRequest, selectedItem.subjectTeacherId));
        } else {
            setLoaderModule(true);
            dispatch(createSubjectTeachers(requestDate));
        }
    };

    const handleCloseModal = () => {
        setCreateModule(false);
    };

    const {
        employeeId,
        batchId
    } = state;

    const modelTitle = editData ? "Edit " : "Create ";
    const modelButtonLbl = editData ? "" : "Save";

    const handleClick = (buttonName) => {
        if (buttonName == 'Add') {
            onCreateForm();
        }
    };

    const { sidebarOpen } = useSidebar();

    return (
        <div>
            <div style={sidebarOpen ? { marginLeft: "50px", marginTop: "50px", marginBottom: "-20px" } : { marginLeft: "90px", marginTop: "50px", marginBottom: "-50px" }}>
                <FilterComponent defaultState={state} btnAlign={"mb-1"} setDefaultState={setState} dynamicForm={subjectFilterForm} noOfColumn={6} btnClickFun={() => onSearch()} />
            </div>
            {/* <SmallLoader value={loaderModule} /> */}
            <View isOpen={createModule} size="md" savetitle={modelButtonLbl} onClose={handleCloseModal} onSubmit={validateForm} title={`${modelTitle} SubjectTeachers`}>
                <FormLayout ref={customerFormRef} defaultState={state} setDefaultState={setState} dynamicForm={subjectTeachersForm} noOfColumn={2} onSubmit={onSubmitForm} />
            </View>


            <View centered={true} size={'sm'} isOpen={deleteModule} onClose={setDeleteModule} savetitle="Yes" onSubmit={onSubmitForm} title="Delete SubjectTeachers">
                <h6 className="text-capitalize mb-1">Are you sure ?</h6>
            </View>

            <SimpleCard >
                <CustomTable buttonData={pageItems.create ? ['add'] : []} handleClick={handleClick} tableName={"Subject Teachers"} columns={column} data={itemList} initialPage={1} />
            </SimpleCard>

        </div>
    )
};

export default SubjectTeachers;
