import * as yup from "yup";
import { Formik } from "formik";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import DateTime from "react-datetime";
import { Form, Button } from "react-bootstrap";
import { DateRange } from "react-date-range";
import SimpleCard from 'app/components/atoms/simple-card/SimpleCard'
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { MdEdit, MdDelete } from "react-icons/md";
import swal from "sweetalert2";
import _ from "lodash";
import { pagePermission, showMessage } from "app/utils/app-functions";
import FormLayout from "app/components/render/formlayout";
import View from "app/components/atoms/modalView/Modalview";
import CustomTable from "app/components/atoms/custom-table/CustomTable";
import formRender from "app/components/render/formcomponent";
import { warehouseForm } from "./formData";
import { FiEdit, FiSend, FiTrash2 } from 'react-icons/fi';
import { createWarehouse, getWarehouse, updateWarehouse, getWarehouseData } from "app/api/WarehouseApi";
import { getLocation } from "app/api/LocationApi";
import Alert from "app/components/atoms/alert";

let editData = false;
let object = [];

const Warehouse = ({ navigation }) => {

    const dispatch = useDispatch();
    const customerFormRef = useRef();

    const getWarehouseSuccess = useSelector((state) => state.warehouseReducer.getWarehouseSuccess);
    const getWarehouseList = useSelector((state) => state.warehouseReducer.getWarehouseList);
    const getWarehouseFailure = useSelector((state) => state.warehouseReducer.getWarehouseFailure);
    const getWarehouseErrorMessage = useSelector((state) => state.warehouseReducer.errorMessage);

    const getLocationSuccess = useSelector((state) => state.locationReducer.getLocationSuccess);
    const getLocationList = useSelector((state) => state.locationReducer.getLocationList);
    const getLocationFailure = useSelector((state) => state.locationReducer.getLocationFailure);
    const getLocationErrorMessage = useSelector((state) => state.locationReducer.errorMessage);


    const createWarehouseSuccess = useSelector((state) => state.warehouseReducer.createWarehouseSuccess);
    const createWarehouseData = useSelector((state) => state.warehouseReducer.createWarehouseData);
    const createWarehouseFailure = useSelector((state) => state.warehouseReducer.createWarehouseFailure);
    const createWarehouseErrorMessage = useSelector((state) => state.locationReducer.errorMessage);



    const updateWarehouseSuccess = useSelector((state) => state.warehouseReducer.updateWarehouseSuccess);
    const updateWarehouseData = useSelector((state) => state.warehouseReducer.updateWarehouseData);
    const updateWarehouseFailure = useSelector((state) => state.warehouseReducer.updateWarehouseFailure);
    const updateWarehouseErrorMessage = useSelector((state) => state.locationReducer.errorMessage);

    const [pageItems, setpageItems] = useState({});

    useEffect(() => {
        let data = pagePermission(pageItems, "Inventory", "Warehouse")
        setpageItems(data)

    }, [navigation]);




    const column = [
        {
            "dataField": "",
            "text": "SNO",
            "sort": true,
            formatter: (cellContent, row, index) => {
                return index + 1
            }
        },
        {
            "dataField": "warehouseName",
            "text": "WarehouseId",
            "sort": true,

        },


        /* {
          dataField: "orderStatusId",
          text: "Status",
          sort: true,
          align: "center",
          headerAlign: "center",
          formatter: (cellContent, row, index) => {
            const conInfo = { inputType: "status", status: cellContent }
            return formRender(null, null, conInfo, null);
          }
        }, */
       
    ]

    if (pageItems.update ||  pageItems.delete ) {
        column.push({
            text: "Action",
            sort: false,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => (
                <div className="icon-container">
                    {pageItems.update && <FiEdit
                        className="text-primary cursor-pointer"
                        size={18}
                        onClick={() => onEditForm(row, index)}
                    ></FiEdit>}
                    {pageItems.delete && <FiTrash2
                        className="text-danger cursor-pointer"
                        size={18}
                        onClick={() => {
                            onDeleteItem(row, index)
                        }}
                    ></FiTrash2>}
                    {/* <MdAddTask
                        className="text-success cursor-pointer"
                        size={18}
                        onClick={() => onEditForm(row, index)}
                    ></MdAddTask> */}
                </div>
            )
        },)
    }
    
    const formColumn = [
        {
            "dataField": "materialType",
            "text": "Material Type",
            "sort": true
        },
        {
            "dataField": "materialCode",
            "text": "Material Code",
            "sort": true
        },
        {
            "dataField": "materialName",
            "text": "Material Name",
            "sort": true
        },
        {
            "dataField": "Qty",
            "text": "Units",
            "sort": true
        },

        {
            "dataField": "Qty",
            "text": "Qty",
            "sort": true
        },
        {
            "dataField": "Qty",
            "text": "Received Quantity",
            "sort": true
        },



        {
            "dataField": "additem",
            "text": "Add Item Details",
            "sort": false
        },
        {
            "dataField": "action",
            "text": "Action",
            "sort": false
        },
    ]

    const [state, setState] = useState({
        voiceName: "",
        purchaseOrderDate: new Date(),
        statusId: 1,
        description: "",
        poChoosed: '',
        "customerList": [],
        "venderList": [],
        "leadSourceTypeList": [],
        footerData: [
            { label: 'SubTotal :', value: '0' },
            { label: 'Tax:', value: '0' },
            { label: 'Total:', value: '0' }
        ],
        statusList: [
            { id: 1, name: "Open" },
            { id: 2, name: "close" }
        ]
    });

    const [createModule, setCreateModule] = useState(false);
    const [loaderModule, setLoaderModule] = useState(false);
    const [editModule, setEditModule] = useState(false);
    const [viewModule, setViewModule] = useState(false);
    const [deleteModule, setDeleteModule] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [selectedItem, setSelectedItem] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);
    const [tableRows, setTableRows] = useState([]);




    useEffect(() => {
        setLoaderModule(true)
        dispatch(getWarehouseData({ isActive: true }))
        // dispatch(getLocation())
        listFetching()
    }, [navigation]);

    const listFetching = async () => {
        let measurementList = await getLocation()
        object = measurementList.getLocationList
        setState({
            ...state,
            locationList: object,
        })

    }


    useEffect(() => {
        if (getWarehouseSuccess) {
            setItemList(getWarehouseList)
            setState({
                ...state,
                warehouseList: getWarehouseList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_SUPPLIER" })
        } else if (getWarehouseFailure) {
            setItemList([])
            showMessage("warning", getWarehouseErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_SUPPLIER" })
        }
    }, [getWarehouseSuccess, getWarehouseFailure]);

    useEffect(() => {
        if (getLocationSuccess) {
            setState({
                ...state,
                locationList: getLocationList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_SUPPLIER" })
        } else if (getLocationFailure) {
            setItemList([])
            showMessage("warning", getLocationErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_SUPPLIER" })
        }
    }, [getLocationSuccess, getLocationFailure]);


    useEffect(() => {
        if (createWarehouseSuccess) {
            const temp_state = [...itemList, createWarehouseData[0]];
            setItemList(temp_state)
            setLoaderModule(false)
            closeModule()
            Alert({  description: 'Created Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            dispatch({ type: "RESET_CREATE_LOCATION" })
        } else if (createWarehouseFailure) {
            setLoaderModule(false)
            showMessage("warning", createWarehouseErrorMessage?.message)
            dispatch({ type: "RESET_CREATE_LOCATION" })
        }
    }, [createWarehouseSuccess, createWarehouseFailure]);

    useEffect(() => {
        if (updateWarehouseSuccess) {
            updateTable(updateWarehouseData[0])
            dispatch({ type: "RESET_UPDATE_SUPPLIER" })
        } else if (updateWarehouseFailure) {
            setLoaderModule(false)
            showMessage("warning", updateWarehouseErrorMessage?.message)
            dispatch({ type: "RESET_UPDATE_SUPPLIER" })
        }
    }, [updateWarehouseSuccess, updateWarehouseFailure]);

    const updateTable = (updatedItem) => {
        const temp_state = [...itemList];
        if (deleteModule) {
            // alert("hii")
            let remainingList = _.remove(temp_state, function (n) {
                return n.id != selectedItem.id;
            });
            Alert({  description: 'Deleted Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(remainingList)
        } else {
            temp_state[selectedIndex] = updatedItem;
            Alert({  description: 'Updated Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(temp_state)
        }
        setLoaderModule(false)
        closeModule()
    }


    const closeModule = () => {
        editData = false;
        setCreateModule(false)
        setDeleteModule(false)
    }

    const resetState = () => {
        setState({
            ...state,
            warehouseName: '',
            locationIdName: ''

        })
        setTableRows([])
    }

    const onCreateForm = () => {
        resetState()
        editData = false;
        setCreateModule(true)
    }

    const onEditForm = (data, index) => {
        const temp_state = state?.locationList || []
        const locationIds = data?.locationId?.split(",").map(Number) || [];
        /* const sel_loc = _.filter(temp_state, (item) => {
            return locationIds?.includes(item.id.toString());
        }) */
        setState({
            ...state,
            warehouseName: data?.warehouseName || '',
            locationIdName: locationIds
        })

        editData = true;
        setSelectedIndex(index);
        setSelectedItem(data);
        setCreateModule(true);
    };
    const handleWarehouseChange = (event, index, name) => {
        let temp_state = "";
        temp_state = {
            ...state,
            [name]: event,
            contactNumber: event[0]?.primaryNo || "",
            contactPerson: event[0]?.contactName || "",
            supplierAddress: event[0]?.address || "",

        }
        setState(temp_state)

    }

    const onDeleteItem = (data, index) => {

        editData = false;
        setSelectedItem(data)
        setSelectedIndex(index)
        setDeleteModule(true)
    }
    // console.log(state.locationIdName);

    const handleLocationId = (event, name, index) => {
        const selectedLocationIds = event/* map(option => parseInt(option.id)); */
        setState(prevState => ({
            ...prevState,
            [name]: event,
            location: selectedLocationIds
        }));
    };
    const validateForm = async () => {
        const validatefom = customerFormRef.current.validateForm();
    }

    const onSubmitForm = () => {

        const req = {

            name: state?.warehouseName || '',
            locationId: state.locationIdName ? state?.locationIdName.join(',') : state?.locationIdName
        }
        console.log(JSON.stringify(req))

        // return
        setCreateModule(false)


        if (editData) {
            setLoaderModule(true)
            dispatch(updateWarehouse(req, selectedItem.id))
        }
        else if (deleteModule) {
            const deleteRequest = {
                isActive: "false"
            }
            setLoaderModule(true)
            dispatch(updateWarehouse(deleteRequest, selectedItem.id))
        } else {
            setLoaderModule(true)
            dispatch(createWarehouse(req))

        }

    }

    const confromDialog = () => {
        return (
            swal
                .fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    type: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!",
                    cancelButtonText: "No",
                })
                .then((result) => {
                    if (result.value) {
                        onSubmitForm()
                    } else {
                        setDeleteModule(false)
                        swal.fire({
                            title: "Cancelled!",
                            text: "Permission denied.",
                            type: "error",
                            icon: "error",
                            timer: 1500,
                        });
                    }
                })
        )
    }

    useEffect(() => {
        if (deleteModule) {
            confromDialog()
        }
    }, [deleteModule])

    
    const handleClick = (buttonName) => {
        if (buttonName == 'Add') {
            onCreateForm();
        }
    };

    const modelTitle = editData ? "Edit " : "Create ";
    const modelButtonLbl = editData ? "" : "Save";

    return (
        <div>
            <div style={{ marginTop: "-10px" }}>
            <View isOpen={createModule} size="sm" savetitle={modelButtonLbl} onClose={setCreateModule} onSubmit={validateForm} title={`${modelTitle} Warehouse`}>
                <FormLayout ref={customerFormRef} defaultState={state} setDefaultState={setState} dynamicForm={warehouseForm} noOfColumn={1} onSubmit={onSubmitForm} onChangeCallback={{ "handleLocationId": handleLocationId }} />
            </View>

            <SimpleCard >
                <CustomTable buttonData={pageItems.create ? ['add'] : []} handleClick={handleClick} tableName={"Warehouse"} columns={column} data={itemList} initialPage={1} />
            </SimpleCard>

        </div>
        </div>
    )
};

export default Warehouse;
