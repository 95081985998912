import React, { useState, useEffect } from 'react';
import { Collapse, Table } from "react-bootstrap";
import { IoIosArrowDown } from "react-icons/io";
// import Calculation from './Calculation';
// import "../../index.css";
import TableLayout from '../../render/formlayout/tablelayout';
import { lightTheme } from 'styles/theme';

const CollapseView = ({ ...props }) => {
    const { header, state, setState, description, setTableRows, tableRows, expandView, fieldList, onSearch, btnName, addRow, iconPress, onChangeCallback, calculationTitle, currencySymbol } = props;

    const [statusViews, setStatusViews] = useState([]);
    const [openIndex, setOpenIndex] = useState(null);

    useEffect(() => {
        setStatusViews(new Array(tableRows.length).fill(false));
    }, []);

    useEffect(() => {
        setStatusViews(prevStatusViews => {
            const updatedStatusViews = new Array(tableRows.length).fill(false);
            if (openIndex !== null && openIndex < tableRows.length) {
                updatedStatusViews[openIndex] = true;
            }
            return updatedStatusViews;
        });
    }, [tableRows, openIndex]);
    const toggleStatusView = (index) => {
        setState({
            ...state,
            clickIndex: index
        });
        setStatusViews(prevStatusViews => {
            const updatedStatusViews = prevStatusViews.map((status, idx) => {
                if (idx === index) {
                    return !status;
                }
                return false;
            });
            setOpenIndex(updatedStatusViews[index] ? index : null);
            return updatedStatusViews;
        });
    };
 
    const expandLayout = (item, rowIndex) => {
        return (
            <h6 className="text-capitalize mb-1">{item || ""}</h6>
        );
    };
    return (
        <div>
            {btnName ? (
                <button className="btn btn-primary mb-3" onClick={() => addRow()} style={{ backgroundColor: lightTheme.primaryColor, border: "none", fontSize: "12px" }}>
                    {btnName ? `${btnName}` : "Add item"}
                </button>
            ) : ""}
            <Table style={{ tableLayout: 'fixed' }}>
                <colgroup style={{ width: 'auto' }}>
                    <col />
                    {(header ? header : []).map((_, index) => (
                        <col key={index} style={{ width: 'auto' }} />
                    ))}
                </colgroup>
                <thead>
                    <tr key={`th_0`}>
                        {(description || expandView) && <th style={{ width: '45px' }}></th>}
                        {(header ? header : []).map((item, index) => {
                            const style = item?.align || 'left';
                            return (
                                <th key={index} /* style={{ textAlign: `${style}` }} */ className={item?.wrap || ""}>
                                    {item.text}
                                </th>
                            );
                        })}
                    </tr>
                </thead>
                <tbody>
                    {tableRows.map((row, rowIndex) => (
                        <React.Fragment key={rowIndex}>
                            <tr>
                                {(description || expandView) && (
                                    <td>
                                        <button onClick={() => toggleStatusView(rowIndex)} className={`btn toggle-btn ${statusViews[rowIndex] ? 'rotate' : ''}`}>
                                            <IoIosArrowDown /> 
                                        </button>
                                    </td>
                                )}
                                {fieldList.map((item, index) => (
                                    <td key={index}>
                                        <TableLayout iconPress={iconPress} isField={true} defaultState={state} setDefaultState={setTableRows} index={rowIndex} layoutType="table" dataList={tableRows} setDataList={setTableRows} dynamicForm={item} onChangeCallback={onChangeCallback}/*  style={{ textAlign: 'center' }}  */onSearch={() => {}} />
                                    </td>
                                ))}
                            </tr>
                            <tr style={{ border: '' }}>
                                {(description || expandView) && (
                                    <td colSpan={header.length + 1}>
                                        <Collapse in={statusViews[rowIndex]}>
                                            <div>
                                                {expandView ? expandView(rowIndex) : expandLayout(description, rowIndex)}
                                            </div>
                                        </Collapse>
                                    </td>
                                )}
                            </tr>
                        </React.Fragment>
                    ))}
                </tbody>
            </Table>
        </div>
    );
};

export default CollapseView;
