const warehouseForm = [{

    title: "Basic Information",
    formBody: [
        {
            formField: [
                {
                    inputType: "text",
                    placeholder: 'Warehouse Name',
                    uniqueKey: "departmentId",
                    displayKey: "departmentName",
                    fieldName: "warehouseName",
                    defaultValue: "",
                    config: {
                        required: "required",
                    },
                },
                {
                    inputType: "select",
                    options: "locationList",
                    placeholder: 'Location',
                    onChange: "handleLocationId",
                    multiple: true,
                    uniqueKey: "id",
                    displayKey: "name",
                    fieldName: "locationIdName",
                    // fieldId: "location",
                    defaultValue: "",
                    config: {
                        required: "required",
                    },
                    // icon:"MdAdd"
                },

            ]
        },


    ]
}]

export { warehouseForm }
