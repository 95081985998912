export const lightTheme = {
    bg: "rgb(255,255,255)",
    bgAlpha: "rgba(250,250,250,.3)",
    bg2: "rgb(245,245,245)",
    bg3: "rgb(230,230,230)",
    text: "rgb(45,45,45)",
    primary: "rgb(52, 131, 235)",

    // Grays and Neutrals
    lightGray: "rgb(211, 211, 211)",
    gainsboro: "rgb(220, 220, 220)",
    silver: "rgb(192,192,192)",
    dimGray: "rgb(105,105,105)",
    darkGray: "rgb(169,169,169)",
    slateGray: "rgb(112,128,144)",
    darkSlateGray: "rgb(47,79,79)",
    lightSlateGray: "rgb(119,136,153)",
    charcoalGray: "rgb(51,51,51)",
    ghostWhite: "rgb(248,248,255)",
    whiteSmoke: "rgb(245,245,245)",
    ivory: "rgb(255,255,240)",
    beige: "rgb(245,222,179)",

    // Blues and Greens
    steelBlue: "rgb(70,130,180)",
    darkTeal: "rgb(0,128,128)",
    softAqua: "rgb(0,206,209)",
    seaGreen: "rgb(46,139,87)",
    pastelMint: "rgb(152,255,152)",
    forestGreen: "rgb(34,139,34)",
    emerald: "rgb(80,200,120)",
    teal: "rgb(0,128,128)",
    lightSeaGreen: "rgb(32,178,170)",
    mediumTurquoise: "rgb(72,209,204)",

    // Warm Tones
    warmGold: "rgb(255,215,0)",
    coolCoral: "rgb(255,107,107)",
    rustOrange: "rgb(210,105,30)",
    salmonPink: "rgb(250,128,114)",
    peach: "rgb(255,229,180)",
    sienna: "rgb(160,82,45)",
    firebrick: "rgb(178,34,34)",
    goldenrod: "rgb(218,165,32)",
    sandyBrown: "rgb(244,164,96)",

    // Purples and Reds
    royalPurple: "rgb(120,81,169)",
    deepLavender: "rgb(153,102,204)",
    crimson: "rgb(220,20,60)",
    burgundy: "rgb(128,0,32)",
    lavenderBlush: "rgb(255,240,245)",
    orchid: "rgb(218,112,214)",
    plum: "rgb(221,160,221)",
    // mediumPurple: "rgb(147,112,219)",

    // Accent Colors
    mutedNavy: "rgb(30,58,95)",
    midnightBlue: "rgb(25,25,112)",
    brightYellow: "rgb(255,255,0)",
    pastelPink: "rgb(255,182,193)",
    paleGoldenrod: "rgb(238,232,170)",
    aquaMarine: "rgb(127,255,212)",
    lavender: "rgb(230,230,250)",
    lightPink: "rgb(255,182,193)",
    honeydew: "rgb(240,255,240)",
    mediumPurple:"#212B54",
    backgroundSidebar:"#212B54",
    purplebaseline:"#161748",
    iconColor:"white",
    modalViewHeader:"#4D6D9A",
    primaryColor:"#212B54",
    secondaryColor:"#FECC00",


};


export const darkTheme = {
    bg: "rgb(15,15,15)",
    bgAlpha: "rgba(0,0,0,.3)",
    bg2: "rgb(30,30,30)",
    bg3: "rgb(50,50,50)",
    text: "rgb(210,210,210)",
    primary: "rgb(52, 131, 235)",
};
