import React from 'react';
import { Card, List, Typography, Tag, Row, Col } from 'antd';

const { Title, Text } = Typography;

const AssignmentList = ({ assignments }) => {
    const getStatusTag = (status) => {
        switch (status) {
            case 'Missed':
                return <Tag color="red">Missed</Tag>;
            case 'Submitted':
                return <Tag color="green">Submitted</Tag>;
            case 'Pending':
                return <Tag color="orange">Pending</Tag>;
            default:
                return <Tag>{status}</Tag>;
        }
    };

    const cardBackgroundColor = (status) => {
        switch (status) {
            case 'Missed':
                return '#ffe6e6'; // Light red
            case 'Submitted':
                return '#e6ffe6'; // Light green
            case 'Pending':
                return '#fff5e6'; // Light orange
            default:
                return '#ffffff'; // Default white
        }
    };

    return (
       
            <List
                itemLayout="vertical"
                dataSource={assignments}
                renderItem={(item) => (
                    <List.Item style={{ padding: '8px 0' }}>
                        <Card
                            type="inner"
                            title={
                                <Row align="middle" justify="space-between">
                                    <Col>
                                        <div>
                                            <Title level={5} style={{ color: '#4a90e2', marginBottom: '4px' }}>
                                                {item.subjectTitle}
                                            </Title>
                                            <Text 
                                                type="secondary" 
                                                style={{ fontSize: '12px', color: '#555555', display: 'block' }}
                                            >
                                                {item.typeName}
                                            </Text>
                                        </div>
                                    </Col>
                                    <Col>{getStatusTag(item.submissionStatus)}</Col>
                                </Row>
                            }
                            style={{
                                marginBottom: '8px',
                                borderRadius: '6px',
                                padding: '12px',
                                background: cardBackgroundColor(item.submissionStatus),
                            }}
                        >
                            <Row gutter={[16, 8]}>
                                <Col span={12}>
                                    <p style={{ marginBottom: '4px', color: '#555555' }}>
                                        <strong style={{ color: '#333333' }}>Homework Title:</strong> {item.homeworkTitle}
                                    </p>
                                </Col>
                                <Col span={12}>
                                    <p style={{ marginBottom: '0', color: '#555555' }}>
                                        <strong style={{ color: '#333333' }}>Submission Date:</strong>{' '}
                                        {new Date(item.submissionDate).toLocaleDateString()}
                                    </p>
                                </Col>
                            </Row>
                        </Card>
                    </List.Item>
                )}
            />
    );
};

export default AssignmentList;


