import styled from "styled-components";
import { v } from "../../../styles/variables";

export const SLayout = styled.div`
    display: flex;
    z-index:150;

    
    @media (min-width: 768px) {
        flex-direction: row; /* For larger screens, layout switches to row */
    }
`;

export const SMain = styled.main`
    position: absolute;
    top: 60px;
    left: ${({ isOpen }) => (isOpen ? `250px` : `0px`)};
    transition: left 0.5s ease;
    overflow-y: scroll;
    height:100vh;
    right:0px;



    
    overflow-x: none;


    /* Hide scrollbar */
    -webkit-scrollbar {
        width: 0;
        background: transparent;
    }
    scrollbar-width: none;

   
`;
