import React, { useRef } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { dateConversion } from "app/utils/app-functions";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { imagePath, studentPath } from "app/utils/constant";
import noProfile from "app/pages/images/no_profile.png";
import Alert from "app/components/atoms/alert";

const ProfileDetails = ({ data = [], studentDetails = [], pageAccess = [] }) => {
  const profileRef = useRef(); // Reference to the profile container
  console.log()
  const StudentData = studentDetails.length > 0 ? studentDetails[0] : [];

  if (!data) {
    return <div>No profile data available</div>;
  }

  const {
    studentName,
    phoneNo,
    email,
    dob,
    genderName,
    profilePic,
    batchName,
    studentToken,
    statusId,
    classTitle,
    sectionTitle,
    Caste,
    casteName
  } = data;

  const profile = `${imagePath}${studentPath}${StudentData?.studentToken || ""}/${encodeURI(StudentData?.profilePic || "")}`;

  const handleExportPDF = async () => {
    if (!pageAccess.download) {
      Alert({ type: "error", description: "Access Denied!.." });
      return false;
    }

    const element = profileRef.current; // Reference to the container

    // Hide the export icon before capturing
    const exportIcon = document.querySelector(".export-icon");
    exportIcon.style.display = "none";

    // Convert the component to a canvas
    const canvas = await html2canvas(element, { scale: 2 });
    const imgData = canvas.toDataURL("image/png");

    // Restore the export icon visibility
    exportIcon.style.display = "block";

    // Create PDF
    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "px",
      format: [canvas.width, canvas.height],
    });

    pdf.addImage(imgData, "PNG", 0, 0, canvas.width, canvas.height);
    pdf.save("profile-details.pdf");
  };


  return (
    <div>
      <div className="profile-container" ref={profileRef}>
        <div className="profile-header">
          <div className="profile-pic-container">
            <img
              src={profile || noProfile}
              alt={studentName || "Student"}
              className="profile-pic"
            />
          </div>
          <div className="profile-info">
            <h1 className="profile-name">{studentName || "No Name Provided"}</h1>
            <p className="profile-email">{email || "No email provided"}</p>
            <p className="profile-email">{phoneNo || "No Contact provided"}</p>
          </div>
          <div className="export-container">
            <FaCloudDownloadAlt
              onClick={handleExportPDF}
              className="export-icon"
              title="Download Profile as PDF"
            />
          </div>
        </div>

        <div className="profile-details">
          <table className="details-table">
            <thead>
              <tr>
                <th colSpan="2">Personal Information</th>
              </tr>
            </thead>
            <tbody>

              <tr>
                <td><strong>Address:</strong></td>
                <td>{StudentData.address || "Not specified"}</td>
              </tr>
              <tr>
                <td><strong>Date of Birth:</strong></td>
                <td>{dob || "Not provided"}</td>
              </tr>
              <tr>
                <td><strong>Gender:</strong></td>
                <td>{genderName || "Not specified"}</td>
              </tr>
              <tr>
                <td><strong>Email:</strong></td>
                <td>{email || "Not specified"}</td>
              </tr>
              <tr>
                <td><strong>Caste:</strong></td>
                <td>{StudentData.casteName || "Not specified"}</td>
              </tr>
              <tr>
                <td><strong>Father Name:</strong></td>
                <td>{StudentData.fatherName || "Not specified"}</td>
              </tr>
              <tr>
                <td><strong>Mother Name:</strong></td>
                <td>{StudentData.motherName || "Not specified"}</td>
              </tr>
              <tr>
                <td><strong>Father Qualification:</strong></td>
                <td>{StudentData.fatherQualificationName || "Not specified"}</td>
              </tr>
              <tr>
                <td><strong>Mother Qualification:</strong></td>
                <td>{StudentData.motherQualificationName || "Not specified"}</td>
              </tr>
              <tr>
                <td><strong>Father Occupation:</strong></td>
                <td>{StudentData.fatherOccupation || "Not specified"}</td>
              </tr>
              <tr>
                <td><strong>Mother Occupation:</strong></td>
                <td>{StudentData.motherOccupation || "Not specified"}</td>
              </tr>
              <tr>
                <td><strong>Blood Group Name:</strong></td>
                <td>{StudentData.bloodGroupName || "Not specified"}</td>
              </tr>
              <tr>
                <td><strong>Date of join:</strong></td>
                <td>{dateConversion(StudentData.dateOfJoin, "DD-MMM-YYYY") || "Not specified"}</td>
              </tr>
              <tr>
                <td><strong>Mother Tongue:</strong></td>
                <td>{StudentData.motherTongueName || "Not specified"}</td>
              </tr>
              <tr>
                <td><strong>Identification:</strong></td>
                <td>{StudentData.identification || "Not specified"}</td>
              </tr>
              <tr>
                <td><strong>Batch Name:</strong></td>
                <td>{StudentData.batchName || "Not specified"}</td>
              </tr>

              <tr>
                <td><strong>Admission Number:</strong></td>
                <td>{StudentData.admissionNo || "Not specified"}</td>
              </tr>
              <tr>
                <td><strong>Nationality:</strong></td>
                <td>{StudentData.nationalityName || "Not specified"}</td>
              </tr>
              <tr>
                <td><strong>Religion Name:</strong></td>
                <td>{StudentData.religionName || "Not specified"}</td>
              </tr>
              <tr>
                <td><strong>State Name:</strong></td>
                <td>{StudentData.stateName || "Not specified"}</td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th colSpan="2">School & Enrollment</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><strong>Batch:</strong></td>
                <td>{batchName || "Not assigned"}</td>
              </tr>
              <tr>
                <td><strong>Class:</strong></td>
                <td>{classTitle || "Not assigned"}</td>
              </tr>
              <tr>
                <td><strong>Section:</strong></td>
                <td>{sectionTitle || "Not assigned"}</td>
              </tr>
              {/*  <tr>
                <td><strong>Student Token:</strong></td>
                <td>{studentToken}</td>
              </tr> */}
              <tr>
                <td><strong>Status:</strong></td>
                <td>{statusId === 1 ? "Active" : "Pending"}</td>
              </tr>
            </tbody>
          </table>
          <table className="details-table sibling-table">
            <thead>
              <tr>
                <th>Sibling Name</th>
                <th>Class Section</th>
                <th>Admission Number</th>
              </tr>
            </thead>
            <tbody>
              {StudentData.siblingDetails && StudentData.siblingDetails.length > 0 ? (
                StudentData.siblingDetails.map((sibling, index) => (
                  <tr key={index}>
                    <td>{sibling.studentName || "Not specified"}</td>
                    <td>{sibling.classSection || "Not specified"}</td>
                    <td>{sibling.admissionNo || "Not specified"}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="3">No sibling details available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <style jsx>{`
        .profile-container {
          padding: 20px;
          max-width: 900px;
          margin: 30px auto;
          border-radius: 15px;
          background: #f9f9f9;
          box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
        }

        .profile-header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 30px;
          padding: 20px;
          background:linear-gradient(90deg, #bdc3c7 0%, #2c3e50 100%);
          color: white;
          border-radius: 10px;
        }

        .profile-pic-container {
          flex-shrink: 0;
        }

        .profile-pic {
          width: 120px;
          height: 120px;
          border-radius: 50%;
          object-fit: cover;
          border: 4px solid white;
        }

        .profile-info {
          margin-left: 20px;
          flex-grow: 1;
          font-size: 15px;
        }

        .profile-name {
          font-size: 24px;
          font-weight: bold;
          margin-bottom: 5px;
        }

        .profile-email {
          font-size: 14px;
          margin-bottom: 5px;
        }

        .profile-status {
          font-size: 14px;
          background: rgba(255, 255, 255, 0.2);
          padding: 5px 10px;
          border-radius: 5px;
          display: inline-block;
        }

        .export-container {
          flex-shrink: 0;
        }

        .export-icon {
          cursor: pointer;
          font-size: 30px;
          color: white;
          transition: transform 0.3s ease;
        }

        .export-icon:hover {
          transform: scale(1.2);
        }

        .details-table {
          width: 100%;
          border-collapse: collapse;
          margin-top: 20px;
        }

        .details-table th,
        .details-table td {
          border: 1px solid #ddd;
          padding: 8px;
          text-align: left;
        }

        .details-table th {
          background-color: #f4f4f4;
          font-size: 16px;
          color: #333;
        }

        .details-table tbody tr:nth-child(even) {
          background-color: #f9f9f9;
        }
      `}</style>
    </div>
  );
};

export default ProfileDetails;
