import * as yup from "yup";
import { Formik } from "formik";
import { useState, useEffect, useRef, Fragment } from "react";
import { useDispatch, useSelector } from 'react-redux';
import DateTime from "react-datetime";
import { Form, Button } from "react-bootstrap";
import { DateRange } from "react-date-range";
import SimpleCard from 'app/components/atoms/simple-card/SimpleCard'
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { MdEdit, MdDelete } from "react-icons/md";
import swal from "sweetalert2";
import _ from "lodash";
import { pagePermission, showMessage } from "app/utils/app-functions";
import FormLayout from "app/components/render/formlayout";
import View from "app/components/atoms/modalView/Modalview";
import CustomTable from "app/components/atoms/custom-table/CustomTable";
import formRender from "app/components/render/formcomponent";
import { companyLeaveSetingsForm, LeaveTypeSetingsForm, schoolInfoForm } from "./formData";
import LeaveListTabs from "./leaveListTabs";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import "./tabs.css";
import { FiEdit, FiSend, FiTrash2 } from 'react-icons/fi';
import { createLeaveSettings, getLeaveSettings, updateLeaveSettings, asyncgetLeaveSettings } from "app/api/LeaveSettingsApi";
import { getLeaveType, updateLeaveType, asyncGetLeaveTypeInfo } from "app/api/LeaveTypeApi";
import { createWorkingDays, getWorkingDays, updateWorkingDays } from "app/api/WorkingDaysApi";
import { getMonth } from "app/api/MonthApi";
import { createLeaveMeta, updateLeaveMeta } from "app/api/LeaveMetaApi";
import Alert from "app/components/atoms/alert";
import { lightTheme } from "styles/theme";
import { asyncgetMonth } from "app/api/MonthApi";
import { asyncgetLeaveMeta } from "app/api/LeaveMetaApi";
import { useSidebar } from "app/components/sidebar/sidebarContext";

let editData = false;
let leaveEditData = false;
let leaveTypeData = false;


const LeaveSettings = ({ navigation }) => {

    const dispatch = useDispatch();
    const customerFormRef = useRef();
    const leaveTypeFormRef = useRef();
    const workingSettingsFormRef = useRef();

    const getLeaveSettingsSuccess = useSelector((state) => state.leaveSettingsReducer.getLeaveSettingsSuccess);
    const getLeaveSettingsList = useSelector((state) => state.leaveSettingsReducer.getLeaveSettingsList);
    const getLeaveSettingsFailure = useSelector((state) => state.leaveSettingsReducer.getLeaveSettingsFailure);

    const getMonthSuccess = useSelector((state) => state.monthReducer.getMonthSuccess);
    const getMonthList = useSelector((state) => state.monthReducer.getMonthList);
    const getMonthFailure = useSelector((state) => state.monthReducer.getMonthFailure);

    const getLeaveTypeSuccess = useSelector((state) => state.leaveTypeReducer.getLeaveTypeSuccess);
    const getLeaveTypeList = useSelector((state) => state.leaveTypeReducer.getLeaveTypeList);
    const getLeaveTypeFailure = useSelector((state) => state.leaveTypeReducer.getLeaveTypeFailure);

    const getWorkingDaysSuccess = useSelector((state) => state.workingDaysReducer.getWorkingDaysSuccess);
    const getWorkingDaysList = useSelector((state) => state.workingDaysReducer.getWorkingDaysList);
    const getWorkingDaysFailure = useSelector((state) => state.workingDaysReducer.getWorkingDaysFailure);

    const createWorkingDaysSuccess = useSelector((state) => state.workingDaysReducer.createWorkingDaysSuccess);
    const createWorkingDaysData = useSelector((state) => state.workingDaysReducer.createWorkingDaysData);
    const createWorkingDaysFailure = useSelector((state) => state.workingDaysReducer.createWorkingDaysFailure);

    const createLeaveMetaSuccess = useSelector((state) => state.leaveMetaReducer.createLeaveMetaSuccess);
    const createLeaveMetaData = useSelector((state) => state.leaveMetaReducer.createLeaveMetaData);
    const createLeaveMetaFailure = useSelector((state) => state.leaveMetaReducer.createLeaveMetaFailure);

    const updateWorkingDaysSuccess = useSelector((state) => state.workingDaysReducer.updateWorkingDaysSuccess);
    const updateWorkingDaysData = useSelector((state) => state.workingDaysReducer.updateWorkingDaysData);
    const updateWorkingDaysFailure = useSelector((state) => state.workingDaysReducer.updateWorkingDaysFailure);

    const createLeaveSettingsSuccess = useSelector((state) => state.leaveSettingsReducer.createLeaveSettingsSuccess);
    const createLeaveSettingsData = useSelector((state) => state.leaveSettingsReducer.createLeaveSettingsData);
    const createLeaveSettingsFailure = useSelector((state) => state.leaveSettingsReducer.createLeaveSettingsFailure);

    const updateLeaveSettingsSuccess = useSelector((state) => state.leaveSettingsReducer.updateLeaveSettingsSuccess);
    const updateLeaveSettingsData = useSelector((state) => state.leaveSettingsReducer.updateLeaveSettingsData);
    const updateLeaveSettingsFailure = useSelector((state) => state.leaveSettingsReducer.updateLeaveSettingsFailure);

    const updateLeaveMetaSuccess = useSelector((state) => state.leaveMetaReducer.updateLeaveMetaSuccess);
    const updateLeaveMetaData = useSelector((state) => state.leaveMetaReducer.updateLeaveMetaData);
    const updateLeaveMetaFailure = useSelector((state) => state.leaveMetaReducer.updateLeaveMetaFailure);

    const leaveSettingsErrorMessage = useSelector((state) => state.leaveSettingsReducer.errorMessage);
    const leaveTypeErrorMessage = useSelector((state) => state.leaveTypeReducer.errorMessage);
    const workingDaysErrorMessage = useSelector((state) => state.workingDaysReducer.errorMessage);
    const monthErrorMessage = useSelector((state) => state.monthReducer.errorMessage);
    const leaveMetaErrorMessage = useSelector((state) => state.leaveMetaReducer.errorMessage);

    const [pageItems, setpageItems] = useState({});

    useEffect(() => {
        let data = pagePermission(pageItems, "Settings", "Leave Settings")
        setpageItems(data)

    }, [navigation]);

    const column = [
        {
            "dataField": "leaveSettingsName",
            "text": "LeaveSettings Name",
            "sort": true
        },
        {
            "dataField": "leaveSettingsStart",
            "text": "LeaveSettings Year",
            "sort": true,
            formatter: (cellContent, row, index) => (
                row.leaveSettingsStart + "-" + row.leaveSettingsEnd
            )
        },
        {
            dataField: "isActive",
            text: "Status",
            sort: true,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => {
                const conInfo = { inputType: "status", status: cellContent }
                return formRender(null, null, conInfo, null);
            }
        },
        {
            text: "Action",
            sort: false,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => (
                <div className="icon-container">
                    <FiEdit
                        className="text-primary cursor-pointer"
                        size={18}
                        onClick={() => onEditForm(row, index)}
                    ></FiEdit>
                    <FiTrash2
                        className="text-danger cursor-pointer"
                        size={18}
                        onClick={() => {
                            swal
                                .fire({
                                    title: "Are you sure?",
                                    text: "You won't be able to revert this!",
                                    icon: "warning",
                                    type: "question",
                                    showCancelButton: true,
                                    confirmButtonColor: "#3085d6",
                                    cancelButtonColor: "#d33",
                                    confirmButtonText: "Yes, delete it!",
                                    cancelButtonText: "No",
                                })
                                .then((result) => {
                                    if (result.value) {

                                        onDeleteItem(row, index);
                                    } else {
                                        swal.fire({
                                            title: "Cancelled!",
                                            text: "Permission denied.",
                                            type: "error",
                                            icon: "error",
                                            timer: 1500,
                                        });
                                    }
                                });
                        }}
                    ></FiTrash2>
                </div>
            )
        },
    ]
    const tabColumns = [
        {
            "name": "Company Leave",
            "icon": "i-Building"
        },
        {
            "name": "Leave Type",
            "icon": "i-Check"
        },
        {
            "name": "Working Days",
            "icon": "i-Calendar-4"
        },
    ]

    const columnLeaveType = [
        {
            "dataField": "leaveTypeName",
            "text": "Leave Type Name",
            "sort": true
        },
        /*  {
             "dataField": "startMonth",
             "text": "Start Month",
             "sort": true,
             "formatter": (cellContent, row, index) => {
                 return dateConversion(cellContent);
             }
         },
         {
             "dataField": "endMonth",
             "text": "End Month",
             "sort": true,
             "formatter": (cellContent, row, index) => {
                 return dateConversion(cellContent);
             }
         },
         {
             "dataField": "noOfLeave",
             "text": "Number Of Leaves",
             "sort": true
         }, */
        {
            "dataField": "description",
            "text": "Description ",
            "sort": false
        },
        /* {
            "dataField": "isPaid",
            "text": "Pay Status",
            "sort": true,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => {
                const label = cellContent ? "Paid" : "Unpaid";
                const conInfo = { inputType: "status", status: cellContent, label: label }
                return formRender(null, null, conInfo, null);
            }
        }, */

    ]
    if (pageItems.create && pageItems.update) {
        columnLeaveType.push({
            // dataField: "age",
            text: "Action",
            sort: false,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => (
                <div className="icon-container">
                    {pageItems.update && <FiEdit
                        className="text-primary cursor-pointer"
                        size={18}
                        onClick={() => onEdit(row, index)}
                    ></FiEdit>}
                </div>
            )
        },)
    }

    const [state, setState] = useState({
        leaveTypeList: [],
        workingDaysList: [],
        autoRenewBefore: "",
        maxLeaveTypes: "",
        monthList: [],
    });

    const [createModule, setCreateModule] = useState(false);
    const [loaderModule, setLoaderModule] = useState(false);
    const [activeTab, setActiveTab] = useState("companyLeave");
    const [workingDaysModule, setWorkingDaysModule] = useState(false);
    const [workingDaysState, setWorkingDaysState] = useState(false);
    const [deleteModule, setDeleteModule] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [selectedItem, setSelectedItem] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);
    const [selectedIndexTab, setSelectedIndexTab] = useState(false);
    const [initialModule, setInitialModule] = useState(true);
    const [leaveTypeModule, setLeaveTypeModule] = useState(true);


    const listFetching = async () => {
        const requestData = {
            isActive: "true",
        }

        let leavesettingsList = await asyncgetLeaveSettings()
        let monthList = await asyncgetMonth()
        let typeList = await asyncGetLeaveTypeInfo(requestData)
        let leaveData = leavesettingsList.data
        setItemList(leaveData)
        editData = leaveData.length > 0 ? true : false
        setState({
            ...state,
            monthList: monthList.data,
            startMonthId: editData ? leaveData[0]?.startMonthId || "" : undefined,
            autoRenew: editData ? leaveData[0]?.autoRenew || "" : undefined,
            endMonthId: editData ? leaveData[0]?.endMonthId || "" : undefined,
            leaveTypeList: leaveTypeData ? undefined : typeList.data,
        });

        if (leaveTypeData) {
            onEditForm(typeList.data);
        }

        // setLoaderModule(false)

    }
    useEffect(() => {
        setLoaderModule(true)
        const req = {
            isActive: "true"
        }
        // dispatch(getLeaveSettings())
        dispatch(getWorkingDays())

        // dispatch(getMonth())
        listFetching()
    }, [navigation]);

    useEffect(() => {
        if (getLeaveSettingsSuccess) {
            setItemList(getLeaveSettingsList)
            editData = getLeaveSettingsList.length > 0 ? true : false
            if (editData) {
                setState({
                    ...state,
                    startMonthId: getLeaveSettingsList[0]?.startMonthId || "",
                    autoRenew: getLeaveSettingsList[0]?.autoRenew || "",
                    endMonthId: getLeaveSettingsList[0]?.endMonthId || "",
                })
            }
            setLoaderModule(false)
            dispatch(getLeaveType())
            dispatch({ type: "RESET_GET_LEAVE_SETTINGS" })
        } else if (getLeaveSettingsFailure) {
            setItemList([])
            showMessage("warning", leaveSettingsErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_LEAVE_SETTINGS" })
        }
    }, [getLeaveSettingsSuccess, getLeaveSettingsFailure]);

    useEffect(() => {
        if (getMonthSuccess) {
            setState({
                ...state,
                monthList: getMonthList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_MONTH" })
        } else if (getMonthFailure) {
            setState({
                ...state,
                monthList: []
            })
            showMessage("warning", monthErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_MONTH" })
        }
    }, [getMonthSuccess, getMonthFailure]);

    useEffect(() => {
        if (getLeaveTypeSuccess) {
            if (leaveTypeData === true) {
                onEditForm(getLeaveTypeList)

            } else {
                setState({
                    ...state,
                    leaveTypeList: getLeaveTypeList
                })
            }
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_LEAVE_TYPE" })
        } else if (getLeaveTypeFailure) {
            setState({
                ...state,
                leaveTypeList: []
            })
            showMessage("warning", leaveTypeErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_LEAVE_TYPE" })
        }
    }, [getLeaveTypeSuccess, getLeaveTypeFailure]);

    useEffect(() => {
        if (getWorkingDaysSuccess) {
            setWorkingDaysState(getWorkingDaysList)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_WORKING_DAYS" })
        } else if (getWorkingDaysFailure) {
            setWorkingDaysState([])
            showMessage("warning", workingDaysErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_WORKING_DAYS" })
        }
    }, [getWorkingDaysSuccess, getWorkingDaysFailure]);

    useEffect(() => {
        if (createLeaveSettingsSuccess) {
            const temp_state = [...itemList, createLeaveSettingsData[0]];
            setItemList(temp_state)
            setLoaderModule(false)
            closeModule()
            Alert({ description: 'Leave Settings Created Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            dispatch({ type: "RESET_CREATE_LEAVE_SETTINGS" })
        } else if (createLeaveSettingsFailure) {
            setLoaderModule(false)
            showMessage("warning", leaveSettingsErrorMessage?.message)
            dispatch({ type: "RESET_CREATE_LEAVE_SETTINGS" })
        }
    }, [createLeaveSettingsSuccess, createLeaveSettingsFailure]);

    useEffect(() => {
        if (createLeaveMetaSuccess) {
            let temp_state = state.leaveTypeList;//[...state.leaveTypeList, ...createLeaveMetaData];
            temp_state.splice(selectedIndex, 1)
            temp_state.push(createLeaveMetaData[0]);
            setState({ ...state, leaveTypeList: temp_state })
            setLoaderModule(false)
            closeModule()
            Alert({ description: 'Leave Type Created Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            dispatch({ type: "RESET_CREATE_LEAVE_META" })
        } else if (createLeaveMetaFailure) {
            setLoaderModule(false)
            showMessage("warning", leaveMetaErrorMessage?.message)
            dispatch({ type: "RESET_CREATE_LEAVE_META" })
        }
    }, [createLeaveMetaSuccess, createLeaveMetaFailure]);

    useEffect(() => {
        if (createWorkingDaysSuccess) {
            setWorkingDaysState(createWorkingDaysData)
            setLoaderModule(false)
            closeModule()
            Alert({ description: 'Created Working Days Successfully', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });

            dispatch({ type: "RESET_CREATE_WORKING_DAYS" })
        } else if (createWorkingDaysFailure) {
            setLoaderModule(false)
            showMessage("warning", workingDaysErrorMessage?.message)
            dispatch({ type: "RESET_CREATE_WORKING_DAYS" })
        }
    }, [createWorkingDaysSuccess, createWorkingDaysFailure]);

    useEffect(() => {
        if (updateWorkingDaysSuccess) {
            setWorkingDaysState(updateWorkingDaysData)
            setLoaderModule(false)
            Alert({ description: 'Updated Working Days Successfully', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            dispatch({ type: "RESET_UPDATE_WORKING_DAYS" })
        } else if (updateWorkingDaysFailure) {
            setLoaderModule(false)
            showMessage("warning", leaveSettingsErrorMessage?.message)
            dispatch({ type: "RESET_UPDATE_WORKING_DAYS" })
        }
    }, [updateWorkingDaysSuccess, updateWorkingDaysFailure]);

    useEffect(() => {
        if (updateLeaveMetaSuccess) {
            Alert({ description: 'No Of Leave Added Successfully', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            dispatch(getLeaveType({ "isActive": true }))
            setLoaderModule(false)
            closeModule()
            dispatch({ type: "RESET_UPDATE_LEAVE_META" })
        } else if (updateLeaveMetaFailure) {
            setLoaderModule(false)
            showMessage("warning", leaveMetaErrorMessage?.message)
            dispatch({ type: "RESET_UPDATE_LEAVE_META" })
        }
    }, [updateLeaveMetaSuccess, updateLeaveMetaFailure]);


    useEffect(() => {
        if (updateLeaveSettingsSuccess) {
            updateTable(updateLeaveSettingsData[0])
            dispatch({ type: "RESET_UPDATE_LEAVE_SETTINGS" })
        } else if (updateLeaveSettingsFailure) {
            setLoaderModule(false)
            showMessage("warning", leaveSettingsErrorMessage?.message)
            dispatch({ type: "RESET_UPDATE_LEAVE_SETTINGS" })
        }
    }, [updateLeaveSettingsSuccess, updateLeaveSettingsFailure]);

    const updateTable = (updatedItem) => {
        const temp_state = [...itemList];
        if (deleteModule) {
            let remainingList = _.remove(temp_state, function (n) {
                return n.leaveSettingsId != updatedItem.leaveSettingsId;
            });
            Alert({ description: 'Deleted Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(remainingList)
        } else {
            temp_state[selectedIndex] = updatedItem;
            Alert({ description: 'Updated Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(temp_state)
        }
        setLoaderModule(false)
        closeModule()
    }

    const onSelect = (index, item) => {
        if (index === 0) {
            setLeaveTypeModule(false)
            setInitialModule(true)
        } else if (index === 1) {
            setInitialModule(false)
            setLeaveTypeModule(true)
        } else if (index === 2) {
            setWorkingDaysModule(true)
            setInitialModule(false)
            setLeaveTypeModule(false)
        }
        setSelectedIndexTab(index)
    }

    const closeModule = () => {
        editData = false;
        leaveTypeData = false;
        setCreateModule(false)
        setDeleteModule(false)
    }

    const onEdit = async (data, index) => {
        leaveTypeData = true;
        const req = {
            leaveTypeId: data?.leaveTypeId || "",
            isActive: true
        }
        let dta = await asyncgetLeaveMeta(req)
        onEditForm(dta.data)
        setSelectedIndex(index)
        // setSelectedItem(dta.data)
    }

    const resetState = () => {
        setState({
            ...state,
            leaveSettingsName: "",
            leaveSettingsStart: "",
            leaveSettingsEnd: ""
        })
    }

    const onCreateForm = () => {
        resetState()
        editData = false;
        setCreateModule(true)
    }

    const onEditForm = (data, index) => {
        setState({
            ...state,
            leaveTypeId: data[0]?.leaveTypeId || "",
            noOfLeave: data[0]?.noOfLeave || "",
            description: data[0]?.description || "",
            isPaid: data[0]?.isPaid || "",
        })
        setSelectedItem(data?.[0] || {})

        leaveEditData = data.length > 0 ? true : false
        leaveTypeData = true;

        setCreateModule(true)
    }

    const onClearForm = () => {
        setState({
            ...state,
            startMonthId: "",
            autoRenew: false,
            endMonthId: "",
        })
    }

    const onDeleteItem = (data, index) => {
        // editData = false;
        setSelectedIndex(index)
        setSelectedItem(data)
        setDeleteModule(true)
    }

    const validateForm = async () => {
        const validatefom = customerFormRef.current.validateForm();
        // const validatedfom = leaveTypeFormRef.current.validateForm();
    }

    const onSelectStartMonth = (event, name) => {
        //event.persist()
        const selectedVal = event;
        const temp_state = [...state.monthList];
        const monthListFilter = _.filter(temp_state, (o) => o.monthId == selectedVal);

        let endMonthId;

        if (monthListFilter.length > 0) {
            endMonthId = monthListFilter[0].monthId != 1 ? monthListFilter[0].monthId - 1 : 12;
        } else {
            endMonthId = 12;
        }

        setState({
            ...state,
            [name]: selectedVal,
            endMonthId: endMonthId
        });

    }

    const onSubmitForm = () => {
        const requestDate = {
            startMonthId: state.startMonthId,
            endMonthId: state.endMonthId,
            autoRenew: state.autoRenew
        }
        if (editData) {
            setLoaderModule(true)
            dispatch(updateLeaveSettings(requestDate, itemList[0].leaveSettingsId))
        } else {
            setLoaderModule(true)
            dispatch(createLeaveSettings(requestDate))
        }
    }

    const onSubmitWorkingDays = () => {
        let dayIdArr = []
        let workingIdFilter = workingDaysState.filter((item) => { return item?.workingDaysId || null })
        editData = workingIdFilter.length > 0 ? true : false;
        let workingId = workingIdFilter[0]?.workingDaysId
        let filterState = workingDaysState.filter((item) => { return item.status === true })
        filterState.map((ele, idx) => {
            dayIdArr.push(ele.dayId)
        })
        const req = {
            dayIds: dayIdArr.toString()
        }
        if (editData) {
            setLoaderModule(true)
            dispatch(updateWorkingDays(req, workingId))
        } else {
            setLoaderModule(true)
            dispatch(createWorkingDays(req))
        }
    }

    const onSubmitLeaveType = () => {
        leaveTypeData = selectedItem.leaveMetaId != null ? true : false;
        const req = {
            leaveTypeId: state.leaveTypeId,
            noOfLeave: state.noOfLeave,
            isPaid: state?.isPaid || false
        }

        // return
        if (leaveEditData) {
            setLoaderModule(true)
            dispatch(updateLeaveMeta(req, selectedItem.leaveMetaId))
        } else {
            setLoaderModule(true)
            dispatch(createLeaveMeta(req))
        }
    }

    const handleCheckbox = async (event, index) => {
        if (pageItems.create && pageItems.update) {
            event.persist();
            let temp_state = [...workingDaysState]
            temp_state[index].status = event.target.checked
            setWorkingDaysState(temp_state)
        }
    }

    const {
        departmentName,
        description,
    } = state;

    const modelTitle = editData ? "Edit " : "Create ";
    const modelButtonLbl = editData ? "" : "Save";


    const renderActiveTabContent = (activeTab) => {
        switch (activeTab) {
            case "companyLeave":
                return (
                    <CSSTransition key="companyLeave" timeout={300} classNames="tab-transition">
                        <div className="ml-1">
                            <h5
                                className="title-border mb-4"
                                style={{ borderBottom: "3px groove #003473" }}
                            >
                                Company Settings
                            </h5>
                            <FormLayout
                                ref={customerFormRef}
                                onChangeCallback={{ onSelectStartMonth }}
                                defaultState={state}
                                setDefaultState={setState}
                                dynamicForm={companyLeaveSetingsForm}
                                noOfColumn={4}
                                onSubmit={onSubmitForm}
                            />
                            <>
                                {(pageItems.create || pageItems.update) && (
                                    <>
                                        <Button
                                            className="me-2"
                                            style={{ backgroundColor: lightTheme.primaryColor, border: "none", fontSize: "12px" }}
                                            onClick={onSubmitForm}
                                        >
                                            Submit
                                        </Button>
                                        <Button
                                            // className="ms-2"
                                            style={{ backgroundColor: 'white', color: "gray", border: "1px solid gray", fontSize: "12px" }}
                                            onClick={onClearForm}
                                        >
                                            Clear
                                        </Button>
                                    </>
                                )}
                            </>


                        </div>
                    </CSSTransition>
                );
            case "leaveMeta":
                return (
                    <CSSTransition key="leaveMeta" timeout={300} classNames="tab-transition">
                        <div className="ml-4">
                            <h5
                                className="title-border mb-4"
                                style={{ borderBottom: "3px groove #003473" }}
                            >
                                Working Days
                            </h5>

                            <div className="checkbox-container">
                                {(workingDaysState || []).map((ele, idx) => (
                                    <div key={idx} className="d-flex align-items-center">
                                        <label className="d-flex align-items-center mt-1">
                                            <input
                                                type="checkbox"
                                                name={ele?.status}
                                                checked={ele?.status}
                                                onChange={(event) => handleCheckbox(event, idx)}
                                                className="me-2"
                                            />
                                            <span>{ele.dayName || ""}</span>
                                        </label>
                                    </div>

                                ))}
                            </div>

                            {pageItems.create && <Button style={{ backgroundColor: lightTheme.primaryColor, border: "none", fontSize: "12px" }} className="d-flex justify-content-end mt-3" onClick={onSubmitWorkingDays}>
                                Save
                            </Button>}
                        </div>
                    </CSSTransition>
                );
            case "leaveType":
                return (
                    <CSSTransition key="leaveType" timeout={300} classNames="tab-transition">
                        <div className="ml-4">
                            <h5
                                className="title-border mb-4"
                                style={{ borderBottom: "3px groove #003473" }}
                            >
                                Leave Type
                            </h5>
                            <CustomTable
                                columns={columnLeaveType}
                                data={state?.leaveTypeList || []}
                                // tableName="leaveType"
                                state={state}
                                initialPage={1}
                            />
                        </div>
                    </CSSTransition>
                );
            default:
                return null;
        }
    };
    const { sidebarOpen } = useSidebar();

    return (
        <div style={sidebarOpen ? { marginLeft: "10px", marginTop: "20px" } : { marginLeft: "10px", marginTop: "-15px" }}>

            <View isOpen={createModule} size="md" savetitle={modelButtonLbl} onClose={setCreateModule} onSubmit={validateForm} title={`${modelTitle} Leave Type Settings`}>
                <FormLayout ref={customerFormRef} defaultState={state} setDefaultState={setState} dynamicForm={LeaveTypeSetingsForm} noOfColumn={1} onSubmit={onSubmitLeaveType} />
            </View>


            <SimpleCard /* description="Leave Settings" */ >
                <div style={{ marginLeft: "50px" }}>
                    <div className="tabs-header">
                        <ul className="nav nav-pills card-header-pills">
                            <li className="nav-item">
                                <button
                                    className={`nav-link ${activeTab === "companyLeave" ? "active" : ""}`}
                                    onClick={() => setActiveTab("companyLeave")}
                                >
                                    Company Leave
                                </button>
                            </li>
                            <li className="nav-item">
                                <button
                                    className={`nav-link ${activeTab === "leaveMeta" ? "active" : ""}`}
                                    onClick={() => setActiveTab("leaveMeta")}
                                >
                                    Leave Meta
                                </button>
                            </li>
                            <li className="nav-item">
                                <button
                                    className={`nav-link ${activeTab === "leaveType" ? "active" : ""}`}
                                    onClick={() => setActiveTab("leaveType")}
                                >
                                    Leave Type
                                </button>
                            </li>
                        </ul>
                    </div>


                    <div className="tab-content">
                        <TransitionGroup>
                            {renderActiveTabContent(activeTab)}
                        </TransitionGroup>
                    </div>
                </div>
            </SimpleCard>
        </div>
    );

    // export default Tabs;


};

export default LeaveSettings;
