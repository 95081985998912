import React, { useState, useCallback } from "react";
import Cropper from "react-easy-crop";
import getCroppedImg from "./cropImage";
import { Button } from "react-bootstrap";
import { dataURLtoFile } from "./URLConverter";

const ImageCropper = ({
  image,
  croppedImg,
  setCroppedImg,
  againCrop,
  setAgainCrop,
  handleFileSelect,
  closeModule,
  state,
  setState,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        image,
        croppedAreaPixels,
        rotation
      );
      if (croppedImage) {
        const convertDataUrl = croppedImage.toDataURL("image/jpeg");
        setCroppedImg(convertDataUrl);
        setAgainCrop(true);
        closeModule(false);

        const convertFileUrl = dataURLtoFile(convertDataUrl, "cropped-image.jpeg");
        setState({
          ...state,
          profilePic: convertDataUrl,
          // profilePicFileType: convertFileUrl,
        });
      }
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation]);

  return (
    <div>
      <div>
        {againCrop ? (
          <img className="img-fluid" src={croppedImg} alt="" />
        ) : (
          <div>
            <div>
              <div>
                <div style={{ position: "relative", width: "100%", height: "300px" }}>
                  <Cropper
                    image={image}
                    crop={crop}
                    rotation={rotation}
                    zoom={zoom}
                    aspect={4 / 4}
                    onCropChange={setCrop}
                    onRotationChange={setRotation}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                  />
                </div>
              </div>
              <div>
                <div style={{ display: "flex", justifyContent: "space-between", paddingTop: "1rem", marginTop: "1rem" }}>
                  <div style={{ display: "flex", alignItems: "center", width: "50%" }}>
                    <span style={{ marginRight: "1rem", fontSize: "1.25rem" }}>ZOOM</span>
                    <input
                      value={zoom}
                      type="range"
                      style={{ width: "100%" }}
                      onChange={(e) => setZoom(e.target.value)}
                      max="10"
                      step="0.1"
                    />
                  </div>
                  <div style={{ display: "flex", alignItems: "center", width: "50%" }}>
                    <span style={{ marginRight: "1rem", fontSize: "1.25rem" }}>ROTATION</span>
                    <input
                      value={rotation}
                      type="range"
                      style={{ width: "100%" }}
                      onChange={(e) => setRotation(e.target.value)}
                      min="0"
                      max="360"
                      step="1"
                    />
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "center", marginTop: "1rem" }}>
                  <div>
                    <label htmlFor="upload-single-file">
                      <Button style={{ borderRadius: "5px", border: "none", marginRight: "1rem" }} as="span">
                        <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
                          <i className="i-Share-on-Cloud"></i>
                          <span>Upload image</span>
                        </div>
                      </Button>
                    </label>
                    <input
                      style={{ display: "none" }}
                      onChange={(e) => handleFileSelect(e)}
                      id="upload-single-file"
                      type="file"
                      accept="image/*"
                    />
                  </div>
                  <button
                    onClick={() => showCroppedImage()}
                    style={{ borderRadius: "5px", border: "none", backgroundColor: "#007bff", color: "#fff", padding: "0.375rem 0.75rem" }}
                  >
                    {againCrop ? "Crop Again" : "Crop And Save"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageCropper;
