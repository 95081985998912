import React from 'react';
import {TimePicker as AntdTimePicker} from 'antd';
import PropTypes from 'prop-types';
import './styles.css';

const { RangePicker: AntdRangePicker } = AntdTimePicker;
const classNames = require('classnames');

const TimePicker = ({value, onChange, format, size,placeholder, range}) => {
    const className = classNames({
        'ax-date-picker': true,  
        [size]: size
    });

    const props = {value, onChange, format,placeholder};

    return (
        <>
            {!range && <AntdTimePicker getPopupContainer={trigger => trigger.parentNode} className={className}   {...props} />}
            {range && <AntdRangePicker getPopupContainer={trigger => trigger.parentNode} className={className} {...props} />}
        </>
    );
};

TimePicker.propTypes = {
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
    format: PropTypes.string,
    range: PropTypes.bool,
};
TimePicker.defaultProps = {
    format: 'HH:mm:ss',
    range: false,
};

export default TimePicker;
