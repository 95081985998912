import { returnApiCallPromise } from './ApiContent';
import { subject } from './APIContainer';

//GET
export function getSubject(request) {
  const requestObj = request ? { params: request } : "";
  return (dispatch) => {
    returnApiCallPromise("GET", subject, requestObj)
      .then(response => {
        dispatch({
          type: 'GET_SUBJECT_SUCCESS',
          payload: response.data
        })
      }).catch(error => {
        
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'GET_SUBJECT_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}


export function asyncGetSubjectInfo(request) {
  const requestObj = request ? { params: request } : "";
  return  returnApiCallPromise("GET", subject, requestObj)
      .then(response => {
        console.log(response)
        return {
          error: false,
          message:"Succsss",
          data: response?.data?.data || []
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        return {
          error: true,
          data:[],
          message:errorMessage
        }
      })
}

export function getAsyncSubject(request) {
  const requestObj = request ? { params: request } : "";
  return (
    returnApiCallPromise("GET", subject, requestObj)
    .then(response => {
      const result = {
        getSubjectSuccess: true,
        data: response.data.data
      }
     
      return result;
    }).catch(error => {

      let errorMessage = error;
      if (error.response) {
        if (error.response.data.error) {
          errorMessage = error.response.data.message;
        }
      }

      const result = {
        getTaxFailed: true,
        errorMessage: errorMessage
      }
     
      return result;
    })
  )
}

//POST
export function createSubject(request) {

  return (dispatch) => {
    returnApiCallPromise("POST", subject, request)
      .then(response => {
        if (response.data.error) {
          dispatch({
            type: 'CREATE_SUBJECT_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'CREATE_SUBJECT_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'CREATE_SUBJECT_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

export function updateSubject(request, subjectId) {
  return (dispatch) => {
    returnApiCallPromise("PUT", subject+"/"+subjectId, request)
      .then(response => {

        if (response.data.error) {
          dispatch({
            type: 'UPDATE_SUBJECT_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'UPDATE_SUBJECT_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'UPDATE_SUBJECT_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}