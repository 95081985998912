
const feesTypeForm = [
  {
      title: "Basic Information",
      formBody: [
          {
              formField: [
                  {
                      label: "Fees Type Name",
                      inputType: "text",
                      placeholder: "Enter Fees Type Name",
                      fieldName: "feesTypeName",
                      defaultValue: "",
                      config: {
                          required: "required",
                      },
                  },
                  {
                      label: "Installment",
                      inputType: "number",
                      type: "number",
                      placeholder: "Enter Count Installment",
                      fieldName: "installment",
                      defaultValue: "",
                      config: {
                          required: "required",
                      },
                  },
              ]
          }
      ]
  }
];

export {
  feesTypeForm
};