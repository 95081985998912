
const visitorTypeForm = [
  {
      title: "Basic Information",
      formBody : [
          {
              formField: [
                 /*  {
                      label: "Visitor Code",
                      inputType: "text",
                      placeholder: "Enter Visitor Code",
                      fieldName:"visitorCode",
                      defaultValue: "",
                      config: {
                          required: "required",
                      },
                  },    */ 
                  {
                      label: "Visitor Type",
                      inputType: "text",
                      placeholder: "Enter Visitor Type",
                      fieldName:"visitorType",
                      defaultValue: "",
                      config: {
                          required: "required",
                      },
                  },
                  {
                      label: "Description",
                      inputType: "textarea",
                      placeholder: "Enter description",
                      fieldName:"description",
                      defaultValue: "",
                      config: {
                          required: "required",
                      },
                  }
                                                                   
              ]
          }
      ]
  }
];

export {
  visitorTypeForm
};