const studentAttendanceForm = [
    {
        title: "Basic Information",
        formBody : [
            {
                formField: [
                    {
                        label: "",
                        inputType: "switch",
                        fieldName:"attendanceStatusName",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                                                                     
                ]
            }
        ]
    }
]; 

const attendanceFilterForm = [
    {
        title: "Basic Information",
        formBody : [
            {
                formField: [
                    {
                        label: "Batch Name",
                        inputType: "select",
                        options: "batchList",
                        uniqueKey:"batchId",
                        displayKey:"batchName",
                        multiple:false,
                        fieldName:"batchName",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }                                                                                     
                ]
            },
            {
                formField: [
                    {
                        label: "Class -Section",
                        inputType: "select",
                        options: "enrollmentDetailsList",
                        uniqueKey:"enrollmentId",
                        displayKey:"classTitle",
                        subDisplayKey:"sectionTitle",
                        // multiple:true,
                        fieldName:"enrollmentId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }                                                                                    
                ]
            },
            {
                formField: [
                    {
                        label: "Attendance Type",
                        inputType: "select",
                        options: "attendanceTypeList",
                        uniqueKey:"attendanceTypeId",
                        displayKey:"attendanceTypeName",
                        multiple:false,
                        fieldName:"attendTypeId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }                                                                                     
                ]
            },{
                formField: [
                    {
                        label: "Attendance Date",
                        inputType: "date",
                        options: "attendanceTypeList",
                        uniqueKey:"attendanceTypeId",
                        displayKey:"attendanceDate",
                        multiple:false,
                        fieldName:"attendanceDate",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }                                                                                     
                ]
            },
            /* {
                label: "Attendance Date",
                inputType: "date",
                fieldName:"attendanceDate",
                defaultValue: "",
                config: {
                    required: "required",
                },
            }, */
        ]
    }
];

export {
    studentAttendanceForm,
    attendanceFilterForm
};