const initialState = {
    visitorLogList: []  
  }
  
  const VisitorLogReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_VISITOR_LOG_SUCCESS": {             
        return {
          ...state,
          getVisitorLogSuccess: true,
          getVisitorLogList: action.payload.data,
        };
      }
      case "GET_VISITOR_LOG_FAILURE": {
        return {
          ...state,
          getVisitorLogFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_VISITOR_LOG": {
        return {
          ...state,
          getVisitorLogSuccess: false,
          getVisitorLogFailure: false,
          getVisitorLogList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_VISITOR_LOG_SUCCESS": {             
        return {
          ...state,
          createVisitorLogSuccess: true,
          createVisitorLogData: action.payload.data,
        };
      }
      case "CREATE_VISITOR_LOG_FAILURE": {
        return {
          ...state,
          createVisitorLogFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_VISITOR_LOG": {
        return {
          ...state,
          createVisitorLogSuccess: false,
          createVisitorLogFailure: false,
          createVisitorLogData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_VISITOR_LOG_SUCCESS": {             
        return {
          ...state,
          updateVisitorLogSuccess: true,
          updateVisitorLogData: action.payload.data,
        };
      }
      case "UPDATE_VISITOR_LOG_FAILURE": {
        return {
          ...state,
          updateVisitorLogFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_VISITOR_LOG": {
        return {
          ...state,
          updateVisitorLogSuccess: false,
          updateVisitorLogFailure: false,
          updateVisitorLogData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default VisitorLogReducer
  
  