import * as yup from "yup";
import { Formik } from "formik";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import DateTime from "react-datetime";
import { Form, Button } from "react-bootstrap";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { Table } from "react-bootstrap";
import { dateConversion, decimalAmount, pagePermission, showMessage } from "app/utils/app-functions";
import FormLayout from "app/components/render/formlayout";
import View from "app/components/atoms/modalView/Modalview";
import CustomTable from "app/components/atoms/custom-table/CustomTable";
import formRender from "app/components/render/formcomponent";
import SimpleCard from 'app/components/atoms/simple-card/SimpleCard'
import { FiEdit, FiSend, FiTrash2 } from 'react-icons/fi';
import CollapseView from "app/components/atoms/CollapseView/CollapseView";
import TableLayout from "app/components/render/formlayout/tablelayout";
import { inventoryAddForm, innerForm, innerClickForm, fieldList, inner, subInner } from "./formData";
import { MdEdit, MdDelete, MdAddTask } from "react-icons/md";
import swal from "sweetalert2";
import _ from "lodash";
import { createInventoryAdjustment, getInventoryAdjustment, updateInventoryAdjustment } from "app/api/InventoryAdjustmentApi";
import { getSupplier, createSupplier } from "app/api/SupplierApi";
import { th, tr } from "date-fns/locale";
import { getWarehouse } from "app/api/WarehouseApi";
import { getLocation } from "app/api/LocationApi";
import { getMaterialType } from "app/api/MaterialTypeApi";
import { getMaterialCode } from "app/api/MaterialCodeApi";
import { getDelivery } from "app/api/DeliveryApi";
import { getTaxCode } from "app/api/TaxCodeApi";
import { materialCode, taxCode } from "app/api/APIContainer";
import { getShowMaterial } from "app/api/ShowMaterialApi";
import constants from "./constants";
import { getMeasurement } from "app/api/MeasurementApi";
import moment from "moment";
import Alert from "app/components/atoms/alert";
import { lightTheme } from "styles/theme";

let editData = false;

const InventoryAdjustment = ({ navigation, ...props }) => {
  const dispatch = useDispatch();
  const customerFormRef = useRef();
  const jobHistoryModel = useRef();

  const getInventoryAdjustmentSuccess = useSelector((state) => state.inventoryAdjustmentReducer.getInventoryAdjustmentSuccess);
  const getInventoryAdjustmentList = useSelector((state) => state.inventoryAdjustmentReducer.getInventoryAdjustmentList);
  const getInventoryAdjustmentFailure = useSelector((state) => state.inventoryAdjustmentReducer.getInventoryAdjustmentFailure);
  const getInventoryAdjustmentErrorMessage = useSelector((state) => state.inventoryAdjustmentReducer.getInventoryAdjustmentErrorMessage);

  const createInventoryAdjustmentSuccess = useSelector((state) => state.inventoryAdjustmentReducer.createInventoryAdjustmentSuccess);
  const createInventoryAdjustmentData = useSelector((state) => state.inventoryAdjustmentReducer.createInventoryAdjustmentData);
  const createInventoryAdjustmentFailure = useSelector((state) => state.inventoryAdjustmentReducer.createInventoryAdjustmentFailure);
  const createInventoryAdjustmentErrorMessage = useSelector((state) => state.inventoryAdjustmentReducer.createInventoryAdjustmentErrorMessage);

  const updateInventoryAdjustmentSuccess = useSelector((state) => state.inventoryAdjustmentReducer.updateInventoryAdjustmentSuccess);
  const updateInventoryAdjustmentData = useSelector((state) => state.inventoryAdjustmentReducer.updateInventoryAdjustmentData);
  const updateInventoryAdjustmentFailure = useSelector((state) => state.inventoryAdjustmentReducer.updateInventoryAdjustmentFailure);
  const updateInventoryAdjustmentErrorMessage = useSelector((state) => state.inventoryAdjustmentReducer.updateInventoryAdjustmentErrorMessage);

  const getShowMaterialSuccess = useSelector((state) => state.showMaterialReducer.getShowMaterialSuccess);
  const getShowMaterialList = useSelector((state) => state.showMaterialReducer.getShowMaterialList);
  const getShowMaterialFailure = useSelector((state) => state.showMaterialReducer.getShowMaterialFailure);
  const showMaterialErrorMessage = useSelector((state) => state.showMaterialReducer.showMaterialErrorMessage);

  const getWarehouseSuccess = useSelector((state) => state.warehouseReducer.getWarehouseSuccess);
  const getWarehouseList = useSelector((state) => state.warehouseReducer.getWarehouseList);
  const getWarehouseFailure = useSelector((state) => state.warehouseReducer.getWarehouseFailure);
  const warehouseErrorMessage = useSelector((state) => state.warehouseReducer.errorMessage);

  const getLocationSuccess = useSelector((state) => state.locationReducer.getLocationSuccess);
  const getLocationList = useSelector((state) => state.locationReducer.getLocationList);
  const getLocationFailure = useSelector((state) => state.locationReducer.getLocationFailure);
  const locationErrorMessage = useSelector((state) => state.locationReducer.locationErrorMessage);

  const getMaterialTypeSuccess = useSelector((state) => state.materialTypeReducer.getMaterialTypeSuccess);
  const getMaterialTypeList = useSelector((state) => state.materialTypeReducer.getMaterialTypeList);
  const getMaterialTypeFailure = useSelector((state) => state.materialTypeReducer.getMaterialTypeFailure);
  const materialTypeErrorMessage = useSelector((state) => state.materialTypeReducer.materialTypeErrorMessage);

  const getMaterialCodeSuccess = useSelector((state) => state.materialCodeReducer.getMaterialCodeSuccess);
  const getMaterialCodeList = useSelector((state) => state.materialCodeReducer.getMaterialCodeList);
  const getMaterialCodeFailure = useSelector((state) => state.materialCodeReducer.getMaterialCodeFailure);
  const materialCodeErrorMessage = useSelector((state) => state.materialCodeReducer.materialCodeErrorMessage);

  const getMeasurementSuccess = useSelector((state) => state.measurementReducer.getMeasurementSuccess);
  const getMeasurementList = useSelector((state) => state.measurementReducer.getMeasurementList);
  const getMeasurementFailure = useSelector((state) => state.measurementReducer.getMeasurementFailure);
  const measurementErrorMessage = useSelector((state) => state.measurementReducer.errorMessage);


  const [pageItems, setpageItems] = useState({});

  useEffect(() => {
    let data = pagePermission(pageItems, "Inventory", "Inventory Adjustment" ,"Inventory")
    setpageItems(data)
    console.log(data);
  }, [navigation]);

  const column = [
    {
      "dataField": "inventoryAdjustmentNumber",
      "text": "InventoryAdjustment Number",
      "sort": true
    },
    {
      "dataField": "fromWarehouse",
      "text": "From Warehouse",
      "sort": true
    },
    {
      "dataField": "dateOfAdjustment",
      "text": "Date Of Adjustment",
      "sort": true,
      formatter: (cellContent, row, index) => {
        return dateConversion(cellContent, "DD-MMM-YYYY")
      }
    },
    {
      "dataField": "memo",
      "text": "Memo",
      "sort": true,

    },
    {
      "dataField": "reason",
      "text": "Reason ",
      "sort": false
    },
    {
      dataField: "orderStatusId",
      text: "Status",
      sort: true,
      align: "center",
      headerAlign: "center",
      formatter: (cellContent, row, index) => {
        const conInfo = { inputType: "status", status: cellContent }
        return formRender(null, null, conInfo, null);
      }
    },
  ]
  if (pageItems.update) {
    column.push({
      text: "Action",
      sort: false,
      align: "center",
      headerAlign: "center",
      formatter: (cellContent, row, index) => (
        <div className="icon-container">
          {pageItems.update && <FiEdit
            className="text-success cursor-pointer"
            size={18}
            onClick={() => onEditForm(row, index)}
          ></FiEdit>}


        </div>
      )
    },)
  }

  const formColumn = [
    {
      "dataField": "materialCode",
      "text": "Material Code",
      "sort": true
    },
    {
      "dataField": "materialName",
      "text": "Material Name",
      "sort": true
    },
    {
      "dataField": "Qty",
      "text": "Qty",
      "sort": true
    },
    {
      "dataField": "unit",
      "text": "Unit ",
      "sort": false
    },
    {
      "dataField": "price",
      "text": "Dimension",
      "sort": false
    },
    {
      "dataField": "total",
      "text": "Unit Cost",
      "sort": false
    },
    {
      "dataField": "taxCode",
      "text": "Type",
      "sort": false
    },
    {
      "dataField": "totalTax",
      "text": "Reason",
      "sort": false
    },
    {
      "dataField": "totalTax",
      "text": "Remark",
      "sort": false
    },
    {
      "dataField": "additem",
      "text": "Add Item Details",
      "sort": false
    },
    {
      "dataField": "action",
      "text": "Action",
      "sort": false
    },
  ]

  const innerHeading = [
    {
      "dataField": "serialNumber",
      "text": "Warehouse",
      "sort": true
    },
    {
      "dataField": "purchaseName",
      "text": "Receipt Location",
      "sort": true
    },

    {
      "dataField": "purchaseName",
      "text": "Batch Number",
      "sort": true
    },
    {
      "dataField": "purchaseName",
      "text": "Serial Number",
      "sort": true
    },
    {
      "dataField": "purchaseName",
      "text": "Length(mm)",
      "sort": true
    },
    {
      "dataField": "purchaseName",
      "text": "Width(mm)",
      "sort": true
    },
    {
      "dataField": "description",
      "text": "Thickness ",
      "sort": false
    },
    {
      "dataField": "action",
      "text": "Action ",
      "sort": false
    }


  ]
  const itemHeader = [

    {
      "dataField": "status",
      "text": "",
      formatter: (cellContent, row, index, extraData) => {

        const conInfo = { inputType: "checkbox", status: (cellContent ? cellContent : false) }
        return formRender(extraData, null, conInfo, null, null, statusChange, index);
      }
    },

    {
      "dataField": "serialNumber",
      "text": "Serial Number",
      "sort": true
    },
    {
      "dataField": "batchNumber",
      "text": "Batch Number",
      "sort": true
    },
    {
      "dataField": "locationName",
      "text": "Receipt Location",
      "sort": true
    },
    {
      "dataField": "warehouseName",
      "text": "Warehouse",
      "sort": true
    },
    {
      "dataField": "itemHeight",
      "text": "Length",
      "sort": true
    },
    {
      "dataField": "itemWidth",
      "text": "Width",
      "sort": false
    },
    {
      "dataField": "thickness",
      "text": "Thickness ",
      "sort": false
    }


  ]

  const innerTableList = [
    {
      "dataField": "purchaseName",
      "text": "Warehouse",
      "sort": true
    },
    {
      "dataField": "purchaseName",
      "text": "Receipt Location",
      "sort": true
    },
    {
      "dataField": "purchaseName",
      "text": "Length(mm)",
      "sort": true
    },
    {
      "dataField": "purchaseName",
      "text": "Width(mm)",
      "sort": true
    },
    {
      "dataField": "description",
      "text": "Thickness ",
      "sort": false
    },
    {
      "dataField": "description",
      "text": "Action ",
      "sort": false
    },
  ]
  const [state, setState] = useState({
    warehouseList: [],
    locationList: [],
    materialTypeList: [],
    materialCodeList: [],
    unitsList: [],
    qty: '',
    UnitId: '',
    unitPrice: '',
    dateOfAdjustment: new Date(),
    deliveryOrderDate: new Date(),
    memo: "",
    materialCodeId: '',
    deliveryOrderDate: '',
    checkCount: '',
    materialId: '',
    statusList: constants.statusList,

  });


  const [tableRows, setTableRows] = useState([]);
  const [createModule, setCreateModule] = useState(false);
  const [createForm, setCreateForm] = useState(false);
  const [loaderModule, setLoaderModule] = useState(false);
  const [deleteModule, setDeleteModule] = useState(false);
  const [itemAdded, setItemAdded] = useState(false);
  const [itemList, setItemList] = useState([]);
  const [selectedItem, setSelectedItem] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(false);
  const [formList, setFormlist] = useState(fieldList);
  const [selectedmaterial, setSelectMaterial] = useState([])
  const [showForm, setShowForm] = useState(false)


  useEffect(() => {
    const req = {
      ordrStatusId: "close"
    }
    setLoaderModule(true)
    dispatch(getInventoryAdjustment())
    let aa = listFetching()

  }, [navigation]);

  useEffect(() => {
    if (getInventoryAdjustmentSuccess) {
      setItemList(getInventoryAdjustmentList)
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_INVENTORY_ADJUSTMENT" })
    } else if (getInventoryAdjustmentFailure) {
      setItemList([])
      showMessage("warning", getInventoryAdjustmentErrorMessage?.message)
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_INVENTORY_ADJUSTMENT" })
    }
  }, [getInventoryAdjustmentSuccess, getInventoryAdjustmentFailure]);

  useEffect(() => {
    if (getShowMaterialSuccess) {
      const parentIndex = selectedIndex
      const parentRows = [...tableRows];
      const itemInfo = parentRows[parentIndex]?.itemDetails || [];
      const filter_data = _.filter(getShowMaterialList, (item, index) =>
        _.findIndex(itemInfo, (n) => n.itemDetailsId === item.itemDetailsId) === -1
      )

      setSelectMaterial(filter_data)
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_SHOW_MATERIAL" })
    } else if (getShowMaterialFailure) {
      setSelectMaterial([])

      showMessage("warning", showMaterialErrorMessage?.message)
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_SHOW_MATERIAL" })
    }
  }, [getShowMaterialSuccess, getShowMaterialFailure]);


  useEffect(() => {
    if (getWarehouseSuccess) {
      setState({
        ...state,
        warehouseList: getWarehouseList
      })
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_WAREHOUSE" })
    } else if (getWarehouseFailure) {
      setState({
        ...state,
        warehouseList: []
      })
      showMessage("warning", warehouseErrorMessage?.message)
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_WAREHOUSE" })
    }
  }, [getWarehouseSuccess, getWarehouseFailure]);

  useEffect(() => {
    if (getLocationSuccess) {
      setState({
        ...state,
        locationList: getLocationList
      })
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_LOCATION" })
    } else if (getLocationFailure) {
      setState({
        ...state,
        locationList: []
      })
      showMessage("warning", locationErrorMessage?.message)
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_LOCATION" })
    }
  }, [getLocationSuccess, getLocationFailure]);

  useEffect(() => {
    if (getMaterialTypeSuccess) {
      setState({
        ...state,
        materialTypeList: getMaterialTypeList
      })
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_MATERIALTYPE" })
    } else if (getMaterialTypeFailure) {
      setState({
        ...state,
        materialTypeList: getMaterialTypeList
      })
      showMessage("warning", materialTypeErrorMessage?.message)
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_MATERIALTYPE" })
    }
  }, [getMaterialTypeSuccess, getMaterialTypeFailure]);

  useEffect(() => {
    if (getMaterialCodeSuccess) {
      setState({
        ...state,
        materialCodeList: getMaterialCodeList
      })
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_MATERIACODE" })
    } else if (getMaterialCodeFailure) {
      setState({
        ...state,
        materialCodeList: []
      })
      showMessage("warning", materialCodeErrorMessage?.message)
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_MATERIACODE" })
    }
  }, [getMaterialCodeSuccess, getMaterialCodeFailure]);

  useEffect(() => {
    if (getMeasurementSuccess) {
      setState({
        ...state,
        unitsList: getMeasurementList
      })
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_MEASUREMENT" })
    } else if (getMeasurementFailure) {
      setState({
        ...state,
        unitsList: []
      })
      showMessage("warning", measurementErrorMessage?.message)
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_MEASUREMENT" })
    }
  }, [getMeasurementSuccess, getMeasurementFailure]);


  useEffect(() => {
    if (createInventoryAdjustmentSuccess) {
      dispatch(getInventoryAdjustment())

      const temp_state = [...itemList, createInventoryAdjustmentData[0]];
      setItemList(temp_state)
      setLoaderModule(false)
      closeModule()
      Alert({ description: 'Created Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
      dispatch({ type: "RESET_CREATE_INVENTORY_ADJUSTMENT" })
    } else if (createInventoryAdjustmentFailure) {
      setLoaderModule(false)
      showMessage("warning", getInventoryAdjustmentErrorMessage?.message)
      dispatch({ type: "RESET_CREATE_INVENTORY_ADJUSTMENT" })
    }
  }, [createInventoryAdjustmentSuccess, createInventoryAdjustmentFailure]);


  useEffect(() => {
    if (updateInventoryAdjustmentSuccess) {
      dispatch(getInventoryAdjustment())

      updateTable(updateInventoryAdjustmentData[0])
      dispatch({ type: "RESET_UPDATE_INVENTORY_ADJUSTMENT" })
    } else if (updateInventoryAdjustmentFailure) {
      setLoaderModule(false)
      showMessage("warning", getInventoryAdjustmentErrorMessage?.message)
      dispatch({ type: "RESET_UPDATE_INVENTORY_ADJUSTMENT" })
    }
  }, [updateInventoryAdjustmentSuccess, updateInventoryAdjustmentFailure]);

  const updateTable = (updatedItem) => {
    const temp_state = [...itemList];
    if (deleteModule) {
      let remainingList = _.remove(temp_state, function (n) {
        return n.inventoryAdjustmentId != selectedItem.inventoryAdjustmentId;
      });
      Alert({ description: 'Deleted Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
      setItemList(remainingList)
    } else {
      temp_state[selectedIndex] = updatedItem;
      Alert({ description: 'Updated Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
      setItemList(temp_state)
    }
    setLoaderModule(false)
    closeModule()
  }

  const closeModule = () => {
    editData = false;
    setCreateModule(false)
    setDeleteModule(false)
  }

  const resetState = () => {
    setState({
      ...state,
      receiptWarehouse: "",
      memoData: "",
      reasonData: "",
      warehouseId: '',
      purchaseOrderDate: new Date(),
      deliveryOrderDate: new Date(),
      itemDetails: [],
      dateOfAdjustment: moment(new Date(), "YYYY-MM-DD"),
    })
    setTableRows([]);
  };

  const onCreateForm = () => {
    resetState()
    editData = false;
    setCreateModule(true)
  }

  const handleWarehouse = (event, index, name) => {
    let temp_state = ""
    temp_state = {
      ...state,
      [name]: event,
      receiptWarehouse: event.length > 0 ? event[0].id : ""
    }
    setState(temp_state)

  }

  const onEditForm = (data, index) => {
    const inventoryOrderItem = data?.inventoryAdjustmentItem || [];
    console.log(JSON.stringify(inventoryOrderItem))
    setState({
      ...state,
      memo: data?.memo,
      warehouseId: data?.fromWarehouse,
      memoData: data?.memo,
      reasonData: data?.reason,
      // purchaseOrderDate: new Date(),
      dateOfAdjustment: moment(data.dateOfAdjustment, "YYYY-MM-DD"),
      productTax: data?.includeProductTax || false,
      priceGst: data.includingGST,
      amount: data?.amount,
    })
    try {
      if (inventoryOrderItem) {
        inventoryOrderItem.map((item, index) => {
          item.materialCodeId = item?.materialId || null;
          item.itemIcon = "";
          let newFieldList = [...formList];
          const addIndex = newFieldList.findIndex(field => field.fieldName === 'itemIcon');
          if (item.type === 'in' && addIndex > -1) {
            newFieldList[addIndex] = { ...newFieldList[addIndex], onClick: "onAddItem" };
          } else if (item.type === 'out' && addIndex > -1) {
            newFieldList[addIndex] = { ...newFieldList[addIndex], onClick: "onOutlineClock" };

          }
          setFormlist(newFieldList);
        });
      }

    } catch (error) {
      console.error("An error occurred:", error);
    }
    editData = true;
    setSelectedIndex(index);
    setSelectedItem(data);
    if (inventoryOrderItem) {
      const newTableRows = inventoryOrderItem.map((item) => ({
        inventoryAdjustmentMaterialId: item?.inventoryAdjustmentMaterialId || null,
        inventoryAdjustmentId: item?.inventoryAdjustmentId || null,
        materialCodeId: item?.materialId || null,
        materialId: item?.materialId || "",
        materialTypeId: item?.materialTypeId || "",
        materialCodeId: item?.materialCode || "",
        unit: item?.unit || "",
        materialName: item?.materialName || "",
        qty: item?.qty || "",
        receivedQty: item?.receivedQty || "",
        price: item?.price || "",
        dimension: item?.dimension || "",
        unitPrice: item?.unitPrice || "",
        reason: item?.reason || "",
        remark: item?.remark || "",
        type: item?.type || "",

        ...(item.itemDetails && {
          itemDetails: item.itemDetails.map((detail) => ({
            ...detail,
            itemDetailsId: detail.itemDetailsId ? detail.itemDetailsId : null,
            warehouseId: detail.receiptWarehouse ? detail.receiptWarehouse : null,
            locationId: detail.receiptLocation ? detail.receiptLocation : null,
            length: detail?.itemHeight || null,
            width: detail?.itemWidth || null,
          })),

        }),

      }));

      setTableRows(newTableRows);
    }
    // setTableRows(inventoryOrderItem);
    setCreateModule(true);
    setItemAdded(true)
  };
  const confromDialog = () => {
    return (
      swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          type: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
          cancelButtonText: "No",
        })
        .then((result) => {
          if (result.value) {
            onSubmitForm()
          } else {
            swal.fire({
              title: "Cancelled!",
              text: "Permission denied.",
              type: "error",
              icon: "error",
              timer: 1500,
            });
          }
        })
    )
  }

  useEffect(() => {
    if (deleteModule) {
      confromDialog()
    }
  }, [deleteModule])

  const onDeleteItem = (data, index) => {
    editData = false;
    setSelectedItem(data)
    setSelectedIndex(index)
    setDeleteModule(true)
  }

  const onDeletePress = (index) => {
    const updatedTableRows = [...tableRows.slice(0, index), ...tableRows.slice(index + 1)];
    setTableRows(updatedTableRows);
  }

  const onSearch = () => {
    setCreateForm(true);
  }
  const modelTitle = editData ? "Edit " : "Create ";
  const modelTitle2 = editData ? "Edit " : "Create New ";
  const modelButtonLbl = editData ? "" : "Save";

  var itemCount = 0;
  const addRow = () => {
    const defaultNewOrderObject = {
      itemCount: 0,
      materialId: '',
      materialCodeId: '',
      materialName: '',
      qty: "",
      unit: '',
      type: 0,
      reason: '',
      remark: '',
      unitPrice: "",
      totalPrice: "",
      dimension: '',
      itemIcon: "",
      itemDetails: [

      ]
    };
    itemCount++;
    setTableRows([...tableRows, defaultNewOrderObject]);
    setItemAdded(false)
  };

  const onSubmitItemDetails = (index) => {
    const temp_state = [...tableRows];
    const selectedRow = temp_state[index];
    if (selectedRow) {
      const itemDetails = selectedRow.itemDetails || [];
      const warehouseId = selectedRow.receiptWarehouse || 0;
      const locationId = selectedRow.receiptLocation || 0;
      const Batchnumber = selectedRow.batchNumber || 0;
      let updatedItemDetails = itemDetails.map((item) => ({
        ...item,
        receiptWarehouse: warehouseId,
        receiptLocation: locationId,
        batchNumber: Batchnumber

      }));
      temp_state[index].itemDetails = updatedItemDetails;
      setTableRows(temp_state);
    }
  }

  const onDeleteInner = (detailIndex, parentIndex) => {
    const updatedTableRows = [...tableRows];
    updatedTableRows[parentIndex].itemDetails.splice(detailIndex, 1);
    setTableRows(updatedTableRows);
  }

  const handleChange = (event, name, index, parentIndex) => {
    event.persist()
    const numberValue = event.target.value
    let temp_state = [...tableRows]
    temp_state[parentIndex]["itemDetails"][index][event.target.name] = event.target.value;
    setTableRows(temp_state)
  }

  const handlewarehousechange = (event, name, index) => {
    let temp_state = [...tableRows]
    if (event?.target?.value) {
      temp_state[index][name] = event.target.value
    }
    else {
      temp_state[index][name] = event
    }

    setTableRows(temp_state)
  }


  const handlelocationchange = (event, name, index) => {
    let temp_state = [...tableRows]
    if (event?.target?.value) {
      temp_state[index][name] = event.target.value
    }
    else {
      temp_state[index][name] = event
    }
    setTableRows(temp_state)
  }

  const handleTypeChange = (val, index, stateName, parentIndex) => {
    let temp_state = [...tableRows]
    temp_state[parentIndex]["itemDetails"][index][stateName] = val.target.value;
    setTableRows(temp_state)
  }

  const onAddItem = (index) => {
    const temp_state = [...tableRows];
    // temp_state[index].itemIcon = ""


    if (temp_state[index].materialId === "") {
      // showMessage("warning", "Select Material")
      Alert({ description: 'Select Material.', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });

    } else {
      const qtyVal = temp_state[index].qty;
      const prevQty = temp_state[index].itemDetails.length || 0;
      let newItemDetails = [];
      const diff = qtyVal - prevQty;
      if (diff > 0) {
        for (let i = 0; i < diff; i++) {
          const defaultItem = {
            receiptWarehouse: "",
            receiptLocation: "",
            batchNumber: "",
            serialNumber: "",
            itemHeight: "",
            itemWidth: "",
            thickness: "",
            materialId: temp_state[index].materialId
          };
          newItemDetails.push(defaultItem);
        }
      } else {
        // showMessage("warning", "New quantity must be greater")
        Alert({ description: 'New quantity must be greater', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });

      }
      temp_state[index].itemDetails = [...temp_state[index].itemDetails, ...newItemDetails];
      temp_state[index].prevQty = qtyVal;
      setTableRows(temp_state)
      setItemAdded(true);
    }
  };

  // const expandView = (index, setRows) => {
  //   const totalItem = tableRows[index]?.itemDetails || [];
  //   return (
  //     <div>
  //       <h3 style={{ fontSize: '20px', fontWeight: '500' }}>Item Details</h3>
  //       {itemAdded && subInner.map((item, idx) => {
  //         return (
  //           <td key={{ idx }} style={{ paddingRight: "20px" }}>
  //             <TableLayout onSearch={() => onSubmitItemDetails(index)} iconPress={{ "onAddItem": onAddItem }} isField={true} onChangeCallback={{ "handlewarehousechange": handlewarehousechange, "handlelocationchange": handlelocationchange }} defaultState={state} setDefaultState={setState} index={index} layoutType="table" dataList={tableRows || []} setDataList={setTableRows} dynamicForm={item} /* style={{ textAlign: 'center' }} */ />
  //           </td>
  //         )
  //       })}
  //       <Table>
  //         <thead>
  //           <tr >
  //             {innerHeading.map((data, ind) => (
  //               <th key={ind}>{data.text}</th>
  //             ))}
  //           </tr>
  //         </thead>
  //         <tbody>
  //           {
  //             totalItem.map((data, itemIndex) => {
  //               return (
  //                 <tr>
  //                   {inner.map((item, idx) => {
  //                     return (
  //                       <td>
  //                         <TableLayout isField={true} onChangeCallback={{ "handleChange": handleChange, "handleTypeChange": handleTypeChange }} iconPress={{ "onDeleteInner": onDeleteInner }} defaultState={state} setDefaultState={setTableRows} setDataList={setTableRows} parentIndex={index} subItemName="itemDetails" index={itemIndex} layoutType="table" dataList={tableRows[index]?.itemDetails || []} dynamicForm={item} /* style={{ textAlign: 'center' }} */ />
  //                       </td>
  //                     )
  //                   }
  //                   )}
  //                 </tr>
  //               )
  //             }
  //             )}

  //         </tbody>
  //       </Table>
  //     </div>
  //   );
  // };


  const expandView = (index, setRows) => {
    const totalItem = tableRows[index]?.itemDetails || [];

    return (
      <div>
        <h3 style={{ fontSize: '20px', fontWeight: '500' ,color:`${lightTheme.primaryColor}`}}>Item Details</h3>
        {itemAdded && (
          <Table>
            <tbody>
              <tr>
                {subInner.map((item, idx) => (
                  <td key={idx} style={{ paddingRight: "20px" }}>
                    <TableLayout
                      onSearch={() => onSubmitItemDetails(index)}
                      iconPress={{ "onAddItem": onAddItem }}
                      isField={true}
                      onChangeCallback={{
                        "handlewarehousechange": handlewarehousechange,
                        "handlelocationchange": handlelocationchange,
                      }}
                      defaultState={state}
                      setDefaultState={setState}
                      index={index}
                      layoutType="table"
                      dataList={tableRows || []}
                      setDataList={setTableRows}
                      dynamicForm={item}
                    />
                  </td>
                ))}
              </tr>
            </tbody>
          </Table>
        )}
        <Table>
          <thead>
            <tr>
              {innerHeading.map((data, ind) => (
                <th key={ind}>{data.text}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {totalItem.map((data, itemIndex) => (
              <tr key={itemIndex}>
                {inner.map((item, idx) => (
                  <td key={idx}>
                    <TableLayout
                      isField={true}
                      onChangeCallback={{
                        "handleChange": handleChange,
                        "handleTypeChange": handleTypeChange,
                      }}
                      iconPress={{ "onDeleteInner": onDeleteInner }}
                      defaultState={state}
                      setDefaultState={setTableRows}
                      setDataList={setTableRows}
                      parentIndex={index}
                      subItemName="itemDetails"
                      index={itemIndex}
                      layoutType="table"
                      dataList={tableRows[index]?.itemDetails || []}
                      dynamicForm={item}
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  };

  const listFetching = async () => {
    let locationList = await getLocation()
    let warehouseList = await getWarehouse({
      isActive: "true",
    })
    let materialTypeList = await getMaterialType()
    let measurementList = await getMeasurement()

    setState({
      ...state,
      unitsList: measurementList.getMeasurementList,
      locationList: locationList.getLocationList,
      warehouseList: warehouseList.getWarehouseList,
      materialTypeList: materialTypeList.getMaterialTypeList,
    })
    /*     dispatch(getWarehouse())
        dispatch(getLocation())
        dispatch(getMaterialType())
        dispatch(getMeasurement()) */
  }

  const fetchData = (value, index, name) => {

    let apiResponse = state?.materialCodeList || [];

    if (value.length < 3) {
      let tempstate = [...tableRows];
      tempstate[index].filteredData = [];
      tempstate[index].materialCodeId = value;
      tempstate[index].materialName = '';
      tempstate[index].materialId = '';
      setTableRows(tempstate);
      return;
    }

    let filterObject = (apiResponse ? apiResponse : []).find(item => item.materialCode.toLowerCase() === value.toLowerCase());
    const filteredResults = (apiResponse ? apiResponse : []).filter(material =>
      material.materialCode.toLowerCase().includes(value.toLowerCase())
    );


    let tempstate = [...tableRows];

    tempstate[index].filteredData = filteredResults;
    tempstate[index].materialCodeId = value;
    tempstate[index].materialName = filterObject ? filterObject.materialName : '';
    tempstate[index].materialId = filterObject ? filterObject.materialId : '';
    tempstate[index].materialTypeId = filterObject ? filterObject.materialTypeId : '';

    setTableRows(tempstate);

    if (value.length >= filterObject?.materialCode.length) {
      let tempstate = [...tableRows];
      tempstate[index].filteredData = [];
      setTableRows(tempstate);
    }

  };

  const getMaterialName = async (event, name, index) => {
    let value = event.target.value

    const req = {
      materialCode: value
    };
    const response = await getMaterialCode(req);

    if (response.getMaterialCodeSuccess) {
      setState({
        ...state,
        materialCodeList: response.getMaterialCodeList.data
      });
    } else if (response.getMaterialCodeFailure) {
      setState({
        ...state,
        materialCodeList: []
      });
      showMessage("warning", response.materialCodeErrorMessage?.message);
    }

    if (value == "") {
      let tempstate = [...tableRows];
      tempstate[index].filteredData = [];
      setTableRows(tempstate);
    } else {
      fetchData(value, index, name);
    }
  };





  const onOutlineClock = async (index) => {
    const temp_state = [...tableRows];
    // temp_state[index].itemIcon = ""

    setTableRows(tableRows.map(({ materialType, ...row }) => row));
    const material_Id = tableRows[index].materialId
    const qty = parseFloat(tableRows[index]?.qty || 0)
    setSelectedIndex(index);
    let count = parseInt(tableRows[index]?.itemDetails?.length || 0);
    setState({ ...state, checkCount: count })
    if (qty != 0) {
      setLoaderModule(true)
      setSelectMaterial([])
      // dispatch(getShowMaterial(null, material_Id))
      let list = await getShowMaterial(null, material_Id)
      const parentIndex = selectedIndex
      const parentRows = [...tableRows];
      let tempArr = list.getShowMaterialList
      const itemInfo = parentRows[parentIndex]?.itemDetails || [];

      const filter_data = _.filter(tempArr, (item, index) =>
        _.findIndex(itemInfo, (n) => n.itemDetailsId === item.itemDetailsId) === -1
      )

      setSelectMaterial(filter_data)

      setShowForm(true);
    }
    else {
      Alert({ description: 'Please enter the material quantity.', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });

      // showMessage("warning", 'please enter the material quantity')
    }
  }


  const statusChange = (event, index, extraData) => {
    let items = [...selectedmaterial]
    let count = parseInt(state.checkCount);

    const materialQty = parseFloat(tableRows[selectedIndex]?.qty || 0);

    if (count < materialQty) {
      items[index].status = event.target.checked;
      const addingCount = event.target.checked ? 1 : -1;
      count = count + addingCount;
    } else if (count === materialQty && !event.target.checked) {
      items[index].status = event.target.checked;
      const addingCount = -1;
      count = count + addingCount;
    } else {
      Alert({ description: 'Not allowed to select more than material quantity.', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });

      // showMessage("warning", "Not allowed to select more than material quantity", "Not Allowed")
    }
    setState({ ...state, checkCount: count });
    setSelectMaterial(items)
  }

  const convertToGoodsReceivedItem = (value) => {
    let temp_arr = [];
    const updatedId = selectedItem ? selectedItem : {};
    const updateData = state.poChoosed.length ? state.poChoosed[0] : {};

    try {
      value.map((row, index) => {
        const req = {
          // purchaseOrderId: parseInt(updateData?.purchaseOrderId) || null,
          grMaterialId: row?.grMaterialId || null,
          materialId: row?.materialId ? parseFloat(row.materialId) : null,
          receivedQty: row?.receivedQty ? parseFloat(row.receivedQty) : 0,
          unit: row.unit ? parseFloat(row.unit) : null,
          qty: row.qty || null,
          goodsStatus: null,
          dimension: null,
          itemDetails: row.itemDetails.map((data, index) => ({
            itemDetailsId: data?.itemDetailsId,
            // purchaseOrderId: parseInt(updateData?.purchaseOrderId) || null,
            materialId: row?.materialId ? parseFloat(row.materialId) : null,
            serialNumber: data.serialNumber || null,
            batchNumber: data.batchNumber || null,
            receiptWarehouse: data?.locationId ? parseFloat(data.warehouseId) : null,
            receiptLocation: data?.locationId ? parseFloat(data.locationId) : null,
            sizeOfTheGoods: data.width || null,
            itemWidth: data?.width || null,
            itemHeight: data?.length || null,
            thickness: data.thickness || null,
            image: null,
            stockStatus: false
          }))
        };
        temp_arr.push(req);
      });
    } catch (error) {
      console.error("Error occurred while converting to goods received item:", error);
    }
    return temp_arr;
  };


  const handleType = (event, name, index,) => {
    let selectedValue = '';
    let temp_state = [...tableRows];
    let status = ''
    if (name === 'qty') {
      status = temp_state[index].type
      temp_state[index][name] = event
      temp_state[index].itemIcon = status
      selectedValue = status

    }
    else {
      selectedValue = event
      let typeData = state?.statusList.find((item) => item.id == selectedValue)
      status = typeData?.id || 0;
      temp_state[index][name] = selectedValue;

    }

    temp_state[index].statusType = status
    temp_state[index].itemIcon = status === "in" ? "add" : "outline-clock";
    if (selectedValue == 0) {
      temp_state[index].itemIcon = "";
    }

    let newFieldList = [...formList];
    const addIndex = newFieldList.findIndex(field => field.fieldName === 'itemIcon');

    if (status === 'in' && addIndex > -1) {
      newFieldList[addIndex] = { ...newFieldList[addIndex], onClick: "onAddItem" };
    } else if (status === 'out' && addIndex > -1) {
      newFieldList[addIndex] = { ...newFieldList[addIndex], onClick: "onOutlineClock" };
    }
    setFormlist(newFieldList);
    setTableRows(temp_state);
  }

  const confirmForm = () => {
    const temp_state = [...selectedmaterial];
    const parentIndex = selectedIndex;
    let filteredData = _.filter(temp_state, function (item) {
      delete item.purchaseOrderId
      return item.status === true;
    });
    if (tableRows[parentIndex].itemDetails?.length) {
      tableRows[parentIndex].itemDetails = [...tableRows[parentIndex].itemDetails, ...filteredData];
    }
    else
      tableRows[parentIndex].itemDetails = filteredData;
    setTableRows(tableRows);
    setItemAdded(false)
    setShowForm(false)

  }

  const validateForm = async () => {
    const validate = customerFormRef.current.validateForm()

  }


  const onSubmitForm = () => {

    if (!tableRows) {
      Alert({ description: 'Data is missing.', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });

      // showMessage("warning", "Data is missing");
      return false;
    }

    const requestData = {
      "fromWarehouse": state?.warehouseId ? parseFloat(state?.warehouseId) : null,
      "dateOfAdjustment": state.dateOfAdjustment ? dateConversion(state.dateOfAdjustment, "YYYY-MM-DD") : '',
      "purchaseOrderDate": state.purchaseOrderDate ? dateConversion(state.purchaseOrderDate, "YYYY-MM-DD") : '',
      "memo": state?.memoData || '',
      "reason": state?.reasonData || '',
      "adjustmentStatus": "open",
      "inventoryAdjustmentItem": tableRows ? tableRows : null,
    }

    console.log(JSON.stringify(requestData));

    function isValid(value) {
      return value !== null && value !== undefined && value !== '' && value !== 0 &&
        !(typeof value === 'string' && value.trim() === '') &&
        !(typeof value === 'number' && isNaN(value));
    }

    function validateFormData(inventoryAdjustmentItem) {
      if (!Array.isArray(inventoryAdjustmentItem) || inventoryAdjustmentItem.length === 0) {
        return false;
      }

      for (const item of inventoryAdjustmentItem) {
        const keysToValidate = ["materialId", "materialName", "materialCodeId", "qty", "unit", "unitPrice", "remark", "dimension", "reason"];
        for (const key of keysToValidate) {
          if (!isValid(item[key])) {
            return false;
          }
        }

        if (!Array.isArray(item.itemDetails) || item.itemDetails.length === 0) {
          return false;
        }

        for (const detail of item.itemDetails) {
          const detailKeysToValidate = ["receiptWarehouse", "receiptLocation", "itemWidth", "itemHeight", "batchNumber", "serialNumber", "thickness"];
          for (const detailKey of detailKeysToValidate) {
            if (!isValid(detail[detailKey])) {
              return false;
            }
          }
        }
      }

      return true;
    }

    if (!validateFormData(requestData.inventoryAdjustmentItem)) {
      Alert({ description: 'Fill all the add item details.', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
      // showMessage('warning', "Fill all the add item details");

    } else {
      setLoaderModule(true);

      if (editData) {
        dispatch(updateInventoryAdjustment(requestData, selectedItem.inventoryAdjustmentId));
      } else if (deleteModule) {
        const deleteRequest = {
          isActive: "false"
        };
        dispatch(updateInventoryAdjustment(deleteRequest, selectedItem.purchaseId));
      } else {
        dispatch(createInventoryAdjustment(requestData));
      }
    }

  }
  const handleClick = (buttonName) => {
    if (buttonName == 'Add') {
      onCreateForm();
    }
  };
  return (
    <div >
      <div style={{ marginTop: "-10px" }}>
        <View isOpen={createModule} onClose={setCreateModule} size="fullscreen" savetitle={modelButtonLbl} onSubmit={validateForm} title={`${modelTitle} inventoryAdjustment`} >
          <FormLayout ref={customerFormRef} onChangeCallback={{ "handleWarehouse": handleWarehouse }} defaultState={state} setDefaultState={setState} dynamicForm={inventoryAddForm} noOfColumn={4} onSubmit={onSubmitForm} onSearch={() => { onSearch() }} />
          <CollapseView iconPress={{ "onAddItem": onAddItem, "onOutlineClock": onOutlineClock, "onDeletePress": onDeletePress, }} onChangeCallback={{ "getMaterialName": getMaterialName, "handleType": handleType }} state={state} btnName={"Add Item"} setState={setState} header={formColumn} fieldList={formList} expandView={expandView} tableRows={tableRows} setTableRows={setTableRows} addRow={addRow} calculationTitle={[]}>
          </CollapseView>
        </View>
        <View isOpen={showForm} onClose={setShowForm} size="xl" title={`Select Product`} onSubmit={confirmForm} btnName={"Confirm"} count={true} savetitle={"Confirm"} checkCount={state?.checkCount || 0} quatityCount={selectedIndex === false ? 0 : tableRows[selectedIndex]?.qty || 0}>
          <CustomTable columns={itemHeader} data={selectedmaterial} tableName={""} state={state} />
        </View>
        <View isOpen={createForm} onClose={setCreateForm} title={`${modelTitle2} Supplier`} savetitle={modelButtonLbl} >
          <FormLayout ref={customerFormRef} defaultState={state} setDefaultState={setState} dynamicForm={innerClickForm} noOfColumn={2} />
        </View>
        <SimpleCard >
          <CustomTable buttonData={pageItems.create ? ['add'] : []} handleClick={handleClick} tableName={"Inventory Adjustment"} columns={column} data={itemList} state={state} />
        </SimpleCard>
      </div>
    </div>
  )
};
export default InventoryAdjustment;
