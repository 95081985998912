const taxForm = [
    {
        title: "",
        formBody: [
            {
                // title: "Select Country",
                formField: [
                    {
                        label: "Select Country",
                        options: "taxConfigList",
                        displayKey: "country",
                        uniqueKey: "taxConfigurationId",
                        inputType: "select",
                        placeholder: "Select Country",
                        fieldName: "countryName",
                        onChange: "handleCountry",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    },

                ]
            },
            {
                // title: "Name",
                formField: [
                    {
                        label: "Tax Code",

                        options: "countryList",
                        displayKey: "countryName",
                        uniqueKey: "countryId",
                        inputType: "text",
                        placeholder: "Enter Name",
                        fieldName: "name",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },

                ]
            },
            {
                // title: "Description",
                formField: [
                    {
                        label: "Tax Description",
                        options: "countryList",
                        displayKey: "countryName",
                        uniqueKey: "countryId",
                        inputType: "text",
                        placeholder: "Enter Description",
                        fieldName: "description",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    /*   {
                          label: "Description",
                          inputType: "textarea",
                          placeholder: "Enter description",
                          fieldName: "description",
                          defaultValue: "",
                          config: {
                              required: "required",
                          },
                      },
                      {
                          label: "Project Value",
                          inputType: "number",
                          placeholder: "Enter Project Value",
                          fieldName: "projectValue",
                          defaultValue: "",
                          config: {
                              required: "required",
                          },
                      } */
                ]
            }

        ]
    }]

export { taxForm }
