import { returnApiCallPromise } from './ApiContent';
import { course } from './APIContainer';

//GET
export function getCourse(request) {
  const requestObj = request ? { params: request } : "";
  return (dispatch) => {
    returnApiCallPromise("GET", course , requestObj)
      .then(response => {
        dispatch({
          type: 'GET_COURSE_SUCCESS',
          payload: response.data
        })
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'GET_COURSE_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

//POST
export function createCourse(request) {

  return (dispatch) => {
    returnApiCallPromise("POST", course, request)
      .then(response => {
        if (response.data.error) {
          dispatch({
            type: 'CREATE_COURSE_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'CREATE_COURSE_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'CREATE_COURSE_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

export function updateCourse(request, courseId) {
  return (dispatch) => {
    returnApiCallPromise("PUT", course+"/"+courseId , request)
      .then(response => {

        if (response.data.error) {
          dispatch({
            type: 'UPDATE_COURSE_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'UPDATE_COURSE_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'UPDATE_COURSE_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}