import React from 'react';
import styled from 'styled-components';

const AxView = ({ children }) => {
  return (
    <div className='dashboard_container' style={{backgroundColor:'white'}}>
      <div className="dashboard-container">
        {children}
      </div>
    </div>
  );
};

export default AxView;
