import React from 'react';
import './styles.css';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import LoadingProps from "./props"
const classNames = require('classnames');

const Loading = ({ status, delay, size = "large", title, children, height }) => {
    const className = classNames({
        'ax-loading': true,
        'ant-spin-dot-item': true,
        [height]: height,
    });

    return (
        <Spin className={className} delay={delay} /* percent={'auto'} */ spinning={status} size="large" tip={title} fullscreen>
            {children}
        </Spin>
    );
};

Loading.propTypes = {
    status: PropTypes.bool.isRequired,
    delay: PropTypes.number,
    size: PropTypes.oneOf([LoadingProps.size.small, LoadingProps.size.middle, LoadingProps.size.large]),
    title: PropTypes.string,
};
Loading.defaultProps = {
    delay: 0,
    size: LoadingProps.size.default,
    title: null
};

export default Loading;
