import * as yup from "yup";
import { Formik } from "formik";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import DateTime from "react-datetime";
import { Form, Button } from "react-bootstrap";
import { DateRange } from "react-date-range";
import SimpleCard from 'app/components/atoms/simple-card/SimpleCard'
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { MdEdit, MdDelete } from "react-icons/md";
import swal from "sweetalert2";
import _ from "lodash";
import { pagePermission, showMessage } from "app/utils/app-functions";
import FormLayout from "app/components/render/formlayout";
import View from "app/components/atoms/modalView/Modalview";
import CustomTable from "app/components/atoms/custom-table/CustomTable";
import formRender from "app/components/render/formcomponent";
import { materialTypeForm } from "./formData";
import { createMaterialType, getMaterialType, updateMaterialType } from "app/api/ProductApi";
import Alert from "app/components/atoms/alert";
import { FiEdit, FiTrash2 } from "react-icons/fi";

let editData = false;

const MaterialType = ({ navigation }) => {

    const dispatch = useDispatch();
    const customerFormRef = useRef();

    const getMaterialTypeSuccess = useSelector((state) => state.productReducer.getMaterialTypeSuccess);
    const getMaterialTypeList = useSelector((state) => state.productReducer.getMaterialTypeList);
    const getMaterialTypeFailure = useSelector((state) => state.productReducer.getMaterialTypeFailure);

    const createMaterialTypeSuccess = useSelector((state) => state.productReducer.createMaterialTypeSuccess);
    const createMaterialTypeData = useSelector((state) => state.productReducer.createMaterialTypeData);
    const createMaterialTypeFailure = useSelector((state) => state.productReducer.createMaterialTypeFailure);

    const updateMaterialTypeSuccess = useSelector((state) => state.productReducer.updateMaterialTypeSuccess);
    const updateMaterialTypeData = useSelector((state) => state.productReducer.updateMaterialTypeData);
    const updateMaterialTypeFailure = useSelector((state) => state.productReducer.updateMaterialTypeFailure);

    const errorMessage = useSelector((state) => state.productReducer.errorMessage);


    const [pageItems, setpageItems] = useState({});

    useEffect(() => {
        let data = pagePermission(pageItems, "Inventory", "Material Type")
        setpageItems(data)

    }, [navigation]);

    const column = [
        {
            "dataField": "materialName",
            "text": "Material Type Name",
            "sort": true
        },
        {
            "dataField": "description",
            "text": "Description ",
            "sort": false
        },
    ]
    if (pageItems.update || pageItems.delete) {
        column.push({
            text: "Action",
            sort: false,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => (
                <div className="icon-container">
                    {
                        pageItems.update && <FiEdit
                            className="text-primary cursor-pointer"
                            size={18}
                            onClick={() => onEditForm(row, index)}
                        ></FiEdit>
                    }
                    {
                        pageItems.delete && <FiTrash2
                            className="text-danger cursor-pointer"
                            size={18}
                            onClick={() => {
                                onDeleteItem(row, index)
                            }}
                        ></FiTrash2>
                    }
                    {/* <MdAddTask
                        className="text-success cursor-pointer"
                        size={18}
                        onClick={() => onEditForm(row, index)}
                    ></MdAddTask> */}
                </div>
            )
        },)
    }

    const [state, setState] = useState({
        name: "",
        description: "",
        "customerList": [],
        "venderList": [],
        "leadSourceTypeList": [],
    });

    const [createModule, setCreateModule] = useState(false);
    const [loaderModule, setLoaderModule] = useState(false);
    const [editModule, setEditModule] = useState(false);
    const [viewModule, setViewModule] = useState(false);
    const [deleteModule, setDeleteModule] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [selectedItem, setSelectedItem] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);

    useEffect(() => {
        setLoaderModule(true)
        const request = {
            isActive: "true"
        }
        dispatch(getMaterialType(request))
    }, [navigation]);

    useEffect(() => {
        if (getMaterialTypeSuccess) {
            setItemList(getMaterialTypeList)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_MATERIAL_TYPE" })
        } else if (getMaterialTypeFailure) {
            setItemList([])
            showMessage("warning", errorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_MATERIAL_TYPE" })
        }
    }, [getMaterialTypeSuccess, getMaterialTypeFailure]);

    useEffect(() => {
        if (createMaterialTypeSuccess) {
            const temp_state = [...itemList, createMaterialTypeData[0]];
            setItemList(temp_state)
            setLoaderModule(false)
            closeModule()
            Alert({ description: 'Created Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            dispatch({ type: "RESET_CREATE_MATERIAL_TYPE" })
        } else if (createMaterialTypeFailure) {
            setLoaderModule(false)
            showMessage("warning", errorMessage?.message)
            dispatch({ type: "RESET_CREATE_MATERIAL_TYPE" })
        }
    }, [createMaterialTypeSuccess, createMaterialTypeFailure]);


    useEffect(() => {
        if (updateMaterialTypeSuccess) {
            updateTable(updateMaterialTypeData[0])
            dispatch({ type: "RESET_UPDATE_MATERIAL_TYPE" })
        } else if (updateMaterialTypeFailure) {
            setLoaderModule(false)
            showMessage("warning", errorMessage?.message)
            dispatch({ type: "RESET_UPDATE_MATERIAL_TYPE" })
        }
    }, [updateMaterialTypeSuccess, updateMaterialTypeFailure]);

    const updateTable = (updatedItem) => {
        const temp_state = [...itemList];
        if (deleteModule) {
            let remainingList = _.remove(temp_state, function (n) {
                return n.materialTypeId != selectedItem.materialTypeId;
            });
            Alert({ description: 'Deleted Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(remainingList)
        } else {
            temp_state[selectedIndex] = updatedItem;
            Alert({ description: 'Updated Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(temp_state)
        }
        setLoaderModule(false)
        closeModule()
    }

    const closeModule = () => {
        editData = false;
        setCreateModule(false)
        setDeleteModule(false)
    }

    const resetState = () => {
        setState({
            ...state,
            name: "",
            description: ""
        })
    }

    const onCreateForm = () => {
        resetState()
        editData = false;
        setCreateModule(true)
    }

    const onEditForm = (data, index) => {
        console.log(JSON.stringify(data))
        setState({
            ...state,
            name: data?.materialName || "",
            description: data?.description || ""
        })
        editData = true;
        setSelectedIndex(index)
        setSelectedItem(data)
        setCreateModule(true)
    }

    const confromDialog = () => {
        return (
            swal
                .fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    type: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!",
                    cancelButtonText: "No",
                })
                .then((result) => {
                    if (result.value) {
                        onSubmitForm()
                    } else {
                        setDeleteModule(false)
                        swal.fire({
                            title: "Cancelled!",
                            text: "Permission denied.",
                            type: "error",
                            icon: "error",
                            timer: 1500,
                        });
                    }
                })
        )
    }

    useEffect(() => {
        if (deleteModule) {
            confromDialog()
        }
    }, [deleteModule])

    const onDeleteItem = (data, index) => {
        editData = false;
        setSelectedItem(data)
        setSelectedIndex(index)
        setDeleteModule(true)
        if (deleteModule) {
            confromDialog()
        }

    }

    const validateForm = async () => {
        const validatefom = customerFormRef.current.validateForm();
    }

    const onSubmitForm = () => {

        const requestDate = {
            "materialTypeName": state?.name,
            "description": state?.description
        }
        if (editData) {
            setLoaderModule(true)
            console.log(JSON)
            dispatch(updateMaterialType(requestDate, selectedItem.materialTypeId))
        } else if (deleteModule) {
            const deleteRequest = {
                isActive: "false"
            }
            setLoaderModule(true)
            dispatch(updateMaterialType(deleteRequest, selectedItem.materialTypeId))
        } else {
            setLoaderModule(true)
            dispatch(createMaterialType(requestDate))
        }
    }


    const handleClick = (buttonName) => {
        if (buttonName == 'Add') {
            onCreateForm();
        }
    };

    const {
        departmentName,
        description,
        customerList
    } = state;

    const modelTitle = editData ? "Edit " : "Create ";
    const modelButtonLbl = editData ? "" : "Save";
    return (
        <div>
            <div style={{ marginTop: "-10px" }}>
                <View isOpen={createModule} size="sm" savetitle={modelButtonLbl} onClose={setCreateModule} onSubmit={validateForm} title={`${modelTitle} MaterialType`}>
                    <FormLayout ref={customerFormRef} defaultState={state} setDefaultState={setState} dynamicForm={materialTypeForm} noOfColumn={1} onSubmit={onSubmitForm} />
                </View>

                <SimpleCard >
                    <CustomTable buttonData={pageItems.create ? ['add'] : []} handleClick={handleClick} tableName={"Material Type"} columns={column} data={itemList} initialPage={1} />
                </SimpleCard>

            </div>
        </div>
    )
};

export default MaterialType;
