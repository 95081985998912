import React, { useState, useEffect, useImperativeHandle } from "react";
import CheckboxTree from 'react-checkbox-tree';
import { Modal, Button } from "react-bootstrap";
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    MdCheckBoxOutlineBlank, MdCheckBox, MdOutlineIndeterminateCheckBox,
    MdIndeterminateCheckBox, MdOutlineChevronRight, MdOutlineExpand, MdMenuOpen,
    MdMenu, MdOutlineFolder, MdFolderOpen,
    MdOutlineExpandMore, MdInsertDriveFile,
    MdOutlineFolderOpen
} from "react-icons/md";
import _ from "lodash";
// import CustomAlignButton from "./CustomAlignButton";
import SimpleCard from 'app/components/atoms/simple-card/SimpleCard'
const TreeViewEditor = React.forwardRef(({ ...props }, ref) => {
    const { children = [], index, selectedIndex, data = [], title, icon, onClick, btnName, isShow } = props;
    const [state, setState] = useState({
        checked: [],
        expanded: [],
        before: [],
        mainParentState: [],
    });
    useImperativeHandle(ref, () => ({ getPermissionState: () => { return state } }), [state]);

    const [mainParentState, setMainParentState] = useState([])
    const [parentchildrenState, setParentchildrenState] = useState([])
    const [mainParentchildrenState, setMainParentchildrenState] = useState([])
    function getAccessiblePageIds(data) {
        const result = new Set();

        function traverse(node) {
            let hasAccess = false;

            if (node.children && node.children.length > 0) {
                // Recursively process children
                const childAccess = node.children.map(traverse);
                hasAccess = childAccess.some(Boolean); // If any child has access
            }

            // Check accessGiven in children
            if (node.children) {
                node.children.forEach(child => {
                    if (child.accessGiven) {
                        result.add(node.pageId); // Add parent if child has access
                        result.add(child.pageId); // Add child
                        hasAccess = true;
                    }
                });
            }

            if (hasAccess) {
                result.add(node.pageId); // Add parent if it has accessible children
            }

            return hasAccess;
        }

        data.forEach(traverse);
        return Array.from(result);
    }
    useEffect(() => {
        /* const pageIds = data.map((item) => item.pageId);
        let checkedIds = [];
 
        const child_fun = (child, item) => {
            child.map((option) => {
                if (item.hasOwnProperty("access") && option.pageId) {
                    if (option.pageId) {
                        let access_given = item?.access || []
                        const ids = [option?.pageId, item?.pageId];
                        if (access_given.length) {
                            access_given.map((element, index) => {
                                if (option.accessGiven == true) {
                                    const pids = [option?.pageId, item?.pageId];
                                    checkedIds = [...checkedIds, ...pids];
                                }
                            });
                        }
                    }
                    if (option.children) {
                        child_fun(option.children, option)
                    }
                    if (checkedIds.includes(option.pageId) && !checkedIds.includes(item.pageId)) {
                        checkedIds.push(item.pageId);
                    }
                }
            })
        }
 
        data.map((item) => {
            if (item.hasOwnProperty("access")) {
                const pids = [item.pageId];
                checkedIds = [...checkedIds, ...pids];
                checkedIds.push(item.pageId);
                const child = item?.children || []
                child_fun(child, item)
            }
        }); */
        const pageIds = data.map((item) => item.pageId)
        const result = getAccessiblePageIds(data);

        setState({
            ...state,
            checked: result,
            expanded: pageIds
        })
    }, [data]);

    const resetState = () => {
        setState({
            ...state,
            mainParentchildrenState: [],
            parentchildrenState: []
        })
    }

    const findMainPageId = (data, node) => {
        const traverse = (data, parentId) => {
            for (let item of data) {
                if (item.value === parentId) {
                    let itemList = item
                    setMainParentState(itemList)
                    return item.value;
                }

                if (item.children && item.children.length > 0) {
                    for (let child of item.children) {
                        if (child.value === parentId) {
                            const parentIds = []
                            parentIds.push(child.value)
                            const result = traverse(data, child.parentId);
                            parentIds.push(result)
                            return parentIds;
                        }
                    }
                }
            }
        };

        const getParentId = (data, node) => {
            if (!node?.isChild || node?.parentId) {
                return node.value;
            }
            return traverse(data, node?.parent?.parentId);
        };

        return getParentId(data, node);
    };

    let tempArr = []
    let tempArr2 = []

    const checkedItem = (checkedIds, tnode) => {
        // console.log("checkedIds ---->" + JSON.stringify(checkedIds));
        // console.log("tnode ---->" + JSON.stringify(tnode));

        const nodeId = tnode.value.toString();
        const parentId = tnode?.parent?.pageId?.toString() || '';
        const mainParentId = findMainPageId(data, tnode);
        let pageIds = tnode?.children?.map(child => child.pageId);

        if (pageIds && !checkedIds.includes(pageIds)) {
            checkedIds = [...checkedIds, ...[].concat(pageIds).map(id => id.toString())];
        }

        let updatedCheckedIds = [...checkedIds];

        if (Object.keys(tnode.parent).length === 0 && !tnode.checked) {
            // Remove all checked children and subchildren when parent is unchecked
            let pageIds = tnode.children.map(child => child.pageId);
            pageIds.forEach(item => {
                updatedCheckedIds = updatedCheckedIds.filter(ele => ele !== item.toString());
            });

            tnode?.children?.forEach(item => {
                let childPageIds = item?.children?.map(child => child.pageId);
                childPageIds?.forEach(item => {
                    updatedCheckedIds = updatedCheckedIds?.filter(ele => ele !== item.toString());
                });
            });
        }

        if (tnode.checked) {
            // Adding nodeId and parentId when checked
            if (!updatedCheckedIds.includes(nodeId)) {
                updatedCheckedIds.push(nodeId);
            }
            if (parentId && !updatedCheckedIds.includes(parentId)) {
                updatedCheckedIds.push(parentId);
            }
            if (mainParentId && !updatedCheckedIds.includes(mainParentId)) {
                updatedCheckedIds = [...updatedCheckedIds, ...[].concat(mainParentId).map(id => id.toString())];
            }
        } else {
            updatedCheckedIds = updatedCheckedIds.filter(id => id !== nodeId);

            const mainParentIdsArray = [].concat(mainParentId);

            if (mainParentIdsArray && mainParentIdsArray.some(value => value !== undefined)) {
                let tempArr = [];
                let tempArr2 = [];

                for (let id of mainParentIdsArray) {
                    for (let item of data) {
                        if (item?.value === id) {
                            // Collect all children of main parent (e.g., 129)
                            tempArr = item?.children?.map(child => child?.value.toString()) || [];
                        }

                        if (item?.children[0]?.value === id) {
                            // Collect children of the first child (e.g., 67-1)
                            tempArr2 = item?.children[0]?.children?.map(child => child?.value.toString()) || [];
                        }
                    }
                }

                const allChildrenUnchecked = tempArr?.every(childId => !updatedCheckedIds.includes(childId));
                const allSubChildrenUnchecked = tempArr2?.every(childId => !updatedCheckedIds.includes(childId));

                // If all children of main parent are unchecked, remove the main parent
                if (mainParentId && allChildrenUnchecked) {
                    updatedCheckedIds = updatedCheckedIds.filter(id => id !== mainParentId);
                }

                // If all subchildren of main parent are unchecked, remove the subparent
                if (mainParentId && allSubChildrenUnchecked) {
                    updatedCheckedIds = updatedCheckedIds.filter(id => id !== tempArr2);
                }
            }

            const parentChildrenIds = (tnode?.parent?.children || []).map(child => child.value.toString());
            const allChildrenUnchecked = parentChildrenIds?.every(childId => !updatedCheckedIds.includes(childId));

            if (parentId && allChildrenUnchecked) {
                updatedCheckedIds = updatedCheckedIds.filter(id => id !== parentId);
            }

            if (tnode?.value == nodeId) {
                let pageIds = tnode?.children?.map(child => child.pageId);
                pageIds?.forEach(item => {
                    updatedCheckedIds = updatedCheckedIds?.filter(ele => ele !== item.toString());
                });
            }

            const subChildIds = (tnode?.children || []).map(child => child.value.toString());
            const allSubChildrenUnchecked = subChildIds?.every(childId => !updatedCheckedIds.includes(childId));

            if (subChildIds && allSubChildrenUnchecked) {
                updatedCheckedIds = updatedCheckedIds.filter(id => !subChildIds.includes(id));
            }

            const subParentChildrenUnchecked = tempArr?.every(childId => !updatedCheckedIds.includes(childId));

            let ID = tnode?.parent?.parentId == null ? tnode.value : tnode?.parent?.parentId
          
            /* if (subParentChildrenUnchecked) {
                updatedCheckedIds = updatedCheckedIds.filter(id => id !== ID); // Remove the subparent (129)
            } */
        }

        const newStateBefore = updatedCheckedIds.filter(item => !state.before.includes(item));

        let nodeclickedParent = tnode?.parent?.value || 0;
        let subPageIds = '';
        let subChildPageIds = [];

        function findPageId(data, targetId) {
            for (const item of data) {
                if (item.pageId === targetId) {
                    return item;
                }
                if (item.children && item.children.length > 0) {
                    const result = findPageId(item.children, targetId);
                    if (result) {
                        return [result.parentId, item.children];
                    }
                }
            }
            return null;
        }

        for (let i = 0; i < data.length; i++) {
            // console.log('nodeclickedParent',nodeclickedParent);
            
            const ele = data[i];
            if (ele.type === 'dropDown' && ele.pageId === nodeclickedParent) {
                const result = findPageId(data, nodeclickedParent);
                if (result) {
                    // console.log('DropDown item found:', result);
                }
            } else {
                const result = findPageId(ele.children || [], nodeclickedParent);
                if (result) {
                    // console.log('innnnn------1');

                    subPageIds = result[0];
                    // console.log(subPageIds);
                    // console.log(subChildPageIds);
                    
                    if (Array.isArray(result[1]) && result[1].length) {
                        result[1].forEach(o => {
                            subChildPageIds.push(o.pageId);
                        });
                        break; // Now you can break once the result is found
                    }
                }
            }
        }

        let containsCheckIds = subChildPageIds.some(id => updatedCheckedIds.includes(id.toString()));     
        if (!containsCheckIds) {
            // Remove "129" from the array if either 67 or 68 is not present
            updatedCheckedIds = updatedCheckedIds.filter(id => id != subPageIds);
        }

        setState({
            ...state,
            checked: [...new Set(updatedCheckedIds)],
            before: [...state.before, ...new Set(newStateBefore)]
        });
    };




    return (
        <SimpleCard title={title}  >
            <CheckboxTree
                nodes={data}
                showNodeIcon={false}
                checked={state.checked}
                expanded={state.expanded}
                onCheck={(checked, targetNode) => checkedItem(checked, targetNode)}
                onClick={onClick}
                onExpand={expanded => setState({ ...state, expanded })}
                showExpandAll={true}
                // nativeCheckboxes={true}
                icons={{
                    check: <MdCheckBox />,
                    uncheck: <MdCheckBoxOutlineBlank />,
                    halfCheck: <MdOutlineIndeterminateCheckBox />,
                    expandClose: <MdOutlineChevronRight />,
                    expandOpen: <MdOutlineExpandMore />,
                    expandAll: <MdMenuOpen />,
                    collapseAll: <MdMenu />,
                    parentClose: <MdOutlineFolder />,
                    parentOpen: <MdOutlineFolderOpen />,
                    leaf: <MdInsertDriveFile />,
                }}
            />
            {/* {btnName ? <CustomAlignButton btnName={btnName} onClick={() => { onClick(state.before) }}></CustomAlignButton>
                : ''} */}
        </SimpleCard>
    );
});

export default TreeViewEditor;