
import FormLayout from "app/views/formlayout";
import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { Tabs, Tab } from "react-tabs-scrollable";
import "react-tabs-scrollable/dist/rts.css";
import Breadcrumb from "./Breadcrumb";
import { lightTheme } from "styles/theme";
const FilterComponent = ({ ...props }) => {
    const { dataRef, title, defaultState, setDefaultState, dynamicForm, noOfColumn, onSubmitForm, btnAlign, btnClickFun, onChangeCallback } = props

    let count = 0
    useEffect(() => {
        const formData = dynamicForm[0].formBody
        count = formData.length
    }, []);

    return (
        <>
            {title && <Breadcrumb
                routeSegments={[{ name: `${title}`, }, { name: `${title}` }]}
            ></Breadcrumb>}

            <FormLayout ref={dataRef} onChangeCallback={onChangeCallback ? onChangeCallback : ""} defaultState={defaultState} setDefaultState={setDefaultState} dynamicForm={dynamicForm} noOfColumn={noOfColumn} onSubmit={onSubmitForm} />
            <div >
                <Button className={`${btnAlign ? btnAlign : ""}`} style={{backgroundColor: lightTheme.primaryColor,border:"none",padding:"5px 10px" ,fontSize:"12px" , marginTop:"-20px"}} onClick={() => btnClickFun()} >Search</Button>
            </div>

        </>
    );
};

export default FilterComponent;


