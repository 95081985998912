import React, { useState } from "react";
import { AutoComplete, Input } from "antd";
import { MdAdd } from "react-icons/md";
import { MdAdsClick } from "react-icons/md";

function CustomTypeahead({
  optionsList = [], // Default empty array for options
  onChangeCallback,
  handleTypeChange,
  index,
  info = {},
  parentIndex,
  onSearch,
  typeDropdownValidate = {},
}) {
  const [inputValue, setInputValue] = useState("");

  // Safely transform optionsList for AutoComplete
  console.log(info);
  console.log(optionsList);

  const transformedOptions = optionsList.map((option) => ({
    label: `${option?.[info?.displayKey] || "Unknown"} ${info?.subDisplayKey ? ` (${option?.[info?.subDisplayKey] || ""})` : ""
      }`.trim(),
    value: option?.[info?.uniqueKey]?.toString() || "",
    ...option,
  }));


  const handleSelect = (value, option) => {
    setInputValue(option.label);
    if (onChangeCallback?.[info?.onChange]) {
      onChangeCallback[info.onChange](option, info.fieldName, index, parentIndex);
    } else if (handleTypeChange) {
      handleTypeChange(option, info.uniqueKey, info.fieldName);
    }
  };

  const handleChange = (value) => {
    setInputValue(value);
    if (onChangeCallback?.[info?.onChange]) {
      onChangeCallback[info.onChange](value, info.fieldName, index, parentIndex);
    } else if (handleTypeChange) {
      handleTypeChange(value, info.uniqueKey, info.fieldName);
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <AutoComplete
        style={{ width: "100%" }}
        options={transformedOptions}
        value={typeDropdownValidate[info.fieldName]}
        filterOption={(inputValue, option) =>
          option.label.toLowerCase().includes(inputValue.toLowerCase())
        }
        onSelect={handleSelect}
        onChange={handleChange}
        placeholder={info.placeholder || "Select or type"}
        disabled={info.disabled || false}
      >
        {info.icon && (
          <Input
            suffix={
              <MdAdsClick
                style={{
                  fontSize: "18px",
                  color: "rgba(0, 0, 0, 0.45)",
                  cursor: "pointer",
                }}
                onClick={onSearch}
              />
            }
          />
        )}
      </AutoComplete>
    </div>
  );
}

export default CustomTypeahead;
