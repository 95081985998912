import * as yup from "yup";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { DateRange } from "react-date-range";
import SimpleCard from 'app/components/atoms/simple-card/SimpleCard'
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import _ from "lodash";
import { pagePermission, showMessage } from "app/utils/app-functions";
import FormLayout from "app/components/render/formlayout";
import View from "app/components/atoms/modalView/Modalview";
import CustomTable from "app/components/atoms/custom-table/CustomTable";
import formRender from "app/components/render/formcomponent";
import { inventoryForm } from "./formData";
import { getMaterialType } from "app/api/ProductApi";
import { getInventoryMaterial, getMaterial } from "app/api/InventoryApi";
import { useSidebar } from "app/components/sidebar/sidebarContext";


let editData = false;

const StockInventory = ({ navigation }) => {

    const dispatch = useDispatch();
    const customerFormRef = useRef();

    const getMaterialTypeSuccess = useSelector((state) => state.productReducer.getMaterialTypeSuccess);
    const getMaterialTypeList = useSelector((state) => state.productReducer.getMaterialTypeList);
    const getMaterialTypeFailure = useSelector((state) => state.productReducer.getMaterialTypeFailure);

    const getInventoryMaterialSuccess = useSelector((state) => state.inventoryMaterialReducer.getInventoryMaterialSuccess);
    const getInventoryMaterialList = useSelector((state) => state.inventoryMaterialReducer.getInventoryMaterialList);
    const getInventoryMaterialFailure = useSelector((state) => state.inventoryMaterialReducer.getInventoryMaterialFailure);



    const inventoryErrorMessage = useSelector((state) => state.inventoryMaterialReducer.errorMessage);
    const materialErrorMessage = useSelector((state) => state.productReducer.errorMessage);

    const column = [
        {
            "dataField": "materialCode",
            "text": "Material Code",
            "sort": true
        },
        {
            "dataField": "materialName",
            "text": "Material Name",
            "sort": true,

        },
        {
            "dataField": "materialTypeName",
            "text": "Type",
            "sort": true
        },
        {
            "dataField": "qty",
            "text": "Current Stock",
            "sort": true,
            formatter: (cellContent, row, index) =>{
                return row.qty ? cellContent : "0"
            }
        },
        {
            "dataField": "unitName",
            "text": "Unit",
            "sort": true
        },
        /* {
            text: "Action",
            sort: false,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => (
                <div >
                    <MdVisibility />                </div>
            )
        }, */
    ]

    const [state, setState] = useState({});

    const [createModule, setCreateModule] = useState(false);
    const [loaderModule, setLoaderModule] = useState(false);
    const [deleteModule, setDeleteModule] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [selectedItem, setSelectedItem] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);

    useEffect(() => {

        // setLoaderModule(true)
        dispatch(getMaterialType())
        dispatch(getInventoryMaterial())
    }, [navigation]);

    useEffect(() => {
        if (getMaterialTypeSuccess) {
            setState({
                ...state,
                materialTypeList: getMaterialTypeList
            })
            dispatch({ type: "RESET_GET_MATERIAL_TYPE" })
        }
        else if (getMaterialTypeFailure) {
            setState({
                ...state,
                materialTypeList: []
            })
            showMessage("warning", materialErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_MATERIAL_TYPE" })
        }
    }, [getMaterialTypeFailure, getMaterialTypeSuccess]);

    useEffect(() => {
        if (getInventoryMaterialSuccess) {
            setItemList(getInventoryMaterialList)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_INVENTORY_MATERIAL" })
        }
        else if (getInventoryMaterialFailure) {
            setItemList([])
            showMessage("warning", inventoryErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_INVENTORY_MATERIAL" })
        }
    }, [getInventoryMaterialFailure, getInventoryMaterialSuccess]);

    /*  useEffect(() => {
         if (createHolidaysSuccess) {
             const temp_state = [...itemList, createHolidaysData[0]];
             setItemList(temp_state)
             setLoaderModule(false)
             closeModule()
             showMessage("success", "Created Successfully")
             dispatch({ type: "RESET_CREATE_HOLIDAYS" })
         } else if (createHolidaysFailure) {
             setLoaderModule(false)
             showMessage("warning", holidaysErrorMessage?.message)
             dispatch({ type: "RESET_CREATE_HOLIDAYS" })
         }
     }, [createHolidaysSuccess, createHolidaysFailure]);
 
     useEffect(() => {
         if (updateHolidaysSuccess) {
             updateTable(updateHolidaysData[0])
             dispatch({ type: "RESET_UPDATE_HOLIDAYS" })
         } else if (updateHolidaysFailure) {
             setLoaderModule(false)
             showMessage("warning", holidaysErrorMessage?.message)
             dispatch({ type: "RESET_UPDATE_HOLIDAYS" })
         }
     }, [updateHolidaysSuccess, updateHolidaysFailure]); */
    const updateTable = (updatedItem) => {
        const temp_state = [...itemList];
        if (deleteModule) {
            let remainingList = _.remove(temp_state, function (n) {
                return n.holidayId != updatedItem.holidayId;
            });
            showMessage("success", "Deleted Successfully")
            setItemList(remainingList)
        } else {
            temp_state[selectedIndex] = updatedItem;
            showMessage("success", "Updated Successfully")
            setItemList(temp_state)
        }
        setLoaderModule(false)
        closeModule()
    }

    const closeModule = () => {
        editData = false;
        setCreateModule(false)
        setDeleteModule(false)
    }

    const resetState = () => {
        setState({
            ...state,
            holidayName: "",
            holidayDate: ""
        })
    }

    const onCreateForm = () => {
        resetState()
        editData = false;
        setCreateModule(true)
    }

    const onEditForm = (data, index) => {
        setState({
            ...state,
            holidayName: data?.holidayName || "",
            holidayDate: new Date(data?.holidayDate) || "",
        })
        editData = true;
        setSelectedIndex(index)
        setSelectedItem(data)
        setCreateModule(true)
    }

    const onDeleteItem = (data, index) => {
        editData = false;
        setSelectedIndex(index)
        setSelectedItem(data)
        setDeleteModule(true)
    }

    const validateForm = async () => {
        const validatefom = customerFormRef.current.validateForm();
    }

    const onSubmitForm = () => {
        const requestDate = {
            // holidayName: holidayName,
            // holidayDate: dateConversion(new Date(holidayDate), "YYYY-MM-DD")
        }
        if (editData) {
            // setLoaderModule(true)
            // dispatch(updateHolidays(requestDate, selectedItem.holidayId))
        } else if (deleteModule) {
            const deleteRequest = {
                isActive: "false"
            }
            // setLoaderModule(true)
            // dispatch(updateHolidays(deleteRequest, selectedItem.holidayId))
        } else {
            // setLoaderModule(true)
            // dispatch(createHolidays(requestDate))
        }
    }

    const handleSubmit = () => {
        const req = {
            materialCode: state?.materialCode || '',
            materialName: state?.materialName || '',
            materialTypeId: state?.materialType ? [0].materialId || '' : ''
        }
        dispatch(getInventoryMaterial(req))
    }

    const { sidebarOpen } = useSidebar();

    const modelTitle = editData ? "Edit " : "Create ";
    const modelButtonLbl = editData ? "" : "Save";

    return (
        <div>
            <div style={{ marginTop: "-10px" }}>
                <View centered={true} size={'sm'} isOpen={deleteModule} onClose={setDeleteModule} savetitle="Yes" onSubmit={onSubmitForm} title="Delete StockInventory">
                    <h6 className="text-capitalize mb-1">Are you sure ?</h6>
                </View>

                <SimpleCard >
                    <CustomTable columns={column} tableName="StockInventory" data={itemList} initialPage={1} />
                </SimpleCard>
            </div>
        </div>
    )
};

export default StockInventory;
