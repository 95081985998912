import React, { useState, useEffect, useRef } from "react";
 
const SelectWithAnimation = ({ options, selectedValue, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef(null); // Reference to the component
 
  // Toggle dropdown open/close
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
 
  // Handle option selection
  const handleOptionSelect = (option) => {
    onSelect(option);  // Call onSelect function passed via props
    setIsOpen(false);   // Close dropdown after selection
  };
 
  // Close dropdown if clicked outside
  const handleClickOutside = (event) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
 
  // Add event listener to close dropdown when clicking outside
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
 
  const selectContainerStyle = {
    position: "relative",
    display: "inline-block",
  };
  const selectButtonStyle = {
    backgroundColor: "white",
    color: "gray",
    padding: "10px",
    border: "0.2px solid gray",
    cursor: "pointer",
    fontSize: "14px",
    borderRadius: "5px",
  };
 
 
  const selectDropdownStyle = {
    position: "absolute",
    top: "100%",
    left: "0",
    right: "0",
    zIndex:10,
    backgroundColor: "#f0f5f5",
    border: "1px solid #ccc",
    borderRadius: "10px",
    maxHeight: isOpen ? "500px" : "0",
    overflowY: "scroll",
    opacity: isOpen ? "1" : "0",
    transition: "all 0.3s ease",
    width: "250px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",  
    scrollbarWidth: "none",  // For Firefox to hide scrollbar
    msOverflowStyle: "none", // For Internet Explorer and Edge to hide scrollbar
    "&::-webkit-scrollbar": {
      display: "none", // Hide the scrollbar in WebKit browsers
    },
  };
 
  const optionsListStyle = {
    listStyle: "none",
    margin: "0",
    padding: "0",
  };
 
  const optionItemStyle = {
    padding: "10px",
    cursor: "pointer",
    transition: "background-color 0.2s",
  };
 
  const optionItemHoverStyle = {
    backgroundColor: "lightgray",
  };
 
  return (
    <div style={selectContainerStyle} ref={selectRef}>
      <button style={selectButtonStyle} onClick={handleToggle}>
        {selectedValue || "Select Option"}
      </button>
      <div style={selectDropdownStyle}>
        <ul style={optionsListStyle}>
          {options.map((option, index) => (
            <li
              key={index}
              style={optionItemStyle}
              onMouseOver={(e) => (e.target.style.backgroundColor = optionItemHoverStyle.backgroundColor)}
              onMouseOut={(e) => (e.target.style.backgroundColor = "")}
              onClick={() => handleOptionSelect(option)}  // Pass selected option
            >
              {option}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
 
export default SelectWithAnimation;
 
 
 
 