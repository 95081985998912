import React, { useState } from 'react';
import { statusForm, feesRecords } from './formData';
import View from 'app/components/atoms/modalView/Modalview';
import CustomTable from 'app/components/atoms/custom-table/CustomTable';
import FormLayout from 'app/components/render/formlayout';
import SimpleCard from 'app/components/atoms/simple-card/SimpleCard';
import * as yup from "yup";
import { Formik } from "formik";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import DateTime from "react-datetime";
import { Form, Button } from "react-bootstrap";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { studentFeesPaymentForm, studentFeesPaymentFilterForm, feesPaymentForm } from "./formData";
import { MdEdit, MdDelete, MdVisibility, MdDownload } from "react-icons/md";
import { FiEdit } from "react-icons/fi";
import { useSidebar } from "app/components/sidebar/sidebarContext";
import swal from "sweetalert2";
import _ from "lodash";
import formRender from "app/components/render/formcomponent";
import { createStudentFeesPayment, getStudentFeesPayment, getStudentFeesPaymentDetails, updateStudentFeesPayment } from "app/api/StudentFeesPaymentAPi"
import { amountFormat, dateConversion, pagePermission, showMessage } from "app/utils/app-functions";
import { getBatch } from "app/api/BatchApi";
import { getStudentEnrollmentDetails } from "app/api/StudentEnrollmentApi";
import { getEnrollmentDetails } from "app/api/EnrollementDetailsApi";
import { getFeesType } from "app/api/FeesTypeApi";
import { getFeesEnrollment } from "app/api/FeesEnrollmentApi";
import { PDFDownloadLink } from '@react-pdf/renderer';
import InvoiceGenerator from "app/components/InvoiceGenerator";
import { getSchoolInfo } from "app/api/SchoolInfoApi ";
import { getUnitFilter } from "app/api/UnitApi";
import { shadow } from "pdfjs-dist";
import Breadcrumb from 'app/components/Breadcrumb';
import { asyncGetBloodGroupInfo } from "app/api/BloodGroupApi";
import { asyncGetGenderInfo } from "app/api/GenderApi";
import { asyncGetMotherTongueInfo } from "app/api/MotherTongueApi";
import { asyncQualificationInfo } from "app/api/QualificationApi";
import { asyncgetCaste } from "app/api/CasteApi";
import { asyncgetReligion } from "app/api/ReligionApi";
import { asyncGetSubCaste } from "app/api/SubCasteApi";
import { asyncGetNationalityInfo } from "app/api/NationalityApi";
import { asyncGetClassInfo } from "app/api/ClassApi";
import { asyncGetSectionInfo } from "app/api/SectionApi";
import { asyncGetBatchInfo } from "app/api/BatchApi";
import { asyncGetStateInfo } from "app/api/StateApi";
import { asyncGetCountryInfo } from "app/api/CountryApi";
import FilterComponent from 'app/components/FilterComponent';
import { asyncGetEnrollmenetDetailsInfo } from 'app/api/EnrollementDetailsApi';
import Alert from 'app/components/atoms/alert';
import { asyncGetStudentEnrollmentInfo } from 'app/api/StudentEnrollmentApi';
import Loading from 'app/components/atoms/loading';
import { asyncgetStudentFeesPaymentDetails } from 'app/api/StudentFeesPaymentAPi';
import { asyncGetSchoolInfo } from 'app/api/SchoolInfoApi ';
import { asyncGetUnitFilterInfo } from 'app/api/UnitApi';
import './style.css'
let editData = false;
let viewData = false;

let payoff = false
let remarks = false

const StudentFees = ({ navigation }) => {
  const dispatch = useDispatch();
  const customerFormRef = useRef();
  const getSchoolInfoSuccess = useSelector((state) => state.schoolInfoReducer.getSchoolInfoSuccess);
  const getSchoolInfoList = useSelector((state) => state.schoolInfoReducer.getSchoolInfoList);
  const getSchoolInfoFailure = useSelector((state) => state.schoolInfoReducer.getSchoolInfoFailure);

  const getStudentFeesPaymentSuccess = useSelector((state) => state.studentFeesPaymentReducer.getStudentFeesPaymentSuccess);
  const getStudentFeesPaymentList = useSelector((state) => state.studentFeesPaymentReducer.getStudentFeesPaymentList);
  const getStudentFeesPaymentFailure = useSelector((state) => state.studentFeesPaymentReducer.getStudentFeesPaymentFailure);

  const getStudentFeesPaymentDetailsSuccess = useSelector((state) => state.studentFeesPaymentReducer.getStudentFeesPaymentDetailsSuccess);
  const getStudentFeesPaymentDetailsList = useSelector((state) => state.studentFeesPaymentReducer.getStudentFeesPaymentDetailsList);
  const getStudentFeesPaymentDetailsFailure = useSelector((state) => state.studentFeesPaymentReducer.getStudentFeesPaymentDetailsFailure);

  const getBatchSuccess = useSelector((state) => state.batchReducer.getBatchSuccess);
  const getBatchList = useSelector((state) => state.batchReducer.getBatchList);
  const getBatchFailure = useSelector((state) => state.batchReducer.getBatchFailure);
  const batchErrorMessage = useSelector((state) => state.batchReducer.errorMessage);

  const getEnrollmentDetailsSuccess = useSelector((state) => state.enrollmentDetailsReducer.getEnrollmentDetailsSuccess);
  const getEnrollmentDetailsList = useSelector((state) => state.enrollmentDetailsReducer.getEnrollmentDetailsList);
  const getEnrollmentDetailsFailure = useSelector((state) => state.enrollmentDetailsReducer.getEnrollmentDetailsFailure);
  const enrollmentDetailsErrorMessage = useSelector((state) => state.enrollmentDetailsReducer.errorMessage);

  const getStudentEnrollmentDetailsSuccess = useSelector((state) => state.studentEnrollmentReducer.getStudentEnrollmentDetailsSuccess);
  const getStudentEnrollmentDetailsList = useSelector((state) => state.studentEnrollmentReducer.getStudentEnrollmentDetailsList);
  const getStudentEnrollmentDetailsFailure = useSelector((state) => state.studentEnrollmentReducer.getStudentEnrollmentDetailsFailure);
  const studentEnrollmentDetailsErrorMessage = useSelector((state) => state.studentEnrollmentReducer.errorMessage);

  const getFeesTypeSuccess = useSelector((state) => state.feesTypeReducer.getFeesTypeSuccess);
  const getFeesTypeList = useSelector((state) => state.feesTypeReducer.getFeesTypeList);
  const getFeesTypeFailure = useSelector((state) => state.feesTypeReducer.getFeesTypeFailure);
  const feesTypeErrorMessage = useSelector((state) => state.feesTypeReducer.errorMessage);

  const getFeesEnrollmentSuccess = useSelector((state) => state.feesEnrollmentReducer.getFeesEnrollmentSuccess);
  const getFeesEnrollmentList = useSelector((state) => state.feesEnrollmentReducer.getFeesEnrollmentList);
  const getFeesEnrollmentFailure = useSelector((state) => state.feesEnrollmentReducer.getFeesEnrollmentFailure);
  const feesEnrollmentErrorMessage = useSelector((state) => state.feesEnrollmentReducer.errorMessage);

  const createStudentFeesPaymentSuccess = useSelector((state) => state.studentFeesPaymentReducer.createStudentFeesPaymentSuccess);
  const createStudentFeesPaymentData = useSelector((state) => state.studentFeesPaymentReducer.createStudentFeesPaymentData);
  const createStudentFeesPaymentFailure = useSelector((state) => state.studentFeesPaymentReducer.createStudentFeesPaymentFailure);

  const updateStudentFeesPaymentSuccess = useSelector((state) => state.studentFeesPaymentReducer.updateStudentFeesPaymentSuccess);
  const updateStudentFeesPaymentData = useSelector((state) => state.studentFeesPaymentReducer.updateStudentFeesPaymentData);
  const updateStudentFeesPaymentFailure = useSelector((state) => state.studentFeesPaymentReducer.updateStudentFeesPaymentFailure);

  const getUnitFilterSuccess = useSelector((state) => state.unitReducer.getUnitFilterSuccess);
  const getUnitFilterList = useSelector((state) => state.unitReducer.getUnitFilterList);
  const getUnitFilterFailure = useSelector((state) => state.unitReducer.getUnitFilterFailure);

  const unitErrorMessage = useSelector((state) => state.unitReducer.errorMessage);
  const studentFeesPaymentErrorMessage = useSelector((state) => state.studentFeesPaymentReducer.errorMessage);
  const schoolInfoErrorMessage = useSelector((state) => state.schoolInfoReducer.errorMessage);


  const [pageItems, setpageItems] = useState({
    "view": false,
    "create": true,
    "update": false,
    "delete": false
  }
  );

  useEffect(() => {
    let data = pagePermission(pageItems, "Administrator", "Fees Payment")
    setpageItems(data)

  }, [navigation]);

  const column = [
    {
      "dataField": "",
      "text": "S.No",
      "sort": true,
      formatter: (cellContent, row, index) => (
        index + 1
      )
    },
    {
      "dataField": "studentName",
      "text": "Student Name",
      "sort": true,
    },
    {
      dataField: "isFullyPaid",
      text: "Payment",
      sort: true,
      align: "center",
      headerAlign: "center",
      formatter: (cellContent, row, index) => {
        const pendingPayment = _.filter((row?.feesData || []), { isFullyPaid: false })
        const data = pendingPayment.length > 0 ? "Pending" : "Paid";// ? "Paid" : (row.balanceAmount != null) ? amountFormat(row.balanceAmount) : "Not-Paid"
        const status = pendingPayment.length > 0 ? false : true;
        const conInfo = { inputType: "status", status: status, succesName: data, failureName: data }
        return formRender(null, null, conInfo, null);
      }
    },

  ]

  if ((pageItems.update && pageItems.create) || pageItems.view) {
    column.push({
      text: "Action",
      sort: false,
      align: "center",
      headerAlign: "center",
      formatter: (cellContent, row, index) => (
        <div className="icon-container">
          {pageItems.update && pageItems.create && <FiEdit
            className="text-primary cursor-pointer"
            size={18}
            onClick={() => onEditForm(row, index)}
          ></FiEdit>}
          {pageItems.view && <MdVisibility
            className="text-success cursor-pointer"
            size={18}
            onClick={() => onViewDetails(row, index)}
          ></MdVisibility>}
          {/*  <MdDelete
                      className="text-danger cursor-pointer"
                      size={18}
                      onClick={() => {
                          swal
                              .fire({
                                  title: "Are you sure?",
                                  text: "You won't be able to revert this!",
                                  icon: "warning",
                                  type: "question",
                                  showCancelButton: true,
                                  confirmButtonColor: "#3085d6",
                                  cancelButtonColor: "#d33",
                                  confirmButtonText: "Yes, delete it!",
                                  cancelButtonText: "No",
                              })
                              .then((result) => {
                                  if (result.value) {
                                     
                                      onDeleteItem(row, index);
                                  } else {
                                      swal.fire({
                                          title: "Cancelled!",
                                          text: "Permission denied.",
                                          type: "error",
                                          icon: "error",
                                          timer: 1500,
                                      });
                                  }
                              });
                      }}
                  ></MdDelete> */}
        </div>
      )
    });
  }


  const subColumn = [
    /* {
        "dataField": "",
        "text": "S.No",
        "sort": true,
        formatter: (cellContent, row, index) => (
            index + 1
        )
    }, */
    {
      "dataField": "feesName",
      "text": "Fees Name",
      "sort": true,
    },
    {
      "dataField": "feesAmount",
      "text": "Fees Amount",
      "sort": true,
      formatter: (cellContent, row, index) => {

        return amountFormat(cellContent > 0 ? cellContent : 0)
      }
    },
    {
      "dataField": "paidAmount",
      "text": "Amount Paid",
      "sort": true,
      formatter: (cellContent, row, index) => {

        return amountFormat(cellContent > 0 ? cellContent : 0)
      }
    },
    {
      text: "Download Pdf",
      sort: false,
      align: "center",
      headerAlign: "center",
      formatter: (cellContent, row, index, data, subIndex) => (
        <div >

          <MdDownload
            className="text-info cursor-pointer"
            size={18}
            onClick={() => onDownloadPdf(row, index, subIndex)}
          ></MdDownload>
          {/*  <MdDelete
                        className="text-danger cursor-pointer"
                        size={18}
                        onClick={() => {
                            swal
                                .fire({
                                    title: "Are you sure?",
                                    text: "You won't be able to revert this!",
                                    icon: "warning",
                                    type: "question",
                                    showCancelButton: true,
                                    confirmButtonColor: "#3085d6",
                                    cancelButtonColor: "#d33",
                                    confirmButtonText: "Yes, delete it!",
                                    cancelButtonText: "No",
                                })
                                .then((result) => {
                                    if (result.value) {
                                       
                                        onDeleteItem(row, index);
                                    } else {
                                        swal.fire({
                                            title: "Cancelled!",
                                            text: "Permission denied.",
                                            type: "error",
                                            icon: "error",
                                            timer: 1500,
                                        });
                                    }
                                });
                        }}
                    ></MdDelete> */}
        </div>
      )
    },
  ]

  const feesSubColumn = [
    /* {
        "dataField": "",
        "text": "S.No",
        "sort": true,
        formatter: (cellContent, row, index) => (
            index + 1
        )
    }, */
    {
      "dataField": "feesTypeName",
      "text": "Fees Name",
      "sort": true,
    },
    {
      "dataField": "subFees",
      "text": "Fees Amount",
      "sort": true,
      formatter: (cellContent, row, index) => {

        return amountFormat(cellContent > 0 ? cellContent : 0)
      }
    },

  ]

  const feesColumn = [
    {
      "dataField": "",
      "text": "S.No",
      "sort": true,
      formatter: (cellContent, row, index) => (
        index + 1
      )
    },
    {
      "dataField": "feesName",
      "text": "Fees Name",
      "sort": true,
    },
    {
      "dataField": "feesAmount",
      "text": "Fees Amount",
      "sort": true,
      formatter: (cellContent, row, index) => {
        let data = row.feesAmount == null ? row.overallAmount : cellContent
        return amountFormat(data > 0 ? data : 0)
      }
    },
    {
      "dataField": "balanceAmount",
      "text": "Balance Amount",
      "sort": true,
      formatter: (cellContent, row, index) => {
        return amountFormat(cellContent > 0 ? cellContent : 0)
      }
    },
    {
      "dataField": "paidAmount",
      "text": "Paid Amount",
      "sort": true,
      formatter: (cellContent, row, index) => {

        return amountFormat(cellContent > 0 ? cellContent : 0)
      }
    },
    {
      dataField: "isFullyPaid",
      text: "Payment Status",
      sort: true,
      align: "center",
      headerAlign: "center",
      formatter: (cellContent, row, index) => {
        const data = row.isFullyPaid ? "Paid" : "Pending";
        const conInfo = { inputType: "status", status: cellContent, succesName: data, failureName: data }
        return formRender(null, null, conInfo, null);
      }
    },
    {
      text: "Action",
      sort: false,
      align: "center",
      headerAlign: "center",
      formatter: (cellContent, row, index) => (
        <div >
          <MdDownload
            className="text-info cursor-pointer"
            size={18}
            onClick={() => onDownloadAll(row, index)}
          ></MdDownload>

        </div>
      )
    },

  ]



  const paymentFeesColumn = [
    {
      "dataField": "feesName",
      "text": "Fees Name",
      "sort": true,
    },
    {
      "dataField": "feesAmount",
      "text": "Fees Amount",
      "sort": true,
      formatter: (cellContent, row, index) => {
        /*  let updatedState =  state?.paymentHistory || [];
         updatedState[index] = {
             ...updatedState[index],
             feesAmount: valueToFormat
         }; */


        return amountFormat(row.feesAmount != null ? row.feesAmount : row.overallAmount);
      }

    },
    {
      "dataField": "balanceAmount",
      "text": "Balance Amount",
      "sort": true,
      formatter: (cellContent, row, index) => {
        return amountFormat(cellContent > 0 ? cellContent : 0)
      }
    },
    /*{
        "dataField": "paidAmount",
        "text": "Paid Amount",
        "sort": true,
    },*/
    {
      dataField: "isFullyPaid",
      text: "Payment Status",
      sort: true,
      align: "center",
      headerAlign: "center",
      formatter: (cellContent, row, index) => {
        const data = row?.isFullyPaid ? "Paid" : "Pending" /* ?  (row.balanceAmount != null) ? amountFormat(row.balanceAmount) : "Not-Paid"  */
        const status = data == "Pending" ? false : true;
        // let disable = status == true ? 'disabled' : ''
        // const conInfo = { inputType: "status", status: status, succesName: data, failureName: data }
        // const data = row.isFullyPaid ? "Paid" : "Pending";
        const conInfo = { inputType: "status", status: cellContent, succesName: data, failureName: data }
        return formRender(null, null, conInfo, null);
      }
    },

    {
      text: "Pay Amount",
      sort: false,
      align: "center",
      headerAlign: "center",
      formatter: (cellContent, row, index) => {
        const data = row?.isFullyPaid ? "Paid" : "Pending" /* ? "Paid" : (row.balanceAmount != null) ? amountFormat(row.balanceAmount) : "Not-Paid" */
        const status = (data == "Pending") ? false : true;
        let disable = status == true ? true : false
        const conInfo = { inputType: "status", status: status, succesName: data, failureName: data }
        let valueDisable = (row?.payOff > 0 || row?.payOff != null) ? true : false
        // let valueDisable = row.payAmount >= state.balance ? true : false
        return (
          < >
            <input
              id={row.payAmount}
              name="payAmount"
              className="form-control"
              placeholder={"Enter Amount"}
              type={"text"}
              disabled={disable}
              onChange={(event) => handleChange(event, index)}//{(event) => onChangeCallback?.[info.onChange] ? onChangeCallback?.[info.onChange](event) : handleChange(event)}
              // disabled={ele.editable === false ? true : false}
              // onBlur={info.fieldType === 'email' ? handleEmail : handleSub}
              // onFocus={removeErrors}
              value={state.paymentHistory[index]?.payAmount || ''}
            />
          </>
        )
      },

    },
    {
      text: "Discount",
      sort: false,
      align: "center",
      headerAlign: "center",
      formatter: (cellContent, row, index) => {
        const data = row?.isFullyPaid ? "Paid" : "Pending" /* ? "Paid" : (row.balanceAmount != null) ? amountFormat(row.balanceAmount) : "Not-Paid" */
        const status = (data == "Pending") ? false : true;
        let disable = status == true ? true : false
        const conInfo = { inputType: "status", status: status, succesName: data, failureName: data }
        let valueDisable = state?.totalFeesAmount != state?.totalBalanceAmount ? true : false

        return (
          < >
            <input
              id={row.payOff}
              name="payOff"
              className="form-control"
              placeholder={"Enter Discount"}
              type={"text"}
              onChange={(event) => handleDiscountChange(event, index)}//{(event) => onChangeCallback?.[info.onChange] ? onChangeCallback?.[info.onChange](event) : handleChange(event)}
              disabled={valueDisable}
            // onBlur={info.fieldType === 'email' ? handleEmail : handleSub}
            // onFocus={removeErrors}
            // value={valueField}
            />
          </>
        )
      },

    },
    {
      text: "Remarks",
      sort: false,
      align: "center",
      headerAlign: "center",
      formatter: (cellContent, row, index) => {
        const data = row?.isFullyPaid ? "Paid" : "Pending" /* ? "Paid" : (row.balanceAmount != null) ? amountFormat(row.balanceAmount) : "Not-Paid" */
        const status = (data == "Pending") ? false : true;
        let disable = status == true ? true : false
        const conInfo = { inputType: "status", status: status, succesName: data, failureName: data }
        let valueDisable = state?.totalFeesAmount != state?.totalBalanceAmount ? true : false

        return (
          < >
            <input
              id={row.remarks}
              name="remarks"
              className="form-control"
              placeholder={"Enter Remarks"}
              type={"text"}
              disabled={valueDisable}

              onChange={(event) => handleRemarks(event, index)}//{(event) => onChangeCallback?.[info.onChange] ? onChangeCallback?.[info.onChange](event) : handleChange(event)}
            // disabled={ele.editable === false ? true : false}
            // onBlur={info.fieldType === 'email' ? handleEmail : handleSub}
            // onFocus={removeErrors}
            // value={valueField}
            />
          </>
        )
      },

    },

  ]


  const [state, setState] = useState({
    studentFeesPaymentName: "",
    studentFeesPaymentStart: "",
    studentFeesPaymentEnd: "",
    disable: false,
    paymentHistory: []
  });

  const [createModule, setCreateModule] = useState(false);
  const [selectedPaymentState, setSelectedPaymentState] = useState(true);
  const [loaderModule, setLoaderModule] = useState(false);
  const [viewModule, setViewModule] = useState(false);
  const [deleteModule, setDeleteModule] = useState(false);
  const [downloadPdf, setDownloadPdf] = useState(false);
  const [itemList, setItemList] = useState([]);
  const [selectedItem, setSelectedItem] = useState(false);
  const [paymentModule, setPaymentModule] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(false);

  const listFetching = async () => {
    const requestData = {
      isActive: "true",
      statusId: 1
    }
    let schoolInfoList = await asyncGetSchoolInfo(requestData)

    let classList = await asyncGetClassInfo(requestData)
    let sectionList = await asyncGetSectionInfo(requestData)
    let batchList = await asyncGetBatchInfo(requestData)
    let stateList = await asyncGetStateInfo(requestData)
    let enrollmentDetailsList = await asyncGetEnrollmenetDetailsInfo(requestData)
    let unitList = await asyncGetUnitFilterInfo(requestData)


    let pageData = localStorage.getItem("unitId")
    let parsedData = JSON.parse(pageData)
    let data = (unitList?.data || []).filter(ele => parsedData?.[0]?.unitId == ele.unitId)

    setState({
      ...state,
      // studentEnrollmentDetailsList:studentList?.data || [],
      unitFilterList: data,
      classList: classList?.data || [],
      sectionList: sectionList?.data || [],
      batchList: batchList?.data || [],
      stateList: stateList?.data || [],
      schoolInfoList: schoolInfoList?.data || [],
      enrollmentDetailsList: enrollmentDetailsList?.data || [],
    })

  }




  useEffect(() => {
    setLoaderModule(true)
    const requestData = {
      "isActive": true
    }
    // dispatch(getBatch(requestData))
    dispatch(getFeesType(requestData))
    // dispatch(getEnrollmentDetails(requestData))
    // dispatch(getSchoolInfo(requestData))
    // dispatch(getUnitFilter(requestData))
    listFetching()
  }, [navigation]);

  useEffect(() => {
    if (getStudentFeesPaymentSuccess) {
      setItemList(getStudentFeesPaymentList)
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_STUDENT_FEES_PAYMENT" })
    } else if (getStudentFeesPaymentFailure) {
      setItemList([])
      showMessage("warning", studentFeesPaymentErrorMessage?.message)
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_STUDENT_FEES_PAYMENT" })
    }
  }, [getStudentFeesPaymentSuccess, getStudentFeesPaymentFailure, getStudentFeesPaymentList]);


  useEffect(() => {
    if (getUnitFilterSuccess) {
      let data = getUnitFilterList.filter(ele => global.unitId == ele.unitId)
      setState({
        ...state,
        unitFilterList: data
      })
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_FILTER_UNIT" })
    } else if (getUnitFilterFailure) {
      setState({
        ...state,
        unitFilterList: []
      })
      showMessage("warning", unitErrorMessage?.message)
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_FILTER_UNIT" })
    }
  }, [getUnitFilterSuccess, getUnitFilterFailure]);

  useEffect(() => {
    if (getStudentFeesPaymentDetailsSuccess) {
      setState({
        ...state,
        studentFeesPaymentDetailsList: getStudentFeesPaymentDetailsList
      })
      setLoaderModule(false)
      onPaymentEditForm(getStudentFeesPaymentDetailsList)
      dispatch({ type: "RESET_GET_STUDENT_FEES_DETAILS_PAYMENT" })
    } else if (getStudentFeesPaymentDetailsFailure) {
      setState({
        ...state,
        studentFeesPaymentDetailsList: []
      })
      showMessage("warning", batchErrorMessage?.message)
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_STUDENT_FEES_DETAILS_PAYMENT" })
    }
  }, [getStudentFeesPaymentDetailsSuccess, getStudentFeesPaymentDetailsFailure]);

  useEffect(() => {
    if (getSchoolInfoSuccess) {
      setState({
        ...state,
        schoolInfoList: [...getSchoolInfoList]
      })
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_SCHOOL_INFO" })
    } else if (getSchoolInfoFailure) {
      setState({
        ...state,
        schoolInfoList: []
      })
      showMessage("warning", schoolInfoErrorMessage?.message)
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_SCHOOL_INFO" })
    }
  }, [getSchoolInfoSuccess, getSchoolInfoFailure]);

  useEffect(() => {
    setState({
      ...state,
      loading: true
    })
  }, [paymentModule]);

  useEffect(() => {
    if (getEnrollmentDetailsSuccess) {
      setState({
        ...state,
        enrollmentDetailsList: getEnrollmentDetailsList
      })
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_ENROLLMENT_DETAILS" })
    } else if (getEnrollmentDetailsFailure) {
      setState({
        ...state,
        enrollmentDetailsList: []
      })
      showMessage("warning", enrollmentDetailsErrorMessage?.message)
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_ENROLLMENT_DETAILS" })
    }
  }, [getEnrollmentDetailsSuccess, getEnrollmentDetailsFailure]);

  useEffect(() => {
    const enrollmentIdData = state?.enrollmentId || null
    const batchIdData = state?.batchId || null
    if (enrollmentIdData != null && batchIdData != null) {
      const req = {
        "enrollmentId": state.enrollmentId,
        "batchId": state.batchId
      }
      dispatch(getStudentEnrollmentDetails(req))
    }
  }, [state.enrollmentId, state.batchId]);

  useEffect(() => {
    if (getStudentEnrollmentDetailsSuccess) {
      setState({
        ...state,
        studentEnrollmentDetailsList: getStudentEnrollmentDetailsList
      })
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_STUDENT_ENROLLMENT_DETAILS" })
    } else if (getStudentEnrollmentDetailsFailure) {
      setState({
        ...state,
        studentEnrollmentDetailsList: []
      })
      showMessage("warning", studentEnrollmentDetailsErrorMessage?.message)
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_STUDENT_ENROLLMENT_DETAILS" })
    }
  }, [getStudentEnrollmentDetailsSuccess, getStudentEnrollmentDetailsFailure]);

  useEffect(() => {
    if (getFeesEnrollmentSuccess) {
      setState({
        ...state,
        feesEnrollmentList: getFeesEnrollmentList
      })
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_FEES_ENROLLMENT" })
    } else if (getFeesEnrollmentFailure) {
      setState({
        ...state,
        feesEnrollmentList: []
      })
      showMessage("warning", feesEnrollmentErrorMessage?.message)
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_FEES_ENROLLMENT" })
    }
  }, [getFeesEnrollmentSuccess, getFeesEnrollmentFailure]);

  useEffect(() => {
    if (getBatchSuccess) {
      setState({
        ...state,
        batchList: getBatchList
      })
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_BATCH" })
    } else if (getBatchFailure) {
      setState({
        ...state,
        batchList: []
      })
      showMessage("warning", batchErrorMessage?.message)
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_BATCH" })
    }
  }, [getBatchSuccess, getBatchFailure]);

  useEffect(() => {
    if (getFeesTypeSuccess) {
      setState({
        ...state,
        feesTypeList: getFeesTypeList
      })
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_FEES_TYPE" })
    } else if (getFeesTypeFailure) {
      setState({
        ...state,
        feesTypeList: []
      })
      showMessage("warning", feesTypeErrorMessage?.message)
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_FEES_TYPE" })
    }
  }, [getFeesTypeSuccess, getFeesTypeFailure]);

  useEffect(() => {
    if (createStudentFeesPaymentSuccess) {

      const temp_state = [...itemList, createStudentFeesPaymentData[0]];
      setItemList(temp_state)
      let data = createStudentFeesPaymentData[0]
      dispatch(getStudentFeesPayment({ "enrollmentId": data.enrollmentId, "batchId": data.batchId }))
      closeModule()
      setLoaderModule(false)
      Alert({ description: 'Created Successfully.', type: 'success', placement: 'topRight', duration: 3 });
      dispatch({ type: "RESET_CREATE_STUDENT_FEES_PAYMENT" })
    } else if (createStudentFeesPaymentFailure) {
      setLoaderModule(false)
      showMessage("warning", studentFeesPaymentErrorMessage?.message)
      dispatch({ type: "RESET_CREATE_STUDENT_FEES_PAYMENT" })
    }
  }, [createStudentFeesPaymentSuccess, createStudentFeesPaymentFailure]);

  useEffect(() => {
    if (updateStudentFeesPaymentSuccess) {
      updateTable(updateStudentFeesPaymentData[0])
      dispatch({ type: "RESET_UPDATE_STUDENT_FEES_PAYMENT" })
    } else if (updateStudentFeesPaymentFailure) {
      setLoaderModule(false)
      showMessage("warning", studentFeesPaymentErrorMessage?.message)
      dispatch({ type: "RESET_UPDATE_STUDENT_FEES_PAYMENT" })
    }
  }, [updateStudentFeesPaymentSuccess, updateStudentFeesPaymentFailure]);

  const updateTable = (updatedItem) => {
    const temp_state = [...itemList];
    if (deleteModule) {
      let remainingList = _.remove(temp_state, function (n) {
        return n.studentFeesPaymentId != updatedItem.studentFeesPaymentId;
      });
      Alert({ description: 'Deleted Successfully.', type: 'success', placement: 'topRight', duration: 3 });
      setItemList(remainingList)
    } else {
      temp_state[selectedIndex] = updatedItem;
      Alert({ description: 'Updated Successfully.', type: 'success', placement: 'topRight', duration: 3 });
      setItemList(temp_state)
    }
    setLoaderModule(false)
    closeModule()
  }

  const closeModule = () => {
    editData = false;
    setCreateModule(false)
    setDeleteModule(false)
    setPaymentModule(false)
    setViewModule(false)
  }

  const resetState = () => {
    setState({
      ...state,
      feesTypeId: "",
      paymentHistory: [],
      paidAmount: "",
      fullyPaid: "",
      lastPaidAmount:0,
      batchId: "",
      enrollmentId: "",
      studentEnrollmentId: "",
    })
  }

  const onViewDetails = async (data, index) => {
    // console.log(JSON.stringify(data));
    viewData = true
    const requestData = {
      // isActive: "true",
      batchId: data?.batchId,
      enrollmentId: data?.enrollmentId,
      studentId: data?.studentId
    }
    setPaymentModule(false)
    let paymentDetails = await asyncgetStudentFeesPaymentDetails(requestData)
    setState({
      ...state,
      studentFeesPaymentDetailsList: paymentDetails?.data || []
    })
    onPaymentEditForm(paymentDetails.data ? paymentDetails.data : [])

    // dispatch(getStudentFeesPaymentDetails(requestData))

  }

  const onCreateForm = () => {
    resetState()
    editData = false;
    setCreateModule(true)
    setPaymentModule(false)
    setViewModule(false)
  }

  const onEditForm = async (data, index) => {
    setLoaderModule(true)
    let payoffvalue = parseInt(data.payOff)
    if (data.payOff === "0") {
      payoff = true;
    } else if (payoffvalue > 0) {
      payoff = true;

    } else if (data.payOff === null) {
      payoff = false;
    }

    if (data.remarks !== null) {
      remarks = true;
    } else if (data.remarks === null) {
      remarks = false;
    }

    let arr = []
    arr.push(data)
    state.batchId = data.batchId
    state.enrollmentId = data.enrollmentId
    state.studentFeesPaymentName = data?.studentFeesPaymentName || ""
    state.studentFeesPaymentStart = data?.studentFeesPaymentStart || ""
    state.studentFeesPaymentEnd = data?.studentFeesPaymentEnd || ""
    state.tId = data?.studentEnrollmentId || ""
    state.studentEnrollmentId = data.studentName
    state.lastPaidAmount = 0

    const requestData = {
      batchId: data.batchId,
      enrollmentId: data.enrollmentId,
      studentId: data.studentId
    }
    const request = {
      batchId: data.batchId,
      enrollmentId: data.enrollmentId,
    }
    editData = true;
    setSelectedItem(data)
    setSelectedPaymentState(true)
    setSelectedIndex(index)
    let paymentDetails = await asyncgetStudentFeesPaymentDetails(requestData)
    setState({
      ...state,
      batchId: Number(data.batchId),
      enrollmentId: Number(data.enrollmentId),
      studentEnrollmentId: data.studentName,
      studentFeesPaymentName: data?.studentFeesPaymentName || "",
      studentFeesPaymentStart: data?.studentFeesPaymentStart || "",
      studentFeesPaymentEnd: data?.studentFeesPaymentEnd || "",
      tId: data?.studentEnrollmentId || "",
      // paymentHistory:data?.feesData || []
      lastPaidAmount:0,

      studentFeesPaymentDetailsList: paymentDetails?.data || []
    })
    onPaymentEditForm(paymentDetails.data ? paymentDetails.data : [])
    // dispatch(getStudentFeesPaymentDetails(requestData))
    dispatch(getStudentFeesPayment(request))

    // setPaymentModule(false)



  }

  const onSearch = async () => {
    const selectedEnrollmentId = state?.addEnrollmentId || null
    setLoaderModule(true)

    const selectedBatchId = state?.addBatchId || null
    if (selectedEnrollmentId != null && selectedBatchId != 0 && selectedBatchId != null && selectedEnrollmentId != 0) {
      const requestData = {
        isActive: "true",
        batchId: state.addBatchId,
        enrollmentId: state.addEnrollmentId
      }
      let studentList = await asyncGetStudentEnrollmentInfo(requestData)
      // setLoaderModule(false)

      setState({
        ...state,
        studentEnrollmentDetailsList: studentList?.data || [],
      })
      dispatch(getStudentFeesPayment(requestData))


    } else {
      setItemList([])
      setLoaderModule(false)

      Alert({ description: 'Please Select the Class and Batch.', type: 'warning', placement: 'topRight', duration: 3 });

      // showMessage('warning', "Please Select the Class and Batch")
    }
  }
  console.log(loaderModule);

  const onFind = () => {
    const selectedEnrollmentId = state?.enrollmentId || null
    const selectedBatchId = state?.batchId || null
    const selectedStudentList = state?.studentEnrollmentId || []
    const selectedStudentId = selectedStudentList.length > 0 ? selectedStudentList[0].studentId : null
    if (selectedEnrollmentId != null && selectedBatchId != null && selectedStudentId != null) {
      const req = {
        isActive: "true",
        enrollmentId: selectedEnrollmentId,
      }
      dispatch(getFeesEnrollment(req))
      const requestData = {
        // isActive: "true",
        batchId: selectedBatchId,
        enrollmentId: selectedEnrollmentId,
        studentId: selectedStudentId
      }
      setPaymentModule(false)
      dispatch(getStudentFeesPaymentDetails(requestData))
    } else {
      // showMessage('warning', "Please Select the Class and Batch")
      Alert({ description: 'Please Select the Class and Batch.', type: 'warning', placement: 'topRight', duration: 3 });

    }
  }

  const onDeleteItem = (data, index) => {
    editData = false;
    setSelectedIndex(index)
    setSelectedItem(data)
    setDeleteModule(true)
  }

  const validateForm = async () => {
    const validatefom = customerFormRef.current.validateForm();
  }


  const handleChange = (event, index) => {

    let temp_state = state?.paymentHistory || [];
    let balanceAmount = selectedItem.assignedFees[index].balanceAmount != null
      ? selectedItem.assignedFees[index].balanceAmount
      : (temp_state[index].feesAmount != null ? temp_state[index].feesAmount : temp_state[index].overallAmount);

    let enteredVal = event.target.value !== '' ? parseFloat(event.target.value).toFixed(2) : 0;
    let balance = parseFloat(balanceAmount) - enteredVal;
    // alert(parseFloat(enteredVal) + "  ----- " + parseFloat(balanceAmount).toFixed(2))
    if (parseFloat(enteredVal) - parseFloat(balanceAmount) > 0.000001) {
      // showMessage('warning', 'Enter only the balance amount');
      Alert({ description: 'Enter only the balance amount.', type: 'warning', placement: 'topRight', duration: 3 });

      event.target.value = balanceAmount;
      return;
    }

    temp_state[index][event.target.name] = event.target.value;
    temp_state[index].balanceAmount = balance > 0 ? balance : 0;


    setState({
      ...state,
      paymentHistory: temp_state
    });
  };

  const handleDiscountChange = (event, index) => {
    let value = event.target.value;
    if (value <= 0) {
      showMessage('warning', 'Make Pay Amount 0 To Display Updated Balance');
    }

    let updatedState = state?.paymentHistory || [];
    let fees = parseInt(selectedItem.assignedFees[index].feesAmount != null ? selectedItem.assignedFees[index].feesAmount : selectedItem.assignedFees[index].overallAmount, 10) || 0;
    let newFeesAmount = fees - value;

    updatedState[index] = {
      ...updatedState[index],
      payAmount: 0,
      feesAmount: newFeesAmount,
      balanceAmount: newFeesAmount,
      payOff: parseInt(value),
      payoff: value
    };

    setState({
      ...state,
      paymentHistory: updatedState,
      payOff: parseInt(value)
    });

  };


  const handleRemarks = (event, index) => {
    let value = event.target.value;  // Access the value from the event object

    setState({
      ...state,  // Spread the current state
      remarks: value  // Update the remarks field with the new value
    });

    /*    let temp_state = state?.paymentHistory || []
       temp_state[index]["remarks"] = value
       setState(temp_state); */
  };





  const onPaymentEditForm = (data) => {
    if (data[0].assignedFees != null) {
      let check = false;
      (data[0].assignedFees).map(item => {
        if (item.paymentHistory != null) {
          check = true;
        }

      })
      if (check) {
        let sum = 0
        let balanceAmount = 0
        let totalBalanceAmount = 0
        data[0].assignedFees.map((item) => {
          let aa = item.feesAmount != null ? item.feesAmount : item.overallAmount
          sum += parseFloat(aa)
          totalBalanceAmount += item.balanceAmount != null ? parseFloat(item.balanceAmount) : parseFloat(item.overallAmount)
        })
        const addKeyFilter = data[0]?.assignedFees.map(v => ({ ...v, balanceAmount: v.balanceAmount != null ? v.balanceAmount : v.overallAmount }))
        console.log(" addKeyFilter  --- >" + JSON.stringify(addKeyFilter));
        const lastPaid = getLastPaidAmount(addKeyFilter);
       console.log(lastPaid);
       
        setState({
          ...state,
          studentName: data[0]?.studentName || "",
          classSection: data[0]?.classTitle + "-" + data[0]?.sectionTitle || "",
          admissionNo: data[0]?.admissionNo || "",
          batchName: data[0]?.batchName || "",
          totalFeesAmount: sum || "",
          totalBalanceAmount: totalBalanceAmount || "",
          paymentHistory: addKeyFilter,
          lastPaidAmount: lastPaid ? lastPaid[lastPaid.length - 1].paidAmount : 0,
          // totalPaid: addKeyFilter[addKeyFilter.length - 1]?.totalPaidAmount || 0,
          totalData: data,
          advanceAmount: data[0]?.totalPaidAmount || "",
        })
        // console.log(state.totalFeesAmount);
        if (viewData === false) {
          setCreateModule(true)
          setPaymentModule(true)
        }
        viewData === true ? setViewModule(true) : setPaymentModule(true)
        setLoaderModule(false)

        viewData = false
        setSelectedItem(JSON.parse(JSON.stringify(data[0])))
        editData = true
      } else {
        let sum = _.sumBy((data[0]?.assignedFees || []), function (o) { return parseFloat(o.feesAmount != null ? o.feesAmount : o.overallAmount); });//0

        if (viewData != true) {
          const addKeyFilter = data[0]?.assignedFees.map(v => ({ ...v, balanceAmount: v.feesAmount != null ? v.feesAmount : v.overallAmount, payAmount: null }))
          setState({
            ...state,
            totalFeesAmount: sum || "",
            totalBalanceAmount: data[0]?.balanceAmount != null ? data[0].balanceAmount : sum,
            // feesEnrollmentId: data[0]?.feesEnrollmentId || "",
            paymentHistory: addKeyFilter,
            totalData: data,
            advanceAmount: data[0]?.totalPaidAmount || "",

          })
          // setSelectedPaymentState(addKeyFilter)
          setPaymentModule(true)
          setCreateModule(true)
          setLoaderModule(false)

          setSelectedItem(JSON.parse(JSON.stringify(data[0])))
          editData = false
        } else {
          viewData = false
          Alert({ description: 'No Transaction Records.', type: 'warning', placement: 'topRight', duration: 3 });
        }
      }
    }
    else {
      Alert({ description: 'No Fees Assigned!', type: 'warning', placement: 'topRight', duration: 3 });
      state.paymentHistory = []
    }
  }

 

  
  function getLastPaidAmount(data) {
    const today = new Date();
    const closestPayments = data
      .map((entry) => {
        if (!entry.paymentHistory) return null; // Skip if no payment history
        const closestPayment = entry.paymentHistory.reduce((closest, current) => {
          const closestDate = new Date(closest.amountPaidDate);
          const currentDate = new Date(current.amountPaidDate);
          return Math.abs(currentDate - today) < Math.abs(closestDate - today)
            ? current
            : closest;
        });
        return closestPayment;
      })
      .filter(Boolean); // Remove null values
  
    return closestPayments.map((payment) => ({
      feesName: payment.feesName,
      paidAmount: payment.paidAmount,
      amountPaidDate: payment.amountPaidDate,
    }));
  }
  
  

  const onSubmitForm = () => {
    try {

      const selectedStudentList = state?.studentEnrollmentId || []
      const selectedstudentEnrollmentId = selectedStudentList.length ? selectedStudentList[0].studentEnrollmentId : null

      let totalPaidAmount = 0
      let total = 0
      let paymentHistoryReq = []
      let feesHistoryReq = []
      const selectedPaymentList = state?.paymentHistory || []
      let balance = selectedPaymentList[0]?.balanceAmount || 0


      selectedPaymentList.map((item, index) => {
        if (item.payAmount == "") {
          return
        }


        if (item.payAmount != null) {
          totalPaidAmount += parseFloat(item.payAmount)
          let overallAmount = (item.feesAmount !== null ? item.feesAmount : (item.overallAmount - (item?.payOff || 0)))
          total = overallAmount
          let balanceAmount = item.balanceAmount
          /*     if (editData == false && item.payOff !== null ) {
                  console.log('innnnnnnnnnn');
                  balanceAmount = item.balanceAmount - overallAmount 
                  console.log(item.overallAmount  + " ==== " + item.balanceAmount +  " ==" + overallAmount);
 
              } */

          const feesDataHistoryReq = {
            studentFeesPaymentDetailsId: item?.studentFeesPaymentDetailsId || null,
            feesAmount: overallAmount,
            paidAmount: overallAmount - balanceAmount,
            balanceAmount: balanceAmount,
            payOff: item?.payOff,
            studentEnrollmentId: state.tId,
            feesEnrollmentId: item.feesEnrollmentId
          }
          if (feesDataHistoryReq.studentFeesPaymentDetailsId != null) {
            feesHistoryReq.push(feesDataHistoryReq)
          } else {
            delete feesDataHistoryReq.studentFeesPaymentDetailsId
            feesHistoryReq.push(feesDataHistoryReq)
          }

          if (item.payAmount != "") {
            const request = {
              studentEnrollmentId: state.tId,
              "feesEnrollmentId": item.feesEnrollmentId,
              "amountPaidDate": new Date(),
              "amount": item.payAmount,
              "balanceAmount": balanceAmount,
            }
            paymentHistoryReq.push(request)
          }
        }
      })
      const requestDate = {
        totalPaidAmount: totalPaidAmount,
        totalFeesAmount: total,
        totalBalanceAmount: state.totalFeesAmount - totalPaidAmount,
        studentEnrollmentId: state.tId,
        paymentHistory: paymentHistoryReq,
        feesHistory: feesHistoryReq,
        /*  payOff: parseInt(state?.payOff || 0),
         remarks: state?.remarks || "null" */
        /*  paidAmount: parseInt(paidedAmount) + parseInt(paidAmount),
         isFullyPaid: fullyPaid,
         feesAmount: feesAmount,
         balanceAmount: parseInt(balanceAmount) - parseInt(paidAmount),
         feesEnrollmentId: feesEnrollmentId,
         studentEnrollmentId: selectedstudentEnrollmentId,
         paymentHistory: {
             feesTypeId: feesTypeId,
             amountPaidDate: 'now()',
             amount: paidAmount
         } */
      }
      console.log(JSON.stringify(requestDate));

      // return
      if (requestDate.payOff === "") {
        Alert({ description: 'Make payoff 0 if there is no discount', type: 'warning', placement: 'topRight', duration: 3 });

      } else if (requestDate.remarks === "" || requestDate.remarks === null) {
        // showMessage('warning', "Enter the Remarks!");
        Alert({ description: 'Enter the Remarks!', type: 'warning', placement: 'topRight', duration: 3 });

        return;
      } else {
        if (editData) {
          if (requestDate.payOff == 0) {
            delete requestDate.payOff
          }
          else if (requestDate.remarks == "null") {
            delete requestDate.remarks
          }
          if (requestDate.feesHistory.length === 0 && requestDate.paymentHistory.length === 0) {
            // showMessage('warning', "Enter the Fees Amount!")
            Alert({ description: 'Enter the Fees Amount!', type: 'warning', placement: 'topRight', duration: 3 });

          }
          else {
            // return
            setLoaderModule(true)
            dispatch(updateStudentFeesPayment(requestDate, selectedItem.studentFeesPaymentId))
          }

        } else if (deleteModule) {
          const deleteRequest = {
            isActive: false
          }
          setLoaderModule(true)
          dispatch(updateStudentFeesPayment(deleteRequest, selectedItem.studentFeesPaymentId))
        } else {
          if (requestDate.feesHistory.length === 0 && requestDate.paymentHistory.length === 0) {
            Alert({ description: 'Enter the Fees Amount!', type: 'warning', placement: 'topRight', duration: 3 });

          }
          else {
            // return
            setLoaderModule(true)
            dispatch(createStudentFeesPayment(requestDate))
          }

        }

      }
    } catch {
      console.log('====================================');
      console.log("error");
      console.log('====================================');
    }
  }

  const onDownloadPdf = (data, index, subIndex) => {
    let amountData = state?.paymentHistory?.[index]?.paymentHistory || []
    let sum = 0;
    for (let i = 0; i <= subIndex; i++) {
      sum = parseFloat(sum) + parseFloat(amountData[i].paidAmount);
    }

    data.studentName = state?.studentName || ''
    data.classSection = state?.classSection || ''
    data.admissionNo = state?.admissionNo || ''
    data.batchName = state?.batchName || ''
    // data.paymentHistory?.[0].studentName = state?.studentName || ''
    // let totalPaidAmount = 0;
    /* (state?.paymentHistory || []).forEach(ele => {
      totalPaidAmount += parseFloat(ele.paidAmount);
    }); */
    let req = {
      "totalPaidAmount": amountFormat(data.paidAmount),
      "subTotal": amountFormat(data.paidAmount),
      "balance": amountFormat(data.balanceAmount)
    }
    setState({
      ...state,
      pdfFile: [data],
      feesData: req,
      advanceInvoice: sum
    })
    setDownloadPdf(true)
    setViewModule(false)

  }

  const onDownloadAll = (data, index) => {
    // console.log(JSON.stringify(state.studentFeesPaymentDetailsList));
    if (data.paymentHistory == null) {
      // showMessage('warning', "No Transaction Records!....")
      Alert({ description: "No Transaction Records!....", type: 'warning', placement: 'topRight', duration: 3 });

      return false
    }
    let req = {}
    data.paymentHistory[0].studentName = state?.studentName || ''
    let totalPaidAmount = 0;
    let totalAmount = 0;
    data.paymentHistory.forEach(ele => {
      totalPaidAmount += parseFloat(ele.paidAmount);
      totalAmount = parseFloat(ele.feesAmount);
      req.feesItem = ele.feesItem
      req.feesName = ele.feesName
      req.amountPaidDate = ele.amountPaidDate
    });
    /* data.forEach(ele => {
        totalPaidAmount += parseFloat(ele.paidAmount);
        totalAmount = parseFloat(ele.feesAmount);
      }); */
    req.feesAmount = totalAmount
    req.paidAmount = totalPaidAmount
    req.studentName = state?.studentName || ''
    req.classSection = state?.classSection || ''
    req.admissionNo = state?.admissionNo || ''
    req.batchName = state?.batchName || ''
    let dataReq = {
      "totalPaidAmount": amountFormat(totalPaidAmount),
      "subTotal": amountFormat(totalPaidAmount),
      "balance": amountFormat(totalAmount - totalPaidAmount)
    }
    setState({
      ...state,
      pdfFile: [req],
      feesData: dataReq,
      advanceInvoice: totalPaidAmount
    })
    setDownloadPdf(true)
    setViewModule(false)

  }

  const onClosePdf = () => {
    setDownloadPdf(false)
    // setViewModule(false)

  }
  const handlePay = (event) => {
    // alert(event.target.checked)
    setSelectedPaymentState(event.target.checked == true ? false : true)
  }
  const {
    feesTypeId,
    paidAmount,
    feesAmount,
    fullyPaid,
    feesEnrollmentId,
    balanceAmount,
    studentEnrollmentId
  } = state;

  const modelTitle = editData ? "Edit " : "Create ";
  const modelButtonLbl = editData ? "" : "Save";

  const { sidebarOpen } = useSidebar();


  return (
    <div >
      <Loading status={loaderModule} />
      <div style={sidebarOpen ? { marginLeft: "50px", marginTop: "50px", marginBottom: "-20px" } : { marginLeft: "90px", marginTop: "50px", marginBottom: "-50px" }}>
        <FilterComponent defaultState={state} /* btnAlign={"start "} */ setDefaultState={setState} dynamicForm={studentFeesPaymentFilterForm} noOfColumn={6} btnClickFun={() => onSearch()} />

      </div>
      <View /* centered={true} */ size={'lg'} isOpen={downloadPdf} onClose={setDownloadPdf} title="Download Pdf">
        <InvoiceGenerator data={state?.pdfFile || []} schoolInfo={state?.schoolInfoList?.[0] || {}} onClick={onClosePdf} unitDetails={state?.unitFilterList || []} feesData={state?.feesData || {}} advance={state?.advanceInvoice || ''} />
      </View>

      <View isOpen={createModule} size="lg" savetitle={modelButtonLbl} onClose={setCreateModule} onSubmit={onSubmitForm} title={`${modelTitle} Student Fees Payment`}>
        <FormLayout ref={customerFormRef} defaultState={state} setDefaultState={setState} dynamicForm={studentFeesPaymentForm} noOfColumn={4} />

        {paymentModule === true ? (
          <>
            <div className="fees-container">
              <div className="column-1">
                <div className="viewCardFees viewCard1">
                  <div className="cardContent">
                    <h4 className="card_title">Total Fees:</h4>
                    <span className="card_amount">{amountFormat(state?.totalFeesAmount || "")}</span>
                  </div>
                </div>
              </div>
              <div className="column-1">
                <div className="viewCardFees viewCard4">
                  <div className="cardContent">
                    <h4 className="card_title">Total Paid Amount:</h4>
                    <span className="card_amount">{amountFormat((state?.totalFeesAmount || 0) - (state?.totalBalanceAmount || 0))}</span>
                  </div>
                </div>
              </div>
              <div className="column-1">
                <div className="viewCardFees viewCard2">
                  <div className="cardContent">
                    <h4 className="card_title">Balance Fees:</h4>
                    <span className="card_amount">{amountFormat(state?.totalBalanceAmount || 0)}</span>
                  </div>
                </div>
              </div>

              <div className="column-1">
                <div className="viewCardFees viewCard3">
                  <div className="cardContent">
                    <h4 className="card_title">Last Paid Amount:</h4>
                    <span className="card_amount">{amountFormat(state?.lastPaidAmount || 0)}</span>
                  </div>
                </div>
              </div>
              
            </div>

            <CustomTable
              isExpand={true}
              columns={paymentFeesColumn}
              highlight={{ "dataField": "isSos", "value": "true" }}
              subColumnItem={"feesItem"}
              subColumns={feesSubColumn}
              data={state?.paymentHistory || []}
              state={state}
            />
          </>
        ) : (
          <h4 className="opacity-50 text-center mt-5 mb-5">Please Select the Data Given Option...!</h4>
        )}


        {/* <FormLayout ref={customerFormRef} defaultState={state} setDefaultState={setState} dynamicForm={studentFeesPaymentForm} noOfColumn={4} onSubmit={onSubmitForm} /> */}
      </View>

      <View centered={true} size={'sm'} isOpen={deleteModule} onClose={setDeleteModule} savetitle="Yes" onSubmit={onSubmitForm} title="Delete Student Fees Payment">
        <h6 className="text-capitalize mb-1">Are you sure ?</h6>
      </View>

      <View centered={true} size={'lg'} isOpen={viewModule} onClose={setViewModule} savetitle="close" onSubmit={closeModule} title="Payment History">
        <h6 className="mb-2">Total Fees : {amountFormat(state?.totalFeesAmount || "")}</h6>
        <h6>Balance Fees : {amountFormat(state?.totalBalanceAmount || "")}</h6>
        <CustomTable isExpand={true} columns={feesColumn} highlight={{ "dataField": "isSos", "value": "true" }} subColumnItem={"paymentHistory"} subColumns={subColumn} data={state?.paymentHistory || []} state={state} />
      </View>

      <SimpleCard  >
        <CustomTable columns={column} data={itemList} tableName={"Fees Payment"} state={state} />

      </SimpleCard>


    </div>
  )
}

export default StudentFees