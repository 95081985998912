import * as yup from "yup";
import { Formik } from "formik";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import DateTime from "react-datetime";
import { Form, Button } from "react-bootstrap";
import { DateRange } from "react-date-range";
import SimpleCard from 'app/components/atoms/simple-card/SimpleCard'
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { MdEdit, MdDelete } from "react-icons/md";
import swal from "sweetalert2";
import _ from "lodash";
import { pagePermission, dateConversion, showMessage } from "app/utils/app-functions";
import FormLayout from "app/components/render/formlayout";
import View from "app/components/atoms/modalView/Modalview";
import CustomTable from "app/components/atoms/custom-table/CustomTable";
import formRender from "app/components/render/formcomponent";
import { materialForm } from "./formData";
import { FiEdit, FiSend, FiTrash2 } from 'react-icons/fi';
import { createMaterial, createMaterialType, getMaterial, getMaterialType, updateMaterial, updateMaterialType } from "app/api/ProductApi";
import Alert from "app/components/atoms/alert";


let editData = false;

const Material = ({ navigation }) => {
    const dispatch = useDispatch();
    const customerFormRef = useRef();

    const getMaterialTypeSuccess = useSelector((state) => state.productReducer.getMaterialTypeSuccess);
    const getMaterialTypeList = useSelector((state) => state.productReducer.getMaterialTypeList);
    const getMaterialTypeFailure = useSelector((state) => state.productReducer.getMaterialTypeFailure);

    const getMaterialSuccess = useSelector((state) => state.productReducer.getMaterialSuccess);
    const getMaterialList = useSelector((state) => state.productReducer.getMaterialList);
    const getMaterialFailure = useSelector((state) => state.productReducer.getMaterialFailure);

    const createMaterialSuccess = useSelector((state) => state.productReducer.createMaterialSuccess);
    const createMaterialData = useSelector((state) => state.productReducer.createMaterialData);
    const createMaterialFailure = useSelector((state) => state.productReducer.createMaterialFailure);

    const updateMaterialSuccess = useSelector((state) => state.productReducer.updateMaterialSuccess);
    const updateMaterialData = useSelector((state) => state.productReducer.updateMaterialData);
    const updateMaterialFailure = useSelector((state) => state.productReducer.updateMaterialFailure);

    const errorMessage = useSelector((state) => state.productReducer.errorMessage);


    const [pageItems, setpageItems] = useState({});

    useEffect(() => {
        let data = pagePermission(pageItems, "Inventory", "Material List")
        setpageItems(data)
        // console.log(data);

    }, [navigation]);

    const column = [
        {
            "dataField": "materialCode",
            "text": "Material Code",
            "sort": true
        },
        {
            "dataField": "materialName",
            "text": "Material Name",
            "sort": true
        },
        {
            "dataField": "description",
            "text": "Description ",
            "sort": false
        },
        {
            "dataField": "materialTypeName",
            "text": "Material Type",
            "sort": true
        },

    ]

    if (pageItems.update || pageItems.delete) {
        column.push({
            text: "Action",
            sort: false,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => (
                <div className="icon-container">
                    {
                        pageItems.update && <FiEdit
                            className="text-primary cursor-pointer"
                            size={18}
                            onClick={() => onEditForm(row, index)}
                        ></FiEdit>
                    }
                    {
                        pageItems.delete && <FiTrash2
                            className="text-danger cursor-pointer"
                            size={18}
                            onClick={() => {
                                onDeleteItem(row, index)
                            }}
                        ></FiTrash2>
                    }
                    {/* <MdAddTask
                        className="text-success cursor-pointer"
                        size={18}
                        onClick={() => onEditForm(row, index)}
                    ></MdAddTask> */}
                </div>
            )
        },)
    }
    const [state, setState] = useState({
        materialCode: "",
        materialName: "",
        description: "",
        materialTypeId: "",
        taxId: "",
        "materialTypeList": [],
        "taxList": []
    });

    const [createModule, setCreateModule] = useState(false);
    const [loaderModule, setLoaderModule] = useState(false);
    const [editModule, setEditModule] = useState(false);
    const [viewModule, setViewModule] = useState(false);
    const [deleteModule, setDeleteModule] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [selectedItem, setSelectedItem] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);

    useEffect(() => {
        setLoaderModule(true)
        const request = {
            isActive: "true"
        }
        dispatch(getMaterial(request))
        dispatch(getMaterialType(request))
    }, [navigation]);

    useEffect(() => {
        if (getMaterialSuccess) {
            setItemList(getMaterialList)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_MATERIAL" })
        } else if (getMaterialFailure) {
            setItemList([])
            showMessage("warning", errorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_MATERIAL" })
        }
    }, [getMaterialSuccess, getMaterialFailure]);

    useEffect(() => {
        if (getMaterialTypeSuccess) {
            setState({
                ...state,
                materialTypeList: getMaterialTypeList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_MATERIAL_TYPE" })
        } else if (getMaterialTypeFailure) {
            setItemList([])
            showMessage("warning", errorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_MATERIAL_TYPE" })
        }
    }, [getMaterialTypeSuccess, getMaterialTypeFailure]);

    useEffect(() => {
        if (createMaterialSuccess) {
            const temp_state = [...itemList, createMaterialData[0]];
            setItemList(temp_state)

            setLoaderModule(false)
            closeModule()
            Alert({ description: 'Created Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            dispatch({ type: "RESET_CREATE_MATERIAL" })
        } else if (createMaterialFailure) {
            setLoaderModule(false)
            showMessage("warning", errorMessage?.message)
            dispatch({ type: "RESET_CREATE_MATERIAL" })
        }
    }, [createMaterialSuccess, createMaterialFailure]);


    useEffect(() => {
        if (updateMaterialSuccess) {

            updateTable(updateMaterialData[0])
            dispatch({ type: "RESET_UPDATE_MATERIAL" })
        } else if (updateMaterialFailure) {
            setLoaderModule(false)
            showMessage("warning", errorMessage?.message)
            dispatch({ type: "RESET_UPDATE_MATERIAL" })
        }
    }, [updateMaterialSuccess, updateMaterialFailure]);

    const updateTable = (updatedItem) => {
        const temp_state = [...itemList];
        if (deleteModule) {
            let remainingList = _.remove(temp_state, function (n) {
                return n.materialId != selectedItem.materialId;
            });
            Alert({ description: 'Deleted Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(remainingList)
        } else {
            temp_state[selectedIndex] = updatedItem;
            Alert({ description: 'Updated Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(temp_state)
        }
        setLoaderModule(false)
        closeModule()
    }

    const closeModule = () => {
        editData = false;
        setCreateModule(false)
        setDeleteModule(false)
    }

    const resetState = () => {
        setState({
            ...state,
            materialCode: "",
            hsnNo: "",
            materialName: "",
            description: "",
            materialTypeId: "",
            taxId: ""
        })
    }

    const onCreateForm = () => {
        resetState()
        editData = false;
        setCreateModule(true)
    }

    const onEditForm = (data, index) => {
        setState({
            ...state,
            materialCode: data?.materialCode || "",
            materialName: data?.materialName || "",
            hsnNo: data?.hsnNo || "",
            description: data?.description || "",
            materialTypeId: data?.materialTypeId || "",
            taxId: data?.taxId || "",
        })
        editData = true;
        setSelectedIndex(index)
        setSelectedItem(data)
        setCreateModule(true)
    }

    const confromDialog = () => {
        return (
            swal
                .fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    type: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!",
                    cancelButtonText: "No",
                })
                .then((result) => {
                    if (result.value) {
                        onSubmitForm()
                    } else {
                        swal.fire({
                            title: "Cancelled!",
                            text: "Permission denied.",
                            type: "error",
                            icon: "error",
                            timer: 1500,
                        });
                    }
                })
        )
    }

    useEffect(() => {
        if (deleteModule) {
            confromDialog()
        }
    }, [deleteModule])

    const onDeleteItem = (data, index) => {
        editData = false;
        setSelectedItem(data)
        setSelectedIndex(index)
        setDeleteModule(true)
    }
    const handlematerialId = (event) => {
        const value = event.target.value
        setState({
            ...state,
            materialTypeId: value

        })
    }

    const validateForm = async () => {
        const validatefom = customerFormRef.current.validateForm();
    }

    const onSubmitForm = () => {
        const requestDate = {
            materialCode: state?.materialCode || "",
            materialName: state?.materialName || "",
            description: state?.description || "",
            hsnNo: state?.hsnNo || "",
            materialTypeId: state?.materialTypeId || "",
            // taxId: state?.taxId
        }
        if (editData) {
            requestDate.materialItemId = selectedItem.materialItemId
            setLoaderModule(true)
            dispatch(updateMaterial(requestDate, selectedItem.materialId))
        } else if (deleteModule) {
            const deleteRequest = {
                isActive: "false"
            }
            setLoaderModule(true)
            dispatch(updateMaterial(deleteRequest, selectedItem.materialId))
        } else {
            setLoaderModule(true)
            dispatch(createMaterial(requestDate))
        }
    }

    const {
        materialTypeList,
        taxList
    } = state;


    const handleClick = (buttonName) => {
        if (buttonName == 'Add') {
            onCreateForm();
        }
    };

    const modelTitle = editData ? "Edit " : "Create ";
    const modelButtonLbl = editData ? "" : "Save";
    return (
        <div>
            <div style={{ marginTop: "-10px" }}>
                <View isOpen={createModule} size="md" savetitle={modelButtonLbl} onClose={setCreateModule} onSubmit={validateForm} title={`${modelTitle} Material`}>
                    <FormLayout ref={customerFormRef} defaultState={state} setDefaultState={setState} dynamicForm={materialForm} noOfColumn={1} onSubmit={onSubmitForm} />
                </View>


                {/*  <View centered={true} size={'sm'} isOpen={deleteModule} onClose={setDeleteModule} savetitle="Yes" onSubmit={onSubmitForm} title="Delete Material">
                <h6 className="text-capitalize mb-1">Are you sure ?</h6>
            </View> */}

                <SimpleCard >

                    <CustomTable buttonData={pageItems.create ? ['add'] : []} handleClick={handleClick} tableName={"Material"} columns={column} data={itemList} initialPage={1} />
                </SimpleCard>

            </div>
        </div>
    )
};

export default Material;
