import React from 'react';
import { Button } from 'react-bootstrap';
import { AiFillFilePdf, AiFillFileExcel } from 'react-icons/ai';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import CustomTable from 'app/components/atoms/custom-table/CustomTable';
import FormLayout from 'app/components/render/formlayout';
import SimpleCard from 'app/components/atoms/simple-card/SimpleCard';
import { employeeAttendanceFilterForm } from './formData';
import { getEmployeeReport, getEmployeeDetails } from "app/api/EmployeeApi";
import { dateConversion, export2pdf, exportToExcel, pagePermission, showMessage } from "app/utils/app-functions";
import { getDepartment } from "app/api/Department";
import { getShift } from "app/api/EmployeeShiftApi";
import { getEmployeeLeave } from "app/api/EmployeeLeaveApi";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { MdDownload, MdPictureAsPdf } from 'react-icons/md';
import Alert from 'app/components/atoms/alert';
import FilterComponent from 'app/components/FilterComponent';
import { useSidebar } from "app/components/sidebar/sidebarContext";
import { asyncGetShiftInfo } from 'app/api/EmployeeShiftApi';
import { asyncGetDepartmentInfo } from 'app/api/Department';
import { lightTheme } from 'styles/theme';
import Loading from 'app/components/atoms/loading';

let editData = false;

const EmployeeReport = ({ navigation }) => {
    const dispatch = useDispatch();
    const customerFormRef = useRef();

    const getEmployeeDetailsSuccess = useSelector((state) => state.employeeDetailsReducer.getEmployeeDetailsSuccess);
    const getEmployeeDetailsList = useSelector((state) => state.employeeDetailsReducer.getEmployeeDetailsList);
    const getEmployeeDetailsFailure = useSelector((state) => state.employeeDetailsReducer.getEmployeeDetailsFailure);
    const employeeDetailsErrorMessage = useSelector((state) => state.employeeDetailsReducer.errorMessage);

    const getEmployeeReportSuccess = useSelector((state) => state.employeeReducer.getEmployeeReportSuccess);
    const getEmployeeReportList = useSelector((state) => state.employeeReducer.getEmployeeReportList);
    const getEmployeeReportFailure = useSelector((state) => state.employeeReducer.getEmployeeReportFailure);
    const employeeReportErrorMessage = useSelector((state) => state.employeeReducer.errorMessage);

    const getEmployeeLeaveSuccess = useSelector((state) => state.employeeLeaveReducer.getEmployeeLeaveSuccess);
    const getEmployeeLeaveList = useSelector((state) => state.employeeLeaveReducer.getEmployeeLeaveList);
    const getEmployeeLeaveFailure = useSelector((state) => state.employeeLeaveReducer.getEmployeeLeaveFailure);

    const getShiftSuccess = useSelector((state) => state.shiftReducer.getShiftSuccess);
    const getShiftList = useSelector((state) => state.shiftReducer.getShiftList);
    const getShiftFailure = useSelector((state) => state.shiftReducer.getShiftFailure);
    const shiftErrorMessage = useSelector((state) => state.shiftReducer.errorMessage);

    const getDepartmentSuccess = useSelector((state) => state.departmentReducer.getDepartmentSuccess);
    const getDepartmentList = useSelector((state) => state.departmentReducer.getDepartmentList);
    const getDepartmentFailure = useSelector((state) => state.departmentReducer.getDepartmentFailure);
    const departmentErrorMessage = useSelector((state) => state.departmentReducer.errorMessage);
    const employeeLeaveErrorMessage = useSelector((state) => state.employeeLeaveReducer.errorMessage);

    const [pageItems, setpageItems] = useState({});

    useEffect(() => {
        let data = pagePermission(pageItems, "Report", "Attendance Report")
        setpageItems(data)

    }, [navigation]);

    const column = [
        {
            "dataField": "employeeCode",
            "text": "Employee Code",
            "sort": true
        },
        {
            "dataField": "employeeName",
            "text": "Employee Name",
            "sort": true
        },
        {
            "dataField": "departmentName",
            "text": "Department Name",
            "sort": true
        },
        {
            "dataField": "designationName",
            "text": "Designation Name",
            "sort": true
        },
        {
            "dataField": "phoneNo",
            "text": "Contact Number",
            "sort": true
        },
        {
            "dataField": "dateOfJoining",
            "text": "Date Of Join",
            "sort": true,
            "formatter": (cellContent, row, index) => {
                return dateConversion(cellContent);
            }
        },
        {
            "dataField": "dateOfRelieving",
            "text": "Date Of Relieving",
            "sort": true,
            "formatter": (cellContent, row, index) => {
                return dateConversion(cellContent);
            }
        },
        {
            text: "Action",
            sort: false,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => (
                <div className="icon-container">
                    {pageItems.view && <MdPictureAsPdf
                        className="text-info cursor-pointer"
                        size={18}
                        onClick={() => onDownloadExcel(row, index)}
                    ></MdPictureAsPdf>}
                    {pageItems.view && <MdDownload
                        className="text-info cursor-pointer"
                        size={18}
                        onClick={() => onDownloadPdf(row, index)}
                    ></MdDownload>}
                </div>
            )
        },
    ]

    const [state, setState] = useState({
        shiftList: [],
        departmentList: [],
        employeeDetailsList: [],
        attendanceDate: "",
    });

    const [createModule, setCreateModule] = useState(false);
    const [loaderModule, setLoaderModule] = useState(false);
    const [editModule, setEditModule] = useState(false);
    const [viewModule, setViewModule] = useState(false);
    const [deleteModule, setDeleteModule] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [selectedItem, setSelectedItem] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);

    useEffect(() => {
        setLoaderModule(true)
        const req2 = {
            isActive: true
        }
        const request = { leaveStatusId: 2 }
        dispatch(getEmployeeLeave(request))
        /*  dispatch(getShift(req2))
         dispatch(getDepartment(req2)) */
        listFetching()

    }, [navigation]);

    const listFetching = async () => {
        const requestData = {
            isActive: "true",
        }
        let shiftList = await asyncGetShiftInfo(requestData)
        let departmentList = await asyncGetDepartmentInfo(requestData)

        setState({
            ...state,
            shiftList: shiftList?.data || [],
            departmentList: departmentList?.data || [],
        })
    }
    
    useEffect(() => {
        if (getEmployeeReportSuccess) {
            setItemList(getEmployeeReportList)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_EMPLOYEE_REPORT" })
        } else if (getEmployeeReportFailure) {
            setItemList([])
            showMessage("warning", employeeReportErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_EMPLOYEE_REPORT" })
        }
    }, [getEmployeeReportSuccess, getEmployeeReportFailure]);

    useEffect(() => {
        if (getEmployeeLeaveSuccess) {
            setState({
                ...state,
                employeeLeaveList: getEmployeeDetailsList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_EMPLOYEE_LEAVE" })
        } else if (getEmployeeLeaveFailure) {
            setItemList([])
            showMessage("warning", employeeLeaveErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_EMPLOYEE_LEAVE" })
        }
    }, [getEmployeeLeaveSuccess, getEmployeeLeaveFailure]);

    useEffect(() => {
        if (getEmployeeDetailsSuccess) {
            setState({
                ...state,
                employeeDetailsList: getEmployeeDetailsList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_EMPLOYEE_DETAILS" })
        } else if (getEmployeeDetailsFailure) {
            setState({
                ...state,
                employeeDetailsList: []
            })
            showMessage("warning", employeeDetailsErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_EMPLOYEE_DETAILS" })
        }
    }, [getEmployeeDetailsSuccess, getEmployeeDetailsFailure]);

    useEffect(() => {
        if (getShiftSuccess) {
            setState({
                ...state,
                shiftList: getShiftList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_SHIFT" })
        } else if (getShiftFailure) {
            setState({
                ...state,
                shiftList: []
            })
            showMessage("warning", shiftErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_SHIFT" })
        }
    }, [getShiftSuccess, getShiftFailure]);

    useEffect(() => {
        if (getDepartmentSuccess) {
            setState({
                ...state,
                departmentList: getDepartmentList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_DEPARTMENT" })
        } else if (getDepartmentFailure) {
            setState({
                ...state,
                departmentList: []
            })
            showMessage("warning", departmentErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_DEPARTMENT" })
        }
    }, [getDepartmentSuccess, getDepartmentFailure]);

    const onSearch = () => {
        let selectedShiftId = state?.shiftId || 0
        let selectedDepartmentId = state?.departmentId || ""
        if (state.attendanceDate == null) {
            Alert({ description: 'Please Select Date', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList([])
            return false
        }
        var requestData = {};

        if (selectedShiftId != 0) {
            requestData.shiftId = state.shiftId;
            requestData.departmentId = selectedDepartmentId == 0 ? "" : state.departmentId;
            requestData.isActive = true
        } else if (selectedDepartmentId === "" || selectedDepartmentId === 0) {
            requestData.shiftId = state.shiftId;
            requestData.isActive = true
        } else {
            requestData.departmentId = selectedDepartmentId;
            requestData.isActive = true

        }
        let shift = requestData.shiftId;
        let dept = requestData.departmentId;

        if ((shift === undefined || dept == 0) || shift === 0 || shift === ' ' || dept === undefined || dept === 0) {
            // showMessage("warning", "Please Select Shift/Department");
            Alert({ description: 'Please Select Shift/Department', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });

            setItemList([]);
        } else {
            setLoaderModule(true);
            dispatch(getEmployeeReport(requestData));
        }




    }

    const onClear = () => {
        setState({
            ...state,
            departmentId: "",
            shiftId: "",
        })
        setItemList([])
        // showMessage("success", "Cleared Successfully")
        Alert({ description: 'Cleared Successfully', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });

    }

    const onDownloadExcel = () => {    //Individual Report

    }

    const onDownloadPdf = () => {         //Individual Report

    }

    const exportExcel = () => {
        let tempArr = []
        itemList.map((item, index) => {
            let req = {
                "s.no": index + 1,
                "Employee Name": item.employeeName,
                "Employee Code": item.employeeCode,
                "Leaves Taken": item.leaveTaken.noOfLeaves,
                "Department": item.departmentName,
                "Designation": item.designationName,
                "Date Of Reliving": item.dateOfReliving,
                "Date Of Joining": item.dateOfJoining,
                "Contact Number": item.phoneNo,
            }
            tempArr.push(req)
        })
        let data = exportToExcel(tempArr, 'emp-report')
    }

    const exportPdf = () => {
        const headers = [["s.no", "Employee Name", "Employee Code", "Department", "Designation", "Contact Number", "Date Of Joining", "Date Of Reliving", "Leaves Taken"]];
        let arr = []
        itemList.map((item, index) => {
            let tempArr = []
            tempArr.push(index + 1, item.employeeName, item.employeeCode, item.departmentName, item.designationName, item.phoneNo, dateConversion(item.dateOfJoining, 'DD-MM-YYYY'), dateConversion(item.dateOfRelieving, 'DD-MM-YYYY'), item.leaveTaken.noOfLeaves)
            arr.push(tempArr)
        })
        const title = 'Employee Report';
        const name = 'sample';
        export2pdf(headers, arr, title, name)
    }

    const resetState = () => {
        setState({
            ...state,
            departmentName: "",
            description: ""
        })
    }

    const onCreateForm = () => {
        resetState()
        editData = false;
        setCreateModule(true)
    }

    const onEditForm = (data, index) => {
        setState({
            ...state,
            departmentName: data?.departmentName || "",
            description: data?.description || ""
        })
        editData = true;
        setSelectedIndex(index)
        setSelectedItem(data)
        setCreateModule(true)
    }

    const onDeleteItem = (data, index) => {
        editData = false;
        setSelectedIndex(index)
        setSelectedItem(data)
        setDeleteModule(true)
    }

    const validateForm = async () => {
        const validatefom = customerFormRef.current.validateForm();
    }

    /*  const onSubmitForm = () => {
         const requestDate = {            
             departmentName: departmentName,
             description: description
         }
         if (editData) {
             setLoaderModule(true) 
             dispatch(updateDepartment(requestDate, selectedItem.departmentId))
         } else if (deleteModule) {
             const deleteRequest = {
                 isActive: "false"
             }
             setLoaderModule(true) 
             dispatch(updateDepartment(deleteRequest, selectedItem.departmentId))
         } else {     
             setLoaderModule(true)       
             dispatch(createDepartment(requestDate))
         }
     } */

    const {
        departmentName,
        description,
    } = state;

    const modelTitle = editData ? "Edit " : "Create ";
    const modelButtonLbl = editData ? "" : "Save";
    const { sidebarOpen } = useSidebar();

    return (
        <div>
            <Loading status={loaderModule} />
            <div style={sidebarOpen ? { marginLeft: "50px", marginTop: "50px", marginBottom: "-20px" } : { marginLeft: "90px", marginTop: "50px", marginBottom: "-50px" }}>
                <FormLayout defaultState={state} setDefaultState={setState} dynamicForm={employeeAttendanceFilterForm} noOfColumn={4} />
                <Button onClick={onSearch} className='mr-2' style={{ backgroundColor:lightTheme.primaryColor, border: "none", fontSize: "12px", marginTop: "-20px" }}>Search</Button>
                <Button onClick={onClear} style={{ backgroundColor: "white", color: "gray", border: "1px solid gray", fontSize: "12px", marginTop: "-20px" }}>Clear</Button>
            </div>

            <SimpleCard >
                <CustomTable columns={column} tableName="Employee Report" data={itemList} initialPage={1} />
            </SimpleCard>
        </div>
    );
};

export default EmployeeReport;
