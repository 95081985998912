const InputProps = {
  type: {
    text: 'text', 
    number: 'number', 
    textarea: 'textarea', 
    search: 'search', 
    password: 'password',
    url: 'url', // Add this line for URL type
  },
  size: {large: 'large', default: 'default', small: 'small'},
};

export default InputProps;
