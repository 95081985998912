
const quotationForm = [
    {
        title: "Basic Information",
        formBody: [
            {
                formField: [
                    {
                        label: "From Date",
                        inputType: "date",
                        fieldName: "fromDate",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }
                ]
            },
            {
                formField: [
                    {
                        label: "To Date",
                        inputType: "date",
                        fieldName: "toDate",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }
                ]
            },
            {
                formField: [
                    {
                        label: "Customer Name",
                        inputType: "text",
                        fieldName: "customerName",
                        placeholder: "Customer Name",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },
            {
                formField: [
                    {
                        label: "Quotation Number",
                        inputType: "text",
                        fieldName: "billNumber",
                        placeholder: "Quotation Number",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },
            {
                formField: [

                    {
                        label: "Submit",
                        inputType: "button",
                        classStyle: "mt-4",
                        displayKey: "submit",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    }

                ]
            },
        ]
    }
];

const createQuotationForm = [{
    title: "Basic Information",
    formBody: [
        {

            formField: [
                {
                    subField: [
                        {
                            // label: "Customer Name",
                            inputType: "typedropdown",
                            fieldName: "customerName",
                            options: "customerList",
                            uniqueKey: "customerId",
                            displayKey: "customerName",
                            placeholder: "Customer",
                            onChange: "handleCustomerChange",
                            defaultValue: "",
                            classStyle:'22px',
                            config: {
                                required: "required",
                            },
                            icon: "i-Add"
                        },
                        {
                            placeholder: "Select Credit Term",
                            inputType: "select",
                            options: "creditList",
                            uniqueKey: "creditTermId",
                            displayKey: "creditTermName",
                            fieldName: "creditTerm",
                            defaultValue: "",
                            config: {
                                required: "required",
                            },
                        },
                    ]

                },
                {
                    subField: [
                        {
                            // label: "Contact Person",
                            placeholder: "Contact Person",
                            inputType: "text",
                            fieldName: "contactPerson",
                            defaultValue: "",
                            config: {
                                required: "required",
                            },
                        },
                        {
                            // label: "Sales Person",
                            placeholder: "Sales Person",
                            inputType: "text",
                            fieldName: "salesPerson",
                            defaultValue: "",
                            config: {
                                required: "required",
                            },
                        },
                    ]

                },
                {
                    subField: [
                        {
                            // label: "Contact Number",
                            placeholder: "Contact Number",
                            inputType: "text",
                            fieldName: "phoneNo",
                            defaultValue: "",
                            config: {
                                required: "required",
                            },
                        },
                        {
                            // label: "Customer PO Number",
                            placeholder: "Customer PO Number",
                            inputType: "text",
                            fieldName: "custPoNumber",
                            defaultValue: "",
                            config: {
                                required: "required",
                            },
                        },
                    ]

                },
                {
                    subField: [
                        {
                            // label: "Customer Address",
                            placeholder: "Customer Address*",
                            inputType: "textarea",
                            fieldName: "address",
                            defaultValue: "",
                            config: {
                                required: "required",
                            },
                        },
                        {
                            // label: "Memo",
                            placeholder: "Memo*",
                            inputType: "textarea",
                            fieldName: "memo",
                            defaultValue: "",
                            config: {
                                required: "required",
                            },
                        },

                    ]

                },


            ]
        },
        {
            formField: [
                {
                    description: "Same as Customer Address",
                    inputType: "checkbox",
                    onChange: "sameAsCustomer",
                    fieldName: "customerAddress",
                    classStyle: "mt-4",
                    defaultValue: "",
                    config: {
                        required: "",
                    },
                },
                {
                    // label: "Billing Address",
                    inputType: "textarea",
                    fieldName: "billingAddress",
                    placeholder: "Billing Address",
                    defaultValue: "",
                    config: {
                        required: "required",
                    },
                },
                {

                    description: "Same as billing Address",
                    inputType: "checkbox",
                    fieldName: "sameBillingAddress",
                    onChange: "sameAsCustomer",
                    classStyle: "mt-4",
                    defaultValue: "",
                    config: {
                        required: "",
                    },
                },
                {
                    // label: "Delivery Address",
                    inputType: "textarea",
                    placeholder: "Delivery Address",
                    fieldName: "deliveryAddress",
                    defaultValue: "",
                    config: {
                        required: "required",
                    },
                },
               
            ]
        },
        {
            formField: [
                
                
                
                
                {
                    description: "Price Including GST",
                    inputType: "checkbox",
                    fieldName: "priceIncludingGST",
                    classStyle: "mt-4",
                    onChange: "sameAsCustomer",
                    defaultValue: "",
                    config: {
                        required: "",
                    },
                },
                {
                    description: "Reserve Stock",
                    inputType: "checkbox",
                    fieldName: "reserveStock",
                    classStyle: "mt-4",
                    defaultValue: "",
                    config: {
                        required: "",
                    },
                },
                {
                    // label: "Select Country",
                    placeholder: "Select Country",
                    inputType: "select",
                    options: "currencyList",
                    uniqueKey: "currencyId",
                    onChange: "handleCurrencyChange",
                    displayKey: "countryName",
                    fieldName: "currency",
                    defaultValue: "",
                    config: {
                        required: "required",
                    },
                },
                {
                    // label: "Valid Date",
                    inputType: "date",
                    fieldName: "validDate",
                    classStyle: "mt-4",
                    defaultValue: "",
                    config: {
                        required: "required",
                    },
                },
                {
                    // label: "From Date",
                    inputType: "date",
                    fieldName: "fromDate",
                    classStyle: "mt-4",
                    defaultValue: "",
                    config: {
                        required: "required",
                    },
                },
            ]
        }
    ]
}]



const createCustomerForm = [{
    title: "Basic Information",
    formBody: [
        {
            // title: "Customer Information",
            formField: [{
                label: "",
                inputType: "text",
                placeholder: "Customer Name*",
                fieldName: "name",
                defaultValue: "",
                config: {
                    required: "required",
                },
            },
            {
                label: "",
                inputType: 'typedropdown',
                options: "creditList",
                placeholder: 'Credit Term',
                uniqueKey: "creditTermId",
                displayKey: "creditTermName",
                fieldName: "creditTerm",
                fieldId: "creditTermId",
                defaultValue: "",
                config: {
                    required: "required",
                },
            },
            {
                label: "",
                inputType: "typedropdown",
                options: "priceGroupList",
                uniqueKey: "custPriceGroupId",
                displayKey: "custPriceGroupTitle",
                placeholder: 'Price Group',
                fieldName: "pricegroup",
                fieldId: "custPriceGroupId",
                defaultValue: "",
                config: {
                    required: "required",
                },
            },
            {
                label: "",
                inputType: "text",
                placeholder: "Contact Person",
                fieldName: "contact",
                defaultValue: "",
                config: {
                    required: "required",
                },
            },
            {
                label: "",
                inputType: "text",
                placeholder: "Customer UEN",
                fieldName: "customerUen",
                defaultValue: "",
                config: {
                    required: "required",
                },
            },
            {
                label: "",
                inputType: "typedropdown",
                options: "taxList",
                uniqueKey: "taxId",
                displayKey: "taxCode",
                placeholder: "Tax",
                fieldName: "tax",
                fieldId: "taxId",
                defaultValue: "",
                config: {
                    required: "required",
                },
            },
            {
                label: "",
                inputType: "text",
                placeholder: "Credit Limit",
                fieldName: "creditLimit",
                defaultValue: "",
                config: {
                    required: "required",
                },
            },
            {
                label: "",
                inputType: "select",
                options: "currencyList",
                placeholder:"Select Currency",
                uniqueKey: "currencyId",
                displayKey: "countryName",
                fieldName: "currency",
                defaultValue: "",
                config: {
                    required: "required",
                },
            },
            ]
        },
        {
            formField: [
                {
                    label: "",
                    inputType: "text",
                    placeholder: "Email",
                    fieldName: "email",
                    defaultValue: "",
                    config: {
                        required: "required",
                    },
                },
                {
                    label: "",
                    inputType: "text",
                    type: "number",
                    placeholder: "Contact No",
                    fieldName: "contactNo",
                    defaultValue: "",
                    config: {
                        required: "required",
                    }
                },
                {
                    label: "",
                    inputType: "text",
                    type: "number",
                    placeholder: "Postal Code",
                    fieldName: "postalCode",
                    defaultValue: "",
                    config: {
                        required: "required",
                    },
                },
                {
                    label: "",
                    inputType: "textarea",
                    placeholder: "Billing Address",
                    fieldName: "CusbillingAddress",
                    defaultValue: "",
                    config: {
                        required: "required",
                    },
                },
                {
                    description: "Same as billing Address",
                    inputType: "checkbox",
                    fieldName: "CusCheckBillingAddress",
                    onChange: "cusBillingAddress",
                    defaultValue: "",
                    config: {
                        required: "",
                    },
                },
                {
                    label: "",
                    inputType: "textarea",
                    placeholder: "Shipping Address",
                    fieldName: "shippingAddress",
                    defaultValue: "",
                    config: {
                        required: "required",
                    },
                },
            ]
        }
    ]
}]

const fieldList = [
    {
        label: "",
        inputType: "select",
        options: "materialTypeList",
        uniqueKey: "materialTypeId",
        displayKey: "materialName",
        fieldName: "materialTypeId",
        // fieldId: "materialTypeI",
        disabled:true ,
        defaultValue: "",
        config: {
            required: "required",
        },
    },
    /* {
        label: "",
        inputType: "typedropdown",
        options: "materialInfoList",
        uniqueKey: "materialId",
        onChange: "handleMaterialChange",
        displayKey: "materialCode",
        fieldName: "materialCode",
        defaultValue: "",
        config: {
            required: "required",
        },
        icon: "i-Magnifi-Glass1"
    }, */
    {
        label: "",
        inputType: "searchableInput",
        placeholder: "materialCodeId",
        fieldName: "materialCode",
        onChange: "handleMaterialChange",
        displayKey: "materialCode",
        // disabled: true,
        defaultValue: "",
        config: {
            required: "",
        },
    },
    {
        label: "",
        inputType: "text",
        placeholder: "MaterialName",
        fieldName: "materialName",
        defaultValue: "",
        disabled: true,
        config: {
            required: "required",
        },
    },
    {
        label: "",
        inputType: "text",
        placeholder: "Qty*",
        fieldName: "qty",
        onChange: "handleCalculation",
        defaultValue: "",
        config: {
            required: "required",
        },
    },
    {
        label: "",
        inputType: "select",
        fieldName: "unit",
        options: "unitsList",
        disabled: false,
        uniqueKey: "unitId",
        displayKey: "unitName",
        defaultValue: "",
        config: {
            required: "required",
        },
    },
    {
        label: "",
        inputType: "text",
        placeholder: "Price*",
        onChange: "handleCalculation",
        fieldName: "price",
        defaultValue: "",
        config: {
            required: "required",
        },
    },
    {
        label: "",
        inputType: "text",
        placeholder: "Amount*",
        disabled: true,
        fieldName: "total",
        defaultValue: "",
        config: {
            required: "required",
        },
    },
    {
        label: "",
        inputType: "select",
        options: "taxList",
        uniqueKey: "taxId",
        displayKey: "taxCode",
        onChange: "handleTaxCode",
        fieldName: "tax",
        defaultValue: "",
        config: {
            required: "required",
        },
    },
    {
        label: "",
        inputType: "text",
        placeholder: "Total tax",
        fieldName: "totalTax",
        disabled: true,
        defaultValue: "",
        config: {
            required: "required",
        },
    },
    {
        label: "",
        inputType: "icon-table",
        index: 'file',
        fieldName: "description",
        defaultValue: "",
        config: {
            required: "required",
        },
    },
    {
        label: "",
        inputType: "icon-table",
        index: 'outline-clock',
        onClick: 'onAddItem',
        fieldName: "outline",
        defaultValue: "",
        config: {
            required: "required",
        },
    },
    {
        label: "",
        inputType: "icon-table",
        index: 'delete',
        fieldName: "delete",
        onClick: 'onDeletePress',
        defaultValue: "",
        config: {
            required: "required",
        },
    },
]

const sizeList = [
    {
        label: "",
        inputType: "text",
        placeholder: "0",
        fieldName: "length",
        defaultValue: "",
        config: {
            required: "required",
        },
    },
    {
        label: "",
        inputType: "text",
        placeholder: "0",
        fieldName: "width",
        defaultValue: "",
        config: {
            required: "required",
        },
    },
    {
        label: "",
        inputType: "text",
        placeholder: "0",
        fieldName: "thickness",
        defaultValue: "",
        config: {
            required: "required",
        },
    },
]
export {
    quotationForm, createQuotationForm, createCustomerForm, sizeList, fieldList
};