import * as yup from "yup";
import { Formik } from "formik";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import DateTime from "react-datetime";
import { Form, Button } from "react-bootstrap";
import { DateRange } from "react-date-range";
import SimpleCard from 'app/components/atoms/simple-card/SimpleCard'
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { MdEdit, MdDelete } from "react-icons/md";
import swal from "sweetalert2";
import _ from "lodash";
import { pagePermission, showMessage } from "app/utils/app-functions";
import FormLayout from "app/components/render/formlayout";
import View from "app/components/atoms/modalView/Modalview";
import CustomTable from "app/components/atoms/custom-table/CustomTable";
import formRender from "app/components/render/formcomponent";
import { taxForm } from "./formData";
import { FiEdit, FiSend, FiTrash2 } from 'react-icons/fi';
import { getCountry } from "app/api/CountryApi";
import { getTaxCode, createTaxCode, updateTaxCode } from "app/api/TaxCodeApi";
import { getTaxConfig, createTaxConfig, updateTaxConfig } from "app/api/TaxConfigApi";
import Alert from "app/components/atoms/alert";
import { asyncGetCountryInfo } from "app/api/CountryApi";

let editData = false;
let deleteModule = false;

const TaxCode = ({ navigation }) => {

    const dispatch = useDispatch();
    const customerFormRef = useRef();



    const getTaxCodeSuccess = useSelector((state) => state.taxCodeReducer.getTaxCodeSuccess);
    const getTaxCodeList = useSelector((state) => state.taxCodeReducer.getTaxCodeList);
    const getTaxCodeFailure = useSelector((state) => state.taxCodeReducer.getTaxCodeFailure);
    const taxCodeErrorMessage = useSelector((state) => state.taxCodeReducer.errorMessage);



    const getLeadSourceTypeSuccess = useSelector((state) => state.utilityReducer.getLeadSourceTypeSuccess);
    const getLeadSourceTypeList = useSelector((state) => state.utilityReducer.getLeadSourceTypeList);
    const getLeadSourceTypeFailure = useSelector((state) => state.utilityReducer.getLeadSourceTypeFailure);
    const leadSourceErrorMessage = useSelector((state) => state.utilityReducer.errorMessage);



    const getCountrySuccess = useSelector((state) => state.countryReducer.getCountrySuccess);
    const getCountryList = useSelector((state) => state.countryReducer.getCountryList);
    const getCountryFailure = useSelector((state) => state.countryReducer.getCountryFailure);
    const countryErrorMessage = useSelector((state) => state.countryReducer.errorMessage);


    const getTaxConfigSuccess = useSelector((state) => state.taxConfigReducer.getTaxConfigSuccess);
    const getTaxConfigList = useSelector((state) => state.taxConfigReducer.getTaxConfigList);
    const getTaxConfigFailure = useSelector((state) => state.taxConfigReducer.getTaxConfigFailure);
    const taxConfigErrorMessage = useSelector((state) => state.taxConfigReducer.errorMessage);

    const createTaxCodeSuccess = useSelector((state) => state.taxCodeReducer.createTaxCodeSuccess);
    const createTaxCodeData = useSelector((state) => state.taxCodeReducer.createTaxCodeData);
    const createTaxCodeFailure = useSelector((state) => state.taxCodeReducer.createTaxCodeFailure);

    const updateTaxCodeSuccess = useSelector((state) => state.taxCodeReducer.updateTaxCodeSuccess);
    const updateTaxCodeData = useSelector((state) => state.taxCodeReducer.updateProjectData);
    const updateTaxCodeFailure = useSelector((state) => state.taxCodeReducer.updateProjectFailure);


    const [pageItems, setpageItems] = useState({});

    useEffect(() => {
        let data = pagePermission(pageItems, "Inventory", "Tax-Code")
        setpageItems(data)
    }, [navigation]);

    const column = [
        {
            "dataField": "taxCode",
            "text": "Tax Code",
            "sort": true
        },
        {
            "dataField": "taxVal",
            "text": "Tax Percentage",
            "sort": true
        },
    ]
    if (pageItems.update || pageItems.delete) {
        column.push({
            text: "Action",
            sort: false,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => (
                <div className="icon-container">
                    {pageItems.update && <FiEdit
                        className="text-primary cursor-pointer"
                        size={18}
                        onClick={() => onEditForm(row, index)}
                    ></FiEdit>}
                   


                </div>
            )
        },)
    }

    const TaxColumn = [
        {
            "dataField": "status",
            "text": "",
            formatter: (cellContent, row, index, extraData) => {
                // Check if editData is false before rendering the checkbox
                if (!editData) {
                    const conInfo = {
                        inputType: "checkbox",
                        status: cellContent !== undefined ? cellContent : true, // Default to true if cellContent is undefined
                    };
                    return formRender(extraData, null, conInfo, null, null, statusChange, index);
                }
                // Return an empty element if editData is true
                return null;
            }
        },
        {
            "dataField": "code",
            "text": "Code",
            "sort": true
        },
        {
            "dataField": "name",
            "text": "Name",
            "sort": true
        },
        {
            "dataField": "taxValue",
            text: "Tax Percentage",
            sort: false,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => {
                let data = state?.taxType || [];
                return (
                    <input
                        name="taxValue"
                        className="form-control"
                        placeholder={"Enter Value"}
                        type={"number"}
                        onChange={(event) => handleTaxValue(event, index, row)}
                        value={data[index]?.taxValue || ""}
                    />
                );
            },
        },
    ];

    const [state, setState] = useState({
        departmentName: "",
        description: "",
        "customerList": [],
        "venderList": [],
        "leadSourceTypeList": [],
        deletedItems: [],

    });

    const [createModule, setCreateModule] = useState(false);
    const [loaderModule, setLoaderModule] = useState(false);
    const [editModule, setEditModule] = useState(false);
    const [viewModule, setViewModule] = useState(false);
    const [deleteModule, setDeleteModule] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [selectedItem, setSelectedItem] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);
    const [siteDetailsList, setsiteDetailsList] = useState([]);
    const [removeModule, setRemoveModule] = useState(false);



    useEffect(() => {
        // setLoaderModule(true)
        const requestData = {
            isActive: "true"
        }
        dispatch(getTaxCode(requestData))
        dispatch(getTaxConfig(requestData))
        listFetching()
    }, [navigation]);


    const listFetching = async () => {
        const requestData = {
            isActive: "true"
        }
        let countryList = await asyncGetCountryInfo(requestData)
        setState({
            ...state,
            countryList: countryList.data
        })
    }


    const confromDialog = () => {
        return (
            swal
                .fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    type: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!",
                    cancelButtonText: "No",
                })
                .then((result) => {
                    if (result.value) {
                        onSubmitForm()
                    } else {
                        setDeleteModule(false)
                        swal.fire({
                            title: "Cancelled!",
                            text: "Permission denied.",
                            type: "error",
                            icon: "error",
                            timer: 1500,
                        });
                    }
                })
        )
    }

    useEffect(() => {
        if (deleteModule) {
            confromDialog()
        }
    }, [deleteModule])


    useEffect(() => {
        if (getTaxConfigSuccess) {
            setState({
                ...state,
                "taxConfigList": getTaxConfigList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_TAX_CONFIG" })
        } else if (getTaxConfigFailure) {
            setState({
                ...state,
                "taxConfigList": []
            })
            showMessage("warning", taxConfigErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_TAX_CONFIG" })
        }
    }, [getTaxConfigSuccess, getTaxConfigFailure]);


    useEffect(() => {
        if (getTaxCodeSuccess) {
            setState({
                ...state,
                taxCodeList: getTaxCodeList
            })
            setItemList(getTaxCodeList)

            setLoaderModule(false)
            dispatch({ type: "RESET_GET_TAXCODE" })
        } else if (getTaxCodeFailure) {
            setState({
                ...state,
                taxCodeList: []
            })
            showMessage("warning", taxCodeErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_TAXCODE" })
        }
    }, [getTaxCodeSuccess, getTaxCodeFailure]);


    useEffect(() => {
        if (getCountrySuccess) {
            setState({
                ...state,
                countryList: getCountryList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_COUNTRY" })
        } else if (getCountryFailure) {
            setState({
                ...state,
                countryList: []
            })
            showMessage("warning", countryErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_COUNTRY" })
        }
    }, [getCountrySuccess, getCountryFailure]);



    useEffect(() => {
        if (getLeadSourceTypeSuccess) {
            setState({
                ...state,
                "leadSourceTypeList": getLeadSourceTypeList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_LEAD_SOURCE_TYPE" })
        } else if (getLeadSourceTypeFailure) {
            setState({
                ...state,
                "leadSourceTypeList": []
            })
            showMessage("warning", leadSourceErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_LEAD_SOURCE_TYPE" })
        }
    }, [getLeadSourceTypeSuccess, getLeadSourceTypeFailure]);





    useEffect(() => {
        if (createTaxCodeSuccess) {
            const temp_state = [...itemList, createTaxCodeData[0]];
            setItemList(temp_state)
            setLoaderModule(false)
            closeModule()
            Alert({ description: 'Created Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            dispatch({ type: "RESET_CREATE_TAXCODE" })
        } else if (createTaxCodeFailure) {
            setLoaderModule(false)
            // showMessage("warning", errorMessage?.message)
            dispatch({ type: "RESET_CREATE_TAXCODE" })
        }
    }, [createTaxCodeSuccess, createTaxCodeFailure]);


    useEffect(() => {
        if (updateTaxCodeSuccess) {
            console.log(updateTaxCodeData)
            const requestData = {
                isActive: "true"
            }
            dispatch(getTaxCode(requestData))
            Alert({ description: 'Updated Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            closeModule()
            setLoaderModule(false)


            // updateTable(updateTaxCodeData[0] || [])
            dispatch({ type: "RESET_UPDATE_TAXCODE" })
        } else if (updateTaxCodeFailure) {
            setLoaderModule(false)
            // showMessage("warning", errorMessage?.message)
            dispatch({ type: "RESET_UPDATE_TAXCODE" })
        }
    }, [updateTaxCodeSuccess, updateTaxCodeFailure]);

    const updateTable = (updatedItem) => {
        const temp_state = [...itemList];
        if (deleteModule) {
            let remainingList = _.remove(temp_state, function (n) {
                return n.taxId != updatedItem.taxId;
            });
            Alert({ description: 'Deleted Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(remainingList)
        } else {
            temp_state[selectedIndex] = updatedItem;
            Alert({ description: 'Updated Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(temp_state)
        }
        setLoaderModule(false)
        closeModule()
    }

    const closeModule = () => {
        editData = false;
        setCreateModule(false)
        setDeleteModule(false)
    }

    const resetState = () => {
        setState({
            ...state,
            projectTitle: "",
            description: "",
            startDate: "",
            endDate: "",
            customerId: "",
            contactName: "",
            contactNumber: "",
            projectValue: "",
            advanceAmount: "",
            venderId: "",
            leadSourceTypeId: "",
            commissionAmount: "",
            countryName: "",
            name: "",
            description: "",
            taxType: []


        })
        setsiteDetailsList([])

    }

    const onCreateForm = () => {
        resetState()
        editData = false;
        setCreateModule(true)
        const requestData = {
            isActive: "true"
        }
        if ((taxForm[0]?.formBody?.[0]?.formField?.[0]?.fieldName || '') != "countryName") {
            taxForm[0]?.formBody.unshift({
                // title: "Select Country",
                formField: [
                    {
                        label: "Select Country",
                        options: "taxConfigList",
                        displayKey: "country",
                        uniqueKey: "taxConfigurationId",
                        inputType: "select",
                        placeholder: "Enter Project Name",
                        fieldName: "countryName",
                        onChange: "handleCountry",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    },

                ]
            })
        }



        dispatch(getTaxConfig(requestData))

    }

    const handleTaxValue = (event, index, row) => {
        let value = event.target.value;  // Keep the raw string value

        // Don't convert the value right away; allow the user to type '0'
        let taxData = state?.taxType || [];
        let taxDetails = [...taxData];

        // Update the specific taxValue in taxDetails; store as a string to handle user input
        taxDetails[index].taxValue = value;

        // Convert values for calculation, ensuring empty strings become 0 for summing
        const totalTaxValue = taxDetails.reduce((total, item) => total + (parseFloat(item.taxValue) || 0), 0);
        console.log(totalTaxValue)

        setState(prevState => ({
            ...prevState,
            taxType: taxDetails, // Update taxDetails with the raw input
            totalTaxValue: totalTaxValue >= 1 ? totalTaxValue : "0" // Set totalTaxValue based on the condition
        }));
    };




    const statusChange = (event, index) => {

        const isChecked = event.target.checked;
        let taxData = state?.taxType || [];
        let taxDetails = [...taxData];

        // Update the status based on the checkbox value
        taxDetails[index].status = isChecked;


        // Filter out items with status `false`
        const updatedData = taxDetails.filter(item => item.status !== false);

        // Update the state with the filtered array
        setState(prevState => ({
            ...prevState,
            taxType: taxDetails,
            updatedTax: updatedData
        }));

        // `state?.taxType` might not reflect the update immediately due to async nature of `setState`
        console.log("Updated Data: ", updatedData); // Log the updated data
    };


    const onEditForm = (data, index) => {
        console.log(data)


        if ((taxForm[0]?.formBody?.[0]?.formField?.[0]?.fieldName || '') == "countryName") {
            taxForm[0].formBody.splice(0, 1);
        }


        setState({
            ...state,
            name: data?.taxCode || "",
            taxType: data?.taxMeta,
            description: data?.description || "",
            // taxConfigList: [],


        })

        editData = true;
        setSelectedIndex(index)
        setSelectedItem(data)
        setCreateModule(true)
    }

    const onDeleteItem = (data, index) => {
        editData = false;
        setDeleteModule(true)
        setSelectedIndex(index)
        setSelectedItem(data)     
        const deleteRequest = {
            isActive: "false"
        }
        setLoaderModule(true)
        // dispatch(updateProject(deleteRequest, data.projectId))
    }

    const validateForm = async () => {
        const validatefom = customerFormRef.current.validateForm();
    }

    const handleCountry = (event) => {
        let value = event.target.value
        let taxConfigList = state?.taxConfigList || []
        let countryObject = taxConfigList.filter((country) => country.taxConfigurationId === Number(value));
        let taxType = countryObject.length > 0 ? countryObject[0]?.taxType : []
        console.log(taxType)

        setState({
            ...state,
            taxType: taxType,
            countryName: value,
            countryObject: countryObject


        })
    }

    const onSubmitForm = () => {
        let data = state?.countryObject || ""

        const requestDate = {
            "taxVal": state?.totalTaxValue || selectedItem?.taxVal || 0,
            "taxCode": state?.name || "",
            "description": state?.description || "",
            "taxMeta": (Array.isArray(state?.updatedTax) && state.updatedTax.length > 0)
                ? state.updatedTax
                : state?.taxType
        };
        const hasInvalidTaxMeta = requestDate.taxMeta.some(tax => !tax.code || !tax.name || !tax.taxValue);


        if (!requestDate.taxCode || hasInvalidTaxMeta || !requestDate.description) {
            showMessage("warning", "Please fill  all required fields."); // or show a custom message
            return; // Stop form submission
        }
        // return
        if (editData) {
            requestDate.taxMeta = selectedItem?.taxMeta || []

            setLoaderModule(true)

            dispatch(updateTaxCode(requestDate, selectedItem.taxId))


        } else if (deleteModule) {
            const deleteRequest = {
                isActive: "false"
            }
            setLoaderModule(true)
            // dispatch(updateProject(deleteRequest, selectedItem.projectId))
        } else {

            setLoaderModule(true)
            dispatch(createTaxCode(requestDate))
        }
    }

    const {
        departmentName,
        description,
        customerList
    } = state;

    const modelTitle = editData ? "Edit " : "Create ";
    const modelButtonLbl = editData ? "Update" : "Save";

    const addItem = () => {
        const defaultItem = {
            "siteName": "",
            "description": "",
            "postalCode": "",
            "address": "",

        }
        setsiteDetailsList([...siteDetailsList, defaultItem])
    }



    const handleClick = (buttonName) => {
        if (buttonName == 'Add') {
            onCreateForm();
        }
    };

    return (
        <div>
            <div style={{ marginTop: "-10px" }}>
                <View isOpen={createModule} size="lg" savetitle={modelButtonLbl} onClose={setCreateModule} onSubmit={validateForm} title={`${modelTitle} TaxCode`}>
                    <FormLayout ref={customerFormRef} defaultState={state} setDefaultState={setState} dynamicForm={taxForm} noOfColumn={3} onSubmit={onSubmitForm} />
                    <CustomTable columns={TaxColumn} data={state?.taxType || []}  state={state} initialPage={1} />
                </View>


                <SimpleCard >
                    <CustomTable buttonData={['add']} handleClick={handleClick} tableName={"TaxCode"} columns={column} data={itemList} initialPage={1} />
                </SimpleCard>

            </div>
        </div>
    )
};

export default TaxCode;
