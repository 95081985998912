import * as yup from "yup";
import { Formik } from "formik";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import DateTime from "react-datetime";
import { Form, Button } from "react-bootstrap";
import { DateRange } from "react-date-range";
import SimpleCard from 'app/components/atoms/simple-card/SimpleCard'
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { MdEdit, MdDelete } from "react-icons/md";
import swal from "sweetalert2";
import _ from "lodash";
import { pagePermission, showMessage } from "app/utils/app-functions";
import FormLayout from "app/components/render/formlayout";
import View from "app/components/atoms/modalView/Modalview";
import CustomTable from "app/components/atoms/custom-table/CustomTable";
import formRender from "app/components/render/formcomponent";
import { locationForm } from "./formData";
import { FiEdit, FiSend, FiTrash2 } from 'react-icons/fi';
import { createLocation, getLocation, updateLocation, getLocationData } from "app/api/LocationApi";
import Alert from "app/components/atoms/alert";

let editData = false;

const Location = ({ navigation }) => {

  const dispatch = useDispatch();
  const customerFormRef = useRef();

  const getLocationSuccess = useSelector((state) => state.locationReducer.getLocationSuccess);
  const getLocationList = useSelector((state) => state.locationReducer.getLocationList);
  const getLocationFailure = useSelector((state) => state.locationReducer.getLocationFailure);
  const supplierErrorMessage = useSelector((state) => state.locationReducer.errorMessage);


  const createLocationSuccess = useSelector((state) => state.locationReducer.createLocationSuccess);
  const createLocationData = useSelector((state) => state.locationReducer.createLocationData);
  const createLocationFailure = useSelector((state) => state.locationReducer.createLocationFailure);


  const updateLocationSuccess = useSelector((state) => state.locationReducer.updateLocationSuccess);
  const updateLocationData = useSelector((state) => state.locationReducer.updateLocationData);
  const updateLocationFailure = useSelector((state) => state.locationReducer.updateLocationFailure);

  const errorMessage = useSelector((state) => state.productReducer.errorMessage);

  const [pageItems, setpageItems] = useState({});

  useEffect(() => {
    let data = pagePermission(pageItems, "Inventory", "Location")
    setpageItems(data)
  }, [navigation]);

  const column = [
    {
      "dataField": "",
      "text": "SNO",
      "sort": true ,
      formatter: (cellContent, row, index) => {
        return index + 1
      }
    },
    {
      "dataField": "name",
      "text": "LocationId",
      "sort": true,

    },
    {
      "dataField": "description",
      "text": "Description",
      "sort": true
    },

    /* {
      dataField: "orderStatusId",
      text: "Status",
      sort: true,
      align: "center",
      headerAlign: "center",
      formatter: (cellContent, row, index) => {
        const conInfo = { inputType: "status", status: cellContent }
        return formRender(null, null, conInfo, null);
      }
    }, */
  ]
  if (pageItems.update) {
    column.push({
      text: "Action",
      sort: false,
      align: "center",
      headerAlign: "center",
      formatter: (cellContent, row, index) => (
        <div className="icon-container">
          {pageItems.update && <FiEdit
            className="text-primary cursor-pointer"
            size={18}
            onClick={() => onEditForm(row, index)}
          ></FiEdit>}
        </div>
      )
    },)
  }


  const [state, setState] = useState({
    voiceName: "",
    purchaseOrderDate: new Date(),
    statusId: 1,
    description: "",
    poChoosed: '',
    "customerList": [],
    "venderList": [],
    "leadSourceTypeList": [],
    footerData: [
      { label: 'SubTotal :', value: '0' },
      { label: 'Tax:', value: '0' },
      { label: 'Total:', value: '0' }
    ],
    statusList: [
      { id: 1, name: "Open" },
      { id: 2, name: "close" }
    ]
  });

  const [createModule, setCreateModule] = useState(false);
  const [loaderModule, setLoaderModule] = useState(false);
  const [editModule, setEditModule] = useState(false);
  const [viewModule, setViewModule] = useState(false);
  const [deleteModule, setDeleteModule] = useState(false);
  const [itemList, setItemList] = useState([]);
  const [selectedItem, setSelectedItem] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(false);
  const [tableRows, setTableRows] = useState([]);




  useEffect(() => {
    setLoaderModule(true)
    dispatch(getLocationData())

  }, [navigation]);



  useEffect(() => {
    if (getLocationSuccess) {
      setItemList(getLocationList)
      setState({
        ...state,
        locationList: getLocationList
      })
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_SUPPLIER" })
    } else if (getLocationFailure) {
      setItemList([])
      showMessage("warning", supplierErrorMessage?.message)
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_SUPPLIER" })
    }
  }, [getLocationSuccess, getLocationFailure]);


  useEffect(() => {
    if (createLocationSuccess) {
      const temp_state = [...itemList, createLocationData[0]];
      setItemList(temp_state)
      setLoaderModule(false)
      closeModule()
      Alert({ description: 'Created Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
      dispatch({ type: "RESET_CREATE_LOCATION" })
    } else if (createLocationFailure) {
      setLoaderModule(false)
      showMessage("warning", supplierErrorMessage?.message)
      dispatch({ type: "RESET_CREATE_LOCATION" })
    }
  }, [createLocationSuccess, createLocationFailure]);

  useEffect(() => {
    if (updateLocationSuccess) {
      updateTable(updateLocationData[0])
      dispatch({ type: "RESET_UPDATE_LOCATION" })
    } else if (updateLocationFailure) {
      setLoaderModule(false)
      showMessage("warning", errorMessage?.message)
      dispatch({ type: "RESET_UPDATE_LOCATION" })
    }
  }, [updateLocationSuccess, updateLocationFailure]);

  const updateTable = (updatedItem) => {
    const temp_state = [...itemList];
    if (deleteModule) {
      let remainingList = _.remove(temp_state, function (n) {
        return n.id != selectedItem.id;
      });
      Alert({ description: 'Deleted Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
      setItemList(remainingList)
    } else {
      temp_state[selectedIndex] = updatedItem;
      Alert({ description: 'Updated Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
      setItemList(temp_state)
    }
    setLoaderModule(false)
    closeModule()
  }


  const closeModule = () => {
    editData = false;
    setCreateModule(false)
    setDeleteModule(false)
  }

  const resetState = () => {
    setState({
      ...state,
      supplierCode: '',
      supplierName: '',
      secondaryNumber: '',
      email: '',
      contactName: '',
      address: '',
      postCode: '',
      comment: '',
      locationId: '',
      description: ""

    })
    setTableRows([])


  }

  const onCreateForm = () => {
    resetState()
    editData = false;
    setCreateModule(true)
  }
  // const updateData = state.poChoosed.length? state.poChoosed[0] : {};

  const onEditForm = (data, index) => {
    console.log(JSON.stringify(data))
    setState({
      ...state,
      locationId: data?.name,
      description: data?.description






    })


    editData = true;
    setSelectedIndex(index);
    setSelectedItem(data);
    setCreateModule(true);
  };
  const handleLocationChange = (event, index, name) => {
    let temp_state = "";
    temp_state = {
      ...state,
      [name]: event,
      contactNumber: event[0]?.primaryNo || "",
      contactPerson: event[0]?.contactName || "",
      supplierAddress: event[0]?.address || "",

    }
    setState(temp_state)

  }
  const handleStatus = (event, index, name) => {
    const selectedValue = event.target.value;
    const statusObject = state?.statusList.find(stat => stat.id == selectedValue);
    const status = statusObject?.name || '';
    setState({
      ...state,
      [name]: selectedValue,
      statusOpen: statusObject?.name || null
    });
  }
  // const updateData = state.poChoosed.length ? state.poChoosed[0] : {};


  const onDeleteItem = (data, index) => {
    editData = false;
    setSelectedItem(data)
    setSelectedIndex(index)
    setDeleteModule(true)
  }








  var itemCount = 0;
  const addRow = () => {

    const defaultNewOrderObject = {
      itemCount: 0,
      materialId: '',
      materialCodeId: '',
      materialName: '',
      qty: "",
      unit: '',
      price: "",
      total: "",
      taxCode: '',
      totalTax: '',

      itemDetails: [
        /*  {
           warehouseId: "",
           locationId: "",
           // materialCode:"",
           length: "",
           width: "",
           thickness: "",
         } */
      ]
    };
    itemCount++;
    setTableRows([...tableRows, defaultNewOrderObject]);
  };


  const handleTypeChange = (val, index, stateName, parentIndex) => {
    let temp_state = [...tableRows]
    // alert(val.target.value+","+index+","+stateName+","+parentIndex)
    temp_state[parentIndex]["itemDetails"][index][stateName] = val.target.value;
    //  temp_state[parentIndex]["itemDetails"][index][stateName] = val;
    console.log(temp_state)
    setTableRows(temp_state)
  }


  const getMaterialId = (event, index, name) => {

    let temp_state = [...tableRows];
    const material = state?.materialTypeList || []
    // const materialItem = material.filter((item, index) => item.materialId == event.target.value);
    temp_state[index][event.target.name] = event.target.value
    setTableRows(temp_state)

  }

  const handleGoodsChange = (event, index, name) => {
    const data = event;
    // const updateData = state.poChoosed.length ? state.poChoosed[0] : {};
    const updatedId = selectedItem ? selectedItem : {};

    if (!event || event.length === 0) {
      setTableRows([]);
      setState((prevState) => ({
        ...prevState,
        [name]: event,
        supplierId: [],
        inVoiceData: "",
        contactNumber: "",
        contactPerson: "",
        supplierAddress: "",
        permitId: "",
        shipVia: "",
        fob: "",
        debitTerm: "",
        memo: "",
        deliveryName: "",
        deliveryPhoneNumber: "",
        deliveryEmailId: "",
        deliveryContactPersonName: "",
        deliveryAddress: "",
        supplierInvoice: "",
        supplierEmail: "",
      }));
      return;
    }

    /*     const purchaseOrderItem = Array.isArray(event[0]?.purchaseOrderItem) && event[0]?.purchaseOrderItem.length > 0 ? event[0].purchaseOrderItem : [];
     */
    const purchaseOrderItem = event[0]?.purchaseOrderItem || ''

    let dd = _.filter(state?.supplierList || [], { supplierId: event[0].supplierId });



    let temp_state = {
      ...state,
      [name]: event,
      purchaseOrderId: parseInt(updatedId?.purchaseOrderId) || 0,
      supplierId: dd,
      contactNumber: dd[0]?.primaryNo ? dd[0].primaryNo : "",
      contactPerson: dd[0]?.contactName ? dd[0].contactName : "",
      supplierEmail: dd[0]?.email ? dd[0].email : "",
      supplierAddress: dd[0]?.address ? dd[0].address : "",
      permitId: event[0]?.permitNumber ? event[0].permitNumber : "",
      shipVia: event[0]?.shippingType ? event[0].shippingType : "",
      fob: event[0]?.fob ? event[0].fob : "",
      debitTerm: event[0]?.debitTerm ? event[0].debitTerm : "",
      memo: event[0]?.memo ? event[0].memo : "",
      deliveryName: event[0]?.deliveryInfo?.deliveryName ? event[0].deliveryInfo.deliveryName : "",
      deliveryPhoneNumber: event[0]?.deliveryInfo?.deliveryPhone ? event[0].deliveryInfo.deliveryPhone : "",
      deliveryEmailId: event[0]?.deliveryInfo?.deliveryEmail ? event[0].deliveryInfo.deliveryEmail : "",
      deliveryContactPersonName: event[0]?.deliveryInfo?.deliveryContactPerson ? event[0].deliveryInfo.deliveryContactPerson : "",
      deliveryAddress: event[0]?.deliveryInfo?.deliveryAddress ? event[0].deliveryInfo.deliveryAddress : "",
      supplierInvoice: event[0]?.supplierInvoice ? event[0].supplierInvoice : ""


    };
    setState(temp_state);

    const updatedTableRows = [];
    console.log("event", JSON.stringify(event));
    purchaseOrderItem.map((item) => {
      const req = {
        purchaseOrderId: parseInt(updatedId?.purchaseOrderId) || 0,
        materialId: item?.materialTypeId,
        materialCodeId: item.materialTypeId,
        materialName: item.materialName,
        qty: item.qty,
        unit: item.unit,
        itemDetails: item.itemDetails.map((itm) => {
          return {
            purchaseOrderId: parseInt(updatedId?.purchaseOrderId) || 0,
            warehouseId: itm?.receiptWarehouse || '',
            locationId: itm?.receiptLocation || '',
            serialNumber: itm?.serialNumber || '',
            thickness: itm?.thickness || '',
          };
        })
      };

      updatedTableRows.push(req);
    });

    setTableRows(updatedTableRows);
  };



  const onDeleteInner = (detailIndex, parentIndex) => {
    const updatedTableRows = [...tableRows];
    updatedTableRows[parentIndex].itemDetails.splice(detailIndex, 1);
    setTableRows(updatedTableRows);
  }

  const getMaterialName = (event, index, name) => {

    let temp_state = [...tableRows];
    let selectedmaterial = event.target.value
    const material = state?.materialCodeList || []
    const materialItem = material.filter((item, index) => item.materialId == selectedmaterial);
    temp_state[index].materialName = materialItem[0].materialName
    temp_state[index].materialCode = materialItem[0].materialCode
    temp_state[index].materialId = event.target.value;
    temp_state[index][event.target.name] = event.target.value;
    setTableRows(temp_state)





  };
  const validateForm = async () => {
    const validatefom = customerFormRef.current.validateForm();
  }




  const onSubmitForm = () => {

    const req = {

      "name": state.locationId || '',
      "description": state.description || '',
    }
    console.log(JSON.stringify(req))


    setCreateModule(false)
    // return

    if (editData) {
      setLoaderModule(true)
      dispatch(updateLocation(req, selectedItem.id))
    }
    else if (deleteModule) {
      const deleteRequest = {
        isActive: "false"
      }
      dispatch(updateLocation(deleteRequest, selectedItem.id))
      setLoaderModule(true)
    } else {
      setLoaderModule(true)
      dispatch(createLocation(req))

    }

  }

  const confromDialog = () => {
    return (
      swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          type: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
          cancelButtonText: "No",
        })
        .then((result) => {
          if (result.value) {
            onSubmitForm()
          } else {
            setDeleteModule(false)
            swal.fire({
              title: "Cancelled!",
              text: "Permission denied.",
              type: "error",
              icon: "error",
              timer: 1500,
            });
          }
        })
    )
  }

  useEffect(() => {
    if (deleteModule) {
      confromDialog()
    }
  }, [deleteModule])


  const handleClick = (buttonName) => {
    if (buttonName == 'Add') {
      onCreateForm();
    }
  };

  const modelTitle = editData ? "Edit " : "Create ";
  const modelButtonLbl = editData ? "" : "Save";

  return (
    <div>
      <div style={{ marginTop: "-10px" }}>
        <View isOpen={createModule} size="sm" savetitle={modelButtonLbl} onClose={setCreateModule} onSubmit={validateForm} title={`${modelTitle} Location`}>
          <FormLayout ref={customerFormRef} defaultState={state} setDefaultState={setState} dynamicForm={locationForm} noOfColumn={1} onSubmit={onSubmitForm} />
        </View>


        <SimpleCard>
          <CustomTable buttonData={['add']} handleClick={handleClick} tableName={"Location"} columns={column} data={itemList} initialPage={1} />
        </SimpleCard>

      </div>
    </div>
  )
};

export default Location;
