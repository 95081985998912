const employeeForm = [
    {
        title: "Basic Information",
        formBody: [
            {
                formField: [
                    {
                        label: "First Name",
                        inputType: "text",
                        placeholder: "Enter First Name",
                        fieldName: "firstName",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },

                    {
                        label: "Email",
                        inputType: "text",
                        placeholder: "Enter Email",
                        fieldName: "email",
                        fieldType: "email",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Phone No",
                        inputType: "phonenumber",
                        errorMsg: "* Invalid Phone Number",
                        placeholder: "Enter Phone No",
                        fieldName: "phoneNo",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Date Of Join",
                        inputType: "date",
                        placeholder: "Enter Date Of Join",
                        fieldName: "dateOfJoin",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Date Of Relieving",
                        inputType: "date",
                        placeholder: "Enter Date Of Relieving",
                        fieldName: "dateOfRelieving",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },


                    /* {
                        label: "User Id",
                        inputType: "text",
                        placeholder: "Enter userId",
                        fieldName:"userId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Unit Id",
                        inputType: "text",
                        placeholder: "Enter unitId",
                        fieldName:"unitId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },                 
                    {
                        label: "Employee Code",
                        inputType: "text",
                        placeholder: "Enter empCode",
                        fieldName:"empCode",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },  */
                ]
            },
            {
                title: "JOB HISTORY",
                formField: [
                    {
                        label: "Company Name",
                        inputType: "text",
                        placeholder: "Enter Company Name",
                        fieldName: "companyName",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },

                    {
                        label: "Start Date",
                        inputType: "date",
                        placeholder: "Enter Start Date",
                        fieldName: "startDate",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "End Date",
                        inputType: "date",
                        placeholder: "Enter End Date",
                        fieldName: "endDate",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Job Title",
                        inputType: "text",
                        placeholder: "Enter jobTitle",
                        fieldName: "jobTitle",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },
            {
                title: "Address Info",
                formField: [
                    {
                        label: "Address 1",
                        inputType: "text",
                        placeholder: "Enter Address",
                        fieldName: "address1",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Address 2",
                        inputType: "text",
                        placeholder: "Enter Address 2",
                        fieldName: "address2",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Postal Code",
                        inputType: "number",
                        placeholder: "Enter postal Code",
                        fieldName: "postalCode",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "City",
                        inputType: "select",
                        options: "cityList",
                        uniqueKey: "cityId",
                        displayKey: "cityName",
                        multiple: false,
                        fieldName: "cityId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },
            {
                title: "Employee salary",
                formField: [
                    {
                        label: "Salary Type",
                        inputType: "select",
                        options: "employeeSalaryTypeList",
                        uniqueKey: "empSalaryTypeId",
                        displayKey: "salaryTypeName",
                        fieldName: "empSalaryTypeId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Salary Amount",
                        inputType: "text",
                        placeholder: "Enter Salary Amount",
                        fieldName: "salaryAmt",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Commission Pct",
                        inputType: "text",
                        placeholder: "Enter Commission Pct",
                        fieldName: "commissionPct",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Gross-Pay",
                        inputType: "text",
                        placeholder: "Enter Gross-Pay",
                        fieldName: "grossPay",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Net-Pay",
                        inputType: "text",
                        placeholder: "Enter Net-Pay",
                        fieldName: "netPay",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Deduction",
                        inputType: "text",
                        placeholder: "Enter Deduction",
                        fieldName: "deduction",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },
            {
                title: "Employee Position",
                formField: [
                    {
                        label: "Designation",
                        inputType: "select",
                        options: "designationList",
                        uniqueKey: "designationId",
                        displayKey: "designationName",
                        fieldName: "designationId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Department",
                        inputType: "select",
                        options: "departmentList",
                        uniqueKey: "departmentId",
                        displayKey: "departmentName",
                        fieldName: "departmentId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    /* {
                        label: "Manager",
                        inputType: "select",
                        options: "managerList",
                        uniqueKey:"managerId",
                        displayKey:"managerName",
                        multiple:false,
                        fieldName:"managerId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },  */




                ]
            },


        ]
    }
];

const employeeDetails = [
    {
        title: "Basic Information",
        formBody: [

            {
                formField: [
                    {
                        label: "First Name",
                        inputType: "text",
                        placeholder: "Enter First Name",
                        fieldName: "firstName",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Last Name",
                        inputType: "text",
                        placeholder: "Enter Last Name",
                        fieldName: "lastName",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Email",
                        inputType: "text",
                        fieldType: "email",
                        placeholder: "Enter Email",
                        fieldName: "email",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Phone No",
                        inputType: "phonenumber",
                        // type: "number",
                        placeholder: "Enter Phone No",
                        fieldName: "phoneNo",
                        errorMsg: "* Invalid Phone Number",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Date Of Join",
                        inputType: "date",
                        fieldName: "dateOfJoin",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },





                ]
            },
            {
                formField: [
                    {
                        label: "Unit",
                        inputType: "select",
                        multiple: true,
                        options: "unitFilterList",
                        onChange: "handleUnit",
                        uniqueKey: "unitId",
                        displayKey: "unitName",
                        fieldName: "unit",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },


                    {
                        label: "Role",
                        inputType: "select",
                        options: "roleList",
                        // disabled: true,

                        uniqueKey: "roleId",
                        displayKey: "roleName",
                        fieldName: "roleId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },

                    {
                        label: "Date Of Relieving",
                        inputType: "date",
                        fieldName: "dateOfRelieving",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    },
                    {
                        label: "Employee Type",
                        inputType: "select",
                        options: "employeeTypeList",
                        uniqueKey: "employeeTypeId",
                        displayKey: "employeeTypeName",
                        fieldName: "employeeTypeId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Report To",
                        inputType: "select",
                        options: "employeeReportingList",
                        uniqueKey: "employeeId",
                        displayKey: "employeeCode",
                        subDisplayKey: "employeeName",
                        fieldName: "reportTo",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    },
                ]
            },
            {
                formField: [
                    /*  {
                         description: "Login credentials are required for employees.",
                         inputType: "checkbox",
                         fieldName: "loginInfo",
                         onChange:"onHandleCheckBox",
                         defaultValue: "",
                         config: {
                             required: "required",
                         },
                     }, */
                    {
                        label: "User Name",
                        inputType: "text",
                        placeholder: "Enter User Name",
                        fieldName: "email",
                        disabled: true,
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Password",
                        inputType: "text",
                        type: "password",
                        // disabled: true,

                        placeholder: "Enter Password",
                        fieldName: "password",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Confirm Password",
                        inputType: "text",
                        type: "password",
                        placeholder: "Enter Confirm Password",
                        fieldName: "confirmPassword",
                        // disabled: true,

                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Shift",
                        inputType: "select",
                        options: "shiftList",
                        uniqueKey: "shiftId",
                        displayKey: "shiftName",
                        fieldName: "shiftId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },


                ]
            },

        ]
    }
];
const personalDetails = [
    {
        title: "Basic Information",
        formBody: [

            {
                formField: [
                    {
                        label: "Upload Profile Picture",
                        inputType: "imageUploader",
                        // placeholder: "Enter Details/Number",
                        onChange: "onWriteDocument",
                        fieldName: "profilePic",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    },








                ]
            },
            {
                formField: [
                    {
                        label: "Father Name",
                        inputType: "text",
                        placeholder: "Enter Father Name",
                        fieldName: "fatherName",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Mother Name",
                        inputType: "text",
                        placeholder: "Enter Mother Name",
                        fieldName: "motherName",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Mother Tongue",
                        inputType: "select",
                        options: "motherTongueList",
                        uniqueKey: "motherTongueId",
                        displayKey: "motherTongueName",
                        fieldName: "motherTongueId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },




                    {
                        label: "Age",
                        inputType: "text",
                        placeholder: "Enter Age",
                        fieldName: "age",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    },



                ]
            },
            {
                formField: [
                    {
                        label: "Martial Status",
                        inputType: "select",
                        options: "martialStatusList",
                        uniqueKey: "martialStatusId",
                        displayKey: "martialStatusName",
                        fieldName: "martialStatusId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Blood Group",
                        inputType: "select",
                        options: "bloodGroupList",
                        uniqueKey: "bloodGroupId",
                        displayKey: "bloodGroupName",
                        fieldName: "bloodGroupId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },



                    {
                        label: "No. Of. Childrens",
                        inputType: "text",
                        placeholder: "Enter No. Of. Childrens",
                        fieldName: "noOfchildrens",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    },
                    {
                        label: "Gender",
                        inputType: "select",
                        options: "genderList",
                        uniqueKey: "genderId",
                        displayKey: "genderName",
                        fieldName: "genderId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },



                ]
            },
            {
                formField: [
                    {
                        label: "Nationality",
                        inputType: "select",
                        options: "nationalityList",
                        uniqueKey: "nationalityId",
                        displayKey: "nationalityName",
                        fieldName: "nationalityId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },

                    {
                        label: "Date Of Birth",
                        inputType: "date",
                        placeholder: "Enter Date Of birth",
                        fieldName: "dateOfBirth",
                        onChange: "onSelectedDOB",
                        dob: true,
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Emergency Contact Relation",
                        inputType: "text",
                        placeholder: "Enter Contact Relation",
                        fieldName: "emergencyRelation",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Emergency Contact Number",
                        inputType: "phonenumber",
                        type: "number",
                        placeholder: "Enter Contact Number",
                        errorMsg: "* Invalid Phone Number",
                        fieldName: "emergencyContactNumber",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },


                ]
            },

        ]
    }
];
const addressForm = [
    {
        title: "Basic Information",
        formBody: [

            {
                formField: [

                    {
                        label: "Address",
                        inputType: "text",
                        placeholder: "Enter Address",
                        fieldName: "address1",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "City",
                        inputType: "select",
                        options: "cityList",
                        uniqueKey: "cityId",
                        displayKey: "cityName",
                        fieldName: "cityId",
                        onChange: "onSelectedCity",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "State",
                        inputType: "select",
                        options: "stateList",
                        uniqueKey: "stateId",
                        displayKey: "stateName",
                        disable: true,
                        fieldName: "stateId",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    },
                    {
                        label: "Land Mark",
                        inputType: "text",
                        placeholder: "Enter Address 2",
                        fieldName: "landmark",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },





                ]
            },
            {
                formField: [
                    {
                        label: "Postal Code",
                        inputType: "text",
                        type: "number",
                        placeholder: "Enter postal Code",
                        fieldName: "postalCode",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Country",
                        inputType: "select",
                        options: "countryList",
                        uniqueKey: "countryId",
                        displayKey: "countryName",
                        disable: true,
                        fieldName: "countryId",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    },


                ]
            },
            {
                formField: [
                    {
                        label: "Mother Name",
                        inputType: "text",
                        placeholder: "Enter Mother Name",
                        fieldName: "motherName",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Martial Status",
                        inputType: "select",
                        options: "martialStatusList",
                        uniqueKey: "martialStatusId",
                        displayKey: "martialStatusName",
                        fieldName: "martialStatusId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "No. Of. Childrens",
                        inputType: "text",
                        placeholder: "Enter No. Of. Childrens",
                        fieldName: "noOfchildrens",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    },
                    {
                        label: "Emergency Contact Relation",
                        inputType: "text",
                        placeholder: "Enter Contact Relation",
                        fieldName: "emergencyRelation",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },

                ]
            },

        ]
    }
];

const employeeStep2 = [
    {
        title: "Basic Information",
        formBody: [
            {
                title: "Personal Info",
                formField: [
                    {
                        label: "Upload Profile Picture",
                        inputType: "imageUploader",
                        // placeholder: "Enter Details/Number",
                        onChange: "onWriteDocument",
                        fieldName: "profilePic",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    },
                    {
                        label: "Gender",
                        inputType: "select",
                        options: "genderList",
                        uniqueKey: "genderId",
                        displayKey: "genderName",
                        fieldName: "genderId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Date Of Birth",
                        inputType: "date",
                        placeholder: "Enter Date Of birth",
                        fieldName: "dateOfBirth",
                        onChange: "onSelectedDOB",
                        dob: true,
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Age",
                        inputType: "text",
                        placeholder: "Enter Age",
                        fieldName: "age",
                        disable: false,
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    },
                    {
                        label: "Father Name",
                        inputType: "text",
                        placeholder: "Enter Father Name",
                        fieldName: "fatherName",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Mother Name",
                        inputType: "text",
                        placeholder: "Enter Mother Name",
                        fieldName: "motherName",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },



                ]
            },
            {
                title: "true",
                formField: [
                    {
                        label: "Martial Status",
                        inputType: "select",
                        options: "martialStatusList",
                        uniqueKey: "martialStatusId",
                        displayKey: "martialStatusName",
                        fieldName: "martialStatusId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "No. Of. Childrens",
                        inputType: "text",
                        placeholder: "Enter No. Of. Childrens",
                        fieldName: "noOfchildrens",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    },
                    {
                        label: "Emergency Contact Relation",
                        inputType: "text",
                        placeholder: "Enter Contact Relation",
                        fieldName: "emergencyRelation",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Emergency Contact Number",
                        inputType: "phonenumber",
                        type: "number",
                        placeholder: "Enter Contact Number",
                        errorMsg: "* Invalid Phone Number",
                        fieldName: "emergencyContactNumber",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Mother Tongue",
                        inputType: "select",
                        options: "motherTongueList",
                        uniqueKey: "motherTongueId",
                        displayKey: "motherTongueName",
                        fieldName: "motherTongueId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Blood Group",
                        inputType: "select",
                        options: "bloodGroupList",
                        uniqueKey: "bloodGroupId",
                        displayKey: "bloodGroupName",
                        fieldName: "bloodGroupId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Nationality",
                        inputType: "select",
                        options: "nationalityList",
                        uniqueKey: "nationalityId",
                        displayKey: "nationalityName",
                        fieldName: "nationalityId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },
        ]
    },
];

const jobHistoryForm = [
    {
        title: "Basic Information",
        formBody: [
            {
                title: "JOB HISTORY",
                formField: [
                    {
                        label: "",
                        inputType: "text",
                        placeholder: "Enter Company Name",
                        fieldName: "companyName",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },
            {
                title: "JOB HISTORY",
                formField: [
                    {
                        label: "",
                        inputType: "text",
                        placeholder: "Enter jobTitle",
                        fieldName: "jobTitle",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },
            {
                title: "JOB HISTORY",
                formField: [

                    {
                        label: "",
                        inputType: "date",
                        type: "date",
                        placeholder: "Enter Start Date",
                        fieldName: "startDate",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },
            {
                title: "JOB HISTORY",
                formField: [
                    {
                        label: "",
                        inputType: "date",
                        type: "date",
                        fieldName: "endDate",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },

                ]
            },
            {
                formField: [
                    {
                        label: "",
                        inputType: "icon",
                        defaultValue: "",
                        onClick: "onRemove",
                        fieldName: "jobHistoryRemove",
                        config: {
                            required: "required",
                        },
                    }
                ]
            }
        ]
    }
];

const addressInfoForm = [
    {
        title: "Basic Information",
        formBody: [
            {
                formField: [
                    {
                        label: "Current Address",
                        inputType: "text",
                        placeholder: "Enter Address",
                        fieldName: "address1",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                   
                   
                    {
                        label: "City",
                        inputType: "select",
                        options: "cityList",
                        uniqueKey: "cityId",
                        displayKey: "cityName",
                        fieldName: "cityId",
                        onChange: "onSelectedCity",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Postal Code",
                        inputType: "text",
                        type: "number",
                        placeholder: "Enter postal Code",
                        fieldName: "postalCode",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                   

                ]
            },
            {
                formField: [
                    {
                        label: "Land Mark",
                        inputType: "text",
                        placeholder: "Enter Address 2",
                        fieldName: "landmark",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    
                  
                  
                    {
                        label: "State",
                        inputType: "select",
                        options: "stateList",
                        uniqueKey: "stateId",
                        displayKey: "stateName",
                        disable: true,
                        fieldName: "stateId",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    },

                    {
                        label: "Country",
                        inputType: "select",
                        options: "countryList",
                        uniqueKey: "countryId",
                        displayKey: "countryName",
                        disable: true,
                        fieldName: "countryId",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    },

                ]
            },
        ]
    }
];

const permanentAddressInfoForm = [
    {
        title: "Basic Information",
        formBody: [
            {
                formField: [
                   
                    {
                        label: "Permanenet Address",
                        inputType: "text",
                        placeholder: "Enter Address",
                        fieldName: "permanentAddress1",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "City",
                        inputType: "select",
                        options: "cityList",
                        uniqueKey: "cityId",
                        displayKey: "cityName",
                        fieldName: "permanentCityId",
                        onChange: "onSelectedPermanentCity",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                   
                    {
                        label: "Postal Code",
                        inputType: "text",
                        placeholder: "Enter postal Code",
                        fieldName: "PermantPostalCode",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },

                ]
            },
            {
                formField: [
                    {
                        label: "Land Mark",
                        inputType: "text",
                        placeholder: "Enter Land Mark ",
                        fieldName: "permanentLandmark",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                   
                    {
                        label: "State",
                        inputType: "select",
                        options: "stateList",
                        uniqueKey: "stateId",
                        displayKey: "stateName",
                        disable: true,
                        fieldName: "permanentStateId",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    },
                   
                    {
                        label: "Country",
                        inputType: "select",
                        options: "countryList",
                        uniqueKey: "countryId",
                        disable: true,
                        displayKey: "countryName",
                        fieldName: "permanentCountryId",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    },

                ]
            },
        ]
    }
];

const qualificationForm = [
    {
        title: "Basic Information",
        formBody: [
            {
                // title: "Qualification",
                formField: [
                    {
                        label: "",
                        inputType: "select",
                        options: "qualificationList",
                        uniqueKey: "qualificationId",
                        displayKey: "qualificationName",
                        fieldName: "qualificationId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },
            {
                // title: "Qualification",
                formField: [
                    {
                        label: "",
                        inputType: "date",
                        type: "date",
                        fieldName: "startDate",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },
            {
                // title: "Qualification",
                formField: [
                    {
                        label: "",
                        inputType: "date",
                        type: "date",
                        fieldName: "endDate",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },
            {
                // title: "Qualification",
                formField: [
                    {
                        label: "",
                        inputType: "icon",
                        defaultValue: "",
                        onClick: "onRemove",
                        fieldName: "qualificationRemove",

                        config: {
                            required: "required",
                        },
                    }
                ]
            },
        ]
    }
];

const salaryForm = [
    {
        title: "Basic Information",
        formBody: [
            {
                // title: "Employee salary",
                formField: [
                    {
                        label: "Department",
                        inputType: "select",
                        options: "departmentList",
                        uniqueKey: "departmentId",
                        displayKey: "departmentName",
                        fieldName: "departmentId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Commission Pct",
                        inputType: "text",
                        placeholder: "Enter Commission Pct",
                        fieldName: "commissionPct",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Pf Number",
                        inputType: "text",
                        placeholder: "Enter pf number",
                        fieldName: "pfNumber",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    },



                ]
            },
            {
                // title: "Employee salary",
                formField: [

                    {
                        label: "Gross-Pay",
                        inputType: "text",
                        type: "number",
                        placeholder: "Enter Gross-Pay",
                        fieldName: "grossPay",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Salary Type",
                        inputType: "select",
                        options: "employeeSalaryTypeList",
                        uniqueKey: "empSalaryTypeId",
                        displayKey: "salaryTypeName",
                        fieldName: "empSalaryTypeId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Health Insurance Number",
                        inputType: "text",
                        type: "number",
                        placeholder: "Enter health insurance number",
                        fieldName: "healthInsurance",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    },



                ]
            },
            {
                formField: [
                    {
                        label: "Designation",
                        inputType: "select",
                        options: "designationList",
                        uniqueKey: "designationId",
                        displayKey: "designationName",
                        fieldName: "designationId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Salary Amount",
                        inputType: "text",
                        type: "number",
                        placeholder: "Enter Salary Amount",
                        fieldName: "salaryAmt",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Net-Pay",
                        inputType: "text",
                        type: "number",
                        placeholder: "Enter Net-Pay",
                        fieldName: "netPay",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },



                ]
            },
            {
                formField: [

                    {
                        label: "Deduction",
                        inputType: "text",
                        type: "number",
                        placeholder: "Enter Deduction",
                        fieldName: "deduction",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },


                ]
            },
        ]
    },
    {
        formBody: [
            {
                formField: [



                ]
            },
            {
                formField: [


                ]
            },
            {
                formField: [



                ]
            },
        ]
    }
];

const employeePositionForm = [
    {
        title: "Basic Information",
        formBody: [
            {
                title: "Employee Position",
                formField: [
                    {
                        label: "Designation",
                        inputType: "select",
                        options: "designationList",
                        uniqueKey: "designationId",
                        displayKey: "designationName",
                        fieldName: "designationId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },
            {
                title: "Employee Position",
                formField: [
                    {
                        label: "Department",
                        inputType: "select",
                        options: "departmentList",
                        uniqueKey: "departmentId",
                        displayKey: "departmentName",
                        fieldName: "departmentId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    /* {
                        label: "Manager",
                        inputType: "select",
                        options: "managerList",
                        uniqueKey:"managerId",
                        displayKey:"managerName",
                        multiple:false,
                        fieldName:"managerId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },  */




                ]
            },


        ]
    }
];

const fileUploaderForm = [
    {
        title: "Basic Information",
        formBody: [
            {
                title: "Upload Files",
                formField: [
                    {
                        label: "",
                        inputType: "select",
                        options: "fileTypeList",
                        uniqueKey: "fileTypeId",
                        displayKey: "fileTypeName",
                        fieldName: "fileTypeId",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    },
                ]
            },
            {
                title: "true",
                formField: [
                    {
                        label: "",
                        inputType: "file",
                        fileFormat: "application/pdf",
                        onChange: "onWriteDocument",
                        fieldName: "fileName",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    },
                ]
            },
            {
                title: "true",
                formField: [
                    {
                        label: "",
                        inputType: "icon",
                        defaultValue: "",
                        onClick: "onRemove",
                        fieldName: "onRemoveFileUpload",

                        config: {
                            required: "",
                        },
                    }
                ]
            },
            {
                title: "true",
                formField: [
                    {
                        label: "",
                        inputType: "viewicon",
                        fieldName: "fileUploadView",
                        defaultValue: "",
                        onClick: "onView",
                        config: {
                            required: "",
                        },
                    }
                ]
            },
        ]
    }
];

const relivingForm = [
    {
        title: "Basic Information",
        formBody: [
            {
                // title: "Qualification",
                formField: [
                    {
                        label: "Date Of Relieving",
                        inputType: "date",
                        placeholder: "Enter Date Of Relieving",
                        fieldName: "dateOfRelieving",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },
        ]
    }
];

const reJoinForm = [
    {
        title: "Basic Information",
        formBody: [
            {
                // title: "Qualification",
                formField: [
                    {
                        label: "Employee Code",
                        inputType: "searchtext",
                        placeholder: "Enter Employee Code",
                        fieldName: "searchEmployee",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "search",
                        inputType: "button",
                        placeholder: "Enter Employee Code",
                        className: "mt-3",
                        onClick: "onEmployeSearch",
                        fieldName: "searchEmployee",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },
        ]
    }
];

const proofUploaderForm = [
    {
        // title: "Basic Information",
        formBody: [
            {
                title: "Upload Proof",
                formField: [
                    {
                        label: "",
                        inputType: "select",
                        options: "proofTypeList",
                        uniqueKey: "proofTypeId",
                        displayKey: "proofTypeName",
                        fieldName: "proofTypeId",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    },
                ]
            },
            {
                title: "true",
                formField: [
                    {
                        label: "",
                        inputType: "file",
                        fileFormat: "application/pdf",
                        onChange: "onWriteDocument",
                        fieldName: "proofDocument",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    },
                ]
            },
            {
                title: "true",
                formField: [
                    {
                        label: "",
                        inputType: "text",
                        placeholder: "Enter Proof Number",
                        fieldName: "proofNumber",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    },
                ]
            },
            {
                title: "true",
                formField: [
                    {
                        label: "",
                        inputType: "viewicon",
                        fieldName: "employeProof",


                        defaultValue: "",
                        onClick: "onView",
                        config: {
                            required: "",
                        },
                    }
                ]
            },
            {
                title: "true",
                formField: [
                    {
                        label: "",
                        inputType: "icon",
                        defaultValue: "",
                        onClick: "onRemove",
                        fieldName: "proofRemove",

                        config: {
                            required: "",
                        },
                    }
                ]
            },
        ]
    }
];

export {
    employeeForm,
    employeeDetails,
    reJoinForm,
    employeeStep2,
    qualificationForm,
    jobHistoryForm,
    addressInfoForm,
    relivingForm,
    permanentAddressInfoForm,
    salaryForm,
    employeePositionForm,
    fileUploaderForm,
    proofUploaderForm,
    personalDetails,
    addressForm
};