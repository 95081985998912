
const employeeLeaveForm = [
    {
        title: "Basic Information",
        formBody: [
            {
                // title: "Basic Information",
                formField: [  
                                     
                    {
                        label: "Employee",
                        inputType: "typedropdown",
                        options: "employeeList",
                        uniqueKey: "employeeId",
                        displayKey: "employeeCode",
                        subDisplayKey: "employeeName",
                        onChange:"resetState",
                        limit:"1",
                        fieldName: "employeeId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        }
                    },
                   
                   /*  {
                        label: "Day Type",
                        inputType: "radio",
                        options: "dayTypeList",
                        uniqueKey: "dayTypeId",
                        // type: "block",
                        onChange:"handleDayType",
                        displayKey: "dayTypeName",   
                        fieldName: "dayType",
                        defaultValue: "1",
                        config: {
                            required: "required",
                        },
                    }, */
                    {
                        label: "Leave Type",
                        inputType: "select",
                        options: "leaveTypeList",
                        uniqueKey: "leaveTypeId",
                        displayKey: "leaveTypeName",
                        multiple: false,
                        onChange:"resetState",
                        fieldName: "leaveTypeId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        }
                    },
                   
                  
                    {
                        label: "Start Date",
                        inputType: "date",
                        fieldName: "startDate",
                        onChange:"resetState",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                   
                    
                    /* {
                        label: "Number Of Days",
                        inputType: "text",
                        disable:true,
                        fieldName: "noOfDays",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }, */
                   
                    {
                        fieldName: "balanceLeaveCount",
                        inputType: "label",
                    },
                    {
                        fieldName: "leavesContent",
                        inputType: "label",
                    },
                   
                ]
            },
            {
                formField: [
                    {
                        label: "Day Type",
                        inputType: "radio",
                        options: "dayTypeList",
                        uniqueKey: "dayTypeId",
                        marginTop: "11px",
                        // type: "block",
                        onChange:"handleDayType",
                        displayKey: "dayTypeName",   
                        fieldName: "dayType",
                        defaultValue: "1",
                        config: {
                            required: "required",
                        },
                    }, 
                    
                    {
                        label: "Description",
                        inputType: "textarea",
                        fieldName: "description",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    },
                    {
                        label: "End Date",
                        inputType: "date",
                        fieldName: "endDate",
                        onChange: "onSelectEndDate",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                   
                   
                ]
            }
        ]
    }
];

const assignStatusForm = [
    {
        title: "Basic Information",
        formBody: [
            {
                // title: "Basic Information",
                formField: [
                     {
                        label: "Leave Status",
                        inputType: "select",
                        options: "leaveStatusList",
                        uniqueKey: "leaveStatusId",
                        displayKey: "leaveStatusName",
                        multiple: false,
                        fieldName: "leaveStatusId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        }
                    },
                    {
                        label: "Reason",
                        inputType: "textarea",
                        fieldName: "leaveStatusRemarks",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }, 
                ]
            }
        ]
    }
];


const employeeLeaveEditForm = [
    {
        title: "Basic Information",
        formBody: [
            {
                // title: "Basic Information",
                formField: [
                    /* {
                        label: "Accepted By",
                        inputType: "text",
                        placeholder: "Enter acceptedBy",
                        fieldName: "acceptedBy",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }, */
                    /* {
                        label: "Leave Status",
                        inputType: "select",
                        options: "leaveStatusList",
                        uniqueKey: "leaveStatusId",
                        displayKey: "leaveStatusName",
                        multiple: false,
                        fieldName: "leaveStatusId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        }
                    }, */
                    /* {
                        label: "Employee",
                        inputType: "typedropdown",
                        options: "employeeList",
                        uniqueKey: "employeeId",
                        displayKey: "firstName",
                        subDisplayKey:"lastName",
                        disable:true,
                        onChange:"resetState",
                        fieldName: "employeeId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        }
                    }, */
                    {
                        label: "Employee",
                        inputType: "typedropdown",
                        options: "employeeList",
                        uniqueKey: "employeeId",
                        displayKey: "employeeCode",
                        onChange:"resetState",
                        limit:"1",
                        disable:true,
                        fieldName: "employeeId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        }
                    },
                    {
                        label: "Leave Type",
                        inputType: "select",
                        options: "leaveTypeList",
                        uniqueKey: "leaveTypeId",
                        displayKey: "leaveTypeName",
                        multiple: false,
                        onChange:"resetState",
                        fieldName: "leaveTypeId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        }
                    },
                    {
                        label: "Start Date",
                        inputType: "date",
                        fieldName: "startDate",
                        onChange:"resetState",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "End Date",
                        inputType: "date",
                        fieldName: "endDate",
                        onChange: "onSelectEndDate",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Description",
                        inputType: "textarea",
                        fieldName: "description",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    },
                    {
                        label: "Number Of Days",
                        inputType: "text",
                        disable:true,
                        fieldName: "noOfDays",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },

                ]
            }
        ]
    }
];
export {
    employeeLeaveForm,
    assignStatusForm,
    employeeLeaveEditForm
};