import { returnApiCallPromise } from './ApiContent';
import { studentAttendance } from './APIContainer';

//GET
export function getStudentAttendance(request) {
  const requestObj = request ? { params: request } : "";
  return (dispatch) => {
    returnApiCallPromise("GET", studentAttendance, requestObj)
      .then(response => {
        dispatch({
          type: 'GET_STUDENT_ATTENDANCE_SUCCESS',
          payload: response.data
        })
      }).catch(error => {
        
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'GET_STUDENT_ATTENDANCE_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

//POST
export function createStudentAttendance(request) {

  return (dispatch) => {
    returnApiCallPromise("POST", studentAttendance, request)
      .then(response => {
        if (response.data.error) {
          dispatch({
            type: 'CREATE_STUDENT_ATTENDANCE_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'CREATE_STUDENT_ATTENDANCE_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'CREATE_STUDENT_ATTENDANCE_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

export function updateStudentAttendance(request, studentAttendanceId) {
  return (dispatch) => {
    returnApiCallPromise("PUT", studentAttendance+"/"+studentAttendanceId , request)
      .then(response => {

        if (response.data.error) {
          dispatch({
            type: 'UPDATE_STUDENT_ATTENDANCE_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'UPDATE_STUDENT_ATTENDANCE_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'UPDATE_STUDENT_ATTENDANCE_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}