import { returnApiCallPromise } from './ApiContent';
import { employeeAttendance,employeeAttendanceDetails } from './APIContainer';


//GET
export function getEmployeeAttendance(request) {
  const requestObj = request ? { params: request } : "";
  
  return (dispatch) => {
    returnApiCallPromise("GET",employeeAttendance, requestObj)
      .then(response => {
        dispatch({
          type: 'GET_EMPLOYEE_ATTENDANCE_SUCCESS',
          payload: response.data
        })
      }).catch(error => {
        
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'GET_EMPLOYEE_ATTENDANCE_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

export function getEmployeeAttendanceDetails(request) {
  const requestObj = request ? { params: request } : "";
  return (dispatch) => {
    returnApiCallPromise("GET",employeeAttendanceDetails, requestObj)
      .then(response => {
        dispatch({
          type: 'GET_EMPLOYEE_ATTENDANCE_DETAILS_SUCCESS',
          payload: response.data
        })
      }).catch(error => {
        
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'GET_EMPLOYEE_ATTENDANCE_DETAILS_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

//POST
export function createEmployeeAttendance(request) {

  return (dispatch) => {
    returnApiCallPromise("POST", employeeAttendance, request)
      .then(response => {
        if (response.data.error) {
          dispatch({
            type: 'CREATE_EMPLOYEE_ATTENDANCE_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'CREATE_EMPLOYEE_ATTENDANCE_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'CREATE_EMPLOYEE_ATTENDANCE_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

export function updateEmployeeAttendance(request, employeeAttendanceId) {
  return (dispatch) => {
    returnApiCallPromise("PUT", employeeAttendance, request)
      .then(response => {

        if (response.data.error) {
          dispatch({
            type: 'UPDATE_EMPLOYEE_ATTENDANCE_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'UPDATE_EMPLOYEE_ATTENDANCE_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'UPDATE_EMPLOYEE_ATTENDANCE_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}