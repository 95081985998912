
const leaveTypeForm = [
    {
        title: "Basic Information",
        formBody : [
            {
                formField: [
                    {
                        label: "Leave Type Name",
                        inputType: "text",
                        fieldName:"leaveTypeName",
                        placeholder: "Enter Leave Type name",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Description",
                        inputType: "textarea",
                        placeholder:"Enter description",
                        fieldName:"description",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }                                                
                ]
            }
        ]
    }
];

export {
    leaveTypeForm
};