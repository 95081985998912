

const eventForm = [
    {
        title: "Basic Information",
        formBody: [
            {
                formField: [

                    {
                        label: "Event Name",
                        inputType: "text",
                        placeholder: "Enter Title",
                        fieldName: "eventName",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Event Date",
                        inputType: "date",
                        placeholder: "Select Event Date",
                        fieldName: "eventDate",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }
                ]
            },
            {
                formField: [
                    {
                        label: "Start Time",
                        inputType: "time",
                        type: "time",
                        /*  options: "materialTypeList",
                            uniqueKey: "knowledgeMaterialTypeId",
                            onChange:"onSelectMaterialType",
                            displayKey: "knowledgeMaterialType", */
                        fieldName: "startTime",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {

                        label: "End Time",
                        inputType: "time",
                        type: "time",
                        /*  options: "enrollmentDetailsList",
                        uniqueKey: "enrollmentId",
                        displayKey: "classTitle",
                        onChange: "onSelectClass", */
                        subDisplayKey: "sectionTitle",
                        multiple: true,
                        fieldName: "endTime",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },

                    }
                ]
            },
            {
                formField: [
                    {
                        label: "Upload Image",
                        inputType: "file",
                        fieldName: "imageName",
                        onChange: "onWriteDocument",
                        defaultValue: "",
                        disable: false,
                        config: {
                            required: "",
                        },
                    }
                ]
            },
        ]
    }
];

export {
    eventForm
};