import { Placeholder } from "react-bootstrap";

const holidaysForm = [
    {
        title: "Basic Information",
        formBody: [
            {
                formField: [
                    {
                        label: "Holiday Date",
                        inputType: "date",
                        fieldName: "holidayDate",
                        startPlaceholder: "Start Date",
                        endPlaceholder: "End Date",
                        range: true,
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Holiday Name",
                        inputType: "text",
                        fieldName: "holidayName",
                        placeholder: "Enter Holiday Name",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    /* {
                        label: "Holiday Type",
                        inputType: "text",
                        fieldName:"holidayType",
                        defaultValue: "",
                        config: {                      
                            required: "required",
                        },
                    } */

                ]
            }
        ]
    }
];

export {
    holidaysForm
};