import { Link } from "react-router-dom";
import styled from "styled-components";
import { btnReset, v } from "../../../styles/variables";
import { NavLink } from "react-router-dom";

export const SSidebar = styled.div`
    width: 70px; /* Adjust widths as needed */
    height: 100vh;
    padding: ${v.lgSpacing};
    position: relative;
    z-index:100;
    background-color:red;
    margin-top:15rem;
    position:relative;


    transition: width 0.3s ease; 
    ::-webkit-scrollbar {
            width: 0; 
            background: transparent; 
        }

        /* Hide scrollbar in Firefox */
        scrollbar-width: none; /* For Firefox */ 


`;


export const SLogo = styled.div`
    background-color:red;
    width:70px;

    img {
        width: 50px;
        height: 60px;
        position:fixed;
        top:-5px;
        left:10px;
    }
    cursor: pointer;
`;

export const SLinkContainer = styled.div`
    border-radius: ${v.borderRadius};
    width:70px;
    margin: 8px 0;
    border:none
  
`;

export const SLinkLabel = styled.span`
    position: absolute;
    left: 70px; /* Adjust position as needed */
    background: ${({ theme }) => theme.bg3};
    color: ${({ theme }) => theme.text};
    padding: 4px 18px;
    width: auto;
    border-radius: 4px;
    white-space: nowrap;
    opacity: 0; /* Start invisible */
    transition: opacity 0.3s ease;

    /* Show label when hovered, if sidebar is collapsed */
    ${SLinkContainer}:hover & {
        opacity: 10;
        display:block;
    }
`;

export const SLabel = styled.h6`
    margin-left: 10px;
    opacity: 0;
    transition: opacity 0.3s ease;
    white-space: nowrap;
`;



export const SDiv = styled.div`
    position: fixed; /* Fixes the sidebar in place */
    top: 70px; /* Space from the top */
    left: 0; 
    height: calc(100% - 20px); 
    overflow-y: scroll; /* Enable scrolling if content overflows */
    padding: ${v.lgSpacing};
    background-color: ${({ theme }) => theme.primaryColor};

    padding-bottom:100px;
    border-right:0.2px solid #D3D3D3;
    border-top:0.2px solid #D3D3D3;
    border-top:0.2px solid #D3D3D3;
    width:70px;
    overflow-x:hidden;




`;


export const SLink = styled(Link)`
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: calc(${v.smSpacing} - 2px) 0;

    
`;

export const SLinkIcon = styled.div`
    padding: 10px;
    display: flex;



   svg {
        font-size: 20px;
        color: ${({ theme, isActive }) => (!isActive ? theme.iconColor : theme.primaryColor)};
    }

    background: ${({ theme, isActive }) => (!isActive ? `transparent` : theme.secondaryColor)};
    border-radius: ${v.borderRadius};

    /* Change background color to red on hover over the link container */
    ${SLinkContainer}:hover & {
        background: ${({ theme, isActive }) => (!isActive ? theme.lightGray : theme.lightGray)};
        svg {
        font-size: 20px;
        color:${({ theme, isActive }) => (!isActive ? 'gray' : 'white')};

        }
       
    }
`

export const SLinkNotification = styled.div`
    font-size: 14px;
    padding: calc(${v.smSpacing} / 2) ${v.smSpacing};
    border-radius: calc(${v.borderRadius} / 2);
    background: ${({ theme }) => theme.primary};
    color: white;
    margin-right: ${v.mdSpacing};
`;

export const STheme = styled.div`
    display: flex;
    align-items: center;
    font-size: 16px;
`;

export const SThemeLabel = styled.span`
    display: block;
    flex: 1;
`;

export const SThemeToggler = styled.button`
    ${btnReset};
    margin: 0 auto;
    cursor: pointer;
    width: 36px;
    height: 20px;
    border-radius: 10px;
    background: ${({ theme, isActive }) => (!isActive ? theme.bg3 : theme.primary)};
    position: relative;
    border:0.2px solid gray;
`;

export const SToggleThumb = styled.div`
    height: 18px;
    width: 18px;
    position: absolute;
    top: 1px;
    bottom: 1px;
    transition: 0.2s ease right;
    right: calc(100% - 18px - 1px);
    border-radius: 50%;
    background: ${({ theme }) => theme.bg};
`;
export const SHoverLabel = styled.span`
    padding: 5px 5px;
    position: fixed; 
    z-index: 200; /* Ensure it appears above other elements */
    border-radius: 10px;
    color: white;
    white-space: nowrap; 
    text-align: center;
    pointer-events: auto;
`;

export const CardContainer = styled.div`
    width: 240px;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden; /* Prevent overflow */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: absolute; /* Use absolute positioning for better control */
    background-color: ${({ theme }) => theme.primaryColor};
`;


export const CardLink = styled(NavLink)`
text-decoration: none;
color: white;
display: block;
padding: 8px;
// border-radius: 5px;
font-size: 13px;
position: relative;
transition: background-color 0.3s;
width:170px;
word-wrap: break-word;
white-space: normal; 
justify-content: flex-start; /* Align items at the start */



&.active {
  color:orange;

  

}

&.active::before {
  content: '';
  position: absolute;
  top: 0;
  width: 3px;
  height:100%;
  left:-10px;

  background-color:${({ theme }) => theme.secondaryColor};
  border-radius: 0px 5px 5px 0px;
}
`;

export const SLink_Icon = styled.div`
    padding-right: 2px;
    display: flex;
    margin-right:10px;

    svg {
        font-size: 14px;
        color: ${({ theme, isActive }) => (!isActive ? theme.iconColor : theme.secondaryColor)};
    }

    background: ${({ theme, isActive }) => (isActive ? theme.activeBackground : 'transparent')};
    border-radius: ${v.borderRadius};

    ${SLinkContainer}:hover & {
        background: ${({ theme, isActive }) => (isActive ? theme.secondaryColor : theme.hoverBackground)};
        svg {
            color: ${({ theme, isActive }) => (isActive ? theme.activeIconHoverColor : theme.hoverIconColor)};
        }
    }
`;



export const CardHeader = styled.div`
  color:white;
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
  border-bottom:1px solid #D3D3D3;
  border-radius:10px;
  margin-bottom:3px;
  
`;

export const MenuList = styled.ul`
  list-style-type: none;
  overflow-y: scroll;


  /* For WebKit Browsers (Chrome, Safari, Edge) */
  &::-webkit-scrollbar {
    width: 2px;
    color:${({ theme }) => theme.mediumPurple}; 
      }

  &::-webkit-scrollbar-thumb {
    background-color:${({ theme }) => theme.mediumPurple}; /* Scrollbar thumb color */
    border-radius: 3px; /* Rounded corners for the thumb */
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Thumb color on hover */
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* Scrollbar track color */
  }

 
`;

export const MenuItem = styled.li`
  cursor: pointer;
  transition: background-color 0.3s ease;
//   border-radius:10px;
  text-align:start;
  padding-left:10px;
  white-space: nowrap;


  

  
  &:hover {
    background-color: #CCCCCC;
  }
`;

export const SubMenuList = styled.div`
  height: ${(props) => (props.visible ? '200px' : '0px')};
  overflow-y: scroll;
  opacity: ${(props) => (props.visible ? '1' : '0')}; /* Fade in/out */
  transition: height 0.3s ease-out, opacity 0.3s ease-out; /* Animate both height and opacity */
  padding-left: 60px; /* Optional padding */
  text-align:start;
 
  
 

`;



export const SubMenuItem = styled.li`
  padding: 1px 1px;
  cursor: pointer;
  margin-left:-30px;
  
  
  
  


  
  &:hover {
    background-color: #bdc3c7;
    border-radius:5px;
    color:white!important;
  }
`;

export const SubmenuToggle = styled.button`
  background: none;
  border: none;
  color: gray;
  cursor: pointer;
  font-size: 16px;
  transition: transform 0.3s ease-in-out; /* Smooth rotation of the icon */
  transform: ${({ open }) => (open ? 'rotate(180deg)' : 'rotate(0)')};
  margin-right:0px;
  position:absolute;
  left:200px;
  
`;

export const Tooltip = styled.div`
  position: fixed;
  background-color: ${({ theme }) => theme.modalViewHeader};
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  pointer-events: none;
  opacity: 0;
  transform: translate(-50%, -10px);
  transition: opacity 0.2s ease, transform 0.2s ease;
  z-index: 1000;

  &.visible {
    opacity: 1;
  }
`;