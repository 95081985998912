import React from 'react';
import { event, imagePath } from "app/utils/constant";
import { dateConversion } from "app/utils/app-functions";
import { BsCalendarDateFill } from "react-icons/bs";

const EventView = ({ data, id }) => {
  const selectedEvent = data.find(event => event.eventId === id);
  const profile = `${imagePath}${event}/${encodeURI(selectedEvent?.image || "")}`;

  const styles = {
    container: {
      padding: "20px",
      borderRadius: "12px",
      margin: "30px auto",
      maxWidth: "900px",
      backgroundColor: "#ffffff",
      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "20px",
      borderBottom: "1px solid #eaeaea",
      paddingBottom: "10px",
    },
    dateBadge: {
      display: "flex",
      alignItems: "center",
      gap: "8px",
      backgroundColor: "#418596",
      color: "#ffffff",
      padding: "8px 12px",
      borderRadius: "8px",
      fontSize: "14px",
    },
    title: {
      fontSize: "24px",
      fontWeight: "600",
      color: "#4D6D9A",
      margin: "0",
    },
    image: {
      display: "block",
      margin: "20px auto",
      maxWidth: "100%",
      height: "auto",
      borderRadius: "12px",
    },
    detailsTable: {
      width: "100%",
      marginTop: "20px",
      borderSpacing: "0 8px",
    },
    tableRow: {
      display: "flex",
      padding: "10px",
      borderBottom: "1px solid #eaeaea",
    },
    label: {
      fontWeight: "bold",
      color: "#333",
    },
    value: {
      color: "#555",
    },
    description: {
      marginTop: "20px",
      lineHeight: "1.6",
      color: "#555",
    },
  };

  return (
    <div style={styles.container}>
      {/* Header */}
      <div style={styles.header}>
        <h1 style={styles.title}>{selectedEvent?.title || "Event Details"}</h1>
        <div style={styles.dateBadge}>
          <BsCalendarDateFill />
          <span>{dateConversion(selectedEvent?.eventDate || "", "DD-MM-YYYY")}</span>
        </div>
      </div>

      {/* Event Image */}
      {selectedEvent?.image && <img src={profile} alt="Event" style={styles.image} />}

      {/* Event Details */}
      <div>
        <table style={styles.detailsTable}>
          <tbody>
            <tr style={styles.tableRow}>
              <td style={styles.label}>Start Time:</td>
              <td style={styles.value}>{selectedEvent?.startTime || "N/A"}</td>
            </tr>
            <tr style={styles.tableRow}>
              <td style={styles.label}>End Time:</td>
              <td style={styles.value}>{selectedEvent?.endTime || "N/A"}</td>
            </tr>
            <tr style={styles.tableRow}>
              <td style={styles.label}>Description:</td>
              <td style={styles.value}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: selectedEvent?.description || "No description available.",
                  }}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EventView;
