
const bookTypeForm = [
    {
        title: "Basic Information",
        formBody : [
            {
                formField: [
                    {
                        label: "Book Type",
                        inputType: "text",
                        placeholder: "Enter Book name",
                        fieldName:"booksType",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    /* {
                        label: "Author Name",
                        inputType: "text",
                        placeholder: "Enter Author Name",
                        fieldName:"authorName",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Edition",
                        inputType: "text",
                        placeholder: "Enter Edition",
                        fieldName:"edition",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Title",
                        inputType: "text",
                        placeholder: "Enter Title",
                        fieldName:"title",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Material",
                        inputType: "text",
                        placeholder: "Enter Material",
                        fieldName:"material",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Short Description",
                        inputType: "text",
                        placeholder: "Enter Short Description",
                        fieldName:"shortDescription",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Book Type",
                        inputType: "select",
                        options:'bookTypeList',
                        fieldName:"bookTypeId",
                        displayKey:"booksType",
                        uniqueKey:"bookTypeId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },*/ 
                    /* {
                        label: "Batch End",
                        inputType: "text",
                        type:'number',
                        placeholder: "YYYY",
                        fieldName:"batchEnd",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }
                        */                                              
                ]
            }
        ]
    }
];

export {
    bookTypeForm
};