import React from 'react';
import { lightTheme } from "styles/theme";

const DynamicButton = ({ text, onClick, style }) => {
  return (
    <button 
      style={{ 
        backgroundColor: lightTheme.primaryColor || 'lightblue', 
        border: "none", 
        fontSize: "12px",
        padding:"5px",
        borderRadius:"5px",
        color:"white", 
        ...style // Allow additional styles to be passed
      }} 
      onClick={onClick}
    >
      {text}
    </button>
  );
};

export default DynamicButton;
