import { returnApiCallPromise } from './ApiContent';
import { customerInventory,customerOrder } from './APIContainer';

//GET
export function getCustomer(request) {
    const requestObj = request ? { params: request } : "";
    return (
        returnApiCallPromise("GET", customerInventory, requestObj)
        .then(response => {
            const result = {
              getCustomerSuccess: true,
              getCustomerList: response.data.data
            }
            return result;
          }).catch(error => {
      
            let errorMessage = error;
            if (error.response) {
              if (error.response.data.error) {
                errorMessage = error.response.data.message;
              }
            }
            const result = {
              getCustomerFailed: true,
              errorMessage: errorMessage
            }
            return result;
          })
    )
}

export function getCustomerOrder(request) {
    const requestObj = request ? { params: request } : "";
    return (dispatch) => {
        returnApiCallPromise("GET", customerOrder, requestObj)
            .then(response => {
                dispatch({
                    type: 'GET_CUSTOMER_ORDER_SUCCESS',
                    payload: response.data
                })
            }).catch(error => {

                let errorMessage = error;
                if (error.response) {
                    if (error.response.data.error) {
                        errorMessage = error.response.data.message;
                    }
                }
                dispatch({
                    type: 'GET_CUSTOMER_ORDER_FAILURE',
                    errorMessage: errorMessage
                })
            })
    }
}

//POST
export function createCustomer(request) {

    return (dispatch) => {
        returnApiCallPromise("POST", customerInventory, request)
            .then(response => {
                if (response.data.error) {
                    dispatch({
                        type: 'CREATE_CUSTOMER_FAILURE',
                        errorMessage: response.data.message
                    })
                } else {
                    dispatch({
                        type: 'CREATE_CUSTOMER_SUCCESS',
                        payload: response.data
                    })
                }
            }).catch(error => {
                let errorMessage = error;
                if (error.response) {
                    if (error.response.data.error) {
                        errorMessage = error.response.data.message;
                    }
                }
                dispatch({
                    type: 'CREATE_CUSTOMER_FAILURE',
                    errorMessage: errorMessage
                })
            })
    }
}