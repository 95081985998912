
const unitTypeForm = [
    {
        title: "Basic Information",
        formBody: [
            {
                formField: [
                    {
                        label: "Unit Type Name",
                        inputType: "text",
                        fieldName: "unitTypeName",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            }
        ]
    }
];

const unitForm = [
    {
        title: "Basic Information",
        formBody: [
            {
                formField: [
                    {
                        label: "Unit",
                        inputType: "select",
                        options: "unitFilterList",
                        uniqueKey: "unitId",
                        onChange: "onHandleUnit",
                        displayKey: "unitName",
                        fieldName: "unitId",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    },
                    {
                        label: "Unit Name",
                        inputType: "text",
                        fieldName: "unitName",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },

                    {
                        label: "Email",
                        inputType: "text",
                        fieldType: "email",
                        fieldName: "email",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Phone",
                        inputType: "phonenumber",
                        type: "number",
                        fieldName: "phone",
                        errorMsg: "* Invalid Phone Number",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        description: "Login Info for Master Admin",
                        inputType: "checkbox",
                        fieldName: "loginInfo",
                        defaultValue: "",
                        onChange: "handleCheckboxLogin",
                        disable: false,
                        config: {
                            required: "required",
                        },
                    },
                   

                ]
            },
            {
                formField: [
                    {
                        label: "Unit Type",
                        inputType: "select",
                        options: "unitTypeList",
                        uniqueKey: "unitTypeId",
                        displayKey: "unitTypeName",
                        fieldName: "unitTypeId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Address",
                        inputType: "text",
                        fieldName: "address",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Pincode",
                        inputType: "text",
                        fieldName: "pincode",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Location",
                        inputType: "text",
                        fieldName: "location",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Branch Code",
                        inputType: "text",
                        fieldName: "unitCode",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },

                    /*  {
                         label: "image",
                         inputType: "text",
                         fieldName:"location",
                         defaultValue: "",
                         config: {
                             required: "required",
                         },
                     },   */
                ]
            },
           
        ],

    },

]

const parentunitForm = [
    {
        title: "Basic Information",
        formBody: [
            {
                formField: [
                    {
                        label: "Unit",
                        inputType: "select",
                        options: "unitFilterList",
                        uniqueKey: "unitId",
                        onChange: "onHandleUnit",
                        displayKey: "unitName",
                        fieldName: "unitId",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    },
                    {
                        label: "Unit Name",
                        inputType: "text",
                        fieldName: "unitName",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    
                    {
                        label: "Email",
                        inputType: "text",
                        fieldType: "email",
                        fieldName: "email",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    
                    {
                        label: "Phone",
                        inputType: "phonenumber",
                        type: "number",
                        fieldName: "phone",
                        errorMsg: "* Invalid Phone Number",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Address",
                        inputType: "text",
                        fieldName: "address",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },
            {
                formField: [
                    {
                        label: "Unit Type",
                        inputType: "select",
                        options: "unitTypeList",
                        uniqueKey: "unitTypeId",
                        displayKey: "unitTypeName",
                        fieldName: "unitTypeId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                  
                    {
                        label: "Pincode",
                        inputType: "text",
                        fieldName: "pincode",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Location",
                        inputType: "text",
                        fieldName: "location",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Unit Code",
                        inputType: "text",
                        fieldName: "unitCode",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Unit Logo",
                        inputType: "file",
                        fieldName: "imageName",
                        onChange: "onWriteDocument",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    },
                    /*  {
                         label: "image",
                         inputType: "text",
                         fieldName:"location",
                         defaultValue: "",
                         config: {
                             required: "required",
                         },
                     },   */
                ]
            },
           /*  {
                formField: [
                    {
                        label: "Login Info for Master Admin",
                        inputType: "checkbox",
                        fieldName: "loginInfo",
                        defaultValue: "",
                        disable: true,
                        config: {
                            required: "",
                        },
                    }, {
                        label: "User Name",
                        inputType: "text",
                        placeholder: "Enter User Name",
                        fieldName: "userName",
                        disable: true,
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    },
                    {
                        label: "Password",
                        inputType: "text",
                        type: "password",
                        disable: true,
                        placeholder: "Enter Password",
                        fieldName: "password",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    },
                    {
                        label: "Role",
                        inputType: "select",
                        options: "roleList",
                        disable: true,
                        uniqueKey: "roleId",
                        displayKey: "roleName",
                        fieldName: "roleId",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    },
                    {
                        label: "Confirm Password",
                        inputType: "text",
                        type: "password",
                        disable: true,
                        placeholder: "Enter Confirm Password",
                        fieldName: "confirmPassword",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    },

                ],
            } */
        ],

    },

]
export {
    unitTypeForm,
    unitForm,
    parentunitForm
};