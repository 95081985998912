import React from "react";
import { Button } from "react-bootstrap";
import { Tabs, Tab } from "react-tabs-scrollable";
import "react-tabs-scrollable/dist/rts.css";

const CustomAlignButton = ({ ...props }) => {

    const { btnName,alignName,onClick } = props
    return (
        <div>
        <div className={`d-flex justify-content-${alignName ? alignName : "end"}`} >
            <Button onClick={()=>onClick()}> {btnName ? btnName : "Save"}</Button>
        </div>
        </div>
    );
};

export default CustomAlignButton;


