import * as yup from "yup";
import { Formik } from "formik";
import { useState, useRef, useImperativeHandle, forwardRef } from "react";
import DateTime from "react-datetime";
import { Form, Button } from "react-bootstrap";
// import { CodeViewer, Breadcrumb } from "@gull";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import formRender from "../formcomponent";

const TableLayout = forwardRef((props, ref) => {
    // const { dynamicForm, onSubmit } = props;
    const formRef = useRef()
    const [errorState, setErrorState] = useState({});
    const { options, data, closeModule, updatedData } = props;
    const { defaultState, setDefaultState,onChangeCallback, dataList, setDataList, updateState, index,subIndex, layoutType, dynamicForm, noOfColumn, isField, onSubmit, iconPress, onSearch,parentIndex } = props;
    const validateForm = () => {
        const stateData = { ...errorState, };
        let errorStatus = false;
        (dynamicForm ? dynamicForm : []).map((item, index) => {
            (item?.formBody || []).map((sItem, sIndex) => {
                const formField = (sItem.formField).filter(x => x.config?.required === "required").map((x) => {
                    const stateValueExist = defaultState[x.fieldName] ? true : false;
                    stateData[x.fieldName] = stateValueExist ? false : true;
                    errorStatus = errorStatus ? errorStatus : stateData[x.fieldName];
                });

            })
        })
        setErrorState(stateData);
        if (!errorStatus) onSubmit();
    }
    useImperativeHandle(ref, () => ({
        validateForm() {
            validateForm();
        }
    }));
    const [state, setState] = useState({
        firstName: "",
        lastName: "",
        phone: "",
        select: "",
        birthDay: "",
        cardNumber: "4444444444444444",
        email: "",
        password: "",
        repassword: "",
        website: "",
        switch: false,
        checkbox1: "",
        checkbox2: "",
        radio: "",
        range: {
            startDate: new Date(),
            endDate: (() => {
                let date = new Date();
                date.setDate(date.getDate() + 7);
                return date;
            })(),
        },
    });

    const handleSubmit = (values, { setSubmitting }) => {
        console.log(values);
    };

    const handleDateRangeChange = (range) => {
        let { startDate, endDate } = range;
        setState((prevState) => ({
            ...prevState,
            range: {
                startDate: startDate.toDate(),
                endDate: endDate.toDate(),
            },
        }));
    };

    // return (
    //     <Formik
    //         initialValues={state}
    //         validationSchema={basicFormSchema}
    //         onSubmit={handleSubmit}
    //     >
    //         {({
    //             values,
    //             errors,
    //             touched,
    //             handleChange,
    //             handleBlur,
    //             handleSubmit,
    //             isSubmitting,
    //         }) => (
    //             <>
    //                 {
    //                     dynamicForm.length > 0 && dynamicForm.map(el => {
    //                         const sectionData = el?.formBody || [];
    //                         //const columnCount = noOfColumn ? noOfColumn : sectionData?.length || 0;
    //                        // const colWidth = 12 / columnCount
    //                         return (
    //                             <tr>
    //                                 {
    //                                     sectionData.map(ele => {
    //                                         const formField = ele.formField?.length ? ele.formField : []
    //                                         return (
    //                                             // <td className={`col-md-${colWidth} form-group mb-3`}>
    //                                             <td>
    //                                             {/* <Grid item lg={colWidth} md={colWidth} sm={12} xs={12} > */}
    //                                                 {
    //                                                     formField.map(field => {
    //                                                         return (
    //                                                             formRender(defaultState, setDefaultState, field, onSearch, onChangeCallback, iconPress, index, layoutType, dataList, setDataList, errorState, setErrorState)
    //                                                         )
    //                                                     })
    //                                                 }
    //                                             {/* </Grid> */}
    //                                             </td>
    //                                         )
    //                                     })
    //                                 }
    //                             </tr>
    //                         )
    //                     })
    //                 }
    //             </>
    //         )}
    //     </Formik>
    // )
    return isField ? formRender(defaultState, setDefaultState, dynamicForm, onSearch, onChangeCallback, iconPress, index, layoutType, dataList, setDataList, errorState, setErrorState,parentIndex) 
    : (
        <Formik
            initialValues={state}
            validationSchema={basicFormSchema}
            onSubmit={handleSubmit}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
            }) => (
                <>
                    {
                        dynamicForm.length > 0 && dynamicForm.map((el, rowIndex) => {
                            const sectionData = el?.formBody || [];
                            return (
                                <tr key={rowIndex}>
                                    {
                                        sectionData.map((ele, colIndex) => {
                                            const formField = ele.formField?.length ? ele.formField : [];
                                            return (
                                                <td key={colIndex}>
                                                    {
                                                        formField.map((field, fieldIndex) => (
                                                            formRender(defaultState, setDefaultState, field, onSearch, onChangeCallback, iconPress, index, layoutType, dataList, setDataList, errorState, setErrorState)
                                                        ))
                                                    }
                                                </td>
                                            )
                                        })
                                    }
                                </tr>
                            )
                        })
                    }
                </>
            )}
        </Formik>
    )
    
});

const basicFormSchema = yup.object().shape({
    firstName: yup.string().required("first name is required"),
    lastName: yup.string().required("last name is required"),
    phone: yup.string().required("select any option"),
    select: yup.string().required("select any option"),
    birthDay: yup.string().required("birthDay is required"),
    cardNumber: yup
        .number()
        .test("card-validator", "Invlid card", (value) =>
            value && value.toString().length !== 16 ? false : true
        )
        .required("Card number is required"),
    email: yup.string().email("Invalid email").required("email is required"),
    checkbox1: yup.string().required("Required"),
    checkbox2: yup.string().required("Required"),
    switch: yup.string().required("Required"),
    // password: yup
    //   .string()
    //   .min(8, "Password must be 8 character long")
    //   .required("password is required"),
    // repassword: yup
    //   .string()
    //   .required("repeat password")
    //   .oneOf([yup.ref("password")], "Passwords must match")
});

export default TableLayout;
