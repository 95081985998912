import React, { useState } from 'react';
import { Eye, EyeOff } from 'lucide-react';
import './index.css';  // Make sure to import the CSS where Tailwind is set up

export function InputField({
  label,
  type: initialType,
  value,
  onChange,
  placeholder,
  error,
  onBlur
}) {
  const [focused, setFocused] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const type = initialType === 'password' && showPassword ? 'text' : initialType;

  return (
    <div className="mb-6 animate-fade-slide-up" style={{ '--delay': '0.2s' }}>
      <label className="block text-gray-700 text-sm font-medium mb-2 transition-colors duration-200 " style={{color:"gray",fontWeight:"bold"}} >
        {label}
      </label>
      <div className="relative group">
        <input
          type={type}
          value={value}
          onChange={onChange}
          onBlur={(e) => {
            setFocused(false);
            if (onBlur) onBlur();
          }}
          onFocus={() => setFocused(true)}
          placeholder={placeholder}
          className={`w-full px-4 py-3 border-2 rounded-xl transition-all duration-300
                      border-gray-300 // Ensure border is always visible
                      ${focused ? 'border-blue-400 ring-4 ring-blue-100' : ''} // Focused state styles
                      ${error ? 'border-red-500 ring-red-100' : ''} // Error state styles
                      bg-white/50 backdrop-blur-sm`} // Keep the background and blur
        />



        {initialType === 'password' && (
          <button
            type="button"
            onClick={() => setShowPassword(!showPassword)}
            className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-600 transition-colors duration-200"
          >
            {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
          </button>
        )}

        {error && (
          <div
            className="text-red-500 text-sm mt-2 transition-all duration-300 overflow-hidden flex items-center gap-2"
            style={{
              maxHeight: error ? '40px' : '0',
              opacity: error ? 1 : 0,
            }}
          >
            <span className="inline-block w-1 h-1 bg-red-500 rounded-full animate-pulse" />
            {error}
          </div>
        )}
      </div>
    </div>
  );
}
