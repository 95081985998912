
const createExamForm = [
    {
        title: "Basic Information",
        formBody: [
            {
                formField: [
                    {
                        label: "Batch Name",
                        inputType: "select",
                        options: "batchList",
                        uniqueKey: "batchId",
                        displayKey: "batchName",
                        multiple: false,
                        fieldName: "addBatchId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Exam Type",
                        inputType: "select",
                        options: "examType",
                        uniqueKey: "examTypeId",
                        displayKey: "examTypeName",
                        multiple: false,
                        fieldName: "addExamTypeId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }
                ]
            },
            {
                formField: [
                    {
                        label: "Class -Section",
                        inputType: "select",
                        options: "enrollmentDetailsList",
                        uniqueKey: "enrollmentId",
                        displayKey: "classTitle",
                        subDisplayKey: "sectionTitle",
                        multiple: false,
                        fieldName: "enrollmentIds",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Exam Name",
                        inputType: "text",
                        placeholder: "Enter Exam",
                        fieldName: "examName",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }
                ]
            },
            {
                formField: [
                    {
                        label: "Start Date",
                        inputType: "date",
                        fieldName: "addExamStartDate",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "End Date",
                        inputType: "date",
                        fieldName: "addExamEndDate",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },
            {
                formField: [
                    {
                        label: "Created By",
                        inputType: "select",
                        options: "employeeList",
                        uniqueKey: "employeeId",
                        displayKey: "employeeCode",
                        subDisplayKey: "employeeName",
                        fieldName: "createdBy",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    },
                    {
                        label: "Description",
                        inputType: "textarea",
                        fieldName: "addDescription",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },

                ]
            },
        ]
    }
];

const editExamForm = [
    {
        title: "Basic Information",
        formBody: [
            {
                formField: [
                    {
                        label: "Batch Name",
                        inputType: "select",
                        disable: true,
                        options: "batchList",
                        uniqueKey: "batchId",
                        displayKey: "batchName",
                        multiple: false,
                        fieldName: "addBatchId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Exam Type",
                        inputType: "select",
                        options: "examType",
                        uniqueKey: "examTypeId",
                        displayKey: "examTypeName",
                        multiple: false,
                        disable: true,
                        fieldName: "addExamTypeId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }
                ]
            },
            {
                formField: [
                    {
                        label: "Class -Section",
                        inputType: "select",
                        options: "enrollmentDetailsList",
                        uniqueKey: "enrollmentId",
                        displayKey: "classTitle",
                        subDisplayKey: "sectionTitle",
                        multiple: true,
                        disabled: true,
                        fieldName: "enrollmentIds",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Exam Name",
                        inputType: "text",
                        placeholder: "Enter Exam",
                        fieldName: "examName",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }
                ]
            },
            {
                formField: [
                    {
                        label: "Start Date",
                        inputType: "date",
                        fieldName: "addExamStartDate",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "End Date",
                        inputType: "date",
                        fieldName: "addExamEndDate",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },
            {
                formField: [
                    {
                        label: "Created By",
                        inputType: "select",
                        options: "employeeList",
                        uniqueKey: "employeeId",
                        disabled: true,
                        displayKey: "employeeCode",
                        subDisplayKey: "employeeName",
                        fieldName: "createdBy",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    },
                    {
                        label: "Description",
                        inputType: "textarea",
                        fieldName: "addDescription",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }
                ]
            },
        ]
    }
];

const examFilterForm = [
    {
        title: "Basic Information",
        formBody: [
            {
                formField: [
                    {
                        label: "Batch Name",
                        inputType: "select",
                        options: "batchList",
                        uniqueKey: "batchId",
                        displayKey: "batchName",
                        multiple: false,
                        fieldName: "batchName",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }
                ]
            },
            {
                formField: [
                    {
                        label: "Class-Section",
                        inputType: "select",
                        options: "enrollmentDetailsList",
                        uniqueKey: "enrollmentId",
                        displayKey: "classTitle",
                        subDisplayKey: "sectionTitle",
                        multiple: false,
                        fieldName: "className",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }
                ]
            },
            {
                formField: [
                    {
                        label: "Exam Type",
                        inputType: "select",
                        options: "examType",
                        uniqueKey: "examTypeId",
                        displayKey: "examTypeName",
                        multiple: false,
                        fieldName: "examTypeName",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    }
                ]
            },
            /* {
                formField: [
                    {
                        label: "Exam Variant",
                        inputType: "select",
                        options: "examVariantList",
                        uniqueKey: "examVariantId",
                        // onChange: "onHandleRadio",
                        displayKey: "examVariant",
                        fieldName: "examVariantId",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    }                                                                                   
                ]
            }, */


        ]
    }
];

const addItemForm = [
    {
        title: "Basic Information",
        formBody: [
            {
                formField: [

                    /* {
                            label: "",
                            inputType: "checkbox",
                            // onChange:"handleExam",
                            // options: "subjectList",
                            // uniqueKey:"subjectId",
                            // displayKey:"subjectTitle",
                            multiple:false,
                            fieldName:"checkData",
                            defaultValue: "",
                            config: {
                                required: "required",
                            }
                    } */
                ]
            },
            {
                formField: [

                    {
                        label: "",
                        inputType: "select",
                        placeholder: "Subject",
                        options: "subjectList",
                        uniqueKey: "subjectId",
                        displayKey: "subjectTitle",
                        fieldName: "subjectId",
                        fieldId: "subject_Id",
                        defaultValue: "",
                        config: {
                            required: "required",
                        }
                    }
                ]
            },
            {
                formField: [
                    {
                        label: "",
                        inputType: "date",
                        type: "date",
                        fieldName: "examDate",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }
                ]
            },
            {
                formField: [
                    {
                        label: "",
                        inputType: "time",
                        type: "time",
                        fieldName: "fromTime",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }
                ]
            },
            {
                formField: [
                    {
                        label: "",
                        inputType: "time",
                        type: "time",
                        fieldName: "toTime",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }
                ]
            },
            {
                formField: [
                    {
                        label: "",
                        inputType: "text",
                        type: "number",
                        fieldName: "minimumMark",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }
                ]
            },
            {
                formField: [
                    {
                        label: "",
                        inputType: "text",
                        type: "number",
                        fieldName: "maximumMark",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }
                ]
            },
            {
                formField: [
                    {
                        label: "",
                        inputType: "text",
                        type:"number",
                        fieldName: "examMark",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }
                ]
            },
            {
                formField: [
                    {
                        label: "",
                        inputType: "text",
                        type: "number",
                        fieldName: "internalMark",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }
                ]
            },
            {
                formField: [
                    {
                        label: "",
                        inputType: "text",
                        type: "number",
                        fieldName: "practicalMark",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }
                ]
            },

            {
                formField: [
                    {
                        label: "",
                        inputType: "icon",
                        defaultValue: "",
                        onClick: "onRemove",
                        config: {
                            required: "required",
                        },
                    }
                ]
            }
        ]
    }
];

const enterItem = [
    {
        title: "Basic Information",
        formBody: [
            {
                formField: [

                    /* {
                            label: "",
                            inputType: "checkbox",
                            // onChange:"handleExam",
                            // options: "subjectList",
                            // uniqueKey:"subjectId",
                            // displayKey:"subjectTitle",
                            multiple:false,
                            fieldName:"checkData",
                            defaultValue: "",
                            config: {
                                required: "required",
                            }
                    } */
                ]
            },
            {
                formField: [

                    {
                        label: "",
                        inputType: "select",
                        placeholder: "Subject",
                        options: "subjectList",
                        uniqueKey: "subjectId",
                        displayKey: "subjectTitle",
                        fieldName: "subjectId",
                        fieldId: "subject_Id",

                        defaultValue: "",
                        config: {
                            required: "required",
                        }
                    }
                ]
            },
            {
                formField: [
                    {
                        label: "",
                        inputType: "date",
                        type: "date",
                        fieldName: "examDate",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }
                ]
            },
            {
                formField: [
                    {
                        label: "",
                        inputType: "time",
                        type: "time",
                        fieldName: "fromTime",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }
                ]
            },
            {
                formField: [
                    {
                        label: "",
                        inputType: "time",
                        type: "time",
                        fieldName: "toTime",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }
                ]
            },
            {
                formField: [
                    {
                        label: "",
                        inputType: "text",
                        type: "number",
                        fieldName: "minimumMark",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }
                ]
            },
            {
                formField: [
                    {
                        label: "",
                        inputType: "text",
                        type: "number",
                        fieldName: "maximumMark",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }
                ]
            },
            {
                formField: [
                    {
                        label: "",
                        inputType: "text",
                        type:"number",
                        fieldName: "examMark",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }
                ]
            },
            {
                formField: [
                    {
                        label: "",
                        inputType: "",
                        // fieldName:"internalMark",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }
                ]
            },
            {
                formField: [
                    {
                        label: "",
                        inputType: "",
                        // fieldName:"practicalMark",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }
                ]
            },

            {
                formField: [
                    {
                        label: "",
                        inputType: "icon",
                        defaultValue: "",
                        onClick: "onRemove",
                        config: {
                            required: "required",
                        },
                    }
                ]
            }
        ]
    }
];

const statusForm = [
    {
        title: "Basic Information",
        formBody: [
            {
                formField: [
                    {
                        label: "Status",
                        inputType: "select",
                        placeholder: "Subject",
                        options: "examStatusList",
                        uniqueKey: "examStatusId",
                        displayKey: "examStatus",
                        multiple: false,
                        fieldName: "examStatusId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },
        ]
    }
];

export {
    createExamForm,
    examFilterForm,
    addItemForm,
    enterItem,
    statusForm,
    editExamForm
};