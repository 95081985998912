import * as yup from "yup";
import React, { Fragment } from "react";
import { Formik } from "formik";
import { useState } from "react";
import { Form, Button } from "react-bootstrap";
// import { CodeViewer, Breadcrumb, ModalView } from "@gull";
import Autocomplete from "react-autocomplete";
// import searchIcon from "../images/search-icon.svg";
import { DateRange } from "react-date-range";
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import "react-date-range/dist/styles.css";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io"
import "react-date-range/dist/theme/default.css";
import TimeKeeper from 'react-timekeeper';
import { FaSearch } from "react-icons/fa";
import { MdEditNote } from "react-icons/md";
// import DatePicker from "react-datepicker";

import { ReactSearchAutocomplete } from 'react-search-autocomplete'
// import { IoDocumentText } from "react-icons/io5";
import Multiselect from 'multiselect-react-dropdown';
import DatePicker from "react-datepicker";
import { TiArrowSortedDown } from 'react-icons/ti';

// import noProfileImage from "app/views/images/no_profile.png";
// import { convertDateFormat, getPrice, getStatusColor } from "./utils";
import { IoDocumentText } from "react-icons/io5";
import { MdCancel, MdMoreTime, MdOutlineHistory, MdDelete, MdAdd, MdSearch } from "react-icons/md";
// import { showMessage } from "./app-functions";
import moment from "moment";
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input'
// import SearchableInput from "app/components/searchableInput/SearchableInput";
// import { SearchResult } from "app/components/searchableInput/SearchResult";
// import { SearchResultsList } from "app/components/searchableInput/SearchResultList";
import { CiSaveDown1 } from "react-icons/ci";
import './formcomponent.css'
import Text from "../components/atoms/text-option/Text";
import SelectWithAnimation from "../components/atoms/select-option/Select";
import Input from "../components/atoms/input";
import SelectProps from "../components/atoms/select/props";
import Select from "../components/atoms/select";
import CustomTypeahead from "../components/atoms/typedropdown/CustomTypeahead";
import CheckboxGroup from "../components/atoms/checkbox-group";

let pickedInfo = "";
const formRender = (state, setState, info, onSearch, onChangeCallback, iconPress, index, subIndex, layoutType = "form", dataList, setDataList, errorState = {}, setErrorState, parentIndex, onIconClick) => {
    const checkErrors = Object.keys(errorState).length === 0 ? false : true;
    const { label, inputType, icon, fieldName, config, options, uniqueKey, displayKey, placeholder, multiple } = info;
    const props = {
        state,
        setState,
        info,
        onSearch,
        onChangeCallback,
        iconPress,
        index,
        subIndex,
        layoutType: "form",
        dataList,
        setDataList,
        errorState,
        setErrorState,
        parentIndex,
        onIconClick
    };
    const iconMap = {
        "MdSearch": MdSearch,
        "MdAdd": MdAdd,
        "MdMoreTime": MdMoreTime
    };
    const IconComponent = iconMap[icon];




    const removeErrors = (event, type) => {
        if (checkErrors) {
            const fieldName = event.target.name;
            let state = { ...errorState };
            //delete state[fieldName]; 
            state[fieldName] = false;
            setErrorState(state);
        }
    }

    const handleChange = (event, type) => {
        event.persist()
        const numberValue = event.target.value
        let temp_state = "";
        if (layoutType == "table") {

            temp_state = [...dataList]
            temp_state[index][event.target.name] = event.target.value;
        } else if (type === 'number') {
            if (numberValue.length < 11)
                temp_state = {
                    ...state,
                    [event.target.name]: event.target.value
                }
            else {
                temp_state = {
                    ...state,
                }
            }
        } else {
            temp_state = {
                ...state,
                [event.target.name]: event.target.value
            }
        }
        setState(temp_state)

    }

    const handleSelectChange = (event) => {
        setState({
            ...state,
            [info.fieldName]: event
        })

    }
    const selectChange = (event) => {
        setState({
            ...state,
            [info.fieldName]: event
        })

    }

    const handleFileChange = (event) => {
        const file = event.target.files[0]
        let temp_state = "";
        if (layoutType === "table") {
            temp_state = [...dataList]
            temp_state[index][event.target.name] = file
        } else {
            temp_state = {
                ...state,
                [event.target.name]: file
            }
        }
        setState(temp_state)
    }

    const onTime = (status) => {
        pickedInfo = info.fieldName;
        setState({ ...state, showTimePicker: status })
    }

    const handleTimeChange = (time, name) => {
        setState({
            ...state,
            [pickedInfo]: time.formatted24
        })
    }

    const handleDateChange = (stateName, date) => {
        let temp_state = "";
        if (layoutType === "table") {
            temp_state = [...dataList]
            temp_state[index][stateName] = date
        } else {
            temp_state = {
                ...state,
                [stateName]: date
            }
        }
        setState(temp_state)
    }


    const handleCheckbox = (event) => {
        event.persist();
        setState({
            ...state,
            [event.target.name]: event.target.checked,
        })
        /* setState({
            ...state,
            [stateName]: date
        }) */
        /* let updateObject = {
            ...state,
            [event.target.name]: event.target.checked,
        }
        if (dupicateFromField) {
            if (event.target.checked) {
                updateObject[dupicateToField] = state[dupicateFromField];
            } else {
                updateObject[dupicateToField] = "";
            }
        }
        setState(updateObject) */
    }
    const handleTypeChange = (val, idName, stateName) => {
        let temp_state = "";
        if (layoutType === "table") {
            temp_state = [...dataList]
            temp_state[index][stateName] = val
            setDataList(temp_state)
        } else {
            temp_state = {
                ...state,
                [stateName]: val
            }
            setState(temp_state)
        }

    }

    const handlePhone = (event) => {
        const inputValue = event.target.value;
        let spaceIndex = inputValue.indexOf(" ");
        let phoneNum = inputValue;
        if (spaceIndex !== -1) {
            phoneNum = inputValue.substring(spaceIndex + 1).replace(/[^\d]/g, '');
        }
        if (!isValidPhoneNumber(phoneNum)) {
            const fieldName = [info.fieldName];
            let state = { ...errorState };
            state[fieldName] = true;
            setErrorState(state);
        } else {
            const fieldName = [info.fieldName];
            let state = { ...errorState };
            state[fieldName] = false;
            setErrorState(state);
        }
    };

    const isValidPhoneNumber = (phoneNum) => {
        return phoneNum.length === 10;
    };

    const handleSub = () => {

    }

    const onSelectOption = (event, values, keyName, valueName, keyIds, callback) => {
        const existValues = state[keyName] ? state[keyName] : [];
        if (layoutType === "table") {
            let temp_state = [...dataList]
            //const existValue = temp_state[index][keyIds] ? temp_state[index][keyIds] : [];
            const existValueIds = temp_state[index][keyName] ? temp_state[index][keyName].split(',') : [];

            // temp_state[index][keyIds] = [...existValue,...[values]]
            temp_state[index][keyName] = [...existValueIds, ...[values[valueName]]].toString()
        }
        else {
            setState({
                ...state,
                [keyName]: [...existValues, ...[values]],
            })
        }
    }

    const onRemove = (selectedList, removedItem, keyName, valueName, keyIds, callback) => {
        if (layoutType === "table") {
            let temp_state = [...dataList];
            const removingId = removedItem[valueName];
            let existValueIds = temp_state[index][keyName] ? temp_state[index][keyName].split(',') : [];

            const idx = existValueIds.indexOf(removingId.toString());
            if (idx > -1) { existValueIds.splice(idx, 1); }
            //temp_state[index][keyName] = selectedList;
            temp_state[index][keyName] = existValueIds.toString()
        }
        else {
            const existValues = selectedList ? selectedList : [];
            setState({
                ...state,
                [keyName]: existValues,
            })
        }
    }
    /* 
        const handleEmail = (event) => {
            const inputValue = event.target.value
            let emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
            if (inputValue.length) {
                if (!emailRegex.test(inputValue)) {
                    showMessage("warning", "Invalid email.");
                }
            }
        }
     */

    const selectedImage = (img, index) => {
        /* let temp_state = [...itemDetails];
        // 2. Make a shallow copy of the element you want to mutate
        let temp_element = { ...temp_state[index] };
        if (img) {
            const imageName = generateSerial() + ".jpg";
            const blobFile = dataURLtoFile(img, imageName)
            // 3. Update the property you're interested in
            temp_element['imageName'] = imageName;
            temp_element['image'] = blobFile;
            temp_element['imageStatus'] = false;
        }
    
        // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
        temp_state[index] = temp_element;
        // 5. Set the state to our new copy
        updateState(false, itemIndex, temp_state); */
    }

    const getUserStatusClass = (status) => {

        switch (status) {

            case "active":

                return "bg-success";

            case "inactive":

                return "bg-warning";

            case "pending":

                return "bg-primary";

            default:

                break;

        }

    };

    let custStyle = "";
    const boldStyle = info?.weight === "bold" ? custStyle += "t-font-boldest" : ""
    const positionStyle = info?.position === "right" ? custStyle += "d-flex justify-content-end " : "d-flex justify-content-start"

    switch (info.inputType) {
        case 'line':
            return (
                <hr key={index} style={{ borderTop: '2px solid red' }} />
            )
            break;
        case 'space':
            return (
                <div key={index} className="col-md-12 form-group mb-3" style={{ minHeight: `${info?.height}px` || '57px' }} />
            )
            break;
        case 'header':
            // code block  

            const headingPosition = info?.position === "right" ? "d-flex justify-content-end " : "d-flex justify-content-start"
            return (
                <div key={index} className={headingPosition}>
                    <span className="ul-widget4__number t-font-boldest">
                        {info.label || ''}
                    </span>
                </div>
            )
            break;
        case 'icon-table':
            //code block
            const icon_state = layoutType === "table" ? dataList[index] : state;
            const key = info.index ? [info.index] : icon_state[info.fieldName];
            return (
                <div key={index} className='d-flex justify-content-center mt-1'
                >
                    {key == 'file' ? (<IoDocumentText className="text-info cursor-pointer" size={24} textalign="center" />)
                        : (key == 'outline-clock' ? (<MdOutlineHistory onClick={() => { iconPress[info.onClick](index) }} className="text-danger cursor-pointer" size={24} textalign="center" />) :
                            key == 'save' ? (<CiSaveDown1 onClick={() => { iconPress[info.onClick](index) }} className="text-danger cursor-pointer" size={24} textalign="center" />) :
                                (key == 'delete' ? (<span style={{ marginTop: `${info?.onStyle || '0px'}`, marginRight: `${info?.onRight || '0px'}` }}><MdDelete className="text-danger cursor-pointer " size={24} onClick={() => { iconPress[info.onClick](index, parentIndex) }} /></span>) :
                                    (key == 'add' ? (<MdAdd className="text-info cursor-pointer" size={24} textalign="center" onClick={() => { iconPress[info.onClick](index) }} />) : null))
                        )}
                </div>)
            break

        // code block
        /*  const key2 = [info.index]
         return (
             <div key={index}  className='d-flex justify-content-center' style={{ alignItems: 'center' }}>
                 {key2 == 'add' ? (<MdAdd className="text-info cursor-pointer" size={24} textalign="center" />)
                     : (key2 == 'outline-clock' ? (<MdOutlineHistory className="text-danger cursor-pointer" size={24} textalign="center" />)
                         : (key2 == 'delete' ? <span style={{ marginLeft: '-150px' }}><MdDelete className="text-danger cursor-pointer" size={24} onClick={() => { onSearch("delete") }} /></span> : null))}</div>
         )
         break; */


        case 'label-row':

            // const custStyle = info?.style ? info.style : {}            

            const position = info?.position === "right" ? "justify-content-end" : "justify-content-start"

            return (
                <div key={index} className="row mb-lg-2" >
                    <div className="col-lg-6">
                        <div className="row">
                            <div className="col-lg-10">
                                <span className="t-font-boldest">
                                    {info?.label}
                                </span>
                            </div>
                            <div className="col-lg-2">
                                <span className="t-font-boldest">
                                    :
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <span className={`d-flex ${position} `} >
                            {state[info.fieldName] || 'sdd'}
                        </span>
                    </div>
                </div>
            )

            break;

        /*  case 'text-test':
 
         return (
             <FieldWrapper>
               <div className={"k-form-field-wrap"}>
                 <FloatingLabel
                   label={label}
                   editorValue={value}
                   editorValid={valid}
                   editorId={id}
                 >
                   <Input
                     value={value}
                     valid={valid}
                     type={"email"}
                     id={id}
                     {...others}
                   />
                 </FloatingLabel>
                 {!showValidationMessage && (
                   <Hint>Enter your personal email address.</Hint>
                 )}
                 {showValidationMessage && <Error>{validationMessage}</Error>}
               </div>
             </FieldWrapper>
           );
 
             break;
  */

        case 'sideArrow':
            const viewField = layoutType === "table" ? dataList[index] : state;
            return (
                <>
                    <td key={index} >
                        <button onClick={() => iconPress(index)} className={`btn toggle-btn ${viewField ? 'rotate' : ''}`} >
                            <IoIosArrowDown />
                        </button>
                    </td>
                </>
            )
            break;

        case 'label':

            return (
                <div key={index} className={custStyle}>
                    <span className="ul-widget4__number">
                        {state[info.fieldName] || ''}
                    </span>
                </div>
            )
            break;

        case 'number':
            const numberField = layoutType === "table" ? dataList[index] : state;
            return (
                <div key={index} className="col-md-12 form-group mb-3">
                    {
                        info.label && (
                            <label htmlFor="firstName">{info.label || "text area"}{info.config.required ? "*" : ""}</label>
                        )
                    }
                    <input
                        type='number'
                        id={info.fieldName}
                        className="form-control"
                        name={info.fieldName}
                        onChange={(event) => handleChange(event, info.inputType)}
                        placeholder={info.placeholder || ""}
                        onFocus={removeErrors}
                        value={numberField[info.fieldName] || ''} />
                    {checkErrors && errorState[info.fieldName] && (
                        <div className="text-danger mt-1 ml-2">
                            {info.errorMsg || "* required"}
                        </div>
                    )}
                </div>
            )
            break;
        case 'inlineInput':
            // code block
            return (<div className="container">
                <div key={index} className="row">
                    <div className="col-12">
                        <div className="input-group">
                            <input className="form-control border-secondary py-2" type="search" value="search" />
                            <div className="input-group-append">
                                <button className="btn btn-outline-secondary" type="button">
                                    <i className="fa fa-search" aria-hidden="true"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>)
            break;

        case 'imageUploader':
            const imageUploaderField = layoutType === "table" ? dataList[index] : state;
            return (
                <div key={index} className="col-md-12 form-group mb-4">
                    {
                        info.label && (
                            <label htmlFor="firstName"><b>{info.label || "text area"}<span style={{ color: "red" }}>{info.config.required ? "*" : ""}</span></b></label>
                        )
                    }
                    <div className="input-right-icon">
                        <img src={imageUploaderField.profilePic} width="54%" onClick={() => { onSearch() }}></img>
                        <div className="d-flex justify-content-start" >
                            <i className="ul-form__icon i-Upload" style={{ fontSize: "18px" }} onClick={() => { onSearch() }}></i>
                        </div>


                        {checkErrors && errorState[info.fieldName] && (
                            <div className="text-danger mt-1 ml-2">
                                {info.errorMsg || "* required"}
                            </div>
                        )}
                        {/* <span className="span-right-input-icon" name={info.fieldName} onClick={() => { onSearch() }} >
                                <i className="ul-form__icon i-Magnifi-Glass1"></i>
                            </span> */}
                    </div>
                </div>
            )
            break;

        case 'searchtext':
            const textSearchField = layoutType === "table" ? dataList[index] : state;
            return (
                <div key={index} className="col-md-12 form-group mb-3">
                    {
                        info.label && (
                            <label htmlFor="firstName">{info.label || "text area"}<span>{info.config.required ? "*" : ""}</span></label>
                        )}
                    <div className="input-right-icon">
                        <input
                            // id="firstName"
                            id={info.fieldName}
                            name={info.fieldName}
                            className="form-control"
                            placeholder={info.placeholder || ""}
                            type={info.type || "text"}
                            onChange={typeof info.onChange === 'function' ? info.onChange : handleChange}

                            disabled={info.disable ? true : false}
                            // onBlur={handleBlur}
                            onFocus={removeErrors}
                            value={textSearchField[info.fieldName] || ''}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    onSearch(info.fieldName);
                                }
                            }}
                        />
                        {checkErrors && errorState[info.fieldName] && (
                            <div className="text-danger mt-1 ml-2">
                                {info.errorMsg || "* required"}
                            </div>
                        )}
                        <span className="span-right-input-icon" name={info.fieldName} onClick={() => { onSearch(info.fieldName) }} >
                            <i className={`ul-form__icon ${info?.icon || "i-Magnifi-Glass1"}`}></i>
                        </span>
                    </div>
                </div>
            )
            break;

        case 'typedropdown':
            const optionsList = state[info.options] || [];
            const keyField = info.uniqueKey || "id";
            const displayField = info.displayKey || "name";
            const typeDropdownValidate = layoutType === "table" ? dataList[index] : state;

            return (
                <div key={index} className="col-md-12 form-group mb-3">
                    {
                        info.label && (
                            <label htmlFor={keyField} class="label-color">{info.label || "text area"}{info.config.required ? "*" : ""}</label>
                        )
                    }
                    <div >
                        <CustomTypeahead info={info} optionsList={optionsList} typeDropdownValidate={typeDropdownValidate}
                            onChangeCallback={onChangeCallback} handleTypeChange={handleTypeChange} style={{ width: '100%' }}
                        />


                        {IconComponent && (
                            <IconComponent
                                onClick={() => { iconPress[info.onClick](index) }}
                                style={{
                                    position: 'absolute',
                                    right: '10px',
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    cursor: 'pointer',
                                    fontSize: '20px',
                                }}
                            />

                        )}
                    </div>

                    {info.icon ? (<span className="span-right-input-icon" name={info.fieldName} onClick={() => { onSearch() }} style={{ marginTop: info?.classStyle || '' }}>
                        <i className={`ul-form__icon ${info?.icon || "i-Magnifi-Glass1"}`}></i>
                    </span>) : ""}
                    {checkErrors && errorState[info.fieldName] && (
                        <div className="text-danger mt-1 ml-2">
                            {info.errorMsg || "* required"}
                        </div>
                    )}
                </div>

            )
            break;
        case 'textarea':
            // code block
            const textAreaValue = layoutType === "table" ? dataList[index] : state;
            return (
                <div key={index} className="col-md-12 form-group mb-3">
                    {
                        info.label && (
                            <label htmlFor="firstName" class="label-color">{info.label || "text area"}{info.config.required ? "*" : ""}</label>
                        )
                    }

                    <Input
                        type="textarea"
                        design="inv-textarea-design" // Example class, adjust as needed
                        placeholder="Type your message here"
                        rows={3}
                        style={{ marginBottom: '8px' }}
                    />

                </div>
            )
            break;
        case 'select-new':
            // code block
            const selectValue = layoutType === "table" ? dataList[index] : state[info.fieldName];
            const newoptionList = state[info.options] || [];
            const uniqueValKey = info.uniqueKey || "id";
            const displayValKey = info.displayKey || "name";
            return (
                <div key={index} className="col-md-12 form-group mb-3">
                    {info.label && (
                        <label htmlFor="firstName" className="label-color" >
                            {info.label || "text area"}{info.config.required ? "*" : ""}
                        </label>
                    )}
                    {/* <br /> */}
                    <div><Select
                        options={newoptionList}
                        optionValKey={uniqueValKey}
                        optionLabelKey={displayValKey}
                        value={selectValue}
                        onChange={selectChange} // Pass a handler for the onChange event
                        placeholder="Please select an option"
                        size="md" // You can use 'sm', 'md', 'lg', etc. based on your prop definitions
                        position="left" // Options: 'center', 'left', 'right'
                        style={{ marginBottom: '8px' }} // Add margin above Select
                    /></div>

                </div>

            )
            break;
        /* case 'textarea':
            // code block
            const textAreaValue = layoutType === "table" ? dataList[index] : state;
            return (
                <div key={index} className="col-md-12 form-group mb-3">
                    {
                        info.label && (
                            <label htmlFor="firstName">{info.label || "text area"}{info.config.required ? "*" : ""}</label>
                        )
                    }

                    <textarea
                        id={info.fieldName}
                        name={info.fieldName}
                        className="form-control"
                        aria-label="With textarea"
                        placeholder={info.placeholder || ""}
                        type="textarea"
                        key={index}
                        onChange={handleChange}
                        onFocus={removeErrors}
                        // onBlur={handleBlur}
                        value={textAreaValue[info.fieldName] || ''}
                    />
                    {checkErrors && errorState[info.fieldName] && (
                        <div className="text-danger mt-1 ml-2">
                            {info.errorMsg || "* required"}
                        </div>
                    )}
                </div>
            )
            break; */
        case 'switch':
            return (
                <div key={index} className="col-md-12 form-group mb-3">
                    {
                        info.label && (
                            <label htmlFor="firstName">{info.label || "text area"}{info.config.required ? "*" : ""}</label>
                        )
                    }
                    <Form.Check
                        type="switch"
                        name={info.fieldName}
                        checked={state[info.fieldName]}
                        onChange={handleCheckbox}
                        id="custom-switch 5"
                    />
                </div>
            )
            break;

        case 'checkbox':
            const selectedCheckbox = layoutType === "table" ? dataList[index][info.fieldName] || '' : state[info.fieldName] || '';
            return (
                <Fragment key={index}>
                    <br></br>
                    <br></br>
                    <label className="checkbox checkbox-primary" >

                        <input
                            // style={{marginTop:"20px" ,marginLeft:'10px'}}
                            type="checkbox"
                            name={info.fieldName}
                            checked={info?.status || selectedCheckbox}
                            key={index}
                            onChange={(event) => {
                                if (typeof iconPress === 'function') {
                                    iconPress(event, index, state);
                                } else {
                                    handleCheckbox(event, info.fieldName);
                                }
                            }}
                        />

                        <span> {info.label || ""}</span>
                        <span className="checkmark"></span>
                    </label>
                    {checkErrors && errorState[info.fieldName] && (
                        <div className="text-danger mt-1 ml-2">
                            {info.errorMsg || "* required"}
                        </div>
                    )}</Fragment>
            )
            // code block
            /* return <FormControlLabel
                control={<Checkbox name={info.fieldName} checked={state[info.fieldName]} onChange={(e) => handleCheckbox(e, info.dupicateFromField, info.dupicateToField)} />}
                label={info.label || "checkbox"}
            /> */
            break;
        case 'radio':
            // code block 
            const opsList = state[info.options] || [];
            const unqKey = info.uniqueKey || "id";
            const dispKey = info.displayKey || "name";
            return (
                <div key={index} className="col-md-6 form-group mb-3">
                    {
                        info.label && (
                            <label htmlFor="firstName">{info.label || "text area"}{info.config.required ? "*" : ""}</label>
                        )
                    }
                    <div className="ul-form__radio-inline">  {/* col-md-6 form-group mb-1 */}
                        {
                            opsList.length && (
                                opsList.map((item, index) => (
                                    <label key={index} className=" ul-radio__position radio radio-primary form-check-inline">
                                        <input
                                            checked={state[info.fieldName] == item[unqKey]}
                                            key={`sel_${index}`}
                                            onFocus={removeErrors}
                                            onChange={(event) => info.onChange ? onChangeCallback?.[info.onChange](event, index) : handleChange(event)}
                                            type="radio"
                                            name={info.fieldName}
                                            value={item[unqKey]}

                                        />
                                        <label className="ul-form__radio-font">
                                            {item[dispKey]}
                                        </label>
                                        <span className="checkmark"></span>
                                    </label>
                                ))
                            )
                        }
                    </div>
                    {checkErrors && errorState[info.fieldName] && (
                        <div className="text-danger mt-1 ml-2">
                            {info.errorMsg || "* required"}
                        </div>
                    )}
                </div>
            )
            break;

        case 'icon':
            // code block
            return (
                <div key={index} className='mt-1'>
                    <MdCancel className="text-danger cursor-pointer" size={24} textalign="center" onClick={() => { /* iconPress("cancel") */ iconPress[info.onClick](index) }}
                    ></MdCancel>
                    <MdOutlineHistory className="text-danger cursor-pointer" size={24} textalign="center" onClick={() => { iconPress("cancel") }}
                    ></MdOutlineHistory>
                </div>




            )
            break;

        /*  case 'icon-table':
             //code block
             const icon_state = layoutType === "table" ? dataList[index] : state;
             const key = info.index ? [info.index] : icon_state[info.fieldName];            return (
                 <div className='d-flex justify-content-center mt-1' >
                     {key == 'file' ? (<IoDocumentText className="text-info cursor-pointer" size={24} textalign="center" />)
                         : (key == 'outline-clock' ? (<MdOutlineHistory onClick={() => { iconPress[info.onClick](index) }} className="text-danger cursor-pointer" size={24} textalign="center" />) :
                             (key == 'delete' ? (<span style={{ marginTop: `${info?.onStyle || '0px'}`, marginRight: `${info?.onRight|| '0px'}` }}><MdDelete className="text-danger cursor-pointer " size={24} onClick={() => { iconPress[info.onClick](index) }} /></span>) :
                                 (key == 'add' ? (<MdAdd className="text-info cursor-pointer" size={24} textalign="center" onClick={() => { iconPress[info.onClick](index) }} />) : null))
                         )}
                 </div>)
             break */
        case 'file':
            // code block
            return (
                <div key={index} className="col-md-12 form-group mb-3">
                    {
                        info.label && (
                            <label htmlFor="firstName">{info.label || "text area"}{info.config.required ? "*" : ""}</label>
                        )
                    }
                    <input
                        type="file"
                        name={info.fieldName}
                        className="form-control"
                        onChange={handleFileChange}
                        onFocus={removeErrors}
                        id="inputGroupFile01"
                    />
                    {checkErrors && errorState[info.fieldName] && (
                        <div className="text-danger mt-1 ml-2">
                            {info.errorMsg || "* required"}
                        </div>
                    )}
                </div>
            )
            /*  */
            break;
        case 'image':
            // code block            

            // return <ChooseImage selectedFile={itemRow.image} initialImage={imagePath + itemRow.imageName} index={index} onDrop={selectedImage} onProcess={onProcess} />            
            break;
        case 'multiselect':
            const multOptionList = state[info.options] || [];
            return (
                <div key={index} className="col-md-12 form-group mb-3">
                    {
                        info.label && (
                            <label htmlFor="firstName">{info.label || "text area"}{info.config.required ? "*" : ""}</label>
                        )
                    }
                    <Multiselect
                        options={multOptionList} // Options to display in the dropdown
                        singleSelect={false}
                        style={{ chips: { background: "rgba(var(--bs-primary-rgb))" }, searchBox: { maxHeight: "40px", overflow: "scroll", overflowX: "hidden" }, inputField: { maxHeight: "40px" } }}
                        selectedValues={state[info.fieldName] || ''}
                        key={index}
                        onSelect={(event, values) => onSelectOption(event, values, info.fieldName, info.uniqueKey, info.keyIds)}
                        onRemove={(selectedList, removedItem) => onRemove(selectedList, removedItem, info.fieldName, info.uniqueKey, info.keyIds)}
                        displayValue={info.displayKey}
                        onFocus={removeErrors}
                    />
                    {checkErrors && errorState[info.fieldName] && (
                        <div className="text-danger mt-1 ml-2">
                            {info?.errorMsg || '* required'}
                        </div>
                    )}
                </div>
            )
            break;
        case 'select':
            const optionList = state[info.options] || [];
            const uniqueKey = info.uniqueKey || "id";
            const displayKey = info.displayKey || "name";
            let serviceTypeArr = [
            ]

            const selectedValue = layoutType === "table" ? dataList[index][info.fieldName] || '' : state[info.fieldName] || '';
            return (
                <div key={index} className="col-md-12 form-group mb-3">
                    {
                        info.label && (
                            <label htmlFor="firstName" class="label-color">{info.label || "text area"}{info.config.required ? "*" : ""}</label>
                        )
                    }
                    <div style={{ position: 'relative' }} /* className="custom-dropdown" */>
                        <SelectWithAnimation
                            options={optionList}
                            selectedValue={selectedValue}
                            onSelect={handleSelectChange}
                            /* handleOptionSelect={handleChange} */ />
                        {/* {isDefaultSelected && <span className="custom-arrow">&#9662;</span>} */}
                    </div>


                    {IconComponent && (
                        <IconComponent
                            onClick={(key) => onSearch()}
                            style={{
                                position: 'absolute',
                                right: '10px',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                cursor: 'pointer',
                                fontSize: '20px',
                            }}
                        />

                    )}

                    {checkErrors && errorState[info.fieldName] && (
                        <div className="text-danger mt-1 ml-2">
                            {info.errorMsg || "* required"}
                        </div>
                    )}
                </div>
            )
            break;
        case 'selectsearch':
            /* const optionList = state[info.options] || [];
            const uniqueKey = info.uniqueKey || "id";
            const displayKey = info.displayKey || "name";
            let serviceTypeArr = []
            if (info?.multiple) {
                serviceTypeArr = [];
            }
     
            const selectedItem = multiple ? optionList.filter((item, index) => serviceTypeArr.indexOf(item) == index) || null
                : optionList.find(v => v?.[uniqueKey]?.toString() === state[info.fieldName]?.toString());
            const multiple = info?.multiple || false */


            const items = [
                {
                    id: 0,
                    name: 'Cobol'
                },
                {
                    id: 1,
                    name: 'JavaScript'
                },
                {
                    id: 2,
                    name: 'Basic'
                },
                {
                    id: 3,
                    name: 'PHP'
                },
                {
                    id: 4,
                    name: 'Java'
                }
            ]

            const handleOnSearch = (string, results) => {
                // onSearch will have as the first callback parameter
                // the string searched and for the second the results.
            }

            const handleOnHover = (result) => {
                // the item hovered
            }

            const handleOnSelect = (item) => {
                // the item selected
            }

            const handleOnFocus = () => {
            }

            const formatResult = (item) => {
                return (
                    <>
                        <span style={{ display: 'block', textalign: 'left' }}>id: {item.id}</span>
                        <span style={{ display: 'block', textalign: 'left' }}>name: {item.name}</span>
                    </>
                )
            }

            return (
                <div key={index} style={{ margin: info.margin }}>
                    {
                        info.label && (
                            <label htmlFor="email">{info.label || "text area"}{info.config.required ? "*" : ""}</label>
                        )
                    }
                    <div >
                        <ReactSearchAutocomplete

                            items={items}

                            onSearch={handleOnSearch}
                            onHover={handleOnHover}
                            onSelect={handleOnSelect}
                            onFocus={handleOnFocus}
                            autoFocus
                            formatResult={formatResult}
                        />
                    </div>
                </div>
            )
            break;
        case 'email':
            const emailValueFiled = layoutType === "table" ? dataList[index] : state;
            return (
                <div key={index} className="col-md-12 form-group mb-3">
                    {
                        info.label && (
                            <label htmlFor="email">{info.label || "text area"}{info.config.required ? "*" : ""}</label>
                        )
                    }
                    <input
                        name={info.fieldName}
                        id={info.fieldName}
                        className="form-control"
                        placeholder={info.placeholder || ""}
                        onChange={handleChange}
                        type="email"
                        onFocus={removeErrors}
                        value={emailValueFiled[info.fieldName] || ''}
                    />
                    {checkErrors && errorState[info.fieldName] && (
                        <div className="text-danger mt-1 ml-2">
                            {info.errorMsg || "* required"}
                        </div>
                    )}
                </div>
            )

            // return <ChooseImage selectedFile={itemRow.image} initialImage={imagePath + itemRow.imageName} index={index} onDrop={selectedImage} onProcess={onProcess} />            
            break;

        case 'button':
            return (
                <Button key={index} className={info?.classStyle || ""} style={{ margin: info.margin, borderRadius: info.borderRadius }} variant="primary" onClick={() => onSearch()}>
                    {info?.label || "Save Changes"}
                </Button>
            )

            // return <ChooseImage selectedFile={itemRow.image} initialImage={imagePath + itemRow.imageName} index={index} onDrop={selectedImage} onProcess={onProcess} />            
            break;

        case 'card':
            return (
                <div key={index} className="card" style={{ width: info.width, height: "info.height", margin: info.margin }}>
                    <div className="card-body">
                        <h5 className="card-title">Card title</h5>
                        <h6 className="card-subtitle mb-2 text-muted">Card subtitle</h6>
                        <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                        <a href="#" className="card-link">Card link</a>
                        <a href="#" className="card-link">Another link</a>
                    </div>
                </div>
            )

            // return <ChooseImage selectedFile={itemRow.image} initialImage={imagePath + itemRow.imageName} index={index} onDrop={selectedImage} onProcess={onProcess} />            
            break;
        case 'apply':
            return (
                <div key={index} >
                    <Button className="btn btn-success   onClick={() => { iconPress[info.onClick](index) }}">
                        {info?.label || "Save Changes"}
                    </Button>
                </div>
            )

            break;
        case 'phonenumber':
            // code block
            const phoneNum = layoutType === "table" ? dataList[index] : state;
            return (
                <div key={index} className="col-md-12 form-group mb-3">
                    {
                        info.label && (
                            <label htmlFor="firstName" style={{ color: info.color ? info.color : "black" }}>{info.label || "text area"}{info.config.required ? "*" : ""}</label>
                        )
                    }
                    <PhoneInput
                        placeholder="Enter phone number"
                        // className="form-control"
                        // defaultCountry="US"
                        value={phoneNum[info.fieldName]}
                        onChange={handleTypeChange}
                        onBlur={handlePhone}
                        onFocus={removeErrors}
                        defaultCountry="IN" />
                    {/* // error={phoneNum[info.fieldName] ? (isValidPhoneNumber(phoneNum[info.fieldName]) ? undefined : showMessage('warning', 'Invalid phone number')) : showMessage('danger', 'Phone number required')}  */}
                    {checkErrors && errorState[info.fieldName] && (
                        <div className="text-danger mt-1 ml-2">
                            {info.errorMsg || "* required"}
                        </div>
                    )}
                </div>
            )
            break;

        case 'text':
            // code block
            const valueField = layoutType === "table" ? dataList[index] : state;
            return (
                <div key={index} className="col-md-12 form-group mb-3">
                    {info.label && (
                        <label
                            htmlFor="firstName"
                            className="label-color"
                            style={{ marginBottom: '8px' }}
                        >
                            {info.label || "text area"}{info.config.required ? "*" : ""}
                        </label>
                    )}
                    <Text
                        info={info}
                        onChangeCallback={onChangeCallback}
                        index={index}
                        valueField={valueField}
                        handleChange={handleChange}
                        removeErrors={removeErrors}
                        checkErrors={checkErrors}
                        errorState={errorState}
                    />
                </div>

            )
            /*const emailValueFiled = layoutType === "table" ? dataList[index] : state;
             return (
                <div className="col-md-12 form-group mb-3">
                    {
                        info.label && (
                            <label htmlFor="email">{info.label || "text area"}</label>
                        )
                    }
                    <input
                        name={info.fieldName}
                        id={info.fieldName}
                        className="form-control"
                        placeholder={info.placeholder || ""}
                        type="email"
                    //onChange={handleChange}
                    //onBlur={handleBlur}
                    // value={values.email}
                    />
                    {/* {errors.email && touched.email && (
                      <div className="text-danger mt-1 ml-2">
                        {errors.email}
                      </div>
                    )}
                    <small id="emailHelp" className="form-text text-muted">
                      We'll never share your email with anyone else.
                    </small>
                    </div>
                    ) */
            break;

        case 'status':
            return (
                <span key={index} className={`badge ${getUserStatusClass(info.status ? "active" : "inactive")}`}>
                    {info.status ? info?.succesName || "Active" : info?.failureName || "InActive"}
                </span>
            )
            break;

        default:
        // code block
    }

    /* switch (info.inputType) {
        case 'header':
            // code block
            return <CardTitle >{info?.label || ""}</CardTitle>
            break;
        case 'label':
            // code block
            return <DatePicker format={info?.format || "DD/MM/YYYY"} value={state[info?.fieldName] || new Date()} label={info?.label || "Date"} onChange={handleDateChange} fieldName={info?.fieldName} />
            break;
        case 'date':
            // code block
            return <DatePicker format={info?.format || "DD/MM/YYYY"} value={state[info?.fieldName] || new Date()} label={info?.label || "Date"} onChange={handleDateChange} fieldName={info?.fieldName} />
            break;
        case 'text':
            // code block
            return <TextField
                type="text"
                size="small"
                name={info.fieldName}
                // onChange={handleChange}
                onChange={(event) => info.onChange ? onChangeCallback?.[info.onChange](event,index) : handleChange(event)}
                value={state[info.fieldName] || ''}                
                label={info.label || "input text"}
                errorMessages={['this field is required']}
            />
            break;
            
        case 'textarea':
            // code block
            return <TextAreaField
                size="small"
                label={info.label || "text area"}
                name={info.fieldName}
                value={state[info.fieldName] || ''}
                onChange={handleChange}
                // onChange={(e) => handleChange("address", e)}
                // error={"errorObj.address"}
                placeholder=""
            // errorMsg="Please enter address."
            />
            break;
        case 'checkbox':
            // code block
            return <FormControlLabel
                control={<Checkbox name={info.fieldName} checked={state[info.fieldName]} onChange={(e) => handleCheckbox(e, info.dupicateFromField, info.dupicateToField)} />}
                label={info.label || "checkbox"}
            />
            break;
        case 'radio':
            // code block
             <Form.Check
                              type="radio"
                              label="first radio"
                              name="radio"
                              id="formHorizontalRadios1"
                              value="radio1"
                            />
            return <RowRadioButtonsGroup name={info.fieldName} value={state[info.fieldName]} title={info.label} items={info.options} handleChange={handleChange} />
            break;
        case 'file':
            // code block
            return (
                <>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <ChooseFile />
                    </Box>
                    {
                        info.label && (
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Typography align="center" variant="subtitle1" >{info.label}</Typography>
                            </Box>
                        )
                    }
                    

                </>
            )
            break;
        case 'image':
            // code block            
            return (
                <>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        
                        <ChooseImage onDrop={selectedImage} />
                    </Box>
                    {
                        info.label && (
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Typography align="center" variant="subtitle1" >{info.label}</Typography>
                            </Box>
                        )
                    }
                    

                </>
            )
            // return <ChooseImage selectedFile={itemRow.image} initialImage={imagePath + itemRow.imageName} index={index} onDrop={selectedImage} onProcess={onProcess} />            
            break;
        case 'select':
            const optionList = state[info.options] || [];
            const uniqueKey = info.uniqueKey || "id";
            const displayKey = info.displayKey || "name";
            // alert(info.fieldName+"---"+state[info.fieldName])
            let serviceTypeArr = []
            if (info?.multiple) {
                serviceTypeArr = [];//state[info.fieldName] ? state[info.fieldName].split(",") : []
            }

            // const toFindDuplicates = optionList.filter((item, index) => state[info.fieldName].indexOf(item) !== index)
            const selectedItem = multiple ? optionList.filter((item, index) => serviceTypeArr.indexOf(item) == index) || null
                : optionList.find(v => v?.[uniqueKey]?.toString() === state[info.fieldName]?.toString());
            // alert(JSON.stringify(selectedItem))
            const multiple = info?.multiple || false
            return <AutoComplete
                options={optionList}
                disableClearable
                multiple={multiple}
                name={info.fieldName}
                getOptionLabel={(option) => option[displayKey]}
                onChange={(event, values) => onSelectOption(event, values, info.fieldName, info.uniqueKey, multiple, info?.subItem)}
                // value={selectedItem || (multiple ? [] : null)}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        size="small"
                        label={info.label || "options"}
                        variant="outlined"
                        fullWidth
                    />
                )}
                required={true}
                validators={[
                    'required'
                ]}
                errorMessages={['this field is required']}

            />
            break;
        default:
        // code block
    } */
}

export default formRender;