import React, { useEffect, useState } from 'react';
import { ConfigProvider, DatePicker as AntdDatePicker } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import './styles.css';
import { useTranslation } from 'react-i18next';
import TimePicker from '../time-picker/index';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import ta from 'antd/es/date-picker/locale/ta_IN';
import ta_IN from 'antd/es/locale/ta_IN';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
// import TimePickerLocale from '../../time-picker/locale/ta_IN';
// import { PickerLocale } from '../generatePicker';

dayjs.extend(weekday);
dayjs.extend(localeData);
const { RangePicker: AntdRangePicker } = AntdDatePicker;
const classNames = require('classnames');

const DatePicker = ({ value, onChange, range, name, size, info, picker, drop, visible, open, allowClear, staticPopup, disabled, showTime, posLeft, placeholder }) => {
    const [t] = useTranslation();
    const [reRender, setReRender] = useState(false);
    const [isOpenStatus, setIsOpenStatus] = useState(null);

 
    const className = classNames({
        'ax-date-picker': true,
        [size]: size,
        [visible]: visible,
    });

    useEffect(() => {
        if (reRender) {
            setReRender(false);
        }
    }, [reRender]);


    let timeProps = showTime ? { format: 'YYYY-MM-DD HH:mm' } : {};
    const format = 'HH:mm';
    const staticPopupTrigger = trigger => trigger.parentNode;

    function handleTimeChange(position, timeString) {
        const newValue = (!value || value.length === 0) ? [moment(), moment()] : [...value];
        const hourStart = timeString.split(':')[0];
        const minuteStart = timeString.split(':')[1];
        newValue[position].set('hour', hourStart);
        newValue[position].set('minute', minuteStart);
        onChange(newValue);
    }

    function timeStartChange(time, timeString) {
        handleTimeChange(0, timeString);
    }

    function timeEndChange(time, timeString) {
        handleTimeChange(1, timeString);
    }

    let weekStartDay = "";
    let weekEndDay = "";

    if (picker === "week") {
        const date = moment(value),
            begin = moment(date).startOf('week').isoWeekday(7);
        weekStartDay = begin.add('d', 1).format('YYYY-MM-DD');
        weekEndDay = begin.add('d', 6).format('YYYY-MM-DD');
        timeProps = { format: weekStartDay + " - " + weekEndDay };
    }
    const props = { onChange, allowClear, ...timeProps };

    const globalBuddhistLocale = {
        ...ta_IN,
        DatePicker: {
            ...ta_IN.DatePicker,
            //   lang: buddhistLocale.lang,
        },
    };

    return (
        <>
            {!range &&
                // <ConfigProvider locale={locale}>

                    <AntdDatePicker
                        placeholder={placeholder}
                        disabled={disabled}
                        name={name}
                        // defaultValue={dayjs('2019-09-03', 'YYYY-MM-DD')}
                        /*  minDate={info.minimumDate === true ? dayjs(new Date(),'YYYY-MM-DD') : ""}
                          */
                        // onFocus={removeErrors}
                        // maxDate={info.dob === true ? dayjs().subtract(18, "year").toDate() : ""}

                        getPopupContainer={staticPopup && staticPopupTrigger}
                        dropdownClassName={drop}
                        getValueProps={(value) => ({ value: value ? dayjs(value) : "", })}
                        className={className}
                        {...props}
                        picker={picker}
                        value={value ? dayjs(value) : ""}
                        open={open} />
                // </ConfigProvider>
                }
            {(range && !reRender) &&
                <AntdRangePicker
                    placeholder={[info.startPlaceholder || t('common:label.startTime'), info.endPlaceholder ||  t('common:label.endTime')]}
                    // onOpenChange={() => setIsOpenStatus(true)}
                    popupStyle={{ 'transform': posLeft }}
                    // open={isOpenStatus}
                    disabled={disabled}
                    getPopupContainer={staticPopup && staticPopupTrigger}
                    className={className}
                    {...props}
                    value={value.length ? [dayjs(value[0]), dayjs(value[1])] : "" }
                    ranges={{
                        [t('today')]: [moment().startOf('day'), moment().endOf('day')],
                        [t('thisWeek')]: [moment().startOf('isoWeek'), moment().endOf('isoWeek')],
                        [t('thisMonth')]: [moment().startOf('month'), moment().endOf('month')],
                        [t('thisYear')]: [moment().startOf('year'), moment().endOf('year')]
                    }}
                    renderExtraFooter={() => <>
                        {showTime && <div className="time-range-picker">
                            <div>
                                <span>{t('common:label.startTime')}</span>
                                <TimePicker
                                    placeholder={t('common:label.selectTime')}
                                    value={value ? value[0] : null}
                                    format={format}
                                    onChange={timeStartChange}
                                    size="sm"
                                />
                            </div>
                            <div>
                                <span>{t('common:label.endTime')}</span>
                                <TimePicker
                                    placeholder={t('common:label.selectTime')}
                                    value={value ? value[1] : null}
                                    format={format}
                                    onChange={timeEndChange}
                                    size="sm"
                                />
                            </div>
                        </div>}
                        <div className="time-picker-complate" style={{}}>
                            <div className="time-picker-complate-ok" onClick={() => { setIsOpenStatus(false); setReRender(true); }}>
                                {t('common:label.complateSelection')}
                            </div>
                            {/* <div className="time-picker-complate-cancel" onClick={()=>{setIsOpenStatus(false); setReRender(true); setIsCancel(true); }}>
                            Cancel
                        </div>*/}
                        </div>

                    </>}
                />}
        </>
    );
};

DatePicker.propTypes = {
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
    showTime: PropTypes.bool,
    range: PropTypes.bool,
    allowClear: PropTypes.bool,
};
DatePicker.defaultProps = {
    showTime: false,
    range: false,
    allowClear: false,
};

export default DatePicker;
