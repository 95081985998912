import * as yup from "yup";
import { Formik } from "formik";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import DateTime from "react-datetime";
import { Form, Button } from "react-bootstrap";
import { DateRange } from "react-date-range";
import SimpleCard from 'app/components/atoms/simple-card/SimpleCard'
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import {MdPrint, MdDelete } from "react-icons/md";
import swal from "sweetalert2";
import _ from "lodash";
import { decimalAmount, pagePermission, showMessage } from "app/utils/app-functions";
import FormLayout from "app/components/render/formlayout";
import View from "app/components/atoms/modalView/Modalview";
import CustomTable from "app/components/atoms/custom-table/CustomTable";
import formRender from "app/components/render/formcomponent";
import { fieldList, inner, innerClickForm, purchaseAddForm, purchasingForm, subInner, supplierForm } from "./formData";
import { FiEdit, FiSend, FiTrash2 } from 'react-icons/fi';
import TableLayout from "app/components/render/formlayout/tablelayout";
import { Table } from "react-bootstrap";
import CollapseView from "app/components/atoms/CollapseView/CollapseView";
import { createPurchase, getPurchase, updatePurchase } from "app/api/PurchaseApi";
import { getSupplier, createSupplier, updateSupplier, getAsyncSupplier } from "app/api/SupplierApi";
import { getWarehouse } from "app/api/WarehouseApi";
import { getLocation } from "app/api/LocationApi";
import { getMaterialType } from "app/api/MaterialTypeApi";
import { getMaterialCode } from "app/api/MaterialCodeApi";
import { getTaxCode } from "app/api/TaxCodeApi";
import { getMeasurement } from "app/api/MeasurementApi";
import { dateConversion } from "app/utils/app-functions";
import Alert from "app/components/atoms/alert";
import moment from "moment";
import { getTax } from "app/api/TaxApi";
import { getCurrency } from "app/api/CurrencyApi";
import { lightTheme } from "styles/theme";
import { printInvoice } from "app/components/PurchasePdf";

let editData = false;
let object = [];
let unitObject = [];

const Purchase = ({ navigation }) => {

  const dispatch = useDispatch();
  const customerFormRef = useRef();
  const purchaseFormRef = useRef()
  const jobHistoryModel = useRef();

  const getPurchaseSuccess = useSelector((state) => state.purchaseReducer.getPurchaseSuccess);
  const getPurchaseList = useSelector((state) => state.purchaseReducer.getPurchaseList);
  const getPurchaseFailure = useSelector((state) => state.purchaseReducer.getPurchaseFailure);
  const purchaseErrorMessage = useSelector((state) => state.purchaseReducer.errorMessage);

  const createPurchaseSuccess = useSelector((state) => state.purchaseReducer.createPurchaseSuccess);
  const createPurchaseData = useSelector((state) => state.purchaseReducer.createPurchaseData);
  const createPurchaseFailure = useSelector((state) => state.purchaseReducer.createPurchaseFailure);

  const createSupplierSuccess = useSelector((state) => state.supplierReducer.createSupplierSuccess);
  const createSupplierData = useSelector((state) => state.supplierReducer.createSupplierData);
  const createSupplierFailure = useSelector((state) => state.supplierReducer.createSupplierFailure);


  const updatePurchaseSuccess = useSelector((state) => state.purchaseReducer.updatePurchaseSuccess);
  const updatePurchaseData = useSelector((state) => state.purchaseReducer.updatePurchaseData);
  const updatePurchaseFailure = useSelector((state) => state.purchaseReducer.updatePurchaseFailure);

  // const getCurrencySuccess = useSelector((state) => state.currencyReducer.getCurrencySuccess);
  // const getCurrencyList = useSelector((state) => state.currencyReducer.getCurrencyList);
  // const getCurrencyFailure = useSelector((state) => state.currencyReducer.getCurrencyFailure);
  // const currencyErrorMessage = useSelector((state) => state.currencyReducer.errorMessage);

  const getSupplierSuccess = useSelector((state) => state.supplierReducer.getSupplierSuccess);
  const getSupplierList = useSelector((state) => state.supplierReducer.getSupplierList);
  const getSupplierFailure = useSelector((state) => state.supplierReducer.getSupplierFailure);
  const supplierErrorMessage = useSelector((state) => state.supplierReducer.errorMessage);

  const getWarehouseSuccess = useSelector((state) => state.warehouseReducer.getWarehouseSuccess);
  const getWarehouseList = useSelector((state) => state.warehouseReducer.getWarehouseList);
  const getWarehouseFailure = useSelector((state) => state.warehouseReducer.getWarehouseFailure);
  const warehouseErrorMessage = useSelector((state) => state.warehouseReducer.errorMessage);

  const getLocationSuccess = useSelector((state) => state.locationReducer.getLocationSuccess);
  const getLocationList = useSelector((state) => state.locationReducer.getLocationList);
  const getLocationFailure = useSelector((state) => state.locationReducer.getLocationFailure);
  const locationErrorMessage = useSelector((state) => state.locationReducer.errorMessage);

  const getMaterialTypeSuccess = useSelector((state) => state.materialTypeReducer.getMaterialTypeSuccess);
  const getMaterialTypeList = useSelector((state) => state.materialTypeReducer.getMaterialTypeList);
  const getMaterialTypeFailure = useSelector((state) => state.materialTypeReducer.getMaterialTypeFailure);
  const materialTypeErrorMessage = useSelector((state) => state.materialTypeReducer.errorMessage);

  const getMaterialCodeSuccess = useSelector((state) => state.materialCodeReducer.getMaterialCodeSuccess);
  const getMaterialCodeList = useSelector((state) => state.materialCodeReducer.getMaterialCodeList);
  const getMaterialCodeFailure = useSelector((state) => state.materialCodeReducer.getMaterialCodeFailure);
  const materialCodeErrorMessage = useSelector((state) => state.materialCodeReducer.errorMessage);


  /* const getMaterialLimitSuccess = useSelector((state) => state.materialLimitReducer.getMaterialLimitSuccess);
  const getMaterialLimitList = useSelector((state) => state.materialLimitReducer.getMaterialLimitList);
  const getMaterialLimitFailure = useSelector((state) => state.materialLimitReducer.getMaterialLimitFailure);
  const materialLimitErrorMessage = useSelector((state) => state.materialLimitReducer.errorMessage); */

  /*   const getDeliverySuccess = useSelector((state) => state.deliveryReducer.getDeliverySuccess);
    const getDeliveryList = useSelector((state) => state.deliveryReducer.getDeliveryList);
    const getDeliveryFailure = useSelector((state) => state.deliveryReducer.getDeliveryFailure);
    const deliveryErrorMessage = useSelector((state) => state.deliveryReducer.errorMessage); */

  const getMeasurementSuccess = useSelector((state) => state.measurementReducer.getMeasurementSuccess);
  const getMeasurementList = useSelector((state) => state.measurementReducer.getMeasurementList);
  const getMeasurementFailure = useSelector((state) => state.measurementReducer.getMeasurementFailure);
  const measurementErrorMessage = useSelector((state) => state.measurementReducer.errorMessage);

  const getTaxCodeSuccess = useSelector((state) => state.taxCodeReducer.getTaxCodeSuccess);
  const getTaxCodeList = useSelector((state) => state.taxCodeReducer.getTaxCodeList);
  const getTaxCodeFailure = useSelector((state) => state.taxCodeReducer.getTaxCodeFailure);
  const taxCodeErrorMessage = useSelector((state) => state.taxCodeReducer.errorMessage);

  const [pageItems, setpageItems] = useState({});

  useEffect(() => {
    let data = pagePermission(pageItems, "Inventory", "Purchase","Purchase Order" )
    setpageItems(data)


  }, [navigation]);


  const column = [
    {
      "dataField": "purchaseNumber",
      "text": "Purchase Number",
      "sort": true
    },

    {
      "dataField": "purchaseOrderDate",
      "text": "PurchaseOrderDate",
      "sort": true,
      formatter: (cellContent, row, index) => {
        return dateConversion(cellContent, "DD-MMM-YYYY")
      }
    },
    {
      "dataField": "supplierName",
      "text": "Supplier Name",
      "sort": true
    },
    {
      "dataField": "deliveryDate",
      "text": "DeliveryDate",
      "sort": true,
      formatter: (cellContent, row, index) => {
        return dateConversion(cellContent, "DD-MMM-YYYY")
      }
    },
    {
      "dataField": "amount",
      "text": "Amount ",
      "sort": false
    },
    {
      dataField: "orderStatusId",
      text: "Status",
      sort: true,
      align: "center",
      headerAlign: "center",
      formatter: (cellContent, row, index) => {
        const conInfo = { inputType: "status", status: cellContent }
        return formRender(null, null, conInfo, null);
      }
    },
  ]
  if (true) {
    column.push({
      text: "Action",
      sort: false,
      align: "center",
      headerAlign: "center",
      formatter: (cellContent, row, index) => (
        <div className="icon-container">
          {pageItems.update && <FiEdit
            className="text-primary cursor-pointer"
            size={18}
            onClick={() => onEditForm(row, index)}
          ></FiEdit>}
          {pageItems.view && <MdPrint

            className="text-success cursor-pointer"
            size={18}
            onClick={() => printInvoice({ row: row })}
          ></MdPrint>}


        </div>
      )
    },)
  }

  const formColumn = [
    {
      "dataField": "materialType",
      "text": "Material Type",
      "sort": true
    },
    {
      "dataField": "materialCode",
      "text": "Material Code",
      "sort": true
    },
    {
      "dataField": "materialName",
      "text": "Material Name",
      "sort": true
    },
    {
      "dataField": "Qty",
      "text": "Qty",
      "sort": true
    },
    {
      "dataField": "unit",
      "text": "Unit ",
      "sort": false
    },
    {
      "dataField": "price",
      "text": "Unit Price",
      "sort": false
    },
    {
      "dataField": "total",
      "text": "Total",
      "sort": false
    },
    {
      "dataField": "taxCode",
      "text": "Tax Code",
      "sort": false
    },
    {
      "dataField": "totalTax",
      "text": "Total Tax",
      "sort": false
    },
    {
      "dataField": "additem",
      "text": "Add Item Details",
      "sort": false
    },
    {
      "dataField": "action",
      "text": "Action",
      "sort": false
    },
  ]

  const innerHeading = [
    {
      "dataField": "purchaseName",
      "text": "Warehouse",
      "sort": true
    },
    {
      "dataField": "purchaseName",
      "text": "Receipt Location",
      "sort": true
    },
    {
      "dataField": "purchaseName",
      "text": "Length(mm)",
      "sort": true
    },
    {
      "dataField": "purchaseName",
      "text": "Width(mm)",
      "sort": true
    },
    {
      "dataField": "description",
      "text": "Thickness ",
      "sort": false
    },
    {
      "dataField": "action",
      "text": "Action ",
      "sort": false
    }


  ]

  const innerTableList = [
    {
      "dataField": "purchaseName",
      "text": "Warehouse",
      "sort": true
    },
    {
      "dataField": "purchaseName",
      "text": "Receipt Location",
      "sort": true
    },
    {
      "dataField": "purchaseName",
      "text": "Length(mm)",
      "sort": true
    },
    {
      "dataField": "purchaseName",
      "text": "Width(mm)",
      "sort": true
    },
    {
      "dataField": "description",
      "text": "Thickness ",
      "sort": false
    },
    {
      "dataField": "description",
      "text": "Action ",
      "sort": false
    },
  ]
  const [state, setState] = useState({
    purchaseName: "",
    description: "",
    customerList: [],
    currencyList: [],
    venderList: [],
    leadSourceTypeList: [],
    supplierList: [],
    warehouseList: [],
    locationList: [],
    materialTypeList: [/* 
        {value:1,label:"AAAA"},
        {value:2,label:"BBB"},
        {value:3,label:"CCCC"},
        {value:4,label:"DD"},
        {value:5,label:"EE"},
        {value:6,label:"FFF"},
        {value:7,label:"GGG"}, */
    ],
    materialCodeList: [],
    deliveryList: [],
    unitsList: [],
    taxCode: [],
    contactPerson: '',
    qty: '',
    UnitId: '',
    unitPrice: '',
    totalId: '',
    totalTax: '',
    /* purchaseOrderDate: new Date(),
    deliveryOrderDate: new Date(), */
    deliveryId: '',
    phoneNumber: '',
    emailId: '',
    deliveryAddress: '',
    contactPersonName: '',
    inVoiceData: '',
    permitId: '',
    shipVia: '',
    fob: '',
    debitTerm: '',
    memo: "",
    logisticId: '',
    supplierId: '',
    materialCodeId: '',
    currencySymbol: '',
    deliveryOrderDate: '',
    postCode: '',
    supplierCode: '',
    primaryNumber: '',
    emailCode: '',
    addressCode: '',
    suplierNameValue: '',
    secondaryPhone: '',
    contact: '',
    materialId: '',
    comment: '',
    footerData: [
      { label: 'SubTotal :', value: '0' },
      { label: 'Tax:', value: '0' },
      { label: 'Total:', value: '0' }
    ],
    insuranceAmount: "",
    logisticAmount: "",
    handlingCost: "",
    otherCost: "",
    checkCount: '',
    filterData: [],
    deletedItems: []



  });




  const [tableRows, setTableRows] = useState([]);
  const [createModule, setCreateModule] = useState(false);
  const [createForm, setCreateForm] = useState(false);
  const [loaderModule, setLoaderModule] = useState(false);
  const [editModule, setEditModule] = useState(false);
  const [viewModule, setViewModule] = useState(false);
  const [deleteModule, setDeleteModule] = useState(false);
  const [itemAdded, setItemAdded] = useState(false);
  const [itemList, setItemList] = useState([]);
  const [selectedItem, setSelectedItem] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(false);
  const [uploadModule, setUploadModule] = useState(false);
  const [formList, setFormlist] = useState(fieldList);
  const [list, setList] = useState([])
  const [id, setId] = useState([])
  const [currencySymbol, setCurrencySymbol] = useState('')
  const [showForm, setShowForm] = useState(false)
  const [selectedmaterial, setSelectMaterial] = useState([])
  const [results, setResults] = useState([]);
  const [filteredObjects, setFilteredObjects] = useState([]);
  const [deletedRows, setDeletedRows] = useState([]);







  useEffect(() => {
    const req = {
      isActive: "true"
    }


    setLoaderModule(true)
    dispatch(getPurchase())
    // dispatch(getSupplier())
    // dispatch(getWarehouse())
    // dispatch(getMaterialLimit())
    // dispatch(getLocation())
    // dispatch(getMaterialType())
    // dispatch(getMaterialCode())
    // dispatch(getDelivery())
    // dispatch(getMeasurement())
    // dispatch(getTaxCode(req))
    // dispatch(getCurrency())
    listFetching()

  }, [navigation]);


  const listFetching = async () => {
    let materialTypeList = await getMaterialType()
    let supplierList = await getAsyncSupplier()
    let measurementList = await getMeasurement()
    let warehouseList = await getWarehouse({
      isActive: "true",
    })
    let locationList = await getLocation()

    let taxList = await getTax({
      isActive: "true",
    })
    let currencyList = await getCurrency()

    unitObject = measurementList.getMeasurementList

    setState({
      ...state,
      unitsList: measurementList.getMeasurementList,
      taxCodeList: taxList.getTaxList,
      materialTypeList: materialTypeList.getMaterialTypeList,
      currencyList: currencyList.getCurrencyList,
      supplierList: supplierList.data,
      locationList: locationList.getLocationList,
      warehouseList: warehouseList.getWarehouseList,
      // deliveryList: data,
    })

  }

  useEffect(() => {
    if (getPurchaseSuccess) {
      setItemList(getPurchaseList)
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_PURCHASE" })
    } else if (getPurchaseFailure) {
      setItemList([])
      showMessage("warning", purchaseErrorMessage?.message)
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_PURCHASE" })
    }
  }, [getPurchaseSuccess, getPurchaseFailure]);


  /*   useEffect(() => {
      if (getMaterialLimitSuccess) {
        setLoaderModule(false)
        dispatch({ type: "RESET_GET_MATERIALLIMIT" })
      } else if (getMaterialLimitFailure) {
        showMessage("warning", purchaseErrorMessage?.message)
        setLoaderModule(false)
        dispatch({ type: "RESET_GET_MATERIALLIMIT" })
      }
    }, [getMaterialLimitSuccess, getMaterialLimitFailure]); */


  /*  useEffect(() => {
     if (getCurrencySuccess) {
       setState({
         ...state,
         currencyList: getCurrencyList
 
       })
       setLoaderModule(false)
       dispatch({ type: "RESET_GET_CURRENCY" })
     } else if (getCurrencyFailure) {
       setState({
         ...state,
         currencyList: []
       })
       showMessage("warning", currencyErrorMessage?.message)
       setLoaderModule(false)
       dispatch({ type: "RESET_GET_CURRENCY" })
     }
   }, [getCurrencySuccess, getCurrencyFailure]); */

  useEffect(() => {
    if (getSupplierSuccess) {
      setState({
        ...state,
        supplierList: getSupplierList

      })

      setLoaderModule(false)
      dispatch({ type: "RESET_GET_SUPPLIER" })
    } else if (getSupplierFailure) {
      setState({
        ...state,
        supplierList: []
      })
      showMessage("warning", supplierErrorMessage?.message)
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_SUPPLIER" })
    }
  }, [getSupplierSuccess, getSupplierFailure, getSupplierList]);

  useEffect(() => {
    if (getWarehouseSuccess) {
      setState({
        ...state,
        warehouseList: getWarehouseList
      })
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_WAREHOUSE" })
    } else if (getWarehouseFailure) {
      setState({
        ...state,
        warehouseList: []
      })
      showMessage("warning", warehouseErrorMessage?.message)
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_WAREHOUSE" })
    }
  }, [getWarehouseSuccess, getWarehouseFailure]);

  useEffect(() => {
    if (getLocationSuccess) {
      setState({
        ...state,
        locationList: getLocationList
      })
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_LOCATION" })
    } else if (getLocationFailure) {
      setState({
        ...state,
        locationList: []
      })
      showMessage("warning", locationErrorMessage?.message)
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_LOCATION" })
    }
  }, [getLocationSuccess, getLocationFailure]);

  useEffect(() => {
    if (getMaterialTypeSuccess) {
      setState({
        ...state,
        materialTypeList: getMaterialTypeList
      })
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_MATERIALTYPE" })
    } else if (getMaterialTypeFailure) {
      setState({
        ...state,
        materialTypeList: getMaterialTypeList
      })
      showMessage("warning", materialTypeErrorMessage?.message)
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_MATERIALTYPE" })
    }
  }, [getMaterialTypeSuccess, getMaterialTypeFailure]);

  useEffect(() => {
    if (getMaterialCodeSuccess) {
      setState({
        ...state,
        materialCodeList: getMaterialCodeList
      })
      setSelectMaterial(getMaterialCodeList)
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_MATERIALCODE" })
    } else if (getMaterialCodeFailure) {
      setState({
        ...state,
        materialCodeList: []
      })
      showMessage("warning", materialCodeErrorMessage?.message)
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_MATERIALCODE" })
    }
  }, [getMaterialCodeSuccess, getMaterialCodeFailure]);
  /* 
    useEffect(() => {
      if (getDeliverySuccess) {
        setState({
          ...state,
          deliveryList: getDeliveryList
        })
        setLoaderModule(false)
        dispatch({ type: "RESET_GET_DELIVERY" })
      } else if (getDeliveryFailure) {
        setState({
          ...state,
          deliveryList: []
        })
        showMessage("warning", deliveryErrorMessage?.message)
        setLoaderModule(false)
        dispatch({ type: "RESET_GET_DELIVERY" })
      }
    }, [getDeliverySuccess, getDeliveryFailure]); */

  useEffect(() => {
    if (getMeasurementSuccess) {
      setState({
        ...state,
        unitsList: getMeasurementList
      })
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_MEASUREMENT" })
    } else if (getMeasurementFailure) {
      setState({
        ...state,
        unitsList: []
      })
      showMessage("warning", measurementErrorMessage?.message)
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_MEASUREMENT" })
    }
  }, [getMeasurementSuccess, getMeasurementFailure]);


  useEffect(() => {
    if (getTaxCodeSuccess) {
      setState({
        ...state,
        taxCodeList: getTaxCodeList
      })
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_TAXCODE" })
    } else if (getTaxCodeFailure) {
      setState({
        ...state,
        taxCodeList: []
      })
      showMessage("warning", taxCodeErrorMessage?.message)
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_TAXCODE" })
    }
  }, [getTaxCodeSuccess, getTaxCodeFailure]);



  useEffect(() => {
    if (createPurchaseSuccess) {
      const temp_state = [...itemList, createPurchaseData[0]];
      setItemList(temp_state)
      dispatch(getPurchase())

      setState({
        ...state,
        supplierList: temp_state

      })
      setLoaderModule(false)
      closeModule()
      Alert({ description: 'Created Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
      dispatch({ type: "RESET_CREATE_PURCHASE" })
    } else if (createPurchaseFailure) {
      setLoaderModule(false)
      showMessage("warning", purchaseErrorMessage?.message)
      dispatch({ type: "RESET_CREATE_PURCHASE" })
    }
  }, [createPurchaseSuccess, createPurchaseFailure]);

  useEffect(() => {
    if (createSupplierSuccess) {
      const temp_state = [...itemList, createSupplierData[0]];
      setLoaderModule(false)
      dispatch(getSupplier())
      resetState()

      // closeModule()
      Alert({ description: 'Created Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
      dispatch({ type: "RESET_CREATE_SUPPLIER" })
    } else if (createSupplierFailure) {
      setLoaderModule(false)
      showMessage("warning", purchaseErrorMessage?.message)
      dispatch({ type: "RESET_CREATE_SUPPLIER" })
    }
  }, [createSupplierSuccess, createSupplierFailure]);


  useEffect(() => {
    if (updatePurchaseSuccess) {
      updateTable(updatePurchaseData[0])
      dispatch({ type: "RESET_UPDATE_PURCHASE" })
    } else if (updatePurchaseFailure) {
      setLoaderModule(false)
      showMessage("warning", purchaseErrorMessage?.message)
      dispatch({ type: "RESET_UPDATE_PURCHASE" })
    }
  }, [updatePurchaseSuccess, updatePurchaseFailure]);



  const updateTable = (updatedItem) => {
    const temp_state = [...itemList];
    if (deleteModule) {
      let remainingList = _.remove(temp_state, function (n) {
        return n.purchaseId != selectedItem.purchaseOrderId;
      });
      Alert({ description: 'Deleted Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
      setItemList(remainingList)
    } else {
      temp_state[selectedIndex] = updatedItem;
      Alert({ description: 'Updated Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
      setItemList(temp_state)
    }
    setLoaderModule(false)
    closeModule()
  }

  const closeModule = () => {
    editData = false;
    setCreateModule(false)
    setDeleteModule(false)
  }



  const resetState = () => {
    setState({
      ...state,
      contactPerson: '',
      contactNumber: '',
      supplierAddress: '',
      productTax: '',
      priceGst: '',
      qty: '',
      UnitId: '',
      unitPrice: '',
      totalId: '',
      totalTax: '',
      deliveryName: '',
      phoneNumber: '',
      emailId: '',
      email: '',
      address: '',
      deliveryAddress: '',
      contactPersonName: '',
      insuranceAmount: '',
      inVoiceData: '',
      permitId: '',
      shipVia: '',
      fob: '',
      debitTerm: '',
      memo: '',
      name: '',
      purchaseOrderDate: moment(new Date(), "YYYY-MM-DD"),
      deliveryOrderDate: moment(new Date(), "YYYY-MM-DD"),
      logisticId: '',
      supplierId: '',
      materialCodeId: '',
      currencySymbol: '',
      postCode: '',
      supplierCode: '',
      primaryNumber: '',
      emailCode: '',
      addressCode: '',
      suplierNameValue: '',
      secondaryPhone: '',
      contact: '',
      materialType: '',
      comment: '',
      amount: 0,
      supplierName: '',
      countryName: '',
      insuranceId: '',
      updatedObject: "",
      logisticId: '',
      logisticAmount: '',
      handlingCost: '',
      otherCost: '',
      itemDetails: [],
      footerData: [
        { label: 'SubTotal :', value: '' },
        { label: 'Tax:', value: '' },
        { label: 'Total:', value: '' }
      ],
    });

    setCurrencySymbol([])



    setTableRows([]);

  };



  const onCreateForm = () => {
    resetState()
    editData = false;
    setCreateModule(true)
  }

  const handlePurchaseChange = (event, index, name) => {
    if (event.length > 0) {
      let temp_state = ""
      temp_state = {
        [name]: event,
        name: event[0].deliveryId,

      }
      setState(temp_state)
    }
  }


  const footerCalculation = (tableRows) => {
    const tableData = tableRows ? tableRows : [];

    let lineItemTotal = 0, lineItemTax = 0;

    if (tableData.length > 0) {
      tableData.map((row) => {
        lineItemTotal += parseFloat(row?.total || 0);
        lineItemTax += parseFloat(row?.totalTax || 0);
      });
    }
    const totalAmount = lineItemTotal + lineItemTax || 0;
    const footerData = [
      { label: 'Subtotal', value: (lineItemTotal ? lineItemTotal.toFixed(2) : 0) },
      { label: 'Tax :', value: (lineItemTax ? lineItemTax.toFixed(2) : 0) },
      { label: 'Total Amount:', value: (totalAmount ? totalAmount.toFixed(2) : 0) }
    ];

    setState({ footerData });
    return footerData;
  };



  const handleSupplierChange = (event, name, index) => {
    let object = event;
    let temp_state = "";
    temp_state = {
      ...state,
      [name]: object.label,
      contactNumber: object?.primaryNo || "",
      contactPerson: object?.contactName || "",
      supplierAddress: object?.address || "",
      supplier: event.value
    }
    setState(temp_state)

  }

  const onEditForm = (data, index) => {
    const temp_data = state?.supplierList || []
    const filter_data = temp_data.filter(item => item.supplierId === data.supplierId)
    // alert(JSON.stringify(data))

    const item = data?.deliveryInfo || ''
    const purchaseOrderItem = data?.purchaseOrderItem || [];
    // alert(data.currencyId)
    setState({
      ...state,
      supplierId: filter_data?.[0]?.supplierName || '',
      supplier: data.supplierId,
      countryName: data.currencyId,
      // countryName: data.currencyId,
      supplierAddress: data.supplierAddress || "",
      contactNumber: data.primaryNo || "",
      contactPerson: data.createdBy || "",

      inVoiceData: data.supplierInvoice || "",
      name: item.deliveryName || "",
      phoneNumber: item.deliveryPhone || "",
      emailId: item.deliveryEmail || "",
      contactPersonName: item.deliveryContactPerson || "",
      deliveryAddress: item.deliveryAddress || "",
      permitId: data.permitNumber,
      shipVia: data.shippingType,
      fob: data.fob,
      debitTerm: data.debitTerm,
      memo: data.memo,
      purchaseOrderDate: moment(data?.purchaseOrderDate, "YYYY-MM-DD"),
      deliveryOrderDate: moment(data?.deliveryDate, "YYYY-MM-DD"),
      productTax: data?.includeProductTax || false,
      priceGst: data.includingGST,
      amount: data?.amount,
      logisticAmount: data?.logistics[0]?.logisticAmount || "",
      handlingCost: data?.logistics[0]?.handlingCost || "",
      insuranceAmount: data?.logistics[0]?.insurance || "",
      otherCost: data?.logistics[0]?.otherCost || "",

      footerData: [
        { label: 'Subtotal', value: data.totalAmount || '0' },
        { label: 'Tax :', value: data.taxAmount || '0' },
        { label: 'Total Amount:', value: data.amount || '0' },
      ]
    })




    if (purchaseOrderItem) {
      const newTableRows = purchaseOrderItem.map((item, index) => ({

        poMaterialId: item?.poMaterialId || null,
        taxCode: item?.tax,
        materialId: item?.materialId || "",
        materialTypeId: item?.materialTypeId || "",

        materialCodeId: item?.materialCode || "",
        unit: item?.unit || "",
        materialName: item?.materialName || "",
        qty: item?.qty || "",
        price: item?.price || "",
        total: item?.total || "",
        totalTax: item?.totalTax || "",

        // If itemDetails exists, update the details
        ...(item.itemDetails && {
          itemDetails: item.itemDetails.map((detail) => ({
            ...detail,
            warehouseId: detail.receiptWarehouse ? detail.receiptWarehouse : null,
            locationId: detail.receiptLocation ? detail.receiptLocation : null,
            length: detail?.itemHeight || null,
            width: detail?.itemWidth || null,
          })),
        }),
      }));

      setTableRows(newTableRows);
    }


    editData = true;
    setSelectedIndex(index);
    setSelectedItem(data);
    setCreateModule(true);
    setItemAdded(true)
  };


  const confromDialog = () => {
    return (
      swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          type: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
          cancelButtonText: "No",
        })
        .then((result) => {
          if (result.value) {
            onSubmitForm()
          } else {
            swal.fire({
              title: "Cancelled!",
              text: "Permission denied.",
              type: "error",
              icon: "error",
              timer: 1500,
            });
          }
        })
    )
  }

  useEffect(() => {
    if (deleteModule) {
      confromDialog()
    }
  }, [deleteModule])

  const onDeleteItem = (data, index) => {
    editData = false;
    setSelectedItem(data)
    setSelectedIndex(index)
    setDeleteModule(true)
  }

  const itemHeader = [
    {
      "dataField": "index",
      "text": "S.No",
      "sort": true,
      formatter: (cellContent, row, index) => {
        return index + 1
      }
    },

    {
      "dataField": "status",
      "text": "",
      formatter: (cellContent, row, index, extraData) => {
        const conInfo = { inputType: "checkbox", status: cellContent }
        return formRender(extraData, null, conInfo, null, null, statusChange, index);
      }
    },

    {
      "dataField": "materialId",
      "text": "Material Id",
      "sort": true
    },
    {
      "dataField": "materialCode",
      "text": "Material Code",
      "sort": true
    },
    {
      "dataField": "materialName",
      "text": "Material Name",
      "sort": true
    },



  ]

  const confirmForm = (index) => {
    setShowForm(false)

  }


  const onDeletePress = (itemIndex) => {
    let temp_state = [...tableRows];
    let addd = tableRows.splice(itemIndex, 1);
    let remainingList = _.remove(temp_state, function (n, index) {
      return index != itemIndex
    });
    let updatedState = footerCalculation(remainingList)
    setTableRows(remainingList);
    let temp_arr = { ...state }
    setState({
      ...state,
      footerData: updatedState,
      deletedItems: [...temp_arr.deletedItems, ...addd]
    })
  };

  const onSearch = () => {
    setCreateForm(true);
  }
  const modelTitle = editData ? "Edit " : "Create ";
  const modelTitle2 = editData ? "Edit " : "Create New ";
  const modelButtonLbl = editData ? "" : "Save";

  var itemCount = 0;
  const addRow = () => {

    const defaultNewOrderObject = {
      itemCount: 0,
      selectedValue: "",
      materialId: '',
      materialCodeId: '',
      materialName: '',
      qty: "",
      unit: '',
      price: "",
      total: "",
      taxCode: '',
      totalTax: '',

      itemDetails: [

      ]
    };
    itemCount++;
    setTableRows([...tableRows, defaultNewOrderObject]);
    setItemAdded(false)
  };

  const statusChange = (event, index, extraData) => {
    const newSelectedMaterial = [...selectedmaterial];
    let tempState = [...tableRows];

    const isChecked = event.target.checked;

    if (isChecked && newSelectedMaterial.some((material, i) => material.status && i !== index)) {
      showMessage("warning", "You Cannot Select Multiple Objects");
      event.preventDefault();
      return;
    }

    // Update the status of selected material
    newSelectedMaterial.forEach((material, i) => {
      material.status = (i === index) ? isChecked : false;
    });

    const updatedObject = newSelectedMaterial[index];

    // Find the corresponding material object from materialCodeList
    const materialObject = state?.materialCodeList || [];
    const foundObjects = []; // Initialize an empty array to store found objects

    const foundObject = materialObject.find(item => item.materialId === updatedObject.materialId);
    if (foundObject) {
      foundObjects.push(foundObject); // Add the found object to the array
    }

    // Update state with the new selected material
    setState((prevState) => ({
      ...prevState,
      updatedObject: updatedObject,
      foundObjects: foundObjects // Store the array in the state if needed
    }));

    setSelectMaterial(newSelectedMaterial);

    // Update the table data
    setTableRows((prevTableRows) => {
      const updatedRows = [...prevTableRows];
      const indexx = state.index || 0;
      if (indexx < updatedRows.length && foundObject) {
        updatedRows[indexx] = {
          ...updatedRows[indexx],
          materialCodeId: foundObjects,
          materialName: updatedObject.materialName,
          materialId: updatedObject.materialId
        };
      }
      return updatedRows;
    });
  };



  const onSubmitItemDetails = (index) => {
    const temp_state = [...tableRows];
    const selectedRow = temp_state[index];

    if (selectedRow) {
      const itemDetails = selectedRow.itemDetails || [];
      const warehouseId = selectedRow.warehouseId || 0;
      const locationId = selectedRow.locationId || 0;
      const length = selectedRow.length || 0;
      const width = selectedRow.width || 0;
      const thickness = selectedRow.thickness || 0;

      let updatedItemDetails = itemDetails.map((item) => ({
        ...item,
        warehouseId: warehouseId,
        locationId: locationId,
        length: length,
        width: width,
        thickness: thickness,
      }));

      temp_state[index].itemDetails = updatedItemDetails;
      setTableRows(temp_state);
    }
  }




  const onDeleteInner = (detailIndex, parentIndex) => {
    const updatedTableRows = [...tableRows];
    updatedTableRows[parentIndex].itemDetails.splice(detailIndex, 1);
    setTableRows(updatedTableRows);
  }



  const handleChange = (event, name, index, parentIndex) => {
    event.persist()
    const numberValue = event.target.value
    let temp_state = [...tableRows]
    temp_state[parentIndex]["itemDetails"][index][event.target.name] = event.target.value;
    setTableRows(temp_state)
  }

  const handlewarehousechange = (event, name, index) => {
    let temp_state = [...tableRows]
    if (event?.target?.value) {
      temp_state[index][name] = event.target.value
    }
    else {
      temp_state[index][name] = event
    }

    setTableRows(temp_state)
  }

  const handlelocationchange = (event, name, index) => {
    let temp_state = [...tableRows]
    temp_state[index][name] = event
    setTableRows(temp_state)

  }

  const handleTypeChange = (val, stateName, index, parentIndex) => {
    let temp_state = [...tableRows]
    temp_state[parentIndex]["itemDetails"][index][stateName] = val.target.value;

    setTableRows(temp_state)

  }
  const onAddItem = (index) => {
    const temp_state = [...tableRows];
    const qtyVal = temp_state[index].qty;
    const prevQty = temp_state[index].itemDetails.length || 0;
    let newItemDetails = [];
    const diff = qtyVal - prevQty;
    if (diff > 0) {
      for (let i = 0; i < diff; i++) {
        const defaultItem = {
          receiptWarehouse: "",
          receiptLocation: "",
          batchNumber: "",
          serialNumber: "",
          itemHeight: "",
          itemWidth: "",
          thickness: ""
        };
        newItemDetails.push(defaultItem);
      }
      temp_state[index].itemDetails = [...temp_state[index].itemDetails, ...newItemDetails];
      temp_state[index].prevQty = qtyVal;
      setTableRows(temp_state)
      setItemAdded(true);
    } else {
      showMessage("warning", "New quantity must be greater than the previous one.");
    }
  };



  // const expandView = (index, setRows) => {
  //   const totalItem = tableRows[index]?.itemDetails || [];
  //   return (
  //     <div>
  //       <h3 style={{ fontSize: '20px', fontWeight: '500' }}>Item Details</h3>
  //       {itemAdded && subInner.map((item, idx) => {
  //         return (
  //           <td key={ idx } style={{ paddingRight: "20px" }}>
  //             <TableLayout onSearch={() => onSubmitItemDetails(index)} iconPress={{ "onAddItem": onAddItem }} isField={true} onChangeCallback={{ "handlewarehousechange": handlewarehousechange, "handlelocationchange": handlelocationchange }} defaultState={state} setDefaultState={setState} index={index} layoutType="table" dataList={tableRows || []} setDataList={setTableRows} dynamicForm={item} /* style={{ textAlign: 'center' }} */ />
  //           </td>
  //         )
  //       })}
  //       <Table>
  //         <thead>
  //           <tr >
  //             {innerHeading.map((data, ind) => (
  //               <th key={ind}>{data.text}</th>
  //             ))}
  //           </tr>
  //         </thead>
  //         <tbody>
  //           {
  //             totalItem.map((data, itemIndex) => {
  //               return (
  //                 <tr>
  //                   {inner.map((item, idx) => {
  //                     return (
  //                       <td >
  //                         <TableLayout isField={true} onChangeCallback={{ "handleChange": handleChange, "handleTypeChange": handleTypeChange }} iconPress={{ "onDeleteInner": onDeleteInner }} defaultState={state} setDefaultState={setTableRows} setDataList={setTableRows} parentIndex={index} subItemName="itemDetails" index={itemIndex} layoutType="table" dataList={tableRows[index]?.itemDetails || []} dynamicForm={item} /* style={{ textAlign: 'center' }} */ />
  //                       </td>
  //                     )
  //                   }
  //                   )}
  //                 </tr>
  //               )
  //             }
  //             )}

  //         </tbody>
  //       </Table>
  //     </div>
  //   );
  // };

  const expandView = (index, setRows) => {
    const totalItem = tableRows[index]?.itemDetails || [];

    return (
      <div>
        <h3 style={{ fontSize: '20px', fontWeight: '500', olor: `${lightTheme.primaryColor}` }}>Item Details</h3>
        <table>
          <tbody style={{display:"flex",width:"100rem"}}>
            {itemAdded && subInner.map((item, idx) => (
              <tr key={idx} style={{overflow:"hidden",scrollbarWidth:"0",height:"50px"}}>
                <td >
                  <TableLayout
                    onSearch={() => onSubmitItemDetails(index)}
                    iconPress={{ "onAddItem": onAddItem }}
                    isField={true}
                    onChangeCallback={{ "handlewarehousechange": handlewarehousechange, "handlelocationchange": handlelocationchange }}
                    defaultState={state}
                    setDefaultState={setState}
                    index={index}
                    layoutType="table"
                    dataList={tableRows || []}
                    setDataList={setTableRows}
                    dynamicForm={item}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Table>
          <thead>
            <tr>
              {innerHeading.map((data, ind) => (
                <th key={ind}>{data.text}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {totalItem.map((data, itemIndex) => (
              <tr key={itemIndex}>
                {inner.map((item, idx) => (
                  <td key={idx}>
                    <TableLayout
                      isField={true}
                      onChangeCallback={{ "handleChange": handleChange, "handleTypeChange": handleTypeChange }}
                      iconPress={{ "onDeleteInner": onDeleteInner }}
                      defaultState={state}
                      setDefaultState={setTableRows}
                      setDataList={setTableRows}
                      parentIndex={index}
                      subItemName="itemDetails"
                      index={itemIndex}
                      layoutType="table"
                      dataList={tableRows[index]?.itemDetails || []}
                      dynamicForm={item}
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  };




  const fetchData = (value, index, name) => {

    let apiResponse = state?.materialCodeList || [];


    if (value.length < 3) {
      let tempstate = [...tableRows];
      tempstate[index].filteredData = [];
      tempstate[index].materialCodeId = value;
      tempstate[index].materialName = '';
      tempstate[index].materialId = '';
      setTableRows(tempstate);
      return;
    }

    let filterObject = (apiResponse ? apiResponse : []).find(item => item.materialCode.toLowerCase() === value.toLowerCase());
    const filteredResults = (apiResponse ? apiResponse : []).filter(material =>
      material.materialCode.toLowerCase().includes(value.toLowerCase())
    );

    let tempstate = [...tableRows];
    tempstate[index].filteredData = filteredResults;
    tempstate[index].materialCodeId = value;
    tempstate[index].materialName = filterObject ? filterObject.materialName : '';
    tempstate[index].materialId = filterObject ? filterObject.materialId : '';
    tempstate[index].materialTypeId = filterObject ? filterObject.materialTypeId : '';

    setTableRows(tempstate);

    if (value.length >= filterObject?.materialCode.length) {
      let tempstate = [...tableRows];
      tempstate[index].filteredData = [];
      setTableRows(tempstate);
    }

  };

  const getMaterialName = async (event, name, index) => {
    let value = event.target.value

    const req = {
      materialCode: value
    };
    const response = await getMaterialCode(req);

    if (response.getMaterialCodeSuccess) {
      setState({
        ...state,
        materialCodeList: response.getMaterialCodeList.data
      });
    } else if (response.getMaterialCodeFailure) {
      setState({
        ...state,
        materialCodeList: []
      });
      showMessage("warning", response.materialCodeErrorMessage?.message);
    }

    if (value == "") {
      let tempstate = [...tableRows];
      tempstate[index].filteredData = [];
      setTableRows(tempstate);
    } else {
      fetchData(value, index, name);
    }
  };


  const getMaterialId = (event, name, index) => {

    let selectedmaterial = event

    let temp_state = [...tableRows];
    const material = state?.materialTypeList || []
    const materialItem = material.filter((item, index) => item.materialTypeId == selectedmaterial);
    // temp_state[index].materialCodeId = materialItem[0].materialId
    // temp_state[index].materialCodeIds = materialItem[0].materialId

    temp_state[index].materialName = materialItem?.[0]?.materialName || ''
    temp_state[index][name] = event
    setTableRows(temp_state)

  }

  const handleCurrencyChange = (event, name, index) => {
    const selectedCurrencyId = event;
    const selectedCurrency = state.currencyList.find(currency => currency.currencyId == selectedCurrencyId);
    const symbol = selectedCurrency?.symbol || '';
    setCurrencySymbol(symbol);
    setState({
      ...state,
      [name]: selectedCurrencyId,
      insuranceAmount: symbol,
      logisticAmount: symbol,
      handlingCost: symbol,
      otherCost: symbol,
      countryName: selectedCurrency?.currencyId || '',
    });
  };


  const handleUnitsChange = (event, index, name) => {

    let temp_state = [...tableRows];
    const material = state?.unitsList || []
    const materialItem = material.filter((item, index) => item.materialId == event.target.value);
    temp_state[index][event.target.name] = event.target.value
    temp_state[index].unit = materialItem.unitId
    setTableRows(temp_state)
  };


  const handletaxChange = (event, name, index) => {

    let temp_state = [...tableRows];
    let temp_arr = { ...state };
    const tax = state?.taxCodeList || []
    temp_state[index][name] = event

    const taxItem = tax.filter((item, index) => item.taxId == event);
    let taxVal = taxItem.length ? taxItem[0].taxVal : 0;
    let totalTaxAmount = (temp_state[index].total * (taxVal / 100)).toFixed(2)
    temp_state[index].taxVal = taxVal
    temp_state[index].totalTax = totalTaxAmount
    const footerData = footerCalculation(temp_state);
    temp_arr.footerData = footerData;
    footerCalculation()
    setTableRows(temp_state)
    setState(temp_arr);

  };

  const onPriceChange = (event, name, index) => {
    let temp_state = [...tableRows];
    let temp_arr = { ...state }
    const tax = state?.taxCodeList || []
    let taxId = parseInt(temp_state[index]?.taxCode || 0)
    const taxItem = tax.filter((item, index) => item.taxId == taxId);
    let taxVal = parseFloat(taxItem.length ? taxItem?.[0].taxVal : 0);
    temp_state[index][event.target.name] = event.target.value;
    const item_total = parseFloat(temp_state[index]?.qty || 0) * parseFloat(temp_state[index]?.price || 0);
    const item_tax_percent = (taxVal) / 100 || 0;

    temp_state[index].totalTax = decimalAmount(parseFloat(item_total) * parseFloat(item_tax_percent));


    temp_state[index].total = decimalAmount(item_total);
    const footerData = footerCalculation(temp_state);
    temp_arr.footerData = footerData;
    setTableRows(temp_state)
    setState(temp_arr)
  }

  const onSubmitInnerForm = () => {
    const req = {
      "supplierCode": state?.supplierCode || '',
      "supplierName": state?.suplierNameValue || '',
      "primaryNo": state?.primaryNumber || '',
      "secondaryNo": state?.secondaryPhone || '',
      "email": state?.email || '',
      "contactName": state?.contact || '',
      "address": state?.address || '',
      "pincode": state?.postCode || '',
      "comment": state?.comment || '',
    }

    // Validation function
    const isValid = (value) => {
      return value !== null && value.trim() !== '';
    }


    let isValidForm = true;

    // Check if any field is empty or null
    for (const key in req) {
      if (!isValid(req[key])) {
        Alert({ description: `${key} is required.`, type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });

        isValidForm = false;
        break;
      }
    }

    // Dispatch only if the form is valid
    if (isValidForm) {
      setLoaderModule(true);

      if (editData) {
        // Add your dispatch or other actions for editing here
      } else if (deleteModule) {
        const deleteRequest = {
          isActive: "false"
        }

      } else {
        dispatch(createSupplier(req));
        setCreateForm();
        setCreateModule(true);
      }
    }
  }


  const convertToPurchaseOrderItem = (value) => {
    const updateData = selectedItem ? selectedItem : {};

    let temp_arr = [];
    let itemDetailsArr = [];

    try {


      value.map((row, index) => {

        const req = {
          poMaterialId: row?.poMaterialId || null,
          materialId: row?.materialId ? parseFloat(row.materialId) : null,
          materialName: row.materialName || null,
          qty: row.qty || null,
          unit: row.unit ? parseFloat(row.unit) : null,
          price: row.price || null,
          total: (row.total || null)?.toString() || null,
          tax: parseInt(row.taxCode),
          taxVal: row.taxVal,
          totalTax: parseFloat(row.totalTax) || 0,
          receivedQty: row.qty || null,
          orderStatusId: true,
          itemDetails: row.itemDetails.map((data, ind) => ({
            itemDetailsId: data?.itemDetailsId || null,
            materialId: row?.materialId ? parseFloat(row.materialId) : null,
            materialCode: row?.materialCodeId ? (row.materialCodeId) : null,
            receiptWarehouse: data?.warehouseId ? parseFloat(data.warehouseId) : null,
            receiptLocation: data?.locationId ? parseFloat(data.locationId) : null,
            sizeOfTheGoods: null,
            itemWidth: data?.width || null,
            itemHeight: data?.length || null,
            thickness: data?.thickness || null,
            stockStatus: false
          }))
        }
        temp_arr.push(req);
      });
    } catch (error) {
      console.error("Error occurred while converting to purchase order item:", error);
    }
    return temp_arr;
  };


  const purchaseOrderBill = () => {
    const updateData = selectedItem ? selectedItem : {};
    let request = [{
      poBillId: parseInt(updateData?.poBillId) || 0,
      supplierInvoice: state.inVoiceData || '',
      permitNumber: state.permitId || 0,
      shippingType: state.shipVia || 'empty',
      fob: state.fob || '',
      debitTerm: state.debitTerm || '',
      memo: state.memo || '',
      includeProductTax: state?.productTax || false,
      includingGST: state?.priceGst || false,
      amount: (state?.footerData[2]?.value || '').toString(),
      taxAmount: (state?.footerData[1]?.value || '').toString(),
      totalAmount: (state?.footerData[0]?.value || '').toString(),
    }];
    return request;
  }

  const validateForm = async () => {
    const validatefom = purchaseFormRef.current.validateForm()
    // const validate = customerFormRef.current.validateForm()

    /*   if (validatefom  ) {
        onSubmitForm()
      } */

  }


  const onSubmitForm = () => {
    const updateData = selectedItem ? selectedItem : {};
    const requestData = {
      "supplierId": state?.supplier || null,
      "createdBy": state?.contactPerson || null,
      "currencyId": state?.countryName || null,
      "purchaseOrderDate": state.purchaseOrderDate ? dateConversion(state.purchaseOrderDate, "YYYY-MM-DD") : '',
      "deliveryDate": state.deliveryOrderDate ? dateConversion(state.deliveryOrderDate, "YYYY-MM-DD") : '',
      "purchaseOrderItem": convertToPurchaseOrderItem(tableRows) || null,
      "purchaseOrderBill": purchaseOrderBill(tableRows) || null,
      "deliveryInfo": [
        {
          "poDeliveryId": parseInt(updateData?.deliveryInfo?.poDeliveryId) || '',
          "name": state?.name || '',
          "phone": state?.phoneNumber || null,
          "email": state?.emailId || null,
          "address": state?.deliveryAddress || null,
          "contactPerson": state?.contactPersonName || null,
        }
      ],
      "logistics": [
        {
          "logisticAmount": state?.logisticAmount || null,
          "handlingCost": state?.handlingCost || null,
          "insurance": state?.insuranceAmount || null,
          "otherCost": state?.otherCost || null,
        }
      ]
    };

    function isValid(value) {
      return value !== null/*  && value !== undefined */ && value !== '' && value !== 0 &&
        !(typeof value === 'string' && value.trim() === '') &&
        !(typeof value === 'number' && isNaN(value));
    }

    function validateFormData(purchaseOrderItem) {
      if (!Array.isArray(purchaseOrderItem) || purchaseOrderItem.length === 0) {
        return false;
      }

      for (const item of purchaseOrderItem) {
        if (
          // !isValid(item.poMaterialId) ||
          !isValid(item.materialId) ||
          !isValid(item.materialName) ||
          !isValid(item.qty) ||
          !isValid(item.unit) ||
          !isValid(item.price) ||
          !isValid(item.total) ||
          !isValid(item.tax) ||
          !isValid(item.taxVal)
          // !isValid(item.totalTax)
          // !isValid(item.receivedQty)
          // !isValid(item.orderStatusId)
        ) {
          return false;
        }

        if (!Array.isArray(item.itemDetails) || item.itemDetails.length === 0) {
          return false;
        }

        for (const detail of item.itemDetails) {
          if (

            !isValid(detail.receiptWarehouse) ||
            !isValid(detail.receiptLocation) ||
            !isValid(detail.itemWidth) ||
            !isValid(detail.itemHeight) ||
            !isValid(detail.thickness)
          ) {
            return false;
          }
        }
      }

      return true;
    }

    if (!validateFormData(requestData.purchaseOrderItem)) {
      Alert({ description: 'Fill all the add item details.', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });

    } else {
      if (editData) {
        setLoaderModule(true);
        requestData.purchaseOrderItem.forEach((item) => {
          if (item.poMaterialId == null) {
            delete item.poMaterialId;
          }
        });

        requestData.purchaseOrderItem.forEach((itm) => {
          itm.itemDetails.forEach((item) => {
            if (item.itemDetailsId == null) {
              delete item.itemDetailsId;
            }
          });
        });

        requestData.logistics[0].logisticId = updateData?.logistics[0]?.logisticId || '';
        let data = state.deletedItems
        let deletingRow = data.map(ele => {
          ele.isDelete = true
          requestData.purchaseOrderItem.push(ele)
        })
        setLoaderModule(true)
        dispatch(updatePurchase(requestData, selectedItem.purchaseOrderId));

      } else if (deleteModule) {
        const deleteRequest = { isActive: "false" };
        setLoaderModule(true);
        dispatch(updatePurchase(deleteRequest, selectedItem.purchaseId));

      } else {
        requestData.purchaseOrderItem.forEach((item) => {
          delete item.poMaterialId;
          item.itemDetails.forEach((itm) => {
            delete itm.itemDetailsId;
          });
        });
        requestData.purchaseOrderBill.forEach((item) => {
          delete item.poBillId;
        });
        requestData.deliveryInfo.forEach((item) => {
          delete item.poDeliveryId;
        });

        setLoaderModule(true);
        dispatch(createPurchase(requestData));
      }
    }

  };
  const materialListView = (index) => {

    setShowForm(true);
    setState({
      ...state,
      index: index
    })
  }


  const handleClick = (buttonName) => {
    if (buttonName == 'Add') {
      onCreateForm();
    }
  };

  return (
    <div>
      <div style={{ marginTop: "-10px" }}>
        <View isOpen={createModule} onClose={setCreateModule} size="fullscreen" savetitle={modelButtonLbl} onSubmit={validateForm} title={`${modelTitle} Purchase`} >
          <FormLayout ref={purchaseFormRef} defaultState={state} setDefaultState={setState} dynamicForm={purchaseAddForm} noOfColumn={4} onSearch={() => { onSearch() }} onChangeCallback={{ "handlePurchaseChange": handlePurchaseChange, "handleSupplierChange": handleSupplierChange, "handleCurrencyChange": handleCurrencyChange }} onSubmit={onSubmitForm} />
          <p style={{ fontSize: '20px', fontWeight: '500' }} className="label-color">Logistics and handling cost</p>
          <FormLayout ref={customerFormRef} defaultState={state} /* onSubmit={onSubmitForm} */ setDefaultState={setState} dynamicForm={purchasingForm} noOfColumn={4} />
          <CollapseView iconPress={{ "onAddItem": onAddItem, "onDeletePress": onDeletePress, "materialListViews": materialListView }} onChangeCallback={{ "getMaterialName": getMaterialName, "handleUnitsChange": handleUnitsChange, "handletaxChange": handletaxChange, "onPriceChange": onPriceChange, "getMaterialId": getMaterialId }} state={state} btnName={"Add Item"} setState={setState} header={formColumn} fieldList={formList} onSearch={materialListView} expandView={expandView} tableRows={tableRows} setTableRows={setTableRows} addRow={addRow} currencySymbol={currencySymbol} calculationTitle={state.footerData}>
          </CollapseView>

        </View>
        <View isOpen={createForm} size="md" onClose={setCreateForm} title={`${modelTitle2} Supplier`} savetitle={modelButtonLbl} onSubmit={onSubmitInnerForm}>
          <FormLayout /* ref={customerFormRef} */ defaultState={state} setDefaultState={setState} dynamicForm={innerClickForm} noOfColumn={2} onSubmit={onSubmitInnerForm} />
        </View>
        <View size="xl" isOpen={showForm} onClose={setShowForm} title={`Select Product`} onSubmit={confirmForm} btnName={"Confirm"} count={true} savetitle={"Confirm"} checkCount={state?.checkCount || 0} quatityCount={selectedIndex === false ? 0 : tableRows[selectedIndex]?.qty || 0}>
          <CustomTable columns={itemHeader} data={selectedmaterial} tableName={""} state={state} />
        </View>

        <SimpleCard >
          <CustomTable buttonData={pageItems.create ? ['add'] : []} handleClick={handleClick} tableName={"Purchase"} columns={column} data={itemList} initialPage={1} />
        </SimpleCard>

      </div>
    </div>
  )
};

export default Purchase;
