const initialState = {
  otherLeaveList: []  
  }
  
  const OtherLeaveReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_OTHER_LEAVE_SUCCESS": {             
        return {
          ...state,
          getOtherLeaveSuccess: true,
          getOtherLeaveList: action.payload.data,
        };
      }
      case "GET_OTHER_LEAVE_FAILURE": {
        return {
          ...state,
          getOtherLeaveFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_OTHER_LEAVE": {
        return {
          ...state,
          getOtherLeaveSuccess: false,
          getOtherLeaveFailure: false,
          getOtherLeaveList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_OTHER_LEAVE_SUCCESS": {             
        return {
          ...state,
          createOtherLeaveSuccess: true,
          createOtherLeaveData: action.payload.data,
        };
      }
      case "CREATE_OTHER_LEAVE_FAILURE": {
        return {
          ...state,
          createOtherLeaveFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_OTHER_LEAVE": {
        return {
          ...state,
          createOtherLeaveSuccess: false,
          createOtherLeaveFailure: false,
          createOtherLeaveData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_OTHER_LEAVE_SUCCESS": {             
        return {
          ...state,
          updateOtherLeaveSuccess: true,
          updateOtherLeaveData: action.payload.data,
        };
      }
      case "UPDATE_OTHER_LEAVE_FAILURE": {
        return {
          ...state,
          updateOtherLeaveFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_OTHER_LEAVE": {
        return {
          ...state,
          updateOtherLeaveSuccess: false,
          updateOtherLeaveFailure: false,
          updateOtherLeaveData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default OtherLeaveReducer
  
  