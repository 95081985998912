
const studentListForm = [
    {
        title: "Basic Information",
        formBody : [
            {
                formField: [
                    {
                        label: "Batch Name",
                        inputType: "select",
                        options: "batchList",
                        uniqueKey:"batchId",
                        displayKey:"batchName",
                        multiple:false,
                        fieldName:"batchId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },                                         
                ]
            },
            {
                formField: [
                    {
                        label: "Class - Section",
                        inputType: "select",
                        options: "studentClassList",
                        uniqueKey:"enrollmentId",
                        displayKey:"classTitle",
                        subDisplayKey:"sectionTitle",
                        multiple:false,
                        fieldName:"enrollmentId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }                                                                                    
                ]
            }
        ]
    }
];


export {
    studentListForm,
    // filterSubjectTeachersForm
};