import React, { createContext, useReducer, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import axios from 'axios'
import { UserLogin } from "app/api/LoginApi";
import Alert from "app/components/atoms/alert";
import { userDetailsInfo } from "app/api/LoginApi";
import { useGlobalContext } from "GlobalContext";

const initialState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
};
const AuthContext = createContext({
  ...initialState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => { },
});

const reducer = (state, action) => {
  switch (action.type) {
    case 'INIT':
      const { isAuthenticated, user } = action.payload;
      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
      };
    case 'LOGIN':
      // const { user } = action.payload;
      return {
        ...state,
        isAuthenticated: localStorage.getItem("isAuthenticated"),
        // user,
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    default:
      return state;
  }
};


/* const setSession = (accessToken, userInfo) => {
  const {setUserInfo } = useGlobalContext();

  if (accessToken) {
    localStorage.setItem('accessToken', accessToken)
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
  } else if (userInfo) {
    setUserInfo(userInfo[0])
    localStorage.setItem('userInfo', JSON.stringify(global.UserInfo))
    localStorage.setItem('isAuthenticated', true)
  } else {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('userInfo')
    // localStorage.removeItem('branchInfo')
    localStorage.removeItem('user')
    localStorage.removeItem('isAuthenticated')
    localStorage.removeItem('page')
    localStorage.removeItem('unitId')
    localStorage.removeItem('unitCode')
    localStorage.removeItem('unitToken')
    // global.pageItems = [];
    global.pageItems = [{
      label: 'PAGES',
      type: 'label',
    }];
    // console.log('global.pageItems', JSON.stringify(global.pageItems))
    delete axios.defaults.headers.common.Authorization
  }
} */


  const useSession = () => {
    const { setUserInfo } = useGlobalContext();
  
    const setSession = (accessToken, userInfo) => {
      if (accessToken) {
        localStorage.setItem("accessToken", accessToken);
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
      } else if (userInfo) {
        localStorage.setItem("userInfo", JSON.stringify(userInfo));
        localStorage.setItem("isAuthenticated", true);
      } else {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("userInfo");
        localStorage.removeItem("userId");
        localStorage.removeItem("isAuthenticated");
        localStorage.removeItem("page");
        localStorage.removeItem("unitId");
        localStorage.removeItem("unitCode");
        localStorage.removeItem("unitToken");
        global.pageItems = [
          {
            label: "PAGES",
            type: "label",
          },
        ];
        delete axios.defaults.headers.common.Authorization;
      }
    };
  
    return { setSession };
  };

const setPageSession = (userData) => {
  if (userData) {
      localStorage.setItem('page', userData)
  } else {
      localStorage.removeItem('page')
  }
}

const getSession = (sessionName) => {
  return sessionName ? window.localStorage.getItem(sessionName) : false
}

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();
  const { setSession } = useSession(); // Use the custom hook

  const login = async (email, password) => {
    // alert(email + " === " +password )
   
    const loginCredential = {
      emailId: email,
      password: password
    }

    const response = await UserLogin(loginCredential)
    console.log("response --- " + JSON.stringify(response));

    const { accessToken, loginSuccess, payload, errorMessage } = response
    if (loginSuccess) {
      
      // setSession(true)
      setSession(accessToken)
      const userDetailsInfos = await userDetailsInfo(loginCredential);
      const { pages, userInfoSuccess, payload, errorMessage } = userDetailsInfos;
      if (userInfoSuccess) {
        setSession(false, payload);
        setPageSession(JSON.stringify(pages))
        global.pageItems = pages;
        dispatch({
          type: 'LOGIN',
          // payload: { user },
        });
        navigate("/");

        Alert({ description: "Login Successful.", type: 'success', placement: 'topRight', duration: 0.5 });
      } else {
        Alert({ description: errorMessage, type: 'error', placement: 'topRight', duration: 3 });

        // showMessage("error", errorMessage)
       /*  dispatch({
          type: 'LOGIN',
          payload: {
            user: {
              id: "",
              email: "",
              name: "",
              role: "",
            },
          },
        }) */
      }
    } else {
      Alert({ description: errorMessage, type: 'error', placement: 'topRight', duration: 3 });
     /*  dispatch({
        type: 'LOGIN',
        payload: {
          user: {
            id: "",
            email: "",
            name: "",
            role: "",
          },
        },
      }) */
    }
    // return
   
  };

  const logout = () => {
    dispatch({
      type: 'LOGOUT',
    });
    localStorage.setItem("isAuthenticated", false);
    localStorage.removeItem('accessToken')
    localStorage.removeItem('userInfo')
    localStorage.removeItem('unitId')
    localStorage.removeItem('teamList')
    localStorage.removeItem('unitCode')
    localStorage.removeItem('unitToken')
    localStorage.removeItem('userId')
    localStorage.removeItem('user')
    localStorage.removeItem('page')
    sessionStorage.removeItem('accessPath')
    // global.pageItems = [];
    global.pageItems = [{
      label: 'PAGES',
      type: 'label',
    }];
    delete axios.defaults.headers.common.Authorization
    navigate("/session/authform");

  };

  return (
    <AuthContext.Provider value={{ ...state, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to access auth context
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export default AuthContext;
