



const constants = {
    statusList: [
        { id: "in", typeName: "in" },
        { id: "out", typeName: "out" }
    ],
   
    
    
}
export default constants;