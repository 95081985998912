import { returnApiCallPromise } from './ApiContent';
import { enrollmentDetails } from './APIContainer';

//GET
export function getEnrollmentDetails(request) {
  const requestObj = request ? { params: request } : "";
  return (dispatch) => {
    returnApiCallPromise("GET", enrollmentDetails, requestObj )
      .then(response => {
        dispatch({
          type: 'GET_ENROLLMENT_DETAILS_SUCCESS',
          payload: response.data
        })
      }).catch(error => {
        
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'GET_ENROLLMENT_DETAILS_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}
export function asyncGetEnrollmenetDetailsInfo(request) {
  const requestObj = request ? { params: request } : "";
  return  returnApiCallPromise("GET", enrollmentDetails, requestObj)
      .then(response => {
        return {
          error: false,
          message:"Succsss",
          data: response?.data?.data || []
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        return {
          error: true,
          data:[],
          message:errorMessage
        }
      })
}