import { returnApiCallPromise } from './ApiContent';
import { subjectEnrollment } from './APIContainer';

//GET
export function getSubjectEnrollment(request) {
  const requestObj = request ? { params: request } : "";
  return (dispatch) => {
    returnApiCallPromise("GET", subjectEnrollment, requestObj)
      .then(response => {
        dispatch({
          type: 'GET_SUBJECT_ENROLLMENT_SUCCESS',
          payload: response.data
        })
      }).catch(error => {

        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'GET_SUBJECT_ENROLLMENT_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}
export function asyncgetSubjectEnrollment(request) {
  const requestObj = request ? { params: request } : "";
  return (
    returnApiCallPromise("GET", subjectEnrollment, requestObj)
      .then(response => {
        return {
          error: false,
          message: "Succsss",
          data: response?.data?.data || []
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        return {
          error: true,
          data: [],
          message: errorMessage
        }
      })
  )
}

//POST
export function createSubjectEnrollment(request) {

  return (dispatch) => {
    returnApiCallPromise("POST", subjectEnrollment, request)
      .then(response => {
        if (response.data.error) {
          dispatch({
            type: 'CREATE_SUBJECT_ENROLLMENT_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'CREATE_SUBJECT_ENROLLMENT_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'CREATE_SUBJECT_ENROLLMENT_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

export function updateSubjectEnrollment(request, subjectEnrollmentId) {
  return (dispatch) => {
    returnApiCallPromise("PUT", subjectEnrollment + "/" + subjectEnrollmentId, request)
      .then(response => {

        if (response.data.error) {
          dispatch({
            type: 'UPDATE_SUBJECT_ENROLLMENT_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'UPDATE_SUBJECT_ENROLLMENT_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'UPDATE_SUBJECT_ENROLLMENT_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}