import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
    *, *::before, *::after {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    body {
        letter-spacing: .6px;
        position:fixed;
        width:100%;
        height:auto;
        font-size:12.5px;
        font-family: 'Montserrat', sans-serif;

        

          @media (max-width: 768px) {
        body {
            font-size: 0.9rem;
        }
    }

   

    }
`;
