import React from 'react';
import './card.css';  // Import the CSS file for styling
import { Button } from 'react-bootstrap';
import { useSidebar } from '../../sidebar/sidebarContext';
import styled from 'styled-components';
import { lightTheme } from "styles/theme";

const SimpleCard = ({ title, description, image, children, onClick, btnName }) => {
  const { sidebarOpen } = useSidebar();

  const StyledButton = styled.button`
  margin-right: ${sidebarOpen ? '50px' : '25px'};
  background-color: ${({ theme }) => theme.mediumPurple};
  border: none;
  height: 30px;
  font-size: 12px;
  font-weight: 400;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  width: auto;
  padding: 0 10px;
  transition: margin-right 0.2s linear;
  margin-bottom:-80px;
`;

  return (
    <div className={`simple-card card-container ${sidebarOpen ? 'expanded' : 'collapsed'}`}>
      <div className="card-header ">
        {description && (
          <h6 className={`card-description-${sidebarOpen ? 'expanded' : 'collpased'}`} style={{
            color: lightTheme.primaryColor,
          }}>
            {description}
          </h6>
        )}
        {onClick && (
          <StyledButton onClick={onClick}>
            {btnName || "Add"}
          </StyledButton>
        )}
      </div>

      <div className="card-body">
        {children}
      </div>
    </div>
  );
};

export default SimpleCard;
