import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./multislider.css"; // Include necessary CSS
import { GiPreviousButton, GiNextButton } from "react-icons/gi";
import ProgressSteps from "./MultiWrapper";
const MultiSlider = ({ sliderData, stepIndicatorPosition, onReachStep7 }) => {
    const [currentIndexes, setCurrentIndexes] = useState(
        new Array(sliderData.length).fill(0)
    );


    useEffect(() => {
        // Check if we are at the last step of the first slider
        if (currentIndexes[0] === sliderData[0].slides.length - 1) {
            onReachStep7(false); // Pass true when last step is completed
        } else {
            onReachStep7(true); // Reset to false for other steps
        }
    }, [currentIndexes, sliderData, onReachStep7]);

    const nextSlide = (index) => {
        setCurrentIndexes((prevIndexes) => {
            const updatedIndexes = [...prevIndexes];
            updatedIndexes[index] =
                updatedIndexes[index] === sliderData[index].slides.length - 1
                    ? 0
                    : updatedIndexes[index] + 1;
            return updatedIndexes;
        });
    };

    const prevSlide = (index) => {
        setCurrentIndexes((prevIndexes) => {
            const updatedIndexes = [...prevIndexes];
            updatedIndexes[index] =
                updatedIndexes[index] === 0
                    ? sliderData[index].slides.length - 1
                    : updatedIndexes[index] - 1;
            return updatedIndexes;
        });
    };

    return (
        <div className="multi-slider-container">
            {/* Step Indicator */}
            <div className="step-indicator">
                {sliderData[0].slides.map((slide, stepIndex) => (
                    <React.Fragment key={stepIndex}>
                        <div
                            className={`step ${currentIndexes[0] >= stepIndex ? "active" : ""}`}
                        >
                            <div className="circle">{stepIndex + 1}</div>
                            <div className="label">{slide.title}</div>
                        </div>
                        {stepIndex < sliderData[0].slides.length - 1 && (
                            <div  style={{
                                left: slide.left || "",
                                width: slide.width || "",
                            }} className={`step-line ${currentIndexes[0] > stepIndex ? "active-line" : ""}`} />
                        )}
                    </React.Fragment>
                ))}
            </div>


            {/* Sliders */}
            {sliderData.map((slider, index) => {
                const { title, slides } = slider

                return (
                    <div className="slider-section" key={index}>
                        <div className="slider-navigation">
                            <button
                                className="prev-btn"
                                onClick={() => prevSlide(index)}
                                disabled={currentIndexes[index] === 0}
                            >
                                <GiPreviousButton />
                            </button>
                            <button
                                className="next-btn"
                                onClick={() => nextSlide(index)}
                                disabled={currentIndexes[index] === slides.length - 1}
                            >
                                <GiNextButton />
                            </button>
                        </div>

                        <div className="slider-wrapper">
                            <div
                                className="slider-content"
                                style={{
                                    transform: `translateX(-${currentIndexes[index] * 100}%)`,
                                    transition: "transform 0.5s ease-in-out",
                                    display: "flex",
                                }}
                            >
                                {slides.map((slide, slideIndex) => (
                                    <div
                                        className={`slide-item slide-${slideIndex}`}
                                        key={slideIndex}
                                        style={{
                                            marginTop: slide.marginTop || "0px",
                                        }}
                                    >
                                        <div className="slide-card">
                                            {slide.title && (
                                                <p className="header-title"></p>
                                            )}
                                            {slide.content ? slide.content : slide.description}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};


MultiSlider.propTypes = {
    sliderData: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            slides: PropTypes.arrayOf(
                PropTypes.shape({
                    content: PropTypes.node,
                    title: PropTypes.string.isRequired,
                    description: PropTypes.string,
                    marginTop: PropTypes.string, // Optional custom margin
                })
            ).isRequired,
        })
    ).isRequired,
    stepIndicatorPosition: PropTypes.shape({
        left: PropTypes.string, // Custom left value
        top: PropTypes.string, // Optional custom top value
    }),
};

export default MultiSlider;