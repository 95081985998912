import { returnApiCallPromise } from './ApiContent';
import { salaryType } from './APIContainer';
//GET
export function getEmployeeSalaryType(request) {
    const requestObj = request ? { params: request } : "";
    return (dispatch) => {
      returnApiCallPromise("GET", salaryType, requestObj)
        .then(response => {
          dispatch({
            type: 'GET_EMPLOYEE_SALARY_TYPE_SUCCESS',
            payload: response.data
          })
        }).catch(error => {
          
          let errorMessage = error;
          if (error.response) {
            if (error.response.data.error) {
              errorMessage = error.response.data.message;
            }
          }
          dispatch({
            type: 'GET_EMPLOYEE_SALARY_TYPE_FAILURE',
            errorMessage: errorMessage
          })
        })
    }
  }

  export function asyncGetSalaryTypeInfo(request) {
    const requestObj = request ? { params: request } : "";
    return  returnApiCallPromise("GET", salaryType, requestObj)
        .then(response => {
          return {
            error: false,
            message:"Succsss",
            data: response?.data?.data || []
          }
        }).catch(error => {
          let errorMessage = error;
          if (error.response) {
            if (error.response.data.error) {
              errorMessage = error.response.data.message;
            }
          }
          return {
            error: true,
            data:[],
            message:errorMessage
          }
        })
  }