import { returnApiCallPromise } from './ApiContent';
import { syllabus } from './APIContainer';

//GET
export function getSyllabus(request) {
  const requestObj = request ? { params: request } : "";
  return (dispatch) => {
    returnApiCallPromise("GET", syllabus, requestObj)
      .then(response => {
        dispatch({
          type: 'GET_SYLLABUS_SUCCESS',
          payload: response.data
        })
      }).catch(error => {
        
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'GET_SYLLABUS_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

//POST
export function createSyllabus(request) {

  return (dispatch) => {
    returnApiCallPromise("POST", syllabus, request)
      .then(response => {
        if (response.data.error){
          dispatch({
            type: 'CREATE_SYLLABUS_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'CREATE_SYLLABUS_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'CREATE_SYLLABUS_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

export function updateSyllabus(request, syllabusId) {
  return (dispatch) => {
    returnApiCallPromise("PUT", syllabus+"/"+syllabusId , request)
      .then(response => {

        if (response.data.error) {
          dispatch({
            type: 'UPDATE_SYLLABUS_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'UPDATE_SYLLABUS_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'UPDATE_SYLLABUS_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}