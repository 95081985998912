
const feesTypeForm = [
    {
        title: "Basic Information",
        formBody: [
            {
                formField: [
                    {
                        label: "Fees Type Name",
                        inputType: "text",
                        placeholder: "Enter Fees Type Name",
                        fieldName: "feesTypeName",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Installment",
                        inputType: "number",
                        type: "number",
                        placeholder: "Enter Count Installment",
                        fieldName: "installment",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            }
        ]
    }
];


export const homeWorkForm = [
    {
        title: "Basic Information",
        formBody: [
            {
                formField: [
                    {
                        label: "Batch Name",
                        inputType: "select",
                        options: "batchList",
                        uniqueKey: "batchId",
                        onChange: "onSelectBatch",
                        displayKey: "batchName",
                        fieldName: "batch_Id",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },




            {
                formField: [
                    {
                        label: "Class -Section",
                        inputType: "select",
                        options: "enrollmentDetailsList",
                        uniqueKey: "enrollmentId",
                        displayKey: "classTitle",
                        onChange: "onSelectClass",
                        subDisplayKey: "sectionTitle",
                        multiple: false,
                        fieldName: "enrollment_Id",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },



        ]
    }
];
export const studentFilterForm = [
    {
        title: "Basic Information",
        formBody: [
            {
                formField: [
                    {
                        label: "Select Student",
                        inputType: "select",
                        options: "studentList",
                        uniqueKey: "studentEnrollmentId",
                        // onChange: "onSelectBatch",
                        displayKey: "studentName",
                        limit:"3",
                        multiple: true,
                        subDisplayKey: "class-section",
                        fieldName: "studentEnrollmentId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },

        ]
    }
];

export {
    feesTypeForm,

};