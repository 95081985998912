import * as yup from "yup";
import { Formik } from "formik";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import DateTime from "react-datetime";
import { Form, Button } from "react-bootstrap";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import FormLayout from "app/views/formlayout";
import { feesTypeForm, homeWorkForm, studentFilterForm } from "./formData";
import { MdEdit, MdDelete, MdOutlineMoreHoriz } from "react-icons/md";
import { FiEdit } from "react-icons/fi";
import { lightTheme } from "styles/theme";
import BodyView from "app/components/atoms/BodyView";
import swal from "sweetalert2";
import _ from "lodash";
import formRender from "app/utils/formcomponent";
import { createFeesType, getFeesType, updateFeesType } from "app/api/FeesTypeApi";
import { pagePermission, showMessage } from "app/utils/app-functions";
import CustomTable from "app/components/atoms/custom-table/CustomTable";
import SimpleCard from "app/components/atoms/simple-card/SimpleCard";
import View from "app/components/atoms/modalView/Modalview";
import { useSidebar } from "app/components/sidebar/sidebarContext";
import DynamicButton from "app/components/atoms/DynamicButton";
import Alert from "app/components/atoms/alert";
import Loading from "app/components/atoms/loading";
import AreaData from "app/components/atoms/area";
import CardView from "app/components/atoms/card-view";
import AxView from "app/components/atoms/ax-view";
import Image from "app/components/atoms/image";
import { Col, Row } from "antd";
import ProgressBar from "app/components/atoms/ProgressBar";
import { asyncGetStudentListInfo } from "app/api/StudentListApi";
import { FaArrowDown, FaFilter } from 'react-icons/fa';
import StudentDashboard from "app/components/atoms/student-analytics/StudentDashboard";
import { asyncGetStudentAnalyticsInfo } from "app/api/StudentAnalyticsApi";
import { asyncGetEnrollmenetDetailsInfo } from "app/api/EnrollementDetailsApi";
import { asyncGetBatchInfo } from "app/api/BatchApi";
import { imagePath, studentPath } from "app/utils/constant";
import { asyncGetExamStudentAnalyticsInfo } from "app/api/StudentAnalyticsApi";
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

let editData = false;

const noProfile = require("app/pages/images/no_profile.png");
// import { imagePath, studentPath } from "app/utils/constant";

const StudentAnalytics = ({ navigation }) => {

    const dispatch = useDispatch();
    const customerFormRef = useRef();

    const getFeesTypeSuccess = useSelector((state) => state.feesTypeReducer.getFeesTypeSuccess);
    const getFeesTypeList = useSelector((state) => state.feesTypeReducer.getFeesTypeList);
    const getFeesTypeFailure = useSelector((state) => state.feesTypeReducer.getFeesTypeFailure);

    const createFeesTypeSuccess = useSelector((state) => state.feesTypeReducer.createFeesTypeSuccess);
    const createFeesTypeData = useSelector((state) => state.feesTypeReducer.createFeesTypeData);
    const createFeesTypeFailure = useSelector((state) => state.feesTypeReducer.createFeesTypeFailure);

    const updateFeesTypeSuccess = useSelector((state) => state.feesTypeReducer.updateFeesTypeSuccess);
    const updateFeesTypeData = useSelector((state) => state.feesTypeReducer.updateFeesTypeData);
    const updateFeesTypeFailure = useSelector((state) => state.feesTypeReducer.updateFeesTypeFailure);

    const feesTypeErrorMessage = useSelector((state) => state.feesTypeReducer.errorMessage);

    const [pageItems, setpageItems] = useState({
        "view": true,
        "create": true,
        "update": true,
        "deleteOption": false
    }
    );

    useEffect(() => {
        /* let data = pagePermission(pageItems, "Administrator", "Fees Type")
        setpageItems(data) */

    }, [navigation]);

    const column = [
        {
            "dataField": "index",
            "text": "S.No",
            "sort": true,
            formatter: (cellContent, row, index) => (
                index + 1
            )
        },
        {
            "dataField": "feesTypeName",
            "text": "Fees Type Name",
            "sort": true
        },
        {
            "dataField": "installment",
            "text": "Installment",
            "sort": true,
        },
        {
            dataField: "isActive",
            text: "Status",
            sort: true,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => {
                const conInfo = { inputType: "status", status: cellContent }
                return formRender(null, null, conInfo, null);
            }
        },
        /*   {
              text: "Action",
              sort: false,
              align: "center",
              headerAlign: "center",
              formatter: (cellContent, row, index) => (
                  <div >
                     { pageItems.update && <FiEdit
                          className="text-success cursor-pointer"
                          size={18}
                          onClick={() => onEditForm(row, index)}
                      ></FiEdit>}
                      {pageItems.deleteOption && <MdDelete
                          className="text-danger cursor-pointer"
                          size={18}
                          onClick={() => {
                              swal
                                  .fire({
                                      title: "Are you sure?",
                                      text: "You won't be able to revert this!",
                                      icon: "warning",
                                      type: "question",
                                      showCancelButton: true,
                                      confirmButtonColor: "#3085d6",
                                      cancelButtonColor: "#d33",
                                      confirmButtonText: "Yes, delete it!",
                                      cancelButtonText: "No",
                                  })
                                  .then((result) => {
                                      if (result.value) {
                                          
                                          onDeleteItem(row, index);
                                      } else {
                                          swal.fire({
                                              title: "Cancelled!",
                                              text: "Permission denied.",
                                              type: "error",
                                              icon: "error",
                                              timer: 1500,
                                          });
                                      }
                                  });
                          }}
                      ></MdDelete>}
                      {!pageItems.update && !pageItems.deleteOption && "No Option Found"}
                  </div>
              )
          }, */
    ]
    if (pageItems.update || pageItems.delete) {
        column.push({
            text: "Action",
            sort: false,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => (
                <div className="icon-container">
                    {pageItems.update && (
                        <FiEdit
                            className="text-primary cursor-pointer"
                            size={18}
                            onClick={() => onEditForm(row, index)}
                        />
                    )}
                    {pageItems.deleteOption && (
                        <MdDelete
                            className="text-danger cursor-pointer"
                            size={18}
                            onClick={() => {
                                swal
                                    .fire({
                                        title: "Are you sure?",
                                        text: "You won't be able to revert this!",
                                        icon: "warning",
                                        type: "question",
                                        showCancelButton: true,
                                        confirmButtonColor: "#3085d6",
                                        cancelButtonColor: "#d33",
                                        confirmButtonText: "Yes, delete it!",
                                        cancelButtonText: "No",
                                    })
                                    .then((result) => {
                                        if (result.value) {
                                            onDeleteItem(row, index);
                                        } else {
                                            swal.fire({
                                                title: "Cancelled!",
                                                text: "Permission denied.",
                                                type: "error",
                                                icon: "error",
                                                timer: 1500,
                                            });
                                        }
                                    });
                            }}
                        />
                    )}
                </div>
            )
        });
    }

    const [state, setState] = useState({
        feesTypeName: "",
        feesTypeStart: "",
        feesTypeEnd: "",
        studentData: [],
    });

    const [createModule, setCreateModule] = useState(false);
    const [loaderModule, setLoaderModule] = useState(false);
    const [editModule, setEditModule] = useState(false);
    const [viewModule, setViewModule] = useState(false);
    const [filterModule, setFilterModule] = useState(false);
    const [deleteModule, setDeleteModule] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [selectedItem, setSelectedItem] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);
    const [studentDetailsView, setstudentDetailsView] = useState(false);

    const listFetching = async () => {

        const requestData = {
            isActive: "true",
            statusId: 1
        }
        let schoolInfoList = await asyncGetEnrollmenetDetailsInfo(requestData)

        let batchList = await asyncGetBatchInfo(requestData)

        setState({
            ...state,
            enrollmentDetailsList: schoolInfoList?.data || [],

            batchList: batchList?.data || [],

        })
        // setLoaderModule(false)

    }


    useEffect(() => {
        /* setLoaderModule(true)
        const requestData = {
            isActive: "true"
        }
        dispatch(getFeesType(requestData)) */
        listFetching()

    }, [navigation]);

    useEffect(() => {
        if (getFeesTypeSuccess) {
            setItemList(getFeesTypeList)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_FEES_TYPE" })
        } else if (getFeesTypeFailure) {
            setItemList([])
            showMessage("warning", feesTypeErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_FEES_TYPE" })
        }
    }, [getFeesTypeSuccess, getFeesTypeFailure]);

    useEffect(() => {
        if (createFeesTypeSuccess) {
            const temp_state = [...itemList, createFeesTypeData[0]];
            setItemList(temp_state)
            closeModule()
            setLoaderModule(false)
            Alert({ description: 'Created Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            dispatch({ type: "RESET_CREATE_FEES_TYPE" })
        } else if (createFeesTypeFailure) {
            setLoaderModule(false)
            showMessage("warning", feesTypeErrorMessage?.message)
            dispatch({ type: "RESET_CREATE_FEES_TYPE" })
        }
    }, [createFeesTypeSuccess, createFeesTypeFailure]);

    useEffect(() => {
        if (updateFeesTypeSuccess) {
            updateTable(updateFeesTypeData[0])
            dispatch({ type: "RESET_UPDATE_FEES_TYPE" })

        } else if (updateFeesTypeFailure) {
            setLoaderModule(false)
            showMessage("warning", feesTypeErrorMessage?.message)
            dispatch({ type: "RESET_UPDATE_FEES_TYPE" })
        }
    }, [updateFeesTypeSuccess, updateFeesTypeFailure]);

    const updateTable = (updatedItem) => {
        const temp_state = [...itemList];
        if (deleteModule) {
            let remainingList = _.remove(temp_state, function (n) {
                return n.feesTypeId != updatedItem.feesTypeId;
            });
            Alert({ description: 'Deleted Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(remainingList)
        } else {
            temp_state[selectedIndex] = updatedItem;
            Alert({ description: 'Updated Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(temp_state)
        }
        setLoaderModule(false)
        closeModule()
    }

    const closeModule = () => {
        editData = false;
        setCreateModule(false)
        setDeleteModule(false)
    }

    const resetState = () => {
        setState({
            ...state,
            feesTypeName: "",
            installment: ""
        })
    }

    const onCreateForm = () => {
        resetState()
        editData = false;
        setCreateModule(true)
    }

    const onEditForm = (data, index) => {
        setState({
            ...state,
            feesTypeName: data?.feesTypeName || "",
            installment: data?.installment || ""
        })
        editData = true;
        setSelectedIndex(index)
        setSelectedItem(data)
        setCreateModule(true)
    }

    const onDeleteItem = (data, index) => {
        editData = false;
        setSelectedIndex(index)
        setSelectedItem(data)
        setDeleteModule(true)
    }

    const validateForm = async () => {
        const validatefom = customerFormRef.current.validateForm();
    }

    const onSubmitForm = () => {
        const requestDate = {
            feesTypeName: feesTypeName,
            installment: installment
        }
        if (editData) {
            setLoaderModule(true)
            dispatch(updateFeesType(requestDate, selectedItem.feesTypeId))
        } else if (deleteModule) {
            const deleteRequest = {
                isActive: false
            }
            setLoaderModule(true)
            dispatch(updateFeesType(deleteRequest, selectedItem.feesTypeId))
        } else {
            setLoaderModule(true)
            dispatch(createFeesType(requestDate))
        }
    }

    const handleClick = (buttonName) => {
        if (buttonName == 'Add') {
            onCreateForm();
        }
    };

    const {
        feesTypeName,
        installment
    } = state;

    const modelTitle = editData ? "Edit " : "Create ";
    const modelButtonLbl = editData ? "" : "Save";
    const { sidebarOpen } = useSidebar();

    const onFilterStudent = async () => {
        const enrollment_Id = state?.enrollment_Id || '';
        const batch_Id = state?.batch_Id || '';
        const studentEnrollmentId = state?.studentEnrollmentId || '';
        if (!enrollment_Id || !batch_Id) {
            Alert({ description: 'Select Batch and Class.', type: 'warning' });
            return false
        }
        if (state.studentEnrollmentId) {
            setFilterModule(false)
        }
        let request = {
            "batchId": batch_Id,
            "enrollmentId": enrollment_Id,
            "studentEnrollmentId": studentEnrollmentId,
            isActive: true
        }
        setLoaderModule(true)

        let studentData = await asyncGetStudentAnalyticsInfo(request);
        setState({
            ...state,
            studentData: studentData?.data || [],
            studentEnrollmentId: ''
        })
        setLoaderModule(false)


    }
    const onFilter = async () => {
        const enrollment_Id = state?.enrollment_Id || '';
        const batch_Id = state?.batch_Id || '';
        if (!enrollment_Id || !batch_Id) {
            Alert({ description: 'Select Batch and Class.', type: 'warning' });
            return false
        }
        let request = {
            "batchId": batch_Id,
            "enrollmentId": enrollment_Id,
            isActive: true
        }
        setLoaderModule(true)

        let studentData = await asyncGetStudentListInfo(request);
        setState({
            ...state,
            studentList: studentData?.data || [],
        })
        setLoaderModule(false)
        setFilterModule(true)

    }


    const headerLayout = (student) => {
        if (!student) return null; // Handle case where student data is not provided

        // Define the profile URL
        const profile = `${imagePath}${studentPath}${student?.studentToken || ""}/${encodeURI(student?.profileImage || "")}`;

        return (
            <div>
                <div style={{ height: '60px', width: '100%', padding: '10px' }}>
                    <Row style={{ alignItems: 'center' }}>
                        <img
                            src={profile}
                            style={{
                                width: "40px",
                                height: "40px",
                                borderRadius: "50%",
                                objectFit: "cover"
                            }}
                            alt="Student Profile"
                        />
                        <Col style={{ paddingLeft: '10px' }}>
                            <h6 className="text-capitalize">{student.studentName}</h6>
                            {/* <p style={{ fontSize: "12px" }}>Grade: {student.grade || "Not Available"}</p> */}
                        </Col>
                    </Row>
                </div>
                <div style={{ height: '80px', width: '100%', padding: '10px', backgroundColor: '#f7f7fa' }}>
                    <h6 className="text-capitalize">Assignments</h6>
                    <Row style={{ padding: '5px' }}>
                        <Col style={{ width: '80px', justifyItems: 'center' }}>
                            <p style={{ fontSize: '20px', fontWeight: '500' }}>{student.assignmentsGiven}</p>
                            <p className="text-capitalize" style={{ fontSize: "12px" }}>Total</p>
                        </Col>
                        <Col style={{ width: '80px', justifyItems: 'center' }}>
                            <p style={{ fontSize: '20px', fontWeight: '500' }}>{student.homeWorkdue}</p>
                            <p className="text-capitalize" style={{ fontSize: "12px" }}>Due</p>
                        </Col>
                        <Col style={{ width: '80px', justifyItems: 'center' }}>
                            <p style={{ fontSize: '20px', fontWeight: '500' }}>{student.submissionMissed}</p>
                            <p className="text-capitalize" style={{ fontSize: "12px" }}>Missing</p>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    };



    const studentView = async (studentEnrollmentId) => {
        let studentData = state?.studentData || [];

        // Filter to find objects that match the studentEnrollmentId
        let filteredData = studentData.filter(
            (student) => student.studentEnrollmentId === studentEnrollmentId
        );
        let studentExamData = await asyncGetExamStudentAnalyticsInfo(studentEnrollmentId);


        setstudentDetailsView(true)
        setState({
            ...state,
            studentFilterData: filteredData,
            studentExamData: studentExamData?.data[0]?.examDetails || [],
            studentExamChart: studentExamData?.data[0]?.examAnalytics || [],
            attendanceDetails: studentExamData?.data[0]?.attendanceDetails || [],
            assignmentDetails: studentExamData?.data[0]?.assignmentDetails || [],

        })
    }

    useEffect(() => {
        setState({
            ...state,
            studentEnrollmentId: ''
        })
    }, [!filterModule])

    return (
        <AxView>
            <Loading status={loaderModule} />

            <div style={sidebarOpen ? { marginLeft: "20px", marginTop: "20px" } : { marginLeft: "100px", marginTop: "20px" }}>

                {/* First Row: FormLayout and Button */}
                <Row>
                    <Col >
                        <FormLayout
                            defaultState={state}
                            setDefaultState={setState}
                            dynamicForm={homeWorkForm}
                            noOfColumn={2}
                            onSubmit={onSubmitForm}
                        />
                        <DynamicButton
                            text="Search"
                            onClick={onFilterStudent}
                            style={{ marginTop: "-60px", marginBottom: "10px" }}
                        />
                    </Col>
                    <Col>
                        <FaFilter size={20} onClick={onFilter} style={{ margin: "25px", cursor: "pointer" }} />
                    </Col>
                </Row>

                {/* Second Row: CardView */}
                <Row>
                    <>
                        {state?.studentData.length === 0 ? (
                            <p style={{ textAlign: 'center', fontSize: '14px', color: '#777' }}>No data available</p>
                        ) : (
                            state.studentData.slice(0, 3).map((student, studentIndex) => (
                                <Col key={student.studentEnrollmentId}>
                                    {console.log("infinity")}
                                    <CardView
                                        style={{ borderRadius: '6px', padding: 0, margin: '30px', width: '83%' }}
                                        headerView={headerLayout(student)}
                                    >
                                        <div>
                                            {Object.keys(student.examAverage).length > 0 ? (
                                                <>
                                                    <ResponsiveContainer height={150}>
                                                        <BarChart
                                                            data={Object.keys(student.examAverage).slice(0, 3).map((subject) => ({
                                                                subject,
                                                                percentage: parseFloat(student.examAverage[subject]),
                                                            }))}
                                                        >
                                                            <XAxis dataKey="subject" axisLine={false} tickLine={false} />
                                                            <YAxis hide />
                                                            <Tooltip />
                                                            <Bar dataKey="percentage" fill="#8884d8" barSize={20} />
                                                        </BarChart>
                                                    </ResponsiveContainer>
                                                    <div
                                                        style={{
                                                            textAlign: 'center',
                                                            marginBottom: '10px',
                                                            float: 'right',
                                                            cursor: 'pointer',
                                                            padding: '5px 0',
                                                        }}
                                                        onClick={() => studentView(student.studentEnrollmentId)}
                                                    >
                                                        View More<b>...</b>
                                                    </div>
                                                </>
                                            ) : (
                                                <p style={{ textAlign: 'center', fontSize: '12px', color: '#999' }}>
                                                    No exam data available for {student.studentName}.
                                                </p>
                                            )}
                                        </div>
                                    </CardView>
                                </Col>
                            ))
                        )}
                    </>
                </Row>

            </div>

            <View size="fullscreen" isOpen={studentDetailsView} onClose={setstudentDetailsView} title="Student Analytics">
                <StudentDashboard studentFilterData={state?.studentFilterData || []} studentExamData={state?.studentExamData || []} studentExamChart={state?.studentExamChart || []} state={state} />
            </View>
            <View size="sm" isOpen={filterModule} onClose={setFilterModule} onSubmit={onFilterStudent} title="Student Filter">
                <FormLayout
                    defaultState={state}
                    setDefaultState={setState}
                    dynamicForm={studentFilterForm}
                    noOfColumn={1}
                />
            </View>
        </AxView>

    )
}

export default StudentAnalytics;