import * as yup from "yup";
import { Formik } from "formik";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import DateTime from "react-datetime";
import { Form, Button } from "react-bootstrap";
import { DateRange } from "react-date-range";
import SimpleCard from 'app/components/atoms/simple-card/SimpleCard'
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { MdEdit, MdDelete } from "react-icons/md";
import swal from "sweetalert2";
import _ from "lodash";
import { pagePermission, showMessage } from "app/utils/app-functions";
import FormLayout from "app/components/render/formlayout";
import View from "app/components/atoms/modalView/Modalview";
import CustomTable from "app/components/atoms/custom-table/CustomTable";
import formRender from "app/components/render/formcomponent";
import { schoolInfoForm } from "./formData";
import { createSchoolInfo, getSchoolInfo, updateSchoolInfo } from "app/api/SchoolInfoApi ";
import { getState } from "app/api/StateApi";
import { getCity } from "app/api/CityApi";
import Alert from "app/components/atoms/alert";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import { asyncGetStateInfo } from "app/api/StateApi";
import { asyncGetCityInfo } from "app/api/CityApi";
import { useSidebar } from "app/components/sidebar/sidebarContext";

let editData = false;

const SchoolInfo = ({ navigation }) => {

    const dispatch = useDispatch();
    const customerFormRef = useRef();

    const getSchoolInfoSuccess = useSelector((state) => state.schoolInfoReducer.getSchoolInfoSuccess);
    const getSchoolInfoList = useSelector((state) => state.schoolInfoReducer.getSchoolInfoList);
    const getSchoolInfoFailure = useSelector((state) => state.schoolInfoReducer.getSchoolInfoFailure);

    const createSchoolInfoSuccess = useSelector((state) => state.schoolInfoReducer.createSchoolInfoSuccess);
    const createSchoolInfoData = useSelector((state) => state.schoolInfoReducer.createSchoolInfoData);
    const createSchoolInfoFailure = useSelector((state) => state.schoolInfoReducer.createSchoolInfoFailure);

    const updateSchoolInfoSuccess = useSelector((state) => state.schoolInfoReducer.updateSchoolInfoSuccess);
    const updateSchoolInfoData = useSelector((state) => state.schoolInfoReducer.updateSchoolInfoData);
    const updateSchoolInfoFailure = useSelector((state) => state.schoolInfoReducer.updateSchoolInfoFailure);

    const getCitySuccess = useSelector((state) => state.cityReducer.getCitySuccess);
    const getCityList = useSelector((state) => state.cityReducer.getCityList);
    const getCityFailure = useSelector((state) => state.cityReducer.getCityFailure);
    const cityErrorMessage = useSelector((state) => state.cityReducer.errorMessage);

    const getStateSuccess = useSelector((state) => state.stateReducer.getStateSuccess);
    const getStateList = useSelector((state) => state.stateReducer.getStateList);
    const getStateFailure = useSelector((state) => state.stateReducer.getStateFailure);
    const stateErrorMessage = useSelector((state) => state.stateReducer.errorMessage);

    const schoolInfoErrorMessage = useSelector((state) => state.schoolInfoReducer.errorMessage);

    const [pageItems, setpageItems] = useState({});

    useEffect(() => {
        let data = pagePermission(pageItems, "Settings", "School Info")
        setpageItems(data)

    }, [navigation]);

    const column = [
        {
            "dataField": "schoolName",
            "text": "School Name",
            "sort": true
        },
        {
            "dataField": "phoneNumber",
            "text": "Phone Number",
            "sort": true
        },

        {
            "dataField": "email",
            "text": "Email",
            "sort": true
        },
        {
            "dataField": "address1",
            "text": "Address",
            "sort": true
        },
        {
            "dataField": "cityName",
            "text": "City",
            "sort": true
        },
        {
            "dataField": "pincode",
            "text": "Pin Code",
            "sort": true
        },
        {
            "dataField": "stateName",
            "text": "State",
            "sort": true
        },
        /* 
        {
            text: "Action",
            sort: false,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => (
                <div >
                    <MdEdit
                        className="text-success cursor-pointer"
                        size={18}
                        onClick={() => onEditForm(row, index)}
                    ></MdEdit>
                    <MdDelete
                        className="text-danger cursor-pointer"
                        size={18}
                        onClick={() => {
                            swal
                                .fire({
                                    title: "Are you sure?",
                                    text: "You won't be able to revert this!",
                                    icon: "warning",
                                    type: "question",
                                    showCancelButton: true,
                                    confirmButtonColor: "#3085d6",
                                    cancelButtonColor: "#d33",
                                    confirmButtonText: "Yes, delete it!",
                                    cancelButtonText: "No",
                                })
                                .then((result) => {
                                    if (result.value) {

                                        onDeleteItem(row, index);
                                    } else {
                                        swal.fire({
                                            title: "Cancelled!",
                                            text: "Permission denied.",
                                            type: "error",
                                            icon: "error",
                                            timer: 1500,
                                        });
                                    }
                                });
                        }}
                    ></MdDelete>
                </div>
            )
        }, */
    ]

    if (pageItems.delete || pageItems.update) {
        column.push({
            text: "Action",
            sort: false,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => (
                <div className="icon-container">
                    {pageItems.update && <FiEdit
                        className="text-primary cursor-pointer"
                        size={18}
                        onClick={() => onEditForm(row, index)}
                    ></FiEdit>}
                    {pageItems.delete && <FiTrash2
                        className="text-danger cursor-pointer"
                        size={18}
                        onClick={() => {
                            onDeleteItem(row, index);

                        }}
                    ></FiTrash2>}
                </div>
            )
        })
    }

    const [state, setState] = useState({
        cityList: [],
        stateList: [],
    });

    const [createModule, setCreateModule] = useState(false);
    const [loaderModule, setLoaderModule] = useState(false);
    const [deleteModule, setDeleteModule] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [selectedItem, setSelectedItem] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);


    const listFetching = async () => {
        const requestData = {
            isActive: "true",
            statusId: 1
        }

        let stateList = await asyncGetStateInfo(requestData)
        let countryList = await asyncGetCityInfo(requestData)

        setState({
            ...state,
            stateList: stateList?.data || [],
            cityList: countryList?.data || [],
        })
        // setLoaderModule(false)

    }
    useEffect(() => {
        setLoaderModule(true)
        const requestData = {
            isActive: "true"
        }
        dispatch(getSchoolInfo(requestData))
        listFetching()
    }, [navigation]);



    useEffect(() => {
        if (deleteModule) {
            confromDialog()
        }
    }, [deleteModule])

    const confromDialog = () => {
        return (
            swal
                .fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    type: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!",
                    cancelButtonText: "No",
                })
                .then((result) => {
                    if (result.value) {
                        onSubmitForm()
                    } else {
                        swal.fire({
                            title: "Cancelled!",
                            text: "Permission denied.",
                            type: "error",
                            icon: "error",
                            timer: 1500,
                        });
                    }
                })
        )
    }


    useEffect(() => {
        if (getCitySuccess) {
            setState({
                ...state,
                cityList: getCityList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_CITY" })
        } else if (getCityFailure) {
            setState({
                ...state,
                cityList: []
            })
            showMessage("warning", cityErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_CITY" })
        }
    }, [getCitySuccess, getCityFailure]);



    useEffect(() => {
        if (getStateSuccess) {
            setState({
                ...state,
                stateList: getStateList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_STATE" })
        } else if (getStateFailure) {
            setState({
                ...state,
                stateList: []
            })
            showMessage("warning", stateErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_STATE" })
        }
    }, [getStateSuccess, getStateFailure]);

    useEffect(() => {
        if (getSchoolInfoSuccess) {
            setItemList(getSchoolInfoList)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_SCHOOL_INFO" })
        } else if (getSchoolInfoFailure) {
            setItemList([])
            showMessage("warning", schoolInfoErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_SCHOOL_INFO" })
        }
    }, [getSchoolInfoSuccess, getSchoolInfoFailure]);

    useEffect(() => {
        if (createSchoolInfoSuccess) {
            const temp_state = [...itemList, createSchoolInfoData[0]];
            setItemList(temp_state)
            setLoaderModule(false)
            closeModule()
            Alert({ description: 'Created Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            dispatch({ type: "RESET_CREATE_SCHOOL_INFO" })
        } else if (createSchoolInfoFailure) {
            setLoaderModule(false)
            showMessage("warning", schoolInfoErrorMessage?.message)
            dispatch({ type: "RESET_CREATE_SCHOOL_INFO" })
        }
    }, [createSchoolInfoSuccess, createSchoolInfoFailure]);


    useEffect(() => {
        if (updateSchoolInfoSuccess) {
            updateTable(updateSchoolInfoData[0])
            dispatch({ type: "RESET_UPDATE_SCHOOL_INFO" })
        } else if (updateSchoolInfoFailure) {
            setLoaderModule(false)
            showMessage("warning", schoolInfoErrorMessage)
            dispatch({ type: "RESET_UPDATE_SCHOOL_INFO" })
        }
    }, [updateSchoolInfoSuccess, updateSchoolInfoFailure]);

    const updateTable = (updatedItem) => {
        const temp_state = [...itemList];
        if (deleteModule) {
            let remainingList = _.remove(temp_state, function (n) {
                return n.schoolInfoId != updatedItem.schoolInfoId;
            });
            Alert({ description: 'Deleted Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(remainingList)
        } else {
            temp_state[selectedIndex] = updatedItem;
            Alert({ description: 'Updated Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(temp_state)
        }
        setLoaderModule(false)
        closeModule()
    }

    const closeModule = () => {
        editData = false;
        setCreateModule(false)
        setDeleteModule(false)
    }

    const resetState = () => {
        setState({
            ...state,
            phoneNumber: "+91 ",
            cityId: "",
            email: "",
            landMark: "",
            pincode: "",
            schoolName: "",
            stateId: "",
            address2: "",
            address1: "",
        })
    }

    const onCreateForm = () => {
        resetState()
        editData = false;
        setCreateModule(true)
    }

    const onEditForm = (data, index) => {
        setState({
            ...state,
            phoneNumber: data?.phoneNumber || "",
            cityId: data?.cityId || "",
            schoolName: data?.schoolName || "",
            email: data?.email || "",
            landMark: data?.landMark || "",
            pincode: data?.pincode || "",
            stateId: data?.stateId || "",
            address1: data?.address1 || "",
            address2: data?.address2 || ""
        })
        editData = true;
        setSelectedIndex(index)
        setSelectedItem(data)
        setCreateModule(true)
    }

    const onDeleteItem = (data, index) => {
        editData = false;
        setSelectedIndex(index)
        setSelectedItem(data)
        setDeleteModule(true)
    }

    const validateForm = async () => {
        const validatefom = customerFormRef.current.validateForm();
    }

    const onSubmitForm = () => {
        const requestDate = {
            phoneNumber: state?.phoneNumber || "",
            cityId: state?.cityId || "",
            email: state?.email || "",
            schoolName: state?.schoolName || "",
            // location: state?.location || "",
            pincode: state?.pincode || "",
            landMark: state?.landMark || "",
            stateId: state?.stateId || "",
            address1: state?.address1 || "",
            address2: state?.address2 || "",
        }
        if (editData) {
            setLoaderModule(true)
            dispatch(updateSchoolInfo(requestDate, selectedItem.schoolInfoId))
        } else if (deleteModule) {
            const deleteRequest = {
                isActive: "false"
            }
            setLoaderModule(true)
            dispatch(updateSchoolInfo(deleteRequest, selectedItem.schoolInfoId))
        } else {
            setLoaderModule(true)
            dispatch(createSchoolInfo(requestDate))
        }
    }

    // const onViewForm = (data, index) => {
    //     editData = false;
    //     // setViewModule(true)
    //     console.log(JSON.stringify(data));
    //     setState({
    //         ...state,
    //         viewData: data,
    //     })
    // }

    const {
        holidayName,
        holidayDate,
    } = state;

    const modelTitle = editData ? "Edit " : "Create ";
    const modelButtonLbl = editData ? "" : "Save";

    const handleClick = (buttonName) => {
        if (buttonName == 'Add') {
            onCreateForm();
        }
    };
    const { sidebarOpen } = useSidebar();


    return (
        <div>
            <div style={sidebarOpen ? { marginLeft: "10px", marginTop: "-10px" } : { marginLeft: "10px", marginTop: "-50px" }}>

                <View isOpen={createModule} size="md" savetitle={modelButtonLbl} onClose={setCreateModule} onSubmit={validateForm} title={`${modelTitle} School Info`}>
                    <FormLayout ref={customerFormRef} defaultState={state} setDefaultState={setState} dynamicForm={schoolInfoForm} noOfColumn={2} onSubmit={onSubmitForm} />
                </View>

                <SimpleCard >
                    <CustomTable columns={column} data={itemList} buttonData={pageItems.create ? ['add'] : []} handleClick={handleClick} tableName={"School Info"} state={state} />
                </SimpleCard>
            </div>
        </div>
    )
};

export default SchoolInfo;
