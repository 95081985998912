import * as yup from "yup";
import { Formik } from "formik";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import DateTime from "react-datetime";
import { Form, Button } from "react-bootstrap";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import FormLayout from "app/views/formlayout";
import { markSheetForm, extraMarkForm } from "./formData";
import { MdEdit, MdDelete } from "react-icons/md";
import swal from "sweetalert2";
import _ from "lodash";
import formRender from "app/utils/formcomponent";
import { createMarkSheet, getMarkSheet, updateMarkSheet } from "app/api/MarkSheetApi";
import { firstLetterCaptial, formatStr, pagePermission, showMessage } from "app/utils/app-functions";
import { getBatch } from "app/api/BatchApi";
import { getEnrollmentDetails } from "app/api/EnrollementDetailsApi";
import { getExamType } from "app/api/ExamTypeApi";
import FilterComponent from "app/components/FilterComponent";
import { getExam } from "app/api/ExamApi";
import { getExamListData } from "app/api/ExamApi";
import { getSubjectEnrollment } from "app/api/SubjectEnrollmentApi";
import TableCustomeCellEditor from "app/components/CustomeCellEditor";
import { getStudentClassList } from "app/api/StudentListApi";
import SimpleCard from "app/components/atoms/simple-card/SimpleCard";
import CustomTable from "app/components/atoms/custom-table/CustomTable";
import View from "app/components/atoms/modalView/Modalview";
import { asyncGetBatchInfo } from "app/api/BatchApi";
import { asyncGetExamTypeInfo } from "app/api/EnquiryTypeApi";
import { MarksheetLayout } from "./DisplayMarkList";
import { asyncGetEnrollmenetDetailsInfo } from "app/api/EnrollementDetailsApi";
import { asyncGetExamTypeListInfo } from "app/api/ExamTypeApi";
import { asyncGetExamListInfo } from "app/api/ExamApi";
import Alert from "app/components/atoms/alert";
import { useSidebar } from "app/components/sidebar/sidebarContext";
import { lightTheme } from "styles/theme";
import { getAsyncStudentClassList } from "app/api/StudentListApi";


let editData = false;
let columns = false

const Marksheet = ({ navigation }) => {

    const dispatch = useDispatch();
    const customerFormRef = useRef();

    const getMarkSheetSuccess = useSelector((state) => state.markSheetReducer.getMarkSheetSuccess);
    const getMarkSheetList = useSelector((state) => state.markSheetReducer.getMarkSheetList);
    const getMarkSheetFailure = useSelector((state) => state.markSheetReducer.getMarkSheetFailure);

    const getSubjectEnrollmentSuccess = useSelector((state) => state.subjectEnrollmentReducer.getSubjectEnrollmentSuccess);
    const getSubjectEnrollmentList = useSelector((state) => state.subjectEnrollmentReducer.getSubjectEnrollmentList);
    const getSubjectEnrollmentFailure = useSelector((state) => state.subjectEnrollmentReducer.getSubjectEnrollmentFailure);
    const subjectEnrollmentErrorMessage = useSelector((state) => state.subjectEnrollmentReducer.errorMessage);

    const getBatchSuccess = useSelector((state) => state.batchReducer.getBatchSuccess);
    const getBatchList = useSelector((state) => state.batchReducer.getBatchList);
    const getBatchFailure = useSelector((state) => state.batchReducer.getBatchFailure);
    const batchErrorMessage = useSelector((state) => state.batchReducer.errorMessage);

    const getEnrollmentDetailsSuccess = useSelector((state) => state.enrollmentDetailsReducer.getEnrollmentDetailsSuccess);
    const getEnrollmentDetailsList = useSelector((state) => state.enrollmentDetailsReducer.getEnrollmentDetailsList);
    const getEnrollmentDetailsFailure = useSelector((state) => state.enrollmentDetailsReducer.getEnrollmentDetailsFailure);
    const enrollmentDetailsErrorMessage = useSelector((state) => state.enrollmentDetailsReducer.errorMessage);

    const getExamTypeSuccess = useSelector((state) => state.examTypeReducer.getExamTypeSuccess);
    const getExamTypeList = useSelector((state) => state.examTypeReducer.getExamTypeList);
    const getExamTypeFailure = useSelector((state) => state.examTypeReducer.getExamTypeFailure);
    const examTypeErrorMessage = useSelector((state) => state.examTypeReducer.errorMessage);

    const getExamSuccess = useSelector((state) => state.examReducer.getExamSuccess);
    const getExamList = useSelector((state) => state.examReducer.getExamList);
    const getExamFailure = useSelector((state) => state.examReducer.getExamFailure);
    const examErrorMessage = useSelector((state) => state.examReducer.errorMessage);

    const getExamListSuccess = useSelector((state) => state.examReducer.getExamListSuccess);
    const getExamListList = useSelector((state) => state.examReducer.getExamListList);
    const getExamListFailure = useSelector((state) => state.examReducer.getExamListFailure);
    const examListErrorMessage = useSelector((state) => state.examReducer.errorMessage);

    const createMarkSheetSuccess = useSelector((state) => state.markSheetReducer.createMarkSheetSuccess);
    const createMarkSheetData = useSelector((state) => state.markSheetReducer.createMarkSheetData);
    const createMarkSheetFailure = useSelector((state) => state.markSheetReducer.createMarkSheetFailure);

    const getStudentClassListSuccess = useSelector((state) => state.studentListReducer.getStudentClassListSuccess);
    const getStudentClassListList = useSelector((state) => state.studentListReducer.getStudentClassListList);
    const getStudentClassListFailure = useSelector((state) => state.studentListReducer.getStudentClassListFailure);
    const studentClassListErrorMessage = useSelector((state) => state.studentListReducer.errorMessage);

    const updateMarkSheetSuccess = useSelector((state) => state.markSheetReducer.updateMarkSheetSuccess);
    const updateMarkSheetData = useSelector((state) => state.markSheetReducer.updateMarkSheetData);
    const updateMarkSheetFailure = useSelector((state) => state.markSheetReducer.updateMarkSheetFailure);

    const markSheetErrorMessage = useSelector((state) => state.markSheetReducer.errorMessage);

    const column = [
        {
            "dataField": "markSheetName",
            "text": "MarkSheet Name",
            "sort": true
        },
        {
            "dataField": "description",
            "text": "Description ",
            "sort": false
        },
        {
            dataField: "isActive",
            text: "Status",
            sort: true,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => {
                const conInfo = { inputType: "status", status: cellContent }
                return formRender(null, null, conInfo, null);
            }
        },
        {
            text: "Action",
            sort: false,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => (
                <div className="icon-container">
                    <MdEdit
                        className="text-success cursor-pointer"
                        size={18}
                        onClick={() => onEditForm(row, index)}
                    ></MdEdit>
                    <MdDelete
                        className="text-danger cursor-pointer"
                        size={18}
                        onClick={() => {
                            swal
                                .fire({
                                    title: "Are you sure?",
                                    text: "You won't be able to revert this!",
                                    icon: "warning",
                                    type: "question",
                                    showCancelButton: true,
                                    confirmButtonColor: "#3085d6",
                                    cancelButtonColor: "#d33",
                                    confirmButtonText: "Yes, delete it!",
                                    cancelButtonText: "No",
                                })
                                .then((result) => {
                                    if (result.value) {
                                        onDeleteItem(row, index);
                                    } else {
                                        swal.fire({
                                            title: "Cancelled!",
                                            text: "Permission denied.",
                                            type: "error",
                                            icon: "error",
                                            timer: 1500,
                                        });
                                    }
                                });
                        }}
                    ></MdDelete>
                </div>
            )
        },
    ]

    const [pageItems, setpageItems] = useState({
        "view": false,
        "create": true,
        "update": false,
        "deleteOption": false
    }
    );

    useEffect(() => {
        let data = pagePermission(pageItems, "Student", "Term Exam Mark Sheet", "MarkSheet")
        setpageItems(data)

    }, [navigation]);

    const [state, setState] = useState({
        subjectEnrollmentList: []
    });

    const [createModule, setCreateModule] = useState(false);
    const [loadingFinishModule, setLoadingFinishModule] = useState(false);
    const [loaderModule, setLoaderModule] = useState(false);
    const [editModule, setEditModule] = useState(false);
    const [viewModule, setViewModule] = useState(false);
    const [deleteModule, setDeleteModule] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [selectedItem, setSelectedItem] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);

    let userInfo = window.localStorage.getItem("userInfo");

    let parsedData = JSON.parse(userInfo);
    const listFetching = async () => {
        const requestData = {
            isActive: "true",
        }
        const req = {
        };
        const reqq = {
            isActive: "true",

            examVariantId: 1
        };
        if (parsedData[0].roleId == 5) {
            req.empCode = parsedData[0]?.userCode || '';
        }

        let batchList = await asyncGetBatchInfo(requestData)
        let enrollmentDetailsList = await getAsyncStudentClassList(req)
        let examType = await asyncGetExamTypeListInfo(reqq)
        let examListList = await asyncGetExamListInfo(reqq)
        setState({
            ...state,
            // schoolList:schoolInfoList?.getSchoolInfoList || [],
            batchList: batchList?.data || [],
            examType: examType?.data || [],
            enrollmentDetailsList: enrollmentDetailsList?.data || [],
            examListList: examListList?.data || [],


        })

    }

    useEffect(() => {
        setLoaderModule(true)
        const requestData = {
            isActive: "true"
        }
        const req = {
            isActive: "true",
            examVariantId: 1
        }
        const req1 = {
        };
        if (global.roleId == 5) {
            req1.empCode = global.UserInfo?.userCode || '';
        }
        // dispatch(getMarkSheet(requestData))
        dispatch(getStudentClassList(req1))
        // dispatch(getBatch(requestData))
        // dispatch(getEnrollmentDetails(requestData))
        // dispatch(getExamType(req))
        listFetching()
    }, [navigation]);

    useEffect(() => {
        if (getMarkSheetSuccess) {
            try {
                const requestData = {
                    "enrollmentId": state?.enrollmentId || '',
                    "isActive": true
                }
                dispatch(getSubjectEnrollment(requestData))

                if ((getMarkSheetList ? getMarkSheetList : []).some(item => 'markSheetId' in item)) {
                    editData = true;
                    setSelectedItem(getMarkSheetList);
                }
                setState({
                    ...state,
                    filterExam: getMarkSheetList?.[0]?.examDetails || []
                })
                const filterRes = []
                getMarkSheetList.map((item) => {
                    filterRes.push(item.markMeta)
                })
                setItemList(filterRes)
            } catch {
                console.log("error");
            }
            // setCreateModule(true)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_MARK_SHEET" })
        } else if (getMarkSheetFailure) {
            setItemList([])
            showMessage("warning", markSheetErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_MARK_SHEET" })
        }
    }, [getMarkSheetSuccess, getMarkSheetFailure]);

    useEffect(() => {
        if (getSubjectEnrollmentSuccess) {
            setState({
                ...state,
                subjectEnrollmentList: getSubjectEnrollmentList
            })
            onColumns(getSubjectEnrollmentList)

            setLoaderModule(false)
            dispatch({ type: "RESET_GET_SUBJECT_ENROLLMENT" })
        } else if (getSubjectEnrollmentFailure) {
            setState({
                ...state,
                subjectEnrollmentList: []
            })
            showMessage("warning", subjectEnrollmentErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_SUBJECT_ENROLLMENT" })
        }
    }, [getSubjectEnrollmentSuccess, getSubjectEnrollmentFailure]);


    useEffect(() => {
        if (getStudentClassListSuccess) {
            setState({
                ...state,
                enrollmentDetailsList: getStudentClassListList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_STUDENT_CLASS_LIST" })
        } else if (getStudentClassListFailure) {
            setState({
                ...state,
                enrollmentDetailsList: []
            })
            showMessage("warning", studentClassListErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_STUDENT_CLASS_LIST" })
        }
    }, [getStudentClassListSuccess, getStudentClassListFailure]);


    useEffect(() => {
        if (getBatchSuccess) {
            setState({
                ...state,
                batchList: getBatchList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_BATCH" })
        } else if (getBatchFailure) {
            setState({
                ...state,
                batchList: []
            })
            showMessage("warning", batchErrorMessage?.message, "Batch")
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_BATCH" })
        }
    }, [getBatchSuccess, getBatchFailure]);

    useEffect(() => {
        if (getEnrollmentDetailsSuccess) {
            setState({
                ...state,
                enrollmentDetailsList: getEnrollmentDetailsList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_ENROLLMENT_DETAILS" })
        } else if (getEnrollmentDetailsFailure) {
            setState({
                ...state,
                enrollmentDetailsList: []
            })
            showMessage("warning", enrollmentDetailsErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_ENROLLMENT_DETAILS" })
        }
    }, [getEnrollmentDetailsSuccess, getEnrollmentDetailsFailure]);

    useEffect(() => {
        if (getExamTypeSuccess) {
            setState({
                ...state,
                examType: getExamTypeList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_EXAM_TYPE" })
        } else if (getExamTypeFailure) {
            setState({
                ...state,
                examType: []
            })
            showMessage("warning", examTypeErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_EXAM_TYPE" })
        }
    }, [getExamTypeSuccess, getExamTypeFailure]);

    useEffect(() => {
        if (getExamSuccess) {
            setState({
                ...state,
                examList: getExamList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_EXAM" })
        } else if (getExamFailure) {
            setState({
                ...state,
                examList: []
            })
            showMessage("warning", examErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_EXAM" })
        }
    }, [getExamSuccess, getExamFailure]);

    useEffect(() => {
        if (getExamListSuccess) {
            setState({
                ...state,
                examListList: getExamListList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_EXAM_LIST" })
        } else if (getExamListFailure) {
            setState({
                ...state,
                examListList: []
            })
            showMessage("warning", examListErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_EXAM_LIST" })
        }
    }, [getExamListSuccess, getExamListFailure]);

    useEffect(() => {
        if (createMarkSheetSuccess) {
            const temp_state = [...itemList, createMarkSheetData[0]];
            setItemList(temp_state)
            setLoaderModule(false)
            closeModule()
            showMessage("success", "Created Successfully")
            Alert({ description: 'Created Successfully ', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });

            dispatch({ type: "RESET_CREATE_MARK_SHEET" })
        } else if (createMarkSheetFailure) {
            setLoaderModule(false)
            showMessage("warning", markSheetErrorMessage?.message)
            dispatch({ type: "RESET_CREATE_MARK_SHEET" })
        }
    }, [createMarkSheetSuccess, createMarkSheetFailure]);


    useEffect(() => {
        if (updateMarkSheetSuccess) {
            // updateTable(updateMarkSheetData[0])
            editData = false;
            setLoaderModule(false)
            Alert({ description: 'Updated Successfully ', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });

            /*  setItemList([])
             columns =[] */
            closeModule()
            dispatch({ type: "RESET_UPDATE_MARK_SHEET" })
        } else if (updateMarkSheetFailure) {
            setLoaderModule(false)
            showMessage("warning", markSheetErrorMessage?.message)
            dispatch({ type: "RESET_UPDATE_MARK_SHEET" })
        }
    }, [updateMarkSheetSuccess, updateMarkSheetFailure]);

    const updateTable = (updatedItem) => {
        const temp_state = [...itemList];
        if (deleteModule) {
            let remainingList = _.remove(temp_state, function (n) {
                return n.markSheetId != updatedItem.markSheetId;
            });
            showMessage("success", "Deleted Successfully")
            setItemList(remainingList)
        } else {
            temp_state[selectedIndex] = updatedItem;
            showMessage("success", "Updated Successfully")
            setItemList(temp_state)
        }
        setLoaderModule(false)
        closeModule()
    }

    /* const onhandleChange = (cell, row, rowIndex, formatExtraData) => {
        alert(cell)
        alert(JSON.stringify(row))
        alert(rowIndex)
        alert(formatExtraData)
    } */
    const formatStr = (str) => {
        return str ? str.toLowerCase().replace(/\s+/g, '') : ''; // Check if str is not null before calling toLowerCase
    };

    const onColumns = (data) => {
        if ((pageItems.create && pageItems.update) || pageItems.view) {
            columns = [
                {
                    dataField: "studentName",
                    text: "Student / Subject",
                    // formatter: onhandleChange,
                    editable: false,
                    subjectName: null
                },
                {
                    dataField: "total",
                    text: "Total",
                    // formatter: onhandleChange,
                    editable: false,
                    subjectName: null
                },
                {
                    dataField: "result",
                    text: "Result",
                    // formatter: onhandleChange,
                    editable: false,
                    subjectName: null
                }
            ]
            let temp_arr = []
            if (data && data.length > 0) {
                temp_arr = data[0].subjectList
            }
            else {
                Alert({ type: "warning", description: "Enroll Subject to Assign Marks" })
                return false
            }

            setState({
                ...state,
                subjectList: data?.[0]?.subjectList || []
            })
            if (temp_arr.length > 0) {
                temp_arr.map((item, index) => {
                    const examData = state?.filterExam || [];
                    let req = {
                        dataField: `${formatStr(item.subjectName)}`,
                        text: `${(item.subjectName).replace(/\s+/g, '')}`,
                        editable: false,
                        subjectId: item.subjectId,
                        subjectName: `${formatStr(item.subjectName)}`
                    };

                    examData.map((val) => {
                        if (formatStr(val.subjectTitle) === formatStr(item.subjectName)) {
                            delete req.editable;
                        }
                    });

                    columns.splice(index + 1, 0, req);
                });

                setCreateModule(true);
            } else {
                console.error("Data array is empty or undefined.");
            }

            const updatedExamData = processExamData(state?.filterExam || []);
        }
    }


    const processExamData = (examData) => {

        return itemList.map((item, index) => {
            examData.forEach((ele) => {
                let marks = [
                    {
                        dataField: "internalMark",
                        value: item[`${formatStr(ele.subjectTitle)}Marks`]?.[0]?.internalMark || "",
                        text: ele.subjectTitle,
                        editable: ele.internalMark > 0 ? true : false
                    },
                    {
                        dataField: "practicalMark",
                        value: item[`${formatStr(ele.subjectTitle)}Marks`]?.[1]?.practicalMark || "",
                        text: ele.subjectTitle,
                        editable: ele.practicalMark > 0 ? true : false
                    }
                ];
                itemList[index][`${formatStr(ele.subjectTitle)}Marks`] = marks;

                /* console.log(JSON.stringify(itemList[index][`${ele.subjectTitle.replace(/\s+/g, '')}Marks`]));
                return   item = {
                    ...item,
                    [`${ele.subjectTitle}Marks`]: marks
                }; */
            });
        });


    };

    const closeModule = () => {
        editData = false;
        setCreateModule(false)
        setDeleteModule(false)
    }

    const resetState = () => {
        setState({
            ...state,
            markSheetName: "",
            description: ""
        })
    }

    const onCreateForm = () => {
        resetState()
        editData = false;
        setCreateModule(true)
    }

    const onEditForm = (data, index) => {
        setState({
            ...state,
            markSheetName: data?.markSheetName || "",
            description: data?.description || ""
        })
        editData = true;
        setSelectedIndex(index)
        setSelectedItem(data)
        setCreateModule(true)
    }

    const onDeleteItem = (data, index) => {
        editData = false;
        setSelectedIndex(index)
        setSelectedItem(data)
        setDeleteModule(true)
    }

    const validateForm = async () => {
        const validatefom = customerFormRef.current.validateForm();
    }

    const onSubmitForm = () => {
        let temp_arr = []
        console.log(JSON.stringify(itemList));
        const hasInvalidMarks = itemList.some((item) => !item.total && !item.result);

        if (hasInvalidMarks) {
            setState({
                ...state,
                isValidated: true
            })
            Alert({
                description: 'Enter the students mark details.',
                type: 'warning',
                placement: 'topRight',
                duration: 3,
                onClose: () => console.log('Notification closed'),
            });
            return false
        }

        itemList.map((itm, idx) => {
            Object.keys(itm).forEach(key => {
                const value = itm[key];
                if (Array.isArray(value)) {
                    itm[key] = value.map(item => {
                        const newData = { [item.dataField]: parseInt(item.value) };
                        delete newData.value;
                        return newData;
                    });
                }
            });
            if (itm.statusId !== 1 && itm.statusId !== 3) {
                const requestDate = {
                    markMeta: JSON.stringify(itm),
                    examId: state.examId,
                    enrollmentId: state.enrollmentId,
                    batchId: state.batchId,
                    studentEnrollmentId: itm.studentEnrollmentId
                }
                temp_arr.push(requestDate)
            }

        })
        // return


        // return
        if (itemList.length == 0) {
            Alert({ description: 'LIST IS EMPTY', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            return

        } else {
            if (editData) {
                const editTempArr = temp_arr.map((v, idx) => ({ ...v, markSheetId: selectedItem[idx]?.markSheetId }))
                setLoaderModule(true)
                dispatch(updateMarkSheet(editTempArr))
            } else if (deleteModule) {
                const deleteRequest = {
                    isActive: "false"
                }
                setLoaderModule(true)
                dispatch(updateMarkSheet(deleteRequest, selectedItem.markSheetId))
            } else {
                setLoaderModule(true)
                dispatch(createMarkSheet(temp_arr))
            }
        }

    }

    let count = state.subjectEnrollmentList

    const onSearch = () => {
        editData = false
        if (state.enrollmentId == null || state.enrollmentId === 0 || state.enrollmentId === "" || state.enrollmentId === "0") {
            showMessage("warning", "Please Select Enrollment");
            Alert({ description: 'Please Select Enrollment', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });

            setItemList([])
        } else if (state.examTypeId == null || state.examTypeId === 0 || state.examTypeId === "" || state.examTypeId === "0") {
            setItemList([])
            showMessage("warning", "Please Select Exam Type");
            Alert({ description: 'Please Select Exam Type', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });

        } else if (state.batchId == null || state.batchId === 0 || state.batchId === "" || state.batchId === "0") {
            setItemList([])
            showMessage("warning", "Please Select Batch");
        } else if (state.examId == null || state.examId === 0 || state.examId === "" || state.examId === "0" || state.examId === undefined) {
            setItemList([])
            showMessage("warning", "Please Select Exam");
            Alert({ description: 'Please Select Exam', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });

        }

        else {
            const req = {
                "examTypeId": state.examTypeId,
                "enrollmentId": state.enrollmentId,
                "batchId": state.batchId,
                "examId": state.examId,
                // "isActive":true

            };
            setLoaderModule(true);

            dispatch(getMarkSheet(req));
            setCreateModule(true)
            /*   const reqq = {
                  "examTypeId": state.examTypeId,
                  "enrollmentId": state.enrollmentId,
                  "batchId": state.batchId
              }
              dispatch(getExamListData(reqq)) */
        }
    };

    useEffect(() => {
        const examTypeIdData = state?.examTypeId || null
        const enrollmentIdData = state?.enrollmentId || null
        const batchIdData = state?.batchId || null
        if (examTypeIdData != null && enrollmentIdData != null && batchIdData != null) {
            setState((prevState) => ({
                ...prevState,
                examId: ""
            }));
            const req = {
                "examTypeId": state.examTypeId,
                "enrollmentId": state.enrollmentId,
                "batchId": state.batchId,
                "isActive": true
            }
            dispatch(getExamListData(req))

        }
    }, [state.examTypeId, state.enrollmentId, state.batchId]);

    const {
        markSheetName,
        description,
    } = state;

    const modelTitle = editData ? "Edit " : "Create ";
    const modelButtonLbl = editData ? "" : "Save";

    const { sidebarOpen } = useSidebar();

    return (
        <div style={{marginBottom:"60px"}}>
            {/* <FormLayout ref={customerFormRef} defaultState={state} setDefaultState={setState} dynamicForm={markSheetForm} noOfColumn={4} onSubmit={onSubmitForm} /> */}

            <div style={sidebarOpen ? { marginLeft: "70px", marginTop: "50px" } : { marginLeft: "90px", marginTop: "50px" }}>
                <FormLayout defaultState={state} setDefaultState={setState} dynamicForm={markSheetForm} noOfColumn={6} />
                <Button onClick={onSearch} className='mr-2' style={{ backgroundColor: lightTheme.primaryColor, border: "none", fontSize: "12px", marginTop: "-20px" }}>Search</Button>
                {/* <Button onClick={onClear} style={{ backgroundColor: "white", color: "gray", border: "1px solid gray", fontSize: "12px", marginTop: "-20px" }}>Clear</Button> */}
            </div>
            <div style={{ marginLeft: "50px", }}>
                {createModule && <TableCustomeCellEditor titleName={"Term Exam MarkSheet"} data={itemList} defaultState={setItemList} columns={columns} examDetails={state?.filterExam || []} onSubmit={onSubmitForm} forms={extraMarkForm} permissionData={pageItems} subjectList={state?.subjectList || []} />}

            </div>



            {/* <View show={createModule} size="md" savetitle={modelButtonLbl} onHide={setCreateModule} onSubmit={validateForm} title={`${modelTitle} MarkSheet`}>
                <FormLayout ref={customerFormRef} defaultState={state} setDefaultState={setState} dynamicForm={markSheetForm} noOfColumn={1} onSubmit={onSubmitForm}/>
            </View>

            <View centered={true} size={'sm'} show={deleteModule} onHide={setDeleteModule} savetitle="Yes" onSubmit={onSubmitForm} title="Delete MarkSheet">
                <h6 className="text-capitalize mb-1">Are you sure ?</h6>
            </View>
            
            <SimpleCard title="MarkSheet" onClick={onCreateForm}>
            <CustomTable columns={column} data={itemList} tableName={"markSheet"} state={state} />
            </SimpleCard> */}
        </div>
    )
};

export default Marksheet;
