import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { FaUsers, FaBriefcase, FaTrophy, FaChalkboardTeacher } from 'react-icons/fa';
import AutoLayoutExample from './TeacherView';
import { lightTheme } from 'styles/theme';
import ColumnsGrid from './SchoolData';
import { useDispatch, useSelector } from 'react-redux';
import { getStudent } from "app/api/StudentApi";
import { getEmployee } from "app/api/EmployeeApi";
import { getEmployeeDetails, getEmployeePromotion, getEmployeeReporting, getEmployeeReportingInfo } from "app/api/EmployeeApi";
import { getSchoolDashboard } from 'app/api/SchoolDashboardApi';
import { asyncGetBestEmployeList } from 'app/api/EmployePerformanceApi';
import { asyncGetBatchInfo } from 'app/api/BatchApi';
import Loading from "app/components/atoms/loading";

function OrderingExample() {

    const getStudentSuccess = useSelector((state) => state.studentReducer.getStudentSuccess);
    const getStudentList = useSelector((state) => state.studentReducer.getStudentList);
    const getStudentFailure = useSelector((state) => state.studentReducer.getStudentFailure);

    const getEmployeeSuccess = useSelector((state) => state.employeeReducer.getEmployeeSuccess);
    const getEmployeeList = useSelector((state) => state.employeeReducer.getEmployeeList);
    const getEmployeeFailure = useSelector((state) => state.employeeReducer.getEmployeeFailure);

    const getSchoolDashboardSuccess = useSelector((state) => state.schoolDashboardReducer.getSchoolDashboardSuccess);
    const getSchoolDashboardList = useSelector((state) => state.schoolDashboardReducer.getSchoolDashboardList);
    const getSchoolDashboardFailure = useSelector((state) => state.schoolDashboardReducer.getSchoolDashboardFailure);
    const schoolDashboardErrorMessage = useSelector((state) => state.schoolDashboardReducer.errorMessage);

    const [studentCount, setStudentCount] = useState(0);
    const [teacherCount, setteacherCount] = useState(0);
    const [dashBoardData, setdashBoardData] = useState([]);
    const [state, setState] = useState([]);
    const [employeeCount, setEmployeeCount] = useState(0);
    const [isVisible, setIsVisible] = useState(false);
    const [hoveredCard, setHoveredCard] = useState(null);
    const [loaderModule, setLoaderModule] = useState(false);
    const [showColumnsGrid, setShowColumnsGrid] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        setIsVisible(true);
    }, []);
    const listFetching = async () => {
        const requestData = { batchId: 1 };
        const dashBoardList = await getSchoolDashboard()();
        const getSchoolDashboardList = dashBoardList?.data || []
        setdashBoardData(getSchoolDashboardList)
        const studentCount = getSchoolDashboardList.length > 0 ? getSchoolDashboardList[0].students.studentCount : 0;
        const teachersCount = getSchoolDashboardList.length > 0 ? getSchoolDashboardList[0].teachers.teachersCount : 0;
        setStudentCount(studentCount);
        setteacherCount(teachersCount);

    };

    useEffect(() => {
        const requestData = { isActive: "true" };
        dispatch(getStudent(requestData));
        dispatch(getEmployee(requestData));

    }, []);


    useEffect(() => {
        setLoaderModule(true);

        // Dispatch actions and stop loader after 3 seconds
        const timer = setTimeout(() => {
            const requestData = { isActive: "true" };

            dispatch(getSchoolDashboard())
            setShowColumnsGrid(true);
            // Stop loader after dispatching actions
            setLoaderModule(false);
        }, 2000);

        // Cleanup function to clear the timeout on unmount
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        if (getStudentSuccess) {
            // setStudentCount(getStudentList.length > 0 ? getStudentList.length : 0);
            dispatch({ type: "RESET_GET_STUDENT" });
        } else if (getStudentFailure) {
            dispatch({ type: "RESET_GET_STUDENT" });
        }
    }, [getStudentSuccess, getStudentFailure, getStudentList]);

    useEffect(() => {
        if (getSchoolDashboardSuccess) {

            setdashBoardData(getSchoolDashboardList)
            const studentCount = getSchoolDashboardList.length > 0 ? getSchoolDashboardList[0].students.studentCount : 0;
            const teachersCount = getSchoolDashboardList.length > 0 ? getSchoolDashboardList[0].teachers.teachersCount : 0;
            setStudentCount(studentCount);
            setteacherCount(teachersCount);

            console.log(dashBoardData)
            dispatch({ type: "RESET_GET_SCHOOL_DASHBOARD" })
        } else if (getSchoolDashboardFailure) {
            setState({
                ...state,
                schoolDashboardList: []
            })
            dispatch({ type: "RESET_GET_SCHOOL_DASHBOARD" })
        }
    }, [getSchoolDashboardSuccess, getSchoolDashboardFailure]);

    useEffect(() => {
        if (getEmployeeSuccess) {
            setEmployeeCount(getEmployeeList.length > 0 ? getEmployeeList.length : 0);

            dispatch({ type: "RESET_GET_EMPLOYEE" });
        } else if (getEmployeeFailure) {
            dispatch({ type: "RESET_GET_EMPLOYEE" });
        }
    }, [getEmployeeSuccess, getEmployeeFailure, getEmployeeList]);

    const cardStyle = (index) => ({
        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
        opacity: isVisible ? 1 : 0,
        transform: isVisible ? 'translateY(0)' : 'translateY(20px)',
        boxShadow: hoveredCard === index ? '0 4px 10px rgba(0, 0, 0, 0.1)' : 'none',
        transform: hoveredCard === index ? 'scale(1.05)' : 'scale(1)',

        color: lightTheme.primaryColor,
        padding: '3px',
        border: "none",
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)'
    });

    const cardChildStyle = (index) => ({
        position: 'absolute',
        top: 0,
        left: 0,
        width: '6px',
        height: '100%',
        background: index === 0
            ? 'linear-gradient(135deg, #FFDDC1, #FFABAB)'
            : index === 1
                ? 'linear-gradient(135deg, #FFEFBA, #FFD59E)'
                : index === 2
                    ? 'linear-gradient(135deg, #B9F6CA, #69F0AE)'
                    : 'linear-gradient(135deg, #A7C7E7, #6C9BD9)',
        borderTopRightRadius: "10px",
        borderBottomRightRadius: "10px",

    });

    const bodyStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        padding: '3px',
        paddingBottom: '10px',
    };

    const titleStyle = {
        fontSize: '0.9rem',
        marginBottom: '5px',
        color: lightTheme.primaryColor
    };

    const textStyle = {
        fontSize: '1.5rem',
        color: lightTheme.secondaryColor
    };



    const iconContainerStyle = (index) => ({
        fontSize: '3.5rem',
        background: index === 0
            ? 'linear-gradient(135deg, #FFDDC1, #FFABAB)'
            : index === 1
                ? 'linear-gradient(135deg, #FFEFBA, #FFD59E)'
                : index === 2
                    ? 'linear-gradient(135deg, #B9F6CA, #69F0AE)'
                    : 'linear-gradient(135deg, #A7C7E7, #6C9BD9)',
        borderRadius: '50%',
        padding: '15px',
        marginRight: '10px',
    });

    const lineStyle = {
        width: '2px',                // Thickness of the line
        height: '80px',              // Length of the line (adjust as needed)
        backgroundColor: '#F2F2F2',     // Line color (black in this case)
        transform: 'rotate(180deg)',   // Rotate the line by 90 degrees
        transformOrigin: 'center',   // Ensure rotation happens from the center


    };

    return (
        <div>
            <Loading status={loaderModule} />

            <Container>
                <Row>
                    <Col xs={12} sm={6} md={3}>
                        <Card
                            style={cardStyle(0)}
                            onMouseEnter={() => setHoveredCard(0)}
                            onMouseLeave={() => setHoveredCard(null)}
                        >
                            <div style={cardChildStyle(0)}></div>
                            <Card.Body style={bodyStyle}>
                                <div>
                                    <FaUsers style={iconContainerStyle(0)} />
                                </div>
                                <div style={lineStyle}></div>
                                <div>
                                    <Card.Title style={titleStyle}>Total Students</Card.Title>
                                    <Card.Text style={textStyle}>{studentCount}</Card.Text>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={3}>
                        <Card
                            style={cardStyle(1)}
                            onMouseEnter={() => setHoveredCard(1)}
                            onMouseLeave={() => setHoveredCard(null)}
                        >
                            <div style={cardChildStyle(1)}></div>

                            <Card.Body style={bodyStyle}>
                                <div>
                                    <FaBriefcase style={iconContainerStyle(1)} />
                                </div>
                                <div style={lineStyle}></div>

                                <div>
                                    <Card.Title style={titleStyle}>Total Employees</Card.Title>
                                    <Card.Text style={textStyle}>{employeeCount}</Card.Text>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={3}>
                        <Card
                            style={cardStyle(2)}
                            onMouseEnter={() => setHoveredCard(2)}
                            onMouseLeave={() => setHoveredCard(null)}
                        >
                            <div style={cardChildStyle(2)}></div>

                            <Card.Body style={bodyStyle}>
                                <div>
                                    <FaTrophy style={iconContainerStyle(2)} />
                                </div>
                                <div style={lineStyle}></div>

                                <div>
                                    <Card.Title style={titleStyle}>Awards</Card.Title>
                                    <Card.Text style={textStyle}>20</Card.Text>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={3}>
                        <Card
                            style={cardStyle(3)}
                            onMouseEnter={() => setHoveredCard(3)}
                            onMouseLeave={() => setHoveredCard(null)}
                        >
                            <div style={cardChildStyle(3)}></div>

                            <Card.Body style={bodyStyle}>
                                <div>
                                    <FaChalkboardTeacher style={iconContainerStyle(3)} />
                                </div>
                                <div style={lineStyle}></div>

                                <div>
                                    <Card.Title style={titleStyle}>Total Teachers</Card.Title>
                                    <Card.Text style={textStyle}>{teacherCount}</Card.Text>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <br />
                {showColumnsGrid &&<ColumnsGrid counts={dashBoardData[0]?.students || []}></ColumnsGrid>}
                <br />
                <AutoLayoutExample eventData={dashBoardData[0]?.events || []} batchData={state?.batchData || []}></AutoLayoutExample>
            </Container>
        </div>
    );
}

export default OrderingExample;
