import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Modal as AntdModal } from 'antd';
import ModalProps from './props';

const Wrapper = styled(AntdModal)`
   
    box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.09);
    border-radius: 3px;
    margin: 10px 200px;

    ${({ size }) => size === ModalProps.size.sm && `
        .ant-modal-content {
            width: 400px !important;
            margin: 20px 300px;
        }
    `}
    ${({ size }) => size === ModalProps.size.md && `
        .ant-modal-content {
            width: 520px !important;
            margin: 20px 250px;
        }
    `}
    ${({ size }) => size === ModalProps.size.lg && `
        .ant-modal-content {
            width: 900px !important;
            margin: 20px 50px;
        }
    `}
    ${({ size }) => size === ModalProps.size.xl && `
        .ant-modal-content {
            width: 1100px !important;
            margin: 20px -60px;
        }
    `}

    ${({ fullscreen }) => fullscreen && `
        .ant-modal-content {
            width: 1500px;
            height: 100vh;
          
        }
    `}

    .ant-modal-body {
        overflow-y: auto;
        min-height: 500px;
        max-height: 600px;
        padding: 10px;

        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgba(0, 0, 0, 0.2);
            border-radius: 4px;
        }
        &::-webkit-scrollbar-track {
            background-color: rgba(0, 0, 0, 0.05);
            border-radius: 4px;
        }
    }

    .ant-modal-close {
        visibility: visible !important;
    }

    .custom-close-btn {
        position: absolute;
        top: 20px;
        right: 20px;
        background-color: red;
        color: white;
        border: none;
        padding: 10px;
        cursor: pointer;
    }
`;

const ModalView = ({ visible, size, fullscreen, children, onCancel, closable, tableName }) => {
    const words = tableName.split(" ");  // Split tableName into words

    return (
        <Wrapper
            className={`ax-modal ${size}`}
            visible={visible}
            size={size}
            onCancel={onCancel}
            closable={closable}
            fullscreen={fullscreen}
        >
            <h6 style={{
                padding: "10px",
                borderRadius: "10px",
                width: "100%",
                color: "gray",
                fontWeight: "600",
                borderBottom: "1px solid green",
                fontSize:"20px"
            }}>
                {words[0]} <span style={{ color: "red",fontSize:"20px" }}>{words[1]}</span>
            </h6>
            <div style={{margin:"10px"}}>
                {children}

            </div>
        </Wrapper>
    );
};
ModalView.propTypes = {
    visible: PropTypes.bool.isRequired,
    size: PropTypes.oneOf([
        ModalProps.size.sm,
        ModalProps.size.md,
        ModalProps.size.lg,
        ModalProps.size.xl,
    ]),
    fullscreen: PropTypes.bool,
    children: PropTypes.node,
    closable: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
};
ModalView.defaultProps = {
    size: ModalProps.size.lg,
    closable: true,
    fullscreen: false,
};

export default ModalView;
