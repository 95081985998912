import * as yup from "yup";
import { Formik } from "formik";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import DateTime from "react-datetime";
import { Form, Button } from "react-bootstrap";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import FormLayout from "app/components/render/formlayout";
import { employeeLeaveForm, assignStatusForm, employeeLeaveEditForm } from "./formData";
import { MdEdit, MdPending, MdViewModule } from "react-icons/md";
import { getLeaveType } from "app/api/LeaveTypeApi";
import { getLeaveStatus } from "app/api/LeaveStatusApi";
import swal from "sweetalert2";
import _ from "lodash";
import formRender from "app/components/render/formcomponent";
import { createEmployeeLeave, getEmployeeLeave, updateEmployeeLeave } from "app/api/EmployeeLeaveApi";
import { getEmployee } from "app/api/EmployeeApi";
import moment from 'moment'
import { getHolidays, asyncgetHolidays } from "app/api/HolidaysApi";
import { dateConversion, isBeforeDate, pagePermission, showMessage, timeConversion } from "app/utils/app-functions";
import { getLeaveBalance } from "app/api/LeaveBalanceApi";
import { getWorkingDays } from "app/api/WorkingDaysApi";
import View from "app/components/atoms/modalView/Modalview";
import CustomTable from "app/components/atoms/custom-table/CustomTable";
import SimpleCard from "app/components/atoms/simple-card/SimpleCard";
import { asyncGetEmployeeInfo } from "app/api/EmployeeApi";
import Alert from "app/components/atoms/alert";
import { asyncGetLeaveTypeInfo } from "app/api/LeaveTypeApi";
import { asyncGetLeaveBalanceInfo } from "app/api/LeaveBalanceApi";
import { AiFillMessage } from "react-icons/ai";
import { asyncGetLeaveStatusInfo } from "app/api/LeaveStatusApi";
import { FiEdit } from "react-icons/fi";
import locale from 'antd/locale/zh_CN';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { asyncgetWorkingDays } from "app/api/WorkingDaysApi";
import { useSidebar } from "app/components/sidebar/sidebarContext";
import Loading from "app/components/atoms/loading";

dayjs.extend(customParseFormat);

const ExcelJS = require('exceljs');


let editData = false;


const EmployeeLeave = ({ navigation }) => {
    const dispatch = useDispatch();
    const customerFormRef = useRef();

    const getEmployeeLeaveSuccess = useSelector((state) => state.employeeLeaveReducer.getEmployeeLeaveSuccess);
    const getEmployeeLeaveList = useSelector((state) => state.employeeLeaveReducer.getEmployeeLeaveList);
    const getEmployeeLeaveFailure = useSelector((state) => state.employeeLeaveReducer.getEmployeeLeaveFailure);

    const getLeaveBalanceSuccess = useSelector((state) => state.leaveBalanceReducer.getLeaveBalanceSuccess);
    const getLeaveBalanceList = useSelector((state) => state.leaveBalanceReducer.getLeaveBalanceList);
    const getLeaveBalanceFailure = useSelector((state) => state.leaveBalanceReducer.getLeaveBalanceFailure);
    const leaveBalanceErrorMessage = useSelector((state) => state.leaveBalanceReducer.errorMessage);

    const getHolidaysSuccess = useSelector((state) => state.holidaysReducer.getHolidaysSuccess);
    const getHolidaysList = useSelector((state) => state.holidaysReducer.getHolidaysList);
    const getHolidaysFailure = useSelector((state) => state.holidaysReducer.getHolidaysFailure);
    const holidaysErrorMessage = useSelector((state) => state.holidaysReducer.errorMessage);

    const getLeaveTypeSuccess = useSelector((state) => state.leaveTypeReducer.getLeaveTypeSuccess);
    const getLeaveTypeList = useSelector((state) => state.leaveTypeReducer.getLeaveTypeList);
    const getLeaveTypeFailure = useSelector((state) => state.leaveTypeReducer.getLeaveTypeFailure);
    const leaveTypeErrorMessage = useSelector((state) => state.leaveTypeReducer.errorMessage);

    const getLeaveStatusSuccess = useSelector((state) => state.leaveStatusReducer.getLeaveStatusSuccess);
    const getLeaveStatusList = useSelector((state) => state.leaveStatusReducer.getLeaveStatusList);
    const getLeaveStatusFailure = useSelector((state) => state.leaveStatusReducer.getLeaveStatusFailure);
    const leaveStatusErrorMessage = useSelector((state) => state.leaveStatusReducer.errorMessage);

    const getWorkingDaysSuccess = useSelector((state) => state.workingDaysReducer.getWorkingDaysSuccess);
    const getWorkingDaysList = useSelector((state) => state.workingDaysReducer.getWorkingDaysList);
    const getWorkingDaysFailure = useSelector((state) => state.workingDaysReducer.getWorkingDaysFailure);
    const workingDaysErrorMessage = useSelector((state) => state.workingDaysReducer.errorMessage);

    const getEmployeeSuccess = useSelector((state) => state.employeeReducer.getEmployeeSuccess);
    const getEmployeeList = useSelector((state) => state.employeeReducer.getEmployeeList);
    const getEmployeeFailure = useSelector((state) => state.employeeReducer.getEmployeeFailure);
    const employeeErrorMessage = useSelector((state) => state.employeeReducer.errorMessage);

    const createEmployeeLeaveSuccess = useSelector((state) => state.employeeLeaveReducer.createEmployeeLeaveSuccess);
    const createEmployeeLeaveData = useSelector((state) => state.employeeLeaveReducer.createEmployeeLeaveData);
    const createEmployeeLeaveFailure = useSelector((state) => state.employeeLeaveReducer.createEmployeeLeaveFailure);

    const updateEmployeeLeaveSuccess = useSelector((state) => state.employeeLeaveReducer.updateEmployeeLeaveSuccess);
    const updateEmployeeLeaveData = useSelector((state) => state.employeeLeaveReducer.updateEmployeeLeaveData);
    const updateEmployeeLeaveFailure = useSelector((state) => state.employeeLeaveReducer.updateEmployeeLeaveFailure);

    const employeeLeaveErrorMessage = useSelector((state) => state.employeeLeaveReducer.errorMessage);

    const [pageItems, setpageItems] = useState({
        "view": false,
        "create": true,
        "update": false,
        "deleteOption": false
    }
    );

    useEffect(() => {
        let data = pagePermission(pageItems, "Employee", "Apply Leave")
        setpageItems(data)

    }, [navigation]);

    const column = [
        {
            "dataField": "employeeId",
            "text": "Employee Name",
            "sort": true,
            formatter: (cellContent, row, index) => (
                row.firstName + "-" + row.lastName
            )
        },
        {
            "dataField": "leaveTypeName",
            "text": "Leave Type",
            "sort": true,
        },
        {
            "dataField": "requestedName",
            "text": "Requested To",
            "sort": true,
        },
        {
            "dataField": "acceptedName",
            "text": "Approved By",
            "sort": true,
        },

        {
            "dataField": "startDate",
            "text": "Leave Date",
            "sort": true,
            formatter: (cellContent, row, index) => (
                dateConversion(row.startDate, "DD-MMM-YYYY") + "  To  " + dateConversion(row.endDate, "DD-MMM-YYYY")
            )
        },
        {
            "dataField": "createdAt",
            "text": "Requested On",
            "sort": true,
            formatter: (cellContent, row, index) => (
                dateConversion(row.createdAt, "DD-MMM-YYYY")
            )
        },
        {
            dataField: "leaveStatusName",
            text: "Status",
            sort: true,
            formatter: (cellContent, row, index) => {
                let status = ''
                if (cellContent === 'Approve') {
                    status = 'Approved';
                } else if (cellContent === 'Decline') {
                    status = 'Declined';
                } else if (cellContent === 'Pending') {
                    status = 'Pending';
                }
                const conInfo = { inputType: "checkInStatus", statusName: status }
                return formRender(null, null, conInfo, null);
            }
        },

    ]

    if (pageItems.view || pageItems.update) {
        column.push({
            text: "Action",
            sort: false,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => (
                <div className="icon-container">
                    {pageItems.update && <FiEdit
                        className="text-primary cursor-pointer"
                        size={18}
                        onClick={() => onEditForm(row, index)}
                    ></FiEdit>}
                    {pageItems.process && <AiFillMessage
                        className="text-warning cursor-pointer"
                        size={18}
                        onClick={() => onProcessForm(row, index)}>
                    </AiFillMessage>}
                    {/* <MdDelete
                        className="text-danger cursor-pointer"
                        size={18}
                        onClick={() => {
                            swal
                                .fire({
                                    title: "Are you sure?",
                                    text: "You won't be able to revert this!",
                                    icon: "warning",
                                    type: "question",
                                    showCancelButton: true,
                                    confirmButtonColor: "#3085d6",
                                    cancelButtonColor: "#d33",
                                    confirmButtonText: "Yes, delete it!",
                                    cancelButtonText: "No",
                                })
                                .then((result) => {
                                    if (result.value) {
                                        
                                        onDeleteItem(row, index);
                                    } else {
                                        swal.fire({
                                            title: "Cancelled!",
                                            text: "Permission denied.",
                                            type: "error",
                                            icon: "error",
                                            timer: 1500,
                                        });
                                    }
                                });
                        }}
                    ></MdDelete> */}
                </div>
            )
        })
    }





    const [state, setState] = useState({
        employeeList: [],
        leaveTypeList: [],
        leaveStatusList: [],
        holidayList: [],
        leaveBalanceList: [],
        workingDaysList: [],
        balance: "",
        filterBalanceList: [],
        // endDate:new Date(),
        dayType: 1,
        dayTypeList: [{ dayTypeId: 1, dayTypeName: 'Full-day' }, { dayTypeId: 2, dayTypeName: 'Half-day' },]
    });

    const [createModule, setCreateModule] = useState(false);
    const [loaderModule, setLoaderModule] = useState(false);
    const [editModule, setEditModule] = useState(false);
    const [viewModule, setViewModule] = useState(false);
    const [deleteModule, setDeleteModule] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [assignModule, setAssignModule] = useState(false);
    const [selectedItem, setSelectedItem] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);
    const [processData, setProcessData] = useState([])


    let userInfo = []
    let parsedData = []
    const listFetching = async () => {
        const requestData = {
            isActive: "true",
        }
        const request = {
            isActive: "true",
            leaveStatusId: 1
        }
        if (parsedData?.[0]?.roleId == 5 || parsedData?.[0]?.roleId == 4) {
            requestData.empCode = parsedData?.[0]?.userCode || ''
        } else{
            requestData.leaveStatusId=1
        }
       
        let employeeList = await asyncGetEmployeeInfo(requestData)
        let leaveTypeList = await asyncGetLeaveTypeInfo(request)
        let leaveBalanceList = await asyncGetLeaveBalanceInfo()
        let leaveStatusList = await asyncGetLeaveStatusInfo()
        let workingDays = await asyncgetWorkingDays(request)
        let hildays = await asyncgetHolidays(request)

        setState({
            ...state,
            employeeList: employeeList?.data || [],
            leaveTypeList: leaveTypeList?.data || [],
            leaveBalanceList: leaveBalanceList?.data || [],
            leaveStatusList: leaveStatusList?.data || [],
            workingDaysList: workingDays?.data || [],
            holidayList: hildays?.data || [],

        })

    }


    useEffect(() => {
        setLoaderModule(true)
        userInfo = window.localStorage.getItem("userInfo");
        const req1 = {
            isActive: "true",
        };
        parsedData = JSON.parse(userInfo);
        if (parsedData?.[0]?.roleId == 5 || parsedData?.[0]?.roleId == 4 || parsedData?.[0]?.roleId == 6) {
            req1.empCode = parsedData?.[0]?.userCode || ''
        }
        else {
            req1.leaveStatusId = 1
        }
        dispatch(getEmployeeLeave(req1))
        // dispatch(getLeaveType(req))
        // dispatch(getEmployee(req1))
        dispatch(getLeaveStatus())
        // dispatch(getHolidays(req))
        // dispatch(getLeaveBalance())
        // dispatch(getWorkingDays())
        listFetching()
    }, [navigation]);

    useEffect(() => {
        if (getEmployeeLeaveSuccess) {
            setItemList(getEmployeeLeaveList)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_EMPLOYEE_LEAVE" })
        } else if (getEmployeeLeaveFailure) {
            setItemList([])
            showMessage("warning", employeeLeaveErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_EMPLOYEE_LEAVE" })
        }
    }, [getEmployeeLeaveSuccess, getEmployeeLeaveFailure]);

    useEffect(() => {
        if (getHolidaysSuccess) {
            setState({
                ...state,
                holidayList: getHolidaysList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_HOLIDAYS" })
        } else if (getHolidaysFailure) {
            setState({
                ...state,
                holidayList: []
            })
            showMessage("warning", holidaysErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_HOLIDAYS" })
        }
    }, [getHolidaysSuccess, getHolidaysFailure]);

    useEffect(() => {
        if (getWorkingDaysSuccess) {
            setState({
                ...state,
                workingDaysList: getWorkingDaysList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_WORKING_DAYS" })
        } else if (getWorkingDaysFailure) {
            setState({
                ...state,
                workingDaysList: []
            })
            showMessage("warning", workingDaysErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_WORKING_DAYS" })
        }
    }, [getWorkingDaysSuccess, getWorkingDaysFailure]);

    useEffect(() => {
        if (getLeaveBalanceSuccess) {

            setState(prevState => ({
                ...prevState,
                leaveBalanceList: getLeaveBalanceList,

            }));

            setLoaderModule(false)
            dispatch({ type: "RESET_GET_LEAVE_BALANCE" })
        } else if (getLeaveBalanceFailure) {
            setState({
                ...state,
                leaveBalanceList: []
            })
            showMessage("warning", leaveBalanceErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_LEAVE_BALANCE" })
        }
    }, [getLeaveBalanceSuccess, getLeaveBalanceFailure, getLeaveBalanceList]);

    useEffect(() => {
        if (getLeaveTypeSuccess) {
            setState({
                ...state,
                leaveTypeList: getLeaveTypeList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_LEAVE_TYPE" })
        } else if (getLeaveTypeFailure) {
            setState({
                ...state,
                leaveTypeList: []
            })
            showMessage("warning", leaveTypeErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_LEAVE_TYPE" })
        }
    }, [getLeaveTypeSuccess, getLeaveTypeFailure]);

    useEffect(() => {
        if (getEmployeeSuccess) {
            setState({
                ...state,
                employeeList: getEmployeeList,
                employeeId: (global.roleId == 5 || global.roleId == 4 || global.roleId == 6 || global.roleId == 3) ? getEmployeeList : '',
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_EMPLOYEE" })
        } else if (getEmployeeFailure) {
            setState({
                ...state,
                employeeList: []
            })
            showMessage("warning", employeeErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_EMPLOYEE" })
        }
    }, [getEmployeeSuccess, getEmployeeFailure]);

    useEffect(() => {
        if (getLeaveStatusSuccess) {
            setState({
                ...state,
                leaveStatusList: getLeaveStatusList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_LEAVE_STATUS" })
        } else if (getLeaveStatusFailure) {
            setState({
                ...state,
                leaveStatusList: []
            })
            showMessage("warning", leaveStatusErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_LEAVE_STATUS" })
        }
    }, [getLeaveStatusSuccess, getLeaveStatusFailure]);

    useEffect(() => {
        if (createEmployeeLeaveSuccess) {

            const temp_state = [...itemList, createEmployeeLeaveData[0]];
            setItemList(temp_state)
            setLoaderModule(false)
            closeModule()
            Alert({ description: 'Created successfully.', type: 'success', placement: 'topRight', duration: 3 });
            dispatch({ type: "RESET_CREATE_EMPLOYEE_LEAVE" })
        } else if (createEmployeeLeaveFailure) {
            setLoaderModule(false)
            // showMessage("warning", employeeLeaveErrorMessage)
            Alert({ description: `${employeeLeaveErrorMessage}`, type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });

            dispatch({ type: "RESET_CREATE_EMPLOYEE_LEAVE" })
        }
    }, [createEmployeeLeaveSuccess, createEmployeeLeaveFailure]);


    useEffect(() => {
        if (updateEmployeeLeaveSuccess) {
            const request = {
                isActive: "true",
            };
            if (global.roleId == 5 || global.roleId == 4 || global.roleId == 6) {
                request.empCode = global.UserInfo?.userCode || '';
            }
            else {
                request.leaveStatusId = 1
            }
            dispatch(getEmployeeLeave(request))

            updateTable(updateEmployeeLeaveData[0])
            // Alert({ description: 'Updated successfully.', type: 'success', placement: 'topRight', duration: 3 });

            dispatch({ type: "RESET_UPDATE_EMPLOYEE_LEAVE" })
        } else if (updateEmployeeLeaveFailure) {
            setLoaderModule(false)
            showMessage("warning", employeeLeaveErrorMessage?.message)
            Alert({ description: `${employeeLeaveErrorMessage.message}`, type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });

            dispatch({ type: "RESET_UPDATE_EMPLOYEE_LEAVE" })
        }
    }, [updateEmployeeLeaveSuccess, updateEmployeeLeaveFailure]);

    const updateTable = (updatedItem) => {
        const temp_state = [...itemList];
        if (deleteModule || (assignModule)) {
            let remainingList = _.remove((temp_state ? temp_state : []), function (n) {
                return n?.empLeaveId != updatedItem?.empLeaveId;
            });
            assignModule ?
                Alert({ description: 'Processed successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), }) :
                Alert({ description: 'Deleted successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });

            setItemList(remainingList)
        } else {
            temp_state[selectedIndex] = updatedItem;
            Alert({ description: 'Updated successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(temp_state)
        }
        setLoaderModule(false)
        closeModule()
    }

    const closeModule = () => {
        editData = false;
        setCreateModule(false)
        setDeleteModule(false)
        setAssignModule(false)
        setViewModule(false)
    }

    const resetState = () => {
        setState({
            ...state,
            leaveTypeId: "",
            dayTypeId: 1,
            requestedTo: "",
            leavesContent: '',
            leaveStatusId: "",
            startDate: "",
            endDate: "",
            description: "",
            balanceLeaveCount: ""
        })
    }

    const onCreateForm = () => {
        resetFilledData(state?.employeeList || [], "employeeId")
        resetState()
        editData = false;
        setCreateModule(true)
    }


    const onProcessForm = (data, index) => {
        if (data.empCode === parsedData[0]?.userCode || '') {
            Alert({ description: 'Un-Authorized.', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });

            return false
        }
        setState({
            ...state,
            leaveStatusId: data.leaveStatusId,
            leaveStatusRemarks: "",
            employeeId: data.employeeId,
            startDate: data.startDate,
            endDate: data.endDate,
        })
        editData = false
        setProcessData(data)
        setSelectedIndex(index)
        setAssignModule(true)
    }

    const onSelectEndDate = (date, name, index, dateString) => {
        const dayTypeId = state.dayType;
        const start_Date = moment(state.startDate, 'YYYY/MM/DD');
        const end_Date = moment(date, 'YYYY/MM/DD');

        if (dayTypeId === 2 && !(moment(end_Date).isSame(start_Date))) {
            Alert({ description: 'Start and end dates must be the same for a half-day.', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            return false
        }
        else if (dayTypeId === 1 && (moment(end_Date).isBefore(start_Date))) {
            Alert({ description: 'End date cannot be earlier than the start date for a full day..', type: 'warning', placement: 'topRight', duration: 3 });
            return false
        }

        // return
        const temp_state = [...state.holidayList]
        var start = moment(start_Date, "YYYY-MM-DD").local(); // Ensure local time
        var end = moment(end_Date, "YYYY-MM-DD").local();


        var list = [];
        let daysList = []
        while (start.isSameOrBefore(end, 'day')) {  // Compare dates reliably
            list.push(start.format("YYYY-MM-DD"));
            daysList.push(start.format("dddd"));
            start.add(1, 'd');  // Increment after adding the current day to the list
        }

        let holidaysCount = 0
        temp_state.forEach((item) => {
            list.forEach((ele) => {
                if (ele === item.holidayDate) {
                    holidaysCount += 1;
                }
                return holidaysCount
            })
        })

        let weekOffDays = 0
        let workingDaysList = (state?.workingDaysList || []).filter((itm) => { return itm.status === false })
        daysList.forEach((ele) => {
            workingDaysList.forEach((itm) => {
                if (ele == itm.dayName) {
                    weekOffDays = weekOffDays + 1;
                }
            })
        })





        const aa = dayjs(state.startDate);
        const b = dayjs(date);
      
        let totalDays = 0
        let days = 0
        let dayCount = 0
        let calDays = 0
        if (state.dayType == 1){
            totalDays = b.diff(aa, 'day');
            days = totalDays + 1
            dayCount = holidaysCount + weekOffDays
            calDays = days - dayCount
        }
        else{
            totalDays = 0.5
            days = 0.5
            dayCount = holidaysCount + weekOffDays
            calDays = 0.5
        }
        console.log("totalDays --- " + totalDays);
        console.log("holidaysCount --- " + holidaysCount);
        console.log("weekOffDays --- " + weekOffDays);
        console.log(calDays);

        setState({
            ...state,
            noOfDays: calDays,
            noOfHolidays: holidaysCount,
            noOfWeekOff: weekOffDays,
            leavesContent: `No. of leave day(s):${calDays}, No. of holiday(s):${holidaysCount},No.Of WeekOffs: ${weekOffDays}`,
            endDate: date
        })
        // }
    }

    let filterBalance = []


    const onEditForm = (data, extra) => {
        if (data.leaveStatusId == 1) {
            let start_Date = dayjs(data.startDate);
            let endDate = dayjs(data.endDate)
            const temp_state = [...state.employeeList]
            const selectedEmpId = data.employeeId;
            const employeeFilter = _.find(temp_state, ((o) => {
                return o.employeeId === selectedEmpId
            }))
            setState({
                ...state,
                employeeId: (employeeFilter.employeeCode || "") + " " + `(${(employeeFilter.employeeName || "")})`,
                leaveTypeId: data?.leaveTypeId || "",
                leaveStatusId: data?.leaveStatusId || "",
                dayType: data?.dayTypeId ,
                startDate: data.startDate ? moment(new Date(data.startDate), 'YYYY-MM-DD') : "",
                endDate: data.endDate ? moment(new Date(data.endDate), 'YYYY-MM-DD') : "",
                description: data.description,
                noOfDays: data.noOfDays,

            })

            editData = true
            // setSelectedIndex(index)
            setSelectedItem(data)
            setViewModule(true)
            setCreateModule(true)
        }
        else {

            setSelectedItem(data)
            let status = data.leaveStatusId == 2 ? "Leave is Already Approved" : "Leave is Declined"
            Alert({ description: status, type: 'warning', placement: 'topRight', duration: 3 });
        }
    }

    const onDeleteItem = (data, index) => {
        editData = false;
        setSelectedIndex(index)
        setSelectedItem(data)
        setDeleteModule(true)
    }

    const validateForm = async () => {
        const validatefom = customerFormRef.current.validateForm();
    }


    const onSubmitForm = () => {
        const storedBalance = localStorage.getItem('balance');
        const selectedEmpId = selectedItem.leaveTypeId || '';
        const balanceList = state.filterBalanceList || [];
        const balanceLeaveCount = storedBalance;
        const leaveDaysCount = parseFloat(state?.noOfDays) || "";
        const empId = employeeId.employeeId || 0;

        const requestDate = {
            employeeId: state?.employee_Id || 0,
            leaveTypeId: leaveTypeId,
            dayTypeId: state?.dayType || 0,
            leaveStatusId: 1,
            startDate: state.startDate,
            endDate: state.endDate,
            description: description,
            noOfDays: noOfDays,
            noOfHolidays: state?.noOfHolidays || 0,
            noOfWeekOff: state?.noOfWeekOff || 0,
            // requestedTo :0
        };
        console.log(JSON.stringify(leaveDaysCount));
        console.log(JSON.stringify(balanceLeaveCount));

        // return
        if (leaveDaysCount > parseFloat(balanceLeaveCount)) {
            Alert({ description: 'Leave days crossing ur balance limit.', type: 'warning', placement: 'topRight', duration: 3 });
        } else {
            if (editData && selectedItem.empLeaveId) {
                setLoaderModule(true);
                dispatch(updateEmployeeLeave(requestDate, selectedItem.empLeaveId));
            } else if (deleteModule) {
                const deleteRequest = {
                    isActive: "false"
                };
                setLoaderModule(true);
                dispatch(updateEmployeeLeave(deleteRequest, selectedItem.empLeaveId));
            } else if (assignModule) {
                const assignRequest = {
                    acceptedBy: processData.requestedTo,
                    leaveStatusRemarks: state.leaveStatusRemarks,
                    leaveStatusId: state.leaveStatusId,
                    employeeId: processData.employeeId,
                    startDate: dateConversion(state.startDate, "YYYY-MM-DD"),
                    endDate: dateConversion(state.endDate, "YYYY-MM-DD"),
                };
                console.log(JSON.stringify(assignRequest));
                // return
                setLoaderModule(true);
                dispatch(updateEmployeeLeave(assignRequest, processData.empLeaveId));
            } else {
                setLoaderModule(true);
                dispatch(createEmployeeLeave(requestDate));
            }
        }
    };

    const {
        employeeId,
        leaveTypeId,
        description,
        noOfDays,
    } = state;

    const resetFilledData = (value, name,) => {
        const selectedEmpId = selectedItem.employeeId
        const filterBalanceList = state.leaveBalanceList.filter((ele) => { return ele.employeeId === value.employeeId })

        let esistState = state;
        if (name === 'employeeId') {
            const selectedEmpId = value.employeeId || ''
            const filterBalanceList = state.leaveBalanceList.filter((ele) => { return ele.employeeId === selectedEmpId })

            esistState.filterBalanceList = filterBalanceList
            esistState.employeeId = value.label;
            esistState.employee_Id = value.employeeId;
            esistState.leaveTypeId = '';
            esistState.leavesContent = '';
            esistState.balanceLeaveCount = '';
            esistState.startDate = '';
            esistState.endDate = '';
            esistState.description = '';
            esistState.noOfDays = '';
        } else if (name === 'leaveTypeId') {
            //---->total
            const selectedLeaveTypeId = value;
            let balance = ""
            let leaveCount = ""
            const balanceList = state?.filterBalanceList?.length > 0 ? state.filterBalanceList : filterBalanceList;
            balanceList.map((ele) => {
                if (selectedLeaveTypeId == ele.leaveTypeId) {
                    balance = ele.leaveBalance
                    leaveCount = ele.noOfLeave
                }
            })
            localStorage.setItem('balance', balance.toString());
            const temp_arr = [...itemList]
            const employeeId = state?.employeeId || "";
            let pendingLeaveDays = 0
            if (employeeId.length > 0) {
                const filter_arr = temp_arr.map((itm) => {
                    if (itm.leaveTypeId == selectedLeaveTypeId && itm.employeeId == employeeId[0].employeeId) {
                        pendingLeaveDays = parseInt(pendingLeaveDays) + parseInt(itm.noOfDays)
                    }
                })
            }

            esistState.leaveTypeId = value;
            esistState.balance = `${balance}`
            esistState.balanceLeaveCount = `Balance Leave Count: ${balance} `;
            esistState.leavesContent = '';
            esistState.startDate = '';
            esistState.endDate = '';
            esistState.description = '';
            esistState.noOfDays = '';
        } else if (name === 'startDate') {

            try {
                esistState.startDate = value //moment(new Date(value)).format("YYYY/MM/DD")
                esistState.endDate = '';
                esistState.description = '';
                esistState.noOfDays = '';
            } catch (e) {
                console.log("Date Error --- " + e)
            }

        }
        setState({
            ...state,
            esistState
        })
    }

    const handleDayType = (event) => {
        let temp_state = { ...state }
        const start_Date = state?.startDate
        const end_Date = state?.endDate
        // console.log(event.target.name + " ==== " + event.target.value);

        if (event.target.value === 2 && start_Date && end_Date && start_Date != end_Date) {
            Alert({
                description: 'Start and end date must be the same for a half-day.',
                type: 'warning',
                placement: 'topRight',
                duration: 3,
            });
            temp_state = {
                ...temp_state,
                endDate: '', // Clear the endDate if the condition is not met
                [event.target.name]: event.target.value, // Update the specific field
            };
        }
        else {
            temp_state[event.target.name] = event.target.value;
            temp_state.endDate = '';
            

        }
        console.log(temp_state);
        
        setState(temp_state);

    }

    const handleClick = (buttonName) => {
        if (buttonName == 'Add') {
            onCreateForm();
        }
    };
    const modelTitle = editData ? "Edit " : "Create ";
    const modelButtonLbl = editData ? "" : "Save";
    const { sidebarOpen } = useSidebar();

    return (
        <div>
            <div style={sidebarOpen ? { marginLeft: "10px", marginTop: "-10px" } : { marginLeft: "10px", marginTop: "-50px" }}>
            <Loading status={loaderModule} /> 
                <View size="md" isOpen={createModule} onClose={setCreateModule} onSubmit={validateForm} title={`${modelTitle} Employee Leave`}>
                    {/* <DynamicCheckBox options={options} onSelectionChange={handleSelectionChange} /> */}
                    <FormLayout ref={customerFormRef} defaultState={state} onChangeCallback={{ "onSelectEndDate": onSelectEndDate, "resetState": resetFilledData, "handleDayType": handleDayType }} setDefaultState={setState} dynamicForm={employeeLeaveForm} noOfColumn={2} onSubmit={onSubmitForm} />

                </View>
                <View isOpen={assignModule} size="sm" savetitle={modelButtonLbl} onClose={setAssignModule} onSubmit={validateForm} title={"Assign Employee Leave"}>
                    <FormLayout ref={customerFormRef} defaultState={state} setDefaultState={setState} dynamicForm={assignStatusForm} noOfColumn={1} onSubmit={onSubmitForm} />
                </View>

                <SimpleCard  >

                    <CustomTable
                        columns={column}
                        data={itemList}
                        initialPage={1}
                        buttonData={pageItems.create ? ['add'] : []} handleClick={handleClick} tableName={"Apply Leave"}
                    />
                </SimpleCard>


            </div>
        </div>
    )
}

export default EmployeeLeave;