import { returnApiCallPromise } from './ApiContent';
import { qualification,deleteQualification } from './APIContainer';

//DELETE
export function deleteEmployeeQualification(request) {
  const requestObj = request ? { params: request } : "";
  return (dispatch) => {
    returnApiCallPromise("DELETE", deleteQualification, requestObj)
      .then(response => {
        dispatch({
          type: 'DELETE_EMPLOYEE_QUALIFICATION_SUCCESS',
          payload: response.data
        })
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'DELETE_EMPLOYEE_QUALIFICATION_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

//GET
export function getQualification(request) {
  const requestObj = request ? { params: request } : "";
  return (dispatch) => {
    returnApiCallPromise("GET",qualification, requestObj)
      .then(response => {
        dispatch({
          type: 'GET_QUALIFICATION_SUCCESS',
          payload: response.data
        })
      }).catch(error => {
        
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'GET_QUALIFICATION_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

export function asyncQualificationInfo(request) {
  const requestObj = request ? { params: request } : "";
  return  returnApiCallPromise("GET", qualification, requestObj)
      .then(response => {
        return {
          error: false,
          message:"Succsss",
          data: response?.data?.data || []
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        return {
          error: true,
          data:[],
          message:errorMessage
        }
      })
}


//POST
export function createQualification(request) {

  return (dispatch) => {
    returnApiCallPromise("POST",qualification, request)
      .then(response => {
        if (response.data.error) {
          dispatch({
            type: 'CREATE_QUALIFICATION_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'CREATE_QUALIFICATION_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'CREATE_QUALIFICATION_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

export function updateQualification(request, qualificationId) {
  return (dispatch) => {
    returnApiCallPromise("PUT",qualification+"/"+qualificationId, request)
      .then(response => {

        if (response.data.error) {
          dispatch({
            type: 'UPDATE_QUALIFICATION_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'UPDATE_QUALIFICATION_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'UPDATE_QUALIFICATION_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}