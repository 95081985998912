
const subjectTeachersForm = [
    {
        title: "Basic Information",
        formBody: [
            {
                formField: [
                    {
                        label: "Employee",
                        inputType: "select",
                        options: "employeeList",
                        uniqueKey: "employeeId",
                        displayKey: "employeeCode",
                        subDisplayKey: "employeeName",
                        onChange: "resetState",
                        // multiple: true,
                        fieldName: "employeeId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        }
                    },
                    {
                        label: "Batch Name",
                        inputType: "select",
                        options: "batchList",
                        uniqueKey: "batchId",
                        displayKey: "batchName",
                        multiple: false,
                        fieldName: "batchId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    
                   
                ]
            },
            {
                formField: [
                   
                    
                    {
                        label: "Subject",
                        inputType: "select",
                        options: "subjectList",
                        uniqueKey: "subjectId",
                        displayKey: "subjectTitle",
                        multiple: true,
                        fieldName: "subjectId",
                        defaultValue: "",
                        config: {
                            required: "",
                        }
                    },
                    {
                        label: "Class-Section",
                        inputType: "select",
                        options: "enrollmentDetailsList",
                        uniqueKey: "enrollmentId",
                        displayKey: "classTitle",
                        subDisplayKey: "sectionTitle",
                        multiple: true,
                        fieldName: "enrollmentId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                   
                   
                ]
            },
            {
                formField: [
                   
                    
                    {
                        description: "Class Teacher",
                        inputType: "checkbox",
                        fieldName: "classTeacher",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    }
                ]
            },
        ]
    }
];

const filterSubjectTeachersForm = [
    {
        title: "Basic Information",
        formBody: [
            {
                formField: [
                    {
                        label: "Batch Name",
                        inputType: "select",
                        options: "batchList",
                        uniqueKey: "batchId",
                        displayKey: "batchName",
                        multiple: false,
                        fieldName: "addBatchId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },
            {
                formField: [
                    {
                        label: "Employee",
                        inputType: "select",
                        options: "employeeList",
                        uniqueKey: "employeeId",
                        displayKey: "employeeCode",
                        subDisplayKey: "employeeName",
                        onChange: "resetState",
                        limit: "1",
                        fieldName: "addEmployeeId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        }
                    },
                ]
            }
        ]
    }
];

const subjectFilterForm = [
    {
        title: "Basic Information",
        formBody: [
            {
                formField: [
                    {
                        label: "Batch Name",
                        inputType: "select",
                        options: "batchList",
                        uniqueKey: "batchId",
                        displayKey: "batchName",
                        multiple: false,
                        fieldName: "addBatchId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },
            {
                formField: [
                    {
                        label: "Employee",
                        inputType: "select",
                        options: "employeeList",
                        uniqueKey: "employeeId",
                        displayKey: "employeeCode",
                        subDisplayKey: "employeeName",
                        // multiple:true,
                        fieldName: "addEmployeeId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },

        ]
    }
];
export {
    subjectTeachersForm,
    filterSubjectTeachersForm,
    subjectFilterForm,
};