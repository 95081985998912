import { returnApiCallPromise } from './ApiContent';
import { section } from './APIContainer';

//GET
export function getSection(request) {
  const requestObj = request ? { params: request } : "";
  return (dispatch) => {
    returnApiCallPromise("GET", section, requestObj)
      .then(response => {
        dispatch({
          type: 'GET_SECTION_SUCCESS',
          payload: response.data
        })
      }).catch(error => {
        
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'GET_SECTION_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}


export function asyncGetSectionInfo(request) {
  const requestObj = request ? { params: request } : "";
  return  returnApiCallPromise("GET", section, requestObj)
      .then(response => {
        return {
          error: false,
          message:"Succsss",
          data: response?.data?.data || []
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        return {
          error: true,
          data:[],
          message:errorMessage
        }
      })
}

//POST
export function createSection(request) {

  return (dispatch) => {
    returnApiCallPromise("POST",  section, request)
      .then(response => {
        if (response.data.error) {
          dispatch({
            type: 'CREATE_SECTION_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'CREATE_SECTION_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'CREATE_SECTION_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

export function updateSection(request, sectionId) {
  return (dispatch) => {
    returnApiCallPromise("PUT",  section+"/"+sectionId , request)
      .then(response => {

        if (response.data.error) {
          dispatch({
            type: 'UPDATE_SECTION_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'UPDATE_SECTION_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'UPDATE_SECTION_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}