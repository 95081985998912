import * as yup from "yup";
import { Formik } from "formik";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import DateTime from "react-datetime";
import { Form, Button } from "react-bootstrap";
import { DateRange } from "react-date-range";
import SimpleCard from 'app/components/atoms/simple-card/SimpleCard'
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { MdEdit, MdDelete } from "react-icons/md";

import swal from "sweetalert2";
import _ from "lodash";
import { pagePermission, showMessage } from "app/utils/app-functions";
import FormLayout from "app/components/render/formlayout";
import View from "app/components/atoms/modalView/Modalview";
import CustomTable from "app/components/atoms/custom-table/CustomTable";
import formRender from "app/components/render/formcomponent";
import { batchForm } from "./formData";
import { createBatch, getBatch, updateBatch } from "app/api/BatchApi";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import Alert from "app/components/atoms/alert";
import { useSidebar } from "app/components/sidebar/sidebarContext";

let editData = false;

const Batch = ({ navigation }) => {
    const dispatch = useDispatch();
    const customerFormRef = useRef();

    const getBatchSuccess = useSelector((state) => state.batchReducer.getBatchSuccess);
    const getBatchList = useSelector((state) => state.batchReducer.getBatchList);
    const getBatchFailure = useSelector((state) => state.batchReducer.getBatchFailure);

    const createBatchSuccess = useSelector((state) => state.batchReducer.createBatchSuccess);
    const createBatchData = useSelector((state) => state.batchReducer.createBatchData);
    const createBatchFailure = useSelector((state) => state.batchReducer.createBatchFailure);

    const updateBatchSuccess = useSelector((state) => state.batchReducer.updateBatchSuccess);
    const updateBatchData = useSelector((state) => state.batchReducer.updateBatchData);
    const updateBatchFailure = useSelector((state) => state.batchReducer.updateBatchFailure);

    const batchErrorMessage = useSelector((state) => state.batchReducer.errorMessage);  

    const [pageItems, setpageItems] = useState({
       
    }
    );

    useEffect(() => {
        let data = pagePermission(pageItems, "Masters", "Batch")
        setpageItems(data)

    }, [navigation]);

    const column = [
        {
            "dataField": "sNo",
            "text": "S.No",
            "sort": true,
            formatter: (cellContent, row, index) => (
                index + 1
            )
        }, 
        {
            "dataField": "batchName",
            "text": "Batch Name",
            "sort": true
        },
        {
            "dataField": "batchStart",
            "text": "Batch Year",
            "sort": true,
            formatter: (cellContent, row, index) => (
                row.batchStart+"-"+row.batchEnd
            )
        },
        {
            dataField: "isActive",
            text: "Status",
            sort: true,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => {
                const conInfo = { inputType: "status", status: cellContent }
                return formRender(null, null, conInfo, null);
            }
        },   
    ]
    
    if(pageItems.delete || pageItems.update){
        column.push({
            text: "Action",
            sort: false,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => (
                <div className="icon-container">
                   { pageItems.update && <FiEdit
                        className="text-primary cursor-pointer"
                        size={18}
                        onClick={() => onEditForm(row, index)}
                    ></FiEdit>}
                   { pageItems.delete && <FiTrash2
                        className="text-danger cursor-pointer"
                        size={18}
                        onClick={() => {
                            onDeleteItem(row, index);
                        }}
                    ></FiTrash2>}
                </div>
            )
        })
    }
    const [state, setState] = useState({
        batchName: "",
        batchStart:"",
        batchEnd:""
    });

    const [createModule, setCreateModule] = useState(false);
    const [loaderModule, setLoaderModule] = useState(false);
    const [editModule, setEditModule] = useState(false);
    const [viewModule, setViewModule] = useState(false);
    const [deleteModule, setDeleteModule] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [selectedItem, setSelectedItem] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);

    useEffect(() => {
        setLoaderModule(true)
        const requestData = {            
            isActive: "true"
        }
        dispatch(getBatch(requestData))
    }, [navigation]);

       const confromDialog = () => {
                return (
                    swal
                        .fire({
                            title: "Are you sure?",
                            text: "You won't be able to revert this!",
                            icon: "warning",
                            type: "question",
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Yes, delete it!",
                            cancelButtonText: "No",
                        })
                        .then((result) => {
                            if (result.value) {
                                onSubmitForm()
                            } else {
                                setDeleteModule(false)
                                swal.fire({
                                    title: "Cancelled!",
                                    text: "Permission denied.",
                                    type: "error",
                                    icon: "error",
                                    timer: 1500,
                                });
                            }
                        })
                )
            }
        
            useEffect(() => {
                if (deleteModule) {
                    confromDialog()
                }
            }, [deleteModule])

    useEffect(() => {
        if (getBatchSuccess) {
            setItemList(getBatchList)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_BATCH" })
        } else if (getBatchFailure) {     
            setItemList([])
            showMessage("warning",batchErrorMessage?.message) 
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_BATCH" })
        }
    }, [getBatchSuccess, getBatchFailure]);

    useEffect(() => {
        if (createBatchSuccess) {
            const temp_state = [...itemList, createBatchData[0]];
            setItemList(temp_state)
            closeModule()
            setLoaderModule(false)
            Alert({  description: 'Created Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            dispatch({ type: "RESET_CREATE_BATCH" })
        } else if (createBatchFailure) {
            setLoaderModule(false)
            showMessage("warning",batchErrorMessage?.message) 
            dispatch({ type: "RESET_CREATE_BATCH" })
        }
    }, [createBatchSuccess, createBatchFailure]);

    useEffect(() => {        
        if (updateBatchSuccess) {           
            updateTable(updateBatchData[0])
            dispatch({ type: "RESET_UPDATE_BATCH" })
        } else if (updateBatchFailure) {
            setLoaderModule(false)    
            showMessage("warning",batchErrorMessage?.message) 
            dispatch({ type: "RESET_UPDATE_BATCH" })
        }
    }, [updateBatchSuccess, updateBatchFailure]);

    const updateTable = (updatedItem) => {
        const temp_state = [...itemList];
        if(deleteModule) {      
            let remainingList = _.remove(temp_state, function (n) {
                return n.batchId != updatedItem.batchId;
            });          
            Alert({  description: 'Deleted Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(remainingList)            
        } else {
            temp_state[selectedIndex] = updatedItem;
            Alert({  description: 'Updated Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(temp_state)        
        }
        setLoaderModule(false)
        closeModule()
    }

    const closeModule = () => {
        editData = false;
        setCreateModule(false)
        setDeleteModule(false)
    }

    const resetState = () => {
        setState({
            ...state,
            batchName: "",
            batchStart: "",
            batchEnd: ""
        })
    }

    const onCreateForm = () => {
        resetState()
        editData = false;
        setCreateModule(true)
    }

    const onEditForm = (data, index) => {     
        setState({
            ...state,
            batchName: data?.batchName || "",
            batchStart: data?.batchStart || "",
            batchEnd: data?.batchEnd || ""
        })
        editData = true;
        setSelectedIndex(index)
        setSelectedItem(data)
        setCreateModule(true)
    }

    const onDeleteItem = (data, index) => {     
        editData = false;
        setSelectedIndex(index)
        setSelectedItem(data)
        setDeleteModule(true)
    }

    const validateForm = async () => {
        const validatefom = customerFormRef.current.validateForm();
    }

    const onSubmitForm = () => {
        const requestDate = {
            batchName: batchName,
            batchStart: batchStart,
            batchEnd: batchEnd
        }
        if (editData) {
            setLoaderModule(true)  
            dispatch(updateBatch(requestDate, selectedItem.batchId))
        } else if (deleteModule) {
            const deleteRequest = {
                isActive: false
            }
            setLoaderModule(true)  
            dispatch(updateBatch(deleteRequest, selectedItem.batchId))
        } else {
            setLoaderModule(true)  
            dispatch(createBatch(requestDate))
        }
    }

    const {
        batchName,
        batchStart,
        batchEnd 
    } = state;

    const handleClick = (buttonName) => {
        if (buttonName == 'Add') {
            onCreateForm();
        }
    };
    const handleCloseModal = () => {
        setCreateModule(false);
    };
    const modelTitle = editData ? "Edit " : "Create ";
    const modelButtonLbl = editData ? "" : "Save";
    const { sidebarOpen } = useSidebar();

    return (
        <div>
        <div style={sidebarOpen ? { marginLeft: "10px", marginTop: "-10px" } : { marginLeft: "10px", marginTop: "-50px" }}>
            {/* <SmallLoader value={loaderModule} /> */}
            <View isOpen={createModule} size="sm" savetitle={modelButtonLbl} onClose={handleCloseModal} onSubmit={validateForm} title={`${modelTitle} Batch`}>
                <FormLayout ref={customerFormRef} defaultState={state} setDefaultState={setState} dynamicForm={batchForm} noOfColumn={1} onSubmit={onSubmitForm} />
            </View>


            <View centered={true} size={'sm'} isOpen={deleteModule} onClose={setDeleteModule} savetitle="Yes" onSubmit={onSubmitForm} title="Delete Batch">
                <h6 className="text-capitalize mb-1">Are you sure ?</h6>
            </View>

            <SimpleCard >
                <CustomTable buttonData={pageItems.create ? ['add'] : []} handleClick={handleClick} tableName={"Batch"} columns={column} data={itemList} initialPage={1} />
            </SimpleCard>

        </div>
        </div>
    )
};

export default Batch;
