
const departmentForm = [
    {
        title: "Basic Information",
        formBody : [
            {
                formField: [
                    {
                        label: "Department Name",
                        inputType: "text",
                        placeholder: "Enter Department name",
                        fieldName:"departmentName",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },    
                    {
                        label: "Description",
                        inputType: "textarea",
                        placeholder: "Enter description",
                        fieldName:"description",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }
                                                                     
                ]
            }
        ]
    }
];

export {
    departmentForm
};