import React, { useState, useCallback } from "react";
import Cropper from "react-easy-crop";
import getCroppedImg from "./cropImage";
import { Button } from "react-bootstrap";
import { dataURLtoFile } from "./URLConverter";

const ImageCropper = ({
  image,
  croppedImg,
  setCroppedImg,
  againCrop,
  setAgainCrop,
  handleFileSelect,
  closeModule,
  state,
  setState,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        image,
        croppedAreaPixels,
        rotation
      );
      if (croppedImage) {
        const convertDataUrl = croppedImage.toDataURL("image/jpeg");
        setCroppedImg(convertDataUrl);
        setAgainCrop(true);
        closeModule(false);

        const convertFileUrl = dataURLtoFile(convertDataUrl, "cropped-image.jpeg");
        setState({
          ...state,
          profilePic: convertDataUrl,
        });
      }
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation]);

  return (
    <div
      style={{
        padding: "20px",
        maxWidth: "600px",
        margin: "auto",
        backgroundColor: "#fff",
        borderRadius: "15px",
        boxShadow: "0 8px 20px rgba(0, 0, 0, 0.1)",
        fontFamily: "'Roboto', sans-serif",
      }}
    >
      <div>
        {againCrop ? (
          <img
            className="img-fluid"
            src={croppedImg}
            alt="Cropped Preview"
            style={{
              borderRadius: "10px",
              marginBottom: "20px",
              maxWidth: "100%",
              boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
            }}
          />
        ) : (
          <div>
            <div
              style={{
                position: "relative",
                width: "100%",
                height: "300px",
                marginBottom: "20px",
                borderRadius: "10px",
                overflow: "hidden",
              }}
            >
              <Cropper
                image={image}
                crop={crop}
                rotation={rotation}
                zoom={zoom}
                aspect={1}
                onCropChange={setCrop}
                onRotationChange={setRotation}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            </div>
            <div style={{ marginBottom: "20px" }}>
              <div style={{ marginBottom: "15px" }}>
                <label
                  style={{
                    display: "block",
                    marginBottom: "5px",
                    fontWeight: "500",
                    fontSize: "14px",
                  }}
                >
                  Zoom
                </label>
                <input
                  type="range"
                  value={zoom}
                  min="1"
                  max="10"
                  step="0.1"
                  onChange={(e) => setZoom(e.target.value)}
                  style={{
                    width: "100%",
                    appearance: "none",
                    background: "#ddd",
                    height: "5px",
                    borderRadius: "5px",
                    outline: "none",
                  }}
                />
              </div>
              <div>
                <label
                  style={{
                    display: "block",
                    marginBottom: "5px",
                    fontWeight: "500",
                    fontSize: "14px",
                  }}
                >
                  Rotation
                </label>
                <input
                  type="range"
                  value={rotation}
                  min="0"
                  max="360"
                  step="1"
                  onChange={(e) => setRotation(e.target.value)}
                  style={{
                    width: "100%",
                    appearance: "none",
                    background: "#ddd",
                    height: "5px",
                    borderRadius: "5px",
                    outline: "none",
                  }}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <label htmlFor="upload-single-file" style={{ margin: 0 }}>
                <Button
                  variant="outline-primary"
                  as="span"
                  style={{
                    padding: "5px",
                    borderRadius: "30px",
                    fontWeight: "500",
                    fontSize:"12px"

                  }}
                >
                  Upload Image
                </Button>
              </label>
              <input
                type="file"
                id="upload-single-file"
                accept="image/*"
                onChange={handleFileSelect}
                style={{ display: "none" }}
              />
              <Button
                variant="primary"
                onClick={showCroppedImage}
                style={{
                  padding: "5px",
                  borderRadius: "30px",
                  fontWeight: "500",
                  fontSize:"12px"
                }}
              >
                {againCrop ? "Crop Again" : "Crop And Save"}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageCropper;
