import React, { useState } from 'react';
import { Select, List, Button } from 'antd';
import { CaretRightOutlined, CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { MdAdd } from 'react-icons/md';
import { lightTheme } from 'styles/theme';

const UnitMenu = ({ units,state, onUnitClick, selectedUnit ,isDropDown}) => {
    const [expandedUnits, setExpandedUnits] = useState({});
    const [searchValue, setSearchValue] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    console.log(state)
    const toggleBranch = (unitId) => {
        setExpandedUnits((prev) => ({
            ...prev,
            [unitId]: !prev[unitId],
        }));
    };

    const handleSearch = (value) => {
        setSearchValue(value.toLowerCase());
    };

    const filteredUnits = units.filter((unit) => {
        const unitMatches = unit.unitName.toLowerCase().includes(searchValue);
        const branchMatches = unit.branches?.some((branch) =>
            branch.unitName.toLowerCase().includes(searchValue)
        );
        return unitMatches || branchMatches;
    });

    return (
        <Select
            style={{ width: '250px' }}
            placeholder="Select a unit"
            value={selectedUnit} // Bind selected value
            onDropdownVisibleChange={(open) => setIsOpen(open)}
            onChange={(value) => onUnitClick(value)} // Pass the value to parent handler
            showSearch
            disabled={(state?.unitList.length === 1 && !isDropDown) ? true : false}
            suffixIcon={ !isDropDown  ? '':
                <CaretDownOutlined
                    style={{
                        fontSize: '16px',
                        color: lightTheme.primaryColor,
                        transition: 'transform 0.5s ease', // Smooth rotation
                        transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)', // Rotate when dropdown is open
                    }}
                />
            }
            //   bordered={false}
            onSearch={handleSearch}
            filterOption={false} // Let our custom search handle filtering
            dropdownRender={() => (
                <div>
                    <List
                        bordered
                        dataSource={filteredUnits}
                        renderItem={(unit) => (
                            <div style={{ padding: '5px 10px' }}>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        backgroundColor: unit.unitName === selectedUnit ? '#f0f5ff' : 'transparent',
                                        borderRadius: '4px',
                                        transition: 'background-color 0.3s ease',
                                    }}
                                >
                                    <span
                                        style={{
                                            cursor: 'pointer',
                                            fontWeight: unit.unitName === selectedUnit ? 'bold' : 'normal',
                                            color: 'black',
                                        }}
                                        onClick={() => onUnitClick(unit, unit.unitId, unit.unitName)} // Set unit on click
                                    >
                                        {unit.unitName}
                                    </span>
                                    {unit.branches?.length > 0 && (
                                        <Button
                                            type="text"
                                            icon={expandedUnits[unit.unitId] ? <CaretDownOutlined /> : <CaretRightOutlined />}
                                            onClick={() => toggleBranch(unit.unitId)}
                                        />
                                    )}
                                </div>
                                <div
                                    style={{
                                        maxHeight: expandedUnits[unit.unitId] ? `${unit.branches.length * 40}px` : '0px',
                                        overflow: 'hidden',
                                        transition: 'max-height 0.5s ease',
                                        marginLeft: '20px',
                                        marginTop: '5px',
                                    }}
                                >
                                    {unit.branches?.map((branch) => (
                                        <div
                                            key={branch.unitId}
                                            style={{
                                                padding: '5px 0',
                                                cursor: 'pointer',
                                                fontWeight: branch.unitName === selectedUnit ? 'bold' : 'normal',
                                                backgroundColor: branch.unitName === selectedUnit ? '#e6f7ff' : 'transparent',
                                                borderRadius: '4px',
                                                transition: 'background-color 0.3s ease',
                                            }}
                                            onClick={() => onUnitClick(branch, branch.unitId, branch.unitName)} // Allow selecting branches too
                                        >
                                            {branch.unitName}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    />
                </div>
            )}
        />
    );
};

export default UnitMenu;
