
const schoolInfoForm = [
    {
        title: "Basic Information",
        formBody : [
            {
                formField: [
                    {
                        label: "School Name",
                        inputType: "text",
                        placeholder:"Enter School Name",
                        fieldName:"schoolName",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Phone Number",
                        inputType: "phonenumber",
                        fieldName:"phoneNumber",
                        placeholder:"Enter Phone Number",
                        errorMsg:"* Invalid Phone Number",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    
                    {
                        label: "Address 1",
                        inputType: "textarea",
                        fieldName:"address1",
                        placeholder:"Enter Address 1",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                   
                    {
                        label: "State",
                        inputType: "select",
                        options: "stateList",
                        uniqueKey: "stateId",
                        displayKey: "stateName",
                        disable: false,
                        fieldName: "stateId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Pin Code",
                        inputType: "text",
                        fieldName:"pincode",
                        placeholder:"Enter Pincode",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },  
                ]
            },
            {
                formField: [
                    {
                        label: "Email",
                        inputType: "text",
                        fieldName:"email",
                        placeholder:"Enter Email",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Land Mark",
                        inputType: "text",
                        fieldName:"landMark",
                        placeholder:"Enter Land Mark",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    },
                    {
                        label: "Address 2",
                        inputType: "textarea",
                        fieldName:"address2",
                        placeholder:"Enter Address 2",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                   
                    {
                        label: "City",
                        inputType: "select",
                        options: "cityList",
                        uniqueKey: "cityId",
                        displayKey: "cityName",
                        multiple: false,
                        fieldName: "cityId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    
                ]
            }, 
        ]
    }
];

export {
    schoolInfoForm
};