const taxConfigForm = [
    {
        title: "",
        formBody: [
            {
                // title: "Select Country",
                formField: [
                    {
                        label: "Select Country",
                        options:"countryList",
                        displayKey:"countryName",
                        uniqueKey:"countryId",
                        inputType: "select",
                        placeholder: "Select Country",
                        fieldName: "countryId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                  /*   {
                        label: "Description",
                        inputType: "textarea",
                        placeholder: "Enter description",
                        fieldName: "description",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Project Value",
                        inputType: "number",
                        placeholder: "Enter Project Value",
                        fieldName: "projectValue",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    } */
                ]
            },
            {
                title: "true",
              /*   formField: [
                    {
                        label: "Start Date",
                        inputType: "date",
                        placeholder: "Enter Start Date",
                        fieldName: "startDate",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "End Date",
                        inputType: "date",
                        placeholder: "Enter End Date",
                        fieldName: "endDate",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "",
                        inputType: "space",
                        height: 5
                    },
                    {
                        label: "Advance Amount",
                        inputType: "number",
                        placeholder: "Enter Advance Amount",
                        fieldName: "advanceAmount",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }
                ] */
            }
        ]
    },
 
];

const projectSiteDetailsForm = [
    {
        title: "Basic Information",
        formBody: [
            {
                formField: [
                    {
                        label: "",
                        inputType: "text",
                        fieldName: "code",
                        placeholder: "Enter Tax Code",

                        defaultValue: "required",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },
            

            {
                formField: [
                    {
                        label: "",
                        inputType: "text",
                        placeholder: "Enter Tax Name",
                        fieldName: "name",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }

                ]
            },

            {
                formField: [
                    {
                        label: "",
                        inputType: "icon-table",
                        index: 'delete',
                        fieldName: "delete",
                        onRight: "90px",
                        onClick: "onDeletePress",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            }
        ]
    }
];
export { taxConfigForm ,projectSiteDetailsForm}
