import { returnApiCallPromise } from './ApiContent';
import { materialCode } from './APIContainer';
 
//GET
 
export function getMaterialCode(request) {
  const requestObj = request ? { params: request } : "";
  return (
    returnApiCallPromise("GET", materialCode, requestObj)
      .then(response => {
        const result = {
          getMaterialCodeSuccess: true,
          getMaterialCodeList: response.data
        }
       
        return result;
      }).catch(error => {
 
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
 
        const result = {
          getMaterialCodeFailed: true,
          errorMessage: errorMessage
        }
       
        return result;
      })
  )
}
 
export function getMaterialCode_1(request) {
  const requestObj = request ? { params: request } : "";
  return (dispatch) => {
    returnApiCallPromise("GET", materialCode, requestObj)
      .then(response => {
        dispatch({
          type: 'GET_MATERIALCODE_SUCCESS',
          payload: response.data
        })
      }).catch(error => {
 
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'GET_MATERIALCODE_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}
 
//POST
export function createMaterialCode(request) {
 
  return (dispatch) => {
    returnApiCallPromise("POST", materialCode, request)
      .then(response => {
        if (response.data.error) {
          dispatch({
            type: 'CREATE_MATERIALCODE_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'CREATE_MATERIALCODE_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'CREATE_MATERIALCODE_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}
 
export function updateMaterialCode(request, materialCodeId) {
  return (dispatch) => {
    returnApiCallPromise("PUT", materialCode + "/" + materialCodeId, request)
      .then(response => {
 
        if (response.data.error) {
          dispatch({
            type: 'UPDATE_MATERIALCODE_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'UPDATE_MATERIALCODE_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'UPDATE_MATERIALCODE_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}