import React from 'react';
import { statusForm, feesRecords } from './formData';
import View from 'app/components/atoms/modalView/Modalview';
import CustomTable from 'app/components/atoms/custom-table/CustomTable';
import FormLayout from 'app/components/render/formlayout';
import SimpleCard from 'app/components/atoms/simple-card/SimpleCard';
import MultiTabs from 'app/components/atoms/multiTab/MultiTab';
import { getVisitor } from "app/api/VisitorApi"
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import swal from "sweetalert2";
import _ from "lodash";
import { showMessage, dateConversion } from "app/utils/app-functions";
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import { useSidebar } from "app/components/sidebar/sidebarContext";
import { asyncGetEmployeeInfo } from 'app/api/EmployeeApi';


let editData = false;
const VisitorLog = ({ navigation }) => {
    const dispatch = useDispatch();
    const customerFormRef = useRef();

    const getVisitorSuccess = useSelector((state) => state.visitorReducer.getVisitorSuccess);
    const getVisitorList = useSelector((state) => state.visitorReducer.getVisitorList);
    const getVisitorFailure = useSelector((state) => state.visitorReducer.getVisitorFailure);
    const visitorErrorMessage = useSelector((state) => state.visitorReducer.errorMessage);


    const [pageItems, setpageItems] = useState({});

    /*   useEffect(() => {
          let data = pagePermission(pageItems, "Masters", "VisitorLog")
          setpageItems(data)
  
      }, [navigation]); */

    const column = [
        {
            "dataField": "firstName",
            "text": "Visitor Name",
            "sort": true
        },
        {
            "dataField": "phoneNo",
            "text": "Phone Number ",
            "sort": false
        },
        {
            "dataField": "meetingDate",
            "text": "Meeting Date",
            "sort": false,
            "formatter": (cellContent, row, index) => {
                return dateConversion(cellContent);
            }
        },
        {
            "dataField": "WhomToMeet",
            "text": "Whom to Meet ",
            "sort": false,
            "formatter": (cellContent, row, index) => {
                let employeList = state?.employeeList || []
                let employeData = employeList.find((data, index) => (
                    row.whomToMeet === data.employeeId

                ))
                console.log(employeData)

                return employeData?.employeeName || "Not Specified";
            }
        },
        /*  {
             dataField: "isActive",
             text: "Status",
             sort: true,
             align: "center",
             headerAlign: "center",
             formatter: (cellContent, row, index) => {
                 const conInfo = { inputType: "status", status: cellContent }
                 return formRender(null, null, conInfo, null);
             }
         }, */
    ]
    if (pageItems.update || pageItems.delete) {
        column.push({
            text: "Action",
            sort: false,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => (
                <div className="icon-container">
                    {pageItems.update && <FiEdit
                        className="text-success cursor-pointer"
                        size={18}
                        onClick={() => onEditForm(row, index)}
                    ></FiEdit>}
                    {pageItems.delete && <FiTrash2
                        className="text-danger cursor-pointer"
                        size={18}
                        onClick={() => {
                            swal
                                .fire({
                                    title: "Are you sure?",
                                    text: "You won't be able to revert this!",
                                    icon: "warning",
                                    type: "question",
                                    showCancelButton: true,
                                    confirmButtonColor: "#3085d6",
                                    cancelButtonColor: "#d33",
                                    confirmButtonText: "Yes, delete it!",
                                    cancelButtonText: "No",
                                })
                                .then((result) => {
                                    if (result.value) {
                                        onDeleteItem(row, index);
                                    } else {
                                        swal.fire({
                                            title: "Cancelled!",
                                            text: "Permission denied.",
                                            type: "error",
                                            icon: "error",
                                            timer: 1500,
                                        });
                                    }
                                });
                        }}
                    ></FiTrash2>}
                </div>
            )
        },)
    }

    const [state, setState] = useState({

    });

    const [createModule, setCreateModule] = useState(false);
    const [loaderModule, setLoaderModule] = useState(false);
    const [deleteModule, setDeleteModule] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [selectedItem, setSelectedItem] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);
    const listFetching = async () => {
        const requestData = {
            isActive: "true",
        }
        const request = {
            isActive: "true",
            leaveStatusId: 1
        }

        let employeeList = await asyncGetEmployeeInfo(requestData)


        setState({
            ...state,
            employeeList: employeeList?.data || [],


        })

    }

    useEffect(() => {
        setLoaderModule(true)
        const requestData = {
            logStatus: "today",
            isActive: true
        };
        dispatch(getVisitor(requestData))
        listFetching()
    }, [navigation]);


    useEffect(() => {
        if (getVisitorSuccess) {
            setItemList(getVisitorList)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_VISITOR" })
        } else if (getVisitorFailure) {
            setItemList([])
            showMessage("warning", visitorErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_VISITOR" })
        }
    }, [getVisitorSuccess, getVisitorFailure]);



    const onEditForm = (data, index) => {
        setState({
            ...state,
            visitorLogName: data?.visitorLogName || "",
            description: data?.description || ""
        })
        editData = true;
        setSelectedIndex(index)
        setSelectedItem(data)
        setCreateModule(true)
    }

    const onDeleteItem = (data, index) => {
        editData = false;
        setSelectedIndex(index)
        setSelectedItem(data)
        setDeleteModule(true)
    }

    const validateForm = async () => {
        const validatefom = customerFormRef.current.validateForm();
    }

    const handleTabSelect = (key) => {
        const requestData = {
            logStatus: key,
            isActive: true
        };
        setLoaderModule(true)
        dispatch(getVisitor(requestData));
    };


    const modelTitle = editData ? "Edit " : "Create ";
    const modelButtonLbl = editData ? "" : "Save";
    const { sidebarOpen } = useSidebar();


    const tabs = [
        {
            id: "today",
            title: "Today",
            content: (
                <div style={sidebarOpen ? { marginLeft: "10px", marginTop: "-10px" } : { marginLeft: "10px", marginTop: "-35px" }}>
                    <CustomTable
                        columns={column}
                        data={itemList}
                        initialPage={1}
                        tableName="Visitor Log"
                    // addButton={addButton}
                    />
                </div>
            ),
        },
        {
            id: "upcoming",
            title: "Upcoming",
            content: (
                <div style={sidebarOpen ? { marginLeft: "10px", marginTop: "-10px" } : { marginLeft: "10px", marginTop: "-35px" }}>
                    <CustomTable
                        columns={column}
                        data={itemList}
                        initialPage={1}
                        tableName="Visitor Log"
                    // addButton={addButton}
                    />
                </div>
            ),
        }
    ];

    return (
        <div style={sidebarOpen ? { marginLeft: "10px", marginTop: "-10px" } : { marginLeft: "10px", marginTop: "-35px" }}>
           
            <SimpleCard >
                <MultiTabs tabs={tabs} handleTab={handleTabSelect} />
            </SimpleCard>
        </div>
    );
};

export default VisitorLog;
