import { returnApiCallPromise } from './ApiContent';
import { goodsReceived } from './APIContainer';

//GET
export function getGoodsReceived(request) {
  const requestObj = request ? { params: request } : "";
  return (dispatch) => {
    returnApiCallPromise("GET", goodsReceived, requestObj)
      .then(response => {
        dispatch({
          type: 'GET_GOODSRECEIVED_SUCCESS',
          payload: response.data
        })
      }).catch(error => {
        
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'GET_GOODSRECEIVED_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

//POST
export function createGoodsReceived(request) {

  return (dispatch) => {
    returnApiCallPromise("POST", goodsReceived, request)
      .then(response => {
        if (response.data.error) {
          dispatch({
            type: 'CREATE_GOODSRECEIVED_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'CREATE_GOODSRECEIVED_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'CREATE_GOODSRECEIVED_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

export function updateGoodsReceived(request, goodsReceivedId) {
  return (dispatch) => {
    returnApiCallPromise("PUT", goodsReceived+"/"+goodsReceivedId , request)
      .then(response => {

        if (response.data.error) {
          dispatch({
            type: 'UPDATE_GOODSRECEIVED_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'UPDATE_GOODSRECEIVED_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'UPDATE_GOODSRECEIVED_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}