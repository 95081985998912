import { returnApiCallPromise } from './ApiContent';
import { bloodGroup } from './APIContainer';

//GET
export function getBloodGroup(request) {
  const requestObj = request ? { params: request } : "";
  return (dispatch) => {
    returnApiCallPromise("GET", bloodGroup, requestObj)
      .then(response => {
        dispatch({
          type: 'GET_BLOOD_GROUP_SUCCESS',
          payload: response.data
        })
      }).catch(error => {
        
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'GET_BLOOD_GROUP_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

export function asyncGetBloodGroupInfo(request) {
  const requestObj = request ? { params: request } : "";
  return  returnApiCallPromise("GET", bloodGroup, requestObj)
      .then(response => {
        return {
          error: false,
          message:"Succsss",
          data: response?.data?.data || []
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        return {
          error: true,
          data:[],
          message:errorMessage
        }
      })
}


//POST
export function createBloodGroup(request) {

  return (dispatch) => {
    returnApiCallPromise("POST", bloodGroup, request)
      .then(response => {
        if (response.data.error) {
          dispatch({
            type: 'CREATE_BLOOD_GROUP_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'CREATE_BLOOD_GROUP_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'CREATE_BLOOD_GROUP_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

export function updateBloodGroup(request, bloodGroupId) {
  return (dispatch) => {
    returnApiCallPromise("PUT", bloodGroup+"/"+bloodGroupId , request)
      .then(response => {

        if (response.data.error) {
          dispatch({
            type: 'UPDATE_BLOOD_GROUP_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'UPDATE_BLOOD_GROUP_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'UPDATE_BLOOD_GROUP_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}