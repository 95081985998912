import React, { useState } from 'react';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa'; // Import icons for pass/fail
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Collapsible from 'react-collapsible';
import styled, { keyframes } from "styled-components";
import { imagePath, studentPath } from "app/utils/constant";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import ProgressBar from "app/components/atoms/ProgressBar";
import Collapse from 'app/components/Accordion';
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { Card, List, Typography, Tag, Row, Col } from 'antd';
import { Grid, Box, Paper } from '@mui/material'; // Using MUI Grid and Paper components

const StudentExamDashboard = ({ studentFilterData = [], studentExamData = [], studentExamChart = {} }) => {
    let studentObject = studentFilterData[0] || {};
    const totalExams = studentExamData.length;
    const passCount = studentExamData.filter(exam => exam.result === "Pass").length;
    const failCount = totalExams - passCount;
    const [activeIndex, setActiveIndex] = useState(null);

    const handleToggle = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };
    const styles = {
        table: {
            width: '100%',
            marginTop: '10px',
            borderCollapse: 'collapse',
            fontFamily: 'Arial, sans-serif',
            textAlign: 'left',
            fontSize: '12px',
        },
        headerRow: {
            backgroundColor: '#f2f2f2',
        },
        headerCell: {
            padding: '10px',
            color: '#333',
            borderBottom: '1px solid #ddd',
        },
        bodyRow: {
            transition: 'background-color 0.2s',
        },
        bodyCell: {
            padding: '10px',
            borderBottom: '1px solid #ddd',
        },
    };

    const columnStyle = {
        height: '500px', // Set a fixed height for both columns
        borderRadius: '8px', // Optionally, apply border radius for smooth corners
        backgroundColor: '#fff', // Set background color for columns
        padding: '2px', // Add padding to columns
        marginBottom: '10px',
        overflowY: 'auto',
        overflowX: 'hidden',
    };

    const containerStyle = {

    };

    const scrollbarStyle = {
        '::-webkit-scrollbar': {
            display: "hidden"

        },

    };


    const profile = `${imagePath}${studentPath}${studentObject?.studentToken || ""}/${encodeURI(studentObject?.profileImage || "")}`;
    const getStatusTag = (status) => {
        switch (status) {
            case 'Fail':
                return <Tag color="red">Fail</Tag>;
            case 'Pass':
                return <Tag color="green">Pass</Tag>;
            case 'Pending':
                return <Tag color="orange">Pending</Tag>;
            default:
                return <Tag>{status}</Tag>;
        }
    };
    return (
        
           
            <Box sx={{ ...containerStyle }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Paper sx={{ ...columnStyle, ...scrollbarStyle }}>

                            <ProfileSection>
                                <ProfileCard>
                                    <img src={profile} alt="Profile" />
                                    <h2>{studentObject?.studentName || "Not Specified"}</h2>
                                </ProfileCard>


                            </ProfileSection>
                            <StatsCard>
                                <StatsContainer>
                                    <StatsItem status="total">
                                        <h4>Exams</h4>
                                        <p>{totalExams}</p>
                                    </StatsItem>
                                    <StatsItem status="pass">
                                        <h4>Passed</h4>
                                        <p>{passCount}</p>
                                    </StatsItem>
                                    <StatsItem status="fail">
                                        <h4>Failed</h4>
                                        <p>{failCount}</p>
                                    </StatsItem>
                                </StatsContainer>
                            </StatsCard>
                        </Paper>
                    </Grid>

                    {/* Column 2: Teacher Details Table */}
                    <Grid item xs={12} sm={6}>
                        <Paper sx={{ ...columnStyle, ...scrollbarStyle }}>
                            <ExamsSection>
                                <h2 style={{ color: "#4D6D9A" }}><strong>Exam Performance</strong></h2>
                                {studentExamData.map((exam, index) => (
                                    <div key={index} style={{ marginBottom: "30px" }}>
                                        <Collapse
                                            title={
                                                <ToggleButton
                                                    onClick={() => handleToggle(index)}
                                                    rotated={activeIndex === index}
                                                >
                                                    {/* Exam Title */}
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', width: "70%" }}>
                                                        <div className="row" style={{ width: "100%" }}>
                                                            <div className="col">{exam.examName}</div>
                                                            <div className="col">Total-{exam.total || '--'}</div>
                                                            <div className="col">{getStatusTag(exam.result)}</div>
                                                        </div>

                                                    </div>
                                                    <FaChevronDown />
                                                </ToggleButton>
                                            }
                                            isOpen={activeIndex === index}
                                        >
                                            <ExamDetails>
                                                {/* Table for Subjects */}
                                                <table style={styles.table}>
                                                    <thead>
                                                        <tr style={styles.headerRow}>
                                                            <th style={styles.headerCell}>Subject Name</th>
                                                            <th style={styles.headerCell}>Mark Scored</th>
                                                            <th style={styles.headerCell}>Internal Marks</th>
                                                            <th style={styles.headerCell}>Practical Marks</th>
                                                            <th style={styles.headerCell}>Total Marks</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {exam.subjectMarks.map((subject, idx) => (
                                                            <tr key={idx} style={styles.bodyRow}>
                                                                <td style={styles.bodyCell}>{Object.keys(subject)[0]}</td>
                                                                <td style={styles.bodyCell}>
                                                                    <ProgressBar percent={subject.markScored} status="normal" />
                                                                    <span>{subject.markScored}%</span>
                                                                </td>
                                                                <td style={styles.bodyCell}>{subject.internalMark || '--'}</td>
                                                                <td style={styles.bodyCell}>{subject.practicalMark || '--'}</td>
                                                                <td style={styles.bodyCell}>{subject.maxMark || '--'}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </ExamDetails>

                                        </Collapse>
                                    </div>
                                ))}
                            </ExamsSection>

                            <ChartSection>
                                <h2 style={{ color: "#4D6D9A", marginLeft: "20px" }}><strong>Overall Performance</strong></h2>
                                <HighchartsReact highcharts={Highcharts} options={studentExamChart} />
                            </ChartSection>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
      
    );
};

// Styled Components
const Container = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding: 20px;
    font-family: 'Roboto', sans-serif;
    max-width: 100%;
    box-sizing: border-box;
`;

const LeftColumn = styled.div`
    flex: 1; /* Adjust the width as needed */
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const RightColumn = styled.div`
    flex: 2; /* Adjust the width as needed */
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const Header = styled.header`
    background-color: #4D6D9A;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 30px;
    text-align: center;
    color: white;
`;

const ProfileSection = styled.section`
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 30px;
    padding: 20px;
    border-radius: 10px;
    background: #ffffff;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
    }
`;

const ProfileCard = styled.div`
    flex: 1;
    text-align: center;

    img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        object-fit: cover;
        margin: 0 auto;
    }

    h2 {
        font-size: 18px;
        color: #4D6D9A;
        margin-top: 10px;
    }
`;

const StatsCard = styled.div`
    flex: 2;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    border-radius: 10px;
`;


const StatsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    gap:30px;
`;

const StatsItem = styled.div`
    width: 120px;
    height: 120px;
    text-align: center;
    position: relative;
    background: linear-gradient(145deg, #e6e6e6, #ffffff);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    box-shadow: 4px 4px 10px #c8c8c8, -4px -4px 10px #ffffff;
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
        transform: scale(1.1);
        box-shadow: 6px 6px 15px #c4c4c4, -6px -6px 15px #ffffff;
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 6px solid transparent;
        
        border-color: ${({ status }) =>
        status === "total" ? "#4D6D9A" :
            status === "pass" ? "#28a745" :
                "#dc3545"};
        // animation: rotate 1.5s linear infinite;
    }

    @keyframes rotate {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    h4 {
        font-size: 16px;
        color: ${({ status }) =>
        status === "total" ? "#4D6D9A" :
            status === "pass" ? "#28a745" :
                "#dc3545"};
        margin: 0;
    }

    p {
        font-size: 30px;
        margin-left: 10px;
        color: ${({ status }) =>
        status === "total" ? "#4D6D9A" :
            status === "pass" ? "#28a745" :
                "#dc3545"};
    }
`;

const ExamsSection = styled.section`
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    width: 100%;
`;

const ChartSection = styled.section`
    margin-top: 10px;
`;




const ExamDetails = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 6px;
`;


const ToggleButton = styled.div`
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    color: #212B54; 
    padding: 5px 10px;
    border-radius: 6px;
    width: 100%;

    svg {
        margin-left: 6px;
        transition: transform 0.3s ease;
        transform: ${({ rotated }) => (rotated ? 'rotate(180deg)' : 'rotate(0deg)')};
    }
`;

export default StudentExamDashboard;
