import React from 'react';
import {Checkbox as AntdCheckbox} from 'antd';
import styled from 'styled-components';
import './styles.css';
// import {} from 'core/components/index';
const classNames = require('classnames');

const Wrapper = styled(AntdCheckbox.Group)`
width:100%;
`;

const CheckboxGroup = ({options, type, size, position,fontSize, ...rest}) => {
    const className = classNames({
        'ax-checkbox-group': true,  
        [type]: type,  
        [size]: size,
        [position]: position,
        [fontSize]: fontSize
    });

    return (
        <Wrapper {...rest} className={className} options={options}/>
       
    );
};


export default CheckboxGroup;
