import React from 'react';
import './loader.css';

const LeafLoader = ({ color = "#34D399", size = "50px", speed = "1s" }) => {
  // Detect reduced motion preference
  const prefersReducedMotion = window.matchMedia("(prefers-reduced-motion: reduce)").matches;

  return (
    <div className="loader-container">
      <div className="leaf-loader" role="progressbar" aria-live="polite" aria-label="Loading, please wait.">
        <div className="leaf" style={{ width: size, height: size, backgroundColor: color, animationDuration: prefersReducedMotion ? '0s' : speed }} />
        <div className="leaf" style={{ width: size, height: size, backgroundColor: color, animationDuration: prefersReducedMotion ? '0s' : speed }} />
        <div className="leaf" style={{ width: size, height: size, backgroundColor: color, animationDuration: prefersReducedMotion ? '0s' : speed }} />
        <div className="leaf" style={{ width: size, height: size, backgroundColor: color, animationDuration: prefersReducedMotion ? '0s' : speed }} />
      </div>
    </div>
  );
};

export default LeafLoader;
