import * as yup from "yup";
import { Formik } from "formik";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import DateTime from "react-datetime";
import { Form, Button } from "react-bootstrap";
import { DateRange } from "react-date-range";
import SimpleCard from 'app/components/atoms/simple-card/SimpleCard'
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { MdEdit, MdDelete } from "react-icons/md";
import swal from "sweetalert2";
import _ from "lodash";
import { pagePermission, showMessage } from "app/utils/app-functions";
import FormLayout from "app/components/render/formlayout";
import View from "app/components/atoms/modalView/Modalview";
import CustomTable from "app/components/atoms/custom-table/CustomTable";
import formRender from "app/components/render/formcomponent";
import { creditTermForm } from "./formData";
import { FiEdit, FiSend, FiTrash2 } from 'react-icons/fi';
import { createCreditTerm, getCreditTerm, updateCreditTerm, getCreditTermData } from "app/api/CreditTermApi";
import Alert from "app/components/atoms/alert";

let editData = false;

const CreditTerm = ({ navigation }) => {
    const dispatch = useDispatch();
    const customerFormRef = useRef();

    const getCreditTermSuccess = useSelector((state) => state.creditTermReducer.getCreditTermSuccess);
    const getCreditTermList = useSelector((state) => state.creditTermReducer.getCreditTermList);
    const getCreditTermFailure = useSelector((state) => state.creditTermReducer.getCreditTermFailure);

    const createCreditTermSuccess = useSelector((state) => state.creditTermReducer.createCreditTermSuccess);
    const createCreditTermFailure = useSelector((state) => state.creditTermReducer.createCreditTermFailure);
    const createCreditTermData = useSelector((state) => state.creditTermReducer.createCreditTermData);

    const updateCreditTermSuccess = useSelector((state) => state.creditTermReducer.updateCreditTermSuccess);
    const updateCreditTermData = useSelector((state) => state.creditTermReducer.updateCreditTermData);
    const updateCreditTermFailure = useSelector((state) => state.creditTermReducer.updateCreditTermFailure);

    const holidaysErrorMessage = useSelector((state) => state.creditTermReducer.errorMessage);

    const [pageItems, setpageItems] = useState({});

    useEffect(() => {
        let data = pagePermission(pageItems, "Inventory", "Credit Term")
        setpageItems(data)

    }, [navigation]);

    const column = [
        {
            "dataField": "creditTermName",
            "text": "Credit Term Name",
            "sort": true
        },
        {
            "dataField": "description",
            "text": "Description",
            "sort": true,
        },

    ]
    if (pageItems.update || pageItems.delete) {
        column.push({
            text: "Action",
            sort: false,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => (
                <div className="icon-container">
                    {pageItems.update && <FiEdit
                        className="text-primary cursor-pointer"
                        size={18}
                        onClick={() => onEditForm(row, index)}
                    ></FiEdit>}
                    {pageItems.delete && <FiTrash2
                        className="text-danger cursor-pointer"
                        size={18}
                        onClick={() => {
                            onDeleteItem(row, index);

                        }}
                    ></FiTrash2>}
                </div>
            )
        },)
    }

    const [state, setState] = useState({});

    const [createModule, setCreateModule] = useState(false);
    const [loaderModule, setLoaderModule] = useState(false);
    const [deleteModule, setDeleteModule] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [selectedItem, setSelectedItem] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);

    useEffect(() => {
        setLoaderModule(true)
        const requestData = {
            isActive: "true"
        }
        const reqq = {
            pageId: 22,
            noOfColumns: 1
        }
        dispatch(getCreditTermData(requestData))
    }, [navigation]);


    const listFetching = async () => {
        const requestData = {
            isActive: "true"
        }
        let dta = await getCreditTerm(requestData)
        console.log(dta);

        setItemList(dta.getCreditTermList)

    }

    useEffect(() => {
        if (getCreditTermSuccess) {
            setItemList(getCreditTermList)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_CREDIT_TERM" })
        } else if (getCreditTermFailure) {
            setItemList([])
            showMessage("warning", holidaysErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_CREDIT_TERM" })
        }
    }, [getCreditTermSuccess, getCreditTermFailure]);



    useEffect(() => {
        if (createCreditTermSuccess) {
            const temp_state = [...itemList, createCreditTermData[0]];
            setItemList(temp_state)
            setLoaderModule(false)
            closeModule()
            Alert({ description: 'Created Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            dispatch({ type: "RESET_CREATE_CREDIT_TERM" })
        } else if (createCreditTermData) {
            setLoaderModule(false)
            showMessage("warning", holidaysErrorMessage?.message)
            dispatch({ type: "RESET_CREATE_CREDIT_TERM" })
        }
    }, [createCreditTermSuccess, createCreditTermData]);


    useEffect(() => {
        if (updateCreditTermSuccess) {
            updateTable(updateCreditTermData[0])
            dispatch({ type: "RESET_UPDATE_CREDIT_TERM" })
        } else if (updateCreditTermFailure) {
            setLoaderModule(false)
            showMessage("warning", holidaysErrorMessage?.message)
            dispatch({ type: "RESET_UPDATE_CREDIT_TERM" })
        }
    }, [updateCreditTermSuccess, updateCreditTermFailure]);

    const updateTable = (updatedItem) => {
        const temp_state = [...itemList];
        if (deleteModule) {
            let remainingList = _.remove(temp_state, function (n) {
                return n.creditTermId != updatedItem.creditTermId;
            });
            Alert({ description: 'Deleted Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(remainingList)
        } else {
            // alert(JSON.stringify(updatedItem))
            temp_state[selectedIndex] = updatedItem;
            Alert({ description: 'Updated Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(temp_state)
        }
        setLoaderModule(false)
        closeModule()
    }

    const closeModule = () => {
        editData = false;
        setCreateModule(false)
        setDeleteModule(false)
    }

    const resetState = () => {
        setState({
            ...state,
            creditTerm: "",
            description: ""
        })
    }

    const onCreateForm = () => {
        resetState()
        editData = false;
        setCreateModule(true)
    }

    const onEditForm = (data, index) => {
        setState({
            ...state,
            creditTerm: data?.creditTermName || "",
            description: data?.description || "",
        })
        editData = true;
        setSelectedIndex(index)
        setSelectedItem(data)
        setCreateModule(true)
    }

    const onDeleteItem = (data, index) => {
        editData = false;
        setSelectedIndex(index)
        setSelectedItem(data)
        setDeleteModule(true)
    }

    const validateForm = async () => {
        const validatefom = customerFormRef.current.validateForm();
    }

    const onSubmitForm = () => {
        const requestDate = {
            creditTermName: creditTerm,
            description: description
        }
        if (editData) {
            setLoaderModule(true)
            dispatch(updateCreditTerm(requestDate, selectedItem.creditTermId))
        } else if (deleteModule) {
            const deleteRequest = {
                isActive: "false"
            }
            setLoaderModule(true)
            dispatch(updateCreditTerm(deleteRequest, selectedItem.creditTermId))
        } else {
            setLoaderModule(true)
            dispatch(createCreditTerm(requestDate))
        }
    }


    const confromDialog = () => {
        return (
            swal
                .fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    type: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!",
                    cancelButtonText: "No",
                })
                .then((result) => {
                    if (result.value) {
                        onSubmitForm()
                    } else {
                        setDeleteModule(false)
                        swal.fire({
                            title: "Cancelled!",
                            text: "Permission denied.",
                            type: "error",
                            icon: "error",
                            timer: 1500,
                        });
                    }
                })
        )
    }

    useEffect(() => {
        if (deleteModule) {
            confromDialog()
        }
    }, [deleteModule])


    const handleClick = (buttonName) => {
        if (buttonName == 'Add') {
            onCreateForm();
        }
    };

    const {
        creditTerm,
        description,
    } = state;

    const modelTitle = editData ? "Edit " : "Create ";
    const modelButtonLbl = editData ? "" : "Save";
    return (
        <div>
            <div style={{ marginTop: "-10px" }}>
                <View isOpen={createModule} size="sm" savetitle={modelButtonLbl} onClose={setCreateModule} onSubmit={validateForm} title={`${modelTitle} CreditTerm`}>
                    <FormLayout ref={customerFormRef} defaultState={state} setDefaultState={setState} dynamicForm={creditTermForm} noOfColumn={1} onSubmit={onSubmitForm} />
                </View>

                <SimpleCard >
                    <CustomTable buttonData={pageItems.create ? ['add'] : []} handleClick={handleClick} tableName={"Credit Term"} columns={column} data={itemList} initialPage={1} />
                </SimpleCard>

            </div>
        </div>
    )
};

export default CreditTerm;
