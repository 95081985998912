import React, { useState } from 'react';
import './styles.css';
import { Input as AntdInput, InputNumber as AntdInputNumber } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import InputProps from './props';
import Alert from '../alert';
const { TextArea, Search, Password } = AntdInput;

const classNames = require('classnames');

const Input = ({ type, design, position, value, size, placeholder, onChange, info, name, ...props }) => {
    const [t] = useTranslation('common');
    const [error, setError] = useState('');

    const handleUrlChange = (e) => {
        // alert("hi")
        const url = e.target.value;
        // Basic URL validation (you can make this more complex if needed)
        const urlPattern = /^(https?:\/\/)?([\w\d\.-]+)\.([a-z\.]{2,6})(\/[\w\d\.-]*)*\/?$/;

        if (url && !urlPattern.test(url)) {
            setError(t('Please enter a valid URL.'));
            alert("Please enter a valid URL.")
        } else {
            setError('');
        }

        onChange(e); // Call the onChange prop passed from the parent component
    };

    const className = classNames({
        'inv-input': true,
        [position]: position,
        [size]: size,
        [design]: design,
    });

    const allProps = { ...props };

    return (
        <div className={className}>
            {type === InputProps.type.text && <AntdInput style={{height:'30px'}} {...allProps} disabled={info?.disabled || false} value={value} name={name} onChange={onChange} placeholder={placeholder || t('Please Enter here')} />}
            {type === InputProps.type.number && <AntdInputNumber {...allProps} value={value} disabled={info?.disabled || false} name={name} onChange={onChange} placeholder={placeholder || t('Please Enter here')} type="number" />}
            {type === InputProps.type.textarea && <TextArea {...allProps} name={name} disabled={info?.disabled || false} value={value} onChange={onChange} placeholder={placeholder || t('Please Enter here')} />}
            {type === InputProps.type.search && <Search {...allProps} name={name} disabled={info?.disabled || false} value={value} onChange={onChange} placeholder={placeholder || t('Please Enter here')} />}
            {type === InputProps.type.password && <Password {...allProps} name={name} disabled={info?.disabled || false} value={value} onChange={onChange} placeholder={placeholder || t('Please Enter here')} />}
            {type === InputProps.type.url && (
                <>
                    <AntdInput
                        {...allProps}
                        disabled={info?.disabled || false}
                        value={value}
                        name={name}
                        onChange={handleUrlChange} // Custom URL validation logic
                        placeholder={placeholder || ('Please Enter a valid URL')}
                        type="url"
                    />
                    {error && <div style={{ color: 'red', fontSize: '12px' }}>{error}</div>} {/* Show error message */}
                </>
            )}
        </div>
    );
};

Input.propTypes = {
    type: PropTypes.oneOf([
        InputProps.type.text,
        InputProps.type.number,
        InputProps.type.textarea,
        InputProps.type.search,
        InputProps.type.password,
        InputProps.type.url, // Add URL type
    ]),
    size: PropTypes.oneOf([InputProps.size.small, InputProps.size.default, InputProps.size.large]),
};
Input.defaultProps = {
    type: InputProps.type.text,
    size: InputProps.size.default,
};

export default Input;
