
const shiftForm = [
    {
        title: "Basic Information",
        formBody: [
            {
                formField: [
                    {
                        label: "Shift Name",
                        inputType: "text",
                        fieldName: "shiftName",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "",
                        inputType: "time",
                        type: "time",
                        fieldName: "shiftStartTime",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                   
                    {
                        label: "",
                        inputType: "time",
                        type: "time",
                        fieldName: "shiftEndTime",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                   
                ]
            }
        ]
    }
];
export {
    shiftForm
};