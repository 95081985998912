
const deliveryForm = [
    {
        title: "Basic Information",
        formBody: [
            {
                formField: [
                    {
                        label: "From Date",
                        inputType: "date",
                        fieldName: "fromDate",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }
                ]
            },
            {
                formField: [
                    {
                        label: "To Date",
                        inputType: "date",
                        fieldName: "toDate",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }
                ]
            },
            {
                formField: [
                    {
                        label: "Customer Name",
                        inputType: "text",
                        fieldName: "customerName",
                        placeholder: "Customer Name",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },
            {
                formField: [
                    {
                        label: "Quotation Number",
                        inputType: "text",
                        fieldName: "billNumber",
                        placeholder: "Quotation Number",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            }, 
            {
                formField: [

                    {
                        label: "Submit",
                        inputType: "button",
                        classStyle : "mt-4",
                        displayKey: "submit",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    }

                ]
            },
        ]
    }
];

const createDeliveryForm = [{
    title: "Basic Information",
    formBody: [
        {
            formField: [
                {
                    label: "Order List",
                    inputType: "radio",
                    options: "osList",
                    uniqueKey: "osId",
                    type:"block",
                    onChange: "onHandleRadio",
                    displayKey: "osName",
                    fieldName: "osId",
                    defaultValue: "1",
                    config: {
                        required: "",
                    },
                },
                {
                    label: "",
                    inputType: "typedropdown",
                    options: 'deliveryList',
                    uniqueKey: "orderId",
                    // isLoading:true,
                    displayKey: "itemInfo",
                    onChange: "handleOrder",
                    fieldName: "orderId",
                    key: "invoiceKey",
                    defaultValue: "",
                    placeholder: 'Order',
                    config: {
                        required: "required",
                    },
                },
                {
                    subField: [
                        {
                            label: "",
                            inputType: "typedropdown",
                            fieldName: "customerName",
                            options: "customerList",
                            uniqueKey: "customerId",
                            displayKey: "customerName",
                            placeholder: "Customer",
                            onChange: "handleCustomerChange",
                            defaultValue: "",
                            config: {
                                required: "required",
                            },
                            icon: "i-Add"
                        },
                        {
                            label: "",
                            inputType: "select",
                            options: "creditList",
                            uniqueKey: "creditTermId",
                            displayKey: "creditTermName",
                            fieldName: "creditTerm",
                            defaultValue: "",
                            config: {
                                required: "required",
                            },
                        },
                    ]

                },
                {
                    subField: [
                        {
                            label: "",
                            placeholder: "Contact Person",
                            inputType: "text",
                            fieldName: "contactPerson",
                            defaultValue: "",
                            config: {
                                required: "required",
                            },
                        },
                        {
                            label: "",
                            placeholder: "Sales Person",
                            inputType: "text",
                            fieldName: "salesPerson",
                            defaultValue: "",
                            config: {
                                required: "required",
                            },
                        },
                    ]

                },
                {
                    subField: [
                        {
                            label: "",
                            placeholder: "Contact Number",
                            inputType: "text",
                            fieldName: "phoneNo",
                            defaultValue: "",
                            config: {
                                required: "required",
                            },
                        },
                        {
                            label: "",
                            placeholder: "Customer PO Number",
                            inputType: "text",
                            fieldName: "custPoNumber",
                            defaultValue: "",
                            config: {
                                required: "required",
                            },
                        },
                    ]

                },
                {
                    subField: [
                        {
                            label: "",
                            placeholder: "Customer Address*",
                            inputType: "textarea",
                            fieldName: "address",
                            defaultValue: "",
                            config: {
                                required: "required",
                            },
                        },
                        {
                            label: "",
                            placeholder: "Memo*",
                            inputType: "textarea",
                            fieldName: "memo",
                            defaultValue: "",
                            config: {
                                required: "required",
                            },
                        },

                    ]

                },


            ]
        },
        {
            formField: [
                {
                    description: "Same as Customer Address",
                    inputType: "checkbox",
                    onChange:"sameAsCustomer",
                    fieldName: "customerAddress",
                    classStyle: "mt-4",
                    defaultValue: "",
                    config: {
                        required: "",
                    },
                },
                {
                    label: "",
                    inputType: "textarea",
                    fieldName: "billingAddress",
                    placeholder: "Billing Address",
                    defaultValue: "",
                    config: {
                        required: "required",
                    },
                },
                {

                    description: "Same as billing Address",
                    inputType: "checkbox",
                    fieldName: "sameBillingAddress",
                    onChange:"sameAsCustomer",
                    classStyle: "mt-4",
                    defaultValue: "",
                    config: {
                        required: "",
                    },
                },
                {
                    label: "",
                    inputType: "textarea",
                    placeholder: "Delivery Address",
                    fieldName: "deliveryAddress",
                    defaultValue: "",
                    config: {
                        required: "required",
                    },
                },
                
                {
                    label: "",
                    inputType: "date",
                    fieldName: "fromDate",
                    classStyle: "mt-4",
                    defaultValue: "",
                    config: {
                        required: "required",
                    },
                },
                {
                    label: "",
                    inputType: "date",
                    fieldName: "validDate",
                    classStyle: "mt-4",
                    defaultValue: "",
                    config: {
                        required: "required",
                    },
                },
                
            ]
        },
        {
            formField: [
                {
                    description: "Reserve Stock",
                    inputType: "checkbox",
                    fieldName: "reserveStock",
                    classStyle: "mt-4",
                    defaultValue: "",
                    config: {
                        required: "",
                    },
                },
                /* {
                    description: "Including Product Tax",
                    inputType: "checkbox",
                    fieldName: "productTax",
                    classStyle: "mt-4",
                    defaultValue: "",
                    config: {
                        required: "",
                    },
                }, */
                {
                    description: "Price Including GST",
                    inputType: "checkbox",
                    fieldName: "priceIncludingGST",
                    onChange: "sameAsCustomer",
                    classStyle: "mt-4",
                    defaultValue: "",
                    config: {
                        required: "",
                    },
                },
                {
                    label: "",
                    inputType: "select",
                    options: "currencyList",
                    placeholder:"Select Country",

                    uniqueKey: "currencyId",
                    onChange:"handleCurrencyChange",
                    displayKey: "countryName",
                    fieldName: "currencyId",
                    defaultValue: "",
                    config: {
                        required: "required",
                    },
                },
               
            ]
        }
    ]
}]

const createCustomerForm = [{
    title: "Basic Information",
    formBody: [
        {
            formField: [{
                label: "",
                inputType: "text",
                placeholder: "Customer Name*",
                fieldName: "name",
                defaultValue: "",
                config: {
                    required: "required",
                },
            },
            {
                // label: "",
                inputType:'select',
                options: "creditList",
                placeholder: "Select Credit Term",
                uniqueKey: "creditTermId",
                displayKey: "creditTermName",
                fieldName: "creditTerm",
                defaultValue: "",
                config: {
                    required: "required",
                },
            },
            {
                label: "",
                inputType: "typedropdown",
                options: "priceGroupList",
                uniqueKey: "custPriceGroupId",
                displayKey: "custPriceGroupTitle",
                placeholder:'Price Group',
                fieldName: "pricegroup",
                defaultValue: "",
                config: {
                    required: "required",
                },
            },
            {
                label: "",
                inputType: "text",
                placeholder: "Contact Person",
                fieldName: "contact",
                defaultValue: "",
                config: {
                    required: "required",
                },
            },
            {
                label: "",
                inputType: "text",
                placeholder: "Customer UEN",
                fieldName: "customerUen",
                defaultValue: "",
                config: {
                    required: "required",
                },
            },
            {
                label: "",
                inputType: "typedropdown",
                options: "taxList",
                uniqueKey: "taxId",
                displayKey: "taxCode",
                placeholder: "Tax",
                fieldName: "tax",
                defaultValue: "",
                config: {
                    required: "required",
                },
            },
            {
                label: "",
                inputType: "number",
                placeholder: "Credit Limit",
                fieldName: "creditLimit",
                defaultValue: "",
                config: {
                    required: "required",
                },
            },
            {
                label: "",
                inputType: "select",
                options: "currencyList",
                uniqueKey: "currencyId",
                displayKey: "countryName",
                fieldName: "currencyId",
                defaultValue: "",
                config: {
                    required: "required",
                },
            },
            ]
        },
        {
            formField: [
                {
                    label: "",
                    inputType: "text",
                    placeholder: "Email",
                    fieldName: "email",
                    defaultValue: "",
                    config: {
                        required: "required",
                    },
                },
                {
                    label: "",
                    inputType: "text",
                    placeholder: "Contact No",
                    fieldName: "contactNo",
                    defaultValue: "",
                    config: {
                        required: "required",
                    }
                },
                {
                    label: "",
                    inputType: "text",
                    placeholder: "Postal Code",
                    fieldName: "postalCode",
                    defaultValue: "",
                    config: {
                        required: "required",
                    },
                },
                {
                    label: "",
                    inputType: "textarea",
                    placeholder: "Billing Address",
                    fieldName: "CusbillingAddress",
                    defaultValue: "",
                    config: {
                        required: "required",
                    },
                },
                {
                    description: "Same as billing Address",
                    inputType: "checkbox",
                    fieldName: "CusCheckBillingAddress",
                    onChange:"cusBillingAddress",
                    defaultValue: "",
                    config: {
                        required: "",
                    },
                },
                {
                    label: "",
                    inputType: "textarea",
                    placeholder: "Shipping Address",
                    fieldName: "shippingAddress",
                    defaultValue: "",
                    config: {
                        required: "required",
                    },
                },
            ]
        }
    ]
}]

const fieldList = [
    {
        label: "",
        inputType: "select",
        options: "materialTypeList",
        uniqueKey: "materialTypeId",
        displayKey: "materialName",
        disabled:true ,
        fieldName: "materialTypeId",
        defaultValue: "",
        config: {
            required: "required",
        },
    },
   /*  {
        label: "",
        inputType: "typedropdown",
        options: "materialInfoList",
        uniqueKey: "materialId",
        onChange: "handleMaterialChange",
        displayKey: "materialCode",
        fieldName: "materialCode",
        defaultValue: "",
        config: {
            required: "required",
        },
        icon: "i-Magnifi-Glass1"
    }, */
    {
        label: "",
        inputType: "searchableInput",
        options: "materialInfoList",
        placeholder: "materialCodeId",
        fieldName: "materialCode",
        onChange:"handleMaterialChange",
        displayKey: "materialCode",
        disabled: true,
        defaultValue: "",
        config: {
            required: "",
        },
    },
    {
        label: "",
        inputType: "text",
        placeholder: "MaterialName",
        fieldName: "materialName",
        disabled:true ,
        defaultValue: "",
        config: {
            required: "required",
        },
    },
    {
        label: "",
        inputType: "text",
        placeholder: "Qty*",
        fieldName: "qty",
        defaultValue: "",
        config: {
            required: "required",
        },
    },
    {
        label: "",
        inputType: "select",
        fieldName: "unit",
        options: "unitsList",
        // disabled:true ,
        uniqueKey: "unitId",
        displayKey: "unitName",
        defaultValue: "",
        config: {
            required: "required",
        },
    },
    {
        label: "",
        inputType: "icon-table",
        index: 'file',
        fieldName: "description",
        defaultValue: "",
        config: {
            required: "required",
        },
    },
    {
        label: "",
        inputType: "icon-table",
        index: 'outline-clock',
        fieldName: "outline",
        onClick: 'onAddItem',
        defaultValue: "",
        config: {
            required: "required",
        },
    },
    {
        label: "",
        inputType: "icon-table",
        index: 'delete',
        fieldName: "delete",
        onClick: 'onDeletePress',
        defaultValue: "",
        config: {
            required: "required",
        },
    },
]

const sizeList = [
    {
        label: "",
        inputType: "text",
        placeholder: "0",
        fieldName: "length",
        defaultValue: "",
        config: {
            required: "required",
        },
    },
    {
        label: "",
        inputType: "text",
        placeholder: "0",
        fieldName: "width",
        defaultValue: "",
        config: {
            required: "required",
        },
    },
    {
        label: "",
        inputType: "text",
        placeholder: "0",
        fieldName: "thickness",
        defaultValue: "",
        config: {
            required: "required",
        },
    },
]
export {
    deliveryForm, createDeliveryForm, createCustomerForm,fieldList,sizeList
};