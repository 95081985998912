

const eventForm = [
    {
        title: "Basic Information",
        formBody: [
            {
                formField: [
                    {
                        label: "Upload Document",
                        inputType: "file",
                        fieldName: "imageName",
                        fileFormat : "application/pdf" ,
                        onChange:"onWriteDocument",
                        defaultValue: "",
                        disable: false,
                        config: {
                            required: "",
                        },
                    },
                    {
                        label: "Select Class",
                        inputType: "select",
                        options: "studentClassList",
                        uniqueKey:"enrollmentId",
                        displayKey:"classTitle",
                        subDisplayKey:"sectionTitle",
                        fieldName: "enrollmentId",
                        onChange:"handleEnrollmentId",
                        defaultValue: "",
                        disable: false,
                        config: {
                            required: "",
                        },
                    },
                    {
                        label: "Subject",
                        inputType: "select",
                        options: "subjectList",
                        uniqueKey:"subjectId",
                        onChange:"handleSubjectId",
                        displayKey:"subjectTitle",
                        multiple:false,
                        fieldName:"subjectId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        }
                }       
                   
                   
                    /* {
                        label: "Description",
                        inputType: "textarea",
                        placeholder: "Enter Description",
                        fieldName: "description",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }, */
                  /*   {
                        label: "Event Date",
                        inputType: "date",
                        placeholder: "Enter Description",
                        fieldName: "eventDate",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }, */
                   
                   
                    /* {
                        label: "Url",
                        inputType: "text",
                        disable: false,
                        fieldName: "url",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    },
                    {
                        label: "Name",
                        inputType: "text",
                        fieldName: "name",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    }, */
                ]
            },
            {
                formField: [
                   
                    
                  
                    // {
                    //     label: "Start Time",
                    //     inputType: "timeKeeper",
                    //    /*  options: "materialTypeList",
                    //     uniqueKey: "knowledgeMaterialTypeId",
                    //     onChange:"onSelectMaterialType",
                    //     displayKey: "knowledgeMaterialType", */
                    //     fieldName: "startTime",
                    //     defaultValue: "",
                    //     config: {
                    //         required: "required",
                    //     },
                    // },
                    // {

                    //     label: "End Time",
                    //     inputType: "timeKeeper",
                    //    /*  options: "enrollmentDetailsList",
                    //     uniqueKey: "enrollmentId",
                    //     displayKey: "classTitle",
                    //     onChange: "onSelectClass", */
                    //     subDisplayKey: "sectionTitle",
                    //     multiple: true,
                    //     fieldName: "endTime",
                    //     defaultValue: "",
                    //     config: {
                    //         required: "",
                    //     },

                    // },
                   
                    /* {
                        label: "Url",
                        inputType: "text",
                        disable: false,
                        fieldName: "url",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    },
                    {
                        label: "Name",
                        inputType: "text",
                        fieldName: "name",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    }, */
                ]
            },

        ]
    }
];

export {
    eventForm
};