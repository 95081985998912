import { returnApiCallPromise } from './ApiContent';
import { deleteJobHistory, leadSourceType,creditTerm ,documentType} from './APIContainer';

//DELETE
export function deleteEmployeeJobHistory(request) {
  const requestObj = request ? { params: request } : "";
  return (dispatch) => {
    returnApiCallPromise("DELETE", deleteJobHistory, requestObj)
      .then(response => {
        dispatch({
          type: 'DELETE_EMPLOYEE_JOB_HISTORY_SUCCESS',
          payload: response.data
        })
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'DELETE_EMPLOYEE_JOB_HISTORY_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

export function getLeadSourceType(request) {
  const requestObj = request ? { params: request } : "";
  return (dispatch) => {
    returnApiCallPromise("GET", leadSourceType, requestObj)
      .then(response => {        
        dispatch({
          type: 'GET_LEAD_SOURCE_TYPE_SUCCESS',
          payload: response.data
        })
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }        
        dispatch({
          type: 'GET_LEAD_SOURCE_TYPE_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

//Credit Term
export function getCreditTerm(request) {
  const requestObj = request ? { params: request } : "";
  return (dispatch) => {
    returnApiCallPromise("GET", creditTerm, requestObj)
      .then(response => {        
        dispatch({
          type: 'GET_CREDIT_TERM_SUCCESS',
          payload: response.data
        })
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }        
        dispatch({
          type: 'GET_CREDIT_TERM_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}


