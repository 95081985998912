
const timeTableFilterForm = [
    {
        title: "Basic Information",
        formBody : [
            {
                formField: [
                    {
                        label: "Batch Name",
                        inputType: "select",
                        options: "batchList",
                        uniqueKey:"batchId",
                        displayKey:"batchName",
                        multiple:false,
                        fieldName:"batchId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },                                            
                ]
            },
            {
                formField: [
                    {
                        label: "Class -Section",
                        inputType: "select",
                        options: "enrollmentDetailsList",
                        uniqueKey:"enrollmentId",
                        displayKey:"classTitle",
                        subDisplayKey:"sectionTitle",
                        multiple:false,
                        fieldName:"enrollmentId",
                        // fieldId:"enrollmentName",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },                                              
                ]
            }
        ]
    }
];

const filterForm = [
    {
        title: "Basic Information",
        formBody : [
            {
                formField: [
                    {
                        label: "Batch Name",
                        inputType: "select",
                        options: "batchList",
                        uniqueKey:"batchId",
                        onChange:"handleBatchName",
                        displayKey:"batchName",
                        multiple:false,
                        fieldName:"addBatchId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },                                            
                ]
            },/* 
            {
                formField: [
                    {
                        label: "Search",
                        inputType: "button",
                        fieldName:"find",
                        className: "mt-3",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },                                            
                ]
            }, */
        ]
    }
];

export {
    timeTableFilterForm,
    filterForm
};