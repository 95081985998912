import * as yup from "yup";
import { Formik } from "formik";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import DateTime from "react-datetime";
import { Form, Button } from "react-bootstrap";
import { DateRange } from "react-date-range";
import SimpleCard from 'app/components/atoms/simple-card/SimpleCard'
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { MdEdit, MdDelete } from "react-icons/md";
import swal from "sweetalert2";
import _ from "lodash";
import { pagePermission, showMessage } from "app/utils/app-functions";
import FormLayout from "app/components/render/formlayout";
import View from "app/components/atoms/modalView/Modalview";
import CustomTable from "app/components/atoms/custom-table/CustomTable";
import formRender from "app/components/render/formcomponent";
import { subjectForm } from "./formData";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import Alert from "app/components/atoms/alert";
import { createSubject, getSubject, updateSubject } from "app/api/SubjectApi";
import { useSidebar } from "app/components/sidebar/sidebarContext";

let editData = false;

const Subject = ({ navigation }) => {
    const dispatch = useDispatch();
    const customerFormRef = useRef();

    const getSubjectSuccess = useSelector((state) => state.subjectReducer.getSubjectSuccess);
    const getSubjectList = useSelector((state) => state.subjectReducer.getSubjectList);
    const getSubjectFailure = useSelector((state) => state.subjectReducer.getSubjectFailure);

    const createSubjectSuccess = useSelector((state) => state.subjectReducer.createSubjectSuccess);
    const createSubjectData = useSelector((state) => state.subjectReducer.createSubjectData);
    const createSubjectFailure = useSelector((state) => state.subjectReducer.createSubjectFailure);

    const updateSubjectSuccess = useSelector((state) => state.subjectReducer.updateSubjectSuccess);
    const updateSubjectData = useSelector((state) => state.subjectReducer.updateSubjectData);
    const updateSubjectFailure = useSelector((state) => state.subjectReducer.updateSubjectFailure);

    const subjectErrorMessage = useSelector((state) => state.subjectReducer.errorMessage);

    const [pageItems, setpageItems] = useState({});

    useEffect(() => {
        let data = pagePermission(pageItems, "Masters", "Subject")
        setpageItems(data)

    }, [navigation]);

    const column = [
        {
            "dataField": "sNo",
            "text": "S.No",
            "sort": true,
            formatter: (cellContent, row, index) => (
                index + 1
            )
        },
        {
            "dataField": "subjectTitle",
            "text": "Subject Name",
            "sort": true
        },
        {
            dataField: "isActive",
            text: "Status",
            sort: true,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => {
                const conInfo = { inputType: "status", status: cellContent }
                return formRender(null, null, conInfo, null);
            }
        },
        // {
        //     // dataField: "age",
        //     text: "Action",
        //     sort: false,
        //     align: "center",
        //     headerAlign: "center",
        //     formatter: (cellContent, row, index) => (
        //         <div >
        //             <MdEdit
        //                 className="text-success cursor-pointer"
        //                 size={18}
        //                 onClick={() => onEditForm(row, index)}
        //             ></MdEdit>
        //             <MdDelete
        //                 className="text-danger cursor-pointer"
        //                 size={18}
        //                 onClick={() => {
        //                     swal
        //                         .fire({
        //                             title: "Are you sure?",
        //                             text: "You won't be able to revert this!",
        //                             icon: "warning",
        //                             type: "question",
        //                             showCancelButton: true,
        //                             confirmButtonColor: "#3085d6",
        //                             cancelButtonColor: "#d33",
        //                             confirmButtonText: "Yes, delete it!",
        //                             cancelButtonText: "No",
        //                         })
        //                         .then((result) => {
        //                             if (result.value) {
        //                                 onDeleteItem(row, index);
        //                             } else {
        //                                 swal.fire({
        //                                     title: "Cancelled!",
        //                                     text: "Permission denied.",
        //                                     type: "error",
        //                                     icon: "error",
        //                                     timer: 1500,
        //                                 });
        //                             }
        //                         });
        //                 }}
        //             ></MdDelete>
        //             {/* <span onClick={() => onEditForm(row, index)} className="cursor-pointer text-success me-2">
        //                 <i className="nav-icon i-Pen-2 font-weight-bold"></i>
        //             </span>
        //             <span onClick={() => onDeleteItem(row, index)} className="cursor-pointer text-danger me-2">
        //                 <i className="nav-icon i-Close-Window font-weight-bold"></i>
        //             </span> */}
        //         </div>
        //     )
        //     // formatter: linkFollow()
        // },
    ]
    if (pageItems.delete || pageItems.update) {
        column.push({
            text: "Action",
            sort: false,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => (
                <div className="icon-container">
                    {pageItems.update && <FiEdit
                        className="text-primary cursor-pointer"
                        size={18}
                        onClick={() => onEditForm(row, index)}
                    ></FiEdit>}
                    {pageItems.delete && <FiTrash2
                        className="text-danger cursor-pointer"
                        size={18}
                        onClick={() => {
                            onDeleteItem(row, index);

                        }}
                    ></FiTrash2>}
                </div>
            )
        })
    }

    const [state, setState] = useState({
        subjectTitle: ""
    });

    const [createModule, setCreateModule] = useState(false);
    const [loaderModule, setLoaderModule] = useState(false);
    const [editModule, setEditModule] = useState(false);
    const [viewModule, setViewModule] = useState(false);
    const [deleteModule, setDeleteModule] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [selectedItem, setSelectedItem] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);

    useEffect(() => {
        setLoaderModule(true)
        const requestData = {
            isActive: "true"
        }
        dispatch(getSubject(requestData))
    }, [navigation]);

    const confromDialog = () => {
        return (
            swal
                .fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    type: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!",
                    cancelButtonText: "No",
                })
                .then((result) => {
                    if (result.value) {
                        onSubmitForm()
                    } else {
                        setDeleteModule(false)
                        swal.fire({
                            title: "Cancelled!",
                            text: "Permission denied.",
                            type: "error",
                            icon: "error",
                            timer: 1500,
                        });
                    }
                })
        )
    }

    useEffect(() => {
        if (deleteModule) {
            confromDialog()
        }
    }, [deleteModule])

    useEffect(() => {
        if (getSubjectSuccess) {
            setItemList(getSubjectList)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_SUBJECT" })
        } else if (getSubjectFailure) {
            setItemList([])
            setLoaderModule(false)
            showMessage("warning", subjectErrorMessage?.message)
            dispatch({ type: "RESET_GET_SUBJECT" })
        }
    }, [getSubjectSuccess, getSubjectFailure]);

    useEffect(() => {
        if (createSubjectSuccess) {
            const temp_state = [...itemList, createSubjectData[0]];
            setItemList(temp_state)
            setLoaderModule(false)
            Alert({ description: 'Created Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            closeModule()
            dispatch({ type: "RESET_CREATE_SUBJECT" })
        } else if (createSubjectFailure) {
            setLoaderModule(false)
            showMessage("warning", subjectErrorMessage?.message)
            dispatch({ type: "RESET_CREATE_SUBJECT" })
        }
    }, [createSubjectSuccess, createSubjectFailure]);

    useEffect(() => {
        if (updateSubjectSuccess) {
            updateTable(updateSubjectData[0])
            dispatch({ type: "RESET_UPDATE_SUBJECT" })
        } else if (updateSubjectFailure) {
            setLoaderModule(false)
            showMessage("warning", subjectErrorMessage?.message)
            dispatch({ type: "RESET_UPDATE_SUBJECT" })
        }
    }, [updateSubjectSuccess, updateSubjectFailure]);

    const updateTable = (updatedItem) => {
        const temp_state = [...itemList];
        if (deleteModule) {
            let remainingList = _.remove(temp_state, function (n) {
                return n.subjectId != updatedItem.subjectId;
            });
            Alert({ description: 'Deleted Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(remainingList)
        } else {
            temp_state[selectedIndex] = updatedItem;
            Alert({ description: 'Updated Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(temp_state)
        }
        setLoaderModule(false)
        closeModule()
    }

    const closeModule = () => {
        editData = false;
        setCreateModule(false)
        setDeleteModule(false)
    }

    const resetState = () => {
        setState({
            ...state,
            subjectTitle: ""
        })
    }

    const onCreateForm = () => {
        resetState()
        editData = false;
        setCreateModule(true)
    }

    const onEditForm = (data, index) => {
        setState({
            ...state,
            subjectTitle: data?.subjectTitle || ""
        })
        editData = true;
        setSelectedIndex(index)
        setSelectedItem(data)
        setCreateModule(true)
    }

    const handleCloseModal = () => {
        setCreateModule(false);
    };

    const onDeleteItem = (data, index) => {
        editData = false;
        setSelectedIndex(index)
        setSelectedItem(data)
        setDeleteModule(true)
    }

    const validateForm = async () => {
        const validatefom = customerFormRef.current.validateForm();
    }

    const onSubmitForm = () => {
        const requestDate = {
            subjectTitle: subjectTitle
        }
        if (editData) {
            setLoaderModule(true)
            dispatch(updateSubject(requestDate, selectedItem.subjectId))
        } else if (deleteModule) {
            const deleteRequest = {
                isActive: false
            }
            setLoaderModule(true)
            dispatch(updateSubject(deleteRequest, selectedItem.subjectId))
        } else {
            setLoaderModule(true)
            dispatch(createSubject(requestDate))
        }
    }

    const {
        subjectTitle
    } = state;

    const modelTitle = editData ? "Edit " : "Create ";
    const modelButtonLbl = editData ? "" : "Save";

    const handleClick = (buttonName) => {
        if (buttonName == 'Add') {
            onCreateForm();
        }
    };
    const { sidebarOpen } = useSidebar();

    return (
        <div>
        <div style={sidebarOpen ? { marginLeft: "10px", marginTop: "-10px" } : { marginLeft: "10px", marginTop: "-50px" }}>
                <View isOpen={createModule} size="sm" savetitle={modelButtonLbl} onClose={handleCloseModal} onSubmit={validateForm} title={`${modelTitle} Subject`}>
                    <FormLayout ref={customerFormRef} defaultState={state} setDefaultState={setState} dynamicForm={subjectForm} noOfColumn={1} onSubmit={onSubmitForm} />
                </View>


                <View centered={true} size={'sm'} isOpen={deleteModule} onClose={setDeleteModule} savetitle="Yes" onSubmit={onSubmitForm} title="Delete Subject">
                    <h6 className="text-capitalize mb-1">Are you sure ?</h6>
                </View>

                <SimpleCard >
                    <CustomTable buttonData={pageItems.create ? ['add'] : []} handleClick={handleClick} tableName={"Subject"} columns={column} data={itemList} initialPage={1} />
                </SimpleCard>

            </div>
        </div>
    )
};

export default Subject;
