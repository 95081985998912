import { returnApiCallPromise } from './ApiContent';
import { measurement } from './APIContainer';

//GET
export function getMeasurement(request) {
  const requestObj = request ? { params: request } : "";
  return (
    returnApiCallPromise("GET", measurement, requestObj)
    .then(response => {
      const result = {
        getMeasurementSuccess: true,
        getMeasurementList: response.data.data
      }
      return result;
    }).catch(error => {
      let errorMessage = error;
      if (error.response) {
        if (error.response.data.error) {
          errorMessage = error.response.data.message;
        }
      }
      const result = {
        getMeasurementFailed: true,
        errorMessage: errorMessage
      }
      return result;
    })
  )
  
}

/* //POST
export function createUser(request) {

  return (dispatch) => {
    returnApiCallPromise("POST", user, request)
      .then(response => {
        if (response.data.error) {
          dispatch({
            type: 'CREATE_USER_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'CREATE_USER_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'CREATE_USER_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

export function updateUser(request, userId) {
  return (dispatch) => {
    returnApiCallPromise("PUT", user+"/"+userId , request)
      .then(response => {

        if (response.data.error) {
          dispatch({
            type: 'UPDATE_USER_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'UPDATE_USER_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'UPDATE_USER_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
} */