import * as yup from "yup";
import { Formik } from "formik";
import { useState, useEffect, useRef, Fragment } from "react";
import { useDispatch, useSelector } from 'react-redux';
import DateTime from "react-datetime";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { inventoryTransferForm, createInventoryTransferForm, fieldList } from "./formData";
import { MdEdit, MdDelete, MdVisibility } from "react-icons/md";
import swal from "sweetalert2";
import _ from "lodash";
import { dateConversion, decimalAmount, pagePermission, showMessage } from "app/utils/app-functions";
import FormLayout from "app/components/render/formlayout";
import View from "app/components/atoms/modalView/Modalview";
import CustomTable from "app/components/atoms/custom-table/CustomTable";
import formRender from "app/components/render/formcomponent";
import { createInventoryTransfer, getInventoryMaterial, getInventoryTransfer, updateInventoryTransfer } from "app/api/InventoryApi";
// import SearchButton from "app/components/SearchButton";
import SimpleCard from 'app/components/atoms/simple-card/SimpleCard'
import { FiEdit, FiSend, FiTrash2 } from 'react-icons/fi';
import { Table } from "react-bootstrap";
import CollapseView from "app/components/atoms/CollapseView/CollapseView";
import { getMeasurement } from "app/api/MeasurementApi";
import { getWarehouse } from "app/api/WarehouseApi";
import { getLocation } from "app/api/LocationApi";
import { getMaterialCode } from "app/api/MaterialCodeApi";
import { getAvailableMaterial, getMaterialInfo, getMaterialType } from "app/api/ProductApi";
import moment from "moment";
import Alert from "app/components/atoms/alert";

let editData = false;
let object = [];

const InventoryTransfer = ({ navigation }) => {

    const dispatch = useDispatch();
    const customerFormRef = useRef();
    const materialFormRef = useRef();

    const getMaterialTypeSuccess = useSelector((state) => state.productReducer.getMaterialTypeSuccess);
    const getMaterialTypeList = useSelector((state) => state.productReducer.getMaterialTypeList);
    const getMaterialTypeFailure = useSelector((state) => state.productReducer.getMaterialTypeFailure);

    const getMaterialInfoSuccess = useSelector((state) => state.productReducer.getMaterialInfoSuccess);
    const getMaterialInfoList = useSelector((state) => state.productReducer.getMaterialInfoList);
    const getMaterialInfoFailure = useSelector((state) => state.productReducer.getMaterialInfoFailure);


    const getMeasurementSuccess = useSelector((state) => state.measurementReducer.getMeasurementSuccess);
    const getMeasurementList = useSelector((state) => state.measurementReducer.getMeasurementList);
    const getMeasurementFailure = useSelector((state) => state.measurementReducer.getMeasurementFailure);
    const measurementErrorMessage = useSelector((state) => state.measurementReducer.errorMessage);

    const getLocationSuccess = useSelector((state) => state.locationReducer.getLocationSuccess);
    const getLocationList = useSelector((state) => state.locationReducer.getLocationList);
    const getLocationFailure = useSelector((state) => state.locationReducer.getLocationFailure);

    const getWarehouseSuccess = useSelector((state) => state.warehouseReducer.getWarehouseSuccess);
    const getWarehouseList = useSelector((state) => state.warehouseReducer.getWarehouseList);
    const getWarehouseFailure = useSelector((state) => state.warehouseReducer.getWarehouseFailure);


    const getMaterialCodeSuccess = useSelector((state) => state.materialCodeReducer.getMaterialCodeSuccess);
    const getMaterialCodeList = useSelector((state) => state.materialCodeReducer.getMaterialCodeList);
    const getMaterialCodeFailure = useSelector((state) => state.materialCodeReducer.getMaterialCodeFailure);
    const materialCodeErrorMessage = useSelector((state) => state.materialCodeReducer.errorMessage);

    const getAvailableMaterialSuccess = useSelector((state) => state.productReducer.getAvailableMaterialSuccess);
    const getAvailableMaterialList = useSelector((state) => state.productReducer.getAvailableMaterialList);
    const getAvailableMaterialFailure = useSelector((state) => state.productReducer.getAvailableMaterialFailure);

    const getInventoryTransferSuccess = useSelector((state) => state.inventoryMaterialReducer.getInventoryTransferSuccess);
    const getInventoryTransferList = useSelector((state) => state.inventoryMaterialReducer.getInventoryTransferList);
    const getInventoryTransferFailure = useSelector((state) => state.inventoryMaterialReducer.getInventoryTransferFailure);

    const createInventoryTransferSuccess = useSelector((state) => state.inventoryMaterialReducer.createInventoryTransferSuccess);
    const createInventoryTransferData = useSelector((state) => state.inventoryMaterialReducer.createInventoryTransferData);
    const createInventoryTransferFailure = useSelector((state) => state.inventoryMaterialReducer.createInventoryTransferFailure);

    const updateInventoryTransferSuccess = useSelector((state) => state.inventoryMaterialReducer.updateInventoryTransferSuccess);
    const updateInventoryTransferData = useSelector((state) => state.inventoryMaterialReducer.updateInventoryTransferData);
    const updateInventoryTransferFailure = useSelector((state) => state.inventoryMaterialReducer.updateInventoryTransferFailure);

    const inventoryErrorMessage = useSelector((state) => state.inventoryMaterialReducer.errorMessage);
    const materialErrorMessage = useSelector((state) => state.productReducer.errorMessage);
    const warehouseErrorMessage = useSelector((state) => state.warehouseReducer.errorMessage);
    const locationErrorMessage = useSelector((state) => state.locationReducer.errorMessage);

    const [pageItems, setpageItems] = useState({});

    useEffect(() => {
        let data = pagePermission(pageItems, "Inventory", "Inventory Transfer" ,"Inventory")
        setpageItems(data)


    }, [navigation]);

    const column = [
        {
            "dataField": "transferNumber",
            "text": "Transfer Number",
            "sort": true
        },
        {
            "dataField": "fromWarehouseName",
            "text": "From Warehouse",
            "sort": true,
        },
        {
            "dataField": "toWarehouseName",
            "text": "To Warehouse",
            "sort": true
        },
        {
            "dataField": "memo",
            "text": "Memo",
            "sort": true
        },
        {
            "dataField": "dateOfTransfer",
            "text": "Date of Transfer",
            "sort": true,
            formatter: (cellContent, row, index) => {
                return dateConversion(cellContent, "DD-MMM-YYYY")
            }
        },
    ]
    if (pageItems.update) {
        column.push({
            text: "Action",
            sort: false,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => (
                <div className="icon-container">
                    {pageItems.update && <FiEdit
                        className="text-info cursor-pointer"
                        size={18}
                        onClick={() => onEditForm(row, index)}
                    ></FiEdit>}
                </div>
            )
        },)
    }

    const header = [
        {
            "dataField": "transferNumber",
            "text": "Material Code",
            "sort": true
        },
        {
            "dataField": "transferNumber",
            "text": "Material Name",
            "sort": true
        },
        {
            "dataField": "transferNumber",
            "text": "Quantity",
            "sort": true
        },
        {
            "dataField": "transferNumber",
            "text": "UOM",
            "sort": true
        },
        {
            "dataField": "transferNumber",
            "text": "Dimension",
            "sort": true
        },
        {
            "dataField": "transferNumber",
            "text": "Type",
            "sort": true
        },
        {
            "dataField": "transferNumber",
            "text": "Reason",
            "sort": true
        },
        {
            "dataField": "transferNumber",
            "text": "Remark",
            "sort": true
        },
        {
            "dataField": "transferNumber",
            "text": "Add Item Details",
            "sort": true,
            "wrap": 'text-nowrap'
        },
        {
            "dataField": "transferNumber",
            "text": "Action",
            "sort": true,
            "align": 'center'
        },
    ]

    const itemDetailsHeader = [
        {
            "dataField": "imageName",
            "text": "Image",
            "sort": true,
            formatter: (cellContent, row, index, extraData) => {
                return <img
                    className="avatar-lg active"
                    src={`/assets/images/no-image.png`}
                    alt=""
                    style={{ objectFit: "contain", height: "20px", width: "20px" }}

                />
            }
        },
        {
            "dataField": "serialNumber",
            "text": "Serial Number",
            "sort": true
        },
        {
            "dataField": "batchNumber",
            "text": "Batch Number",
            "sort": true
        },
        {
            "dataField": "locationName",
            "text": "Receipt Location",
            "sort": true
        },
        {
            "dataField": "warehouseName",
            "text": "Warehouse",
            "sort": true
        },
        {
            text: "Action",
            sort: false,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => (
                <div >
                    <MdDelete
                        className="text-danger cursor-pointer"
                        size={18}
                        onClick={() => {
                            onExchangeItem(row, index);
                        }}
                    ></MdDelete>
                </div>
            )
        },
    ]

    const itemHeader = [
        {
            "dataField": "status",
            "text": "",
            formatter: (cellContent, row, index, extraData) => {
                const conInfo = { inputType: "checkbox", status: cellContent }
                return formRender(extraData, null, conInfo, null, null, statusChange, index);
            }
        },
        {
            "dataField": "imageName",
            "text": "Image",
            "sort": true,
            formatter: (cellContent, row, index, extraData) => {
                return <img
                    className="avatar-lg active"
                    src={`/assets/images/no-image.png`}
                    alt=""
                    style={{ objectFit: "contain", height: "20px", width: "20px" }}

                />
            }
        },
        {
            "dataField": "serialNumber",
            "text": "Serial Number",
            "sort": true
        },
        {
            "dataField": "batchNumber",
            "text": "Batch Number",
            "sort": true
        },
        {
            "dataField": "locationName",
            "text": "Receipt Location",
            "sort": true
        },
        {
            "dataField": "warehouseName",
            "text": "Warehouse",
            "sort": true
        },

    ]

    const [state, setState] = useState({
        typeList: [{ id: 1, typeName: 'IN' }, { id: 2, typeName: 'OUT' }],
        checkCount: 0,
    });

    const [createModule, setCreateModule] = useState(false);
    const [tableRows, setTableRows] = useState([]);
    const [loaderModule, setLoaderModule] = useState(false);
    const [deleteModule, setDeleteModule] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [selectedItem, setSelectedItem] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);
    const [selectMaterial, setSelectMaterial] = useState([])
    const [showForm, setShowForm] = useState(false);

    useEffect(() => {
        setLoaderModule(true)
        // dispatch(getMaterialType())
        // dispatch(getInventoryMaterial())
        dispatch(getInventoryTransfer())
        dispatch(getMaterialInfo())
        /*    dispatch(getMeasurement())
           dispatch(getWarehouse())
           dispatch(getLocation()) */
        let aa = listFetching()

        const req = {
            pageId: 40,
            noOfColumns: 6
        }
        // dispatch(getFormfield(req))
    }, [navigation]);

    useEffect(() => {
        if (getMaterialTypeSuccess) {
            setState({
                ...state,
                materialTypeList: getMaterialTypeList
            })
            dispatch({ type: "RESET_GET_MATERIAL_TYPE" })
        }
        else if (getMaterialTypeFailure) {
            setState({
                ...state,
                materialTypeList: []
            })
            showMessage("warning", materialErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_MATERIAL_TYPE" })
        }
    }, [getMaterialTypeFailure, getMaterialTypeSuccess]);


    useEffect(() => {
        if (getMaterialCodeSuccess) {
            setState({
                ...state,
                materialCodeList: getMaterialCodeList
            })
            // setSelectMaterial(getMaterialCodeList)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_MATERIALCODE" })
        } else if (getMaterialCodeFailure) {
            setState({
                ...state,
                materialCodeList: []
            })
            showMessage("warning", materialCodeErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_MATERIALCODE" })
        }
    }, [getMaterialCodeSuccess, getMaterialCodeFailure]);


    useEffect(() => {
        if (getMaterialInfoSuccess) {
            object = getMaterialInfoList
            setState({
                ...state,
                materialInfoList: getMaterialInfoList
            })
            dispatch({ type: "RESET_GET_MATERIAL_INFO_FAILURE" })
        }
        else if (getMaterialInfoFailure) {
            setState({
                ...state,
                materialInfoList: []
            })
            showMessage("warning", materialErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_MATERIAL_INFO_FAILURE" })
        }
    }, [getMaterialInfoSuccess, getMaterialInfoFailure]);

    useEffect(() => {
        if (getMeasurementSuccess) {
            setState({
                ...state,
                unitsList: getMeasurementList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_MEASUREMENT" })
        } else if (getMeasurementFailure) {
            setState({
                ...state,
                unitsList: []
            })
            showMessage("warning", measurementErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_MEASUREMENT" })
        }
    }, [getMeasurementSuccess, getMeasurementFailure]);
    useEffect(() => {
        if (getLocationSuccess) {
            setState({
                ...state,
                locationList: getLocationList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_LOCATION" })
        } else if (getLocationFailure) {
            setState({
                ...state,
                locationList: []
            })
            showMessage("warning", locationErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_LOCATION" })
        }
    }, [getLocationSuccess, getLocationFailure]);

    useEffect(() => {
        if (getWarehouseSuccess) {
            setState({
                ...state,
                warehouseList: getWarehouseList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_WAREHOUSE" })
        } else if (getWarehouseFailure) {
            setState({
                ...state,
                warehouseList: []
            })
            showMessage("warning", warehouseErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_WAREHOUSE" })
        }
    }, [getWarehouseSuccess, getWarehouseFailure]);

    useEffect(() => {
        if (getAvailableMaterialSuccess) {
            const parentIndex = selectedIndex;
            const parentRows = [...tableRows];
            const itemInfo = parentRows[parentIndex]?.itemDetails || [];

            const filter_data = _.filter(getAvailableMaterialList, (item, index) =>
                _.findIndex(itemInfo, (n) => n.itemDetailsId === item.itemDetailsId) === -1
            )
            setSelectMaterial(filter_data)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_AVAILABEL_MATERIAL" })
        } else if (getAvailableMaterialFailure) {
            setSelectMaterial([])
            showMessage("warning", materialErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_AVAILABEL_MATERIAL" })
        }
    }, [getAvailableMaterialSuccess, getAvailableMaterialFailure]);

    useEffect(() => {
        if (getInventoryTransferSuccess) {
            setItemList(getInventoryTransferList)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_INVENTORY_TRANSFER" })
        }
        else if (getInventoryTransferFailure) {
            setItemList([])
            showMessage("warning", inventoryErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_INVENTORY_TRANSFER" })
        }
    }, [getInventoryTransferFailure, getInventoryTransferSuccess]);

    useEffect(() => {
        if (createInventoryTransferSuccess) {
            const temp_state = [...itemList, createInventoryTransferData[0]];
            setItemList(temp_state)
            setLoaderModule(false)
            closeModule()
            Alert({ description: 'Created Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            dispatch({ type: "RESET_CREATE_INVENTORY_TRANSFER" })
        } else if (createInventoryTransferFailure) {
            showMessage("error", inventoryErrorMessage)
            dispatch({ type: "RESET_CREATE_INVENTORY_TRANSFER" })
        }
    }, [createInventoryTransferSuccess, createInventoryTransferFailure]);

    useEffect(() => {
        if (updateInventoryTransferSuccess) {
            updateTable(updateInventoryTransferData[0])
            dispatch({ type: "RESET_UPDATE_INVENTORY_TRANSFER" })
        } else if (updateInventoryTransferFailure) {
            setLoaderModule(false)
            showMessage("warning", inventoryErrorMessage?.message)
            dispatch({ type: "RESET_UPDATE_INVENTORY_TRANSFER" })
        }
    }, [updateInventoryTransferSuccess, updateInventoryTransferFailure]);

    const updateTable = (updatedItem) => {
        const temp_state = [...itemList];
        if (deleteModule) {
            let remainingList = _.remove(temp_state, function (n) {
                return n.inventoryTransferId != updatedItem.inventoryTransferId;
            });
            Alert({ description: 'Deleted Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(remainingList)
        } else {
            temp_state[selectedIndex] = updatedItem;
            Alert({ description: 'Updated Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(temp_state)
        }
        setLoaderModule(false)
        closeModule()
    }

    const closeModule = () => {
        editData = false;
        setCreateModule(false)
        setDeleteModule(false)
    }

    const resetState = () => {
        setState({
            ...state,
            "fromWarehouse": '',
            "fromLocation": '',
            "toWarehouse": '',
            "toLocation": '',
            "transferDate": moment(new Date(), "YYYY-MM-DD"),
            "memo": '',
        })
        setTableRows([])
    }

    const onCreateForm = () => {
        resetState()
        editData = false;
        setCreateModule(true)
    }

    const onEditForm = (data, index) => {
        /* let loc = state?.locationList || []
        let ware = state?.warehouseList || []
        let fromWare = ware.filter(item => data.fromWarehouse === item.id)
        let toWare = ware.filter(item => data.toWarehouse === item.id)
        let fromLoc = loc.filter(item => data.fromLocation === item.id)
        let toLoc = loc.filter(item => data.toLocation === item.id) */
        let temp_arr = state?.materialInfoList || object
        const item_list = (data?.inventoryTransferItem || []).map((o) => {
            const selMaterial = _.filter(temp_arr, { materialId: o.materialId });
            // console.log(selMaterial);

            let materialCode = selMaterial.length > 0 ? selMaterial[0] : [];
            return { ...o, materialCode: materialCode.materialCode }
        })
        // console.log(JSON.stringify(state.materialInfoList));

        setState({
            ...state,
            fromWarehouse: data.fromWarehouse,
            fromLocation: data.fromLocation,
            toWarehouse: data.toWarehouse || "",
            toLocation: data.toLocation || "",
            transferDate: moment(data?.dateOfTransfer, "YYYY-MM-DD"),
            memo: data?.memo || "",
        })
        editData = true;

        setSelectedIndex(index)
        setSelectedItem(data)
        setTableRows(item_list)
        setCreateModule(true)
    }

    const validateForm = async () => {
        const validatefom = customerFormRef.current.validateForm();
    }

    const validateMaterialForm = async () => {
        const validatefom = materialFormRef.current.validateForm();
    }

    const onSubmitForm = () => {
        let data = [...tableRows];
        let transferItem = []
        console.log(JSON.stringify(tableRows));

        let ids = []
        let filterData = data.map(item => {
            let newItem = { ...item }
            item.itemDetails.map(detail => ids.push(detail.itemDetailsId));
            delete newItem.materialCode
            newItem.itemDetailsIds = ids
            delete newItem.itemDetails
            transferItem.push(newItem)
        })
        const requestData = {
            "fromWarehouse": state?.fromWarehouse || '',
            "fromLocation": state?.fromLocation || '',
            "toWarehouse": state?.toWarehouse || '',
            "toLocation": state?.toLocation || '',
            "dateOfTransfer": state?.transferDate || '',
            "memo": state?.memo || '',
            "transferStatus": "open",
            "inventoryTransferItem": transferItem
        };
        // return
        function isValid(value) {
            return value !== null && value !== undefined && value !== '' && value !== 0 &&
                !(typeof value === 'string' && value.trim() === '') &&
                !(typeof value === 'number' && isNaN(value));
        }

        function validateFormData(inventoryAdjustmentItem) {
            if (!Array.isArray(inventoryAdjustmentItem) || inventoryAdjustmentItem.length === 0) {
                return false;
            }

            for (const item of inventoryAdjustmentItem) {
                const keysToValidate = ["materialName", "qty", "unit", "remark", "type", "reason", "itemDetailsIds"];
                for (const key of keysToValidate) {
                    if (!isValid(item[key])) {
                        return false;
                    }
                }

            }

            return true;
        }
        // console.log(JSON.stringify(requestData.inventoryTransferItem));

        if (!validateFormData(requestData.inventoryTransferItem)) {
            Alert({ description: 'Fill all the add item details', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            return false
        }
        if (editData) {
            setLoaderModule(true)
            dispatch(updateInventoryTransfer(requestData, selectedItem.inventoryTransferId))
        } else if (deleteModule) {
            const deleteRequest = {
                isActive: "false"
            }
            setLoaderModule(true)
            // dispatch(updateHolidays(deleteRequest, selectedItem.holidayId))
        } else {
            setLoaderModule(true)
            dispatch(createInventoryTransfer(requestData))
        }
    }

    const listFetching = async () => {
        let warehouseList = await getWarehouse({
            isActive: "true",
        })
        let locationList = await getLocation()

        // let materialTypeList = await getMaterialType()
        let measurementList = await getMeasurement()

        setState({
            ...state,
            unitsList: measurementList.getMeasurementList,
            locationList: locationList.getLocationList,
            warehouseList: warehouseList.getWarehouseList,
            //   materialTypeList: materialTypeList.getMaterialTypeList ,
        })

    }

    const handleSubmit = () => {
        const req = {
            materialCode: state?.materialCode || '',
            materialName: state?.materialName || '',
            materialTypeId: (state?.materialType && state.materialType.length > 0) ? state.materialType[0].materialId : ''
        }
        dispatch(getInventoryMaterial(req))
    }

    const fetchData = (value, index, name) => {

        let apiResponse = state?.materialCodeList || [];


        if (value.length < 3) {
            let tempstate = [...tableRows];
            tempstate[index].filteredData = [];
            tempstate[index].materialCodeId = value;
            tempstate[index].materialName = '';
            tempstate[index].materialId = '';
            setTableRows(tempstate);
            return;
        }

        let filterObject = (apiResponse ? apiResponse : []).find(item => item.materialCode.toLowerCase() === value.toLowerCase());
        const filteredResults = (apiResponse ? apiResponse : []).filter(material =>
            material.materialCode.toLowerCase().includes(value.toLowerCase())
        );


        let tempstate = [...tableRows];

        tempstate[index].filteredData = filteredResults;
        tempstate[index].materialCodeId = value;
        tempstate[index].materialName = filterObject ? filterObject.materialName : '';
        tempstate[index].materialId = filterObject ? filterObject.materialId : '';
        tempstate[index].materialTypeId = filterObject ? filterObject.materialTypeId : '';

        setTableRows(tempstate);

        if (value.length >= filterObject?.materialCode.length) {
            let tempstate = [...tableRows];
            tempstate[index].filteredData = [];
            setTableRows(tempstate);
        }

    };

    const handleMaterialChange = async (event, name, index) => {
        let value = event.target.value;
        const req = {
            materialCode: value
        };
        const response = await getMaterialCode(req);

        if (response.getMaterialCodeSuccess) {
            setState({
                ...state,
                materialCodeList: response.getMaterialCodeList.data
            });
        } else if (response.getMaterialCodeFailure) {
            setState({
                ...state,
                materialCodeList: []
            });
            showMessage("warning", response.materialCodeErrorMessage?.message);
        }

        if (value == "") {
            let tempstate = [...tableRows];
            tempstate[index].filteredData = [];
            setTableRows(tempstate);
        } else {
            fetchData(value, index, name);
        }
    };

    const expandView = (index, setRows) => {
        const data = tableRows[index]?.itemDetails || [];
        return (
            <div style={{ marginTop: "-25px" }}>
                {/* <h3 style={{ fontSize: '20px', fontWeight: '500',marginBottom:"-30px" }} className="label-color">Item Details</h3> */}
                <CustomTable data={data} tableName="Item Details" columns={itemDetailsHeader} ></CustomTable>
            </div>
        );
    };

    var itemCount = 0;
    const addRow = () => {
        const defaultNewOrderObject = {
            materialCode: '',
            materialType: '',
            materialName: '',
            qty: "",
            type: "",
            unit: '',
            reason: "",
            remark: "",
            dimension: "",
            /* itemDetailsSize: [{
                length: '',
                width: '',
                thickness: '',
            }], */
            itemDetails: [
            ]
        };
        itemCount++;
        setTableRows([...tableRows, defaultNewOrderObject]);

    };

    const onAddItem = (index) => {
        setTableRows(tableRows.map(({ materialType, ...row }) => row));
        const material_Id = tableRows[index].materialId
        const qty = parseFloat(tableRows[index]?.qty || 0)
        setSelectedIndex(index);
        let count = parseInt(tableRows[index]?.itemDetails?.length || 0);
        setState({ ...state, checkCount: count })
        if (qty != 0) {
            setLoaderModule(true)
            setSelectMaterial([])
            dispatch(getAvailableMaterial(null, material_Id))
            setShowForm(true);
        }
        else {
            Alert({ description: 'Please enter the material quantity', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });

            // showMessage("warning", 'please enter the material quantity')
        }
    }

    const removeItem = (itemIndex) => {
        swal
            .fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
                cancelButtonText: "No",
            })
            .then((result) => {
                if (result.value) {
                    let temp_state = [...tableRows];
                    let addd = tableRows.splice(itemIndex, 1);
                    // let remainingList = _.(temp_state, index)  
                    // let temp_element = { ...temp_state[index] };
                    let remainingList = _.remove(temp_state, function (n, index) {
                        return index != itemIndex
                    });
                    // let updatedState = amountCalculation(remainingList)
                    setTableRows(remainingList);
                    swal.fire("Deleted!", "The item has been deleted.", "success");
                } else {
                    swal.fire({
                        title: "Cancelled!",
                        text: "Permission denied.",
                        icon: "error",
                        timer: 1500,
                        showConfirmButton: false,
                    });
                }
            });
    }

    const onExchangeItem = (data, index) => {
        let idx = _.findIndex(tableRows, (e) => {
            return e.orderItemId == data.orderItemId;
        });
        const iddx = selectedIndex ? selectedIndex : idx
        const temp_state = [...tableRows];
        temp_state[iddx].itemDetails.splice(index, 1);
        setTableRows(temp_state);
    }

    const confirmForm = () => {
        const temp_state = [...selectMaterial];
        const parentIndex = selectedIndex;
        let filteredData = _.filter(temp_state, function (item) {
            return item.status === true;
        });
        if (tableRows[parentIndex].itemDetails?.length) {
            tableRows[parentIndex].itemDetails = [...tableRows[parentIndex].itemDetails, ...filteredData];
        }
        else
            tableRows[parentIndex].itemDetails = filteredData;
        setTableRows(tableRows);
        setShowForm(false)
    }

    const statusChange = (event, index, extraData) => {
        let items = [...selectMaterial]
        let count = parseInt(state.checkCount);
        const materialQty = parseFloat(tableRows[selectedIndex]?.qty || 0);

        if (count < materialQty) {
            items[index].status = event.target.checked;
            const addingCount = event.target.checked ? 1 : -1;
            count = count + addingCount;
        } else if (count === materialQty && !event.target.checked) {
            items[index].status = event.target.checked;
            const addingCount = -1;

            count = count + addingCount;
        } else {
            Alert({ /* title:"Not Allowed", */ description: 'Not allowed to select more than the material quantity.', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });

            // showMessage("warning", "Not allowed to select more than material quantity", "Not Allowed")
        }

        setState({ ...state, checkCount: count });
        setSelectMaterial(items)
    }

    const onCloseForm = () => {
        setShowForm(false);
    }

    const modelTitle = editData ? "Edit " : "Create ";
    const modelButtonLbl = editData ? "" : "Save";


    const handleClick = (buttonName) => {
        if (buttonName == 'Add') {
            onCreateForm();
        }
    };

    return (
        <div>
            <div style={{ marginTop: "-10px" }}>

                <View isOpen={createModule} onClose={setCreateModule} size="fullscreen" savetitle={modelButtonLbl} onSubmit={validateMaterialForm} title={`${modelTitle} Inventory Transfer`}>
                    <FormLayout ref={materialFormRef} defaultState={state} setDefaultState={setState} dynamicForm={createInventoryTransferForm} noOfColumn={4} onSubmit={onSubmitForm} />
                    <CollapseView iconPress={{ "onAddItem": onAddItem, "onDeletePress": removeItem }} onChangeCallback={{ "handleMaterialChange": handleMaterialChange }} state={state} setState={setState} header={header} fieldList={fieldList} expandView={expandView} btnName={"Add Item"} tableRows={tableRows} setTableRows={setTableRows} addRow={addRow}></CollapseView>
                </View>
                <View isOpen={showForm} onClose={setShowForm} size="xl" savetitle={"Confirm"} title={`Select Product`} onSubmit={confirmForm} btnName={"Confirm"} count={true} checkCount={state?.checkCount || 0} quatityCount={selectedIndex === false ? 0 : tableRows[selectedIndex]?.qty || 0} /* onClose={onCloseForm} */>
                    <CustomTable columns={itemHeader} data={selectMaterial} tableName={""} state={state} />
                </View>

                {/*  <ModalView centered={true} size={'sm'} isOpen={deleteModule} setshow={setDeleteModule} savetitle="Yes" onSubmit={onSubmitForm} title="Delete Holidays">
                <h6 className="text-capitalize mb-1">Are you sure ?</h6>
            </ModalView> */}

                <SimpleCard >
                    {/* <FormLayout ref={customerFormRef} defaultState={state} setDefaultState={setState} dynamicForm={inventoryTransferForm || []} noOfColumn={6} onSubmit={onSubmitForm} onSearch={() => handleSubmit()} /> */}
                    <CustomTable buttonData={pageItems.create ? ['add'] : []} handleClick={handleClick} columns={column} data={itemList} tableName={"Inventory Transfer"} state={state} />
                </SimpleCard>
            </div>
        </div>
    )
};

export default InventoryTransfer;
