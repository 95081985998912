import './styles.css';
import PropTypes from 'prop-types';
import { notification } from 'antd';

const AlertProps = {
    type: {
        success: 'success',
        error: 'error',
        info: 'info',
        warning: 'warning',
    },
    placement: {
        topLeft: 'topLeft',
        topRight: 'topRight',
        bottomLeft: 'bottomLeft',
        bottomRight: 'bottomRight',
    },
};

export { AlertProps };

const Alert = ({ title, description, type, placement, duration, onClose }) => {
    notification[type]({
        message: title,
        description,
        placement,
        duration,
        onClose,
    });
};

Alert.propTypes = {
    title: PropTypes.any.isRequired,
    description: PropTypes.string.isRequired,
    type: PropTypes.oneOf([
        AlertProps.type.success,
        AlertProps.type.error,
        AlertProps.type.info,
        AlertProps.type.warning,
    ]).isRequired,
    placement: PropTypes.oneOf([
        AlertProps.placement.bottomLeft,
        AlertProps.placement.bottomRight,
        AlertProps.placement.topLeft,
        AlertProps.placement.topRight,
    ]),
    duration: PropTypes.number,
    onClose: PropTypes.func,
};

Alert.defaultProps = {
    duration: 3,
    placement: AlertProps.placement.topRight,
};

export default Alert;
