
const studentFeesPaymentForm = [
  {
      title: "Basic Information",
      formBody : [
          {
              formField: [
                  {
                      label: "Batch Name",
                      inputType: "select",
                      options: "batchList",
                      uniqueKey:"batchId",
                      displayKey:"batchName",
                      disabled:true,
                      multiple:false,
                      fieldName:"batchId",
                      defaultValue: "",
                      config: {
                          required: "required",
                      },
                  },
              ]
          },
          {
              formField: [
                  {
                      label: "Class -Section",
                      inputType: "select",
                      options: "enrollmentDetailsList",
                      uniqueKey:"enrollmentId",
                      displayKey:"classTitle",
                      disabled:true,
                      subDisplayKey:"sectionTitle",
                      // multiple:true,
                      fieldName:"enrollmentId",
                      defaultValue: "",
                      config: {
                          required: "required",
                      },
                  },                                              
              ]
          },
          {
              formField: [
                  {
                      label: "Student",
                      inputType: "typedropdown",
                      options: "studentEnrollmentDetailsList",
                      uniqueKey:"studentEnrollmentId",
                      displayKey:"studentName",
                      disabled:true,
                      fieldName:"studentEnrollmentId",
                      defaultValue: "",
                      config: {
                          required: "required",
                      },
                  }                                             
              ]
          },
      ]
  }
];

const studentFeesPaymentFilterForm = [
  {
      title: "Basic Information",
      formBody : [
          {
              formField: [
                  {
                      label: "Batch Name",
                      inputType: "select",
                      options: "batchList",
                      uniqueKey:"batchId",
                      displayKey:"batchName",
                      multiple:false,
                      fieldName:"addBatchId",
                      defaultValue: "",
                      config: {
                          required: "required",
                      },
                  },
              ]
          },
          {
              formField: [
                  {
                      label: "Class -Section",
                      inputType: "select",
                      options: "enrollmentDetailsList",
                      uniqueKey:"enrollmentId",
                      displayKey:"classTitle",
                      subDisplayKey:"sectionTitle",
                      // multiple:true,
                      fieldName:"addEnrollmentId",
                      defaultValue: "",
                      config: {
                          required: "required",
                      },
                  },                                              
              ]
          },
          
      ]
  }
];

const feesPaymentForm = [
  {
      title: "Basic Information",
      formBody : [
          {
              formField: [
                  {
                      label: "Fees Type",
                      inputType: "select",
                      options: "feesEnrollmentList",
                      uniqueKey:"feesTypeId",
                      displayKey:"feesTypeName",
                      fieldName:"feesTypeId",
                      defaultValue: "",
                      config: {
                          required: "required",
                      },
                  },
                  {
                      label: "Amount",
                      inputType: "text",
                      fieldName:"paidAmount",
                      defaultValue: "",
                      config: {
                          required: "required",
                      },
                  },
                  {
                      label: "Fully Paid",
                      inputType: "checkbox",
                      fieldName:"fullyPaid",
                      defaultValue: "",
                      config: {
                          required: "",
                      },
                  },
              ]
          },
      ]
  }
];

export {
  studentFeesPaymentForm,
  studentFeesPaymentFilterForm,
  feesPaymentForm
};