
const visitorForm = [
    {
        title: "Basic Information",
        formBody: [
            {
                formField: [
                    {
                        label: "Upload Profile Picture",
                        inputType: "imageUploader",
                        onChange: "onWriteDocument",
                        fieldName: "profilePic",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    },
                   
                    {
                        label: "Address",
                        inputType: "textarea",
                        placeholder: "Enter Address",
                        fieldName: "address",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                   
                  
                    {
                        label: "Proof",
                        inputType: "file",
                        fileFormat: "application/pdf",
                        onChange: "onWriteDocument",
                        fieldName: "proofDocument",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    },
                    {
                        label: "Proof Type",
                        inputType: "select",
                        options: "proofTypeList",
                        uniqueKey: "proofTypeId",
                        displayKey: "proofTypeName",
                        fieldName: "proofTypeId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    

                ]
            },
            {
                formField: [
                    /* {
                        label: "Phone No",
                        inputType: "phonenumber",
                        // type: "number",
                        placeholder: "Enter Phone No",
                        fieldName: "phoneNo",
                        errorMsg:"* Invalid Phone Number",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }, */
                    {
                        label: "Visitor First Name",
                        inputType: "text",
                        placeholder: "Enter First Name",
                        fieldName: "firstName",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Visitor Last Name",
                        inputType: "text",
                        placeholder: "Enter Last Name",
                        fieldName: "lastName",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                   
                    {
                        label: "Proof Number",
                        inputType: "text",
                        placeholder: "Enter Proof Number",
                        fieldName: "proofNumber",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Meeting Date",
                        inputType: "date",
                        fieldName: "meetingDate",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Gender",
                        inputType: "select",
                        options: "genderList",
                        uniqueKey: "genderId",
                        displayKey: "genderName",
                        fieldName: "genderId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Whom To Meet",
                        inputType: "searchableInput",
                        placeholder: "Search",
                        uniqueKey: "employeeId",
                        onChange: "getVisitorData",
                        displayKey: "employeeCode",
                        multiple: false,
                        fieldName: "visitorName",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    },
                   
                   

                  
                  

                ]
            },
            {
                formField: [
                    {
                        label: "Phone Number",
                        inputType: "phonenumber",
                        placeholder: "Enter Phone Number",
                        fieldName: "phoneNumber",
                        errorMsg: "* Invalid Phone Number",
                        // fieldType: "email",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Email",
                        inputType: "text",
                        placeholder: "Enter Email",
                        fieldName: "email",
                        fieldType: "email",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Postal Code",
                        inputType: "text",
                        type: "number",
                        placeholder: "Enter postal Code",
                        fieldName: "postalCode",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Purpose",
                        inputType: "text",
                        placeholder: "Enter Reason To Meet",
                        fieldName: "reasonToMeet",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Type of Visitor",
                        inputType: "select",
                        options: "visitorTypeList",
                        uniqueKey: "visitorTypeId",
                        displayKey: "visitorTypeName",
                        fieldName: "visitorTypeId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Time",
                        inputType: "time",
                        type:"time",
                        fieldName: "toTime",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                   
                   
                  
                   

                ]
            },
            {
                formField: [
                    /*  {
                         label: "Type of Visitor",
                         inputType: "select",
                         options: "visitorList",
                         uniqueKey: "visitorId",
                         displayKey: "departmentName",
                         fieldName: "visitorId",
                         defaultValue: "",
                         config: {
                             required: "required",
                         },
                     },  */

                    
               
                    
                   
                   
                    
                    /* {
                        label: "",
                        inputType: "text",
                        type:"time",
                        fieldName:"toTime",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }    */

                ]
            },
        ]
    }
];

export {
    visitorForm
};