import { returnApiCallPromise } from './ApiContent';
import { inventoryMaterial,inventoryTransfer } from './APIContainer';

//GET
export function getInventoryMaterial(request) {
  const requestObj = request ? { params: request } : "";
  return (dispatch) => {
    returnApiCallPromise("GET", inventoryMaterial, requestObj)
      .then(response => {
        dispatch({
          type: 'GET_INVENTORY_MATERIAL_SUCCESS',
          payload: response.data
        })
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'GET_INVENTORY_MATERIAL_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

//GET INVENTORY
export function getInventoryTransfer(request) {
  const requestObj = request ? { params: request } : "";
  return (dispatch) => {
    returnApiCallPromise("GET", inventoryTransfer, requestObj)
      .then(response => {
        dispatch({
          type: 'GET_INVENTORY_TRANSFER_SUCCESS',
          payload: response.data
        })
      }).catch(error => {
        
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'GET_INVENTORY_TRANSFER_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}


//POST
export function createInventoryTransfer(request) {
  return (dispatch) => {
    returnApiCallPromise("POST", inventoryTransfer , request)
      .then(response => {
        if (response.data.error) {
          dispatch({
            type: 'CREATE_INVENTORY_TRANSFER_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'CREATE_INVENTORY_TRANSFER_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'CREATE_INVENTORY_TRANSFER_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

//PUT
export function updateInventoryTransfer(request, inventoryTransferId) {
  return (dispatch) => {
    returnApiCallPromise("PUT",inventoryTransfer+"/"+inventoryTransferId, request)
      .then(response => {

        if (response.data.error) {
          dispatch({
            type: 'UPDATE_INVENTORY_TRANSFER_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'UPDATE_INVENTORY_TRANSFER_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'UPDATE_INVENTORY_TRANSFER_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}