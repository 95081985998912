import React from 'react';
import { eventForm } from './formData';
import View from 'app/components/atoms/modalView/Modalview';
import CustomTable from 'app/components/atoms/custom-table/CustomTable';
import FormLayout from 'app/components/render/formlayout';
import SimpleCard from 'app/components/atoms/simple-card/SimpleCard';
import { FiEdit, FiTrash2 } from "react-icons/fi";
import { Button } from 'react-bootstrap';
import RichTextEditor from 'app/components/atoms/rich-text-editor/RichTextEditor';
import ReactQuill from "react-quill";
import Alert from 'app/components/atoms/alert';
import _ from "lodash";
import swal from "sweetalert2";
import { useState, useEffect, useRef } from "react";
import { uploadFiles } from "app/api/DocumentApi";
import { useDispatch, useSelector } from 'react-redux';
import formRender from 'app/components/render/formcomponent';
import { imagePath } from "app/utils/constant";
import { MdRemoveRedEye } from 'react-icons/md';
import { dateConversion } from 'app/utils/app-functions';
import { getEvent, createEvent, updateEvent } from "app/api/EventApi";
import { event } from "app/utils/constant";
import { pagePermission, showMessage } from "app/utils/app-functions";
import { timeConversion } from 'app/utils/app-functions';
import moment from 'moment';
import dayjs from 'dayjs';
import EventView from './EventView';
import { useSidebar } from "app/components/sidebar/sidebarContext";

let editData = false
const EventManagement = ({ navigation }) => {

    const dispatch = useDispatch();
    const customerFormRef = useRef();

    const getEventSuccess = useSelector((state) => state.eventReducer.getEventSuccess);
    const getEventList = useSelector((state) => state.eventReducer.getEventList);
    const getEventFailure = useSelector((state) => state.eventReducer.getEventFailure);

    const createEventSuccess = useSelector((state) => state.eventReducer.createEventSuccess);
    const createEventData = useSelector((state) => state.eventReducer.createEventData);
    const createEventFailure = useSelector((state) => state.eventReducer.createEventFailure);

    const updateEventSuccess = useSelector((state) => state.eventReducer.updateEventSuccess);
    const updateEventData = useSelector((state) => state.eventReducer.updateEventData);
    const updateEventFailure = useSelector((state) => state.eventReducer.updateEventFailure);

    const eventErrorMessage = useSelector((state) => state.eventReducer.errorMessage);

    const [pageItems, setpageItems] = useState({
    });

    useEffect(() => {
        let data = pagePermission(pageItems, "Administrator", "Event Management")
        setpageItems(data)

    }, [navigation]);

    const column = [
        {
            "dataField": "title",
            "text": "Event Name",
            "sort": true
        },

        {
            "dataField": "startTime",
            "text": "Start Time",
            "sort": true
        },
        {
            "dataField": "endTime",
            "text": "End Time",
            "sort": true
        },

    ]

    if (pageItems.view || pageItems.update) {
        column.push({
            text: "Action",
            sort: false,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index, extraData) => (
                <div className="icon-container">
                    {pageItems.view && <MdRemoveRedEye
                        className="text-success cursor-pointer"
                        size={18}
                        onClick={() => { onViewForm(row, index) }}
                    ></MdRemoveRedEye>}
                    {pageItems.update && <FiEdit
                        className="text-primary cursor-pointer"
                        size={18}
                        onClick={() => onEditForm(row, index, extraData)}
                    ></FiEdit>}

                </div>
            )
        },)
    }

    const [state, setState] = useState({});
    const [createModule, setCreateModule] = useState(false);
    const [loaderModule, setLoaderModule] = useState(false);
    const [deleteModule, setDeleteModule] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [selectedItem, setSelectedItem] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);
    const [viewModule, setViewModule] = useState(false);
    const [content, setContent] = useState("");



    useEffect(() => {
        setLoaderModule(true)
        const requestData = {
            isActive: "true",
            unitId: 1
        }
        dispatch(getEvent(requestData))
    }, [navigation]);

    useEffect(() => {
        if (getEventSuccess) {
            setItemList(getEventList)
            setState({
                ...state,
                eventList: getEventList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_EVENT" })
        } else if (getEventFailure) {
            setItemList([])
            showMessage("warning", eventErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_EVENT" })
        }
    }, [getEventSuccess, getEventFailure]);

    useEffect(() => {
        if (createEventSuccess) {
            const temp_state = [...itemList, createEventData[0]];
            const requestData = {
                isActive: "true",
            }
            dispatch(getEvent(requestData))
            setItemList(temp_state)
            setLoaderModule(false)
            closeModule()
            Alert({  description: 'Created Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            dispatch({ type: "RESET_CREATE_EVENT" })
        } else if (createEventFailure) {
            setLoaderModule(false)
            showMessage("warning", eventErrorMessage?.message)
            dispatch({ type: "RESET_CREATE_EVENT" })
        }
    }, [createEventSuccess, createEventFailure]);


    useEffect(() => {
        if (updateEventSuccess) {
            updateTable(updateEventData[0])
            dispatch({ type: "RESET_UPDATE_EVENT" })
        } else if (updateEventFailure) {
            setLoaderModule(false)
            showMessage("warning", eventErrorMessage)
            dispatch({ type: "RESET_UPDATE_EVENT" })
        }
    }, [updateEventSuccess, updateEventFailure]);

    const updateTable = (updatedItem) => {
        const temp_state = [...itemList];
        if (deleteModule) {
            let remainingList = _.remove(temp_state, function (n) {
                return n.holidayId != updatedItem.holidayId;
            });
            Alert({  description: 'Deleted Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(remainingList)
        } else {
            temp_state[selectedIndex] = updatedItem;
            Alert({  description: 'Updated Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(temp_state)
        }
        setLoaderModule(false)
        closeModule()
    }

    const closeModule = () => {
        editData = false;
        setCreateModule(false)
        setDeleteModule(false)
    }

    const resetState = () => {
        setContent([])
        setState({
            ...state,
            eventName: "",
            description: "",
            imageName: "",
            startTime: "",
            endTime: "",
            eventDate: ''

        })
    }

    const onViewForm = (data, index) => {
        const id = data.eventId
        setViewModule(true)
        editData = false;
        setState({
            ...state,
            eventIdValue: id
        })
        const requestData = {
            isActive: "true",
        }
        dispatch(getEvent(requestData))
    }

    const onWriteDocument = (event) => {
        let file = event.target.files[0];
        let fileName = file.name;
        const now = new Date();
        const year = now.getFullYear().toString();
        const fileExtension = fileName?.split('.').pop() || "";
        const uniqueIdentifier = Math.floor(1000 + Math.random() * 9000).toString();
        const day = now.getDate().toString().padStart(2, '0');
        const docName = `${year}${day}${uniqueIdentifier}.${fileExtension}`;
        const renamedFile = new File(
            [file],
            docName,
            {
                type: file?.type,
                name: docName,
                lastModified: file?.lastModified,
            }
        );
        formDataAppend(renamedFile)

        setState({
            ...state,
            [event.target.name]: docName
        })
    };

    const handleContentChange = (content) => {
        // const strippedContent = content.replace(/<[^>]*>/g, '').replace(/&nbsp;/g, ' ').replace(/\\/g, '').replace(/'/g, '');
        setContent(content)
        setState({
            ...state,
            description: content
        });
    };

    const formDataAppend = (data) => {
        const formData = new FormData();
        formData.append('document', data);
        dispatch(uploadFiles(formData, `${event}`));
    }


    const onCreateForm = () => {
        resetState()
        editData = false;
        setCreateModule(true)
    }

    const onEditForm = (data, index) => {
        setState({
            ...state,
            eventName: data?.title || "",
            imageName: data?.image || "",
            startTime: data?.startTime,
            description: data?.description,
            endTime: data?.endTime,
            eventDate: moment(data.eventDate, 'YYYY-MM-DD'),
        })
        editData = true;
        setContent(data?.description)
        setSelectedIndex(index)
        setSelectedItem(data)
        setCreateModule(true)
    }

    const onDeleteItem = (data, index) => {
        editData = false;
        setSelectedIndex(index)
        setSelectedItem(data)
        setDeleteModule(true)
    }

    const validateForm = async () => {
        const validatefom = customerFormRef.current.validateForm();
    }

    const onSubmitForm = () => {
        const currentDate = new Date();
        const currentDateString = currentDate.toISOString().split('T')[0];
        const startTimeString = `${currentDateString}T${state.startTime}:00`;
        const endTimeString = `${currentDateString}T${state.endTime}:00`;

        const requestDate = {
            title: state?.eventName || "",
            description: state?.description || "",
            image: state?.imageName || "",
            fromTime: (state.startTime),
            toTime: (state.endTime),
            eventDate: dateConversion(state?.eventDate || "", "YYYY-MM-DD")
        }

        console.log(JSON.stringify(requestDate));

        // return
        /* function validateFormData(requestData) {
            for (let key in requestData) {
                if (requestData.hasOwnProperty(key) && key !== "middleName" && key !== "emisNumber") {
                    if (
                        requestData[key] === null ||
                        requestData[key] === undefined ||
                        requestData[key] === "" ||
                        requestData[key] === "0" ||
                        requestData[key] === 0 ||
                        (typeof requestData[key] === "string" && requestData[key].trim() === "") ||
                        (typeof requestData[key] === "number" && isNaN(requestData[key]))
                    ) {
                        return false;
                    }
                }
            }
            return true;
        } */

        if (editData) {
            setLoaderModule(true)
            dispatch(updateEvent(requestDate, selectedItem.eventId))
        } else if (deleteModule) {
            const deleteRequest = {
                isActive: "false"
            }
            setLoaderModule(true)
            // dispatch(updateevent(deleteRequest, selectedItem.holidayId))
        } else {
            setLoaderModule(true)
            dispatch(createEvent(requestDate))

        }
    }

    <h1 style={{ fontStyle: "initial" }}></h1>
    const modelTitle = editData ? "Edit " : "Create ";
    const modelButtonLbl = editData ? "" : "Save";

    const handleClick = (buttonName) => {
        if (buttonName == 'Add') {
            onCreateForm();
        }
    };
    const { sidebarOpen } = useSidebar();

    return (
        <div>
            <div style={sidebarOpen ? { marginLeft: "10px", marginTop: "-10px" } : { marginLeft: "10px", marginTop: "-50px"}}>

                <View isOpen={createModule} size="lg" savetitle={modelButtonLbl} onClose={setCreateModule} onSubmit={validateForm} title={`${modelTitle} event`}>
                    <FormLayout ref={customerFormRef} defaultState={state} setDefaultState={setState} dynamicForm={eventForm} noOfColumn={3} onSubmit={onSubmitForm} onChangeCallback={{ "onWriteDocument": onWriteDocument }} />
                    <RichTextEditor
                        toolbarClassName="demo-toolbar"
                        editorClassName="demo-editor"
                        content={content}
                        placeholder="Enter text here..."
                        handleContentChange={handleContentChange}
                        onSubmit={validateForm}
                        value={content}
                    />
                </View>
                <View centered={true} size={'lg'} isOpen={viewModule} onClose={setViewModule} savetitle="Close"  /* onSubmit={validateForm} */ title="Event Details">
                    <EventView data={state?.eventList || []} id={state.eventIdValue}></EventView>
                </View>
                <SimpleCard >
                    <CustomTable columns={column} data={itemList} buttonData={pageItems.create ? ['add'] : []} handleClick={handleClick} tableName={"Event Management"}state={state} />
                </SimpleCard>

            </div>
        </div>
    )
}

export default EventManagement;