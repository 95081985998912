
const roleForm = [
    {
        title: "Basic Information",
        formBody : [
            {
                formField: [
                    {
                        label: "Role Name",
                        inputType: "text",
                        placeholder: "Enter Role name",
                        fieldName:"roleName",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    /* {
                        label: "Select Permission",
                        inputType: "button",
                        // placeholder: "Enter Role name",
                        // fieldName:"roleName",
                        onClick:"permission",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    } */
                                                                     
                ]
            }
        ]
    }
];

export {
    roleForm
};