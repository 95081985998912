import { returnApiCallPromise } from './ApiContent';
import { permission, role ,userPermission } from './APIContainer';

//GET
export function getPermission(request) {
  const requestObj = request ? { params: request } : "";
  return (dispatch) => {
    global.userId = 0
    const headers = { role_id: 0 }; 
    returnApiCallPromise("GET", permission, requestObj, { headers })
    
      .then(response => {
        dispatch({
          type: 'GET_PERMISSION_SUCCESS',
          payload: response.data
        })
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'GET_PERMISSION_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

export function getUserPermission(request) {
  const requestObj = request ? { params: request } : "";
  return (dispatch) => {
    returnApiCallPromise("GET", userPermission, requestObj)
    
      .then(response => {
        dispatch({
          type: 'GET_USER_PERMISSION_SUCCESS',
          payload: response.data
        })
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'GET_USER_PERMISSION_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

//POST
export function createPermission(request) {

  return (dispatch) => {
    returnApiCallPromise("POST", permission, request)
      .then(response => {
        if (response.data.error) {
          dispatch({
            type: 'CREATE_PERMISSION_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'CREATE_PERMISSION_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'CREATE_PERMISSION_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

export function updatePermission(request, permissionId) {
  return (dispatch) => {
    console.log(request);
    returnApiCallPromise("PUT", permission +"/" + permissionId, request)
      .then(response => {

        if (response.data.error) {
          dispatch({
            type: 'UPDATE_PERMISSION_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'UPDATE_PERMISSION_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'UPDATE_PERMISSION_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

//GET ROLES
export function getRoles(request) {
  const requestObj = request ? { params: request } : "";
  return (dispatch) => {
    returnApiCallPromise("GET", role, requestObj)
      .then(response => {
        dispatch({
          type: 'GET_ROLES_SUCCESS',
          payload: response.data
        })
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'GET_ROLES_SUCCESS',
          errorMessage: errorMessage
        })
      })
  }
}