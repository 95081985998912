
const markSheetForm = [
    {
        title: "Basic Information",
        formBody : [
            {
                formField: [
                    {
                        label: "Batch Name",
                        inputType: "select",
                        options: "batchList",
                        uniqueKey:"batchId",
                        displayKey:"batchName",
                        fieldName:"batchId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }                                                                                     
                ]
            },
            {
                formField: [
                    {
                        label: "Class -Section",
                        inputType: "select",
                        options: "enrollmentDetailsList",
                        uniqueKey:"enrollmentId",
                        displayKey:"classTitle",
                        subDisplayKey:"sectionTitle",
                        // multiple:true,
                        fieldName:"enrollmentId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }                                                                                    
                ]
            },
            {
                formField: [
                    {
                        label: "Exam Type",
                        inputType: "select",
                        options: "examType",
                        uniqueKey:"examTypeId",
                        displayKey:"examTypeName",
                        multiple:false,
                        fieldName:"examTypeId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }                                                                                 
                ]
            },
            {
                formField: [
                    {
                        label: "Exam Name",
                        inputType: "select",
                        options: "examListList",
                        uniqueKey:"examId",
                        displayKey:"examName",
                        multiple:false,
                        fieldName:"examId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }                                                                                     
                ]
            },
        ]
    }
];

const extraMarkForm = [
    {
        title: "Basic Information",
        formBody : [
            {
                formField: [
                    {
                        label: "Internal Mark",
                        inputType: "text",
                        type :'number' ,
                        fieldName:"internalMark",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    }                                                                                     
                ]
            },
            {
                formField: [
                    {
                        label: "Practical Mark",
                        inputType: "text",
                        type :'number' ,
                        fieldName:"practicalMark",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    }                                                                                   
                ]
            }
        ]
    }
];
export {
    markSheetForm ,extraMarkForm
};