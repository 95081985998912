import {
    AiOutlineApartment, AiOutlineHome, AiOutlineSetting, AiOutlineFileText,
    AiOutlineBell, AiOutlineQuestionCircle, AiOutlineComment, AiOutlineBook, AiOutlineUser
  } from "react-icons/ai";
  import { FaUsersBetweenLines,FaUserTag ,FaMoneyBillTrendUp } from "react-icons/fa6";
  import { BsCash, BsPeople, BsClipboardCheck } from 'react-icons/bs';
  import { FaQuestionCircle,FaPercentage ,FaCalendarTimes , FaBook, FaBookOpen, FaCalendarAlt, FaCheckCircle, FaListAlt, FaRegCalendarCheck } from 'react-icons/fa';
  import { HiOutlineDocumentReport } from 'react-icons/hi';
  import { MdInventory, MdVisibility, MdLogout,MdOutlineSettingsSystemDaydream , MdOutlineAnalytics, MdAdminPanelSettings, MdDataset, MdSecurity, MdLockClock, MdOutlineLockClock, MdOutlineSyncLock, MdSupervisorAccount, MdOutlineSwitchAccount, MdPermIdentity, MdInfo, MdCallReceived, MdShoppingCart, MdHotelClass, MdOutlineWarehouse, MdLocationOn, MdOutlineTypeSpecimen, MdChecklist, MdAttachMoney } from 'react-icons/md';
  import { IoSettings } from "react-icons/io5";
  import { RiAdminLine } from "react-icons/ri";
  import { PiStudentFill ,PiInfoDuotone ,PiClockClockwiseBold ,PiClockCounterClockwiseBold  } from "react-icons/pi";
  import { HomeOutlined, ReadOutlined, FileTextOutlined, BookOutlined, AppstoreAddOutlined, CalendarOutlined, TeamOutlined, CloudUploadOutlined, FileDoneOutlined, TableOutlined, UserOutlined, ScheduleOutlined, ContainerOutlined, ShopOutlined, TagOutlined, StockOutlined, TransactionOutlined, FileAddOutlined, FileSearchOutlined, ShoppingCartOutlined, CreditCardOutlined, UnorderedListOutlined, BoxPlotOutlined, ToolOutlined } from '@ant-design/icons';
import { BookCheck, Clock } from "lucide-react";
import { GiTeacher } from "react-icons/gi";
import { DashboardOutlined } from "@mui/icons-material";
  
  export const iconMap = {
      RiAdminLine: <RiAdminLine />,
      MdInfo  : <MdInfo  />,
      MdCallReceived  : <MdCallReceived  />,
      MdShoppingCart: <MdShoppingCart />,
      MdHotelClass: <MdHotelClass />,
      MdOutlineWarehouse: <MdOutlineWarehouse />,
      MdAttachMoney: <MdAttachMoney />,
      PiInfoDuotone: <PiInfoDuotone />,
      MdChecklist: <MdChecklist />,
      MdLocationOn: <MdLocationOn />,
      MdOutlineTypeSpecimen: <MdOutlineTypeSpecimen />,
      BookOutlined: <BookOutlined />,
      MdOutlineSettingsSystemDaydream : <MdOutlineSettingsSystemDaydream  />,
      MdOutlineLockClock : <MdOutlineLockClock  />,
      MdOutlineSyncLock : <MdOutlineSyncLock   />,
      MdPermIdentity  : <MdPermIdentity    />,
      FaCalendarTimes : <FaCalendarTimes  />,
      FaMoneyBillTrendUp : <FaMoneyBillTrendUp  />,
      FaUserTag:<FaUserTag />,
      FaPercentage :<FaPercentage  />,
      MdSupervisorAccount  :<MdSupervisorAccount   />,
      MdOutlineSwitchAccount   :<MdOutlineSwitchAccount     />,
      /* IoGeek: <IoGeek />,
      IoMoney: <IoMoney />, */
      IoGeek: <MdDataset />,
      MdSecurity: <MdSecurity  />,
      MdDataset: <MdDataset />,
      BookCheck: <BookCheck />,
      Clock: <MdLockClock />,
      AppstoreAddOutlined: <AppstoreAddOutlined />,
      CalendarOutlined: <CalendarOutlined />,
      TeamOutlined: <TeamOutlined />,
      ReadOutlined: <ReadOutlined />,
      HomeOutlined: <HomeOutlined />,
      FileTextOutlined: <FileTextOutlined />,
      FaBookOpen: <FaBookOpen />,
      PiStudentFill: <PiStudentFill />,
      PiClockClockwiseBold : <PiClockClockwiseBold  />,
      PiClockCounterClockwiseBold  : <PiClockCounterClockwiseBold   />,
      DashboardOutlined: <DashboardOutlined />,
      FileDoneOutlined: <FileDoneOutlined />,
      TableOutlined: <TableOutlined />,
      ScheduleOutlined: <ScheduleOutlined />,
      UserOutlined: <UserOutlined />,
      CalendarOutlined: <CalendarOutlined />,
      IoSettings: <IoSettings />,
      MdVisibility: <MdVisibility />,
      MdInventory: <MdInventory />,
      AiOutlineHome: <AiOutlineHome />,
      AiOutlineFileText: <AiOutlineFileText />,
      BsCash: <BsCash />,
      FaCheckCircle: <FaCheckCircle />,
      MdAdminPanelSettings: <MdAdminPanelSettings />,
      MdOutlineAnalytics: <MdOutlineAnalytics />,
      FaListAlt: <FaListAlt />,
      AiOutlineUser: <AiOutlineUser />,
      FaQuestionCircle: <FaQuestionCircle />,
      FaBook: <FaBook />,
      GiTeacher: <GiTeacher />,
      FaCalendarAlt: <FaCalendarAlt />,
      HiOutlineDocumentReport: <HiOutlineDocumentReport />,
      AiOutlineUser: <AiOutlineUser />,
      FaRegCalendarCheck: <FaRegCalendarCheck />,
      AiOutlineFileText: <AiOutlineFileText />,
      CloudUploadOutlined: <CloudUploadOutlined />,
      BsClipboardCheck: <BsClipboardCheck />
    };