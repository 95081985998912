import React from 'react';
import {Radio as AntdRadio} from 'antd';
import './styles.css';

const classNames = require('classnames');

const Radio = ({value, test, children,color,disabled}) => {   
    const className = classNames({
        'ax-radio': true, 
        [test]: test,
        [color]: color,
        [`value-${value}`]: value,
    });


    return (
        <AntdRadio className={className} value={value} id={value} key={value} disabled={disabled}>
            {children}
        </AntdRadio>
    );
};


export default Radio;
