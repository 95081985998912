import React from 'react';
import { decimalAmount } from '../../../utils/app-functions';

const Calculation = ({ currencySymbol, calculationTitle = [] }) => {
  return (
    <div className='calc-data' style={{ position: 'relative', float: 'right' }}>
      <table className="table">
        <tr>
          <th colSpan="3" style={{ borderTop: '1px solid #000', padding: '10px 0' }}></th>
        </tr>
        <tbody>
          {calculationTitle.map(({ label, value }) => (
            <tr key={label}>
              <td style={{ textAlign: 'left', paddingRight: '75px' }}><h6>{label}</h6></td>
              <td style={{
                textAlign: 'center',
                padding: '5px 10px',
                width: '5%',
                position: 'relative',
                top: '-5px',
              }}>:</td>
              <td><h6><b style={{ paddingRight: '10px' }}>{currencySymbol ? currencySymbol : '$'}</b>{decimalAmount(value)}</h6></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
// <td><h6><b style={{ paddingRight: '10px' }}>{currencySymbol}</b>{value}</h6></td>

export default Calculation;
