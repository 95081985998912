import { returnApiCallPromise } from './ApiContent';
import { knowledge } from './APIContainer';

//GET
export function getKnowledgeMaterial(request) {
  const requestObj = request ? { params: request } : "";
  return (dispatch) => {
    returnApiCallPromise("GET", knowledge, requestObj)
      .then(response => {
        dispatch({
          type: 'GET_KNOWLEDGE_MATERIAL_SUCCESS',
          payload: response.data
        })
      }).catch(error => {
        
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'GET_KNOWLEDGE_MATERIAL_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

//POST
export function createKnowledge(request) {

  return (dispatch) => {
    returnApiCallPromise("POST", knowledge, request)
      .then(response => {
        if (response.data.error) {
          dispatch({
            type: 'CREATE_KNOWLEDGE_MATERIAL_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'CREATE_KNOWLEDGE_MATERIAL_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'CREATE_KNOWLEDGE_MATERIAL_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

export function updateKnowledge(request, knowledgeId) {
  return (dispatch) => {
    returnApiCallPromise("PUT", knowledge+"/"+knowledgeId , request)
      .then(response => {
        if (response.data.error) {
          dispatch({
            type: 'UPDATE_KNOWLEDGE_MATERIAL_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'UPDATE_KNOWLEDGE_MATERIAL_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'UPDATE_KNOWLEDGE_MATERIAL_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}