import { returnApiCallPromise } from './ApiContent';
import { holidays } from './APIContainer';
//GET
export function getHolidays(request) {
  const requestObj = request ? { params: request } : "";
  return (dispatch) => {
    returnApiCallPromise("GET",holidays, requestObj)
      .then(response => {
        dispatch({
          type: 'GET_HOLIDAYS_SUCCESS',
          payload: response.data
        })
      }).catch(error => {

        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'GET_HOLIDAYS_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}
export function asyncgetHolidays(request) {
  const requestObj = request ? { params: request } : "";
  return (
    returnApiCallPromise("GET",holidays, requestObj)
    .then(response => {
      console.log(response)
      return {
        error: false,
        message:"Succsss",
        data: response?.data?.data || []
      }
    }).catch(error => {
      let errorMessage = error;
      if (error.response) {
        if (error.response.data.error) {
          errorMessage = error.response.data.message;
        }
      }
      return {
        error: true,
        data:[],
        message:errorMessage
      }
    })
  )
}

//POST
export function createHolidays(request) {

  return (dispatch) => {
    returnApiCallPromise("POST", holidays, request)
      .then(response => {
        if (response.data.error) {
          dispatch({
            type: 'CREATE_HOLIDAYS_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'CREATE_HOLIDAYS_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'CREATE_HOLIDAYS_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

export function updateHolidays(request, holidaysId) {
  return (dispatch) => {
    returnApiCallPromise("PUT", holidays+"/"+holidaysId, request)
      .then(response => {

        if (response.data.error) {
          dispatch({
            type: 'UPDATE_HOLIDAYS_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'UPDATE_HOLIDAYS_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'UPDATE_HOLIDAYS_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}