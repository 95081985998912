import { returnApiCallPromise } from './ApiContent';
import { studentEnrollment,studentEnrollmentDetails } from './APIContainer';

//GET
export function getStudentEnrollment(request) {
  const requestObj = request ? { params: request } : "";
  return (dispatch) => {
    returnApiCallPromise("GET", studentEnrollment, requestObj)
      .then(response => {
        dispatch({
          type: 'GET_STUDENT_ENROLLMENT_SUCCESS',
          payload: response.data
        })
      }).catch(error => {
        
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'GET_STUDENT_ENROLLMENT_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

export function asyncGetStudentEnrollmentInfo(request) {
  const requestObj = request ? { params: request } : "";
  return  returnApiCallPromise("GET", studentEnrollment, requestObj)
      .then(response => {
        return {
          error: false,
          message:"Succsss",
          data: response?.data?.data || []
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        return {
          error: true,
          data:[],
          message:errorMessage
        }
      })
}

export function getStudentEnrollmentDetails(request) {
    const requestObj = request ? { params: request } : "";
    return (dispatch) => {
      returnApiCallPromise("GET", studentEnrollmentDetails, requestObj)
        .then(response => {
          dispatch({
            type: 'GET_STUDENT_ENROLLMENT_DETAILS_SUCCESS',
            payload: response.data
          })
        }).catch(error => {
          
          let errorMessage = error;
          if (error.response) {
            if (error.response.data.error) {
              errorMessage = error.response.data.message;
            }
          }
          dispatch({
            type: 'GET_STUDENT_ENROLLMENT_FAILURE',
            errorMessage: errorMessage
          })
        })
    }
  }

//POST
export function createStudentEnrollment(request) {

  return (dispatch) => {
    returnApiCallPromise("POST", studentEnrollment, request)
      .then(response => {
        if (response.data.error) {
          dispatch({
            type: 'CREATE_STUDENT_ENROLLMENT_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'CREATE_STUDENT_ENROLLMENT_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'CREATE_STUDENT_ENROLLMENT_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

export function updateStudentEnrollment(request, studentEnrollmentId) {
  return (dispatch) => {
    returnApiCallPromise("PUT", studentEnrollment+"/"+studentEnrollmentId, request)
      .then(response => {

        if (response.data.error) {
          dispatch({
            type: 'UPDATE_STUDENT_ENROLLMENT_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'UPDATE_STUDENT_ENROLLMENT_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'UPDATE_STUDENT_ENROLLMENT_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}