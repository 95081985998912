import React from 'react';
import { PDFViewer, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { lightTheme } from 'styles/theme';
import { amountFormat } from 'app/utils/app-functions';
import { decimalAmount } from 'app/utils/app-functions';

const PdfPreview = ({ data, title, rows, itemFields, subTitle, ItemKey, totalSection }) => {

    const styles = StyleSheet.create({
        page: { padding: 20 },
        header: {
            fontSize: 18, marginBottom: 20, padding: 15,fontWeight: 'bold', textAlign: 'center', color: lightTheme.secondaryColor,
            backgroundColor: lightTheme.primaryColor
        },
        section: { marginBottom: 10, marginLeft: 20 },
        row: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 5,
        },
        label: { fontSize: 12, color: 'black', width: '40%' },
        comma: { fontSize: 12, color: 'grey', textAlign: 'center', width: '10%' },
        value: { fontSize: 10, width: '50%' },
        total: { fontSize: 14, fontWeight: 'bold', textAlign: 'right', marginTop: 10 },
        orderItemsBox: {
            border: '1px solid grey',
            borderRadius: 5,
            padding: 10,
            marginTop: 10,
        },
        tableHeader: {
            flexDirection: 'row',
            borderBottom: '1px solid grey',
            paddingBottom: 3,
            marginBottom: 5,
            textAlign: 'center',
            backgroundColor: lightTheme.primaryColor,
            color: lightTheme.secondaryColor
        },
        tableRow: {
            flexDirection: 'row',
            // justifyContent: 'space-between',
            paddingBottom: 5,
            marginBottom: 5,
            borderBottom: '1px solid grey',
        },
        headerText: { fontSize: 11, width: '20%', textAlign: 'center' },
        cellText: { fontSize: 9, width: '20%', textAlign: 'center' },

    });

    const Row = ({ label, value }) => (
        <View style={styles.row}>
            <Text style={styles.label}>{label}</Text>
            <Text style={styles.comma}>:</Text>
            <Text style={styles.value}>{value}</Text>
        </View>
    );

    return (
        <PDFViewer width="100%" height="600px">
            <Document>
                <Page style={styles.page}>
                    <Text style={styles.header}>{title}</Text>

                    <View style={styles.section}>
                        {rows.map((row, index) => (
                            <Row
                                key={index}
                                label={row.label}
                                value={
                                    row.isDate
                                        ? new Date(data[row.value]).toLocaleDateString()
                                        : data[row.value]
                                }
                            />
                        ))}
                    </View>

                    <View style={styles.section}>
                        <Text style={styles.text}>{subTitle}</Text>
                        <View style={styles.orderItemsBox}>

                            <View style={styles.tableHeader}>
                                {itemFields.map((field, index) => (
                                    <Text key={index} style={styles.headerText}>
                                        {field.label}
                                    </Text>
                                ))}
                            </View>
                            {data[`${ItemKey}`].map((item, index) => (
                                <View key={index} style={styles.tableRow}>
                                    {itemFields.map((field, fieldIndex) => {
                                        const value = item[field.value];
                                        return (
                                            <Text key={fieldIndex} style={styles.cellText}>
                                                {field.isCurrency && typeof value === "number" ? amountFormat(value) : value}
                                            </Text>
                                        );
                                    })}
                                </View>
                            ))}
                        </View>
                    </View>

                    {totalSection ? <View style={styles.section}>
                        <Row label="Total Amount (Excluding Tax)" value={` ${decimalAmount(data.amount)}`} />
                        <Row label="Total Tax" value={` ${data.totalTax}`} />
                        <Text style={styles.total}>Grand Total: {data.totalAmount}</Text>
                    </View> : ''}
                </Page>
            </Document>
        </PDFViewer>
    );
};

export default PdfPreview;
