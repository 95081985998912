import React, { useState, useRef, useEffect } from 'react';
import './multiselect.css';
 
const MultiSelect = ({
    options = [],
    placeholder = "Select options",
    maxSelectable = 100,
    selectedItemStyle = { backgroundColor: "#007bff", color: "#fff" },
    theme = "light", // Can be "light" or "dark"
    onSelectionChange
  }) => {
    const [selectedItems, setSelectedItems] = useState([]);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [focusedIndex, setFocusedIndex] = useState(0);
    const dropdownRef = useRef(null);
 
    useEffect(() => {
      function handleClickOutside(event) {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setIsDropdownOpen(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);
 
    const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);
 
    const handleSelect = (option) => {
      if (selectedItems.includes(option)) {
        // If already selected, remove it
        const updatedSelectedItems = selectedItems.filter(item => item !== option);
        setSelectedItems(updatedSelectedItems);
        onSelectionChange?.(updatedSelectedItems);
      } else if (selectedItems.length < maxSelectable) {
        // Otherwise, add it if max selectable limit not reached
        const updatedSelectedItems = [...selectedItems, option];
        setSelectedItems(updatedSelectedItems);
        onSelectionChange?.(updatedSelectedItems);
      }
    };
 
    const handleKeyDown = (event) => {
      if (event.key === "ArrowDown") {
        setFocusedIndex((prevIndex) => (prevIndex + 1) % filteredOptions.length);
      } else if (event.key === "ArrowUp") {
        setFocusedIndex((prevIndex) => (prevIndex - 1 + filteredOptions.length) % filteredOptions.length);
      } else if (event.key === "Enter") {
        handleSelect(filteredOptions[focusedIndex]);
      }
    };
 
    const handleSearchChange = (event) => {
      setSearchText(event.target.value);
      setFocusedIndex(0);
    };
 
    const filteredOptions = options.filter(option =>
      option.toLowerCase().includes(searchText.toLowerCase())
    );
 
    const selectedDisplayText = selectedItems.length > 0
      ? selectedItems.join(", ")
      : placeholder;
 
    return (
      <div className={`multi-select-container ${theme}`} ref={dropdownRef}>
        <div
          className="dropdown-placeholder"
          onClick={toggleDropdown}
        >
          {selectedDisplayText}
        </div>
        {isDropdownOpen && (
          <div className="dropdown-menu">
            <input
              type="text"
              placeholder="Search..."
              value={searchText}
              onChange={handleSearchChange}
              onKeyDown={handleKeyDown}
              className="search-input"
            />
            <ul className="dropdown-list">
              {filteredOptions.map((option, index) => (
                <li
                  key={option}
                  className={`dropdown-item ${focusedIndex === index ? "focused" : ""}`}
                  onClick={() => handleSelect(option)}
                  onMouseEnter={() => setFocusedIndex(index)}
                >
                  <input
                    type="checkbox"
                    checked={selectedItems.includes(option)}
                    readOnly
                  />
                  {option}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    );
  };
 
  export default MultiSelect;