
const companyLeaveSetingsForm = [
    {
        // title: "Basic Information",
        formBody : [
            {
                // title: "Company Settings",
                formField: [
                    {
                        label: "Start Month",
                        inputType: "select",
                        options: "monthList",
                        uniqueKey: "monthId",
                        displayKey: "monthName",
                        placeholder:"select start date",
                        fieldName:"startMonthId",
                        onChange:"onSelectStartMonth",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Auto Renew",
                        inputType: "checkbox-old",
                        fieldName:"autoRenew",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    }  
                ]
            },
            {
                // title: "true",
                formField: [
                    {
                        label: "End Month",
                        inputType: "select",
                        options: "monthList",
                        placeholder:"select end date",
                        uniqueKey: "monthId",
                        displayKey: "monthName",
                        fieldName:"endMonthId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },                                         
                ]
            },
        ]
    }
];

const LeaveTypeSetingsForm = [
    {
        // title: "Basic Information",
        formBody : [
            {
                // title: "Leave Type Settings",
                formField: [
                    {
                        label: "Leave Type Name",
                        inputType: "select",
                        options: "leaveTypeList",
                        uniqueKey: "leaveTypeId",
                        displayKey: "leaveTypeName",
                        onChange: "onSelectLeaveTypeList",
                        disable:false,
                        multiple:false,
                        fieldName:"leaveTypeId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Number Of Leave",
                        inputType: "text",
                        type:"number",
                        fieldName:"noOfLeave",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    } ,
                    {
                        label: "Description",
                        inputType: "textarea",
                        placeholder: "Description",
                        fieldName:"description",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        description: "IsPaid",
                        inputType: "checkbox",
                        fieldName:"isPaid",
                        className:"mb-4",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    }
                ]
            },
        ]
    }
];


const workingDaysSetingsForm = [
    {
        // title: "Basic Information",
        formBody : [
            {
                // title: "Working Days Settings",
                formField: [
                    {
                        description: "IsPaid",
                        inputType: "checkbox",
                        fieldName:"isPaid",
                        className:"mb-4",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }
                ]
            },
        ]
    }
];

export {
    companyLeaveSetingsForm,
    LeaveTypeSetingsForm,
    workingDaysSetingsForm
};