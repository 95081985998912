const purchaseForm = [
    {
        title: "Basic Information",
        formBody: [

            {
                formField: [
                    {
                        placeholder: "Select Purchase Order",
                        inputType: "typedropdown",
                        options: "purchaseList",
                        displayKey: "itemInfo",
                        uniqueKey: "purchaseOrderId",
                        onChange: "handleGoodsChange",
                        fieldName: "poChoosed",
                        // onChange:"onSelectAttendanceDate",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }
                    ,
                ]
            },
            {
                formField: [
                    {
                        placeholder: "FromDate",
                        inputType: "date",
                        fieldName: "purchaseOrderDate",
                        // onChange:"onSelectAttendanceDate",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }
                ]
            },
            {
                formField: [
                    {
                        inputType: "select",
                        options: "statusList",
                        uniqueKey: "id",
                        displayKey: "name",
                        multiple: false,
                        fieldName: "statusId",
                        onChange: "handleStatus",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }
                ]
            },
            {
                formField: [
                    {
                        inputType: "text",
                        placeholder: 'Supplier Invoice',
                        fieldName: "supplierInvoice",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }
                ]
            },

        ]
    }
];
const purchaseAddForm = [
    {
        title: "Basic Information",
        formBody: [

            {
                formField: [
                    {
                        placeholder: 'Supplier',
                        inputType: "typedropdown",
                        options: "supplierList",
                        onChange: "handleSupplierChange",
                        uniqueKey: "supplierId",
                        displayKey: "supplierName",
                        fieldName: "supplierId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },

                    {

                        placeholder: 'Supplier Contact',
                        inputType: "text",
                        fieldName: "contactNumber",
                        // onChange:"onSelectAttendanceDate",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {

                        placeholder: 'Contact Person',
                        inputType: "text",
                        fieldName: "contactPerson",
                        // onChange:"onSelectAttendanceDate",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {

                        placeholder: 'Email',
                        inputType: "text",
                        fieldName: "supplierEmail",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {

                        placeholder: 'Supplier Address',
                        inputType: "textarea",
                        fieldName: "supplierAddress",
                        // onChange:"onSelectAttendanceDate",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },

                ]
            },
            {
                formField: [
                    {

                        inputType: "text",
                        placeholder: "Name",
                        fieldName: "deliveryName",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {

                        placeholder: 'Delivery Phone',
                        inputType: "text",
                        uniqueKey: "id",
                        displayKey: "number",
                        // type:'number',
                        fieldName: "deliveryPhoneNumber",
                        // onChange:"onSelectAttendanceDate",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {

                        placeholder: 'Delivery Email',
                        inputType: "text",
                        fieldName: "deliveryEmailId",
                        // onChange:"onSelectAttendanceDate",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {

                        placeholder: 'Delivery Contact Person',
                        inputType: "text",
                        fieldName: "deliveryContactPersonName",

                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {

                        placeholder: 'Delivery Address',
                        inputType: "textarea",
                        fieldName: "deliveryAddress",

                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },

                ]
            },
            {
                formField: [
                    {

                        placeholder: 'Permit Number',
                        inputType: "text",
                        fieldName: "permitId",
                        // onChange:"onSelectAttendanceDate",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {

                        placeholder: 'Ship Via',
                        inputType: "text",
                        fieldName: "shipVia",
                        // onChange:"onSelectAttendanceDate",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {

                        placeholder: 'FOB',
                        inputType: "text",
                        fieldName: "fob",
                        // onChange:"onSelectAttendanceDate",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {

                        placeholder: 'Debit Term',
                        inputType: "text",
                        fieldName: "debitTerm",
                        // onChange:"onSelectAttendanceDate",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {

                        placeholder: 'Memo',
                        inputType: "textarea",
                        fieldName: "memo",
                        // onChange:"onSelectAttendanceDate",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },

                ]
            },







        ]
    }
];

const purchasingForm = [
    {
        title: "Basic Information",
        formBody: [

            {
                formField: [
                    {

                        placeholder: 'Insurance',
                        inputType: "text",
                        uniqueKey: "currencyId",

                        fieldName: "insuranceAmount",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },


                ]
            },
            {
                formField: [

                    {

                        placeholder: 'Logistic',
                        inputType: "text",
                        uniqueKey: "currencyId",
                        fieldName: "logisticAmount",
                        // onChange:"onSelectAttendanceDate",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },

                ]
            },
            {
                formField: [

                    {

                        placeholder: 'Handling Cost',
                        inputType: "text",
                        uniqueKey: "currencyId",
                        fieldName: "handlingCost",
                        // onChange:"onSelectAttendanceDate",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },

                ]
            },
            {
                formField: [

                    {

                        placeholder: 'Other Cost',
                        inputType: "text",

                        fieldName: "otherCost",
                        // onChange:"onSelectAttendanceDate",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },


                ]
            },






        ]
    }
];

const subInner = [
    {
        placeholder: 'Warehouse',
        inputType: "typedropdown",
        options: "warehouseList",
        uniqueKey: "id",
        displayKey: "warehouseName",
        fieldName: "warehouseId",
        defaultValue: "",
        config: {
            required: "required",
        },
    },
    {
        placeholder: 'Location',
        inputType: "typedropdown",
        options: "locationList",
        uniqueKey: "id",
        displayKey: "name",
        fieldName: "locationId",
        defaultValue: "",
        config: {
            required: "required",
        },
    },
    {
        label: "Apply to all",
        inputType: "button",

        fieldName: "applyButton",
        onClick: "onSubmitItemDetails",
        defaultValue: "",
        config: {
            required: "required",
        },
    },




]

const fieldList = [
    {
        label: "",
        inputType: "select",
        placeholder: "Material Type",
        options: "materialTypeList",
        onChange: "getMaterialId",
        uniqueKey: "materialTypeId",
        displayKey: "materialName",
        fieldName: "materialTypeId",
        disabled: true,
        defaultValue: "",
        config: {
            required: "required",
        },
    },
    {
        label: "",
        inputType: "searchableInput",
        options: "materialCodeList",
        placeholder: "materialCodeId",
        fieldName: "materialCodeId",
        onChange: "getMaterialName",
        displayKey: "materialCode",
        disabled: true,

        defaultValue: "",
        config: {
            required: "",
        },
    },

    {
        label: "",
        inputType: "text",
        placeholder: "MaterialName",
        fieldName: "materialName",
        defaultValue: "",
        config: {
            required: "required",
        },
    },
    {
        placeholder: 'Unit',
        inputType: "select",
        options: "unitsList",
        // onChange: "handleUnitsChange",
        uniqueKey: "unitId",
        displayKey: "unitName",

        fieldName: "unit",
        defaultValue: "",
        config: {
            required: "required",
        },
    },
    {
        label: "",
        inputType: "text",
        placeholder: "Qty*",
        onChange: "onPriceChange",
        fieldName: "qty",
        defaultValue: "",
        config: {
            required: "required",
        },
    },



    {
        label: "",
        inputType: "text",
        uniqueKey: "poMaterialId",

        placeholder: "Received Quantity",
        fieldName: "receivedQty",

        defaultValue: "",
        config: {
            required: "required",
        },
    },


    {
        label: "",
        inputType: "icon-table",
        index: 'add',
        fieldName: "outline",
        onClick: "onAddItem",
        defaultValue: "",
        config: {
            required: "required",
        },
    },
    {
        label: "",
        inputType: "icon-table",
        index: 'delete',
        fieldName: "delete",
        onRight: "90px",
        onClick: "onDeletePress",
        defaultValue: "",
        config: {
            required: "required",
        },
    },
]


const inner = [
    {
        placeholder: 'Warehouse',
        inputType: "select",
        options: "warehouseList",
        uniqueKey: "id",
        displayKey: "warehouseName",
        fieldName: "warehouseId",
        onChange: "handleTypeChange",
        defaultValue: "",
        config: {
            required: "required",
        },
    },
    {
        placeholder: 'Location',
        inputType: "select",
        options: "locationList",
        uniqueKey: "id",
        displayKey: "name",
        fieldName: "locationId",
        onChange: "handleTypeChange",
        defaultValue: "",
        config: {
            required: "required",
        },
    },
    {
        label: "",
        inputType: "text",
        type: "number",
        placeholder: "batchNumber",
        fieldName: "batchNumber",
        onChange: "handleChange",
        defaultValue: "",
        config: {
            required: "",
        },
    },
    {
        label: "",
        inputType: "text",
        type: "number",
        placeholder: "serialNumber",
        fieldName: "serialNumber",
        onChange: "handleChange",
        defaultValue: "",
        config: {
            required: "",
        },
    },
    {
        label: "",
        inputType: "text",
        type: "number",
        placeholder: "Length",
        fieldName: "length",
        onChange: "handleChange",
        defaultValue: "",
        config: {
            required: "",
        },
    },
    {
        label: "",
        inputType: "text",
        type: "number",
        placeholder: "Width",
        fieldName: "width",
        onChange: "handleChange",
        defaultValue: "",
        config: {
            required: "",
        },
    },
    {
        label: "",
        inputType: "text",
        placeholder: "Thickness",
        fieldName: "thickness",
        onChange: "handleChange",
        defaultValue: "",
        config: {
            required: "",
        },
    },
    {
        label: "",
        inputType: "icon-table",
        index: 'delete',
        fieldName: "deleteInner",
        onClick: "onDeleteInner",
        defaultValue: "",
        config: {
            required: "required",
        },
    },
]


export { purchaseForm, purchaseAddForm, purchasingForm, subInner, inner, fieldList }