

const eventForm = [
  {
      title: "Basic Information",
      formBody: [
          {
              formField: [
                   {
                       label: "Opening Balance",
                       inputType: "text",
                       type:"number",
                       fieldName: "openingBalance",
                       defaultValue: "",
                       placeholder: "Enter Opening balance",
                       disable: false,
                       config: {
                           required: "required",
                       },
                   },
                   {
                      label: "Opening Date",
                      inputType: "date",
                      // placeholder: "Enter Description",
                      fieldName: "createdOn",
                      // disable: true,
                      defaultValue: "",
                      config: {
                          required: "required",
                      },
                  },
                  
              ]
          },
      ]
  }
];

const closeForm = [
  {
      title: "Basic Information",
      formBody: [
          {
              formField: [
                   {
                       label: "Opening Balance",
                       inputType: "text",
                       fieldName: "openingBalance",
                       defaultValue: "",
                       disable: true,
                       config: {
                           required: "",
                       },
                   },
                  {
                      label: "Closing Balance",
                      inputType: "text",
                      // placeholder: "Enter Description",
                      fieldName: "closingBalance",
                      disable: true,
                      defaultValue: "",
                      config: {
                          required: "required",
                      },
                  },
                  {
                      label: "closing Date",
                      inputType: "date",
                      fieldName: "closedDate",
                      // disable: true,
                      defaultValue: "",
                      config: {
                          required: "required",
                      },
                  },
                  
              ]
          },
      ]
  }
];

const feesForm = [
  {
      // title: "Basic Information",
      formBody: [
          {
              formField: [
                  {
                      inputType: "select",
                      options: "pettyCashTypeList",
                      uniqueKey: "pettyCashTypeId",
                      onChange: "onCashType",
                      displayKey: "pettyCashTypeName",
                      fieldName: "pettyCashTypeId",
                      defaultValue: "",
                      config: {
                          required: "required",
                      },
                  },
              ]
          },
          {
              formField: [
                  {
                      // label: "Fees Amount",
                      inputType: "text",
                      type: "number",
                      placeholder: "Enter Amount",
                      fieldName: "amount",
                      onChange:"amountCheck",
                      defaultValue: "",
                      config: {
                          required: "required",
                      },
                  },
              ]
          },
          {
              formField: [
                  {
                      inputType: "text",
                      // label: "Fees Amount",
                      placeholder: "Enter Remarks",
                      fieldName: "remarks",
                      defaultValue: "",
                      config: {
                          required: "required",
                      },
                  },
              ]
          },
          {
              formField: [
                  {
                      label: "",
                      inputType: "icon",
                      defaultValue: "",
                      onClick: "onRemove",
                      config: {
                          required: "",
                      },
                  }
              ]
          },
      ]
  }
];
export {
  eventForm, feesForm ,closeForm
};