import React, { useState, useEffect, useContext } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { linksArray } from '../../../navigationItems';
import { IoChevronForwardSharp, IoChevronDownSharp } from "react-icons/io5";
import { ThemeContext } from 'styled-components';
import styled from 'styled-components';
import Loader from '../atoms/loader/Loader';
import { useSidebar } from './sidebarContext';

const SubMenuBar = () => {
  const { sidebarOpen, toggleSidebar } = useSidebar();
  const { theme } = useContext(ThemeContext);
  const [openSubmenus, setOpenSubmenus] = useState({});
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(null);
  const [loading, setLoading] = useState(false);
  const [reduceMotion, setReduceMotion] = useState(false);
  const [dropDownMenu, setdropDownMenu] = useState(null);
  const pageData = window.localStorage.getItem("page")
  const sideNavbar = JSON.parse(pageData)
  let temp = []
  useEffect(() => {

    // Find the active children item or submenu item
    const currentLink = sideNavbar.find(link =>
      link.path === location.pathname ||
      link.children.some(sub =>
        sub.path === location.pathname ||
        (sub.children && sub.children.some(subItem => subItem.path === location.pathname))
      )
    );

    const subNavData = currentLink?.type === "dropDown" ? currentLink?.children : currentLink;



    let arr = [];

    if (typeof subNavData === 'object' && !Array.isArray(subNavData) && Object.keys(subNavData).length) {
      // If subNavData is an object, push it to the array
      arr.push(subNavData);
    } else {
      arr = subNavData;
    }

    setActiveLink(arr);
    setdropDownMenu(arr);




  }, [location]);
  console.log(dropDownMenu)







  const handleToggleSubmenu = (submenuIndex) => {
    setOpenSubmenus(prevState => ({
      ...prevState,
      [submenuIndex]: !prevState[submenuIndex]
    }));
  };

  if (!activeLink) return null;
  const handleLinkClick = async () => {
    setLoading(true);

    // Simulating an async operation like data fetching
    await new Promise(resolve => setTimeout(resolve, 1500)); // Simulate a delay for demonstration

    setLoading(false); // Hide the loader once the task is done
  };
  let dropdownFilter = dropDownMenu.filter((menuList, menuIndex) => {
    return menuList.type === "dropDown"

  })
  let linkFilter = dropDownMenu.filter((menuList, menuIndex) => {
    return menuList.type === "link"

  })
  console.log(dropdownFilter)

  return (
    <SidebarContainer sidebarOpen={sidebarOpen}>




      <SidebarMenu>
        {linkFilter.map((menuItem, index) => {
          // Check if the children item or any of its submenu items are active
          const isActiveMenu = menuItem.path === location.pathname ||
            (menuItem.children && menuItem.children.some(subItem => subItem.path === location.pathname));

          return (
            <SidebarItem key={index}>

              <SidebarLink
                to={menuItem.path}
                className={isActiveMenu ? 'active' : ''}
                onClick={handleLinkClick}
              >
                <ItemName onClick={() => handleToggleSubmenu(index)}>
                  {menuItem.label}
                </ItemName>
                {menuItem.submenu && (
                  <SubmenuToggle onClick={() => handleToggleSubmenu(index)} open={openSubmenus[index]}>
                    {openSubmenus[index] ? <IoChevronDownSharp /> : <IoChevronForwardSharp />}
                  </SubmenuToggle>
                )}
              </SidebarLink>

              {menuItem.submenu && (
                <Submenu open={openSubmenus[index]}>

                  {menuItem.submenu.map((subItem, subIndex) => (
                    <SubmenuItem key={subIndex}>
                      <SubmenuLink
                        to={subItem.path}
                        className={({ isActive }) => (isActive ? 'active' : '')}
                        onClick={handleLinkClick}
                      >
                        {subItem.label}
                      </SubmenuLink>
                    </SubmenuItem>
                  ))}
                </Submenu>
              )}

            </SidebarItem>
          );
        })}
        {dropdownFilter.length > 0 && (
          <DropdownSection>
            {dropdownFilter.map((menu_item, index) => (
              <React.Fragment key={`dropdown-${index}`}>
                <InventoryHeading><strong>{menu_item.label}</strong></InventoryHeading>
                {menu_item.children.map((item, ind) => (
                  <SidebarItem>
                    <Sidebar_Link to={item.path}>{item.label}</Sidebar_Link>
                  </SidebarItem>
                ))}
              </React.Fragment>
            ))}
          </DropdownSection>
        )}


      </SidebarMenu>

    </SidebarContainer>
  );
};

export default SubMenuBar;
const InventoryHeading = styled.h6`
  font-size: 15px; /* Adjust font size */
  color: ${({ theme }) => theme.primaryColor}; /* Use the primary color from theme */
  padding-left: 5px;

`;
const DropdownSection = styled.div`
  
`;
// Styled Components
const SidebarContainer = styled.div`
  width: 250px;
  // background-color: ${({ theme }) => theme.white};
  // overflow-y: scroll;

  overflow-x:hidden;
  height: 100vh;
  margin-left: ${({ sidebarOpen }) => (sidebarOpen ? '0px' : '-229px')};
  transition: margin-left 0.5s ease;
  z-index: 60;
 /* Scrollbar width for Firefox */

  /* Scrollbar styles for Chrome, Safari, and Edge */
  &::-webkit-scrollbar {
    width: 0px; /* Adjust width as needed */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cccccc; /* Thumb color */
    border-radius: 3px; /* Rounded corners */
  }

  &::-webkit-scrollbar-track {
    background-color: #f9f9f9; /* Track color */
  }
`;

const SidebarTitle = styled.div`
  position: fixed;
  width: 200px;
  height: 61px;
  padding: 10px;
  font-size: 12.5px;
  text-align: center;
  z-index: 1;
  background-image: url('/logo2.png');
  background-size: cover;
  background-position: center; /* This centers the image */
  background-repeat: no-repeat;
  border-bottom: 0.2px solid #D3D3D3;
  // background-color:white;
`;


const SidebarMenu = styled.ul`
  list-style: none;
  padding-left: 10px;
  padding-top:10px;
  padding-bottom:100px;
  margin-top:70px;
  position:absolute;
  height:100vh;
  width:200px;
  overflow-y:scroll;
  border-right: 0.2px dashed #D3D3D3;
  border-top: 0.2px dashed #D3D3D3;


 /* Scrollbar width for Firefox */

  /* Scrollbar styles for Chrome, Safari, and Edge */
  &::-webkit-scrollbar {
    width: 0px; /* Adjust width as needed */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cccccc; /* Thumb color */
    border-radius: 3px; /* Rounded corners */
  }

  &::-webkit-scrollbar-track {
    background-color: #f9f9f9; /* Track color */
  }


`;

const SidebarItem = styled.li`
  margin: 5px 0;
  
    
`;

const SidebarLink = styled(NavLink)`
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 8px;
  padding-left: 10px;

  border-radius: 5px;
  transition: background-color 0.3s;
  max-width: 180px;
  position: relative;

  &.active {
    background-color: ${({ theme }) => theme.primaryColor};
    color: white; /* Optional: change text color */
  }

  &:hover {
    background-color: ${({ theme }) => theme.lightGray}; /* Change path your desired hover color */
    color: ${({ theme }) => theme.text}; /* Optional: change text color */
  }
`;
const Sidebar_Link = styled(NavLink)`
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 8px;
  padding-left: 10px;

  border-radius: 5px;
  transition: background-color 0.3s;
  max-width: 180px;
  position: relative;

  &.active {
    background-color: ${({ theme }) => theme.primaryColor};
    color: white; /* Optional: change text color */
  }

  &:hover {
    background-color: ${({ theme }) => theme.lightGray}; /* Change path your desired hover color */
    color: ${({ theme }) => theme.text}; /* Optional: change text color */
  }
`;

const ItemName = styled.span`
  flex-grow: 2;
  width:100px;
  overflow-wrap: break-word;
 
`;

const Submenu = styled.ul`
  list-style: none;
  padding: ${({ open }) => (open ? '4px 10px' : '0 20px')};
  max-height: ${({ open }) => (open ? '500px' : '0px')};
  transition: max-height 0.2s ease, padding 0.2s ease;
  border-radius: 5px;
  overflow-y: scroll;
  opacity: ${({ open }) => (open ? '1' : '0')};

  /* Scrollbar width for Firefox */

  /* Scrollbar styles for Chrome, Safari, and Edge */
  &::-webkit-scrollbar {
    width: 3px; /* Adjust width as needed */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cccccc; /* Thumb color */
    border-radius: 3px; /* Rounded corners */
  }

  &::-webkit-scrollbar-track {
    background-color: #f9f9f9; /* Track color */
  }
`;

const SubmenuItem = styled.li`
  margin: 5px 0;
`;

const SubmenuLink = styled(NavLink)`
  text-decoration: none;
  color: black;
  display: block;
  padding: 8px 0px;
  padding-left: 20px;
  border-radius: 5px;
  position: relative;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.lightGray}; /* Change path your desired hover color */
    color: ${({ theme }) => theme.text}; /* Optional: change text color */
  }

  &.active {
    color: purple;
  }
  

  &.active::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 3px;
    height: 100%;
    background-color:${({ theme }) => theme.mediumPurple};
    border-radius: 5px 0 0 5px;
  }
`;

const SubmenuToggle = styled.button`
  background: none;
  border: none;
  color: black;
  cursor: pointer;
  float:right;
  transition: 0.3s ease;
  transform: ${({ open }) => (open ? 'rotate(180deg)' : 'rotate(0)')};
`;
