import { returnApiCallPromise } from './ApiContent';
import { examVariant } from './APIContainer';

//GET
export function getExamVariant(request) {
  const requestObj = request ? { params: request } : "";
  return (dispatch) => {
    returnApiCallPromise("GET", examVariant, requestObj)
      .then(response => {
        dispatch({
          type: 'GET_EXAM_VARIANT_SUCCESS',
          payload: response.data
        })
      }).catch(error => {
        
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'GET_EXAM_VARIANT_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}
