import axios from "axios";
import { Fragment, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import _ from "lodash";
import { CSSTransition } from 'react-transition-group';
import { Table, Collapse, DropdownButton, Dropdown, Button } from 'react-bootstrap';
import "./custom.css"
import { useSidebar } from "app/components/sidebar/sidebarContext";
import { Input } from 'antd';
import { buttons } from "app/pages/buttonConfig";
import styled from 'styled-components';
import { lightTheme } from "styles/theme";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import { IoIosArrowDropdownCircle, IoIosArrowDropupCircle } from "react-icons/io";
const { Search } = Input;
const StyledButton = styled.button`
  font-size: ${(props) => props.fontSize || '12px'};
  background-color: ${(props) => props.color || '#4D6D9A'};
  color: ${(props) => props.textColor || 'white'};
  border: ${(props) => props.border || 'none'};
  padding: 5px 10px;
  margin-left: 8px;
  cursor: pointer;

  &.btn-sm {
    padding: 5px 10px;
  }

  &.btn-lg {
    padding: 10px 20px;
  }

  /* Add additional styles as needed */
`;


const CustomTable = (props) => {
  const { data = [], columns = [], highlight, subColumns, subColumnItem, keyField, tableName, state, isExpand = false, expandView, buttonData = [], handleClick } = props;
  const initialPageCount = 10;
  const listData = [...data];
  const [rowsShowing, setrowsShowing] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(initialPageCount);
  const [itemOffset, setItemOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);

  //const currentItems = data.slice(itemOffset, endOffset);
  // Select required buttons for this page
  const pageButtons = buttonData.map(key => buttons[key]);


  const [tableState, tableSetState] = useState({
    itemList: [],
    expandIndex: null,
    sortableColumn: columns ? columns : [{
      dataField: "No Data",
      text: "Table",
      sort: true
    }],
    expandColumn: subColumns ? subColumns : [{
      dataField: "No Data",
      text: "Table",
      sort: true
    }],
  });
  useEffect(() => {
    if (typeof currentPage !== "undefined") {
      setCurrentPage(0);
      setrowsShowing(0);
      setRowsPerPage(initialPageCount);
      setItemOffset(0);
      setCurrentPage(0);
    }
  }, [listData.length]);
  useEffect(() => {
    tableSetState({
      ...tableState,
      expandIndex: null,
      itemList: listData,
      sortableColumn: columns ? columns : [{
        dataField: "No Data",
        text: "Table",
        sort: true
      }],
      expandColumn: subColumns ? subColumns : [{
        dataField: "No Data",
        text: "Table",
        sort: true
      }],
    })
    /*  setrowsShowing(0);
     setRowsPerPage(initialPageCount);
     setItemOffset(0);
     setCurrentPage(0); */
  }, [props]);
  let endOffset = itemOffset + rowsPerPage;
  const pageClick = (clickEvent) => {
    if (clickEvent.nextSelectedPage != undefined) {
      setrowsShowing(clickEvent.nextSelectedPage)
    }
  }
  const handleChangePage = (event) => {
    const newOffset = (event.selected * rowsPerPage) % itemList.length;
    setItemOffset(newOffset);
    setCurrentPage(event.selected)
  }
  const sortItems = (item, index) => {
    const listItems = [...itemList];
    let sortColmn = [...sortableColumn];
    let sortType = "asc";
    if (sortColmn[index].sorting === "asc") { sortType = "desc"; }
    sortColmn[index].sorting = sortType;
    const itms = _.orderBy(listItems, [item["dataField"]], [sortType]);
    tableSetState({
      ...tableState,
      itemList: itms,
      sortableColumn: sortColmn
    })
  }
  const filterData = (event) => {
    const search = event.target.value || '';
    setLoading(search.length > 0);
    const listItems = [...data];
    let filterData = listItems.filter((row) => {
      let found = Object.values(row).find(o => ((o ? o : '').toString().toLowerCase()).includes(search.toLowerCase()));
      if (found) return true;
      else return false;
    });
    if (filterData.length == 0) { setLoading(false) }
    if (!search) { filterData = listData; }
    tableSetState({
      ...tableState,
      itemList: filterData
    })
    setrowsShowing(0);
    setRowsPerPage(initialPageCount);
    setItemOffset(0);
  }
  let { itemList, sortableColumn, expandColumn, expandIndex } = tableState;
  const pageCount = Math.ceil(itemList.length / rowsPerPage);
  const currentItems = itemList.slice(itemOffset, endOffset);
  const totalItems = Math.ceil(itemList.length);

  let rowStart = rowsShowing === 0 ? 1 : ((rowsShowing * rowsPerPage) + 1);
  rowStart = rowStart > totalItems ? 1 : rowStart
  let rowEnding = rowsPerPage * (rowsShowing + 1);
  let rowEnd = rowEnding > totalItems ? totalItems : rowEnding;

  const onChangePageNav = (item) => {
    let rowsShowingReset = 0;
    let rowsPerPageReset = item;
    rowStart = rowsShowingReset === 0 ? 1 : ((rowsShowingReset * rowsPerPageReset) + 1);
    rowEnding = rowsPerPageReset * (rowsShowingReset + 1);
    rowEnd = rowEnding > totalItems ? totalItems : rowEnding;

    setRowsPerPage(item)
    const event = {
      "selected": 0
    }
    const newOffset = (event.selected * rowsPerPageReset) % itemList.length;
    setItemOffset(newOffset);
    setCurrentPage(event.selected)
  }

  const expandTableView = (subItems, rowIndex) => {
    return (
      <div style={{ borderLeft: '5px solid red' }}>
        <Table striped bordered hover>
          <thead>
            <tr>
              {
                expandColumn.length && (
                  expandColumn.map((item, index) => {
                    return (
                      <th key={`th_${rowIndex}_${index}`} scope="col" style={{ cursor: "pointer" }} >
                        {item.text}
                      </th>
                    )
                  })
                )
              }
            </tr>
          </thead>
          <tbody>

            {
              subItems.length && (
                subItems.map((item, index) => {
                  return (
                    <Fragment key={`trf_${rowIndex}_${index}`}>
                      <tr key={`tr_${rowIndex}_${index}`} >
                        {expandColumn.map((colItem, colIndex) => {
                          return (
                            <td key={`tc_${colIndex}`} scope="row">
                              {
                                colItem.formatter ?
                                  colItem.formatter(item[colItem.dataField], item, rowIndex + (rowsShowing * rowsPerPage), state, index)
                                  : item[colItem.dataField]
                              }
                            </td>
                          )
                        }
                        )}
                      </tr>
                    </Fragment>
                  )
                })
              )
            }


            {/* <tr

                        >
                            <td>1</td>
                            <td>TEST 123</td>
                            <td>test123@test.com</td>
                        </tr>
                        <tr

                        >
                            <td>2</td>
                            <td>TEST 456</td>
                            <td>test456@test.com</td>
                        </tr> */}
          </tbody>
        </Table>
      </div>
    )
  }
  const { sidebarOpen } = useSidebar();


  return (

    <div className={`table-container ${sidebarOpen ? 'expanded' : 'collapsed'}`}>

      <div className="d-flex align-items-center justify-content-between">

        <h2 className="card-description" style={{ color: lightTheme.primaryColor }}>
          {tableName}
        </h2>
        {/* </div> */}
        <div className="dynamic-button-container d-flex flex-row-reverse ms-auto">
          {pageButtons.map((button, index) => (
            <button
              key={index}
              style={{
                fontSize: `${button?.fontSize || "12px"}`,
                backgroundColor: `${button?.color || "#4D6D9A"}`,
                color: `${button?.textColor || 'white'}`,
                border: `${button?.border || 'none'}`
              }}
              className={`btn ms-2 btn-${button?.size || 'sm'}`}
              onClick={() => { handleClick(button.name) }}
            >
              {button.name}
            </button>
          ))}
          <Search
            placeholder="Search in this table"
            id="table-search"
            loading={loading}
            // enterButton
            onChange={filterData}

          />

        </div>
      </div>
      <br />
      <div className="table-responsive">
        <Table bordered hover>
          {/* <table id={tableName} className="table dataTable-collapse table-bordered table-striped" > */}
          <thead>
            <tr key={`th_0`}>
              {isExpand && <th></th>}
              {sortableColumn.map((item, index) => {
                return (
                  <th
                    key={`th_${index}`}
                    scope="col"
                    style={{
                      cursor: "pointer",
                      textAlign: item.text === "Action" ? "center" : "left",
                      color: lightTheme.primaryColor
                    }}
                    onClick={() => {
                      item.sort && sortItems(item, index);
                    }}
                  >
                    {item.text}
                    {item?.sorting && (
                      <i
                        className={`text-10 ${item.sorting === "asc" ? `i-Up1` : `i-Down1`
                          }`}
                      ></i>
                    )}
                  </th>
                );
              })}
            </tr>
          </thead>

          <tbody>
            {currentItems.length === 0 && <tr key={"tr_0"} className="text-center"><td style={{ color: "gray", textAlign: "center" }} colSpan={sortableColumn.length} >Data Not Founded</td></tr>}
            {(currentItems).map((rowItem, rowIndex) => {
              const indexClicked = expandIndex === rowIndex ? null : rowIndex;
              let highlightStyle = {};
              let highlightStatus = false
              if (highlight) {
                highlightStatus = rowItem?.[highlight?.dataField]?.toString() === highlight?.value ? true : false;
                highlightStyle = highlightStatus ? { backgroundColor: '#ae2c2c' } : {};
              }

              return (
                <Fragment key={`trf_${rowIndex}`}>
                  <tr key={`tr_${rowIndex}`} style={highlightStyle} onClick={() => {
                    tableSetState({ ...tableState, expandIndex: indexClicked })
                  }}>
                    {isExpand && (
                      <td style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',marginTop:"5px" }}>
                        <span style={{ backgroundColor: "transparent",/*  color: highlightStatus ? '#FFF' : '#000' */ }}>
                          {expandIndex === rowIndex ? <IoIosArrowDropupCircle size={20} /> : <IoIosArrowDropdownCircle size={20} />}
                        </span>
                      </td>
                    )}
                    {sortableColumn.map((colItem, colIndex) => {
                      return (
                        <Fragment key={colIndex}>
                          <td key={`tc_${colIndex}`} scope="row" style={{ maxWidth: '200px', color: highlightStatus ? '#FFF' : '#000' }}>
                            {
                              colItem.formatter ?
                                colItem.formatter(rowItem[colItem.dataField], rowItem, rowIndex + (rowsShowing * rowsPerPage), state)
                                : rowItem[colItem.dataField]
                            }
                          </td>
                        </Fragment>
                      )
                    }
                    )}
                  </tr>
                  {
                    isExpand &&
                    <tr>
                      <td style={{ margin: 0, padding: 0 }} colspan={sortableColumn.length + (isExpand ? 1 : 0)}>
                        <Collapse key={`ce_${rowIndex}`} timeout={1000} in={rowIndex === expandIndex ? true : false} appear={true} >
                          {expandTableView(rowItem?.[subColumnItem] || [], rowIndex)}
                        </Collapse>
                      </td>
                    </tr>
                  }
                </Fragment>
              )
            }
            )}
          </tbody>
        </Table>

      </div>
      <div className="d-flex  align-items-center justify-content-between md-12">
        <div className="d-flex align-items-center">
          <DropdownButton
            variant={"custom"}
            size="sm"
            title={rowsPerPage + " items per page"}
            className="custom-dropdown"
          >
            {
              [5, 10, 20, 30, 40, 50].map((item, index) => {
                return (
                  <Dropdown.Item key={`dd_${index}`} onClick={() => { onChangePageNav(item) }} eventKey={item}>{item} items per page</Dropdown.Item>
                )
              })
            }
          </DropdownButton>
          <a className="text-active text-small w-15 me-3 w-sm-100" style={{ marginBottom: "10px", marginLeft: "20px" }}>Showing rows {rowStart} to {rowEnd} of {totalItems}</a>

        </div>
        <div className="justify-content-end">
          <ReactPaginate
            previousLabel={"< Previous"}
            nextLabel={"Next >"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onClick={pageClick}
            // forcePage={currentPage > 0 ? currentPage : -1}
            forcePage={Math.min(currentPage, pageCount - 1)}

            onPageChange={handleChangePage}
            containerClassName={"pagination pagination-lg"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        </div>
      </div>
    </div>

  );
};

export default CustomTable;