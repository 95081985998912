
const inventoryTransferForm = [
    {
        title: "Basic Information",
        formBody: [
            {
                formField: [
                    {
                        label: "Material Name",
                        inputType: "text",
                        fieldName: "materialName",
                        placeholder:'Material Name',
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    },
                    {
                        label: "Submit",
                        inputType: "button",
                        classStyle : "mt-4",
                        displayKey: "submit",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    }
                ]
            },
            {
                formField: [
                    {
                        label: "Material Code",
                        inputType: "text",
                        fieldName: "materialCode",
                        placeholder:'Material Name',
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    },


                ]
            },
            {
                formField: [

                    {
                        label: "Material Type",
                        inputType: "select",
                        fieldName: "materialType",
                        options: "materialTypeList",
                        uniqueKey: "materialId",
                        displayKey: "materialName",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    }

                ]
            },
            {
                formField: [

                   

                ]
            },
        ]
    }
];

const createInventoryTransferForm = [
    {
        title: "Basic Information",
        formBody: [
            {
                formField: [
                    {
                        // label: "From Warehouse",
                        inputType: "select",
                        fieldName: "fromWarehouse",
                        options: "warehouseList",
                        uniqueKey: "id",
                        displayKey: "warehouseName",
                        placeholder:'From Warehouse*',
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        // label: "From Location",
                        inputType: "select",
                        fieldName: "fromLocation",
                        options: "locationList",
                        uniqueKey: "id",
                        displayKey: "name",
                        placeholder:'From Location*',
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        // label: "To Warehouse",
                        inputType: "select",
                        fieldName: "toWarehouse",
                        options: "warehouseList",
                        uniqueKey: "id",
                        displayKey: "warehouseName",
                        placeholder:'To Warehouse',
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        // label: "To Location",
                        inputType: "select",
                        // placeholder:'Select To Location',
                        fieldName: "toLocation",
                        options: "locationList",
                        uniqueKey: "id",
                        displayKey: "name",
                        placeholder:'To Location*',
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },
            {
                formField: [

                    {
                        // label: "Transfer Date",
                        inputType: "date",
                        fieldName: "transferDate",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        // label: "Memo",
                        inputType: "textarea",
                        fieldName: "memo",
                        placeholder:'Memo',
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },

                ]
            },
        ]
    }
];

const fieldList = [
    {
        label: "",
        inputType: "searchableInput",
        placeholder: "materialCodeId",
        fieldName: "materialCode",
        onChange:"handleMaterialChange",
        displayKey: "materialCode",
        // disable: true,
        defaultValue: "",
        config: {
            required: "",
        },
    },
        // icon: "i-Magnifi-Glass1"
   
    {
        label: "",
        inputType: "text",
        placeholder: "MaterialName",
        fieldName: "materialName",
        defaultValue: "",
        config: {
            required: "required",
        },
    },
    {
        label: "",
        inputType: "text",
        placeholder: "Qty*",
        fieldName: "qty",
        defaultValue: "",
        config: {
            required: "required",
        },
    },
    {
        label: "",
        inputType: "select",
        fieldName: "unit",
        options: "unitsList",
        uniqueKey: "unitId",
        displayKey: "unitName",
        defaultValue: "",
        config: {
            required: "required",
        },
    },
    {
        label: "",
        inputType: "text",
        placeholder: "Dimension",
        fieldName: "dimension",
        defaultValue: "",
        config: {
            required: "required",
        },
    },
    {
        label: "",
        inputType: "select",
        options: "typeList",
        uniqueKey: "id",
        displayKey: "typeName",
        fieldName: "type",
        defaultValue: "",
        config: {
            required: "required",
        },
    },
    {
        label: "",
        inputType: "text",
        placeholder: "Reason",
        fieldName: "reason",
        defaultValue: "",
        config: {
            required: "required",
        },
    },
    {
        label: "",
        inputType: "text",
        placeholder: "Remark",
        fieldName: "remark",
        defaultValue: "",
        config: {
            required: "required",
        },
    },
    {
        label: "",
        inputType: "icon-table",
        index: 'outline-clock',
        onClick: 'onAddItem',
        fieldName: "outline",
        defaultValue: "",
        config: {
            required: "required",
        },
    },
    {
        label: "",
        inputType: "icon-table",
        index: 'delete',
        fieldName: "delete",
        onClick: 'onDeletePress',
        defaultValue: "",
        config: {
            required: "required",
        },
    },
]


export {
    inventoryTransferForm,createInventoryTransferForm,fieldList
};