const homeWorkDetailsForm = [
    {
        title: "Basic Information",
        formBody: [
            {
                formField: [
                    {
                        label: "",
                        inputType: "select",
                        placeholder: "Select Subject",
                        options: "subjectList",
                        uniqueKey: "subjectId",
                        displayKey: "subjectTitle",
                        multiple: false,
                        fieldName: "subjectId",
                        defaultValue: "required",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },
            {
                formField: [
                    {
                        label: "",
                        inputType: "text",
                        placeholder: "Enter Home Work Title",
                        fieldName: "homeWorkTitle",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },
            {
                formField: [
                    {
                        label: "",
                        inputType: "textarea",
                        placeholder: "Enter Description",
                        fieldName: "description",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }

                ]
            },
            {
                formField: [
                    {
                        label: "",
                        inputType: "icon",
                        defaultValue: "",
                        onClick: "onRemove",
                        config: {
                            required: "required",
                        },
                    }
                ]
            }
        ]
    }
];

const homeWorkForm = [
    {
        title: "Basic Information",
        formBody: [
            {
                formField: [
                    {
                        label: "Batch Name",
                        inputType: "select",
                        options: "batchList",
                        uniqueKey: "batchId",
                        onChange: "onSelectBatch",
                        displayKey: "batchName",
                        fieldName: "batchId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },
            {
                formField: [
                    {
                        label: "Created By",
                        inputType: "typedropdown",
                        options: "employeeList",
                        uniqueKey: "employeeId",
                        displayKey: "employeeCode",
                        subDisplayKey: "employeeName",
                        multiple: false,
                        onChange: "handleSubjectTeachers",

                        // disabled:true,
                        // limit: "1",
                        fieldName: "employeeId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },
            {
                formField: [
                    {
                        label: "Class - Section",
                        inputType: "select",
                        options: "enrollmentDetailsList",
                        uniqueKey: "enrollmentId",
                        displayKey: "classTitle",
                        onChange: "onSelectClass",
                        subDisplayKey: "sectionTitle",
                        multiple: true,
                        fieldName: "enrollmentId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },
          
            
            {
                formField: [
                    {
                        label: "Type",
                        inputType: "select",
                        options: "homeWorkTypeList",
                        uniqueKey: "typeId",
                        displayKey: "typeName",
                        multiple: false,
                        fieldName: "typeId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },
            
            {
                formField: [
                    {
                        label: "Submission Date",
                        inputType: "date",
                        fieldName: "submissionDate",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },
            {
                formField: [
                    {
                        label: "Home Work Date",
                        inputType: "date",
                        fieldName: "createdAt",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },
         
            // {
            //     formField: [
            //         {
            //             label: "Select Student",
            //             inputType: "button",
            //             // color: "info",
            //             /*  options: "filterStudentEnrollment",
            //              uniqueKey:"studentEnrollmentId",
            //              displayKey:"studentName",
            //              subDisplayKey:"lastName",
            //              onChange:"onSelectStudent",
            //              multiple:true, */
            //             position: '25px',
            //             fieldName: "studentEnrollmentId",
            //             defaultValue: "",
            //             config: {
            //                 required: "required",
            //             },
            //         }
            //     ]
            // }
        ]
    }
];

const homeWorkEditForm = [
    {
        title: "Basic Information",
        formBody: [
            {
                formField: [
                    {
                        label: "Batch Name",
                        inputType: "select",
                        options: "batchList",
                        uniqueKey: "batchId",
                        disabled:true,
                        onChange: "onSelectBatch",
                        displayKey: "batchName",
                        fieldName: "batchId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },
            {
                formField: [
                    {
                        label: "Type",
                        inputType: "select",
                        options: "homeWorkTypeList",
                        uniqueKey: "typeId",
                        disabled:true,
                        displayKey: "typeName",
                        multiple: false,
                        fieldName: "typeId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },
            {
                formField: [
                    {
                        label: "Created By",
                        inputType: "typedropdown",
                        options: "employeeList",
                        uniqueKey: "employeeId",
                        displayKey: "employeeCode",
                        subDisplayKey: "employeeName",
                        onChange: "handleSubjectTeachers",
                        multiple: false,
                        limit: "1",
                        disabled: true,
                        fieldName: "employeeId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },
            {
                formField: [
                    {
                        label: "Submission Date",
                        inputType: "date",
                        fieldName: "submissionDate",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },
            {
                formField: [
                    {
                        label: "Home Work Date",
                        inputType: "date",
                        fieldName: "createdAt",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },
            {
                formField: [
                    {
                        label: "Class -Section",
                        inputType: "select",
                        options: "enrollmentDetailsList",
                        uniqueKey: "enrollmentId",
                        displayKey: "classTitle",
                        onChange: "onSelectClass",
                        subDisplayKey: "sectionTitle",
                        multiple: true,
                        fieldName: "enrollmentId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },
           
        ]
    }
];


export {
    homeWorkDetailsForm,
    homeWorkForm,
    homeWorkEditForm
};