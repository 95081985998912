import { returnApiCallPromise } from './ApiContent';
import { homework } from './APIContainer';

//GET
export function getHomeWork(request) {
  const requestObj = request ? { params: request } : "";
  return (dispatch) => {
    returnApiCallPromise("GET", homework, requestObj)
      .then(response => {
        dispatch({
          type: 'GET_HOME_WORK_SUCCESS',
          payload: response.data
        })
      }).catch(error => {
        
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'GET_HOME_WORK_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

export function deleteHomeWorkDetails(request,homeWorkId) {
  const requestObj = request ? { params: request } : "";
  return (dispatch) => {
    returnApiCallPromise("DELETE",  homework+"/"+homeWorkId, requestObj)
      .then(response => {
        dispatch({
          type: 'DELETE_HOME_WORK_DETAILS_SUCCESS',
          payload: response.data
        })
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'DELETE_HOME_WORK_DETAILS_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

//POST
export function createHomeWork(request) {

  return (dispatch) => {
    returnApiCallPromise("POST", homework, request)
      .then(response => {
        if (response.data.error) {
          dispatch({
            type: 'CREATE_HOME_WORK_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'CREATE_HOME_WORK_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'CREATE_HOME_WORK_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

export function updateHomeWork(request, homeWorkId) {
  return (dispatch) => {
    returnApiCallPromise("PUT", homework+"/"+homeWorkId, request)
      .then(response => {

        if (response.data.error) {
          dispatch({
            type: 'UPDATE_HOME_WORK_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'UPDATE_HOME_WORK_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'UPDATE_HOME_WORK_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}