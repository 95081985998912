import React from 'react';
import { statusForm, feesRecords } from './formData';
import View from 'app/components/atoms/modalView/Modalview';
import CustomTable from 'app/components/atoms/custom-table/CustomTable';
import FormLayout from 'app/components/render/formlayout';
import SimpleCard from 'app/components/atoms/simple-card/SimpleCard';
import { FiEdit, FiTrash2 } from "react-icons/fi";
import { getEnquiry, createEnquiry, updateEnquiry } from "app/api/EnquiryApi";
import { getEnquiryType } from "app/api/EnquiryTypeApi";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { pagePermission, showMessage } from "app/utils/app-functions";
import _ from "lodash";
import swal from "sweetalert2";
import FormWizardBody from "app/components/atoms/formwizard/FormWizardBody";
import TableLayout from 'app/components/render/formlayout/tablelayout';
import { Button } from 'react-bootstrap';
import Alert from 'app/components/atoms/alert';
import { enquiryForm } from "./formData";
import { useSidebar } from "app/components/sidebar/sidebarContext";

let editData = false;

const Enquiry = ({ navigation }) => {

  const dispatch = useDispatch();
  const customerFormRef = useRef();

  const getEnquirySuccess = useSelector((state) => state.enquiryReducer.getEnquirySuccess);
  const getEnquiryList = useSelector((state) => state.enquiryReducer.getEnquiryList);
  const getEnquiryFailure = useSelector((state) => state.enquiryReducer.getEnquiryFailure);

  const getEnquiryTypeSuccess = useSelector((state) => state.enquiryTypeReducer.getEnquiryTypeSuccess);
  const getEnquiryTypeList = useSelector((state) => state.enquiryTypeReducer.getEnquiryTypeList);
  const getEnquiryTypeFailure = useSelector((state) => state.enquiryTypeReducer.getEnquiryTypeFailure);

  const createEnquirySuccess = useSelector((state) => state.enquiryReducer.createEnquirySuccess);
  const createEnquiryData = useSelector((state) => state.enquiryReducer.createEnquiryData);
  const createEnquiryFailure = useSelector((state) => state.enquiryReducer.createEnquiryFailure);

  const updateEnquirySuccess = useSelector((state) => state.enquiryReducer.updateEnquirySuccess);
  const updateEnquiryData = useSelector((state) => state.enquiryReducer.updateEnquiryData);
  const updateEnquiryFailure = useSelector((state) => state.enquiryReducer.updateEnquiryFailure);

  const enquryErrorMessage = useSelector((state) => state.enquiryReducer.errorMessage);

  const [pageItems, setpageItems] = useState({
    "viewOption": false,
    "addOption": true,
    "updateOption": false,
    "deleteOption": false
  }
  );

  useEffect(() => {
    let data = pagePermission(pageItems, false, "Enquiry")
    setpageItems(data)

  }, [navigation]);

  const column = [
    {
      "dataField": "title",
      "text": "Title",
      "sort": true
    },
    {
      "dataField": "description",
      "text": "Description",
      "sort": true,
      /*  formatter: (cellContent, row, index) => {
           return dateConversion(row.holidayDate, "DD-MMM-YYYY")
       } */
    },
    {
      "dataField": "phoneNo",
      "text": "Phone Number",
      "sort": true
    },
    /*  {
         "dataField": "endTime",
         "text": "End Time",
         "sort": true
     }, */
    /*   {
          dataField: "isActive",
          text: "Status",
          sort: true,
          align: "center",
          headerAlign: "center",
          formatter: (cellContent, row, index) => {
              const conInfo = { inputType: "status", status: cellContent }
              return formRender(null, null, conInfo, null);
          }
      }, */

  ]
  if (pageItems.update) {
    column.push({
      text: "Action",
      sort: false,
      align: "center",
      headerAlign: "center",
      formatter: (cellContent, row, index) => (
        <div className="icon-container">

          {pageItems.update && <FiEdit
            className="text-primary cursor-pointer"
            size={18}
            onClick={() => onEditForm(row, index)}
          ></FiEdit>}

        </div>
      )
    })
  }

  const [state, setState] = useState({});

  const [createModule, setCreateModule] = useState(false);
  const [loaderModule, setLoaderModule] = useState(false);
  const [deleteModule, setDeleteModule] = useState(false);
  const [itemList, setItemList] = useState([]);
  const [selectedItem, setSelectedItem] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(false);
  const [viewModule, setViewModule] = useState(false);


  useEffect(() => {
    setLoaderModule(true)
    const requestData = {
      isActive: "true",
      unitId: 1
    }
    dispatch(getEnquiry(requestData))
    dispatch(getEnquiryType())
  }, [navigation]);

  useEffect(() => {
    if (getEnquirySuccess) {
      setItemList(getEnquiryList)
      setState({
        ...state,
        eventList: getEnquiryList
      })
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_ENQUIRY" })
    } else if (getEnquiryFailure) {
      setItemList([])
      showMessage("warning", enquryErrorMessage?.message)
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_ENQUIRY" })
    }
  }, [getEnquirySuccess, getEnquiryFailure]);

  useEffect(() => {
    if (getEnquiryTypeSuccess) {
      setState({
        ...state,
        enquiryTypeList: getEnquiryTypeList
      })

      setLoaderModule(false)
      dispatch({ type: "RESET_GET_ENQUIRY_TYPE" })
    } else if (getEnquiryTypeFailure) {

      showMessage("warning", enquryErrorMessage?.message)
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_ENQUIRY_TYPE" })
    }
  }, [getEnquiryTypeSuccess, getEnquiryTypeFailure]);

  useEffect(() => {
    if (createEnquirySuccess) {
      const temp_state = [...itemList, createEnquiryData[0]];
      setItemList(temp_state)
      setLoaderModule(false)
      closeModule()
      Alert({  description: 'Created Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
      dispatch({ type: "RESET_CREATE_ENQUIRY" })
    } else if (createEnquiryFailure) {
      setLoaderModule(false)
      showMessage("warning", enquryErrorMessage?.message)
      dispatch({ type: "RESET_CREATE_ENQUIRY" })
    }
  }, [createEnquirySuccess, createEnquiryFailure]);


  useEffect(() => {
    if (updateEnquirySuccess) {
      updateTable(updateEnquiryData[0])
      dispatch({ type: "RESET_UPDATE_ENQUIRY" })
    } else if (updateEnquiryFailure) {
      setLoaderModule(false)
      showMessage("warning", enquryErrorMessage)
      dispatch({ type: "RESET_UPDATE_ENQUIRY" })
    }
  }, [updateEnquirySuccess, updateEnquiryFailure]);

  const updateTable = (updatedItem) => {
    const temp_state = [...itemList];
    if (deleteModule) {
      let remainingList = _.remove(temp_state, function (n) {
        return n.holidayId != updatedItem.holidayId;
      });
      Alert({  description: 'Deleted Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
      setItemList(remainingList)
    } else {
      temp_state[selectedIndex] = updatedItem;
      Alert({  description: 'Updated Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
      setItemList(temp_state)
    }
    setLoaderModule(false)
    closeModule()
  }

  const closeModule = () => {
    editData = false;
    setCreateModule(false)
    setDeleteModule(false)
  }

  const resetState = () => {
    setState({
      ...state,
      enquiryTypeId: "",
      title: "",
      description: "",
      phoneNumber: "",



    })
  }

  const onCreateForm = () => {
    resetState()
    editData = false;
    setCreateModule(true)
  }

  const onEditForm = (data, index) => {
    setState({
      ...state,
      title: data?.title || "",
      description: data?.description || "",
      phoneNumber: data?.phoneNo || "",
      enquiryTypeId: data?.enquiryTypeId || "",
    })
    editData = true;
    setSelectedIndex(index)
    setSelectedItem(data)
    setCreateModule(true)
  }

  const validateForm = async () => {
    const validatefom = customerFormRef.current.validateForm();
  }

  const enquiryid = (event) => {
    const id = event;
    const matchingObject = state.enquiryTypeList.find(item => item.enquiryTypeId == id);
    setState({
      ...state,
      enquiryTypeId: matchingObject?.enquiryTypeId || ""
    })
  }

  const onSubmitForm = () => {
    const requestDate = {
      title: state?.title || "",
      description: state?.description || "",
      phoneNo: state?.phoneNumber || "",
      enquiryTypeId: state?.enquiryTypeId || "",
    }
    function validateFormData(requestData) {
      for (let key in requestData) {
        if (requestData.hasOwnProperty(key) && key !== "middleName" && key !== "emisNumber") {
          if (
            requestData[key] === null ||
            requestData[key] === undefined ||
            requestData[key] === "" ||
            requestData[key] === "0" ||
            requestData[key] === 0 ||
            (typeof requestData[key] === "string" && requestData[key].trim() === "") ||
            (typeof requestData[key] === "number" && isNaN(requestData[key]))
          ) {
            return false;
          }
        }
      }
      return true;
    }
    if (!validateFormData(requestDate)) {
      showMessage("warning", "ENTER ALL THE DATA")
    } else {
      if (editData) {
        setLoaderModule(true)
        dispatch(updateEnquiry(requestDate, selectedItem.enquiryId))
      } else if (deleteModule) {
        const deleteRequest = {
          isActive: "false"
        }
        setLoaderModule(true)
        // dispatch(updateevent(deleteRequest, selectedItem.holidayId))
      } else {
        setLoaderModule(true)
        dispatch(createEnquiry(requestDate))
      }
    }

  }

  const modelTitle = editData ? "Edit " : "Create ";
  const modelButtonLbl = editData ? "" : "Save";

  const handleCloseModal = () => {
    setCreateModule(false);
  };


  const handleClick = (buttonName) => {
    if (buttonName == 'Add') {
      onCreateForm();
    }
  };
  const { sidebarOpen } = useSidebar();

  return (
    <div>
      <div style={sidebarOpen ? { marginLeft: "10px", marginTop: "-10px" } : { marginLeft: "10px", marginTop: "-50px"}}>

        <View isOpen={createModule} size="md" savetitle={modelButtonLbl} onClose={handleCloseModal} onSubmit={onSubmitForm} title={`${modelTitle} Enquiry`}>
          <FormLayout ref={customerFormRef} defaultState={state} setDefaultState={setState} onChangeCallback={{ "onSelectEnquiryId": enquiryid }} dynamicForm={enquiryForm} noOfColumn={2} onSubmit={onSubmitForm} />
        </View>
        <View centered={true} size={'lg'} isOpen={viewModule} onClose={setViewModule} savetitle="Close" onSearch={validateForm} title="Enquiry">
        </View>
        <SimpleCard >
          <CustomTable buttonData={pageItems.create ? ['add'] : []} handleClick={handleClick} tableName={"Enquiry"} columns={column} data={itemList} state={state} />
        </SimpleCard>
      </div>
    </div>
  )
}

export default Enquiry;