import { returnApiCallPromise } from './ApiContent';
import { religion } from './APIContainer';

//GET
export function getReligion(request) {
  const requestObj = request ? { params: request } : "";
  return (dispatch) => {
    returnApiCallPromise("GET", religion, requestObj)
      .then(response => {
        dispatch({
          type: 'GET_RELIGION_SUCCESS',
          payload: response.data
        })
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'GET_RELIGION_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

export function asyncgetReligion(request) {
  const requestObj = request ? { params: request } : "";
  return  returnApiCallPromise("GET", religion, requestObj)
      .then(response => {
        return {
          error: false,
          message:"Succsss",
          data: response?.data?.data || []
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        return {
          error: true,
          data:[],
          message:errorMessage
        }
      })
}

//POST
export function createReligion(request) {
  return (dispatch) => {
    returnApiCallPromise("POST", religion, request)
      .then(response => {
        if (response.data.error) {
          dispatch({
            type: 'CREATE_RELIGION_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'CREATE_RELIGION_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'CREATE_RELIGION_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

export function updateReligion(request, religionId) {
  return (dispatch) => {
    returnApiCallPromise("PUT", religion+"/"+religionId , request)
      .then(response => {
        if (response.data.error) {
          dispatch({
            type: 'UPDATE_RELIGION_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'UPDATE_RELIGION_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'UPDATE_RELIGION_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}