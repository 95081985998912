import React from 'react';
import { statusForm, feesRecords, materialForm } from './formData';
import View from 'app/components/atoms/modalView/Modalview';
import CustomTable from 'app/components/atoms/custom-table/CustomTable';
import FormLayout from 'app/components/render/formlayout';
import SimpleCard from 'app/components/atoms/simple-card/SimpleCard';
import { FiEdit, FiTrash2 } from "react-icons/fi";
import { createKnowledge, getKnowledgeMaterial, updateKnowledge } from "app/api/KnowlegdeMaterialApi";
import { getStudentClassList, getAsyncStudentClassList } from "app/api/StudentListApi";
import { getBookType } from "app/api/BokkTypeApi";
import { getKnowledgeBookType, getAsyncKnowledgeBookType } from "app/api/KnowledgeBookTypeApi";
import { pagePermission, showMessage } from "app/utils/app-functions";
import Alert from 'app/components/atoms/alert';
import _ from "lodash";
import swal from "sweetalert2";
import { useState, useEffect, useRef } from "react";
import { uploadFiles, uploadStream } from "app/api/DocumentApi";
import { useDispatch, useSelector } from 'react-redux';
import formRender from 'app/components/render/formcomponent';
import { getKnowledgeMaterialType, getAsyncKnowledgeMaterialType } from "app/api/KnowledgeMaterialTypeApi";
import { imagePath } from "app/utils/constant";
import { MdRemoveRedEye } from 'react-icons/md';
import { asyncGetKnowledgeMaterialTypeInfo } from 'app/api/KnowledgeMaterialTypeApi';
import { asyncGetKnowledgeBookInfo } from 'app/api/BokkTypeApi';
import { useSidebar } from "app/components/sidebar/sidebarContext";
import { asyncGetKnowledgeBookType } from 'app/api/KnowledgeBookTypeApi';


let editData = false;

const KnowledgeData = ({ navigation }) => {
  const dispatch = useDispatch();
  const customerFormRef = useRef();
  const timeTableModel = useRef();

  const getKnowledgeBookTypeSuccess = useSelector((state) => state.knowledgeBookTypeReducer.getKnowledgeBookTypeSuccess);
  const getKnowledgeBookTypeList = useSelector((state) => state.knowledgeBookTypeReducer.getKnowledgeBookTypeList);
  const getKnowledgeBookTypeFailure = useSelector((state) => state.knowledgeBookTypeReducer.getKnowledgeBookTypeFailure);

  const getKnowledgeMaterialTypeSuccess = useSelector((state) => state.knowledgeMaterialTypeReducer.getKnowledgeMaterialTypeSuccess);
  const getKnowledgeMaterialTypeList = useSelector((state) => state.knowledgeMaterialTypeReducer.getKnowledgeMaterialTypeList);
  const getKnowledgeMaterialTypeFailure = useSelector((state) => state.knowledgeMaterialTypeReducer.getKnowledgeMaterialTypeFailure);

  const getKnowledgeSuccess = useSelector((state) => state.knowledgeReducer.getKnowledgeSuccess);
  const getKnowledgeList = useSelector((state) => state.knowledgeReducer.getKnowledgeList);
  const getKnowledgeFailure = useSelector((state) => state.knowledgeReducer.getKnowledgeFailure);

  const createKnowledgeSuccess = useSelector((state) => state.knowledgeReducer.createKnowledgeSuccess);
  const createKnowledgeData = useSelector((state) => state.knowledgeReducer.createKnowledgeData);
  const createKnowledgeFailure = useSelector((state) => state.knowledgeReducer.createKnowledgeFailure);

  const updateKnowledgeSuccess = useSelector((state) => state.knowledgeReducer.updateKnowledgeSuccess);
  const updateKnowledgeData = useSelector((state) => state.knowledgeReducer.updateKnowledgeData);
  const updateKnowledgeFailure = useSelector((state) => state.knowledgeReducer.updateKnowledgeFailure);

  const createFileSuccess = useSelector((state) => state.documentReducer.createFileSuccess);
  const createFileData = useSelector((state) => state.documentReducer.createFileData);
  const createFileFailure = useSelector((state) => state.documentReducer.createFileFailure);


  const getEnrollmentDetailsSuccess = useSelector((state) => state.enrollmentDetailsReducer.getEnrollmentDetailsSuccess);
  const getEnrollmentDetailsList = useSelector((state) => state.enrollmentDetailsReducer.getEnrollmentDetailsList);
  const getEnrollmentDetailsFailure = useSelector((state) => state.enrollmentDetailsReducer.getEnrollmentDetailsFailure);
  const enrollmentDetailsErrorMessage = useSelector((state) => state.enrollmentDetailsReducer.errorMessage);

  const getStudentClassListSuccess = useSelector((state) => state.studentListReducer.getStudentClassListSuccess);
  const getStudentClassListList = useSelector((state) => state.studentListReducer.getStudentClassListList);
  const getStudentClassListFailure = useSelector((state) => state.studentListReducer.getStudentClassListFailure);
  const studentClassListErrorMessage = useSelector((state) => state.studentListReducer.errorMessage);

  const getBookTypeSuccess = useSelector((state) => state.bookTypeReducer.getBookTypeSuccess);
  const getBookTypeList = useSelector((state) => state.bookTypeReducer.getBookTypeList);
  const getBookTypeFailure = useSelector((state) => state.bookTypeReducer.getBookTypeFailure);
  const bookTypeErrorMessage = useSelector((state) => state.bookReducer.errorMessage);

  const knowledgeMaterialErrorMessage = useSelector((state) => state.knowledgeReducer.errorMessage);
  const knowledgeMaterialTypeErrorMessage = useSelector((state) => state.knowledgeMaterialTypeReducer.errorMessage);
  const documentErrorMessage = useSelector((state) => state.documentReducer.errorMessage);
  const errorMessage = useSelector((state) => state.knowledgeBookTypeReducer.errorMessage);

  const [pageItems, setpageItems] = useState({
    "viewOption": false,
    "addOption": true,
    "updateOption": false,
    "deleteOption": false
  }
  );

  useEffect(() => {
    let data = pagePermission(pageItems, "Unit", "Knowledge")
    setpageItems(data)

  }, [navigation]);

  const column = [
    /*  {
         "dataField": "index",
         "text": "S.No",
         "sort": true,
         formatter: (cellContent, row, index) => (
             index + 1
         )
     }, */
    /*  {
         "dataField": "image",
         "text": "Logo",
         "sort": true,
         formatter: (cellContent, row, index, extraData) => {
             // console.log(row.image);
             return <img
                 className="avatar-lg active"
                 // src={`${row.image !=""} ? ${imagePath}${encodeURI(row.image || "")} : /assets/images/no-image.png`}
                 src={` ${imagePath}${encodeURI(row?.image || "")}` }
                 alt = ""
                 style={{ objectFit: "contain", height: "40px", width: "40px" }}
 
             />
         }
     }, */
    {
      "dataField": "name",
      "text": "Material Name",
      "sort": true,
    },
    {
      "dataField": "title",
      "text": "Title",
      "sort": true,
    },
    {
      "dataField": "shortDescription",
      "text": "Short Description",
      "sort": true,
    },
    {
      "dataField": "knowledgeMaterialTypeName",
      "text": "Material Type",
      "sort": true,
    },
    {
      dataField: "isActive",
      text: "Status",
      sort: true,
      align: "center",
      headerAlign: "center",
      formatter: (cellContent, row, index) => {
        const conInfo = { inputType: "status", status: cellContent }
        return formRender(null, null, conInfo, null);
      }
    },
    /*    {
           text: "Action",
           sort: false,
           align: "center",
           headerAlign: "center",
           formatter: (cellContent, row, index) => (
               <div >
                   <MdEdit
                       className="text-success cursor-pointer"
                       size={18}
                       onClick={() => onEditForm(row, index)}
                   ></MdEdit>
                   <MdRemoveRedEye
                       className="text-primary cursor-pointer"
                       size={18}
                       onClick={() => onDocView(row, index)}
                   ></MdRemoveRedEye>
                   <MdDelete
                       className="text-danger cursor-pointer"
                       size={18}
                       onClick={() => {
                           swal
                               .fire({
                                   title: "Are you sure?",
                                   text: "You won't be able to revert this!",
                                   icon: "warning",
                                   type: "question",
                                   showCancelButton: true,
                                   confirmButtonColor: "#3085d6",
                                   cancelButtonColor: "#d33",
                                   confirmButtonText: "Yes, delete it!",
                                   cancelButtonText: "No",
                               })
                               .then((result) => {
                                   if (result.value) {
 
                                       onDeleteItem(row, index);
                                   } else {
                                       swal.fire({
                                           title: "Cancelled!",
                                           text: "Permission denied.",
                                           type: "error",
                                           icon: "error",
                                           timer: 1500,
                                       });
                                   }
                               });
                       }}
                   ></MdDelete>
               </div>
           )
       }, */
  ]
  if (pageItems.delete || pageItems.update || pageItems.view) {
    column.push({
      text: "Action",
      sort: false,
      align: "center",
      headerAlign: "center",
      formatter: (cellContent, row, index) => (
        <div className="icon-container">
          {pageItems.update && <FiEdit
            className="text-primary cursor-pointer"
            size={18}
            onClick={() => onEditForm(row, index)}
          ></FiEdit>}
          {pageItems.view && <MdRemoveRedEye
            className="text-success cursor-pointer"
            size={18}
            onClick={() => onDocView(row, index)}
          ></MdRemoveRedEye>}
          {pageItems.delete && <FiTrash2
            className="text-danger cursor-pointer"
            size={18}
            onClick={() => {
              swal
                .fire({
                  title: "Are you sure?",
                  text: "You won't be able to revert this!",
                  icon: "warning",
                  type: "question",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes, delete it!",
                  cancelButtonText: "No",
                })
                .then((result) => {
                  if (result.value) {

                    onDeleteItem(row, index);
                  } else {
                    swal.fire({
                      title: "Cancelled!",
                      text: "Permission denied.",
                      type: "error",
                      icon: "error",
                      timer: 1500,
                    });
                  }
                });
            }}
          ></FiTrash2>}
        </div>
      )
    })
  }
  const [state, setState] = useState({
  });

  const [createModule, setCreateModule] = useState(false);
  const [typeModule, setTypeModule] = useState(false);
  const [loaderModule, setLoaderModule] = useState(false);
  const [timeTableModule, setTimeTableModule] = useState(false);
  const [editModule, setEditModule] = useState(false);
  const [viewModule, setViewModule] = useState(false);
  const [deleteModule, setDeleteModule] = useState(false);
  const [itemList, setItemList] = useState([]);
  const [selectedItem, setSelectedItem] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(false);
  const [errorState, setErrorState] = useState(false);

  const [fileState, fileSetState] = useState({
    file: '',
    type: '',
    isImage: false,
  });

  useEffect(() => {
    setLoaderModule(true)
    const requestData = {
      isActive: "true"
    }
    const req1 = {
      isActive: "true",
    };
    if (global.roleId == 5) {
      req1.empCode = global.UserInfo?.userCode || '';
    }
    // dispatch(getKnowledgeMaterialType())
    dispatch(getKnowledgeMaterial(requestData))
    // dispatch(getStudentClassList(req1))
    // dispatch(getBookType(requestData))
    // dispatch(getKnowledgeBookType(requestData))
    listFetching()
  }, [navigation]);

  let userInfo = window.localStorage.getItem("userInfo");
  let parsedData = JSON.parse(userInfo);
  const listFetching = async () => {

    const req1 = {
      isActive: "true",
    };
    let knowledgeBookType = await asyncGetKnowledgeBookType(req1);

    if (parsedData?.[0]?.roleId == 5) {
      req1.empCode = parsedData?.[0]?.userCode || ''
    }
    let enrollList = await getAsyncStudentClassList();
    let knowledgeMaterialType = await asyncGetKnowledgeMaterialTypeInfo(req1);

    setState({
      ...state,
      materialTypeList: knowledgeMaterialType?.data || [],
      enrollmentDetailsList: enrollList?.data || [],
      knowledgeBookTypeList: knowledgeBookType?.data || [],
      /* batchList: batchList?.data || [],
      feesTypeList: feesTypeList?.data || [],
 */
    })

  }

  useEffect(() => {
    if (getKnowledgeSuccess) {
      /*  setState({
           ...state,
           materialList: [...getKnowledgeList]
       }) */
      setItemList(getKnowledgeList)

      setLoaderModule(false)
      dispatch({ type: "RESET_GET_KNOWLEDGE_MATERIAL" })
    } else if (getKnowledgeFailure) {
      setItemList([])
      showMessage("warning", knowledgeMaterialErrorMessage?.message)
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_KNOWLEDGE_MATERIAL" })
    }
  }, [getKnowledgeSuccess, getKnowledgeFailure]);

  /* useEffect(() => {
      if (getEnrollmentDetailsSuccess) {
          setState({
              ...state,
              enrollmentDetailsList: [...getEnrollmentDetailsList]
          })
          setLoaderModule(false)
          dispatch({ type: "RESET_GET_ENROLLMENT_DETAILS" })

      } else if (getEnrollmentDetailsFailure) {
          setState({
              ...state,
              enrollmentDetailsList: []
          })
          showMessage("warning", enrollmentDetailsErrorMessage?.message)
          setLoaderModule(false)
          dispatch({ type: "RESET_GET_ENROLLMENT_DETAILS" })
      }
  }, [getEnrollmentDetailsSuccess, getEnrollmentDetailsFailure]);
  
*/

  useEffect(() => {
    if (getKnowledgeBookTypeSuccess) {
      setState({
        ...state,
        knowledgeBookTypeList: getKnowledgeBookTypeList
      })
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_KNOWLEDGE_BOOK_TYPE" })
    } else if (getKnowledgeBookTypeFailure) {
      setState({
        ...state,
        knowledgeBookTypeList: []
      })
      showMessage("warning", errorMessage?.message)
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_KNOWLEDGE_BOOK_TYPE" })
    }
  }, [getKnowledgeBookTypeSuccess, getKnowledgeBookTypeFailure]);
  useEffect(() => {
    if (getStudentClassListSuccess) {
      setState({
        ...state,
        enrollmentDetailsList: getStudentClassListList
      })
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_STUDENT_CLASS_LIST" })
    } else if (getStudentClassListFailure) {
      setState({
        ...state,
        enrollmentDetailsList: []
      })
      showMessage("warning", studentClassListErrorMessage?.message)
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_STUDENT_CLASS_LIST" })
    }
  }, [getStudentClassListSuccess, getStudentClassListFailure]);

  useEffect(() => {
    if (getKnowledgeMaterialTypeSuccess) {
      // alert(JSON.stringify(getKnowledgeMaterialTypeList))
      setState({
        ...state,
        materialTypeList: [...getKnowledgeMaterialTypeList]
      })
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_KNOWLEDGE_TYPE_MATERIAL" })
    } else if (getKnowledgeMaterialTypeFailure) {
      setState({
        ...state,
        unitTypeList: []
      })
      showMessage("warning", knowledgeMaterialTypeErrorMessage?.message)
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_KNOWLEDGE_TYPE_MATERIAL" })
    }
  }, [getKnowledgeMaterialTypeSuccess, getKnowledgeMaterialTypeFailure]);

  useEffect(() => {
    if (getBookTypeSuccess) {
      setState({
        ...state,
        bookTypeList: getBookTypeList
      })
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_BOOK_TYPE" })
    } else if (getBookTypeFailure) {
      setState({
        ...state,
        bookTypeList: []
      })
      showMessage("warning", bookTypeErrorMessage?.message)
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_BOOK_TYPE" })
    }
  }, [getBookTypeSuccess, getBookTypeFailure]);

  useEffect(() => {
    if (createKnowledgeSuccess) {
      const temp_state = [...itemList, createKnowledgeData[0]];
      /* setState({
          ...state,
          unitFilterList: temp_state
      }) */
      setItemList(temp_state)
      closeModule()
      setLoaderModule(false)
      Alert({ description: 'Created Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
      dispatch({ type: "RESET_CREATE_KNOWLEDGE_MATERIAL" })
    } else if (createKnowledgeFailure) {
      setLoaderModule(false)
      showMessage("warning", knowledgeMaterialErrorMessage?.message)
      dispatch({ type: "RESET_CREATE_KNOWLEDGE_MATERIAL" })
    }
  }, [createKnowledgeSuccess, createKnowledgeFailure]);

  useEffect(() => {
    if (updateKnowledgeSuccess) {
      updateTable(updateKnowledgeData[0])
      dispatch({ type: "RESET_UPDATE_KNOWLEDGE_MATERIAL" })
    } else if (updateKnowledgeFailure) {
      setLoaderModule(false)
      showMessage("warning", knowledgeMaterialErrorMessage?.message)
      dispatch({ type: "RESET_UPDATE_KNOWLEDGE_MATERIAL" })
    }
  }, [updateKnowledgeSuccess, updateKnowledgeFailure]);

  useEffect(() => {
    if (createFileSuccess) {
      // const temp_state = [...itemList, createFileData[0]];
      // closeModule()
      // setLoaderModule(false)
      // showMessage("success", " Uploaded Successfully")
      dispatch({ type: "RESET_CREATE_FILE" })
    } else if (createFileFailure) {
      setLoaderModule(false)
      showMessage("warning", documentErrorMessage?.message)
      dispatch({ type: "RESET_CREATE_FILE" })
    }
  }, [createFileSuccess, createFileFailure]);

  const updateTable = (updatedItem) => {
    const temp_state = [...itemList];
    if (deleteModule) {
      let remainingList = _.remove(temp_state, function (n) {
        return n.knowledgeMaterialId != updatedItem.knowledgeMaterialId;
      });
      Alert({ description: 'Deleted Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
      setItemList(remainingList)
    } else {
      temp_state[selectedIndex] = updatedItem;
      Alert({ description: 'Updated Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
      setItemList(temp_state)
    }
    setLoaderModule(false)
    closeModule()
  }

  const closeModule = () => {
    editData = false;
    setCreateModule(false)
    setViewModule(false)
    setDeleteModule(false)
  }

  const resetState = () => {
    setState({
      ...state,
      title: '',
      name: '',
      url: '',
      knowledgeBookId: '',
      shortDescription: '',
      enrollmentId: '',
      knowledgeMaterialTypeId: '',
      file: "",
    })
  }

  const onCreateForm = () => {
    resetState()
    editData = false;
    setCreateModule(true)
    setTimeTableModule(false)
  }

  const onEditForm = (data, index) => {
    console.log(data)
    const temp_state = state?.enrollmentDetailsList || [];
    // const fiterEnrollmentData = (data?.enrollmentIds || "").split(",");
    const fiterEnrollmentData = data?.enrollmentIds?.split(",").map(Number) || [];

    const enrollList = temp_state.filter((item) => { return fiterEnrollmentData.includes((item.enrollmentId).toString()) });
    let id = data?.knowledgeMaterialTypeId || 0
    if (id == '1') {
      materialForm[0].formBody[0].formField[4].disabled = true;
      materialForm[0].formBody[0].formField[3].disabled = false;
      // name = "url"
    } else if (id == '2') {
      materialForm[0].formBody[0].formField[4].disabled = false;
      materialForm[0].formBody[0].formField[3].disabled = true;
    } else if (id == '3') {
      materialForm[0].formBody[0].formField[4].disabled = true;
      materialForm[0].formBody[0].formField[3].disabled = false;

    }
    setState({
      ...state,
      isUrl: id === '2' ? true : false,
    })
    let isUrl = isValidURL(data.material)
    setState({
      ...state,
      title: data?.title || '',
      name: data?.name || '',
      url: isUrl ? data?.material || '' : '',
      imageName: !isUrl ? data?.material || '' : '',
      shortDescription: data?.shortDescription || '',
      enrollmentId: fiterEnrollmentData,
      knowledgeMaterialTypeId: data?.knowledgeMaterialTypeId || '',
      "knowledgeBookId": data?.knowledgeBookId || 0,

    })
    editData = true
    setSelectedItem(data)
    setSelectedIndex(index)
    setCreateModule(true)
  }

  const onDeleteItem = (data, index) => {
    editData = false;
    setSelectedIndex(index)
    setSelectedItem(data)
    setDeleteModule(true)
  }

  const validateForm = async () => {
    const validatefom = customerFormRef.current.validateForm();
  }


  const onSubmitForm = () => {
    // return
    const filterEnrollmentIds = (state.enrollmentId || []).map((itm) => {
      return itm
    })
    console.log(state.isUrl ? (state?.url || state.imageName) : (state?.imageName || state.url))
    console.log(state.imageName)
    console.log(state.isUrl)
    const requestDate = {
      "title": state?.title || '',
      "name": state?.name || '',
      "material": state.isUrl ? (state?.url || state.imageName) : (state?.imageName || state.url),
      "shortDescription": state?.shortDescription || '',
      "enrollmentIds": filterEnrollmentIds.length ? filterEnrollmentIds.join(',') : 0,
      "knowledgeMaterialTypeId": state?.knowledgeMaterialTypeId || 0,
      "knowledgeBookId": state?.knowledgeBookId || 0,
    }


    if (errorState) {
      Alert({ description: 'Invalid Url.', type: 'error', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
      return
    }
    let current = state.file || ""
    let selectedMaterial = selectedItem?.material || ""
    if (current.name === selectedMaterial) {
      setState({
        ...state,
        file: ""
      })
    } else if (current == "") {
      setState({
        ...state,
        file: ""
      })

    } else {

      formDataAppend(state?.file || "")

    }

    //  return
    if (editData) {

      setLoaderModule(true)
      dispatch(updateKnowledge(requestDate, selectedItem.knowledgeMaterialId))
    } else if (deleteModule) {
      const deleteRequest = {
        isActive: false
      }
      setLoaderModule(true)
      dispatch(updateKnowledge(deleteRequest, selectedItem.knowledgeMaterialId))
    } else {
      setLoaderModule(true)
      dispatch(createKnowledge(requestDate))
    }
  }

  const onWriteDocument = (event) => {
    let file = event.target.files[0];
    let fileName = file?.name || "";
    const now = new Date();
    const fileExtension = fileName.split('.').pop();
    const year = now.getFullYear().toString();
    const uniqueIdentifier = Math.floor(1000 + Math.random() * 9000).toString();
    const day = now.getDate().toString().padStart(2, '0');
    const docName = `${year}${day}${uniqueIdentifier}.${fileExtension}`;
    const renamedFile = new File(
      [file],
      docName,
      {
        type: file?.type,
        name: docName,
        lastModified: file?.lastModified,
      }
    );
    console.log(renamedFile)
    setState({
      ...state,
      [event.target.name]: docName,
      file: renamedFile
    })

  };

  const data = materialForm

  const onSelectMaterialType = (event, field) => {
    let name = ''
    if (event == '1' || event == '3') {
      data[0].formBody[0].formField[4].disabled = true;
      data[0].formBody[0].formField[3].disabled = false;
      name = "url"
    } else if (event == '2') {
      data[0].formBody[0].formField[4].disabled = false;
      data[0].formBody[0].formField[3].disabled = true;
      name = "imageName"
    } else if (event == '3') {
      materialForm[0].formBody[0].formField[4].disabled = true;
      materialForm[0].formBody[0].formField[3].disabled = false;

    }
    setState({
      ...state,
      [name]: '',
      isUrl: event === '2' ? true : false,
      [field]: event
    })
  };


  const formDataAppend = (data) => {
    const formData = new FormData();
    formData.append('document', data);
    // return
    // if (state?.knowledgeMaterialTypeId == 3) {
    //   dispatch(uploadStream(formData, "knowledge_material"));
    // } else {
      dispatch(uploadFiles(formData, "knowledge_material"));
    // }
  }

  let isurl = ''
  function isValidURL(string) {
    try {
      isurl = new URL(string);
      return true;
    } catch (err) {
      return false;
    }
  }

  const onDocView = (data, index) => {
    let url;
    url = isValidURL(data?.material)
    if (data && !url) {
      let fileName = data?.material || '';
      const fileNameWithoutExt = fileName.split('.').slice(0, -1).join('.');
      const fileExtension = fileName.split('.').pop();
      const type = `${fileExtension}`;
      const file = `${imagePath}knowledge_material/${encodeURI(fileNameWithoutExt)}.${fileExtension}`;
      const imageFormats = ["png", "jpg", "jpeg", "bmp", "svg", "webp", "pdf"];
      const isImage = imageFormats.includes(type.toLowerCase());
      fileSetState({
        isDocument: isImage ? true : false,
        isVideo: type === 'mp4' ? true : false,
        file: file,
        type: type
      })
      console.log(fileState.file);
      setViewModule(true);
    } else if (url) {
      console.log(data?.material)
      fileSetState({
        isDocument: false,
        file: data?.material || ''
      })
      setViewModule(true);
    }
    else {
      console.log("File not found for ID:");
    }
  };

  const onClose = () => {
    setViewModule(false)
  }

  const onError = e => {
    console.log(e, "error in file-viewer");
  };
  const modelTitle = editData ? "Edit " : "Create ";
  const modelButtonLbl = editData ? "" : "Save"



  const getYouTubeEmbedURL = (url) => {
    try {
      const parsedURL = new URL(url);

      // Check for shortened YouTube URL (youtu.be)
      if (parsedURL.hostname === 'youtu.be') {
        const videoId = parsedURL.pathname.slice(1); // Extract the video ID from the path
        if (videoId) {
          return `https://www.youtube.com/embed/${videoId}`;
        }
      }

      // Handle standard YouTube URL
      const videoId = parsedURL.searchParams.get('v');
      if (videoId) {
        return `https://www.youtube.com/embed/${videoId}`;
      }

      // If no video ID is found, throw an error
      throw new Error('Invalid YouTube URL: Video ID not found');
    } catch (err) {
      console.error('Error generating embed URL:', err.message);
      return '';
    }
  };

  let url = fileState?.file || ''
  console.log(url)
  const embedURL = getYouTubeEmbedURL(url);

  const handleClick = (buttonName) => {
    if (buttonName == 'Add') {
      onCreateForm();
    }
  };
  const { sidebarOpen } = useSidebar();
  const handleUrlChange = (e) => {
    const url = e.target.value;


    // General URL pattern that accepts all valid URLs
    const urlPattern = /^(https?:\/\/)?([\w\d-]+\.)+[\w-]+(\/[\w\d.-]*)*(\?[\w\d=&.-]*)?$/;

    // Validate the URL
    const isValidUrl = urlPattern.test(url);

    if (url && !isValidUrl) {
      setErrorState(true); // Invalid URL
    } else {
      setErrorState(false); // Valid URL
    }

    setState({
      ...state,
      [e.target.name]: url,
    });
  };

  console.log('Embed URL:', embedURL);
  return (
    <div >
      <div style={sidebarOpen ? { marginLeft: "10px", marginTop: "-10px" } : { marginLeft: "10px", marginTop: "-50px" }}>

        <View isOpen={createModule} size={'md'} savetitle={modelButtonLbl} onClose={setCreateModule} onSubmit={validateForm} title={`${modelTitle}  Knowledge Material`}>
          <FormLayout ref={customerFormRef} defaultState={state} setDefaultState={setState} dynamicForm={materialForm} noOfColumn={2} onChangeCallback={{ "onSelectMaterialType": onSelectMaterialType, "onWriteDocument": onWriteDocument, "onHandleUrl": handleUrlChange }} onSubmit={onSubmitForm} />
        </View>
        <View centered={true} size={'sm'} isOpen={deleteModule} onClose={setDeleteModule} savetitle="Yes" onSubmit={onSubmitForm} title="Delete Class">
          <h6 className="text-capitalize mb-1">Are you sure ?</h6>
        </View>
        <View centered={true} size={'lg'} fullscreen={(fileState.isDocument) ? false : true} savetitle="Close" isOpen={viewModule} onClose={setViewModule} onSubmit={onClose} title="Knowledge Materials">
          <div className="overflow-auto" style={fileState.isDocument ? { height: "500px" } : { width: "auto", height: "auto" }}>
            {
              (fileState.isDocument || fileState.isVideo) ? (
                fileState.isDocument ? (
                  <object data={fileState?.file} type="application/pdf" width="100%" height="100%" allowFullScreen={true}>
                    <a href={fileState?.file}></a>
                  </object>
                ) : (
                  <video style={{ width: "auto", height: "auto" }} controls="controls">
                    <source src={fileState?.file} type="video/mp4" />
                    Your browser does not support the video
                  </video>
                )
              ) : (
                <iframe
                  width="100%"
                  height="500"
                  src={embedURL}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen={true}
                ></iframe>
              )
            }
          </div>
        </View>
        <SimpleCard >
          <CustomTable columns={column} data={itemList} buttonData={pageItems.create ? ['add'] : []} handleClick={handleClick} tableName="Knowledge Material" state={state} />
        </SimpleCard>
      </div>
    </div>
  )
}

export default KnowledgeData;