import * as yup from "yup";
import { Formik } from "formik";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import DateTime from "react-datetime";
import { Form, Button } from "react-bootstrap";
import { DateRange } from "react-date-range";
import SimpleCard from 'app/components/atoms/simple-card/SimpleCard'
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

import { MdEdit, MdDelete } from "react-icons/md";
import swal from "sweetalert2";
import _ from "lodash";
import { pagePermission, showMessage } from "app/utils/app-functions";
import { createUnitType, getUnitType, updateUnitType } from "app/api/UnitTypeApi";
import FormLayout from "app/components/render/formlayout";
import View from "app/components/atoms/modalView/Modalview";
import CustomTable from "app/components/atoms/custom-table/CustomTable";
import formRender from "app/components/render/formcomponent";
import { unitTypeForm } from "./formData";
import Alert from "app/components/atoms/alert";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import { useSidebar } from "app/components/sidebar/sidebarContext";


let editData = false;

const UnitType = ({ navigation }) => {
    const customerFormRef = useRef();
    const dispatch = useDispatch();


    const getUnitTypeSuccess = useSelector((state) => state.unitTypeReducer.getUnitTypeSuccess);
    const getUnitTypeList = useSelector((state) => state.unitTypeReducer.getUnitTypeList);
    const getUnitTypeFailure = useSelector((state) => state.unitTypeReducer.getUnitTypeFailure);

    const createUnitTypeSuccess = useSelector((state) => state.unitTypeReducer.createUnitTypeSuccess);
    const createUnitTypeData = useSelector((state) => state.unitTypeReducer.createUnitTypeData);
    const createUnitTypeFailure = useSelector((state) => state.unitTypeReducer.createUnitTypeFailure);

    const updateUnitTypeSuccess = useSelector((state) => state.unitTypeReducer.updateUnitTypeSuccess);
    const updateUnitTypeData = useSelector((state) => state.unitTypeReducer.updateUnitTypeData);
    const updateUnitTypeFailure = useSelector((state) => state.unitTypeReducer.updateUnitTypeFailure);

    const unitErrorMessage = useSelector((state) => state.unitTypeReducer.errorMessage);

    const [pageItems, setpageItems] = useState({
        "view": false,
        "create": true,
        "update": false,
        "delete": false
    }
    );

    useEffect(() => {
        let data = pagePermission(pageItems, "Super Admin", "Unit Type")
        setpageItems(data)

    }, [navigation]);

    const column = [
        {
            "dataField": "sNo",
            "text": "S.No",
            "sort": true,
            formatter: (cellContent, row, index) => (
                index + 1
            )
        },
        {
            "dataField": "unitTypeName",
            "text": "Unit Type",
            "sort": true
        },
        {
            "dataField": "unitTypeCode",
            "text": "Unit Type Code",
            "sort": true
        },
        {
            dataField: "isActive",
            text: "Status",
            sort: true,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => {
                const conInfo = { inputType: "status", status: cellContent }
                return formRender(null, null, conInfo, null);
            }
        },

    ]

    if (pageItems.delete || pageItems.update) {
        column.push({
            text: "Action",
            sort: false,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => (
                <div className="icon-container">
                    {pageItems.update && <FiEdit
                        className="text-primary cursor-pointer"
                        size={18}
                        onClick={() => onEditForm(row, index)}
                    ></FiEdit>}
                    {pageItems.delete && <FiTrash2
                        className="text-danger cursor-pointer"
                        size={18}
                        onClick={() => {
                            swal
                                .fire({
                                    title: "Are you sure?",
                                    text: "You won't be able to revert this!",
                                    icon: "warning",
                                    type: "question",
                                    showCancelButton: true,
                                    confirmButtonColor: "#3085d6",
                                    cancelButtonColor: "#d33",
                                    confirmButtonText: "Yes, delete it!",
                                    cancelButtonText: "No",
                                })
                                .then((result) => {
                                    if (result.value) {

                                        onDeleteItem(row, index);
                                    } else {
                                        swal.fire({
                                            title: "Cancelled!",
                                            text: "Permission denied.",
                                            type: "error",
                                            icon: "error",
                                            timer: 1500,
                                        });
                                    }
                                });
                        }}
                    ></FiTrash2>}
                </div>
            )
        })
    }
    const [state, setState] = useState({
    });

    const [createModule, setCreateModule] = useState(false);
    const [loaderModule, setLoaderModule] = useState(false);
    const [editModule, setEditModule] = useState(false);
    const [viewModule, setViewModule] = useState(false);
    const [deleteModule, setDeleteModule] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [selectedItem, setSelectedItem] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);

    useEffect(() => {
        setLoaderModule(true)
        const requestData = {
            isActive: "true"
        }
        dispatch(getUnitType(requestData))
    }, [navigation]);

    useEffect(() => {
        if (getUnitTypeSuccess) {
            setItemList(getUnitTypeList)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_UNIT_TYPE" })
        } else if (getUnitTypeFailure) {
            setItemList([])
            showMessage("warning", unitErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_UNIT_TYPE" })
        }
    }, [getUnitTypeSuccess, getUnitTypeFailure]);

    useEffect(() => {
        if (createUnitTypeSuccess) {
            const temp_state = [...itemList, createUnitTypeData[0]];
            setItemList(temp_state)
            closeModule()
            setLoaderModule(false)
            showMessage("success", "Created Successfully")
            Alert({  description: 'Created Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            dispatch({ type: "RESET_CREATE_UNIT_TYPE" })
        } else if (createUnitTypeFailure) {
            setLoaderModule(false)
            showMessage("warning", unitErrorMessage?.message)
            dispatch({ type: "RESET_CREATE_UNIT_TYPE" })
        }
    }, [createUnitTypeSuccess, createUnitTypeFailure]);

    useEffect(() => {
        if (updateUnitTypeSuccess) {
            updateTable(updateUnitTypeData[0])
            dispatch({ type: "RESET_UPDATE_UNIT_TYPE" })
        } else if (updateUnitTypeFailure) {
            setLoaderModule(false)
            showMessage("warning", unitErrorMessage?.message)
            dispatch({ type: "RESET_UPDATE_UNIT_TYPE" })
        }
    }, [updateUnitTypeSuccess, updateUnitTypeFailure]);

    const updateTable = (updatedItem) => {
        const temp_state = [...itemList];
        if (deleteModule) {
            let remainingList = _.remove(temp_state, function (n) {
                return n.unitTypeId != updatedItem.unitTypeId;
            });
            Alert({  description: 'Deleted Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(remainingList)
        } else {
            temp_state[selectedIndex] = updatedItem;
            Alert({  description: 'Updated Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(temp_state)
        }
        setLoaderModule(false)
        closeModule()
    }

    const closeModule = () => {
        editData = false;
        setCreateModule(false)
        setDeleteModule(false)
    }

    const resetState = () => {
        setState({
            ...state,
            unitTypeName: "",
            unitTypeCode: "",
        })
    }

    const onCreateForm = () => {
        resetState()
        editData = false;
        setCreateModule(true)
    }

    const onEditForm = (data, index) => {
        setState({
            ...state,
            unitTypeCode: data?.unitTypeCode || "",
            unitTypeName: data?.unitTypeName || ""
        })
        editData = true;
        setSelectedIndex(index)
        setSelectedItem(data)
        setCreateModule(true)
    }

    const onDeleteItem = (data, index) => {
        editData = false;
        setSelectedIndex(index)
        setSelectedItem(data)
        setDeleteModule(true)
    }

    const validateForm = async () => {
        const validatefom = customerFormRef.current.validateForm();

    }

    const onSubmitForm = () => {
        const requestDate = {
            unitTypeCode: unitTypeCode,
            unitTypeName: unitTypeName,
        }
        if (editData) {
            // setLoaderModule(true)  
            dispatch(updateUnitType(requestDate, selectedItem.unitTypeId))
        } else if (deleteModule) {
            const deleteRequest = {
                isActive: false
            }
            // setLoaderModule(true)  
            dispatch(updateUnitType(deleteRequest, selectedItem.unitTypeId))
        } else {
            // setLoaderModule(true)  
            dispatch(createUnitType(requestDate))
        }
    }

    const {
        unitTypeCode,
        unitTypeName
    } = state;

    const handleAddButtonClick = () => {
        setCreateModule(true);
    };

    const handleCloseModal = () => {
        setCreateModule(false);
    };

    const modelTitle = editData ? "Edit " : "Create ";
    const modelButtonLbl = editData ? "" : "Save";

    const handleClick = (buttonName) => {
        if (buttonName == 'Add') {
            onCreateForm();
        }
    };
    const { sidebarOpen } = useSidebar();

    return (
        <div>
            {/* <SmallLoader value={loaderModule} /> */}
            <div style={sidebarOpen ? { marginLeft: "10px", marginTop: "-10px" } : { marginLeft: "10px", marginTop: "-50px" }}>

                <View isOpen={createModule} size="sm" savetitle={modelButtonLbl} onClose={handleCloseModal} onSubmit={validateForm} title={`${modelTitle} Unit Type`}>
                    <FormLayout ref={customerFormRef} defaultState={state} setDefaultState={setState} dynamicForm={unitTypeForm} noOfColumn={1} onSubmit={onSubmitForm} />
                </View>


                <View centered={true} size={'sm'} isOpen={deleteModule} onClose={setDeleteModule} savetitle="Yes" onSubmit={onSubmitForm} title="Delete Batch">
                    <h6 className="text-capitalize mb-1">Are you sure ?</h6>
                </View>

                <SimpleCard >

                    <CustomTable buttonData={['add']} handleClick={handleClick} tableName={"Unit Type"} columns={column} data={itemList} initialPage={1} />
                </SimpleCard>

            </div>
        </div>
    )
};

export default UnitType;
