import React, { useState, useEffect } from "react";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import HomePage from "./app/pages/HomePage";
import RecentActivity from "./app/pages/RecentActivity";
import Reportwizard from "./app/pages/Reportwizard";
import UnitType from "./app/pages/unitType";
// import StudentFees from "./app/pages/student-fee-payment";  
import Shift from "./app/pages/shift";
import Qualification from "./app/pages/qualification";
import Department from "./app/pages/department";
import Designation from "./app/pages/designation";
import LeaveType from "./app/pages/leave-type";
import Batch from "./app/pages/masters/batch";
import Section from "./app/pages/masters/section";
import Class from "./app/pages/masters/class";
import Course from "./app/pages/masters/course";
import ExamType from "./app/pages/masters/exam-type";
import Period from "./app/pages/masters/period";
import Subject from "./app/pages/masters/subject";
import SubjectTeachers from "./app/pages/masters/subject-teachers";
import SubjectEnrollment from "./app/pages/masters/subject-enrollment";
import Book from "./app/pages/masters/book";
import BookType from "./app/pages/masters/book-type";
import KnowledgeBookType from "./app/pages/masters/knowledge-book-type";
import HomeWork from "./app/pages/homework";
import Exam from "./app/pages/exam";
import Marksheet from "./app/pages/marksheet";
import Timetable from "./app/pages/time-table";
import StudentAttendance from "./app/pages/student-attedance";
import StudentList from "./app/pages/student-list";
import StudentLeave from "./app/pages/student-leave";
import Circular from "./app/pages/circular";
import Loader from "./app/components/atoms/loader/Loader";
import StudentFees from "./app/pages/student-fee-payment";
import StudentAnalytics from "./app/pages/student-analytics";
import FeesEnrollment from "./app/pages/fees-enrollment";
import VisitorType from "./app/pages/visitor-type";
import Visitor from "./app/pages/visitor";
import VisitorLog from "./app/pages/visitor-log";
import VisitorHistory from "./app/pages/visitor-history";
import PettyCash from "./app/pages/petty-cash";
import Enquiry from "./app/pages/enquiry";
import KnowledgeData from "./app/pages/knowledge-material";
import EmployeeStatus from "./app/pages/employee-status";
import EmployeePromotion from "./app/pages/employee-promotion";
import EmployeeLeave from "./app/pages/employee-leave";
import EmployeeAttendance from "./app/pages/employee-attendance";
import Syllabus from "./app/pages/syllabus";
import EventManagement from "./app/pages/event-management";
import EmployeeReport from "./app/pages/employe-report";
import AttendanceReport from "./app/pages/attendance-report";
import Holiday from "./app/pages/settings/holidays";
import SchoolInfo from "./app/pages/settings/school-info";
import LeaveSettings from "./app/pages/settings/leave-settings";
import Material from "./app/pages/inventory/material";
import MaterialType from "./app/pages/inventory/material-type";
import CreditTerm from "./app/pages/inventory/credit-term";
import Warehouse from "./app/pages/inventory/warehouse";
import Location from "./app/pages/inventory/location";
import Supplier from "./app/pages/inventory/supplier";
import TaxCode from "./app/pages/inventory/tax-code";
import TaxConfig from "./app/pages/inventory/tax-config";
import StockInventory from "./app/pages/inventory/stock-inventory";
import Purchase from "./app/pages/inventory/purchase";
import Unit from "./app/pages/unit";
import CreateQuiz from "./app/pages/create-quiz";
import StudentAdmission from "./app/pages/student-admission";
import GoodsReceived from "./app/pages/inventory/goods-received";
import InventoryTransfer from "./app/pages/inventory/inventory-transfer";
import InventoryAdjustment from "./app/pages/inventory/inventory-adjustment";
import Quotation from "./app/pages/inventory/quotation";
import Sales from "./app/pages/inventory/sales";
import Delivery from "./app/pages/inventory/delivery";
import Invoice from "./app/pages/inventory/invoice";
import Employee from "./app/pages/employe";
import HomeWorkType from "app/pages/masters/homework-type";
import FeesType from "app/pages/fees-type";
import DailyExamMarksheet from "app/pages/daily-exam-marksheet";
import Role from "app/pages/masters/role";
import Dashboard from "app/pages/dashboard";
// import sessionsRoutes from "app/views/sessions/sessionsRoutes";

import { AuthProvider } from "app/contexts/JWTAuthContext";
import { useAuth } from "app/contexts/AuthContext";
import Loading from "app/components/atoms/loading";
import OtherLeaves from "app/pages/settings/other-leaves";
const AppRoutes = () => {
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const handleStart = () => setLoading(true);
        const handleStop = () => setLoading(false);

        handleStart();

        const timer = setTimeout(handleStop, 1000);  // You can adjust the timeout duration

        return () => {
            clearTimeout(timer);  
        };
    }, [location]);

    const { login, isAuthenticated } = useAuth();

    return (
        <>
            {/* {loadng && <Loader color="#6C63FF" size="12px" speed="1.5s" />} Show loader when loading is true */}
            <Loading status={loading} />
            <Routes>
                {/* <Route path="/customTable" element={<HomePage />} /> */}
                {/* <Route path="/reportwizard" element={<Reportwizard />} /> */}
                <Route path="/student-fees-payment" element={<StudentFees />} />
                <Route path="/student-analytics" element={<StudentAnalytics />} />
                <Route path="/" element={<Dashboard />} />
                <Route path="/other-leaves" element={<OtherLeaves />} />
                <Route path="/unit-type" element={<UnitType />} />
                <Route path="/shift" element={<Shift />} />
                <Route path="/qualification" element={<Qualification />} />
                <Route path="/department" element={<Department />} />
                <Route path="/designation" element={<Designation />} />
                <Route path="/leave-type" element={<LeaveType />} />
                {/* <Route path="/createFees" element={< />} /> */}
                <Route path="/batch" element={<Batch />} />
                <Route path="/section" element={<Section />} />
                <Route path="/class" element={<Class />} />
                <Route path="/course" element={<Course />} />
                <Route path="/exam-type" element={<ExamType />} />
                <Route path="/period" element={<Period />} />
                <Route path="/subject" element={<Subject />} />
                <Route path="/book" element={<Book />} />
                <Route path="/subject-teachers" element={<SubjectTeachers />} />
                <Route path="/book-type" element={<BookType />} />
                <Route path="/subject-enrollment" element={<SubjectEnrollment />} />
                <Route path="/knowledge-book" element={<KnowledgeBookType />} />
                <Route path="/home-work" element={<HomeWork />} />
                <Route path="/exam" element={<Exam />} />
                <Route path="/mark-sheet" element={<Marksheet />} />
                <Route path="/time-table" element={<Timetable />} />
                <Route path="/student-attendance" element={<StudentAttendance />} />
                <Route path="/student-list" element={<StudentList />} />
                <Route path="/student-leave" element={<StudentLeave />} />
                <Route path="/circular" element={<Circular />} />
                <Route path="/fees-enrollment" element={<FeesEnrollment />} />
                <Route path="/visitor-type" element={<VisitorType />} />
                <Route path="/visitor" element={<Visitor />} />
                <Route path="/visitor-log" element={<VisitorLog />} />
                <Route path="/visitor-history" element={<VisitorHistory />} />
                <Route path="/pettyCash" element={<PettyCash />} />
                <Route path="/enquiry" element={<Enquiry />} />
                <Route path="/knowledge-material-list" element={<KnowledgeData />} />
                <Route path="/employee-status" element={<EmployeeStatus />} />
                <Route path="/employee-promotion" element={<EmployeePromotion />} />
                <Route path="/employee-leave" element={<EmployeeLeave />} />
                <Route path="/employee-attendance" element={<EmployeeAttendance />} />
                <Route path="/syllabus" element={<Syllabus />} />
                <Route path="/event" element={<EventManagement />} />
                <Route path="/employee-report" element={<EmployeeReport />} />
                <Route path="/employee-attendance-report" element={<AttendanceReport />} />
                <Route path="/holidays" element={<Holiday />} />
                <Route path="/school-info" element={<SchoolInfo />} />
                <Route path="/leave-settings" element={<LeaveSettings />} />
                <Route path="/material" element={<Material />} />
                <Route path="/material-type" element={<MaterialType />} />
                <Route path="/credit-term" element={<CreditTerm />} />
                <Route path="/warehouse" element={<Warehouse />} />
                <Route path="/location" element={<Location />} />
                <Route path="/supplier" element={<Supplier />} />
                <Route path="/tax-code" element={<TaxCode />} />
                <Route path="/tax-config" element={<TaxConfig />} />
                <Route path="/stock-inventory" element={<StockInventory />} />
                <Route path="/purchase" element={<Purchase />} />
                <Route path="/unit" element={<Unit />} />
                <Route path="/create-Quiz" element={<CreateQuiz />} />
                <Route path="/admission" element={<StudentAdmission />} />
                <Route path="/goods-received" element={<GoodsReceived />} />
                <Route path="/inventory-transfer" element={<InventoryTransfer />} />
                <Route path="/inventory-adjustment" element={<InventoryAdjustment />} />
                <Route path="/quotation" element={<Quotation />} />
                <Route path="/sales" element={<Sales />} />
                <Route path="/delivery" element={<Delivery />} />
                <Route path="/invoice" element={<Invoice />} />
                <Route path="/employee" element={<Employee />} />
                <Route path="/homework-type" element={<HomeWorkType />} />
                <Route path="/fees-type" element={<FeesType />} />
                <Route path="/daily-exam-mark-sheet" element={<DailyExamMarksheet />} />
                <Route path="/role" element={<Role />} />
            </Routes>
        </>
    );
};

export default AppRoutes;
