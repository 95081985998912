
const periodTimeForm = [
    {
        title: "Basic Information",
        formBody : [
            {
                formField: [
                    {
                        label: "Period Title",
                        inputType: "text",
                        placeholder: "Enter Period Title",
                        fieldName:"periodTitle",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                   
                    {
                        label: "From Time",
                        inputType: "time",
                        type: "time",
                        fieldName: "fromTime",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                   
                   
                    {
                        label: "To Time",
                        inputType: "time",
                        type: "time",
                        fieldName: "toTime",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        description: "Is Special",
                        inputType: "checkbox",
                        //placeholder: "true",
                        fieldName:"isSpecial",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    },
                    {
                        description: "Interval / Lunch",
                        inputType: "checkbox",
                        //placeholder: "true",
                        fieldName:"isBreak",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    }
                                                                     
                ]
            }
        ]
    }
];

export {
    periodTimeForm
};