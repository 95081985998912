import { returnApiCallPromise } from './ApiContent';
import { employeeProof } from './APIContainer';

//DELETE
export function deleteEmployeeProof(request) {
  const requestObj = request ? { params: request } : "";
  return (dispatch) => {
    returnApiCallPromise("DELETE", employeeProof, requestObj)
      .then(response => {
        dispatch({
          type: 'DELETE_EMPLOYEE_PROOF_SUCCESS',
          payload: response.data
        })
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'DELETE_EMPLOYEE_PROOF_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

//GET
export function getEmployeeProof(request) {
  const requestObj = request ? { params: request } : "";
  return (dispatch) => {
    returnApiCallPromise("GET",employeeProof, requestObj)
      .then(response => {
        dispatch({
          type: 'GET_EMPLOYEE_PROOF_SUCCESS',
          payload: response.data
        })
      }).catch(error => {
        
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'GET_EMPLOYEE_PROOF_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

//POST
export function createEmployeeProof(request) {

  return (dispatch) => {
    returnApiCallPromise("POST",employeeProof, request)
      .then(response => {
        if (response.data.error) {
          dispatch({
            type: 'CREATE_EMPLOYEE_PROOF_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'CREATE_EMPLOYEE_PROOF_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'CREATE_EMPLOYEE_PROOF_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

export function updateEmployeeProof(request, employeeProofId) {
  return (dispatch) => {
    returnApiCallPromise("PUT",employeeProof+"/"+employeeProofId, request)
      .then(response => {

        if (response.data.error) {
          dispatch({
            type: 'UPDATE_EMPLOYEE_PROOF_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'UPDATE_EMPLOYEE_PROOF_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'UPDATE_EMPLOYEE_PROOF_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}