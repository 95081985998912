const locationForm = [{

    title: "Basic Information",
    formBody: [
        {
            formField: [
                {
                    label:"Location Name",
                    inputType: "text",
                    placeholder: 'Location name',
                    fieldName: "locationId",
                    defaultValue: "",
                    config: {
                        required: "required",
                    },
                },
                {
                    label:"Description",
                    inputType: "text",
                    placeholder: 'Description',
                    fieldName: "description",
                    defaultValue: "",
                    config: {
                        required: "required",
                    },
                },
               
            ]
        },


    ]
}]

export{locationForm}
