import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { asyncGetBestStudentList } from 'app/api/StudentPerformanceApi';
import { lightTheme } from 'styles/theme';
import Loading from "app/components/atoms/loading";

const Item = styled(Paper)(({ theme }) => ({

  backgroundColor: '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  ...(theme.palette.mode === 'dark' && {
    backgroundColor: '#1A2027',
  }),
}));

const ColumnsGrid = ({ counts }) => {
  const [studentData, setStudentData] = useState([]);
  const [loaderModule, setLoaderModule] = useState(false);

  const listFetching = async () => {
    const studentList = await asyncGetBestStudentList();
    const studentData = studentList?.data || [];
    processStudentData(studentData);
    console.log(studentData)

  };

  useEffect(() => {
    setLoaderModule(true);

    // Dispatch actions and stop loader after 3 seconds
    const timer = setTimeout(() => {
      const requestData = { isActive: "true" };

      listFetching()
      // Stop loader after dispatching actions
      setLoaderModule(false);
    }, 2000);

    // Cleanup function to clear the timeout on unmount
    return () => clearTimeout(timer);
  }, []);




  const currentYear = new Date().getFullYear();

  // Generate data for the current year and previous 4 years
  const years = Array.from({ length: 5 }, (_, i) => currentYear - i); // [2025, 2024, 2023, 2022, 2021]
  const studentStrength = [250, 240, 230, 220, 210]; // Example data for the last 5 years
  let boysCount = parseInt(counts.boysCount)
  let girlsCount = parseInt(counts.girlsCount)
  let othersCount = counts.othersCount
  // Options for the pie chart
  const pieChartOptions = {
    chart: {
      type: 'pie',
    },
    title: {
      text: 'Students Overview',
      style: {
        color: lightTheme.primaryColor,
        fontWeight: 'normal',

      },
    },
    credits: {
      enabled: false,  // Disable Highcharts credits
    },
    series: [
      {
        name: 'Count',
        data: [
          { name: 'Boys Strength', y: parseInt(boysCount) },
          { name: 'Girls Strength', y: parseInt(girlsCount) }
        ],
      },
    ],
  };

  const processStudentData = (data) => {
    const allStudents = Object.keys(data).flatMap((group) =>
      data[group].map((student) => ({
        studentName: student.studentName,
        group: group,
        averagePercentage: parseFloat(student.averagePercentage), // Ensure it's a number
      }))
    );
    setStudentData(allStudents);
  };


  const columnChartOptions = {
    chart: {
      type: 'column',
    },
    title: {
      text: 'Top Performing Students',
      style: {
        color: lightTheme.primaryColor,
        fontWeight: 'normal',
      },
    },
    xAxis: {
      categories: studentData.map((student) => `${student.studentName} (${student.group})`), // Display group alongside the student name
      title: {
        text: 'Student Names and Groups',
      },
      labels: {
        rotation: -45, // Rotate labels to fit the names
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Average Percentage',
      },
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: 'Average Percentage',
        data: studentData.map((student) => student.averagePercentage),
        color: '#007bff',
      },
    ],
  };


  return (
    <Box sx={{ flexGrow: 1 }}>
      <Loading status={loaderModule} />

      <Grid container spacing={2} columns={16}>
        {/* Pie chart */}
        <Grid item xs={8}>
          <Item>
            <HighchartsReact highcharts={Highcharts} options={pieChartOptions} />
          </Item>
        </Grid>

        {/* Students Performance */}
        <Grid item xs={8}>
          <Item>
            <HighchartsReact highcharts={Highcharts} options={columnChartOptions} />

          </Item>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ColumnsGrid;
