import React from 'react';
import { statusForm, feesRecords, visitorForm } from './formData';
import View from 'app/components/atoms/modalView/Modalview';
import CustomTable from 'app/components/atoms/custom-table/CustomTable';
import FormLayout from 'app/components/render/formlayout';
import SimpleCard from 'app/components/atoms/simple-card/SimpleCard';
import { FiEdit, FiTrash2 } from "react-icons/fi";
import formRender from "app/utils/formcomponent";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { createVisitor, getVisitor, updateVisitor } from "app/api/VisitorApi";
import { showMessage, pagePermission, dateConversion } from "app/utils/app-functions";
// import AppImageCropper from "app/components/AppImageCropper";
import { getVisitorType, } from "app/api/VisitorTypeApi";
import { uploadFiles } from "app/api/DocumentApi"
import { getEmployee } from "app/api/EmployeeApi";
import { getProofType } from "app/api/ProofTypeApi";
import { imagePath, visitorpath } from "app/utils/constant";
import { getVisitorName } from "app/api/VisitorNameApi";
import swal from "sweetalert2";
import _ from "lodash";
import AppImageCropper from 'app/components/atoms/appimageCropper/AppImageCropper';
import moment from 'moment';
import { timeConversion } from 'app/utils/app-functions';
import Alert from 'app/components/atoms/alert';
import dayjs from 'dayjs';
import { useSidebar } from "app/components/sidebar/sidebarContext";
import { asyncGetProofInfo } from 'app/api/ProofTypeApi';
import { asyncGetGenderInfo } from 'app/api/GenderApi';
import { asyncGetEmployeeInfo } from 'app/api/EmployeeApi';
import { asyncGetvisitorType } from 'app/api/VisitorTypeApi';


let editData = false
const Visitor = ({ navigation }) => {

  const dispatch = useDispatch();
  const customerFormRef = useRef();

  const getVisitorSuccess = useSelector((state) => state.visitorReducer.getVisitorSuccess);
  const getVisitorList = useSelector((state) => state.visitorReducer.getVisitorList);
  const getVisitorFailure = useSelector((state) => state.visitorReducer.getVisitorFailure);

  const createVisitorSuccess = useSelector((state) => state.visitorReducer.createVisitorSuccess);
  const createVisitorData = useSelector((state) => state.visitorReducer.createVisitorData);
  const createVisitorFailure = useSelector((state) => state.visitorReducer.createVisitorFailure);

  const updateVisitorSuccess = useSelector((state) => state.visitorReducer.updateVisitorSuccess);
  const updateVisitorData = useSelector((state) => state.visitorReducer.updateVisitorData);
  const updateVisitorFailure = useSelector((state) => state.visitorReducer.updateVisitorFailure);

  const getVisitorTypeSuccess = useSelector((state) => state.visitorTypeReducer.getVisitorTypeSuccess);
  const getVisitorTypeList = useSelector((state) => state.visitorTypeReducer.getVisitorTypeList);
  const getVisitorTypeFailure = useSelector((state) => state.visitorTypeReducer.getVisitorTypeFailure);
  const getVisitorTypeErrorMessage = useSelector((state) => state.visitorTypeReducer.errorMessage);

  const visitorErrorMessage = useSelector((state) => state.visitorReducer.errorMessage);

  const getEmployeeSuccess = useSelector((state) => state.employeeReducer.getEmployeeSuccess);
  const getEmployeeList = useSelector((state) => state.employeeReducer.getEmployeeList);
  const getEmployeeFailure = useSelector((state) => state.employeeReducer.getEmployeeFailure);
  const employeeErrorMessage = useSelector((state) => state.employeeReducer.errorMessage);

  const getProofTypeSuccess = useSelector((state) => state.proofTypeReducer.getProofTypeSuccess);
  const getProofTypeList = useSelector((state) => state.proofTypeReducer.getProofTypeList);
  const getProofTypeFailure = useSelector((state) => state.proofTypeReducer.getProofTypeFailure);
  const proofTypeErrorMessage = useSelector((state) => state.proofTypeReducer.errorMessage);

  const createFileSuccess = useSelector((state) => state.documentReducer.createFileSuccess);
  const createFileData = useSelector((state) => state.documentReducer.createFileData);
  const createFileFailure = useSelector((state) => state.documentReducer.createFileFailure);

  const documentErrorMessage = useSelector((state) => state.documentReducer.errorMessage);

  const [pageItems, setpageItems] = useState({});


  useEffect(() => {
    let data = pagePermission(pageItems, "Visitor", "Visitor")
    setpageItems(data)

  }, [navigation]);

  const column = [
    {
      "dataField": "firstName",
      "text": "Visitor Name",
      "sort": true
    },
    {
      "dataField": "phoneNo",
      "text": "Phone Number ",
      "sort": false
    },
    {
      "dataField": "purpose",
      "text": "Purpose ",
      "sort": false
    },
    {
      "dataField": "meetingDate",
      "text": "Meeting Date",
      "sort": false,
      formatter: (cellContent, row, index) => {
        return dateConversion(cellContent, "DD-MMM-YYYY")
      }
    },
    /* {
      dataField: "isActive",
      text: "Status",
      sort: true,
      align: "center",
      headerAlign: "center",
      formatter: (cellContent, row, index) => {
        const conInfo = { inputType: "status", status: cellContent }
        return formRender(null, null, conInfo, null);
      }
    }, */
  ]
  if (true) {
    column.push({
      text: "Action",
      sort: false,
      align: "center",
      headerAlign: "center",
      formatter: (cellContent, row, index) => (
        <div className="icon-container">
          {pageItems.update && <FiEdit
            className="text-primary cursor-pointer"
            size={18}
            onClick={() => onEditForm(row, index)}
          />}
          {pageItems.delete && <FiTrash2
            className="text-danger cursor-pointer"
            size={18}
            onClick={() => {
              onDeleteItem(row, index);

            }}
          ></FiTrash2>}
        </div>
      )
    },)
  }

  const [state, setState] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    address: '',
    visitorTypeId: '',
    genderId: "",
    whomToMeet: '',
    reasonToMeet: '',
    toTime: '',
    uploadData: false,
    genderList: [{ "genderId": 1, "genderName": "Male" }, { "genderId": 2, "genderName": "Female" }, { "genderId": 3, "genderName": "Others" },]
  });

  const [createModule, setCreateModule] = useState(false);
  const [loaderModule, setLoaderModule] = useState(false);
  const [deleteModule, setDeleteModule] = useState(false);
  const [itemList, setItemList] = useState([]);
  const [selectedItem, setSelectedItem] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(false);
  const [uploadModule, setUploadModule] = useState(false);

  


  useEffect(() => {
    // setLoaderModule(true)
    const requestData = {
      isActive: "true"
    }
    dispatch(getVisitor(requestData))
    // dispatch(getVisitorType(requestData))
    dispatch(getEmployee(requestData))
    listFetching()
  }, [navigation]);


  const listFetching = async () => {
    const requestData = {
      isActive: "true",
    }
    let proofList = await asyncGetProofInfo(requestData)
    let visitorTypeInfo = await asyncGetvisitorType(requestData)
    let employeeList = await asyncGetEmployeeInfo(requestData)


    setState({
      ...state,
      proofTypeList: proofList?.data || [],
      empList: employeeList?.data || [],
      visitorTypeList: visitorTypeInfo?.data || [],

    })
  }

  useEffect(() => {
    if (deleteModule) {
      confromDialog()
    }
  }, [deleteModule])

  const confromDialog = () => {
    return (
      swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          type: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
          cancelButtonText: "No",
        })
        .then((result) => {
          if (result.value) {
            onSubmitForm()
          } else {
            swal.fire({
              title: "Cancelled!",
              text: "Permission denied.",
              type: "error",
              icon: "error",
              timer: 1500,
            });
          }
        })
    )
  }

  useEffect(() => {
    if (getVisitorSuccess) {
      setItemList(getVisitorList)
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_VISITOR" })
    } else if (getVisitorFailure) {
      setItemList([])
      showMessage("warning", visitorErrorMessage?.message)
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_VISITOR" })
    }
  }, [getVisitorSuccess, getVisitorFailure]);

  useEffect(() => {
    if (getVisitorTypeSuccess) {
      // setItemList(getVisitorTypeList)
      setState({
        ...state,
        visitorTypeList: getVisitorTypeList
      })

      setLoaderModule(false)
      dispatch({ type: "RESET_GET_VISITOR_TYPE" })
    } else if (getVisitorTypeFailure) {
      // setItemList([])
      showMessage("warning", getVisitorTypeErrorMessage?.message)
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_VISITOR_TYPE" })
    }
  }, [getVisitorTypeSuccess, getVisitorTypeFailure]);

  useEffect(() => {
    if (getProofTypeSuccess) {
      setState({
        ...state,
        proofTypeList: getProofTypeList,
      })
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_PROOF_TYPE" })
    } else if (getProofTypeFailure) {
      setState({
        ...state,
        proofTypeList: [],
      })
      showMessage("warning", proofTypeErrorMessage?.message)
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_PROOF_TYPE" })
    }
  }, [getProofTypeSuccess, getProofTypeFailure]);

  useEffect(() => {
    if (createVisitorSuccess) {
      const temp_state = [...itemList, createVisitorData?.[0] || {}];
      setItemList(temp_state)
      setLoaderModule(false)
      closeModule()
      Alert({ description: 'Created Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
      dispatch({ type: "RESET_CREATE_VISITOR" })
    } else if (createVisitorFailure) {
      setLoaderModule(false)
      showMessage("warning", visitorErrorMessage?.message)
      dispatch({ type: "RESET_CREATE_VISITOR" })
    }
  }, [createVisitorSuccess, createVisitorFailure]);

  useEffect(() => {
    if (getEmployeeSuccess) {
      setState({
        ...state,
        empList: getEmployeeList,
        employeeNames: getEmployeeList.map((item) => item.employeeName)
      })
      console.log(JSON.stringify(state.employeeNames));
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_EMPLOYEE" })
    } else if (getEmployeeFailure) {
      showMessage("warning", employeeErrorMessage?.message)
      setLoaderModule(false)
      setState({
        ...state,
        empList: []
      })
      dispatch({ type: "RESET_GET_EMPLOYEE" })
    }
  }, [getEmployeeSuccess, getEmployeeFailure]);


  useEffect(() => {
    if (updateVisitorSuccess) {
      updateTable(updateVisitorData[0])
      dispatch({ type: "RESET_UPDATE_VISITOR" })
    } else if (updateVisitorFailure) {
      setLoaderModule(false)
      showMessage("warning", visitorErrorMessage?.message)
      dispatch({ type: "RESET_UPDATE_VISITOR" })
    }
  }, [updateVisitorSuccess, updateVisitorFailure]);

  const updateTable = (updatedItem) => {
    const temp_state = [...itemList];
    if (deleteModule) {
      let remainingList = _.remove(temp_state, function (n) {
        return n.visitorId != updatedItem.visitorId;
      });
      Alert({ description: 'Deleted Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
      setItemList(remainingList)
    } else {
      temp_state[selectedIndex] = updatedItem;
      Alert({ description: 'Updated Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
      setItemList(temp_state)
    }
    setLoaderModule(false)
    closeModule()
  }

  useEffect(() => {
    if (createFileSuccess) {
      // const temp_state = [...itemList, createFileData[0]];
      // closeModule()
      // setLoaderModule(false)
      showMessage("success", "Image Uploaded Successfully")
      dispatch({ type: "RESET_CREATE_FILE" })
    } else if (createFileFailure) {
      setLoaderModule(false)
      showMessage("warning", documentErrorMessage?.message)
      dispatch({ type: "RESET_CREATE_FILE" })
    }
  }, [createFileSuccess, createFileFailure]);

  const onCreateForm = () => {
    resetState()
    editData = false;
    setCreateModule(true)
  }
  const closeModule = () => {
    editData = false;
    setCreateModule(false)
    setDeleteModule(false)
  }

  const resetState = () => {
    setState({
      ...state,
      profilePic: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      uploadData: false,
      email: '',
      address: '',
      postalCode: '',
      visitorTypeId: '',
      genderId: "",
      whomToMeet: '',
      reasonToMeet: '',
      proofTypeId: '',
      proofNumber: '',
      proofImage: '',
      employeeId: '',
      date: moment(new Date(), "YYYY-MM-DD"),
      toTime: '',
      visitorName: "",
      filteredData: []
    })
  }

  const onEditForm = (data, index) => {
    let empList = state?.empList || []

    const employeeData = empList.find(emp => emp.employeeId === Number(data?.whomToMeet)); // Assuming employeeId is a number


    // return
    setState({
      ...state,
      uploadData: false,
      profilePic: data?.profile || '',
      firstName: data?.firstName || '',
      lastName: data?.lastName || '',
      phoneNumber: data?.phoneNo || '',
      email: data?.email || '',
      address: data?.address || '',
      visitorTypeId: Number(data?.visitorTypeId || ''),
      genderId: Number(data?.gender || ""),
      employeeId: data?.whomToMeet || "",
      postalCode: data?.postalCode || '',
      reasonToMeet: data?.purpose || '',
      meetingDate:data ? moment(new Date(data.meetingDate), 'YYYY-MM-DD') : "",
      toTime: data?.meetingTime,
      visitorProofId: data?.visitorProofId,
      proofTypeId: Number(data?.proofTypeId || ''),
      proofImage: data?.proofDocument || '',
      proofNumber: data?.proofNumber || '',
      proofImage: data?.proofDocument || '',
      visitorName: employeeData?.employeeCode || '',
      visitorId: employeeData?.employeeId || '',
      filteredData: []


    });
    editData = true;
    setSelectedIndex(index)
    setSelectedItem(data)
    setCreateModule(true)
  }

  const onDeleteItem = (data, index) => {
    editData = false;
    setSelectedIndex(index)
    setSelectedItem(data)
    setDeleteModule(true)
  }

  const onWriteDocument = (event, index) => {
    const file = event.target.files[0];

    if (file) {
      const now = new Date();
      const year = now.getFullYear().toString();
      const uniqueIdentifier = Math.floor(1000 + Math.random() * 9000).toString();
      const month = (now.getMonth() + 1).toString().padStart(2, '0');
      const day = now.getDate().toString().padStart(2, '0');
      const timestamp = `${year}${month}${day}${uniqueIdentifier}`;

      const fileType = file.type;
      const newFileName = `${timestamp}.${fileType.split('/')[1]}`;
      console.log(newFileName);
      const renamedFile = new File([file], newFileName, {
        type: fileType,
        lastModified: now.getTime(),
      });
      formDataAppend(renamedFile);

      setState({
        ...state,
        proofDocument: renamedFile,
      });
    }
  };


  const onFind = () => {
    setUploadModule(true)

  }
  const validateForm = async () => {
    const validatefom = customerFormRef.current.validateForm();
  }


  useEffect(() => {
    // Ensure the file is defined
    if (state.profilePic) {
      let base64Data = state.profilePic.split("base64,")[1];

      if (base64Data) {
        const byteCharacters = atob(base64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
          const slice = byteCharacters.slice(offset, offset + 512);
          const byteNumbers = new Array(slice.length);

          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }

          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }
        const fileType = state.profilePic.split(';')[0].split(':')[1];
        const blob = new Blob(byteArrays, { type: fileType });
        const now = new Date();
        const year = now.getFullYear().toString();
        const uniqueIdentifier = Math.floor(1000 + Math.random() * 9000).toString();
        const month = (now.getMonth() + 1).toString().padStart(2, '0');
        const day = now.getDate().toString().padStart(2, '0');
        const timestamp = `${year}${month}${day}${uniqueIdentifier}`;

        const newFileName = `${timestamp}.${fileType.split('/')[1]}`;
        console.log(newFileName);
        // Create a new File object
        const renamedFile = new File([blob], newFileName, {
          type: fileType,
          lastModified: now.getTime(),
        });
        formDataAppend(renamedFile)

        setState({
          ...state,
          profile: newFileName,
        });

      }
    }
  }, [state.profilePic, state.uploadData]);


  const formDataAppend = (file) => {
    const formData = new FormData();
    formData.append('images', file);
    dispatch(uploadFiles(formData, "units/unitinfo/"));
  };

  const fetchData = (value, index, name) => {
    let apiResponse = state?.employeCodeList || [];
    if (value.length < 3) {
      setState(prevState => ({
        ...prevState,
        filteredData: []
      }));
      return;
    }
    console.log(apiResponse);

    // Check if apiResponse is populated
    if (!apiResponse.length) {
      console.error("No data found in employeCodeList");
      return;
    }

    // Find the object matching employeeCode
    let filterObject = apiResponse.find(item => item.employeeCode.toLowerCase() === value.toLowerCase());
    const filteredResults = apiResponse.filter(material =>
      material?.employeeCode?.toLowerCase().includes(value.toLowerCase())
    );
    console.log(filteredResults);


    setState(prevState => ({
      ...prevState,
      filteredData: filteredResults || [],
      visitorId: filterObject?.employeeId || "",
      visitorName: value
    }));

    console.log(filteredResults);

    // Ensure filterObject and employeeCode exist before comparing lengths
    if (value.length >= filterObject?.employeeCode.length) {
      setState(prevState => ({
        ...prevState,
        filteredData: []
      }));

    }
  };

  const getVisitorData = async (event, name, index) => {
    let value = event.target.value;
    const requestData = {
      isActive: "true"
    }

    const req = {
      employeeCode: value,
      isActive: "true"


    };
    const response = await getVisitorName(req);
    console.log(JSON.stringify(response));

    if (response.getVisitorNameList) {
      setState({
        ...state,
        employeCodeList: response.getVisitorNameList.data
      });
    } else if (response.getVisitorNameFailure) {
      setState({
        ...state,
        employeCodeList: []
      });
      showMessage("warning", response.materialCodeErrorMessage?.message);
    }


    if (value === "") {
      setState({
        ...state,
        employeCodeList: []
      });
    } else {
      fetchData(value, index, name);
    }
  };



  const onSubmitForm = () => {
    const proofDetails = {
      proofTypeId: state.proofTypeId,
      proofNumber: state.proofNumber,
      proofImage: state.proofDocument ? state.proofDocument.name : '',
    };

    if (editData === true) {
      proofDetails.visitorProofId = state?.visitorProofId || 0;
    }

    const requestDate = {
      profileImage: state?.profile || '',
      firstName: state?.firstName || '',
      lastName: state?.lastName || '',
      phoneNo: state?.phoneNumber || '',
      email: state?.email || '',
      address: state?.address || '',
      visitorTypeId: state?.visitorTypeId || '',
      gender: state?.genderId,
      whomToMeet: state?.visitorId || 1,
      postalCode: state?.postalCode || '',
      purpose: state?.reasonToMeet || '',
      meetingDate: dateConversion(state?.meetingDate, "YYYY-MM-DD"),
      meetingTime: state?.toTime,
      proofDetails: proofDetails,
    };
    console.log(JSON.stringify(requestDate));
    // return

    if (editData) {
      setLoaderModule(true)
      dispatch(updateVisitor(requestDate, selectedItem.visitorId))
    } else if (deleteModule) {
      const deleteRequest = {
        isActive: "false"
      }
      setLoaderModule(true)
      dispatch(updateVisitor(deleteRequest, selectedItem.visitorId))
    } else {
      setLoaderModule(true)
      dispatch(createVisitor(requestDate))
    }
  }

  const {
    firstName,
    lastName,
    phoneNumber,
    email,
    address,
  } = state;

  const handleClick = (buttonName) => {
    if (buttonName == 'Add') {
      onCreateForm();
    }
  };

  const modelTitle = editData ? "Edit " : "Create ";
  const modelButtonLbl = editData ? "" : "Save";
  const { sidebarOpen } = useSidebar();


  return (
    <div>
      <div style={sidebarOpen ? { marginLeft: "10px", marginTop: "-10px" } : { marginLeft: "10px", marginTop: "-50px" }}>

        <View isOpen={createModule} size="fullscreen" savetitle={modelButtonLbl} onClose={setCreateModule} onSubmit={validateForm} title={`${modelTitle} Visitor`}>
          <FormLayout ref={customerFormRef} defaultState={state} setDefaultState={setState} onChangeCallback={{ "onWriteDocument": onWriteDocument, getVisitorData: getVisitorData }} dynamicForm={visitorForm} noOfColumn={3} onSubmit={onSubmitForm} onSearch={() => onFind()} />
        </View>
        <View size={'lg'} isOpen={uploadModule} onClose={setUploadModule} title="Upload Image">
          <AppImageCropper state={state} setState={setState} close={setUploadModule} ></AppImageCropper>
        </View>

        <SimpleCard >
          <CustomTable columns={column} buttonData={['add']} handleClick={handleClick} data={itemList} tableName={"Visitor"} state={state} />
        </SimpleCard>

      </div>
    </div>
  )
}

export default Visitor;