import React from "react";
import { Document, Page, PDFViewer, Text, Image, View, StyleSheet } from "@react-pdf/renderer";
import { amountFormat, dateConversion } from "app/utils/app-functions";
import CustomAlignButton from "./CustomAlignButton";
import { imagePath, unitPath } from "app/utils/constant";

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#ffffff",
    padding: 20,
  },
  header: {
    display: 'flex',
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 15,
    borderBottomWidth: 1,
    borderBottomColor: "#000000",
    paddingBottom: 5,
  },
  headerText: {
    fontSize: 16,
    color: 'black',
    textAlign: 'center',
    fontWeight: 10,
    marginTop: 1,
  },
  rowStyle: {
    flex: 1,
    flexDirection: 'row'
  },
  columnStyle: {

  },
  addressText: {
    marginLeft: 190,
    marginBottom: 5,
  },
  schoolInfoContainer: {
    marginBottom: 20,
  },
  schoolLogo: {
    width: 100,
    height: 100,
    // backgroundColor: 'black',
    marginLeft: 4,
  },
  city: {
    fontSize: 15,
    marginLeft: 240,
  },
  email: {

  },
  number: {
    fontSize: 12,
    marginLeft: 20
  },
  sectionHeader: {
    marginBottom: 20,
  },
  tableHeader: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderTopWidth: 1,
    borderBottomColor: "#000000",
    paddingBottom: 5,
    paddingTop: 5,
    marginBottom: 5,
    backgroundColor: '#D3D3D3',
  },
  tableHeaderCell: {
    width: "25%",
    fontWeight: "bold",
    fontSize: 10,
    textAlign: 'left',
  },
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#eeeeee",
    paddingBottom: 5,
    marginBottom: 5,
  },
  tableRowCell: {
    width: "25%",
    fontSize: 9,
    textAlign: 'left',
  },
  totalContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
    marginTop: 10,
  },
  totalLabel: {
    fontWeight: "bold",
    fontSize: 9,
    position: "fixed",
    marginRight: 5,
  },
  footerTotal: {
    flexDirection: "row",
    justifyContent: "space-between",
    borderWidth: 1,
    borderColor: "#000000",
    padding: 5,
    marginTop: 20
  },
  admission: {
    marginTop: 10,
    fontSize: 12,
    marginBottom: 14,
  },
  studentText: {
    marginTop: 10,
    fontSize: 12,
  },
  batch: {
    textAlign: 'right',
    fontSize: 12,
    marginTop: 10
  },
  class: {
    textAlign: 'right',
    marginTop: 10,
    fontSize: 12,
    marginBottom: 14,
  },
  student: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '15px',
    marginTop: '15px'
  },
  schoolNameHeader: {
    marginTop: 18,
    textAlign: 'center',
    alignItems: 'center'
  },
  schoolsubHead: {
    marginTop: 4,
    fontSize: 12,
  },
  address: {
    marginTop: 8,
    textAlign: 'right',
    display: 'flex',
    width: "15%"
  },
  text: {
    fontSize: 12,
    marginBottom: 4,
    textAlign: 'right'
  },
  studentContainer: {
    display: 'flex',
    // alignSelf: 'flex-end',
    width: '40%',
    padding: 0,
    marginTop: 10,
  },
  studentCellLabel: {
    flex: 0.4,
    // marginRight : "-10px",
    fontSize: '12px',
  },
  studentTableCell: {
    flex: 0.3,
    fontSize: '12px',
    marginLeft: '4px'
  },
  batchCellLabel: {
    flex: 0.5,
    fontSize: '12px',
  },
  BatchContainer: {
    display: 'flex',
    flex: 0.5,
    padding: 0,
    marginTop: 10,
  },
  tableContainer: {
    display: 'flex',
    alignSelf: 'flex-end',
    width: '30%',
    padding: 0,
    marginTop: 10,
    marginBottom: 10,
    marginRight: "-26px"
  },
  tableBalanceRow: {
    flexDirection: 'row',
    padding: '4px 0px',
  },
  tableCellLabel: {
    flex: 0.5,
    // marginRight : "-10px",
    fontSize: '10px',
  },
  tableCellColon: {
    padding: 0,
    marginRight: 2,
    fontSize: '10px'

  },
  tableCell: {
    flex: 0.3,
    textAlign: 'right',
    fontSize: '10px',
    marginLeft: '3px'
  },
  batchTableCell: {
    flex: 0.3,
    textAlign: 'right',
    fontSize: '12px',
    // marginLeft : '6px',


  },
  totalHeader: {
    fontWeight: "bold",
    fontSize: 12,
  }
});

const InvoiceDocument = ({ data, schoolInfo, onClick, total, feesData, advance,unitDetails }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View>
        <View style={styles.header}>
          <View style={styles.schoolLogo}>
            <Image style={styles.schoolLogo} src={`${imagePath}${unitPath}${unitDetails?.[0]?.unitToken || ''}/${encodeURI(unitDetails?.[0]?.image || '')}`} />
          </View>
          <View style={styles.schoolNameHeader}>
            <Text style={styles.schoolName}>{schoolInfo?.schoolName || ''}</Text>
            <Text style={styles.schoolsubHead}>{schoolInfo.email} - {schoolInfo.phoneNumber}</Text>
          </View>
          <View style={styles.address}>
            <Text style={styles.text}>{schoolInfo.address1}</Text>
            <Text style={styles.text}>{schoolInfo.address2}</Text>
            <Text style={styles.text}>{schoolInfo.city}</Text>
            <Text style={styles.text}>{schoolInfo.pincode}</Text>
          </View>
        </View>
        <View>
          <Text style={styles.headerText}>Fees Payment Receipt</Text>

          <View style={styles.student}>
            <View style={styles.studentContainer}>
              <View style={styles.tableBalanceRow}>
                <Text style={styles.studentCellLabel}>Bill To</Text>
                <Text style={styles.tableCellColon}>:</Text>
                <Text style={styles.studentTableCell}>{data?.[0]?.studentName || ""}</Text>
              </View>
              <View style={styles.tableBalanceRow}>
                <Text style={styles.studentCellLabel}>Admission No</Text>
                <Text style={styles.tableCellColon}>:</Text>
                <Text style={styles.studentTableCell}>{data?.[0]?.admissionNo || ""}</Text>
              </View>
            </View>
            <View style={styles.BatchContainer}>
              <View style={styles.tableBalanceRow}>
                <Text style={styles.batchCellLabel}>Batch</Text>
                <Text style={styles.tableCellColon}>:</Text>
                <Text style={styles.studentTableCell}>{data?.[0]?.batchName}</Text>
              </View>
              <View style={styles.tableBalanceRow}>
                <Text style={styles.batchCellLabel}>Class-Section</Text>
                <Text style={styles.tableCellColon}>:</Text>
                <Text style={styles.studentTableCell}>{data?.[0]?.classSection}</Text>
              </View>
            </View>
          </View>
          <View>
            <View style={styles.tableHeader}>
              <Text style={styles.tableHeaderCell}>S.No</Text>
              <Text style={styles.tableHeaderCell}>Fees Type</Text>
              <Text style={styles.tableHeaderCell}>Fees Amount</Text>
              <Text style={styles.tableHeaderCell}>Fees Paid</Text>
              <Text style={styles.tableHeaderCell}>Date Paid</Text>
            </View>
            {data.map((item, index) => (
              <View key={index} style={styles.tableRow}>
                <Text style={styles.tableRowCell}>{index + 1}</Text>
                <Text style={styles.tableRowCell}>{item.feesName}</Text>
                <Text style={styles.tableRowCell}>{amountFormat(item.feesAmount)}</Text>
                <Text style={styles.tableRowCell}>{amountFormat(feesData.subTotal)}</Text>
                <Text style={styles.tableRowCell}>{dateConversion(item.amountPaidDate)}</Text>
              </View>
            ))}
          </View>
          <View style={styles.tableContainer}>
            <View style={styles.tableBalanceRow}>
              <Text style={styles.tableCellLabel}>Advance Amount</Text>
              <Text style={styles.tableCellColon}>:</Text>
              <Text style={styles.tableCell}>{amountFormat((advance))}</Text>
            </View>
            <View style={styles.tableBalanceRow}>
              <Text style={styles.tableCellLabel}>Balance</Text>
              <Text style={styles.tableCellColon}>:</Text>
              <Text style={styles.tableCell}>{amountFormat(feesData.balance)}</Text>
            </View>
          </View>
          <View style={styles.footerTotal}>
            <View>
              <Text style={styles.totalHeader}>Amount Paid</Text>
            </View>
            <View>
              <Text style={styles.totalHeader}>{amountFormat(feesData.totalPaidAmount)}</Text>
            </View>
          </View>
        </View>
      </View>
    </Page>
  </Document>
);
//  const advanceAmount = (feesData.balance)-(feesData.totalPaidAmount
function InvoiceGenerator({ data, schoolInfo, onClick, unitDetails, feesData,advance }) {
// feesData.sum = sum

  return (
    <div>
      <PDFViewer style={{ width: '100%', height: '800px' }}>
        <InvoiceDocument data={data} schoolInfo={schoolInfo} onClick={onClick} feesData={feesData}  advance={advance} unitDetails={unitDetails}  />
      </PDFViewer>
    </div>
  );
}

export default InvoiceGenerator;