

const purchaseAddForm = [
    {
        title: "Basic Information",
        formBody: [

            {
                formField: [
                    {
                        label: "Create Title",

                        placeholder: 'Create Title',
                        inputType: "text",
                        uniqueKey: "supplierId",
                        displayKey: "supplierName",
                        icon: "i-Add",
                        fieldName: "title",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Start Time",
                        inputType: "time",
                        type: "time",
                        fieldName: "startTime",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },



                ]
            },
            {
                formField: [
                    {
                        label: "Description",

                        inputType: "text",
                        placeholder: "Description",
                        fieldName: "description",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "End Time",
                        inputType: "time",
                        type: "time",
                        fieldName: "endTime",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    
                 

                ]
            },
            {
                formField: [
                    {
                        label: "Class -Section",
                        inputType: "select",
                        options: "enrollmentDetailsList",
                        uniqueKey: "enrollmentId",
                        displayKey: "classTitle",
                        subDisplayKey: "sectionTitle",
                        multiple: true,
                        fieldName: "enrollmentIds",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    },
                    {

                        label: "End Date",
                        placeholder: 'Enter End Date',
                        inputType: "date",
                        fieldName: "endDate",
                        // onChange:"onSelectAttendanceDate",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },




                ]
            },
            {
                formField: [
                    {
                        label: "Start-Date",
                        placeholder: 'Enter Start Date',
                        inputType: "date",
                        fieldName: "startDate",
                        // onChange:"onSelectAttendanceDate",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },

                ]
            },
            {
                formField: [

                ]
            },
            {
                formField: [

                ]
            },
            {
                formField: [

                ]
            },






        ]
    }
];










/* const innerForm = [{
    title: "Basic Information",
    formBody: [
        {
            formField: [
                {
                    label: "Select Supplier",
                    inputType: "select",
                    placeholder: 'Warehouse',
                    options: "optionsList",
                    uniqueKey: "departmentId",
                    displayKey: "departmentName",
                    multiple: false,
                    fieldName: "Warehouse3",
                    defaultValue: "",
                    config: {
                        required: "",
                    },
                }

            ]
        },

        {
            formField: [
                {
                    label: "",
                    inputType: "select",
                    placeholder: "Receipt Location",
                    fieldName: "selectb",
                    defaultValue: "",
                    config: {
                        required: "required",
                    },
                },

            ]
        },
        {
            formField: [
                {
                    label: "",
                    inputType: "select",
                    placeholder: "Length(mm)",
                    fieldName: "Length",
                    defaultValue: "",
                    config: {
                        required: "required",
                    },
                },

            ]
        },
        {
            formField: [
                {
                    label: "",
                    inputType: "select",
                    placeholder: "Width(mm)",
                    fieldName: "Width",
                    defaultValue: "",
                    config: {
                        required: "required",
                    },
                },

            ]
        },
        {
            formField: [
                {
                    label: "",
                    inputType: "select",
                    options: "qualificationId",
                    uniqueKey: 'id',
                    placeholder: "Thickness",
                    fieldName: "Thickness",
                    defaultValue: "",
                    config: {
                        required: "required",
                    },
                },

            ]
        },

    ]
}] */
const innerClickForm = [{

    title: "Basic Information",
    formBody: [
        {
            formField: [
                {
                    inputType: "text",
                    placeholder: 'Supplier Code',
                    uniqueKey: "departmentId",
                    displayKey: "departmentName",
                    fieldName: "supplierCode",
                    defaultValue: "",
                    config: {
                        required: "",
                    },
                },
                {
                    inputType: "text",
                    type: "number",
                    placeholder: 'Primary Phone',
                    uniqueKey: "Supplier Code",
                    displayKey: "Primary Phone",
                    fieldName: "primaryNumber",
                    defaultValue: "",
                    config: {
                        required: "",
                    },
                },
                {
                    inputType: "email",
                    placeholder: 'Email',
                    uniqueKey: "Supplier Code",
                    displayKey: "Primary Phone",
                    fieldName: "email",
                    defaultValue: "",
                    config: {
                        required: "",
                    },
                },
                {
                    inputType: "textarea",
                    placeholder: 'address',
                    uniqueKey: "Supplier Code",
                    displayKey: "Primary Phone",
                    fieldName: "address",
                    defaultValue: "",
                    config: {
                        required: "",
                    },
                },
                {
                    inputType: "text",
                    type: "number",
                    placeholder: 'Postcode',
                    uniqueKey: "Supplier Code",
                    displayKey: "Primary Phone",
                    fieldName: "postCode",
                    defaultValue: "",
                    config: {
                        required: "",
                    },
                },

            ]
        },
        {
            formField: [
                {
                    inputType: "text",
                    placeholder: 'Supplier Name',
                    uniqueKey: "Supplier Code",
                    displayKey: "Primary Phone",
                    fieldName: "suplierNameValue",
                    defaultValue: "",
                    config: {
                        required: "",
                    },
                },
                {
                    inputType: "text",
                    type: "number",
                    placeholder: 'Secondary Phone',
                    uniqueKey: "Supplier Code",
                    displayKey: "Primary Phone",
                    fieldName: "secondaryPhone",
                    defaultValue: "",
                    config: {
                        required: "",
                    },
                },
                {
                    inputType: "text",
                    placeholder: 'Contact  Person',
                    uniqueKey: "Supplier Code",
                    displayKey: "Primary Phone",
                    fieldName: "contact",
                    defaultValue: "",
                    config: {
                        required: "",
                    },
                },
                {
                    inputType: "textarea",
                    placeholder: 'Comment',
                    uniqueKey: "Supplier Code",
                    displayKey: "Primary Phone",
                    fieldName: "comment",
                    defaultValue: "",
                    config: {
                        required: "",
                    },
                },
            ]
        },

    ]
}]


const inner = [
    {
        label: "",
        inputType: "checkbox",
        // uniqueKey: "option",
        onChange: "handleTypeChange",
        fieldName: "option",
        defaultValue: "",
        config: {
            required: "",
        },

    },
    {
        inputType: "text",
        type: "text",
        placeholder: 'Answer',
        fieldName: "answer",
        onChange:"handleChange",
        defaultValue: "",
        config: {
            required: "",
        },

    },
    {
        label: "",
        inputType: "icon-new",
        index: 'delete',
        fieldName: "deleteInner",

        onClick: "onDeleteInner",
        defaultValue: "",
        config: {
            required: "required",
        },
    },


]
const subInner = [
    {
        placeholder: 'Warehouse',
        inputType: "select",
        options: "warehouseList",
        uniqueKey: "id",
        onRight: "20px",
        onChange: "handlewarehousechange",
        displayKey: "warehouseName",
        fieldName: "warehouseId",
        defaultValue: "",
        config: {
            required: "required",
        },
    },
    {
        placeholder: 'Location',
        inputType: "select",
        options: "locationList",
        onChange: "handlelocationchange",

        uniqueKey: "id",
        displayKey: "name",
        fieldName: "locationId",
        defaultValue: "",
        config: {
            required: "required",
        },
    },
    {
        label: "Apply to all",
        inputType: "button",

        fieldName: "applyButton",
        onClick: "onSubmitItemDetails",
        defaultValue: "",
        config: {
            required: "required",
        },
    },




]


const fieldList = [
    {
        inputType: "text",
        type: "text",
        placeholder: 'Enter Your Question',
        onChange: "handleChange",

        uniqueKey: "Supplier Code",
        displayKey: "Primary Phone",
        fieldName: "question",
        defaultValue: "",
        config: {
            required: "",
        },
    },

    {
        label: "",
        inputType: "text",
        placeholder: "Qty*",
        onChange: "onPriceChange",
        fieldName: "qty",
        defaultValue: "",
        config: {
            required: "required",
        },
    },

    {
        label: "",
        inputType: "icon-table",
        index: 'add',
        onMargin: '-30px',
        fieldName: "outline",
        onClick: "onAddItem",
        defaultValue: "",
        config: {
            required: "",
        },
    },
    {
        label: "",
        inputType: "icon-table",
        index: 'delete',
        onStyle: '-50px',

        fieldName: "delete",
        onRight: "40px",
        onClick: "onDeletePress",
        defaultValue: "",
        config: {
            required: "",
        },
    },
]






export {
    // submenuForm,
    /* productInformationForm */
    // dropDownForm,
    purchaseAddForm,
    // purchaseForm,
    // demo,
    inner,

    // innerForm,
    innerClickForm,
    fieldList,
    subInner,
};