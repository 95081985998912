import { returnApiCallPromise } from './ApiContent';
import { exam, examList, examStatus } from './APIContainer';

//GET
export function getExam(request) {
  const requestObj = request ? { params: request } : "";
  return (dispatch) => {
    returnApiCallPromise("GET", exam, requestObj)
      .then(response => {
        dispatch({
          type: 'GET_EXAM_SUCCESS',
          payload: response.data
        })
      }).catch(error => {

        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'GET_EXAM_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

//Exam Status
export function getExamStatus(request) {
  const requestObj = request ? { params: request } : "";
  return (dispatch) => {
    returnApiCallPromise("GET", examStatus, requestObj)
      .then(response => {
        dispatch({
          type: 'GET_EXAM_STATUS_SUCCESS',
          payload: response.data
        })
      }).catch(error => {

        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'GET_EXAM_STATUS_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

export function asyncGetExamStatusInfo(request) {
  const requestObj = request ? { params: request } : "";
  return  returnApiCallPromise("GET", examStatus, requestObj)
      .then(response => {
        return {
          error: false,
          message:"Succsss",
          data: response?.data?.data || []
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        return {
          error: true,
          data:[],
          message:errorMessage
        }
      })
}


//GET Exam LIST
export function getExamListData(request) {
  const requestObj = request ? { params: request } : "";
  return (dispatch) => {
    returnApiCallPromise("GET", examList, requestObj)
      .then(response => {
        dispatch({
          type: 'GET_EXAM_LIST_SUCCESS',
          payload: response.data
        })
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'GET_EXAM_LIST_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

export function asyncGetExamListInfo(request) {
  const requestObj = request ? { params: request } : "";
  return  returnApiCallPromise("GET", examList, requestObj)
      .then(response => {
        return {
          error: false,
          message:"Succsss",
          data: response?.data?.data || []
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        return {
          error: true,
          data:[],
          message:errorMessage
        }
      })
}


//GET
export function deleteExamDetails(request) {
  const requestObj = request ? { params: request } : "";
  return (dispatch) => {
    returnApiCallPromise("DELETE", exam, requestObj)
      .then(response => {
        dispatch({
          type: 'DELETE_EXAM_DETAILS_SUCCESS',
          payload: response.data
        })
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'DELETE_EXAM_DETAILS_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

//POST
export function createExam(request) {

  return (dispatch) => {
    returnApiCallPromise("POST", exam, request)
      .then(response => {
        if (response.data.error) {
          dispatch({
            type: 'CREATE_EXAM_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'CREATE_EXAM_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'CREATE_EXAM_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

export function updateExam(request, examId) {
  return (dispatch) => {
    returnApiCallPromise("PUT", exam + "/" + examId, request)
      .then(response => {
        if (response.data.error) {
          dispatch({
            type: 'UPDATE_EXAM_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'UPDATE_EXAM_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'UPDATE_EXAM_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}