import React from 'react';
import './index.css';  // Make sure to import the CSS where Tailwind is set up

export function Button({ 
  children, 
  onClick, 
  type = 'button', 
  variant = 'primary', 
  isLoading = false 
}) {
  const baseStyles = `
    relative w-full py-3 px-6 rounded-xl font-medium 
    transition-all duration-300 transform 
    hover:scale-[1.02] hover:-translate-y-0.5
    active:scale-[0.98] active:translate-y-0
    disabled:opacity-50 disabled:cursor-not-allowed
    overflow-hidden
  `;
  
  const variantStyles = variant === 'primary' 
    ? `bg-gradient-to-r from-blue-600 to-blue-700 text-white 
       hover:from-blue-700 hover:to-blue-800
       active:from-blue-800 active:to-blue-900
       shadow-lg hover:shadow-xl active:shadow-md
       disabled:from-blue-400 disabled:to-blue-500`
    : `bg-gray-100 text-gray-700 
       hover:bg-gray-200 active:bg-gray-300
       shadow-md hover:shadow-lg active:shadow-sm`;

  return (
    <button
      type={type}
      onClick={onClick}
      disabled={isLoading}
      className={`${baseStyles} ${variantStyles}`}
    >
      <span className={`transition-opacity duration-200 ${isLoading ? 'opacity-0' : 'opacity-100'}`}>
        {children}
      </span>
      
      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="w-5 h-5 border-3 border-white border-t-transparent rounded-full animate-spin" />
        </div>
      )}
      
      <div className="absolute inset-0 -z-10 animate-shimmer opacity-0 group-hover:opacity-100" />
    </button>
  );
}
