

const materialForm = [
  {
      title: "Basic Information",
      formBody: [
          {
              formField: [
                 
                  {
                      label: "Title",
                      inputType: "text",
                      placeholder: "Enter Title",
                      fieldName: "title",
                      defaultValue: "",
                      config: {
                          required: "required",
                      },
                  },
               
                  {

                      label: "Class",
                      inputType: "select",
                      options: "enrollmentDetailsList",
                      uniqueKey: "enrollmentId",
                      displayKey: "classTitle",
                      onChange: "onSelectClass",
                      subDisplayKey: "sectionTitle",
                      multiple: true,
                      fieldName: "enrollmentId",
                      defaultValue: "",
                      config: {
                          required: "",
                      },
                  },
                  {
                      label: "Material Type",
                      inputType: "select",
                      options: "materialTypeList",
                      uniqueKey: "knowledgeMaterialTypeId",
                      onChange:"onSelectMaterialType",
                      displayKey: "knowledgeMaterialType",
                      fieldName: "knowledgeMaterialTypeId",
                      defaultValue: "",
                      config: {
                          required: "required",
                      },
                  },
                 
                  {
                      label: "Document",
                      inputType: "file",
                      fieldName: "imageName",
                      fileFormat : "application/pdf" ,
                      onChange:"onWriteDocument",
                      defaultValue: "",
                      disabled: false,
                      config: {
                          required: "",
                      },
                  },
                  {
                      label: "Url",
                      inputType: "text",
                      type:"url",
                      onChange:"onHandleUrl",
                      disabled: false,
                      fieldName: "url",
                      defaultValue: "",
                      config: {
                          required: "",
                      },
                  },
                
              ]
          },
          {
              formField: [
                 
                 
                  {
                      label: "Short Description",
                      inputType: "text",
                      placeholder: "Enter Description",
                      fieldName: "shortDescription",
                      defaultValue: "",
                      config: {
                          required: "required",
                      },
                  },
                  {
                      label: "Material Name",
                      inputType: "text",
                      fieldName: "name",
                      defaultValue: "",
                      config: {
                          required: "required",
                      },
                  },
                  {
                      label: "Book Type",
                      inputType: "select",
                      options:'knowledgeBookTypeList',
                      fieldName:"knowledgeBookId",
                      displayKey:"knowledgeBookName",
                      uniqueKey:"knowledgeBookId",
                      defaultValue: "",
                      config: {
                          required: "required",
                      },
                  },
              ]
          },

      ]
  }
];

export {
  materialForm
};