import * as yup from "yup";
import { Formik } from "formik";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import DateTime from "react-datetime";
import { Form, Button } from "react-bootstrap";
import { DateRange } from "react-date-range";
import SimpleCard from 'app/components/atoms/simple-card/SimpleCard'
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { MdEdit, MdDelete } from "react-icons/md";
import swal from "sweetalert2";
import _ from "lodash";
import { pagePermission, showMessage } from "app/utils/app-functions";
import FormLayout from "app/components/render/formlayout";
import View from "app/components/atoms/modalView/Modalview";
import CustomTable from "app/components/atoms/custom-table/CustomTable";
import formRender from "app/components/render/formcomponent";
import { supplierForm } from "./formData";
import { FiEdit, FiSend, FiTrash2 } from 'react-icons/fi';
import { createSupplier, getSupplier, updateSupplier } from "app/api/SupplierApi";
import Alert from "app/components/atoms/alert";

let editData = false;

const Supplier = ({ navigation }) => {

    const dispatch = useDispatch();
    const customerFormRef = useRef();

    const getSupplierSuccess = useSelector((state) => state.supplierReducer.getSupplierSuccess);
    const getSupplierList = useSelector((state) => state.supplierReducer.getSupplierList);
    const getSupplierFailure = useSelector((state) => state.supplierReducer.getSupplierFailure);
    const supplierErrorMessage = useSelector((state) => state.supplierReducer.errorMessage);


    const createSupplierSuccess = useSelector((state) => state.supplierReducer.createSupplierSuccess);
    const createSupplierData = useSelector((state) => state.supplierReducer.createSupplierData);
    const createSupplierFailure = useSelector((state) => state.supplierReducer.createSupplierFailure);


    const updateSupplierSuccess = useSelector((state) => state.supplierReducer.updateSupplierSuccess);
    const updateSupplierData = useSelector((state) => state.supplierReducer.updateSupplierData);
    const updateSupplierFailure = useSelector((state) => state.supplierReducer.updateSupplierFailure);


    const errorMessage = useSelector((state) => state.productReducer.errorMessage);
    const [pageItems, setpageItems] = useState({});

    useEffect(() => {
        let data = pagePermission(pageItems, "Inventory", "Supplier")
        setpageItems(data)

    }, [navigation]);


    const column = [
        {
            "dataField": "supplierCode",
            "text": "Code",
            "sort": true
        },
        {
            "dataField": "supplierName",
            "text": "Name",
            "sort": true,

        },
        {
            "dataField": "primaryNo",
            "text": "Primary Phone",
            "sort": true
        },
        {
            "dataField": "secondaryNo",
            "text": "Secondary Phone",
            "sort": true
        },
        {
            "dataField": "email",
            "text": "Email",
            "sort": false
        },
        {
            "dataField": "contactName",
            "text": "Contact Name",
            "sort": false
        },
        {
            "dataField": "address",
            "text": "Address",
            "sort": false
        },
        /* {
          dataField: "orderStatusId",
          text: "Status",
          sort: true,
          align: "center",
          headerAlign: "center",
          formatter: (cellContent, row, index) => {
            const conInfo = { inputType: "status", status: cellContent }
            return formRender(null, null, conInfo, null);
          }
        }, */
    ]
    if (pageItems.update ||  pageItems.delete ) {
        column.push({
            text: "Action",
            sort: false,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => (
                <div className="icon-container">
                    {pageItems.update && <FiEdit
                        className="text-primary cursor-pointer"
                        size={18}
                        onClick={() => onEditForm(row, index)}
                    ></FiEdit>}
                    {pageItems.delete && <FiTrash2
                        className="text-danger cursor-pointer"
                        size={18}
                        onClick={() => {
                            onDeleteItem(row, index)
                        }}
                    ></FiTrash2>}
                    {/* <MdAddTask
                        className="text-success cursor-pointer"
                        size={18}
                        onClick={() => onEditForm(row, index)}
                    ></MdAddTask> */}
                </div>
            )
        },)
    }

    const formColumn = [
        {
            "dataField": "materialType",
            "text": "Material Type",
            "sort": true
        },
        {
            "dataField": "materialCode",
            "text": "Material Code",
            "sort": true
        },
        {
            "dataField": "materialName",
            "text": "Material Name",
            "sort": true
        },
        {
            "dataField": "Qty",
            "text": "Units",
            "sort": true
        },

        {
            "dataField": "Qty",
            "text": "Qty",
            "sort": true
        },
        {
            "dataField": "Qty",
            "text": "Received Quantity",
            "sort": true
        },



        {
            "dataField": "additem",
            "text": "Add Item Details",
            "sort": false
        },
        {
            "dataField": "action",
            "text": "Action",
            "sort": false
        },
    ]

    const [state, setState] = useState({
        voiceName: "",
        purchaseOrderDate: new Date(),
        statusId: 1,
        description: "",
        poChoosed: '',
        "customerList": [],
        "venderList": [],
        "leadSourceTypeList": [],
        footerData: [
            { label: 'SubTotal :', value: '0' },
            { label: 'Tax:', value: '0' },
            { label: 'Total:', value: '0' }
        ],
        statusList: [
            { id: 1, name: "Open" },
            { id: 2, name: "close" }
        ]
    });

    const [createModule, setCreateModule] = useState(false);
    const [loaderModule, setLoaderModule] = useState(false);
    const [editModule, setEditModule] = useState(false);
    const [viewModule, setViewModule] = useState(false);
    const [deleteModule, setDeleteModule] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [selectedItem, setSelectedItem] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);
    const [tableRows, setTableRows] = useState([]);


    useEffect(() => {
        setLoaderModule(true)
        dispatch(getSupplier())

    }, [navigation]);



    useEffect(() => {
        if (getSupplierSuccess) {
            setItemList(getSupplierList)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_SUPPLIER" })
        } else if (getSupplierFailure) {
            setItemList([])
            showMessage("warning", supplierErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_SUPPLIER" })
        }
    }, [getSupplierSuccess, getSupplierFailure]);


    useEffect(() => {
        if (createSupplierSuccess) {
            const temp_state = [...itemList, createSupplierData[0]];
            setItemList(temp_state)
            setLoaderModule(false)
            closeModule()
            Alert({ description: 'Created Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            dispatch({ type: "RESET_CREATE_SUPPLIER" })
        } else if (createSupplierFailure) {
            setLoaderModule(false)
            showMessage("warning", supplierErrorMessage?.message)
            dispatch({ type: "RESET_CREATE_SUPPLIER" })
        }
    }, [createSupplierSuccess, createSupplierFailure]);

    useEffect(() => {
        if (updateSupplierSuccess) {
            updateTable(updateSupplierData[0])
            dispatch({ type: "RESET_UPDATE_SUPPLIER" })
        } else if (updateSupplierFailure) {
            setLoaderModule(false)
            showMessage("warning", errorMessage?.message)
            dispatch({ type: "RESET_UPDATE_SUPPLIER" })
        }
    }, [updateSupplierSuccess, updateSupplierFailure]);

    const updateTable = (updatedItem) => {
        const temp_state = [...itemList];
        if (deleteModule) {

            let remainingList = _.remove(temp_state, function (n) {
                return n.supplierId != selectedItem.supplierId;
            });

            Alert({ description: 'Deleted Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(remainingList)
        } else {

            temp_state[selectedIndex] = updatedItem;
            Alert({ description: 'Updated Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(temp_state)
        }
        setLoaderModule(false)
        closeModule()
    }


    const closeModule = () => {
        editData = false;
        setCreateModule(false)
        setDeleteModule(false)
    }

    const resetState = () => {
        setState({
            ...state,
            supplierCode: '',
            supplierName: '',
            secondaryNumber: '',
            email: '',
            primaryNumber: '',
            contactName: '',
            address: '',
            postCode: '',
            comment: '',

        })
        setTableRows([])


    }

    const onCreateForm = () => {
        resetState()
        editData = false;
        setCreateModule(true)
    }
    // const updateData = state.poChoosed.length? state.poChoosed[0] : {};

    const onEditForm = (data, index) => {
        setState({
            ...state,
            supplierCode: data?.supplierCode || '',
            secondaryNumber: data?.secondaryNo || '',
            supplierName: data?.supplierName || '',
            primaryNumber: data?.primaryNo || '',
            email: data?.email || '',
            contactName: data?.contactName || '',
            address: data?.address || '',
            postCode: data?.pincode || '',
            comment: data?.comment || '',
        })


        editData = true;
        setSelectedIndex(index);
        setSelectedItem(data);
        setCreateModule(true);
    };
    const handleSupplierChange = (event, index, name) => {
        let temp_state = "";
        temp_state = {
            ...state,
            [name]: event,
            contactNumber: event[0]?.primaryNo || "",
            contactPerson: event[0]?.contactName || "",
            supplierAddress: event[0]?.address || "",

        }
        setState(temp_state)

    }
    const handleStatus = (event, index, name) => {
        const selectedValue = event.target.value;
        const statusObject = state?.statusList.find(stat => stat.id == selectedValue);
        const status = statusObject?.name || '';
        setState({
            ...state,
            [name]: selectedValue,
            statusOpen: statusObject?.name || null
        });
    }
    // const updateData = state.poChoosed.length ? state.poChoosed[0] : {};

    const convertToSupplierItem = (tableRows) => {
        let temp_arr = [];
        const updatedId = selectedItem ? selectedItem : {};


        tableRows.map((row, index) => {

            const req = {
                grMaterialId: row?.grMaterialId || null,
                materialId: row?.materialId ? parseFloat(row.materialId) : null,
                receivedQty: row?.receivedQty ? parseFloat(row.receivedQty) : null,
                unit: row.unit ? parseFloat(row.unit) : null,
                goodsStatus: null,
                dimension: null,
                itemDetails: row.itemDetails.map((data, index) => ({
                    itemDetailsId: data.itemDetailsId,
                    purchaseOrderId: parseInt(updatedId?.purchaseOrderId) || 0,
                    materialId: row?.materialId ? parseFloat(row.materialId) : null,
                    serialNumber: data.serialNumber || null,
                    batchNumber: data.batchNumber || null,
                    receiptWarehouse: data?.locationId ? parseFloat(data.warehouseId) : null,
                    receiptLocation: data?.locationId ? parseFloat(data.locationId) : null,
                    sizeOfTheGoods: data.width || null,
                    thickness: data.thickness || null,
                    image: null,
                    stockStatus: false
                }))
            }
            temp_arr.push(req)
            // console.log(JSON.stringify(temp_arr));

        });
        return temp_arr
    };


    const confromDialog = () => {
        return (
            swal
                .fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    type: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!",
                    cancelButtonText: "No",
                })
                .then((result) => {
                    if (result.value) {
                        onSubmitForm()
                    } else {
                        setDeleteModule(false)

                        swal.fire({
                            title: "Cancelled!",
                            text: "Permission denied.",
                            type: "error",
                            icon: "error",
                            timer: 1500,
                        });
                    }
                })
        )
    }
    useEffect(() => {
        if (deleteModule) {
            confromDialog()
        }
    }, [deleteModule])




    const onDeleteItem = (data, index) => {
        editData = false;
        setSelectedItem(data)
        setSelectedIndex(index)
        setDeleteModule(true)
        if (deleteModule) {
            confromDialog()
        }
    }

    const onSubmitForm = () => {
        const req = {

            "supplierName": state?.supplierName || '',
            "primaryNo": state?.primaryNumber || '',
            "secondaryNo": state?.secondaryNumber || '',
            "email": state?.email || '',
            "contactName": state?.contactName || '',
            "address": state?.address || '',
            "pincode": state?.postCode || '',
            "comment": state?.comment || '',

        }
        setCreateModule(false)

        if (editData) {
            setLoaderModule(true)
            dispatch(updateSupplier(req, selectedItem.supplierId))
        }
        else if (deleteModule) {

            const deleteRequest = {
                isActive: "false"
            }
            dispatch(updateSupplier(deleteRequest, selectedItem.supplierId))

            setLoaderModule(true)
        } else {
            setLoaderModule(true)
            dispatch(createSupplier(req))

        }

    }
    const modelTitle = editData ? "Edit " : "Create ";
    const modelButtonLbl = editData ? "" : "Save";
    const validateForm = async () => {
        const validatefom = customerFormRef.current.validateForm();
    }

    
    const handleClick = (buttonName) => {
        if (buttonName == 'Add') {
            onCreateForm();
        }
    };

    return (
        <div>
            <div style={{ marginTop: "-10px" }}>
                <View isOpen={createModule} size="md" savetitle={modelButtonLbl} onClose={setCreateModule} onSubmit={validateForm} title={`${modelTitle} Supplier`}>
                    <FormLayout ref={customerFormRef} defaultState={state} setDefaultState={setState} dynamicForm={supplierForm} noOfColumn={2} onSubmit={onSubmitForm} />
                </View>

                <SimpleCard >
                    <CustomTable  buttonData={pageItems.create ? ['add'] : []} handleClick={handleClick} tableName={"Supplier"} columns={column} data={itemList} initialPage={1} />
                </SimpleCard>

            </div>
        </div>
    )
};

export default Supplier;
