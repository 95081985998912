import { returnApiCallPromise } from './ApiContent';
import { circular } from './APIContainer';

//GET
export function getCircular(request) {
  const requestObj = request ? { params: request } : "";
  return (dispatch) => {
    returnApiCallPromise("GET",circular , requestObj)
      .then(response => {
        dispatch({
          type: 'GET_CIRCULAR_SUCCESS',
          payload: response.data
        })
      }).catch(error => {
        
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'GET_CIRCULAR_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

//POST
export function createCircular(request) {

  return (dispatch) => {
    returnApiCallPromise("POST", circular, request)
      .then(response => {
        if (response.data.error) {
          dispatch({
            type: 'CREATE_CIRCULAR_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'CREATE_CIRCULAR_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'CREATE_CIRCULAR_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

export function updateCircular(request, circularId) {
  return (dispatch) => {
    returnApiCallPromise("PUT", circular+"/"+circularId, request)
      .then(response => {

        if (response.data.error) {
          dispatch({
            type: 'UPDATE_CIRCULAR_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'UPDATE_CIRCULAR_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'UPDATE_CIRCULAR_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}