import React from 'react';
import Header from './Header';
import MarksPercentageGraph from './MarksPercentage';
import { FaHome, FaClipboardList, FaChartBar } from 'react-icons/fa'; // Adding icons

const StudentDashboard = ({ studentFilterData, studentExamData,studentExamChart ,state}) => {
    const attendanceDetails = state?.attendanceDetails|| {};
    const assignmentDetails = state?.assignmentDetails|| [];

    return (
        <div>
            <Header studentFilterData={studentFilterData} attendanceData={attendanceDetails} studentExamData={studentExamData} studentExamChart={studentExamChart} assignmentDetails={assignmentDetails}/>

        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '20px',
        backgroundColor: '#f0f2f5', // Slight background to separate the sections
    },
    leftColumn: {
        flex: 1,
        marginRight: '20px',
        padding: '20px',
    },
    rightColumn: {
        flex: 1,
        marginLeft: '20px',
        padding: '20px',
    },
    profileCard: {
        backgroundColor: '#ffffff',
        padding: '25px',
        borderRadius: '20px',
        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)',
        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
        borderLeft: '8px solid #00bcd4',
        overflow: 'hidden',
        animation: 'fadeIn 1s ease',
    },
    profileHeader: {
        display: 'flex',
        alignItems: 'center',
        borderBottom: '2px solid #eee',
        paddingBottom: '20px',
        marginBottom: '20px',
    },
    profileImage: {
        width: '120px',
        height: '120px',
        borderRadius: '50%',
        objectFit: 'cover',
        marginRight: '20px',
        border: '5px solid #00bcd4',
    },
    profileInfo: {
        textAlign: 'left',
        color: '#333',
    },
    profileBody: {
        fontSize: '16px',
        color: '#555',
    },
    examScores: {
        marginTop: '20px',
        textAlign: 'left',
    },
    icon: {
        marginRight: '10px',
        color: '#00bcd4',
    },
    profileCardHover: {
        transform: 'scale(1.05)',
        boxShadow: '0 12px 24px rgba(0, 0, 0, 0.2)',
    },
    '@keyframes fadeIn': {
        '0%': {
            opacity: 0,
        },
        '100%': {
            opacity: 1,
        },
    },
};

export default StudentDashboard;
