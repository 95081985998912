import React from "react";
import PropTypes from "prop-types";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import "bootstrap/dist/css/bootstrap.min.css";

// Custom animation plugin for Highcharts
(function (H) {
  H.seriesTypes.pie.prototype.animate = function (init) {
    const series = this,
      chart = series.chart,
      points = series.points,
      { animation } = series.options,
      { startAngleRad } = series;

    function fanAnimate(point, startAngleRad) {
      const graphic = point.graphic,
        args = point.shapeArgs;

      if (graphic && args) {
        graphic
          // Set initial animation values
          .attr({
            start: startAngleRad,
            end: startAngleRad,
            opacity: 1,
          })
          // Animate to the final position
          .animate(
            {
              start: args.start,
              end: args.end,
            },
            {
              duration: animation.duration / points.length,
            },
            function () {
              // On complete, start animating the next point
              if (points[point.index + 1]) {
                fanAnimate(points[point.index + 1], args.end);
              }
              // On the last point, fade in the data labels, then
              // apply the inner size
              if (point.index === series.points.length - 1) {
                series.dataLabelsGroup.animate(
                  {
                    opacity: 1,
                  },
                  void 0,
                  function () {
                    points.forEach((point) => {
                      point.opacity = 1;
                    });
                    series.update(
                      {
                        enableMouseTracking: true,
                      },
                      false
                    );
                    chart.update({
                      plotOptions: {
                        pie: {
                          innerSize: "40%",
                          borderRadius: 8,
                        },
                      },
                    });
                  }
                );
              }
            }
          );
      }
    }

    if (init) {
      // Hide points on init
      points.forEach((point) => {
        point.opacity = 0;
      });
    } else {
      fanAnimate(points[0], startAngleRad);
    }
  };
})(Highcharts);

const StudentAttendanceChart = ({ attendanceData }) => {
  const { totalWorkingDays, totalDaysPresent, totalDaysAbsent } = attendanceData;

  const attendancePercentage =
    totalWorkingDays > 0 ? ((totalDaysPresent / totalWorkingDays) * 100).toFixed(2) : "N/A";

  // Pie chart data
  const pieData = [
    { name: "Present Days", y: ((totalDaysPresent / totalWorkingDays) * 100) }, 
    { name: "Absent Days", y: ((totalWorkingDays - totalDaysAbsent) ) }, 
  ];

  const chartOptions = {
    chart: {
      type: "pie",
    },
    title: {
      text: "Attendance Overview",
    },
    tooltip: {
      headerFormat: "",
      pointFormat: `<span style="color:{point.color}">\u25CF</span> 
                    {point.name}: <b>{point.percentage:.1f}%</b>`,
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        borderWidth: 2,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b><br>{point.percentage:.1f}%",
          distance: 20,
        },
      },
    },
    series: [
      {
        name: "Attendance",
        colorByPoint: true,
        data: pieData,
      },
    ],
  };

  return (
    <div style={styles.container}>
      <div style={styles.card}>
        <h2 style={styles.header}>Student Attendance</h2>
        <div style={styles.statsContainer}>
          <div style={styles.stat}>
            <span style={styles.statLabel}>Total Days</span>
            <span style={styles.statValue}>{totalWorkingDays}</span>
          </div>
          <div style={styles.stat}>
            <span style={styles.statLabel}>Days Present</span>
            <span style={styles.statValue}>{totalDaysPresent}</span>
          </div>
          <div style={styles.stat}>
            <span style={styles.statLabel}>Days Absent</span>
            <span style={styles.statValue}>{totalWorkingDays - totalDaysPresent}</span>
          </div>
          <div style={styles.stat}>
            <span style={styles.statLabel}>Attendance %</span>
            <span style={styles.statValue}>{attendancePercentage}%</span>
          </div>
        </div>
      </div>
      <div style={styles.chart}>
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      </div>
    </div>
  );
};

StudentAttendanceChart.propTypes = {
  attendanceData: PropTypes.shape({
    totalWorkingDays: PropTypes.number.isRequired,
    totalDaysPresent: PropTypes.number.isRequired,
    totalDaysAbsent: PropTypes.number.isRequired,
  }).isRequired,
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px",
    borderRadius: "12px",
  },
  card: {
    background: "linear-gradient(135deg, #6a11cb 0%, #2575fc 100%)",
    color: "#fff",
    borderRadius: "12px",
    padding: "20px",
    width: "300px",
  },
  header: {
    textAlign: "center",
    fontSize: "1.5em",
    marginBottom: "20px",
    fontWeight: "bold",
  },
  statsContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "10px",
  },
  stat: {
    background: "rgba(255, 255, 255, 0.2)",
    padding: "10px",
    borderRadius: "8px",
    textAlign: "center",
  },
  statLabel: {
    fontSize: "0.9em",
    fontWeight: "500",
    marginBottom: "5px",
    display: "block",
  },
  statValue: {
    fontSize: "1.2em",
    fontWeight: "bold",
  },
  chart: {
    flex: 1,
    marginLeft: "20px",
  },
};

export default StudentAttendanceChart;
