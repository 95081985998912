import React from 'react';
import { statusForm, feesRecords, visitorTypeForm } from './formData';
import View from 'app/components/atoms/modalView/Modalview';
import CustomTable from 'app/components/atoms/custom-table/CustomTable';
import FormLayout from 'app/components/render/formlayout';
import SimpleCard from 'app/components/atoms/simple-card/SimpleCard';
import { FiEdit, FiTrash2 } from "react-icons/fi";
import { createVisitorType, getVisitorType, updateVisitorType } from "app/api/VisitorTypeApi";
import _ from "lodash";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { pagePermission, showMessage } from "app/utils/app-functions";
import swal from "sweetalert2";
import Alert from 'app/components/atoms/alert';
import { useSidebar } from "app/components/sidebar/sidebarContext";


let editData = false;

const VisitorType = ({ navigation }) => {

  const dispatch = useDispatch();
  const customerFormRef = useRef();

  const getVisitorTypeSuccess = useSelector((state) => state.visitorTypeReducer.getVisitorTypeSuccess);
  const getVisitorTypeList = useSelector((state) => state.visitorTypeReducer.getVisitorTypeList);
  const getVisitorTypeFailure = useSelector((state) => state.visitorTypeReducer.getVisitorTypeFailure);
  const getVisitorTypeErrorMessage = useSelector((state) => state.visitorTypeReducer.errorMessage);

  const createVisitorTypeSuccess = useSelector((state) => state.visitorTypeReducer.createVisitorTypeSuccess);
  const createVisitorTypeData = useSelector((state) => state.visitorTypeReducer.createVisitorTypeData);
  const createVisitorTypeFailure = useSelector((state) => state.visitorTypeReducer.createVisitorTypeFailure);
  const createVisitorTypeErrorMessage = useSelector((state) => state.locationReducer.errorMessage);


  const updateVisitorTypeSuccess = useSelector((state) => state.visitorTypeReducer.updateVisitorTypeSuccess);
  const updateVisitorTypeData = useSelector((state) => state.visitorTypeReducer.updateVisitorTypeData);
  const updateVisitorTypeFailure = useSelector((state) => state.visitorTypeReducer.updateVisitorTypeFailure);
  const updateVisitorTypeErrorMessage = useSelector((state) => state.locationReducer.errorMessage);

  const [pageItems, setpageItems] = useState({});

  useEffect(() => {
    let data = pagePermission(pageItems, "Visitor", "Visitor Type")
    setpageItems(data)

  }, [navigation]);


  const column = [
    {
      "dataField": "visitorTypeName",
      "text": "Visitor Type",
      "sort": true,

    },
    {
      "dataField": "description",
      "text": "Description",
      "sort": true,

    },

  ]

  if (pageItems.update || pageItems.delete) {
    column.push({
      text: "Action",
      sort: false,
      align: "center",
      headerAlign: "center",
      formatter: (cellContent, row, index) => (
        <div className="icon-container">
          {pageItems.update && <FiEdit
            className="text-primary cursor-pointer"
            size={18}
            onClick={() => onEditForm(row, index)}
          />}
          {pageItems.delete && <FiTrash2
            className="text-danger cursor-pointer"
            size={18}
            onClick={() => {
              onDeleteItem(row, index);

            }}
          ></FiTrash2>}
        </div>

      )
    },)
  }


  const [state, setState] = useState({
    visitorType: '',
    description: ''

  });

  const [createModule, setCreateModule] = useState(false);
  const [loaderModule, setLoaderModule] = useState(false);
  const [deleteModule, setDeleteModule] = useState(false);
  const [itemList, setItemList] = useState([]);
  const [selectedItem, setSelectedItem] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(false);
  const [tableRows, setTableRows] = useState([]);




  useEffect(() => {
    setLoaderModule(true)
    const requestData = {
      isActive: "true"
    }
    dispatch(getVisitorType(requestData))

  }, [navigation]);



  useEffect(() => {
    if (getVisitorTypeSuccess) {
      setItemList(getVisitorTypeList)
      setState({
        ...state,
        visitorTypeList: getVisitorTypeList
      })
      console.log(JSON.stringify(getVisitorTypeList));

      setLoaderModule(false)
      dispatch({ type: "RESET_GET_VISITOR_TYPE" })
    } else if (getVisitorTypeFailure) {
      setItemList([])
      showMessage("warning", getVisitorTypeErrorMessage?.message)
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_VISITOR_TYPE" })
    }
  }, [getVisitorTypeSuccess, getVisitorTypeFailure]);



  useEffect(() => {
    if (createVisitorTypeSuccess) {
      const requestData = {
        isActive: "true"
      }
      dispatch(getVisitorType(requestData))
      const temp_state = [...itemList, createVisitorTypeData?.[0] || {}];

      setItemList(temp_state)
      setLoaderModule(false)
      closeModule()
      Alert({ description: 'Created Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
      dispatch({ type: "RESET_CREATE_VISITOR_TYPE" })
    } else if (createVisitorTypeFailure) {
      setLoaderModule(false)
      showMessage("warning", createVisitorTypeErrorMessage?.message)
      dispatch({ type: "RESET_CREATE_VISITOR_TYPE" })
    }
  }, [createVisitorTypeSuccess, createVisitorTypeFailure]);

  useEffect(() => {
    if (updateVisitorTypeSuccess) {
      updateTable(updateVisitorTypeData[0])
      dispatch({ type: "RESET_UPDATE_VISITOR_TYPE" })
    } else if (updateVisitorTypeFailure) {
      setLoaderModule(false)
      showMessage("warning", updateVisitorTypeErrorMessage?.message)
      dispatch({ type: "RESET_UPDATE_VISITOR_TYPE" })
    }
  }, [updateVisitorTypeSuccess, updateVisitorTypeFailure]);

  const updateTable = (updatedItem) => {
    const temp_state = [...itemList];
    if (deleteModule) {
      let remainingList = _.remove(temp_state, function (n) {
        return n.visitorTypeId != selectedItem.visitorTypeId;
      });
      Alert({ description: 'Deleted Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
      setItemList(remainingList)
    } else {
      temp_state[selectedIndex] = updatedItem;
      Alert({ description: 'Updated Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
      setItemList(temp_state)
    }
    setLoaderModule(false)
    closeModule()
  }


  const closeModule = () => {
    editData = false;
    setCreateModule(false)
    setDeleteModule(false)
  }

  const resetState = () => {
    setState({
      ...state,
      visitorType: '',
      description: ''

    })
    // setTableRows([])
  }

  const onCreateForm = () => {
    resetState()
    editData = false;
    setCreateModule(true)
  }

  const onEditForm = (data, index) => {
    setState({
      ...state,
      visitorType: data?.visitorTypeName || '',
      description: data?.description || '',
    });

    editData = true;
    setSelectedIndex(index);
    setSelectedItem(data);
    setCreateModule(true);
  };

  const onDeleteItem = (data, index) => {
    editData = false;
    setSelectedItem(data)
    setSelectedIndex(index)
    setDeleteModule(true)

  }

  const onSubmitForm = () => {

    const req = {

      visitorTypeName: state?.visitorType || '',
      description: state?.description || '',
    }
    console.log(JSON.stringify(req))
    // return
    if (editData) {
      setLoaderModule(true)
      dispatch(updateVisitorType(req, selectedItem.visitorTypeId))
    }
    else if (deleteModule) {
      const deleteRequest = {
        isActive: "false"
      }
      setLoaderModule(true)
      dispatch(updateVisitorType(deleteRequest, selectedItem.visitorTypeId))
    } else {
      setLoaderModule(true)
      dispatch(createVisitorType(req))

    }
    // return


  }
  const modelTitle = editData ? "Edit " : "Create ";
  const modelButtonLbl = editData ? "" : "Save";

  const validateForm = async () => {
    const validatefom = customerFormRef.current.validateForm();
  }

  const handleClick = (buttonName) => {
    if (buttonName == 'Add') {
      onCreateForm();
    }
  };
  const { sidebarOpen } = useSidebar();


  useEffect(() => {
    if (deleteModule) {
      confromDialog()
    }
  }, [deleteModule])

  const confromDialog = () => {
    return (
      swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          type: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
          cancelButtonText: "No",
        })
        .then((result) => {
          if (result.value) {
            onSubmitForm()
          } else {
            swal.fire({
              title: "Cancelled!",
              text: "Permission denied.",
              type: "error",
              icon: "error",
              timer: 1500,
            });
          }
        })
    )
  }

  return (
    <div >
      <div style={sidebarOpen ? { marginLeft: "10px", marginTop: "-10px" } : { marginLeft: "10px", marginTop: "-50px" }}>
        <View isOpen={createModule} onClose={setCreateModule} size="sm" savetitle={modelButtonLbl} onSubmit={validateForm} title={`${modelTitle} Visitor Type`} >
          <FormLayout ref={customerFormRef} defaultState={state} setDefaultState={setState} dynamicForm={visitorTypeForm} noOfColumn={1} onSubmit={onSubmitForm} />
        </View>
        
        <SimpleCard >
          <CustomTable columns={column} data={itemList} buttonData={pageItems.create ? ['add'] : []} handleClick={handleClick} tableName={"Visitor Type"} state={state} />
        </SimpleCard>
      </div>
    </div>
  )
}

export default VisitorType;