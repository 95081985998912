import React from 'react';
import { Progress } from 'antd';
import PropTypes from 'prop-types';
import ProgressBarProps from './props';
import './styles.css';

const ProgressBar = ({ percent,showInfo = false, status , steps ,type}) => {
    return (
        <>
            <Progress percent={percent} showInfo={showInfo} status={status} steps={steps} type={type} className="gradient-progress-bar"/>
        </>
    );
};

ProgressBar.propTypes = {
    percent: PropTypes.number.isRequired,  
    steps: PropTypes.number.isRequired,  
    status: PropTypes.oneOf([ProgressBarProps.status.success, ProgressBarProps.status.exception,
                             ProgressBarProps.status.normal, ProgressBarProps.status.active]),
    type: PropTypes.oneOf([ProgressBarProps.type.line, ProgressBarProps.type.circle]),

};

ProgressBar.defaultProps = {
    status: ProgressBarProps.status.normal,
    type: ProgressBarProps.type.line,
};
export default ProgressBar;
