import { returnApiCallPromise } from './ApiContent';
import { city } from './APIContainer';

//GET
export function getCity(request) {
  const requestObj = request ? { params: request } : "";
  return (dispatch) => {
    returnApiCallPromise("GET", city, requestObj)
      .then(response => {
        dispatch({
          type: 'GET_CITY_SUCCESS',
          payload: response.data
        })
      }).catch(error => {

        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'GET_CITY_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

export function asyncGetCityInfo(request) {
  const requestObj = request ? { params: request } : "";
  return returnApiCallPromise("GET", city, requestObj)
    .then(response => {
      return {
        error: false,
        message: "Succsss",
        data: response?.data?.data || []
      }
    }).catch(error => {
      let errorMessage = error;
      if (error.response) {
        if (error.response.data.error) {
          errorMessage = error.response.data.message;
        }
      }
      return {
        error: true,
        data: [],
        message: errorMessage
      }
    })
}

//POST
export function createCity(request) {

  return (dispatch) => {
    returnApiCallPromise("POST", city, request)
      .then(response => {
        if (response.data.error) {
          dispatch({
            type: 'CREATE_CITY_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'CREATE_CITY_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'CREATE_CITY_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

export function updateCity(request, cityId) {
  return (dispatch) => {
    returnApiCallPromise("PUT", city + "/" + cityId, request)
      .then(response => {

        if (response.data.error) {
          dispatch({
            type: 'UPDATE_CITY_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'UPDATE_CITY_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'UPDATE_CITY_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}