import { returnApiCallPromise } from './ApiContent';
import { event, examList } from './APIContainer';

//GET
export function getEvent(request) {
  const requestObj = request ? { params: request } : "";
  return (dispatch) => {
    returnApiCallPromise("GET", event, requestObj)
      .then(response => {
        dispatch({
          type: 'GET_EVENT_SUCCESS',
          payload: response.data
        })
      }).catch(error => {

        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'GET_EVENT_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}
//GET event LIST
export function getEventListData(request) {
  const requestObj = request ? { params: request } : "";
  return (dispatch) => {
    returnApiCallPromise("GET", examList, requestObj)
      .then(response => {
        dispatch({
          type: 'GET_EVENT_LIST_SUCCESS',
          payload: response.data
        })
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'GET_EVENT_LIST_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

//GET
export function deleteEventDetails(request) {
  const requestObj = request ? { params: request } : "";
  return (dispatch) => {
    returnApiCallPromise("DELETE", event, requestObj)
      .then(response => {
        dispatch({
          type: 'DELETE_EVENT_DETAILS_SUCCESS',
          payload: response.data
        })
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'DELETE_EVENT_DETAILS_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

//POST
export function createEvent(request) {

  return (dispatch) => {
    returnApiCallPromise("POST", event, request)
      .then(response => {
        if (response.data.error) {
          dispatch({
            type: 'CREATE_EVENT_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'CREATE_EVENT_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'CREATE_EVENT_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

export function updateEvent(request, eventId) {
  return (dispatch) => {
    returnApiCallPromise("PUT", event + "/" + eventId, request)
      .then(response => {
        if (response.data.error) {
          dispatch({
            type: 'UPDATE_EVENT_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'UPDATE_EVENT_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'UPDATE_EVENT_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}