import * as yup from "yup";
import { Formik } from "formik";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import DateTime from "react-datetime";
import { Form, Button } from "react-bootstrap";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { dateConversion, decimalAmount, pagePermission, showMessage } from "app/utils/app-functions";
import FormLayout from "app/components/render/formlayout";
import View from "app/components/atoms/modalView/Modalview";
import CustomTable from "app/components/atoms/custom-table/CustomTable";
import formRender from "app/components/render/formcomponent";
import { getWarehouse } from "app/api/WarehouseApi";
import { getLocation } from "app/api/LocationApi";
import { getMaterialType } from "app/api/MaterialTypeApi";
import { getMaterialCode } from "app/api/MaterialCodeApi";
import { getDelivery } from "app/api/DeliveryApi";
import { getTaxCode } from "app/api/TaxCodeApi";
import { purchaseForm, purchaseAddForm, purchasingForm, subInner, inner, fieldList } from "./formData";
import { getSupplier, createSupplier, getAsyncSupplier } from "app/api/SupplierApi";
import { getPurchase, getAsyncPurchase } from "app/api/PurchaseApi";
import { MdEdit, MdDelete, MdAddTask, MdVisibility } from "react-icons/md";
import swal from "sweetalert2";
import _ from "lodash";
import SimpleCard from 'app/components/atoms/simple-card/SimpleCard'
import { FiEdit, FiSend, FiTrash2 } from 'react-icons/fi';
import TableLayout from "app/components/render/formlayout/tablelayout";
import { Table } from "react-bootstrap";
import CollapseView from "app/components/atoms/CollapseView/CollapseView";
import moment from "moment";
import { createGoodsReceived, getGoodsReceived, updateGoodsReceived } from "app/api/GoodsReceivedApi";
import { getMeasurement } from "app/api/MeasurementApi";
import Alert from "app/components/atoms/alert";
import { getTax } from "app/api/TaxApi";
import { getCurrency } from "app/api/CurrencyApi";
import { lightTheme } from "styles/theme";
import PdfPreview from "app/components/PdfPreview";

let editData = false;
const GoodsReceived = ({ navigation }) => {

  const dispatch = useDispatch();
  const customerFormRef = useRef();
  const purchaseFormRef = useRef()
  const supplierFormRef = useRef()


  const getGoodsReceivedSuccess = useSelector((state) => state.goodsReceivedReducer.getGoodsReceivedSuccess);
  const getGoodsReceivedList = useSelector((state) => state.goodsReceivedReducer.getGoodsReceivedList);
  const getGoodsReceivedFailure = useSelector((state) => state.goodsReceivedReducer.getGoodsReceivedFailure);
  const goodsReceivedErrorMessage = useSelector((state) => state.goodsReceivedReducer.errorMessage);


  const createGoodsReceivedSuccess = useSelector((state) => state.goodsReceivedReducer.createGoodsReceivedSuccess);
  const createGoodsReceivedData = useSelector((state) => state.goodsReceivedReducer.createGoodsReceivedData);
  const createGoodsReceivedFailure = useSelector((state) => state.goodsReceivedReducer.createGoodsReceivedFailure);


  const updateGoodsReceivedSuccess = useSelector((state) => state.goodsReceivedReducer.updateGoodsReceivedSuccess);
  const updateGoodsReceivedData = useSelector((state) => state.goodsReceivedReducer.updateGoodsReceivedData);
  const updateGoodsReceivedFailure = useSelector((state) => state.goodsReceivedReducer.updateGoodsReceivedFailure);

  const getMaterialTypeSuccess = useSelector((state) => state.materialTypeReducer.getMaterialTypeSuccess);
  const getMaterialTypeList = useSelector((state) => state.materialTypeReducer.getMaterialTypeList);
  const getMaterialTypeFailure = useSelector((state) => state.materialTypeReducer.getMaterialTypeFailure);
  const materialTypeErrorMessage = useSelector((state) => state.materialTypeReducer.errorMessage);

  const updateVoiceSuccess = useSelector((state) => state.productReducer.updateVoiceSuccess);
  const updateVoiceData = useSelector((state) => state.productReducer.updateVoiceData);
  const updateVoiceFailure = useSelector((state) => state.productReducer.updateVoiceFailure);

  const getSupplierSuccess = useSelector((state) => state.supplierReducer.getSupplierSuccess);
  const getSupplierList = useSelector((state) => state.supplierReducer.getSupplierList);
  const getSupplierFailure = useSelector((state) => state.supplierReducer.getSupplierFailure);
  const supplierErrorMessage = useSelector((state) => state.supplierReducer.errorMessage);


  const getWarehouseSuccess = useSelector((state) => state.warehouseReducer.getWarehouseSuccess);
  const getWarehouseList = useSelector((state) => state.warehouseReducer.getWarehouseList);
  const getWarehouseFailure = useSelector((state) => state.warehouseReducer.getWarehouseFailure);
  const warehouseErrorMessage = useSelector((state) => state.warehouseReducer.errorMessage);

  const getLocationSuccess = useSelector((state) => state.locationReducer.getLocationSuccess);
  const getLocationList = useSelector((state) => state.locationReducer.getLocationList);
  const getLocationFailure = useSelector((state) => state.locationReducer.getLocationFailure);
  const locationErrorMessage = useSelector((state) => state.locationReducer.errorMessage);

  const getMaterialCodeSuccess = useSelector((state) => state.materialCodeReducer.getMaterialCodeSuccess);
  const getMaterialCodeList = useSelector((state) => state.materialCodeReducer.getMaterialCodeList);
  const getMaterialCodeFailure = useSelector((state) => state.materialCodeReducer.getMaterialCodeFailure);
  const materialCodeErrorMessage = useSelector((state) => state.materialCodeReducer.errorMessage);

  const getDeliverySuccess = useSelector((state) => state.deliveryReducer.getDeliverySuccess);
  const getDeliveryList = useSelector((state) => state.deliveryReducer.getDeliveryList);
  const getDeliveryFailure = useSelector((state) => state.deliveryReducer.getDeliveryFailure);
  const deliveryErrorMessage = useSelector((state) => state.deliveryReducer.errorMessage);

  const getMeasurementSuccess = useSelector((state) => state.measurementReducer.getMeasurementSuccess);
  const getMeasurementList = useSelector((state) => state.measurementReducer.getMeasurementList);
  const getMeasurementFailure = useSelector((state) => state.measurementReducer.getMeasurementFailure);
  const measurementErrorMessage = useSelector((state) => state.measurementReducer.errorMessage);

  const getTaxCodeSuccess = useSelector((state) => state.taxCodeReducer.getTaxCodeSuccess);
  const getTaxCodeList = useSelector((state) => state.taxCodeReducer.getTaxCodeList);
  const getTaxCodeFailure = useSelector((state) => state.taxCodeReducer.getTaxCodeFailure);
  const taxCodeErrorMessage = useSelector((state) => state.taxCodeReducer.errorMessage);

  const getPurchaseSuccess = useSelector((state) => state.purchaseReducer.getPurchaseSuccess);
  const getPurchaseList = useSelector((state) => state.purchaseReducer.getPurchaseList);
  const getPurchaseFailure = useSelector((state) => state.purchaseReducer.getPurchaseFailure);
  const purchaseErrorMessage = useSelector((state) => state.purchaseReducer.errorMessage);




  const errorMessage = useSelector((state) => state.productReducer.errorMessage);


  const [pageItems, setpageItems] = useState({});

  useEffect(() => {
    let data = pagePermission(pageItems, "Inventory", "Goods Received" ,"Purchase Order")
    setpageItems(data)

  }, [navigation]);

  const innerHeading = [
    {
      "dataField": "purchaseName",
      "text": "Warehouse",
      "sort": true
    },
    {
      "dataField": "purchaseName",
      "text": "Receipt Location",
      "sort": true
    },
    {
      "dataField": "purchaseName",
      "text": "Batch Number(mm)",
      "sort": true
    },
    {
      "dataField": "purchaseName",
      "text": "Serial Number(mm)",
      "sort": true
    },
    {
      "dataField": "purchaseName",
      "text": "Length(mm)",
      "sort": true
    },
    {
      "dataField": "purchaseName",
      "text": "Width(mm)",
      "sort": true
    },
    {
      "dataField": "description",
      "text": "Thickness ",
      "sort": false
    },
    {
      "dataField": "action",
      "text": "Action ",
      "sort": false
    }


  ]

  const column = [
    {
      "dataField": "goodsNumber",
      "text": "Bill Number",
      "sort": true
    },
    {
      "dataField": "goodsReceiptDate",
      "text": "Goods Receipt Date",
      "sort": true,
      formatter: (cellContent, row, index) => {
        return dateConversion(cellContent, "DD-MMM-YYYY")
      }
    },
    {
      "dataField": "supplierName",
      "text": "Supplier Name",
      "sort": true
    },
    {
      "dataField": "supplierInvoice",
      "text": "Supplier Invoice",
      "sort": true
    },
    {
      "dataField": "supplierAddress",
      "text": "Address",
      "sort": false
    },
    {
      dataField: "orderStatusId",
      text: "Status",
      sort: true,
      align: "center",
      headerAlign: "center",
      formatter: (cellContent, row, index) => {
        const conInfo = { inputType: "status", status: cellContent }
        return formRender(null, null, conInfo, null);
      }
    },
  ]
  if (pageItems.update || pageItems.delete) {
    column.push({
      text: "Action",
      sort: false,
      align: "center",
      headerAlign: "center",
      formatter: (cellContent, row, index) => (
        <div className="icon-container">
          {pageItems.view && <MdVisibility
            className={`text-success cursor-pointer`}
            size={20}
            onClick={() => onViewForm(row, index)}
          />}
          {pageItems.update && <FiEdit
            className="text-primary cursor-pointer"
            size={18}
            onClick={() => onEditForm(row, index)}
          />}
          {pageItems.delete && <FiTrash2
            className="text-danger cursor-pointer"
            size={18}
            onClick={() => {
              onDeleteItem(row, index)
            }}
          />}
        </div>
      )
    },)
  }

  const formColumn = [
    {
      "dataField": "materialType",
      "text": "Material Type",
      "sort": true
    },
    {
      "dataField": "materialCode",
      "text": "Material Code",
      "sort": true
    },
    {
      "dataField": "materialName",
      "text": "Material Name",
      "sort": true
    },
    {
      "dataField": "Qty",
      "text": "Units",
      "sort": true
    },

    {
      "dataField": "Qty",
      "text": "Qty",
      "sort": true
    },
    {
      "dataField": "Qty",
      "text": "Received Quantity",
      "sort": true
    },



    {
      "dataField": "additem",
      "text": "Add Item Details",
      "sort": false
    },
    {
      "dataField": "action",
      "text": "Action",
      "sort": false
    },
  ]

  const [state, setState] = useState({
    voiceName: "",
    // purchaseOrderDate: new Date(),
    statusId: 1,
    description: "",
    poChoosed: '',
    "customerList": [],
    "venderList": [],
    "leadSourceTypeList": [],
    footerData: [
      { label: 'SubTotal :', value: '0' },
      { label: 'Tax:', value: '0' },
      { label: 'Total:', value: '0' }
    ],
    statusList: [
      { id: 1, name: "open" },
      { id: 2, name: "close" }
    ],
    deletedItems: []

  });

  const [downloadPdf, setDownloadPdf] = useState(false);
  const [createModule, setCreateModule] = useState(false);
  const [loaderModule, setLoaderModule] = useState(false);
  const [editModule, setEditModule] = useState(false);
  const [viewModule, setViewModule] = useState(false);
  const [deleteModule, setDeleteModule] = useState(false);
  const [itemList, setItemList] = useState([]);
  const [selectedItem, setSelectedItem] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(false);
  const [tableRows, setTableRows] = useState([]);
  const [formList, setFormlist] = useState(fieldList);
  const [itemAdded, setItemAdded] = useState(false);





  useEffect(() => {
    setLoaderModule(true)
    const req = {
      isActive: "true"
    }
    dispatch(getGoodsReceived())
    // dispatch(getSupplier())
    // dispatch(getWarehouse())
    // dispatch(getLocation())
    // dispatch(getMaterialType())
    // dispatch(getMaterialCode())
    // dispatch(getDelivery())
    // dispatch(getMeasurement())
    // dispatch(getTaxCode(req))
    // dispatch(getPurchase())
    listFetching()
  }, [navigation]);

  const listFetching = async () => {
    let materialTypeList = await getMaterialType()
    let supplierList = await getAsyncSupplier()
    let measurementList = await getMeasurement()
    let warehouseList = await getWarehouse({
      isActive: "true",
    })
    let locationList = await getLocation()

    let taxList = await getTax({
      isActive: "true",
    })
    let currencyList = await getCurrency()
    let purchase = await getAsyncPurchase()

    const data = purchase.data.map((itm) => {
      return {
        ...itm,
        itemInfo: `${itm.purchaseNumber} - ${itm.supplierName} - ${dateConversion(itm.purchaseOrderDate, "YYYY/MM/DD")}`
      }
    });
    // console.log(data);

    // unitObject = measurementList.getMeasurementList

    setState({
      ...state,
      unitsList: measurementList.getMeasurementList,
      taxCodeList: taxList.getTaxList,
      materialTypeList: materialTypeList.getMaterialTypeList,
      currencyList: currencyList.getCurrencyList,
      supplierList: supplierList.data,
      locationList: locationList.getLocationList,
      warehouseList: warehouseList.getWarehouseList,
      purchaseList: data,
    })

  }

  useEffect(() => {
    if (getPurchaseSuccess) {
      const data = getPurchaseList.map((itm) => {
        return {
          ...itm,
          itemInfo: `${itm.purchaseNumber} - ${itm.supplierName} - ${dateConversion(itm.purchaseOrderDate, "YYYY/MM/DD")}`
        }
      });

      setState({
        ...state,
        purchaseList: data
      });
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_PURCHASE" })
    } else if (getPurchaseFailure) {
      showMessage("warning", purchaseErrorMessage?.message)
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_PURCHASE" })
    }
  }, [getPurchaseSuccess, getPurchaseFailure]);

  useEffect(() => {
    if (getGoodsReceivedSuccess) {
      setItemList(getGoodsReceivedList)
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_GOODSRECEIVED" })
    } else if (getGoodsReceivedFailure) {
      setItemList([])
      showMessage("warning", goodsReceivedErrorMessage?.message)
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_GOODSRECEIVED" })
    }
  }, [getGoodsReceivedSuccess, getGoodsReceivedFailure]);

  useEffect(() => {
    if (createGoodsReceivedSuccess) {
      const temp_state = [...itemList, createGoodsReceivedData[0]];
      setItemList(temp_state)
      dispatch(getGoodsReceived())

      setLoaderModule(false)
      closeModule()
      Alert({ description: 'Created Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
      dispatch({ type: "RESET_CREATE_GOODSRECEIVED" })
    } else if (createGoodsReceivedFailure) {
      setLoaderModule(false)
      showMessage("warning", errorMessage?.message)
      dispatch({ type: "RESET_CREATE_GOODSRECEIVED" })
    }
  }, [createGoodsReceivedSuccess, createGoodsReceivedFailure]);

  useEffect(() => {
    if (updateGoodsReceivedSuccess) {
      updateTable(updateGoodsReceivedData[0])
      dispatch({ type: "RESET_UPDATE_GOODSRECEIVED" })
    } else if (updateGoodsReceivedFailure) {
      setLoaderModule(false)
      showMessage("warning", purchaseErrorMessage?.message)
      dispatch({ type: "RESET_UPDATE_GOODSRECEIVED" })
    }
  }, [updateGoodsReceivedSuccess, updateGoodsReceivedFailure]);

  const updateTable = (updatedItem) => {
    const temp_state = [...itemList];
    if (deleteModule) {
      let remainingList = _.remove(temp_state, function (n) {
        return n.goodsReceivedId != selectedItem.goodsReceivedId;
      });
      Alert({ description: 'Deleted Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
      setItemList(remainingList)
    } else {
      temp_state[selectedIndex] = updatedItem;
      Alert({ description: 'Updated Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
      setItemList(temp_state)
    }
    setLoaderModule(false)
    closeModule()
  }



  useEffect(() => {
    if (getSupplierSuccess) {
      setState({
        ...state,
        supplierList: getSupplierList

      })

      setLoaderModule(false)
      dispatch({ type: "RESET_GET_SUPPLIER" })
    } else if (getSupplierFailure) {
      setState({
        ...state,
        supplierList: []
      })
      showMessage("warning", supplierErrorMessage?.message)
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_SUPPLIER" })
    }
  }, [getSupplierSuccess, getSupplierFailure]);


  useEffect(() => {
    if (getMaterialTypeSuccess) {
      setState({
        ...state,
        materialTypeList: getMaterialTypeList
      })
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_MATERIALTYPE" })
    } else if (getMaterialTypeFailure) {
      setState({
        ...state,
        materialTypeList: getMaterialTypeList
      })
      showMessage("warning", materialTypeErrorMessage?.message)
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_MATERIALTYPE" })
    }
  }, [getMaterialTypeSuccess, getMaterialTypeFailure]);




  useEffect(() => {
    if (deleteModule) {
      confromDialog()
    }
  }, [deleteModule])

  useEffect(() => {
    if (getLocationSuccess) {
      setState({
        ...state,
        locationList: getLocationList
      })
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_LOCATION" })
    } else if (getLocationFailure) {
      setState({
        ...state,
        locationList: []
      })
      showMessage("warning", locationErrorMessage?.message)
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_LOCATION" })
    }
  }, [getLocationSuccess, getLocationFailure]);


  useEffect(() => {
    if (getMaterialCodeSuccess) {
      setState({
        ...state,
        materialCodeList: getMaterialCodeList
      })
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_MATERIACODE" })
    } else if (getMaterialCodeFailure) {
      setState({
        materialCodeList: []
      })
      showMessage("warning", materialCodeErrorMessage?.message)
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_MATERIACODE" })
    }
  }, [getMaterialCodeSuccess, getMaterialCodeFailure]);

  useEffect(() => {
    if (getDeliverySuccess) {
      setState({
        ...state,
        deliveryList: getDeliveryList
      })
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_DELIVERY" })
    } else if (getDeliveryFailure) {
      setState({
        ...state,
        deliveryList: []
      })
      showMessage("warning", deliveryErrorMessage?.message)
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_DELIVERY" })
    }
  }, [getDeliverySuccess, getDeliveryFailure]);

  useEffect(() => {
    if (getMeasurementSuccess) {
      setState({
        ...state,
        unitsList: getMeasurementList
      })
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_MEASUREMENT" })
    } else if (getMeasurementFailure) {
      setState({
        ...state,
        unitsList: []
      })
      showMessage("warning", measurementErrorMessage?.message)
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_MEASUREMENT" })
    }
  }, [getMeasurementSuccess, getMeasurementFailure]);


  useEffect(() => {
    if (getTaxCodeSuccess) {
      setState({
        ...state,
        taxCodeList: getTaxCodeList
      })
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_TAXCODE" })
    } else if (getTaxCodeFailure) {
      setState({
        ...state,
        taxCodeList: []
      })
      showMessage("warning", taxCodeErrorMessage?.message)
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_TAXCODE" })
    }
  }, [getTaxCodeSuccess, getTaxCodeFailure]);

  useEffect(() => {
    if (getWarehouseSuccess) {
      setState({
        ...state,
        warehouseList: getWarehouseList
      })
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_WAREHOUSE" })
    } else if (getWarehouseFailure) {
      setState({
        ...state,
        warehouseList: []
      })
      showMessage("warning", warehouseErrorMessage?.message)
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_WAREHOUSE" })
    }
  }, [getWarehouseSuccess, getWarehouseFailure]);







  const closeModule = () => {
    editData = false;
    setCreateModule(false)
    setDeleteModule(false)
  }

  const resetState = () => {
    setState({
      ...state,
      "goodsReceiptDate": '',
      "supplierId": '',
      contactNumber: '',
      contactPerson: '',
      supplierAddress: '',
      deliveryName: '',
      deliveryPhoneNumber: '',
      deliveryEmailId: '',
      deliveryContactPersonName: '',
      deliveryAddress: '',
      permitId: '',
      shipVia: '',
      fob: '',
      debitTerm: '',
      memo: '',
      insuranceAmount: '',
      logisticAmount: '',
      handlingCost: '',
      otherCost: '',
      statusId: 1,
      poChoosed: '',
      purchaseOrderDate: moment(new Date(), "YYYY-MM-DD"),
      supplierInvoice: '',
    })
    setTableRows([])


  }

  const onCreateForm = () => {
    resetState()
    editData = false;
    setCreateModule(true)
  }
  // const updateData = state.poChoosed.length? state.poChoosed[0] : {};

  const onEditForm = (data, index) => {
    // console.log(JSON.stringify(data));
    const temp_data = state?.supplierList || []
    const filter_data = temp_data.filter(item => item.supplierId === data.supplierId)
    const item = data?.deliveryInfo || ''
    const goodsReceivedItem = data?.goodsReceivedItem || []
    let purchaseList = [];
    if (state && state.purchaseList) {
      purchaseList = state.purchaseList.filter(item => item.purchaseOrderId === data.purchaseOrderId);
    }
    const updateData = state?.poChoosed.length ? state.poChoosed[0] : {};
    setState({
      ...state,
      supplierId: data.supplierName,
      supplier: data.supplierId,
      supplierAddress: data.supplierAddress || "",
      contactNumber: data.primaryNo || "",
      contactPerson: data.supplierContactName || "",
      supplierEmail: data.supplierEmail || "",
      /*  contactPerson: filter_data[0]?.contactName || null,
       supplierEmail: filter_data[0]?.email || null,
       contactNumber: filter_data[0]?.primaryNo || "",
       supplierAddress: filter_data[0]?.address || "", */
      supplierInvoice: data?.supplierInvoice || "",
      deliveryName: item?.deliveryName || "",
      deliveryPhoneNumber: item?.deliveryPhone || "",
      deliveryEmailId: item?.deliveryEmail || "",
      deliveryContactPersonName: item?.deliveryContactPerson || "",
      deliveryAddress: item?.deliveryAddress || "",
      permitId: data?.permitNumber,
      shipVia: data?.shippingType,
      fob: data.fob,
      debitTerm: data.debitTerm,
      memo: data.memo,
      poChoosed: purchaseList,
      logisticAmount: data?.logistics[0]?.logisticAmount || "",
      handlingCost: data?.logistics[0]?.handlingCost || "",
      insuranceAmount: data?.logistics[0]?.insurance || "",
      otherCost: data?.logistics[0]?.otherCost || "",
      purchaseOrderId: parseInt(updateData?.purchaseOrderId) || 0,
    })
    if (goodsReceivedItem) {
      const newTableRows = goodsReceivedItem.map((item) => ({
        grMaterialId: item?.grMaterialId || null,
        materialCodeId: item?.materialId || null,
        materialId: item?.materialId || "",

        taxCode: item?.tax,
        materialTypeId: item?.materialTypeId || "",
        materialCodeId: item?.materialCode || "",
        unit: item?.unit || "",
        materialName: item?.materialName || "",
        qty: item?.receivedQty || "",
        receivedQty: item?.receivedQty || "",
        price: item?.price || "",
        total: item?.total || "",
        totalTax: item?.totalTax || "",

        ...(item.itemDetails && {
          itemDetails: item.itemDetails.map((detail) => ({
            ...detail,
            materialId: item?.materialId || "",

            warehouseId: detail.receiptWarehouse ? detail.receiptWarehouse : null,
            locationId: detail.receiptLocation ? detail.receiptLocation : null,
            length: detail?.itemHeight || null,
            width: detail?.itemWidth || null,
          })),
        }),
      }));

      setTableRows(newTableRows);
    }

    editData = true;
    setSelectedIndex(index);
    setSelectedItem(data);
    setCreateModule(true);
  };
  const handleSupplierChange = (event, name, index,) => {
    let temp_state = "";
    temp_state = {
      ...state,
      [name]: event,
      contactNumber: event?.primaryNo || "",
      contactPerson: event?.contactName || "",
      supplierAddress: event?.address || "",
      supplierEmail: event?.email || "",


    }
    setState(temp_state)

  }
  const handleStatus = (event, name, index,) => {
    const selectedValue = event;
    const statusObject = state?.statusList.find(stat => stat.id == selectedValue);
    const status = statusObject?.name || '';
    setState({
      ...state,
      [name]: selectedValue,
      statusOpen: statusObject?.name || null
    });
  }
  // const updateData = state.poChoosed.length ? state.poChoosed[0] : {};

  const convertToGoodsReceivedItem = (value) => {
    let temp_arr = [];
    const updatedId = selectedItem ? selectedItem : {};
    const updateData = state.poChoosed.length ? state.poChoosed[0] : {};

    try {
      value.map((row, index) => {
        console.log(JSON.stringify(row))
        const req = {
          // purchaseOrderId: parseInt(updateData?.purchaseOrderId) || null,
          grMaterialId: row?.grMaterialId || null,
          materialId: row?.materialId ? parseFloat(row.materialId) : null,
          receivedQty: row?.receivedQty ? parseFloat(row.receivedQty) : 0,
          unit: row.unit ? parseFloat(row.unit) : null,
          qty: row.qty || null,
          goodsStatus: null,
          dimension: null,
          itemDetails: row.itemDetails.map((data, index) => ({
            itemDetailsId: data?.itemDetailsId,
            // purchaseOrderId: parseInt(updateData?.purchaseOrderId) || null,
            materialId: row?.materialId ? parseFloat(row.materialId) : null,
            serialNumber: data.serialNumber || null,
            batchNumber: data.batchNumber || null,
            receiptWarehouse: data?.locationId ? parseFloat(data.warehouseId) : null,
            receiptLocation: data?.locationId ? parseFloat(data.locationId) : null,
            sizeOfTheGoods: data.width || null,
            itemWidth: data?.width || null,
            itemHeight: data?.length || null,
            thickness: data.thickness || null,
            image: null,
            stockStatus: true
          }))
        };
        temp_arr.push(req);
      });
    } catch (error) {
      console.error("Error occurred while converting to goods received item:", error);
    }
    return temp_arr;
  };




  const onAddItem = (index) => {
    const temp_state = [...tableRows];
    const qtyVal = temp_state[index].receivedQty;
    const prevQty = temp_state[index].itemDetails.length || 0;
    let newItemDetails = [];
    const diff = qtyVal - prevQty;
    if (diff > 0) {
      for (let i = 0; i < diff; i++) {
        const defaultItem = {
          receiptWarehouse: "",
          receiptLocation: "",
          batchNumber: "",
          serialNumber: "",
          itemHeight: "",
          itemWidth: "",
          thickness: ""
        };
        newItemDetails.push(defaultItem);
      }
      temp_state[index].itemDetails = [...temp_state[index].itemDetails, ...newItemDetails];
      temp_state[index].prevQty = qtyVal;
      console.log(temp_state[index].itemDetails)
      setTableRows(temp_state)
      setItemAdded(true);
    } else {
      console.log("New quantity must be greater than the previous one.");
    }
  };



  const onDeletePress = (itemIndex) => {
    let temp_state = [...tableRows];
    let addd = tableRows.splice(itemIndex, 1);
    let remainingList = _.remove(temp_state, function (n, index) {
      return index != itemIndex
    });
    setTableRows(remainingList);
    let temp_arr = { ...state }
    setState({
      ...state,
      deletedItems: [...temp_arr.deletedItems, ...addd]
    })
  };
  const onDeleteItem = (data, index) => {
    editData = false;
    setSelectedItem(data)
    setSelectedIndex(index)
    setDeleteModule(true)
  }

  const confromDialog = () => {
    return (
      swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          type: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
          cancelButtonText: "No",
        })
        .then((result) => {
          if (result.value) {
            onSubmitForm()
          } else {
            swal.fire({
              title: "Cancelled!",
              text: "Permission denied.",
              type: "error",
              icon: "error",
              timer: 1500,
            });
          }
        })
    )
  }
  useEffect(() => {
    if (deleteModule) {
      confromDialog()
    }
  }, [deleteModule])

  const validateForm = async () => {
    const validatefom = purchaseFormRef.current.validateForm()
    const validate = customerFormRef.current.validateForm()
    const validat = supplierFormRef.current.validateForm()

    if (validatefom && validate && validat) {
      onSubmitForm()
    }
  }


  const {
    departmentName,
    description,
    customerList
  } = state;




  const handleChange = (event, name, index, parentIndex) => {
    event.persist()
    const numberValue = event.target.value
    let temp_state = [...tableRows]
    temp_state[parentIndex]["itemDetails"][index][event.target.name] = event.target.value;
    setTableRows(temp_state)

  }

  var itemCount = 0;
  const addRow = () => {

    const defaultNewOrderObject = {
      itemCount: 0,
      materialId: '',
      materialCodeId: '',
      materialName: '',
      qty: "",
      receivedQty: "",
      unit: '',
      price: "",
      total: "",
      taxCode: '',
      totalTax: '',
      itemDetails: [

      ]
    };
    itemCount++;
    setTableRows([...tableRows, defaultNewOrderObject]);
  };


  const handleTypeChange = (val, stateName, index, parentIndex) => {
    let temp_state = [...tableRows]
    temp_state[parentIndex]["itemDetails"][index][stateName] = val.target.value;
    setTableRows(temp_state)
  }


  const getMaterialId = (event, index, name) => {
    let selectedmaterial = event.target.value
    let temp_state = [...tableRows];
    const material = state?.materialTypeList || []
    const materialItem = material.filter((item, index) => item.materialId == selectedmaterial);
    console.log(JSON.stringify(materialItem))
    temp_state[index].materialCodeId = materialItem[0].materialId
    temp_state[index].materialCodeIds = materialItem[0].materialId
    temp_state[index].materialName = materialItem[0].materialName


    temp_state[index][event.target.name] = event.target.value
    setTableRows(temp_state)

  }

  const handleGoodsChange = (event, name, index,) => {
    const data = event;
    const updateData = Object.keys(state?.poChoosed || {}).length ? state.poChoosed : {};
    console.log(JSON.stringify(event))
    const updatedId = selectedItem ? selectedItem : {};
    if (!event || event.length === 0) {
      setTableRows([]);
      setState((prevState) => ({
        ...prevState,
        [name]: event,
        supplierId: [],
        inVoiceData: "",
        contactNumber: "",
        contactPerson: "",
        supplierAddress: "",
        permitId: "",
        shipVia: "",
        fob: "",
        debitTerm: "",
        memo: "",
        deliveryName: "",
        deliveryPhoneNumber: "",
        deliveryEmailId: "",
        deliveryContactPersonName: "",
        deliveryAddress: "",
        supplierInvoice: "",
        supplierEmail: "",
        insuranceAmount: "",
        logisticAmount: "",
        handlingCost: "",
        otherCost: "",
      }));
      return;
    }
    const purchaseOrderItem = event?.purchaseOrderItem || []
    const logisticsData = event?.logistics || []
    let dd = _.filter(state?.supplierList || [], { supplierId: event.supplierId });
    let temp_state = {
      ...state,
      [name]: event.label,
      purchaseOrderId: parseInt(updateData?.purchaseOrderId) || 0,
      supplier: event.supplierId,
      supplierId: event.supplierName,
      contactNumber: event.primaryNo ? event.primaryNo : "",
      contactPerson: event.supplierContactName ? event.supplierContactName : "",
      supplierEmail: event.supplierEmail ? event.supplierEmail : "",
      supplierAddress: event.supplierAddress ? event.supplierAddress : "",
      permitId: event?.permitNumber ? event.permitNumber : "",
      shipVia: event?.shippingType ? event.shippingType : "",
      fob: event?.fob ? event.fob : "",
      debitTerm: event?.debitTerm ? event.debitTerm : "",
      memo: event?.memo ? event.memo : "",
      deliveryName: event?.deliveryInfo?.deliveryName ? event.deliveryInfo.deliveryName : "",
      deliveryPhoneNumber: event?.deliveryInfo?.deliveryPhone ? event.deliveryInfo.deliveryPhone : "",
      deliveryEmailId: event?.deliveryInfo?.deliveryEmail ? event.deliveryInfo.deliveryEmail : "",
      deliveryContactPersonName: event?.deliveryInfo?.deliveryContactPerson ? event.deliveryInfo.deliveryContactPerson : "",
      deliveryAddress: event?.deliveryInfo?.deliveryAddress ? event.deliveryInfo.deliveryAddress : "",
      insuranceAmount: logisticsData?.[0]?.insurance || "",
      logisticAmount: logisticsData?.[0]?.logisticAmount || "",
      handlingCost: logisticsData?.[0]?.handlingCost || "",
      otherCost: logisticsData?.[0]?.otherCost || "",
    };
    setState(temp_state);

    const updatedTableRows = [];
    console.log("event", JSON.stringify(event));
    purchaseOrderItem.map((item) => {
      console.log(JSON.stringify(item))

      const req = {
        purchaseOrderId: parseInt(updatedId?.purchaseOrderId) || 0,
        materialTypeId: item?.materialTypeId,
        materialCodeId: item.materialCode,
        materialId: item.materialId,
        materialName: item.materialName,
        qty: item.qty,
        receivedQty: item.receivedQty,
        unit: item.unit,
        itemDetails: item.itemDetails.map((itm) => {
          return {
            purchaseOrderId: parseInt(updateData?.purchaseOrderId) || 0,
            materialId: item?.materialId || "",
            itemDetailsId: parseInt(itm?.itemDetailsId) || "",
            warehouseId: itm?.receiptWarehouse || '',
            locationId: itm?.receiptLocation || '',
            serialNumber: itm?.serialNumber || '',
            length: itm?.itemHeight || '',
            width: itm?.itemWidth || '',
            thickness: itm?.thickness || '',
          };
        })
      };

      updatedTableRows.push(req);
    });

    setTableRows(updatedTableRows);
  };



  const onDeleteInner = (detailIndex, parentIndex) => {
    const updatedTableRows = [...tableRows];
    updatedTableRows[parentIndex].itemDetails.splice(detailIndex, 1);
    setTableRows(updatedTableRows);
  }
  const fetchData = (value, index, name) => {

    let apiResponse = state?.materialCodeList || [];


    if (value.length < 3) {
      let tempstate = [...tableRows];
      tempstate[index].filteredData = [];
      tempstate[index].materialCodeId = value;
      tempstate[index].materialName = '';
      tempstate[index].materialId = '';
      setTableRows(tempstate);
      return;
    }

    let filterObject = (apiResponse ? apiResponse : []).find(item => item.materialCode.toLowerCase() === value.toLowerCase());
    const filteredResults = (apiResponse ? apiResponse : []).filter(material =>
      material.materialCode.toLowerCase().includes(value.toLowerCase())
    );


    let tempstate = [...tableRows];

    tempstate[index].filteredData = filteredResults;
    tempstate[index].materialCodeId = value;
    tempstate[index].materialName = filterObject ? filterObject.materialName : '';
    tempstate[index].materialId = filterObject ? filterObject.materialId : '';
    tempstate[index].materialTypeId = filterObject ? filterObject.materialTypeId : '';

    setTableRows(tempstate);

    if (value.length >= filterObject?.materialCode.length) {
      let tempstate = [...tableRows];
      tempstate[index].filteredData = [];
      setTableRows(tempstate);
    }

  };

  const getMaterialName = async (event, name, index) => {
    let value = event.target.value

    const req = {
      materialCode: value
    };
    const response = await getMaterialCode(req);

    if (response.getMaterialCodeSuccess) {
      setState({
        ...state,
        materialCodeList: response.getMaterialCodeList.data
      });
    } else if (response.getMaterialCodeFailure) {
      setState({
        ...state,
        materialCodeList: []
      });
      showMessage("warning", response.materialCodeErrorMessage?.message);
    }

    if (value == "") {
      let tempstate = [...tableRows];
      tempstate[index].filteredData = [];
      setTableRows(tempstate);
    } else {
      fetchData(value, index, name);
    }
  };




  const expandView = (index, setRows) => {
    const totalItem = tableRows[index]?.itemDetails || [];
    console.log(index)
    return (
      <div>
        <h3 style={{ fontSize: '20px', fontWeight: '500', color: `${lightTheme.primaryColor}` }}>Item Details</h3>
        <Table>
          <thead>
            <tr >
              {innerHeading.map((data, ind) => (
                <th key={ind}>{data.text}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {
              totalItem.map((data, itemIndex) => {
                return (
                  <tr key={itemIndex}>
                    {inner.map((item, idx) => {
                      return (
                        <td key={idx}>
                          <TableLayout isField={true} onChangeCallback={{ "handleChange": handleChange, "handleTypeChange": handleTypeChange }} iconPress={{ "onDeleteInner": onDeleteInner }} defaultState={state} setDefaultState={setTableRows} setDataList={setTableRows} parentIndex={index} subItemName="itemDetails" index={itemIndex} layoutType="table" dataList={tableRows[index]?.itemDetails || []} dynamicForm={item} /* style={{ textAlign: 'center' }} */ />
                        </td>
                      )
                    }
                    )}
                  </tr>
                )
              }
              )}

          </tbody>
        </Table>
      </div>
    );
  };

  // const updateData = state.poChoosed.length? state.poChoosed[0] : {};

  const onSubmitFormValidate = () => {
    return
  }

  const onSubmitForm = () => {
    const updatedId = selectedItem ? selectedItem : {};
    const updateData = state?.poChoosed.length ? state.poChoosed : {};



    const requestData = {
      purchaseOrderId: parseInt(updateData?.purchaseOrderId) || 0,
      goodsReceiptDate: dateConversion(new Date(), "YYYY-MM-DD") || null,
      supplierId: state?.supplier || state?.supplierId || 0,
      supplierEmail: state?.supplierEmail || null,
      description: "product",
      orderStatusId: state?.statusOpen || null,
      createdBy: state?.contactPerson || null,
      primaryNo: state?.contactNumber || null,
      secondaryNo: "32233242",
      supplierInvoice: state?.supplierInvoice || null,
      permitNumber: state?.permitId || 0,
      shippingType: state?.shipVia || 'empty',
      fob: state?.fob || null,
      debitTerm: state?.debitTerm || null,
      memo: state?.memo || null,
      paymentStatus: false,
      goodsReceivedItem: convertToGoodsReceivedItem(tableRows),
      goodsreceivedbill: [
        {
          // purchaseOrderId: parseInt(updateData?.purchaseOrderId) || null,
          goodsReceivedId: parseInt(updatedId?.goodsReceivedId) || 0,
          fob: state?.fob || null,
          memo: state?.memo || null,
          createdBy: null,
          debitTerm: state?.debitTerm || null,
          deliveryDate: "2022-06-29",
          permitNumber: state?.permitId || 0,
          shippingType: state?.shipVia || 'empty',
          logisticAmount: null,
          supplierInvoice: state?.supplierInvoice || null,
        }
      ],
      deliveryInfo: [
        {

          poDeliveryId: parseInt(updatedId?.deliveryInfo?.poDeliveryId) || 0,
          name: state?.deliveryName || '',
          phone: state?.deliveryPhoneNumber || null,
          email: state?.deliveryEmailId || null,
          address: state?.deliveryAddress || null,
          contactPerson: state?.deliveryContactPersonName || null,
          // purchaseOrderId: parseInt(updateData?.purchaseOrderId) || 0,
        }
      ],
      "logistics": [
        {
          "logisticAmount": state?.logisticAmount || null,
          "handlingCost": state?.handlingCost || null,
          "insurance": state?.insuranceAmount || null,
          "otherCost": state?.otherCost || null,
        }
      ]
    }
    console.log(JSON.stringify(requestData))
    function isValid(value) {
      return value !== null/*  && value !== undefined */ && value !== '' && value !== 0 &&
        !(typeof value === 'string' && value.trim() === '') &&
        !(typeof value === 'number' && isNaN(value));
    }

    function validateFormData(goodsReceivedItem) {
      if (!Array.isArray(goodsReceivedItem) || goodsReceivedItem.length === 0) {
        return false;
      }

      for (const item of goodsReceivedItem) {
        if (
          // !isValid(item.poMaterialId) ||
          !isValid(item.materialId) ||
          !isValid(item.materialName) ||
          !isValid(item.qty) ||
          !isValid(item.receivedQty) ||
          !isValid(item.unit) ||
          !isValid(item.price) ||
          !isValid(item.total) ||
          !isValid(item.tax) ||
          !isValid(item.taxVal) ||
          !isValid(item.totalTax)
          // !isValid(item.receivedQty)
          // !isValid(item.orderStatusId)
        ) {
          return false;
        }

        if (!Array.isArray(item.itemDetails) || item.itemDetails.length === 0) {
          return false;
        }

        for (const detail of item.itemDetails) {
          if (

            !isValid(detail.receiptWarehouse) ||
            !isValid(detail.receiptLocation) ||
            !isValid(detail.itemWidth) ||
            !isValid(detail.itemHeight) ||
            !isValid(detail.batchNumber) ||
            !isValid(detail.serialNumber) ||
            !isValid(detail.thickness)
          ) {
            return false;
          }
        }
      }

      return true;
    }

    if (!validateFormData(requestData.goodsReceivedItem)) {
      console.log(JSON.stringify(requestData))
      Alert({ description: 'Fill all the add item details.', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });

      //  showMessage('warning', "Fill all the add item details");
    } else {
      setCreateModule(false)
      if (editData) {
        requestData.goodsReceivedItem.forEach((item) => {
          if (item.grMaterialId == null) {

            delete item.grMaterialId;

          }
        });
        requestData.goodsReceivedItem.forEach((item) => {

          if (item.itemDetailsId == null) {

            delete item.itemDetailsId;
          }
        });
        let data = state.deletedItems
        let deletingRow = data.map(ele => {
          ele.isDelete = true
          requestData.goodsReceivedItem.push(ele)
        })
        setLoaderModule(true)
        dispatch(updateGoodsReceived(requestData, selectedItem.goodsReceivedId))
      } else if (deleteModule) {
        const deleteRequest = {
          isActive: "false"
        }
        setLoaderModule(true)
        dispatch(updateGoodsReceived(deleteRequest, selectedItem.goodsReceivedId))
      } else {

        requestData.goodsReceivedItem.forEach((item) => {
          delete item.grMaterialId
        })
        requestData.goodsreceivedbill.forEach((item) => {
          delete item.goodsReceivedId
        })
        requestData.deliveryInfo.forEach((item) => {
          delete item.purchaseOrderId
        })
        /*  requestData.goodsReceivedItem.forEach((itm) => {
           itm.itemDetails.forEach((item) => {
             {
               delete item.itemDetailsId
   
   
             }
           })
         }) */
        requestData.deliveryInfo.map((item) => {
          delete item.poDeliveryId
        })
        console.log(JSON.stringify(requestData))
        setLoaderModule(true)
        dispatch(createGoodsReceived(requestData))


      }
    }




  }
  const modelTitle = editData ? "Edit " : "Create ";
  const modelButtonLbl = editData ? "" : "Save";


  const handleClick = (buttonName) => {
    if (buttonName == 'Add') {
      onCreateForm();
    }
  };

  const rows = [
    { label: "Goods Number", value: "goodsNumber" },
    { label: "Supplier Name", value: "supplierName" },
    { label: "Primary No", value: "primaryNo" },
    { label: "Secondary No", value: "secondaryNo" },
    { label: "Supplier Email", value: "supplierEmail" },
    { label: "Supplier Address", value: "supplierAddress" },
    { label: "Supplier Contact Name", value: "supplierContactName" },
    { label: "Supplier Invoice", value: "supplierInvoice" },
    { label: "Debit Term", value: "debitTerm" },
    { label: "Delivery Date", value: "deliveryDate", isDate: true },
    { label: "Goods Receipt Date", value: "goodsReceiptDate", isDate: true }
  ]


  const itemFields = [
    { label: "Material Name", value: "materialName" },
    { label: "Quantity", value: "qty", prefix: "Qty: " },
    { label: "Received Qty", value: "receivedQty", prefix: "Qty: " },
    { label: "Unit", value: "unitName", prefix: "Unit: " }
    /* { label: "Price", value: "price", prefix: "Price: $", isCurrency: true },
    { label: "Total", value: "total", prefix: "Total: $", isCurrency: true },
    { label: "Tax", value: "totalTax", prefix: "Tax: $", isCurrency: true }, */
  ];

  const onViewForm = (data) => {
    setState({
        ...state,
        dataList: data
    })
    setDownloadPdf(true)
}

  return (
    <div >
      <div style={{ marginTop: "-10px" }}>
        <View isOpen={createModule} onClose={setCreateModule} size="fullscreen" savetitle={modelButtonLbl} onSubmit={validateForm} title={`${modelTitle} Goods Received`}>
          <FormLayout ref={purchaseFormRef} defaultState={state} setDefaultState={setState} onChangeCallback={{ "handleGoodsChange": handleGoodsChange, "handleStatus": handleStatus }} dynamicForm={purchaseForm} noOfColumn={4} onSubmit={onSubmitFormValidate} />
          <FormLayout ref={customerFormRef} defaultState={state} setDefaultState={setState} dynamicForm={purchaseAddForm} noOfColumn={3} onChangeCallback={{ "handleSupplierChange": handleSupplierChange }} onSubmit={onSubmitFormValidate} />
          <p style={{ fontSize: '20px', fontWeight: '500' }} className="label-color">Logistics and handling cost</p>
          <FormLayout ref={supplierFormRef} defaultState={state} setDefaultState={setState} dynamicForm={purchasingForm} noOfColumn={4} onSubmit={onSubmitFormValidate} />
          <CollapseView onChangeCallback={{ "getMaterialId": getMaterialId, "getMaterialName": getMaterialName }} state={state} setState={setState} header={formColumn} tableRows={tableRows} setTableRows={setTableRows} expandView={expandView} iconPress={{ "onAddItem": onAddItem, "onDeletePress": onDeletePress, }} btnName={"Add Item"} addRow={addRow} fieldList={formList} calculationTitle={[]}></CollapseView>
        </View>
        <View isOpen={downloadPdf} onClose={setDownloadPdf} centered={true} size={'lg'} title="Download Pdf">
          <PdfPreview data={state?.dataList || []} title={"Good Received"} subTitle={"Received Items"} rows={rows} itemFields={itemFields} ItemKey={"goodsReceivedItem"} totalSection={false} />
        </View>

        <SimpleCard>
          <CustomTable columns={column} data={itemList} buttonData={pageItems.create ? ['add'] : []} handleClick={handleClick} tableName={"Goods Received"} state={state} />
        </SimpleCard>
      </div>
    </div>
  )
};

export default GoodsReceived;
