import * as yup from "yup";
import { Formik } from "formik";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import DateTime from "react-datetime";
import { Form, Button } from "react-bootstrap";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import FormLayout from "app/components/render/formlayout";
import { employeeStatusForm } from "./formData";
import { MdEdit, MdPending, MdViewModule } from "react-icons/md";
import { getLeaveType } from "app/api/LeaveTypeApi";
import { getLeaveStatus } from "app/api/LeaveStatusApi";
import swal from "sweetalert2";
import _ from "lodash";
import formRender from "app/components/render/formcomponent";
import { createEmployeeLeave, getEmployeeLeave, updateEmployeeLeave } from "app/api/EmployeeLeaveApi";
import { getEmployee } from "app/api/EmployeeApi";
import moment from 'moment'
import { getHolidays } from "app/api/HolidaysApi";
import { dateConversion, isBeforeDate, showMessage, timeConversion } from "app/utils/app-functions";
import { getLeaveBalance } from "app/api/LeaveBalanceApi";
import { getWorkingDays } from "app/api/WorkingDaysApi";
import SimpleCard from "app/components/atoms/simple-card/SimpleCard";
import CustomTable from "app/components/atoms/custom-table/CustomTable";
import { asyncGetEmployeLeaveInfo } from "app/api/EmployeeLeaveApi";
import { asyncGetEmployeeInfo } from "app/api/EmployeeApi";
import { asyncGetLeaveTypeInfo } from "app/api/LeaveTypeApi";
import { useSidebar } from "app/components/sidebar/sidebarContext";
import Alert from "app/components/atoms/alert";

const ExcelJS = require('exceljs');

let editData = false;


const EmployeeStatus = ({ navigation }) => {

    const dispatch = useDispatch();
    const customerFormRef = useRef();

    const getEmployeeLeaveSuccess = useSelector((state) => state.employeeLeaveReducer.getEmployeeLeaveSuccess);
    const getEmployeeLeaveList = useSelector((state) => state.employeeLeaveReducer.getEmployeeLeaveList);
    const getEmployeeLeaveFailure = useSelector((state) => state.employeeLeaveReducer.getEmployeeLeaveFailure);

    const getLeaveBalanceSuccess = useSelector((state) => state.leaveBalanceReducer.getLeaveBalanceSuccess);
    const getLeaveBalanceList = useSelector((state) => state.leaveBalanceReducer.getLeaveBalanceList);
    const getLeaveBalanceFailure = useSelector((state) => state.leaveBalanceReducer.getLeaveBalanceFailure);
    const leaveBalanceErrorMessage = useSelector((state) => state.leaveBalanceReducer.errorMessage);

    const getHolidaysSuccess = useSelector((state) => state.holidaysReducer.getHolidaysSuccess);
    const getHolidaysList = useSelector((state) => state.holidaysReducer.getHolidaysList);
    const getHolidaysFailure = useSelector((state) => state.holidaysReducer.getHolidaysFailure);
    const holidaysErrorMessage = useSelector((state) => state.holidaysReducer.errorMessage);

    const getLeaveTypeSuccess = useSelector((state) => state.leaveTypeReducer.getLeaveTypeSuccess);
    const getLeaveTypeList = useSelector((state) => state.leaveTypeReducer.getLeaveTypeList);
    const getLeaveTypeFailure = useSelector((state) => state.leaveTypeReducer.getLeaveTypeFailure);
    const leaveTypeErrorMessage = useSelector((state) => state.leaveTypeReducer.errorMessage);

    const getLeaveStatusSuccess = useSelector((state) => state.leaveStatusReducer.getLeaveStatusSuccess);
    const getLeaveStatusList = useSelector((state) => state.leaveStatusReducer.getLeaveStatusList);
    const getLeaveStatusFailure = useSelector((state) => state.leaveStatusReducer.getLeaveStatusFailure);
    const leaveStatusErrorMessage = useSelector((state) => state.leaveStatusReducer.errorMessage);

    const getWorkingDaysSuccess = useSelector((state) => state.workingDaysReducer.getWorkingDaysSuccess);
    const getWorkingDaysList = useSelector((state) => state.workingDaysReducer.getWorkingDaysList);
    const getWorkingDaysFailure = useSelector((state) => state.workingDaysReducer.getWorkingDaysFailure);
    const workingDaysErrorMessage = useSelector((state) => state.workingDaysReducer.errorMessage);

    const getEmployeeSuccess = useSelector((state) => state.employeeReducer.getEmployeeSuccess);
    const getEmployeeList = useSelector((state) => state.employeeReducer.getEmployeeList);
    const getEmployeeFailure = useSelector((state) => state.employeeReducer.getEmployeeFailure);
    const employeeErrorMessage = useSelector((state) => state.employeeReducer.errorMessage);

    const createEmployeeLeaveSuccess = useSelector((state) => state.employeeLeaveReducer.createEmployeeLeaveSuccess);
    const createEmployeeLeaveData = useSelector((state) => state.employeeLeaveReducer.createEmployeeLeaveData);
    const createEmployeeLeaveFailure = useSelector((state) => state.employeeLeaveReducer.createEmployeeLeaveFailure);

    const updateEmployeeLeaveSuccess = useSelector((state) => state.employeeLeaveReducer.updateEmployeeLeaveSuccess);
    const updateEmployeeLeaveData = useSelector((state) => state.employeeLeaveReducer.updateEmployeeLeaveData);
    const updateEmployeeLeaveFailure = useSelector((state) => state.employeeLeaveReducer.updateEmployeeLeaveFailure);

    const employeeLeaveErrorMessage = useSelector((state) => state.employeeLeaveReducer.errorMessage);

    const column = [
        {
            "dataField": "empCode",
            "text": "Employee Code",
            "sort": true,
            formatter: (cellContent, row, index) => (
                (cellContent + " " + `(${row.firstName + " " + row.lastName})`)
            )
        },
        {
            "dataField": "leaveTypeName",
            "text": "Leave Type",
            "sort": true,
        },
        {
            "dataField": "requestedName",
            "text": "Requested To",
            "sort": true,
        },
        {
            "dataField": "acceptedName",
            "text": "Processed By",
            "sort": true,
        },

        {
            "dataField": "startDate",
            "text": "Leave Date",
            "sort": true,
            formatter: (cellContent, row, index) => (
                dateConversion(row.startDate, "DD-MM-YYYY") + "  to  " + dateConversion(row.endDate, "DD-MM-YYYY")
            )
        },
        {
            "dataField": "createdAt",
            "text": "Request Date",
            "sort": true,
            formatter: (cellContent, row, index) => (
                dateConversion(row.createdAt, "DD-MM-YYYY")
            )
        },
        {
            dataField: "leaveStatusName",
            text: "Status",
            sort: true,
            formatter: (cellContent, row, index) => {
                let status = ''
                if (cellContent === 'Approve') {
                    status = 'Approved';
                } else if (cellContent === 'Decline') {
                    status = 'Declined';
                } else if (cellContent === 'Pending') {
                    status = 'Pending';
                }
                const conInfo = { inputType: "checkInStatus", statusName: status }
                return formRender(null, null, conInfo, null);
            }
        },

    ]

    const [state, setState] = useState({
        employeeList: [],
        leaveTypeList: [],
        leaveStatusList: [],
        holidayList: [],
        leaveBalanceList: [],
        workingDaysList: [],
        balance: "",
        filterBalanceList: [],
        employeeDetails: []
    });

    const [createModule, setCreateModule] = useState(false);
    const [loaderModule, setLoaderModule] = useState(false);
    const [editModule, setEditModule] = useState(false);
    const [viewModule, setViewModule] = useState(false);
    const [deleteModule, setDeleteModule] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [assignModule, setAssignModule] = useState(false);
    const [selectedItem, setSelectedItem] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);
    const [balanceLeave, setBalanceLeave] = useState([])


    const listFetching = async () => {
        const requestData = {
            isActive: "true",
            leaveStatusId :1

        }
        let employeeList = await asyncGetEmployeeInfo(requestData)


        setState({
            ...state,
            employeeList: employeeList?.data || [],


        })

    }



    useEffect(() => {
        setLoaderModule(true)
        const req = { isActive: "true" }
        const request = { leaveStatusId: 1 }
        dispatch(getEmployeeLeave())
        dispatch(getLeaveType(req))
        dispatch(getEmployee(req))
        dispatch(getLeaveStatus())
        dispatch(getHolidays())
        dispatch(getLeaveBalance())
        dispatch(getWorkingDays())
        listFetching()
    }, [navigation]);

    useEffect(() => {
        if (getEmployeeLeaveSuccess) {
            setItemList(getEmployeeLeaveList)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_EMPLOYEE_LEAVE" })
        } else if (getEmployeeLeaveFailure) {
            setItemList([])
            showMessage("warning", employeeLeaveErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_EMPLOYEE_LEAVE" })
        }
    }, [getEmployeeLeaveSuccess, getEmployeeLeaveFailure]);

    useEffect(() => {
        if (getHolidaysSuccess) {
            setState({
                ...state,
                holidayList: getHolidaysList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_HOLIDAYS" })
        } else if (getHolidaysFailure) {
            setState({
                ...state,
                holidayList: []
            })
            showMessage("warning", holidaysErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_HOLIDAYS" })
        }
    }, [getHolidaysSuccess, getHolidaysFailure]);

    useEffect(() => {
        if (getWorkingDaysSuccess) {
            setState({
                ...state,
                workingDaysList: getWorkingDaysList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_WORKING_DAYS" })
        } else if (getWorkingDaysFailure) {
            setState({
                ...state,
                workingDaysList: []
            })
            showMessage("warning", workingDaysErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_WORKING_DAYS" })
        }
    }, [getWorkingDaysSuccess, getWorkingDaysFailure]);

    useEffect(() => {
        if (getLeaveBalanceSuccess) {

            setState(prevState => ({
                ...prevState,
                leaveBalanceList: getLeaveBalanceList,

            }));

            setLoaderModule(false)
            dispatch({ type: "RESET_GET_LEAVE_BALANCE" })
        } else if (getLeaveBalanceFailure) {
            setState({
                ...state,
                leaveBalanceList: []
            })
            showMessage("warning", leaveBalanceErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_LEAVE_BALANCE" })
        }
    }, [getLeaveBalanceSuccess, getLeaveBalanceFailure, getLeaveBalanceList]);

    useEffect(() => {
        if (getLeaveTypeSuccess) {
            setState({
                ...state,
                leaveTypeList: getLeaveTypeList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_LEAVE_TYPE" })
        } else if (getLeaveTypeFailure) {
            setState({
                ...state,
                leaveTypeList: []
            })
            showMessage("warning", leaveTypeErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_LEAVE_TYPE" })
        }
    }, [getLeaveTypeSuccess, getLeaveTypeFailure]);

    useEffect(() => {
        if (getEmployeeSuccess) {
            setState({
                ...state,
                employeeList: getEmployeeList
            })
            console.log(JSON.stringify(state.employeeList))
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_EMPLOYEE" })
        } else if (getEmployeeFailure) {
            setState({
                ...state,
                employeeList: []
            })
            showMessage("warning", employeeErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_EMPLOYEE" })
        }
    }, [getEmployeeSuccess, getEmployeeFailure]);

    useEffect(() => {
        if (getLeaveStatusSuccess) {
            setState({
                ...state,
                leaveStatusList: getLeaveStatusList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_LEAVE_STATUS" })
        } else if (getLeaveStatusFailure) {
            setState({
                ...state,
                leaveStatusList: []
            })
            showMessage("warning", leaveStatusErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_LEAVE_STATUS" })
        }
    }, [getLeaveStatusSuccess, getLeaveStatusFailure]);

    useEffect(() => {
        if (createEmployeeLeaveSuccess) {

            const temp_state = [...itemList, createEmployeeLeaveData[0]];
            setItemList(temp_state)
            setLoaderModule(false)
            closeModule()
            showMessage("success", "Created Successfully")
            dispatch({ type: "RESET_CREATE_EMPLOYEE_LEAVE" })
        } else if (createEmployeeLeaveFailure) {
            setLoaderModule(false)
            showMessage("warning", employeeLeaveErrorMessage)
            dispatch({ type: "RESET_CREATE_EMPLOYEE_LEAVE" })
        }
    }, [createEmployeeLeaveSuccess, createEmployeeLeaveFailure]);


    useEffect(() => {
        if (updateEmployeeLeaveSuccess) {
            const request = { leaveStatusId: 1 }
            dispatch(getEmployeeLeave(request))
            updateTable(updateEmployeeLeaveData[0])
            dispatch({ type: "RESET_UPDATE_EMPLOYEE_LEAVE" })
        } else if (updateEmployeeLeaveFailure) {
            setLoaderModule(false)
            showMessage("warning", employeeLeaveErrorMessage?.message)
            dispatch({ type: "RESET_UPDATE_EMPLOYEE_LEAVE" })
        }
    }, [updateEmployeeLeaveSuccess, updateEmployeeLeaveFailure]);

    const updateTable = (updatedItem) => {
        const temp_state = [...itemList];
        if (deleteModule || assignModule) {
            let remainingList = _.remove(temp_state, function (n) {
                return n.empLeaveId != updatedItem.empLeaveId;
            });
            assignModule ? showMessage("success", "Processed Successfully") : showMessage("success", "Deleted Successfully")
            setItemList(remainingList)
        } else {
            temp_state[selectedIndex] = updatedItem;
            showMessage("success", "Updated Successfully")
            setItemList(temp_state)
        }
        setLoaderModule(false)
        closeModule()
    }

    const closeModule = () => {
        editData = false;
        setCreateModule(false)
        setDeleteModule(false)
        setAssignModule(false)
        setViewModule(false)
    }

    const resetState = () => {
        setState({
            ...state,
            employeeId: "",
            leaveTypeId: "",
            requestedTo: "",
            leavesContent: '',
            leaveStatusId: "",
            startDate: "",
            endDate: "",
            description: "",
            balanceLeaveCount: ""
        })
    }

    const onCreateForm = () => {
        resetState()
        editData = false;
        setCreateModule(true)
    }


    const onProcessForm = (data, index) => {
        setState({
            ...state,
            leaveStatusId: data.leaveStatusId,
            leaveStatusRemarks: "",
            employeeId: data.employeeId,
            startDate: data.startDate,
            endDate: data.endDate,
        })
        setSelectedItem(data)
        setSelectedIndex(index)
        setAssignModule(true)
    }

    const onSelectEndDate = (date) => {
        let start_Date = moment(dateConversion(state.startDate, "YYYY-MM-DD"));
        let end_Date = moment(dateConversion(date, "YYYY-MM-DD"));

        const temp_state = [...state.holidayList]
        var start = moment(start_Date)
        var end = moment(end_Date);
        var list = [];
        let daysList = []
        for (var current = start; current <= end; current.add(1, 'd')) {
            list.push(current.format("YYYY-MM-DD"))
            daysList.push(current.format("dddd"))
        }
        let holidaysCount = 0
        temp_state.forEach((item) => {
            list.forEach((ele) => {
                if (ele === item.holidayDate) {
                    holidaysCount += 1;
                }
                return holidaysCount
            })
        })


        let weekOffDays = 0
        let workingDaysList = (state?.workingDaysList || []).filter((itm) => { return itm.status === false })
        daysList.forEach((ele) => {
            workingDaysList.forEach((itm) => {
                if (ele == itm.dayName) {
                    weekOffDays = weekOffDays + 1;
                }
            })
        })

        const checkStartDate = state.startDate ? true : false
        if (checkStartDate === false) {
            showMessage("warning", "Please Select the Start Date")
        } else if (isBeforeDate(end_Date, start_Date)) {
            showMessage("warning", "End date is larger than start date")
        }
        else {
            let totalDays = end_Date.diff(start_Date, 'days')
            let days = totalDays + 1;
            let calDays = days - holidaysCount - weekOffDays
            setState({
                ...state,
                noOfDays: calDays,
                leavesContent: `No. of leave day(s):${calDays}, No. of holiday(s):${holidaysCount},No.Of WeekOffs: ${weekOffDays}`,
                endDate: date
            })
        }
    }
    let filterBalance = []

    const onEditForm = (data, extra) => {
        let start_Date = moment(dateConversion(data.startDate, "YYYY-MM-DD"));
        let end_Date = moment(dateConversion(data.endDate, "YYYY-MM-DD"));
        let totalDays = end_Date.diff(start_Date, 'days')
        const temp_state = [...state.employeeList]
        const selectedEmpId = data.employeeId;
        const employeeFilter = _.filter(temp_state, ((o) => {
            return o.employeeId === selectedEmpId
        }))

        setState({
            ...state,
            employeeId: employeeFilter,
            leaveTypeId: data?.leaveTypeId || "",
            leaveStatusId: data?.leaveStatusId || "",
            startDate: new Date(data.startDate),
            endDate: new Date(data.endDate),
            description: data.description,
            noOfDays: data.noOfDays,

        })

        editData = true
        // setSelectedIndex(index)
        setSelectedItem(data)
        setViewModule(true)
    }

    const onDeleteItem = (data, index) => {
        editData = false;
        setSelectedIndex(index)
        setSelectedItem(data)
        setDeleteModule(true)
    }

    const validateForm = async () => {
        const validatefom = customerFormRef.current.validateForm();
    }


    const onSubmitForm = () => {
        const storedBalance = localStorage.getItem('balance');
        const selectedEmpId = selectedItem.leaveTypeId || '';
        const balanceList = state.filterBalanceList || [];
        const balanceLeaveCount = storedBalance;
        const leaveDaysCount = parseInt(state?.noOfDays) || "";
        const empId = (employeeId && employeeId.length > 0) ? employeeId[0]?.employeeId : 0;
        const requestDate = {
            employeeId: empId,
            leaveTypeId: leaveTypeId,
            requestedTo: 1,
            leaveStatusId: 1,
            startDate: dateConversion(state.startDate, "YYYY-MM-DD"),
            endDate: dateConversion(state.endDate, "YYYY-MM-DD"),
            description: description,
            noOfDays: noOfDays,
        };

        if (leaveDaysCount > balanceLeaveCount) {
            showMessage("warning", "LEAVE DAYS CROSSING YOUR BALANCE LIMIT")
        } else {
            if (editData) {
                setLoaderModule(true);
                dispatch(updateEmployeeLeave(requestDate, selectedItem.empLeaveId));
            } else if (deleteModule) {
                const deleteRequest = {
                    isActive: "false"
                };
                setLoaderModule(true);
                dispatch(updateEmployeeLeave(deleteRequest, selectedItem.empLeaveId));
            } else if (assignModule) {
                const assignRequest = {
                    acceptedBy: 1,
                    leaveStatusRemarks: state.leaveStatusRemarks,
                    leaveStatusId: state.leaveStatusId,
                    employeeId: state.employeeId,
                    startDate: dateConversion(state.startDate, "YYYY-MM-DD"),
                    endDate: dateConversion(state.endDate, "YYYY-MM-DD"),
                };
                setLoaderModule(true);
                dispatch(updateEmployeeLeave(assignRequest, selectedItem.empLeaveId));
            } else {
                setLoaderModule(true);
                dispatch(createEmployeeLeave(requestDate));
            }
        }
    };
    const handleEmployeeDetails = (event) => {
        let employeeList = state?.employeeList || [];
        let employeeId = event.value;  // Assuming event.value holds the employee ID
        let employeeData = employeeList.filter((emp) => (
            emp.employeeId == employeeId
        ));
        console.log(employeeData);

        setState(prevState => ({
            ...prevState,
            employeeId: event.label,
            employeeData: employeeData
        }));
    }



    const employeeDetailsView = () => {
        console.log(state?.employeeData[0])
        const details = state?.employeeData[0] || {}
        let req = {
            "employeeId": details.employeeId
        }
        setLoaderModule(true)
        dispatch(getEmployeeLeave(req))

    }

    const exportExcel = () => {
        if (itemList.length == 0) {
            Alert({ description: 'No Data found to Export Excel', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            return false
        }
        const workbook = new ExcelJS.Workbook();
        const sheet = workbook.addWorksheet('My Sheet');
        sheet.properties.defaultRowHeight = 20;

        sheet.columns = [
            {
                header: "Employee Code",
                key: "empCode",
                width: 30,
            },
            {
                header: "Employee Name",
                key: "employeeName",
                width: 30,
            },
            {
                header: "requested To",
                key: "requestedName",
                width: 30,
            },
            {
                header: "email",
                key: "email",
                width: 30,
            },
            {
                header: "Phone Number",
                key: "phoneNo",
                width: 30,
            },
            {
                header: "Leave Type Name",
                key: "leaveTypeName",
                width: 30,
            },
            {
                header: "Number Of Days",
                key: "noOfDays",
                width: 30,
            },
            {
                header: "Leave Status Name",
                key: "leaveStatusName",
                width: 30,
            },
            {
                header: "Start Date",
                key: "startDate",
                width: 30,
            },
            {
                header: "End Date",
                key: "endDate",
                width: 30,
            },
        ]

        itemList.map((item, index) => {
            sheet.addRow({
                empCode: item?.empCode || "",
                employeeName: item?.firstName || " " + item?.lastName || " ",
                requestedName: item?.requestedName || "",
                email: item?.email || "",
                noOfDays: item?.noOfDays || "",
                phoneNo: item?.phoneNo || "",
                endDate: item.endDate ? moment(dateConversion(item.endDate, "YYYY-MM-DD")) : "-",
                startDate: item.startDate ? moment(dateConversion(item.startDate, "YYYY-MM-DD")) : "-",
                leaveTypeName: item?.leaveTypeName || "",
                leaveStatusName: item?.leaveStatusName || "",
            })
            sheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
                row.eachCell(function (cell, colNumber) {
                    cell.font = {
                        name: 'Arial',
                        family: 2,
                        bold: false,
                        size: 10,
                    };
                    cell.alignment = {
                        vertical: 'middle', horizontal: 'center'
                    };
                    if (rowNumber <= 10) {
                        row.height = 20;
                        cell.font = {
                            bold: true,
                        };
                    }
                    if (rowNumber >= 10) {
                        for (var i = 1; i < 14; i++) {
                            if (rowNumber == 10) {
                                row.getCell(i).fill = {
                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'C7C7C7' }
                                };
                            }
                            row.getCell(i).border = {
                                top: { style: 'thin' },
                                left: { style: 'thin' },
                                bottom: { style: 'thin' },
                                right: { style: 'thin' }
                            };
                        }
                    }
                });
            });
        });

        workbook.xlsx.writeBuffer().then(data => {
            const blob = new Blob([data],
                {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheet.sheet",
                });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = 'Employee-Leave-Report.xlsx';
            anchor.click();
            window.URL.revokeObjectURL(url);
        })
    }

    const {
        employeeId,
        leaveTypeId,
        description,
        noOfDays,
    } = state;

    const resetFilledData = (value, name) => {
        const selectedEmpId = selectedItem.employeeId
        const filterBalanceList = state.leaveBalanceList.filter((ele) => { return ele.employeeId === selectedEmpId })
        let esistState = state;
        if (name === 'employeeId') {
            const selectedEmpId = value[0]?.employeeId || ''
            const filterBalanceList = state.leaveBalanceList.filter((ele) => { return ele.employeeId === selectedEmpId })
            esistState.filterBalanceList = filterBalanceList
            esistState.employeeId = value;
            esistState.leaveTypeId = '';
            esistState.leavesContent = '';
            esistState.balanceLeaveCount = '';
            esistState.startDate = '';
            esistState.endDate = '';
            esistState.description = '';
            esistState.noOfDays = '';
        } else if (name === 'leaveTypeId') {
            //---->total
            const selectedLeaveTypeId = value.target.value;
            let balance = ""
            let leaveCount = ""
            const balanceList = state?.filterBalanceList?.length > 0 ? state.filterBalanceList : filterBalanceList;

            balanceList.map((ele) => {
                if (selectedLeaveTypeId == ele.leaveTypeId) {
                    balance = ele.leaveBalance
                    leaveCount = ele.noOfLeave
                }
            })
            localStorage.setItem('balance', balance.toString());
            const temp_arr = [...itemList]
            const employeeId = state?.employeeId || "";
            let pendingLeaveDays = 0
            if (employeeId.length > 0) {
                const filter_arr = temp_arr.map((itm) => {
                    if (itm.leaveTypeId == selectedLeaveTypeId && itm.employeeId == employeeId[0].employeeId) {
                        pendingLeaveDays = parseInt(pendingLeaveDays) + parseInt(itm.noOfDays)
                    }
                })
            }

            esistState.leaveTypeId = value.target.value;
            esistState.balance = `${balance - pendingLeaveDays}`
            esistState.balanceLeaveCount = `Balance Leave Count: ${balance - pendingLeaveDays} `;
            esistState.leavesContent = '';
            esistState.startDate = '';
            esistState.endDate = '';
            esistState.description = '';
            esistState.noOfDays = '';
        } else if (name === 'startDate') {
            esistState.startDate = value;
            esistState.endDate = '';
            esistState.description = '';
            esistState.noOfDays = '';
        }
        setState({
            ...state,
            esistState
        })
    }

    const { sidebarOpen } = useSidebar();

    const modelTitle = editData ? "Edit " : "Create ";
    const modelButtonLbl = editData ? "" : "Save";

    const handleClick = (buttonName) => {
        if (buttonName == 'Excel') {
            exportExcel();
        }
    };

    return (
        <div>

            <div style={sidebarOpen ? { marginLeft: "50px", marginTop: "50px", marginBottom: "-60px" } : { marginLeft: "90px", marginTop: "50px", marginBottom: "-90px" }}>

                <FormLayout ref={customerFormRef} defaultState={state} setDefaultState={setState} dynamicForm={employeeStatusForm} onChangeCallback={{ "handleEmployeeDetails": handleEmployeeDetails }} noOfColumn={3} onSubmit={onSubmitForm} onSearch={employeeDetailsView} />

            </div>

            <SimpleCard >
                <CustomTable columns={column} tableName="Leave History" buttonData={['excel']} handleClick={handleClick} data={itemList} />
            </SimpleCard>
        </div>
    )
}

export default EmployeeStatus;