
const qualificationForm = [
    {
        title: "Basic Information",
        formBody : [
            {
                formField: [
                    {
                        label: "Qualification",
                        inputType: "text",
                        placeholder: "Enter Department name",
                        fieldName:"qualificationName",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },                                              
                ]
            }
        ]
    }
];

export {
    qualificationForm
};