import React, { createContext, useState, useContext } from 'react';

const GlobalContext = createContext();

export function GlobalProvider({ children }) {
  const [userInfo, setUserInfo] = useState(false);
  const [unitId, setUnitId] = useState('');
  const [userId, setUserId] = useState('');
  const [roleId, setRoleId] = useState('');
  const [unitCode, setUnitCode] = useState('');
  const [unitToken, setUnitToken] = useState('');
  const [pageItems, setPageItems] = useState([]);

  return (
    <GlobalContext.Provider value={{
      userInfo, setUserInfo,
      unitId, setUnitId,
      userId, setUserId,
      roleId, setRoleId,
      unitCode, setUnitCode,
      unitToken, setUnitToken,
      pageItems, setPageItems
    }}>
      {children}
    </GlobalContext.Provider>
  );
}

export function useGlobalContext() {
  return useContext(GlobalContext);
}
