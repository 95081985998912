import * as yup from "yup";
import { Formik } from "formik";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import DateTime from "react-datetime";
import { Form, Button } from "react-bootstrap";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { roleForm } from "./formData";
import { MdEdit, MdDelete } from "react-icons/md";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import { lightTheme } from "styles/theme";
import swal from "sweetalert2";
import _ from "lodash";
import { createRole, getRole, updateRole } from "app/api/RoleApi";
import { pagePermission, showMessage } from "app/utils/app-functions";
import TreeViewEditor from "app/components/atoms/treeViewEditor/TreeView";
import { createPermission, getPermission, getUserPermission } from "app/api/PermissionApi";
import SimpleCard from 'app/components/atoms/simple-card/SimpleCard'
import FormLayout from "app/components/render/formlayout";
import View from "app/components/atoms/modalView/Modalview";
import CustomTable from "app/components/atoms/custom-table/CustomTable";
import formRender from "app/components/render/formcomponent";
import Alert from "app/components/atoms/alert";
import { useSidebar } from "app/components/sidebar/sidebarContext";


let editData = false;
let pageData = []
const Role = ({ navigation }) => {

    const dispatch = useDispatch();
    const customerFormRef = useRef();

    const getRoleSuccess = useSelector((state) => state.roleReducer.getRoleSuccess);
    const getRoleList = useSelector((state) => state.roleReducer.getRoleList);
    const getRoleFailure = useSelector((state) => state.roleReducer.getRoleFailure);

    const createRoleSuccess = useSelector((state) => state.roleReducer.createRoleSuccess);
    const createRoleData = useSelector((state) => state.roleReducer.createRoleData);
    const createRoleFailure = useSelector((state) => state.roleReducer.createRoleFailure);

    const updateRoleSuccess = useSelector((state) => state.roleReducer.updateRoleSuccess);
    const updateRoleData = useSelector((state) => state.roleReducer.updateRoleData);
    const updateRoleFailure = useSelector((state) => state.roleReducer.updateRoleFailure);

    const getPermissionSuccess = useSelector((state) => state.permissionReducer.getPermissionSuccess);
    const getPermissionList = useSelector((state) => state.permissionReducer.getPermissionList);
    const getPermissionFailure = useSelector((state) => state.permissionReducer.getPermissionFailure);

    const getUserPermissionSuccess = useSelector((state) => state.permissionReducer.getUserPermissionSuccess);
    const getUserPermissionList = useSelector((state) => state.permissionReducer.getUserPermissionList);
    const getUserPermissionFailure = useSelector((state) => state.permissionReducer.getUserPermissionFailure);

    const createPermissionSuccess = useSelector((state) => state.permissionReducer.createPermissionSuccess);
    const createPermissionData = useSelector((state) => state.permissionReducer.createPermissionData);
    const createPermissionFailure = useSelector((state) => state.permissionReducer.createPermissionFailure);

    const roleErrorMessage = useSelector((state) => state.roleReducer.errorMessage);
    const permissionErrorMessage = useSelector((state) => state.permissionReducer.errorMessage);

    const [pageItems, setpageItems] = useState({});

    useEffect(() => {
        let data = pagePermission(pageItems, "Super Admin", "Role")
        setpageItems(data)

    }, [navigation]);

    const column = [
        {
            "dataField": "index",
            "text": "S.No",
            "sort": true,
            formatter: (cellContent, row, index) => (
                index + 1
            )
        },
        {
            "dataField": "roleName",
            "text": "Role Name",
            "sort": true
        },
        {
            dataField: "status",
            text: "Status",
            sort: true,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => {
                const conInfo = { inputType: "status", status: cellContent }
                return formRender(null, null, conInfo, null);
            }
        }
    ]
    if (pageItems.delete || pageItems.update) {
        column.push({
            text: "Action",
            sort: false,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => (
                <div className="icon-container">
                    {pageItems.update && <FiEdit
                        className="text-primary cursor-pointer"
                        size={18}
                        onClick={() => onEditForm(row, index)}
                    ></FiEdit>}
                    {pageItems.delete && <FiTrash2
                        className="text-danger cursor-pointer"
                        size={18}
                        onClick={() => {
                            swal
                                .fire({
                                    title: "Are you sure?",
                                    text: "You won't be able to revert this!",
                                    icon: "warning",
                                    type: "question",
                                    showCancelButton: true,
                                    confirmButtonColor: "#3085d6",
                                    cancelButtonColor: "#d33",
                                    confirmButtonText: "Yes, delete it!",
                                    cancelButtonText: "No",
                                })
                                .then((result) => {
                                    if (result.value) {

                                        onDeleteItem(row, index);
                                    } else {
                                        swal.fire({
                                            title: "Cancelled!",
                                            text: "Permission denied.",
                                            type: "error",
                                            icon: "error",
                                            timer: 1500,
                                        });
                                    }
                                });
                        }}
                    ></FiTrash2>}
                </div>
            )
        })
    }

    const [state, setState] = useState({
        roleName: ""
    });

    const [createModule, setCreateModule] = useState(false);
    const [loaderModule, setLoaderModule] = useState(false);
    const [editModule, setEditModule] = useState(false);
    const [viewModule, setViewModule] = useState(false);
    const [deleteModule, setDeleteModule] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [permissionModule, setPermissionModule] = useState(false);
    const [selectedItem, setSelectedItem] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);

    useEffect(() => {
        setLoaderModule(true)
        const requestData = {
            isActive: "true"
        }
        dispatch(getRole(requestData))

    }, [navigation]);

    useEffect(() => {
        if (getPermissionSuccess) {
            setState({
                ...state,
                permissionList: [...getPermissionList]
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_PERMISSION" })
        } else if (getPermissionFailure) {
            setItemList([])
            showMessage("warning", permissionErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_PERMISSION" })
        }
    }, [getPermissionSuccess, getPermissionFailure]);

    useEffect(() => {
        if (getRoleSuccess) {
            setItemList(getRoleList)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_ROLE" })
        } else if (getRoleFailure) {
            setItemList([])
            showMessage("warning", roleErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_ROLE" })
        }
    }, [getRoleSuccess, getRoleFailure]);

    useEffect(() => {
        if (createRoleSuccess) {
            const temp_state = [...itemList, createRoleData[0]];
            setItemList(temp_state)
            setLoaderModule(false)
            Alert({ description: 'Created Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            closeModule()
            dispatch({ type: "RESET_CREATE_ROLE" })
        } else if (createRoleFailure) {
            setLoaderModule(false)
            showMessage("warning", roleErrorMessage?.message)
            dispatch({ type: "RESET_CREATE_ROLE" })
        }
    }, [createRoleSuccess, createRoleFailure]);

    useEffect(() => {
        if (updateRoleSuccess) {
            updateTable(updateRoleData[0])
            dispatch(getRole({ isActive: "true" }))
            dispatch({ type: "RESET_UPDATE_ROLE" })
        } else if (updateRoleFailure) {
            setLoaderModule(false)
            showMessage("warning", roleErrorMessage?.message)
            dispatch({ type: "RESET_UPDATE_ROLE" })
        }
    }, [updateRoleSuccess, updateRoleFailure]);

    useEffect(() => {
        if (getPermissionSuccess) {
            setState({
                ...state,
                permissionList: [...getPermissionList]
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_PERMISSION" })
        } else if (getPermissionFailure) {
            setItemList([])
            showMessage("warning", permissionErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_PERMISSION" })
        }
    }, [getPermissionSuccess, getPermissionFailure]);

    useEffect(() => {
        if (getUserPermissionSuccess) {
            setState({
                ...state,
                permissionList: [...getUserPermissionList]
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_USER_PERMISSION" })
        } else if (getUserPermissionFailure) {
            setState({
                ...state,
                permissionList: []
            })
            showMessage("warning", permissionErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_USER_PERMISSION" })
        }
    }, [getUserPermissionSuccess, getUserPermissionFailure]);


    useEffect(() => {
        if (createPermissionSuccess) {
            const temp_state = [...itemList, createPermissionData[0]];
            closeModule()
            setLoaderModule(false)
            Alert({ description: 'Updated Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setPermissionModule(false)
            dispatch({ type: "RESET_CREATE_PERMISSION" })
        } else if (createPermissionFailure) {
            setLoaderModule(false)
            showMessage("warning", permissionErrorMessage?.message)
            dispatch({ type: "RESET_CREATE_PERMISSION" })
        }
    }, [createPermissionSuccess, createPermissionFailure]);

    const updateTable = (updatedItem) => {
        const temp_state = [...itemList];
        if (deleteModule) {
            let remainingList = _.remove(temp_state, function (n) {
                return n.roleId != updatedItem.roleId;
            });
            Alert({ description: 'Deleted Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(remainingList)
        } else {
            temp_state[selectedIndex] = updatedItem;
            Alert({ description: 'Updated Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(temp_state)
        }
        setLoaderModule(false)
        closeModule()
    }

    const closeModule = () => {
        editData = false;
        setCreateModule(false)
        setDeleteModule(false)
    }

    const resetState = () => {
        setState({
            ...state,
            roleName: "",
            pageData: []
        })
    }

    const onCreateForm = () => {
        resetState()
        dispatch(getPermission({ type: 'tree' }))
        editData = false;
        setCreateModule(true)
    }

    const onEditForm = (data, index) => {
        setState({
            ...state,
            roleName: data?.roleName || ""
        })
        dispatch(getUserPermission({ "roleId": data?.roleId || 0, type: 'tree' }))
        // dispatch(getPermission({ type: 'tree' }))

        editData = true;
        setSelectedIndex(index)
        setSelectedItem(data)
        setCreateModule(true)
    }

    const onDeleteItem = (data, index) => {
        editData = false;
        setSelectedIndex(index)
        setSelectedItem(data)
        setDeleteModule(true)
    }

    /*  const validateForm = async () => {
         const validatefom = customerFormRef.current.validateForm();
     } */

    const page = () => {
        setPermissionModule(true)
        if (!editData) {
            setState({
                ...state,
                pageData: []
            })
        }
    }

    const onSubmitPermission = () => {
        let data = state?.check || []
        const JobHistory = customerFormRef.current.getPermissionState();

        const requestDate = {
            "pageMeta": handleAccess(JobHistory?.checked || [])
        }
        requestDate.pageMeta = requestDate.pageMeta.filter(ele => ele.pageId !== "");
        setState({
            ...state,
            pageData: requestDate?.pageMeta || []
        })
        // showMessage('success', 'Permission  Added')
        Alert({ description: 'Permission  Added.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });

        setPermissionModule(false)


    }

    const handleAccess = (data) => {
        let acessArr = [];
        let pageIds = new Set();
        if (data.length) {
            data.forEach(ele => {
                ele = ele.toString()
                let parts = ele.split("-");
                let pageId = parts[0];
                let access = parts[1] ? parseInt(parts[1]) : null;
                pageIds.add(pageId);

                let existingPage = acessArr.find(item => item.pageId === pageId);
                if (!existingPage) {
                    acessArr.push({ pageId: pageId, access: access ? [access] : [] });
                } else if (access) {
                    existingPage.access.push(access);
                }
            });

            pageIds.forEach(pageId => {
                let existingPage = acessArr.find(item => item.pageId === pageId);
                if (existingPage.access.length === 0) {
                    let access = [1, 2, 3, 4];
                    if (pageId !== '') {
                        acessArr.push({ pageId: pageId, access: access });
                    }
                }
            });

            return acessArr.length ? acessArr : [];
        }

    }

    const onSubmitForm = () => {
        const requestDate = {
            roleName: roleName,
            pageMeta: state?.pageData || []
        }
        console.log(requestDate);
        
        // return
        if (editData) {
            if (requestDate.roleName == "") {
                Alert({ description: 'Enter Role Name', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            }
            else if (!requestDate.pageMeta.length) {
                Alert({ description: 'Set the Permission', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            }
            else {
                setLoaderModule(true)
                dispatch(updateRole(requestDate, selectedItem.roleId))
            }

        } else if (deleteModule) {
            const deleteRequest = {
                status: false
            }
            setLoaderModule(true)
            dispatch(updateRole(deleteRequest, selectedItem.roleId))
        } else {
            if (requestDate.roleName == "") {
                Alert({ description: 'Enter Role Name', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            }
            else if (!requestDate.pageMeta.length) {
                Alert({ description: 'Set the Permission', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            }
            else {
                setLoaderModule(true)
                dispatch(createRole(requestDate))
            }

        }

    }

    const {
        roleName
    } = state;

    const modelTitle = editData ? "Edit " : "Create ";
    const modelButtonLbl = editData ? "" : "Save";

    const [checked, setChecked] = useState([]);
    const [expanded, setExpanded] = useState([]);

    const handleClick = (buttonName) => {
        if (buttonName == 'Add') {
            onCreateForm();
        }
    };
    const { sidebarOpen } = useSidebar();

    return (
        <div>
            <div style={sidebarOpen ? { marginLeft: "10px", marginTop: "-10px" } : { marginLeft: "10px", marginTop: "-55px" }}>

                <View isOpen={createModule} size="sm" savetitle={modelButtonLbl} onClose={setCreateModule} /* onSubmit={validateForm} */ onSubmit={onSubmitForm} title={`${modelTitle} Role`}>
                    <FormLayout ref={customerFormRef} defaultState={state} setDefaultState={setState} dynamicForm={roleForm} noOfColumn={1}  /* onSearch={page} */ />
                    <Button alignName={"start"} /* btnName={""} */ style={{ backgroundColor: lightTheme.primaryColor, border: "none", padding: "5px 10px", fontSize: "12px", marginTop: "-20px" }} onClick={page} >Set Permission</Button>
                </View>
                <View size={'lg'} isOpen={permissionModule} onClose={setPermissionModule} onSubmit={onSubmitPermission} title={`${modelTitle} Permission`}>
                    {/* <CheckboxTree
                    nodes={state.permissionList}
                    checked={checked}
                    expanded={expanded}
                    onCheck={onCheck}
                    onExpand={onExpand}
                />    */}
                    <TreeViewEditor data={state?.permissionList || []} ref={customerFormRef} state={state} title={'permission'} isShow={''} ></TreeViewEditor>
                </View>

                <View centered={true} size={'sm'} isOpen={deleteModule} onClose={setDeleteModule} savetitle="Yes" onSubmit={onSubmitForm} title="Delete Role">
                    <h6 className="text-capitalize mb-1">Are you sure ?</h6>
                </View>
                <SimpleCard >
                    <CustomTable buttonData={pageItems.create ? ['add'] : []} handleClick={handleClick} tableName={"Role"} columns={column} data={itemList} state={state} />
                </SimpleCard>
            </div>
        </div>
    )
};

export default Role;
