const departmentForm = [
    {
        title: "Basic Information",
        formBody: [
            {
                formField: [
                    {
                        label: "",
                        inputType: "text",
                        placeholder: "Enter Employee Id/Code",
                        fieldName: "empCode",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },
            {
                formField: [
                    {
                        label: "Search",
                        inputType: "button",
                        fieldName: "search",
                        className: "mt-0",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },
        ]
    }
];

const employeePromotionForm = [
    {
        title: "Basic Information",
        formBody: [
            {
                formField: [
                    {
                        label: "Date Of Promotion",
                        inputType: "date",
                        fieldName: "promotionDate",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Department",
                        inputType: "select",
                        options: "departmentList",
                        uniqueKey: "departmentId",
                        displayKey: "departmentName",
                        fieldName: "departmentId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },
            {
                formField: [
                    {
                        label: "Promoted By",
                        inputType: "select",
                        options: "employeeList",
                        uniqueKey: "employeeId",
                        displayKey: "employeeCode",
                        subDisplayKey: "employeeName",
                        fieldName: "promotedById",
                        disable: true,
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    },
                    {
                        label: "Designation",
                        inputType: "select",
                        options: "designationList",
                        uniqueKey: "designationId",
                        displayKey: "designationName",
                        fieldName: "designationId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },
            {
                formField: [
                    {
                        label: "Salary Type",
                        inputType: "select",
                        options: "employeeSalaryTypeList",
                        uniqueKey: "empSalaryTypeId",
                        displayKey: "salaryTypeName",
                        fieldName: "empSalaryTypeId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Gross-Pay",
                        inputType: "text",
                        type: "number",
                        placeholder: "Enter Gross-Pay",
                        fieldName: "grossPay",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Commission Pct",
                        inputType: "text",
                        placeholder: "Enter Commission Pct",
                        fieldName: "commissionPct",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },
            {
                formField: [
                    {
                        label: "Salary Amount",
                        inputType: "text",
                        type: "number",
                        placeholder: "Enter Salary Amount",
                        fieldName: "salaryAmt",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Net-Pay",
                        inputType: "text",
                        type: "number",
                        placeholder: "Enter Net-Pay",
                        fieldName: "netPay",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Deduction",
                        inputType: "text",
                        type: "number",
                        placeholder: "Enter Deduction",
                        fieldName: "deduction",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },
        ]
    }
];


export {
    departmentForm,
    employeePromotionForm
};