
const examTypeForm = [
    {
        title: "Basic Information",
        formBody: [
            {
                formField: [
                    {
                        label: "Exam Type Name",
                        inputType: "text",
                        placeholder: "Enter Exam Type name",
                        fieldName: "examTypeName",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Exam Variant",
                        inputType: "radio",
                        options: "examVariantList",
                        uniqueKey: "examVariantId",
                        // type: "block",
                        displayKey: "examVariant",
                        fieldName: "examVariantId",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    }
                       /*  {
                            label: "Text Box",
                            inputType: "radio",
                            // type:'block',
                            fieldName: "radioname",
                            options: "radioList",
                            uniqueKey: "examVariantId",
                            displayKey: "label",
                            defaultValue: "",
                            config: {
                              required: "required",
                            },
                          }, */
              
                ]
            }
        ]
    }
];

export {
    examTypeForm
};