import { returnApiCallPromise } from './ApiContent';
import { showMaterial } from './APIContainer';

//GET
export async function getShowMaterial(request, materialId) {
  const requestObj = request ? { params: request } : {};

  try {
    const response = await returnApiCallPromise("GET", `${showMaterial}/${materialId}`, requestObj);
    return {
      getShowMaterialSuccess: true,
      getShowMaterialList: response.data.data
    };
  } catch (error) {
    let errorMessage = error;
    if (error.response && error.response.data.error) {
      errorMessage = error.response.data.message;
    }
    return {
      getShowMaterialFailed: true,
      errorMessage: errorMessage
    };
  }
}



//POST
export function createShowMaterial(request) {

  return (dispatch) => {
    returnApiCallPromise("POST", showMaterial, request)
      .then(response => {
        if (response.data.error) {
          dispatch({
            type: 'CREATE_SHOW_MATERIAL_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'CREATE_SHOW_MATERIAL_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'CREATE_SHOW_MATERIAL_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

export function updateShowMaterial(request, cityId) {
  return (dispatch) => {
    returnApiCallPromise("PUT", showMaterial + "/" + cityId, request)
      .then(response => {

        if (response.data.error) {
          dispatch({
            type: 'UPDATE_SHOW_MATERIAL_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'UPDATE_SHOW_MATERIAL_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'UPDATE_SHOW_MATERIAL_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}