import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "./redux/api/apiSlice";
import authSliceReducer from "./redux/auth/authSlice";
import layoutSliceReducer from "./redux/layout/layoutSlice";
import loginSliceReducer from "./redux/login/authSlice";
import ecommerceSliceReducer from "./redux/ecommerce/ecommerceSlice";
import PermissionReducer from "./redux/reducers/PermissionReducer";
import DashboardReducer from "./redux/reducers/DashboardReducer";
import SchoolDashboardReducer from "./redux/reducers/SchoolDashboard";
import DepartmentReducer from "./redux/reducers/DepartmentReducer";
import DesignationReducer from "./redux/reducers/DesignationReduce";
import EmployeeSalaryTypeReducer from "./redux/reducers/EmployeeSalaryTypeReducer";
import CityReducer from "./redux/reducers/CityReducer";
import NationalityReducer from "./redux/reducers/NationalityReducer";
import BloodGroupReducer from "./redux/reducers/BloodGroupReducer";
import QualificationReducer from "./redux/reducers/QualificationReducer";
import MotherTongueReducer from "./redux/reducers/MotherTongueReducer";
import ShiftReducer from "./redux/reducers/ShiftReducer";
import EmployeeReducer from "./redux/reducers/EmployeeReducer";
import EmployeeDetailsReducer from "./redux/reducers/EmployeeDetailsReducer";
import StateReducer from "./redux/reducers/StateReducer";
import CountryReducer from "./redux/reducers/CountryReducer";
import GenderReducer from "./redux/reducers/GenderReducer";
import EmployeeTypeReducer from "./redux/reducers/EmployeeTypeReducer";
import HolidaysReducer from "./redux/reducers/HolidaysReducer";
import EmployeeAttendanceReducer from "./redux/reducers/EmployeeAttendanceReducer";
import LeaveSettingsReducer from "./redux/reducers/LeaveSettingsReducer";
import LeaveTypeReducer from "./redux/reducers/LeaveTypeReducer";
import LeaveMetaReducer from "./redux/reducers/LeaveMetaReducer";
import EmployeeLeaveReducer from "./redux/reducers/EmployeeLeaveReducer";
import LeaveStatusReducer from "./redux/reducers/LeaveStatusReducer";
import UserReducer from "./redux/reducers/UserReducer";
import EmployeeDocumentReducer from "./redux/reducers/EmployeeDocumentReducer";
import EmployeeProofReducer from "./redux/reducers/EmployeeProofReducer";
import ProofTypeReducer from "./redux/reducers/ProofTypeReducer";
import WorkingDaysReducer from "./redux/reducers/WorkingDaysReducer";
import MonthReducer from "./redux/reducers/MonthReducer";
import LeaveBalanceReducer from "./redux/reducers/LeaveBalanceReducer";
import StudentReducer from "./redux/reducers/StudentReducer";
import CasteReducer from "./redux/reducers/CasteReducer";
import SubCasteReducer from "./redux/reducers/SubCasteReducer";
import ReligionReducer from "./redux/reducers/ReligionReducer";
import ClassReducer from "./redux/reducers/ClassReducer";
import SectionReducer from "./redux/reducers/SectionReducer";
import BatchReducer from "./redux/reducers/BatchReducer";
import EnrollmentDetailsReducer from "./redux/reducers/EnrollmentDetailsReducer";
import StudentStatusReducer from "./redux/reducers/StudentStatusReducer";
import StudentAttendanceReducer from "./redux/reducers/StudentAttendanceReducer";
import AttendanceTypeReducer from "./redux/reducers/AttendanceTypeReducer";
import ExamReducer from "./redux/reducers/ExamReducer";
import SubjectReducer from "./redux/reducers/SubjectReducer";
import ExamTypeReducer from "./redux/reducers/ExamTypeReducer";
import HomeWorkReducer from "./redux/reducers/HomeWorkReducer";
import HomeWorkTypeReducer from "./redux/reducers/HomeWorkTypeReducer";
import StudentEnrollmentReducer from "./redux/reducers/StudentEnrollmentReducer";
import CourseReducer from "./redux/reducers/CourseReducer";
import PeriodTimeReducer from "./redux/reducers/PeriodTimeReducer";
import EnrollmentReducer from "./redux/reducers/EnrollmentReducer";
import GradeReducer from "./redux/reducers/GradeReducer";
import SubjectEnrollmentReducer from "./redux/reducers/SubjectEnrollmentReducer";
import MarkSheetReducer from "./redux/reducers/MarkSheetReducer";
import SubjectTeachersReducer from "./redux/reducers/SubjectTeachersReducer";
import TimeTableReducer from "./redux/reducers/TimeTableReducer";
import RoleReducer from "./redux/reducers/RoleReducer";
import AccessReducer from "./redux/reducers/AccessReducer";
import PeriodDaysReducer from "./redux/reducers/PeriodDaysReducer";
import FeesTypeReducer from "./redux/reducers/FeesTypeReducer";
import FeesEnrollmentReducer from "./redux/reducers/FeesEnrollmentReducer";
import StudentFeesPaymentReducer from "./redux/reducers/StudentFeesPaymentReducer";
import UnitReducer from "./redux/reducers/UnitReducer";
import UnitTypeReducer from "./redux/reducers/UnitTypeReducer";
import DocumentReducer from "./redux/reducers/DocumentReducer";
import CircularReducer from "./redux/reducers/CircularReducer";
import AssignTypeReducer from "./redux/reducers/AssignTypeReducer";
import SchoolInfoReducer from "./redux/reducers/SchoolInfoReducer";
import KnowledgeReducer from "./redux/reducers/KnowledgeReducer";
import KnowledgeMaterialTypeReducer from "./redux/reducers/KnowledgeMaterialTypeReducer";
import EventReducer from "./redux/reducers/EventReducer";
import EnquiryReducer from "./redux/reducers/EnquiryReducer";
import EnquiryTypeReducer from "./redux/reducers/EnquiryTypeReducer";
import ExamVariantReducer from "./redux/reducers/ExamVariantReducer";
import DailyExamReducer from "./redux/reducers/DailyExamReducer";
import StudentListReducer from "./redux/reducers/StudentListReducer";
import StudentLeaveReducer from "./redux/reducers/StudentLeaveReducer";
import BookReducer from "./redux/reducers/BookReducer";
import BookTypeReducer from "./redux/reducers/BookTypeReducer";
import KnowledgeBookTypeReducer from "./redux/reducers/KnowledgeBookTypeReducer";
import SyllabusReducer from "./redux/reducers/SyllabusReducer";
import DashboardAttendanceReducer from "./redux/reducers/DashboardAttendanceReducer";
import QuizReducer from "./redux/reducers/QuizReducer";
import PettyCashReducer from "./redux/reducers/PettyCashReducer";

// inventory
import MeasurementReducer from "./redux/reducers/MeasurementReducers";
import MaterialCodeReducer from "./redux/reducers/MaterialCodereducer";
import PurchaseReducer from "./redux/reducers/purchaseReducer";
import SupplierReducer from "./redux/reducers/SupplierReducer";
import WarehouseReducer from "./redux/reducers/WarehouseReducer";
import LocationReducer from "./redux/reducers/LocationReducer";
import MaterialTypeReducer from "./redux/reducers/MaterialTypeReducer";
import TaxCodeReducer from "./redux/reducers/TaxCodeReducer";
import GoodsReceivedReducer from "./redux/reducers/GoodsreceivedReducer";
import DeliveryReducer from "./redux/reducers/DeliveryReducer";
import InventoryMaterialReducer from "./redux/reducers/InventoryReducer";
import InventoryAdjustmenReducer from "./redux/reducers/InventoryAdjustmentReducer";
import ShowMaterialReducer from "./redux/reducers/ShowMaterialReducer";
import SalesReducer from "./redux/reducers/SalesReducer";
import CustomerInventoryReducer from "./redux/reducers/CustomerInventoryReducer";
import UtilityReducer from "./redux/reducers/UtilityReducer";
import PriceGroupReducer from "./redux/reducers/PriceGroupReducer";
import TaxReducer from "./redux/reducers/TaxReducer";
import QuotationReducer from "./redux/reducers/QuotationReducer";
import ReceiptListReducer from "./redux/reducers/ReceiptListReducer";
import InvoiceReducer from "./redux/reducers/InvoiceReducer";
import CreditReducer from "./redux/reducers/CreditNotesReducer";
import PaymentReducer from "./redux/reducers/PaymentReducer";
import DocumentTypeReducer from "./redux/reducers/DocumentTypeReducer";

import VisitorTypeReducer from "./redux/reducers/VisitorTypeReducer";
import VisitorReducer from "./redux/reducers/VisitorReducer";
// import VisitorLogReducer from "./redux/reducers/VisitorLogReducer";
import CurrencyReducer from "./redux/reducers/CurrencyReducer";
import VisitorLogReducer from "./redux/reducers/VisitorLogReducer";
import TaxConfigReducer from "./redux/reducers/TaxConfigReducer";





























import ProductReducer from "./redux/reducers/ProductReducer";
import CreditTermReducer from "./redux/reducers/CreditTermReducer";
import OtherLeaveReducer from "./redux/reducers/OtherLeaveReducer";

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authSliceReducer,
    layout: layoutSliceReducer,
    login: loginSliceReducer,
    ecommerce: ecommerceSliceReducer,

    permissionReducer: PermissionReducer,
    dashboardReducer: DashboardReducer,

    bloodGroupReducer: BloodGroupReducer,
    departmentReducer: DepartmentReducer,
    designationReducer: DesignationReducer,
    cityReducer: CityReducer,
    stateReducer: StateReducer,
    countryReducer: CountryReducer,
    genderReducer: GenderReducer,
    nationalityReducer: NationalityReducer,
    proofTypeReducer: ProofTypeReducer,
    qualificationReducer: QualificationReducer,
    motherTongueReducer: MotherTongueReducer,
    shiftReducer: ShiftReducer,
    employeeTypeReducer: EmployeeTypeReducer,
    holidaysReducer: HolidaysReducer,
    employeeAttendanceReducer: EmployeeAttendanceReducer,
    leaveSettingsReducer: LeaveSettingsReducer,
    leaveTypeReducer: LeaveTypeReducer,
    leaveMetaReducer: LeaveMetaReducer,
    leaveStatusReducer: LeaveStatusReducer,
    employeeLeaveReducer: EmployeeLeaveReducer,
    userReducer: UserReducer,
    employeeDocumentReducer: EmployeeDocumentReducer,
    employeeProofReducer: EmployeeProofReducer,
    workingDaysReducer: WorkingDaysReducer,
    monthReducer: MonthReducer,
    leaveBalanceReducer: LeaveBalanceReducer,
    employeeSalaryTypeReducer: EmployeeSalaryTypeReducer,
    employeeReducer: EmployeeReducer,
    employeeDetailsReducer: EmployeeDetailsReducer,



    studentReducer: StudentReducer,
    casteReducer: CasteReducer,
    subCasteReducer: SubCasteReducer,
    religionReducer: ReligionReducer,
    classReducer: ClassReducer,
    sectionReducer: SectionReducer,
    batchReducer: BatchReducer,
    enrollmentDetailsReducer: EnrollmentDetailsReducer,
    studentStatusReducer: StudentStatusReducer,
    studentAttendanceReducer: StudentAttendanceReducer,
    attendanceTypeReducer: AttendanceTypeReducer,
    examReducer: ExamReducer,
    subjectReducer: SubjectReducer,
    examTypeReducer: ExamTypeReducer,
    homeWorkReducer: HomeWorkReducer,
    homeWorkTypeReducer: HomeWorkTypeReducer,
    studentEnrollmentReducer: StudentEnrollmentReducer,
    studentEnrollmentReducer: StudentEnrollmentReducer,
    courseReducer: CourseReducer,
    periodTimeReducer: PeriodTimeReducer,
    enrollmentReducer: EnrollmentReducer,
    gradeReducer: GradeReducer,
    subjectEnrollmentReducer: SubjectEnrollmentReducer,
    markSheetReducer: MarkSheetReducer,
    subjectTeachersReducer: SubjectTeachersReducer,
    timeTableReducer: TimeTableReducer,
    roleReducer: RoleReducer,
    accessReducer: AccessReducer,
    periodDaysReducer: PeriodDaysReducer,
    feesTypeReducer: FeesTypeReducer,
    feesEnrollmentReducer: FeesEnrollmentReducer,
    studentFeesPaymentReducer: StudentFeesPaymentReducer,
    unitReducer: UnitReducer,
    unitTypeReducer: UnitTypeReducer,
    documentReducer: DocumentReducer,
    circularReducer:CircularReducer,
    assignTypeReducer:AssignTypeReducer,
    schoolInfoReducer:SchoolInfoReducer,

    knowledgeReducer:KnowledgeReducer,
    knowledgeMaterialTypeReducer:KnowledgeMaterialTypeReducer,
    eventReducer:EventReducer,
    enquiryReducer:EnquiryReducer,
    enquiryTypeReducer:EnquiryTypeReducer,
    examVariantReducer:ExamVariantReducer,
    dailyExamReducer:DailyExamReducer,
    studentListReducer:StudentListReducer,
    schoolDashboardReducer:SchoolDashboardReducer,
    studentLeaveReducer:StudentLeaveReducer,
    bookReducer: BookReducer,
    bookTypeReducer: BookTypeReducer,
    knowledgeBookTypeReducer: KnowledgeBookTypeReducer,
    syllabusReducer:SyllabusReducer ,
    dashboardAttendanceReducer:DashboardAttendanceReducer,
    quizReducer:QuizReducer,
    pettyCashReducer:PettyCashReducer,
    // inventory
    productReducer: ProductReducer,
    currencyReducer: CurrencyReducer,
    measurementReducer: MeasurementReducer,
    materialCodeReducer: MaterialCodeReducer,
    purchaseReducer: PurchaseReducer,
    warehouseReducer: WarehouseReducer,
    locationReducer: LocationReducer,
    materialTypeReducer: MaterialTypeReducer,
    taxCodeReducer: TaxCodeReducer,
    goodsReceivedReducer: GoodsReceivedReducer,
    deliveryReducer: DeliveryReducer,
    supplierReducer: SupplierReducer,
    inventoryMaterialReducer: InventoryMaterialReducer,
    inventoryAdjustmentReducer: InventoryAdjustmenReducer,
    showMaterialReducer: ShowMaterialReducer,
    salesReducer: SalesReducer,
    customerInventoryReducer: CustomerInventoryReducer,
    utilityReducer: UtilityReducer,
    priceGroupRedecer: PriceGroupReducer,
    taxReducer: TaxReducer,
    quotationReducer: QuotationReducer,
    receiptListReducer: ReceiptListReducer,
    invoiceReducer: InvoiceReducer,
    creditReducer: CreditReducer,
    paymentReducer: PaymentReducer,
    documentTypeReducer: DocumentTypeReducer,


    visitorTypeReducer:VisitorTypeReducer,
    visitorReducer:VisitorReducer,
    visitorLogReducer:VisitorLogReducer,
    creditTermReducer:CreditTermReducer,
    taxConfigReducer:TaxConfigReducer,
    taxCodeReducer: TaxCodeReducer,
    otherLeaveReducer: OtherLeaveReducer,


    // visitorLogReducer:VisitorLogReducer,















    











    




  },
  // RootReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddlewares) =>
    getDefaultMiddlewares({ serializableCheck: false }).concat(apiSlice.middleware),
});
