
const circularDetailsForm = [
    {
        title: "Basic Information",
        formBody: [
            
            {
                formField: [
                    {
                        label: "",
                        inputType: "text",
                        placeholder: "Enter Title",
                        fieldName: "title",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },
            {
                formField: [
                    {
                        label: "",
                        inputType: "textarea",
                        placeholder: "Enter Description",
                        fieldName: "description",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }

                ]
            },
            {
                formField: [
                    {
                        label: "",
                        inputType: "icon",
                        defaultValue: "",
                        onClick: "onRemove",
                        config: {
                            required: "required",
                        },
                    }
                ]
            }
        ]
    }
];

const circularForm = [
    {
        title: "Basic Information",
        formBody: [
            {
                formField: [
                    {
                        label: "Category",
                        inputType: "select",
                        options: "categoryList",
                        displayKey: "name",
                        uniqueKey: "assignTypeId",
                        onChange: "handleChange",
                        multiple: false,
                        fieldName: "name",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },
            {
                formField: [
                    {
                        label: "Start Date",
                        inputType: "date",
                        fieldName:"startDate",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }                                                                                     
                ]
            },
            {
                formField: [
                    {
                        label: "End Date",
                        inputType: "date",
                        fieldName: "endDate",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },
            {
                formField: [
                    {
                        label: "Class -Section",
                        inputType: "select",
                        options: "enrollmentDetailsList",
                        uniqueKey: "enrollmentIds",
                        displayKey: "classTitle",
                        disabled:false,
                        onChange: "onSelectClass",
                        subDisplayKey: "sectionTitle",
                        multiple: true,
                        
                        fieldName: "enrollmentIds",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    
                    
                ]
            },
            {
                formField: [
                    {
                        label: "Title",
                        inputType: "text",
                        placeholder: "Enter Title",
                        fieldName: "title",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },
        ]
    }
];

const circularEditForm = [
    {
        title: "Basic Information",
        formBody: [
            {
                formField: [
                    {
                        label: "Category",
                        inputType: "select",
                        options: "categoryList",
                        displayKey: "name",
                        uniqueKey: "assignTypeId",
                        onChange: "handleChange",
                        multiple: false,
                        disabled: true,
                        fieldName: "name",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },
            {
                formField: [
                    {
                        label: "Start Date",
                        inputType: "date",
                        fieldName:"startDate",
                        placeholder:"Select Start date",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }                                                                                     
                ]
            },
            {
                formField: [
                    {
                        label: "End Date",
                        inputType: "date",
                        fieldName: "endDate",
                        placeholder:"Select End date",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },
            {
                formField: [
                    {
                        label: "Class -Section",
                        inputType: "select",
                        options: "enrollmentDetailsList",
                        uniqueKey: "enrollmentId",
                        displayKey: "classTitle",
                        disabled:false,
                        // onChange: "onSelectClass",
                        subDisplayKey: "sectionTitle",
                        multiple: true,
                        fieldName: "enrollmentIds",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },
            {
                formField: [
                    {
                        label: "Title",
                        inputType: "text",
                        placeholder: "Enter Title",
                        fieldName: "title",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },
        ]
    }
];


export {
    circularDetailsForm,
    circularForm,
    circularEditForm
};