import * as yup from "yup";
import { Formik } from "formik";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import DateTime from "react-datetime";
import { Form, Button, Table } from "react-bootstrap";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { quotationForm, createQuotationForm, createCustomerForm, sizeList, fieldList } from "./formData";
import { MdEdit, MdDelete, MdVisibility } from "react-icons/md";
import swal from "sweetalert2";
import _, { filter } from "lodash";
import { createQuotation, getQuotation, updateQuotation } from "app/api/QuotationApi";
import { Row, Col, Tab, Tabs, Nav } from "react-bootstrap";
import { createCustomer, getCustomer } from "app/api/CustomerInventoryApi";
import { getCreditTerm } from "app/api/CreditTermApi";
import { getAvailableMaterial, getMaterialInfo, getMaterial } from "app/api/ProductApi";
import { getShowMaterial } from "app/api/ShowMaterialApi";
import { getPriceGroup, getAsyncPriceGroup } from "app/api/PriceGroupApi";
import { getTax } from "app/api/TaxApi";
import { getCurrency } from "app/api/CurrencyApi";
import { state } from "app/api/APIContainer";
import { getMeasurement } from "app/api/MeasurementApi";
import { getMaterialType } from "app/api/MaterialTypeApi";
import moment from "moment";
import { createSales, getSales, updateSales } from "app/api/SalesApi";
import { getMaterialCode } from "app/api/MaterialCodeApi";
import { amountFormat, dateConversion, decimalAmount, pagePermission, showMessage } from "app/utils/app-functions";
import FormLayout from "app/components/render/formlayout";
import View from "app/components/atoms/modalView/Modalview";
import CustomTable from "app/components/atoms/custom-table/CustomTable";
import formRender from "app/components/render/formcomponent";
import SimpleCard from 'app/components/atoms/simple-card/SimpleCard'
import { FiEdit, FiSend, FiTrash2 } from 'react-icons/fi';
import CollapseView from "app/components/atoms/CollapseView/CollapseView";
import TableLayout from "app/components/render/formlayout/tablelayout";
import MultiTabs from 'app/components/atoms/multiTab/MultiTab';
import Alert from "app/components/atoms/alert";
import PdfPreview from "app/components/PdfPreview";


let editData = false;
let object = [];
let unitObject = [];

const Quotation = ({ navigation }) => {

    const dispatch = useDispatch();
    const customerFormRef = useRef();

    const getSalesSuccess = useSelector((state) => state.salesReducer.getSalesSuccess);
    const getSalesList = useSelector((state) => state.salesReducer.getSalesList);
    const getSalesFailure = useSelector((state) => state.salesReducer.getSalesFailure);

    const createSalesSuccess = useSelector((state) => state.salesReducer.createSalesSuccess);
    const createSalesData = useSelector((state) => state.salesReducer.createSalesData);
    const createSalesFailure = useSelector((state) => state.salesReducer.createSalesFailure);

    const updateSalesSuccess = useSelector((state) => state.salesReducer.updateSalesSuccess);
    const updateSalesData = useSelector((state) => state.salesReducer.updateSalesData);
    const updateSalesFailure = useSelector((state) => state.salesReducer.updateSalesFailure);

    const getCustomerSuccess = useSelector((state) => state.customerInventoryReducer.getCustomerSuccess);
    const getCustomerList = useSelector((state) => state.customerInventoryReducer.getCustomerList);
    const getCustomerFailure = useSelector((state) => state.customerInventoryReducer.getCustomerFailure);

    const createCustomerSuccess = useSelector((state) => state.customerInventoryReducer.createCustomerSuccess);
    const createCustomerData = useSelector((state) => state.customerInventoryReducer.createCustomerData);
    const createCustomerFailure = useSelector((state) => state.customerInventoryReducer.createCustomerFailure);

    const getCreditTermSuccess = useSelector((state) => state.utilityReducer.getCreditTermSuccess);
    const getCreditTermList = useSelector((state) => state.utilityReducer.getCreditTermList);
    const getCreditTermFailure = useSelector((state) => state.utilityReducer.getCreditTermFailure);

    const getMaterialTypeSuccess = useSelector((state) => state.productReducer.getMaterialTypeSuccess);
    const getMaterialTypeList = useSelector((state) => state.productReducer.getMaterialTypeList);
    const getMaterialTypeFailure = useSelector((state) => state.productReducer.getMaterialTypeFailure);

    const getPricegroupSuccess = useSelector((state) => state.priceGroupRedecer.getPricegroupSuccess);
    const getPricegroupList = useSelector((state) => state.priceGroupRedecer.getPricegroupList);
    const getPricegroupFailure = useSelector((state) => state.priceGroupRedecer.getPricegroupFailure);

    const getTaxSuccess = useSelector((state) => state.taxReducer.getTaxSuccess);
    const getTaxList = useSelector((state) => state.taxReducer.getTaxList);
    const getTaxFailure = useSelector((state) => state.taxReducer.getTaxFailure);

    const getCurrencySuccess = useSelector((state) => state.currencyReducer.getCurrencySuccess);
    const getCurrencyList = useSelector((state) => state.currencyReducer.getCurrencyList);
    const getCurrencyFailure = useSelector((state) => state.currencyReducer.getCurrencyFailure);

    const getMaterialCodeSuccess = useSelector((state) => state.materialCodeReducer.getMaterialCodeSuccess);
    const getMaterialCodeList = useSelector((state) => state.materialCodeReducer.getMaterialCodeList);
    const getMaterialCodeFailure = useSelector((state) => state.materialCodeReducer.getMaterialCodeFailure);
    const materialCodeErrorMessage = useSelector((state) => state.materialCodeReducer.errorMessage);


    const getMeasurementSuccess = useSelector((state) => state.measurementReducer.getMeasurementSuccess);
    const getMeasurementList = useSelector((state) => state.measurementReducer.getMeasurementList);
    const getMeasurementFailure = useSelector((state) => state.measurementReducer.getMeasurementFailure);
    const measurementErrorMessage = useSelector((state) => state.measurementReducer.errorMessage);


    const getLocationSuccess = useSelector((state) => state.locationReducer.getLocationSuccess);
    const getLocationList = useSelector((state) => state.locationReducer.getLocationList);
    const getLocationFailure = useSelector((state) => state.locationReducer.getLocationFailure);

    const getWarehouseSuccess = useSelector((state) => state.warehouseReducer.getWarehouseSuccess);
    const getWarehouseList = useSelector((state) => state.warehouseReducer.getWarehouseList);
    const getWarehouseFailure = useSelector((state) => state.warehouseReducer.getWarehouseFailure);

    const getAvailableMaterialSuccess = useSelector((state) => state.productReducer.getAvailableMaterialSuccess);
    const getAvailableMaterialList = useSelector((state) => state.productReducer.getAvailableMaterialList);
    const getAvailableMaterialFailure = useSelector((state) => state.productReducer.getAvailableMaterialFailure);

    const customerErrorMessage = useSelector((state) => state.customerInventoryReducer.errorMessage);
    const currencyErrorMessage = useSelector((state) => state.currencyReducer.errorMessage);
    const materialErrorMessage = useSelector((state) => state.productReducer.errorMessage);
    const salesErrorMessage = useSelector((state) => state.salesReducer.errorMessage);
    const creditErrorMessage = useSelector((state) => state.utilityReducer.errorMessage);
    const priceGroupErrorMessage = useSelector((state) => state.priceGroupRedecer.errorMessage);
    const taxErrorMessage = useSelector((state) => state.taxReducer.errorMessage);

    const [pageItems, setpageItems] = useState({});

    useEffect(() => {
        let data = pagePermission(pageItems, "Inventory", "Quotation","Sales")
        setpageItems(data)
        console.log(data);

    }, [navigation]);

    const column = [
        {
            "dataField": "billNumber",
            "text": "Quotation Number",
            "sort": true
        },
        {
            "dataField": "dateOfBill",
            "text": " Quotation Date",
            "sort": false,
            formatter: (cellContent, row, index) => {
                return dateConversion(cellContent, "DD-MMM-YYYY")
            }
        },
        {
            "dataField": "customerName",
            "text": "Customer Name",
            "sort": false
        },
        {
            "dataField": "salesPerson",
            "text": "Sales Person",
            "sort": false
        },
        {
            "dataField": "Prepared By",
            "text": "Prepared By",
            "sort": false
        },
        {
            "dataField": "totalAmount",
            "text": "Total Amount",
            "sort": false,
            formatter: (cellContent, row, index) => (
                amountFormat(cellContent, 'dollar')
            )
        },
        {
            dataField: "status",
            text: "Status",
            sort: true,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => {
                const status = cellContent === 'close' ? false : true;
                const conInfo = { inputType: "status", status: status, succesName: cellContent, failureName: cellContent }
                return formRender(null, null, conInfo, null);
            }
        },
    ]
    if (pageItems.update || pageItems.status) {
        column.push({
            text: "Action",
            sort: false,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => {
                const editClass = row.status === 'open' ? 'text-primary' : 'text-danger';
                return (
                    <div className="icon-container">
                          {pageItems.view && <MdVisibility
                            className={`text-success cursor-pointer`}
                            size={20}
                            onClick={() => onViewForm(row, index)}
                        />}
                        {pageItems.update && <FiEdit
                            className={`${editClass} cursor-pointer`}
                            size={20}
                            onClick={() => onEditForm(row, index)}
                        />}
                    </div>
                );
            }
        },)
    }


    const itemColumn = [
        {
            "dataField": "MaterialType",
            "text": "Material Type",
            "sort": true
        },
        {
            "dataField": "MaterialCode",
            "text": "Material Code",
            "sort": true
        },
        {
            "dataField": "MaterialName",
            "text": "Material Name",
            "sort": true
        },
        {
            "dataField": "Qty",
            "text": "Qty",
            "sort": true
        },
        {
            "dataField": "unit",
            "text": "Unit",
            "sort": true
        },
        {
            "dataField": "Unit Price",
            "text": "Unit Price ($)",
            "sort": true
        },
        {
            "dataField": "Total",
            "text": "Total",
            "sort": true
        },
        {
            "dataField": "Tax Code",
            "text": "Tax Code",
            "sort": true
        },
        {
            "dataField": "Total Tax",
            "text": "Total Tax",
            "sort": true
        },
        {
            "dataField": "Total Tax",
            "text": "Description",
            "sort": true,
            "align": 'center'
        },
        {
            "dataField": "Action",
            "text": "Add Item Details",
            "sort": true,
        },
        {
            "dataField": "Action",
            "text": "Action",
            "sort": true,
            "align": 'center'
        },
    ]

    const itemHeader = [
        {
            "dataField": "status",
            "text": "",
            formatter: (cellContent, row, index, extraData) => {
                const conInfo = { inputType: "checkbox", status: (cellContent ? cellContent : false) }
                return formRender(extraData, null, conInfo, null, null, statusChange, index);
            }
        },
        {
            "dataField": "imageName",
            "text": "Image",
            "sort": true,
            formatter: (cellContent, row, index, extraData) => {
                return <img
                    className="avatar-lg active"
                    src={`/assets/images/no-image.png`}
                    alt=""
                    style={{ objectFit: "contain", height: "20px", width: "20px" }}

                />
            }
        },
        {
            "dataField": "serialNumber",
            "text": "Serial Number",
            "sort": true
        },
        {
            "dataField": "batchNumber",
            "text": "Batch Number",
            "sort": true
        },
        {
            "dataField": "locationName",
            "text": "Receipt Location",
            "sort": true
        },
        {
            "dataField": "warehouseName",
            "text": "Warehouse",
            "sort": true
        },

    ]

    const itemDetailsHeader = [
        {
            "dataField": "imageName",
            "text": "Image",
            "sort": true,
            formatter: (cellContent, row, index, extraData) => {
                return <img
                    className="avatar-lg active"
                    src={`/assets/images/no-image.png`}
                    alt=""
                    style={{ objectFit: "contain", height: "20px", width: "20px" }}

                />
            }
        },
        {
            "dataField": "serialNumber",
            "text": "Serial Number",
            "sort": true
        },
        {
            "dataField": "batchNumber",
            "text": "Batch Number",
            "sort": true
        },
        {
            "dataField": "locationName",
            "text": "Receipt Location",
            "sort": true
        },
        {
            "dataField": "warehouseName",
            "text": "Warehouse",
            "sort": true
        },
        {
            text: "Action",
            sort: false,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => (
                <div >
                    <MdDelete
                        className="text-danger cursor-pointer"
                        size={18}
                        onClick={() => {
                            onExchangeItem(row, index);
                        }}
                    ></MdDelete>
                </div>
            )
        },
    ]

    const [state, setState] = useState({
        /*        fromDate: new Date(),
               validDate: new Date(moment().add(10, 'd').format('YYYY-MM-DD')), */
        creditList: [],
        /* currencyList: [
            {value:53,label:"India"},
            {value:54,label:"China"},
            {value:55,label:"US"},
            {value:56,label:"UK"},
        ], */
        deletedItems: [],
        checkCount: 0,
        creditLimit: '',
        clickIndex: '',
        footerData: [
            { label: 'Amount (before tax)', value: 0 },
            { label: 'Tax :', value: 0 },
            { label: 'Total Amount:', value: 0 },
            { label: 'Local Currency:', value: 0 }
        ]
    });

    const [createModule, setCreateModule] = useState(false);
    const [loaderModule, setLoaderModule] = useState(false);
    const [editModule, setEditModule] = useState(false);
    const [viewModule, setViewModule] = useState(false);
    const [deleteModule, setDeleteModule] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [selectedItem, setSelectedItem] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);
    const [subIndex, setSubIndex] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [formList, setFieldList] = useState(fieldList)
    const [customerForm, setCustomerForm] = useState(false)
    const [currencySymbol, setCurrencySymbol] = useState(false)
    const [tableRows, setTableRows] = useState([])
    const [selectMaterial, setSelectMaterial] = useState([])
    const [downloadPdf, setDownloadPdf] = useState(false);

    useEffect(() => {
        setLoaderModule(true)
        const requestData = {
            isActive: "true",
            orderStatus: "quote"
        }
        const request = {
            isActive: "true",
        }

        dispatch(getSales(requestData))
        // dispatch(getCustomer())
        // dispatch(getCreditTerm())
        // dispatch(getMaterialType())
        dispatch(getMaterialInfo())
        // dispatch(getPriceGroup())
        // dispatch(getTax(request))
        // dispatch(getCurrency())
        // dispatch(getMaterial())
        // dispatch(getMeasurement())
        listFetching()

    }, [navigation]);

    //CUSTOMER
    useEffect(() => {
        if (getCustomerSuccess) {
            setState({
                ...state,
                customerList: getCustomerList
            })
            dispatch({ type: "RESET_GET_CUSTOMER" })
        }
        else if (getCustomerFailure) {
            setState({
                ...state,
                customerList: []
            })
            showMessage("warning", customerErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_CUSTOMER" })
        }
    }, [getCustomerSuccess, getCustomerFailure]);

    useEffect(() => {
        if (createCustomerSuccess) {
            setState({
                ...state,
                customerList: [...state?.customerList || [], createCustomerData[0]]
            })
            setLoaderModule(false)
            // closeModule()
            setCustomerForm(false)
            showMessage("success", "Created Successfully")
            Alert({ description: 'Created Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });

            dispatch({ type: "RESET_CREATE_CUSTOMER" })
        } else if (createCustomerFailure) {
            setState({
                ...state,
                customerList: []
            })
            setLoaderModule(false)
            // setCustomerForm(false)
            showMessage("warning", customerErrorMessage?.message)
            dispatch({ type: "RESET_CREATE_CUSTOMER" })
        }
    }, [createCustomerSuccess, createCustomerFailure]);

    useEffect(() => {
        if (getCreditTermSuccess) {
            setState({
                ...state,
                creditList: getCreditTermList
            })
            dispatch({ type: "RESET_GET_CREDIT_TERM" })
        }
        else if (getCreditTermFailure) {
            setState({
                ...state,
                creditList: []
            })
            showMessage("warning", creditErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_CREDIT_TERM" })
        }
    }, [getCreditTermSuccess, getCreditTermFailure]);

    useEffect(() => {
        if (getPricegroupSuccess) {
            setState({
                ...state,
                priceGroupList: getPricegroupList
            })
            dispatch({ type: "RESET_GET_PRICE_GROUP" })
        }
        else if (getPricegroupFailure) {
            setState({
                ...state,
                priceGroupList: []
            })
            showMessage("warning", priceGroupErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_PRICE_GROUP" })
        }
    }, [getPricegroupSuccess, getPricegroupFailure]);

    useEffect(() => {
        if (getTaxSuccess) {
            setState({
                ...state,
                taxList: getTaxList
            })
            dispatch({ type: "RESET_GET_TAX" })
        }
        else if (getTaxFailure) {
            setState({
                ...state,
                taxList: []
            })
            showMessage("warning", taxErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_TAX" })
        }
    }, [getTaxSuccess, getTaxFailure]);

    useEffect(() => {
        if (getMaterialTypeSuccess) {
            setState({
                ...state,
                materialTypeList: getMaterialTypeList
            })
            dispatch({ type: "RESET_GET_MATERIAL_TYPE" })
        }
        else if (getMaterialTypeFailure) {
            setState({
                ...state,
                materialTypeList: []
            })
            showMessage("warning", materialErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_MATERIAL_TYPE" })
        }
    }, [getMaterialTypeFailure, getMaterialTypeSuccess]);

    useEffect(() => {
        if (getCurrencySuccess) {
            setState({
                ...state,
                currencyList: getCurrencyList
            })
            dispatch({ type: "RESET_GET_CURRENCY" })
        }
        else if (getCurrencyFailure) {
            setState({
                ...state,
                currencyList: []
            })
            showMessage("warning", currencyErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_CURRENCY" })
        }
    }, [getCurrencySuccess, getCurrencyFailure]);

    useEffect(() => {
        if (getMaterialCodeSuccess) {
            setState({
                ...state,
                materialCodeList: getMaterialCodeList
            })
            // setSelectMaterial(getMaterialCodeList)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_MATERIALCODE" })
        } else if (getMaterialCodeFailure) {
            setState({
                ...state,
                materialCodeList: []
            })
            showMessage("warning", materialCodeErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_MATERIALCODE" })
        }
    }, [getMaterialCodeSuccess, getMaterialCodeFailure]);

    useEffect(() => {
        if (getMeasurementSuccess) {
            setState({
                ...state,
                unitsList: getMeasurementList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_MEASUREMENT" })
        } else if (getMeasurementFailure) {
            setState({
                ...state,
                unitsList: []
            })
            showMessage("warning", measurementErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_MEASUREMENT" })
        }
    }, [getMeasurementSuccess, getMeasurementFailure]);

    useEffect(() => {
        if (getAvailableMaterialSuccess) {
            const parentIndex = selectedIndex;
            const parentRows = [...tableRows];
            const itemInfo = parentRows[parentIndex]?.itemDetails || [];

            const filter_data = _.filter(getAvailableMaterialList, (item, index) =>
                _.findIndex(itemInfo, (n) => n.itemDetailsId === item.itemDetailsId) === -1
            )
            setSelectMaterial(filter_data)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_AVAILABEL_MATERIAL" })
        } else if (getAvailableMaterialFailure) {
            setSelectMaterial([])
            showMessage("warning", materialErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_AVAILABEL_MATERIAL" })
        }
    }, [getAvailableMaterialSuccess, getAvailableMaterialFailure]);

    useEffect(() => {
        if (getSalesSuccess) {
            setItemList(getSalesList)

            setLoaderModule(false)

            dispatch({ type: "RESET_GET_SALES" })
        } else if (getSalesFailure) {
            setItemList([])
            showMessage("warning", salesErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_SALES" })
        }
    }, [getSalesSuccess, getSalesFailure]);

    useEffect(() => {
        if (createSalesSuccess) {
            const temp_state = [...itemList, createSalesData[0]];
            setItemList(temp_state)
            setLoaderModule(false)
            closeModule()
            Alert({ description: 'Created Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            dispatch({ type: "RESET_CREATE_SALES" })
        } else if (createSalesFailure) {
            setLoaderModule(false)
            showMessage("warning", salesErrorMessage?.message)
            dispatch({ type: "RESET_CREATE_SALES" })
        }
    }, [createSalesSuccess, createSalesFailure]);

    useEffect(() => {
        if (updateSalesSuccess) {
            updateTable(updateSalesData[0])
            dispatch({ type: "RESET_UPDATE_SALES" })
        } else if (updateSalesFailure) {
            setLoaderModule(false)
            showMessage("warning", salesErrorMessage?.message)
            dispatch({ type: "RESET_UPDATE_SALES" })
        }
    }, [updateSalesSuccess, updateSalesFailure]);

    const updateTable = (updatedItem) => {
        const temp_state = [...itemList];
        if (deleteModule) {
            let remainingList = _.remove(temp_state, function (n) {
                return n.orderId != updatedItem.orderId;
            });
            Alert({ description: 'Deleted Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(remainingList)
        } else {
            temp_state[subIndex] = updatedItem;
            Alert({ description: 'Updated Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(temp_state)
        }
        setLoaderModule(false)
        closeModule()
    }

    const closeModule = () => {
        editData = false;
        setCreateModule(false)
        // setCustomerForm(false)
        setDeleteModule(false)
    }

    const resetState = () => {
        setState({
            ...state,
            "customerName": "",
            "contactPerson": "",
            "phoneNo": "",
            "address": "",
            "creditTerm": "",
            "salesPerson": "",
            "custPoNumber": "",
            "memo": "",
            "billingAddress": "",
            "deliveryAddress": "",
            "createDate": "",
            "productTax": false,
            "includingGST": false,
            "reserveStock": false,
            "currency": "",
            "customerAddress": false,
            "sameBillingAddress": false,
            "fromDate": moment(new Date(), "YYYY-MM-DD"),
            "validDate": moment(new Date(), "YYYY-MM-DD"),
            footerData: [
                { label: 'Amount (before tax)', value: 0 },
                { label: 'Tax :', value: 0 },
                { label: 'Total Amount:', value: 0 },
                { label: 'Local Currency:', value: 0 }
            ]
        })
        setTableRows([])
    }

    const resetCustomer = () => {
        setState({
            ...state,
            "name": "",
            "creditTerm": '',
            "pricegroup": '',
            "contact": '',
            "customerUen": "",
            "tax": "",
            "creditLimit": "",
            "currency": "",
            "email": "",
            "contactNo": "",
            "postalCode": "",
            "CusbillingAddress": false,
            "CusCheckBillingAddress": false,
            "shippingAddress": false,

        })
    }

    const onCreateForm = () => {
        resetState()
        editData = false;
        setCreateModule(true)
    }

    const onEditForm = (data, index) => {
        console.log(JSON.stringify(data));

        if (data.status === 'open') {
            const temp_data = state?.customerList || object
            const filter_data = temp_data.filter(item => item.customerId == data.customerId)
            const item_list = (data?.orderItem || []).map((o) => {
                const selMaterial = _.filter(state.materialInfoList, { materialId: o.materialId });
                return { ...o, materialCodeId: selMaterial, materialId: o.materialTypeId }
            })
            // alert(data.billingAddress)

            setState({
                ...state,
                "orderStatus": "quote",
                "customerId": data?.customerId || "" || "",
                "customerName": data?.customerName || '',
                "contactPerson": filter_data[0]?.contactPerson || '',
                "phoneNo": filter_data[0]?.phoneNo || '',
                "address": filter_data[0]?.address || '',
                "creditTerm": data.creditTerm,
                "billingAddress": data.billingAddress,
                "customerAddress": (filter_data[0]?.address || '') == data.billingAddress ? true : false,
                "sameBillingAddress": data.billingAddress == data.deliveryAddress ? true : false,
                "deliveryAddress": data.deliveryAddress,
                "salesPerson": data.salesPerson,
                "memo": data.memo,
                "custPoNumber": data.custPoNumber,
                "fromDate": moment(data.dateOfBill, "YYYY-MM-DD"),
                "validDate": moment(data.validTill, "YYYY-MM-DD"),
                "productTax": data?.productTax || false,
                "priceIncludingGST": data?.priceIncludingGST || false,
                "reserveStock": data?.reserveStock || false,
                "currency": data?.currencyId,
                "currencyId": data?.currencyId,
                footerData: [
                    { label: 'Amount (before tax)', value: data.amount },
                    { label: 'Tax :', value: data.totalTax },
                    { label: 'Total Amount:', value: data.totalAmount },
                    { label: 'Local Currency:', value: 0 }
                ]
            })
            editData = true;
            setSubIndex(index)
            setSelectedItem(data)
            setTableRows(data?.orderItem)
            setCreateModule(true)
        }

    }

    const validateForm = async () => {
        const validatefom = customerFormRef.current.validateForm();
    }

    const onSubmitForm = () => {
        let data = [...tableRows];
        let newItems = [];
        if (data.length == 0) {
            // showMessage('warning', "Fill all the Item details")
            Alert({ description: 'Fill all the Item details.', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });

            return false;
        }

        let material_code = (data ? data : []).map((item, index) => {
            return item?.materialCode || ''
        })

        let material_id = data.map((item, index) => {
            return item?.materialId || ''
        })

        const unitname = (state?.unitsList || unitObject).reduce((total, row) => (row?.unitName || ''));

        function validates(checkData) {
            let isEmpty = false;
            if (Array.isArray(checkData)) {
                (checkData || []).forEach(ele => {
                    for (let key in ele) {
                        if (ele[key] === undefined || ele[key] === null || ele[key] === '' || ele["tax"] === 0) {
                            // console.log(ele);

                            isEmpty = true;
                        }
                    }
                });
            }
            return isEmpty;
        }
        let check = false
        let filter_Data = data.forEach((item, index) => {
            if (item.qty == item.itemDetails.length) {
                let newItem = { ...item };
                let id = item.itemDetails.map(detail => detail?.itemDetailsId).join(',');
                newItem.itemDetailsIds = id
                newItem.materialCode = material_code[index]
                newItem.materialId = material_id[index]
                newItem.price = newItem.price;
                newItem.totalTax = parseFloat(newItem.totalTax)
                newItem.total = newItem.total
                delete newItem?.UnitPrice;
                delete newItem?.remark;
                delete newItem?.dimension;
                delete newItem?.itemWidth;
                delete newItem?.itemHeight;
                delete newItem?.thickness;
                // delete newItem?.taxVal;
                delete newItem?.orederStatus;
                delete newItem?.materialTypeId;
                newItem.unitName = unitname
                delete newItem.itemDetails;
                newItems.push(newItem);
            }
            else {
                check = true
                Alert({ description: 'Quantity and Material Count Must be Same', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
                return false;
            }
        });

        if (validates(newItems)) {
            Alert({ description: 'Fill the all the Item details', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            return false;
        }
        else if (check) {
            return false;
        }
        const requestData = {
            "orderStatus": "quote",
            "customerId": state?.customerId || 0,
            "creditTerm": state?.creditTerm || 0,
            "billingAddress": state.billingAddress,
            "deliveryAddress": state.deliveryAddress,
            "salesPerson": state.salesPerson,
            "memo": state.memo,
            "status": 'open',
            "custPoNumber": state.custPoNumber,
            "dateOfBill": dateConversion(state.fromDate, 'YYYY-MM-DD'),
            "validTill": dateConversion(state.validDate, 'YYYY-MM-DD'),
            "productTax": state?.productTax || false,
            "priceIncludingGST": state?.priceIncludingGST || false,
            "reserveStock": state?.reserveStock || false,
            "amount": state?.footerData[0].value || 0,
            "totalTax": state?.footerData[1].value || 0,
            "totalAmount": state?.footerData[2].value || 0,
            "currencyId": state?.currency || 0,
            "orderItem": newItems
        }
        // return
        if (editData) {
            let data = state.deletedItems
            let deletingRow = data.map(ele => {
                ele.isDelete = true
                requestData.orderItem.push(ele)
            })
            setLoaderModule(true)
            dispatch(updateSales(requestData, selectedItem.orderId))
        } else if (deleteModule) {
            const deleteRequest = {
                isActive: "false"
            }
            setLoaderModule(true)
            // dispatch(updateDepartment(deleteRequest, selectedItem.departmentId))
        } else {
            setLoaderModule(true)
            dispatch(createSales(requestData))
        }
    };

    const handleTaxCode = (event, name, index,) => {
        let temp_state = [...tableRows];
        let temp_arr = { ...state }
        const tax = state?.taxList || []
        const taxItem = tax.filter((item, index) => item.taxId == event);
        let taxVal = taxItem.length ? taxItem[0].taxVal : 0;
        let totalTaxAmount = decimalAmount(parseInt(temp_state[index].total) * parseFloat(taxVal / 100))
        temp_state[index][name] = event
        temp_state[index].totalTax = totalTaxAmount
        const footerData = footerCalculation(temp_state);
        temp_arr.footerData = footerData;
        temp_arr.taxVal = taxVal;
        setTableRows(temp_state)
        setState(temp_arr)
    }

    const onSubmitCustomerForm = () => {

        const customerRequest = {
            "customerName": state.name,
            "contactPerson": state?.contact,
            "phoneNo": state.contactNo,
            "email": state.email,
            "postalCode": state.postalCode,
            "address": state.CusbillingAddress,
            "customerDetails": [
                {
                    "shippingAddress": state.shippingAddress,
                    "creditLimit": state.creditLimit,
                    "creditTerm": state?.creditTermId || '' || "",
                    "customerUEN": state.customerUen,
                    "tax": state.taxId,
                    "currencyId": state.currency,
                    "priceGroup": state.custPriceGroupId
                }

            ]
        }
        console.log(JSON.stringify(customerRequest));

        // return
        setLoaderModule(true)
        dispatch(createCustomer(customerRequest))

    }

    const onExchangeItem = (data, index) => {
        let idx = _.findIndex(tableRows, (e) => {
            return e.orderItemId == data.orderItemId;
        });
        const iddx = state?.clickIndex
        const temp_state = [...tableRows];
        temp_state[iddx].itemDetails.splice(index, 1);
        setTableRows(temp_state);
    }

    const onCloseForm = () => {
        setShowForm(false);
    }

    const handleCustomerChange = (event, name, index,) => {
        let temp_state = "";
        const creditTermData = state?.creditList || []
        const custData = state?.customerList || object
        const currency = state?.currencyList || []
        const selectedCreditName = event?.creditTerm || []

        const list = currency.filter((item) => {
            return item.currencyId === event.currencyId
        })
        const filterCredit = creditTermData.filter((item) => {
            return item.creditTermId == selectedCreditName
        })
        // alert(JSON.stringify(creditTermData))

        temp_state = {
            ...state,
            [name]: event.label,
            contactPerson: event?.contactPerson || "",
            phoneNo: event?.phoneNo || "",
            address: event?.address || "",
            productTax: event?.productTax || false,
            // creditTerm: filterCredit[0]?.creditTermName || "",
            creditTerm: filterCredit[0]?.creditTermId || "",
            currency: event?.currencyId || "",
            customerId: event.value
        }
        setState(temp_state)
    }

    const sameAsCustomer = (event, index, name) => {
        if (event.target.name === "customerAddress" || event.target.name === "billingAddress" || event.target.name === "sameBillingAddress") {
            let customerAddress = ""
            let key = event.target.name === "customerAddress" ? "billingAddress" : "deliveryAddress"
            let stateKey = event.target.name === "customerAddress" ? state?.address : state?.billingAddress
            const result = event.target.name === "customerAddress" && event.target.checked === true || event.target.name === "sameBillingAddress" && event.target.checked === true ? customerAddress = stateKey : customerAddress = ""
            setState({
                ...state,
                [event.target.name]: event.target.checked,
                [key]: customerAddress
            })
        } else {
            setState({
                ...state,
                [event.target.name]: event.target.checked,
            })
        }
    }

    const sameCusBillingAddress = (event, index, name) => {
        setState({
            ...state,
            [event.target.name]: event.target.checked,
            shippingAddress: event.target.checked ? state.CusbillingAddress : ''
        })
    }

    const addCustomer = () => {
        resetCustomer()
        setCustomerForm(true)
    }

    var itemCount = 0;
    const addRow = () => {
        const defaultNewOrderObject = {
            materialCode: '',
            materialType: '',
            materialName: '',
            qty: "",
            // unitName: '',
            price: "",
            total: "",
            tax: "",
            totalTax: "",
            /* itemDetailsSize: [{
                length: '',
                width: '',
                thickness: '',
            }], */
            itemDetails: [
            ]
        };
        itemCount++;
        setTableRows([...tableRows, defaultNewOrderObject]);

    };

    const statusChange = (event, index, extraData) => {
        let items = [...selectMaterial]
        let count = parseFloat(state.checkCount);
        const materialQty = parseFloat(tableRows[selectedIndex]?.qty || 0);

        if (count < materialQty) {
            items[index].status = event.target.checked;
            const addingCount = event.target.checked ? 1 : -1;
            count = count + addingCount;
        } else if (count === materialQty && !event.target.checked) {
            items[index].status = event.target.checked;
            const addingCount = -1;
            count = count + addingCount;
        } else {
            Alert({ title: "Select Quantity", description: 'Not allowed to select more than material quantity', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });

        }
        setState({ ...state, checkCount: count });
        setSelectMaterial(items)
    }

    const confirmForm = () => {
        const temp_state = [...selectMaterial];
        console.log(temp_state);

        const parentIndex = selectedIndex;
        let filteredData = _.filter(temp_state, function (item) {
            return item.status === true;
        });
        if (tableRows[parentIndex].itemDetails?.length) {
            tableRows[parentIndex].itemDetails = [...tableRows[parentIndex].itemDetails, ...filteredData];
        }
        else
            tableRows[parentIndex].itemDetails = filteredData;
        setTableRows(tableRows);
        setShowForm(false)
    }

    const removeItem = (itemIndex) => {
        let temp_state = [...tableRows];
        let addd = tableRows.splice(itemIndex, 1);
        let remainingList = _.remove(temp_state, function (n, index) {
            return index != itemIndex
        });
        let updatedState = footerCalculation(remainingList)
        setTableRows(remainingList);
        let temp_arr = { ...state }
        setState({
            ...state,
            footerData: updatedState,
            deletedItems: [...temp_arr.deletedItems, ...addd]
        })
    }

    const handleCurrencyChange = (event, name) => {
        const selectedCurrencyId = event;
        const selectedCurrency = state.currencyList.find(currency => currency.currencyId == selectedCurrencyId);
        const symbol = selectedCurrency?.symbol || '';
        setCurrencySymbol(symbol);
        setState({
            ...state,
            [name]: event
        })
    };

    const handleCalculation = (event, name, index) => {
        let temp_state = [...tableRows];
        let temp_arr = { ...state }
        const tax = state?.taxList || []
        let taxId = parseInt(temp_state[index]?.tax || 0)
        const taxItem = tax.filter((item, index) => item.taxId == taxId);
        let taxVal = parseFloat(taxItem.length ? taxItem?.[0].taxVal : 0);
        temp_state[index][event.target.name] = event.target.value;
        const item_total = parseFloat(temp_state[index]?.qty || 0) * parseFloat(temp_state[index]?.price || 0);
        const item_tax_percent = (taxVal) / 100;
        temp_state[index].totalTax = decimalAmount(parseFloat(item_total) * parseFloat(item_tax_percent));
        temp_state[index].total = decimalAmount(item_total);
        const footerData = footerCalculation(temp_state);
        temp_arr.footerData = footerData;
        setTableRows(temp_state)
        setState(temp_arr)
    }

    const fetchData = (value, index, name) => {

        let apiResponse = state?.materialCodeList || [];
        if (value.length < 3) {
            let tempstate = [...tableRows];
            tempstate[index].filteredData = [];
            tempstate[index].materialCode = value;
            tempstate[index].materialName = '';
            tempstate[index].materialId = '';
            setTableRows(tempstate);
            return;
        }

        let filterObject = (apiResponse ? apiResponse : []).find(item => item.materialCode.toLowerCase() === value.toLowerCase());
        const filteredResults = (apiResponse ? apiResponse : []).filter(material =>
            material.materialCode.toLowerCase().includes(value.toLowerCase())
        );
        console.log(filterObject);

        let tempstate = [...tableRows];
        tempstate[index].filteredData = filteredResults;
        tempstate[index].materialCodeId = value;
        tempstate[index].materialName = filterObject ? filterObject.materialName : '';
        tempstate[index].materialId = filterObject ? filterObject.materialId : '';
        tempstate[index].materialTypeId = filterObject ? filterObject.materialTypeName : '';
        // tempstate[index].materialTypeId = filterObject ? filterObject.materialTypeId : '';

        setTableRows(tempstate);

        if (value.length >= filterObject?.materialCode.length) {
            let tempstate = [...tableRows];
            tempstate[index].filteredData = [];
            setTableRows(tempstate);
        }

    };

    const handleMaterialChange = async (event, name, index) => {
        let value = event.target.value;
        const req = {
            materialCode: value
        };
        const response = await getMaterialCode(req);
        if (response.getMaterialCodeSuccess) {
            setState({
                ...state,
                materialCodeList: response.getMaterialCodeList.data
            });
        } else if (response.getMaterialCodeFailure) {
            setState({
                ...state,
                materialCodeList: []
            });
            showMessage("warning", response.materialCodeErrorMessage?.message);
        }

        if (value == "") {
            let tempstate = [...tableRows];
            tempstate[index].filteredData = [];
            setTableRows(tempstate);
        } else {
            fetchData(value, index, name);
        }
    };

    const onAddItem = async (index) => {
        /*  if (index < 0 || index >= tableRows.length) {
             showMessage("error", "Invalid index");
             return;
         } */
        setTableRows(tableRows.map(({ materialType, ...row }) => row));
        const selectedRow = tableRows[index];

        /*  if (!selectedRow) {
             showMessage("error", "Selected row not found");
             return;
         } */
        const material_Id = selectedRow.materialId;
        
        const qty = parseFloat(selectedRow?.qty || 0);
        setSelectedIndex(index);
        setSubIndex(index);
        let count = parseInt(selectedRow?.itemDetails?.length || 0);
        setState({ ...state, checkCount: count });

        // Corrected if condition syntax and logic
        if (qty !== null && qty !== "" && selectedRow.materialCodeId !== "") {
            setSelectMaterial([]);
            setLoaderModule(true);
            let list = await getShowMaterial(null, material_Id)
            const parentIndex = selectedIndex
            const parentRows = [...tableRows];
            let tempArr = list.getShowMaterialList
            const itemInfo = parentRows[parentIndex]?.itemDetails || [];

            const filter_data = _.filter(tempArr, (item, index) =>
                _.findIndex(itemInfo, (n) => n.itemDetailsId === item.itemDetailsId) === -1
            )

            setSelectMaterial(filter_data)
            setShowForm(true);
        } else {
            Alert({ description: 'Enter the material quantity (or) Select the Material', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });

        }
    };


    const footerCalculation = (tableRows) => {
        const tableData = tableRows.length > 0 ? tableRows : [];
        let lineItemTotal = 0, lineItemTax = 0;
        tableData.map((row) => {
            lineItemTotal += parseFloat(row?.total || 0);
            lineItemTax += parseFloat(row?.totalTax || 0);
        })
        const totalAfterTax = lineItemTotal + lineItemTax;
        const calculationTitle = [
            { label: 'Amount (before tax)', value: lineItemTotal > 0 ? lineItemTotal : 0 },
            { label: 'Tax :', value: lineItemTax > 0 ? lineItemTax : 0 },
            { label: 'Total Amount:', value: totalAfterTax > 0 ? totalAfterTax : 0 },
            { label: 'Local Currency:', value: 0 }
        ];
        setState({
            ...state,
            footerData: calculationTitle
        })
        return calculationTitle;

    }

    const expandView = (index) => {
        const data = tableRows[index]?.itemDetails || [];
        console.log(JSON.stringify(data));

        return (
            <div style={{ marginTop: "-25px" }}>
                {/* <h3 style={{ fontSize: '20px', fontWeight: '500',marginBottom:"-30px" }} className="label-color">Item Details</h3> */}
                <CustomTable data={data} tableName="Item Details" columns={itemDetailsHeader} ></CustomTable>
            </div>
        );
    };


    const handleSubmit = () => {
        const req = {
            orderStatus: 'quote',
            fromDate: new Date(state?.fromDate || ''),
            toDate: new Date(state?.toDate || ''),
            customerName: state?.customerName || '',
            billNumber: state?.billNumber || ''
        }
        dispatch(getQuotation(req))
    }

    const modelTitle = editData ? "Edit " : "Create ";
    const modelButtonLbl = editData ? "" : "Submit";

    const listFetching = async () => {
        let creditTermList = await getCreditTerm({ isActive: true })
        let customerList = await getCustomer()
        let materialTypeList = await getMaterialType()
        let measurementList = await getMeasurement()
        let priceGroupListList = await getAsyncPriceGroup()
        let taxList = await getTax({
            isActive: "true",
        })
        let currencyList = await getCurrency()
        object = customerList.getCustomerList
        unitObject = measurementList.getMeasurementList
        setState({
            ...state,
            unitsList: measurementList.getMeasurementList,
            creditList: creditTermList.getCreditTermList,
            customerList: customerList.getCustomerList,
            taxList: taxList.getTaxList,
            materialTypeList: materialTypeList.getMaterialTypeList,
            currencyList: currencyList.getCurrencyList,
            priceGroupList: priceGroupListList.data,
        })
        /*     dispatch(getWarehouse())
            dispatch(getLocation())
            dispatch(getMaterialType())
            dispatch(getMeasurement()) */
    }


    const handleClick = (buttonName) => {
        if (buttonName == 'Add') {
            onCreateForm();
        }
    };
    const tabs = [
        {
            id: "quotation",
            title: "Quotation",
            content: (
                <div style={{ marginRight: '10px' }}>
                    <CustomTable buttonData={pageItems.create ? ['add'] : []} handleClick={handleClick} tableName={"Quotation"} columns={column} data={itemList} state={state} />
                </div>
            )
        },
        {
            id: "draft",
            title: "Drafts",
            content: (
                <div style={{ marginRight: '10px' }}>
                    <CustomTable columns={column} data={[]} tableName={"Quotation"} state={state} />
                </div>
            ),
        }
    ];
    const rows = [
        { label: "Order Number", value: "orderNumber" },
        { label: "Bill Number", value: "billNumber" },
        { label: "Customer", value: "customerName" },
        { label: "Address", value: "customerAddress" },
        { label: "Phone", value: "customerPhoneNo" },
        { label: "Email", value: "customerEmail" },
        { label: "Billing Address", value: "billingAddress" },
        { label: "Delivery Address", value: "deliveryAddress" },
        { label: "Salesperson", value: "salesPerson" },
        { label: "Date of Bill", value: "dateOfBill", isDate: true },
        { label: "Valid Till", value: "validTill", isDate: true },
    ]

    const itemFields = [
        { label: "Material Name", value: "materialName" },
        { label: "Quantity", value: "qty", prefix: "Qty: " },
        { label: "Unit", value: "unitName", prefix: "Unit: " },
        { label: "Price", value: "price", prefix: "Price: $", isCurrency: true },
        { label: "Total", value: "total", prefix: "Total: $", isCurrency: true },
        { label: "Tax", value: "totalTax", prefix: "Tax: $", isCurrency: true },
    ];

    const onViewForm = (data) => {
        setState({
            ...state,
            dataList: data
        })
        setDownloadPdf(true)
    }
    return (
        <div>
            {/* <SmallLoader value={loaderModule} /> */}
            <View isOpen={createModule} onClose={setCreateModule} size="fullscreen" savetitle={modelButtonLbl} onSubmit={validateForm} title={`${modelTitle} Quotation List`}>
                <FormLayout ref={customerFormRef} defaultState={state} setDefaultState={setState} dynamicForm={createQuotationForm} noOfColumn={3} onSubmit={onSubmitForm} onSearch={addCustomer} onChangeCallback={{ "handleCustomerChange": handleCustomerChange, "handleCurrencyChange": handleCurrencyChange }} iconPress={(event, index, state) => sameAsCustomer(event, index, state)} />
                <CollapseView iconPress={{ "onAddItem": onAddItem, "onDeletePress": removeItem }} calculationTitle={state.footerData} currencySymbol={currencySymbol} onChangeCallback={{ "handleMaterialChange": handleMaterialChange, "handleCalculation": handleCalculation, "handleTaxCode": handleTaxCode }} state={state} setState={setState} header={itemColumn} fieldList={formList} expandView={expandView} btnName={"Add Item"} tableRows={tableRows} setTableRows={setTableRows} addRow={addRow} />
            </View>
            <View isOpen={showForm} onClose={setShowForm} size="lg" title={`Select Product`} onSubmit={confirmForm} savetitle={"Confirm"} count={true} checkCount={state?.checkCount || 0} quatityCount={selectedIndex === false ? 0 : tableRows[selectedIndex]?.qty || 0} /* onClose={onCloseForm} */ btnName={"Confirm"}>
                <CustomTable columns={itemHeader} data={selectMaterial} tableName={""} state={state} />
            </View>
            <View isOpen={customerForm} size="lg" onClose={setCustomerForm} title={`Create New Customer`} onSubmit={validateForm}>
                <FormLayout ref={customerFormRef} defaultState={state} setDefaultState={setState} dynamicForm={createCustomerForm} onSubmit={onSubmitCustomerForm} noOfColumn={2} iconPress={(event, index, state) => sameCusBillingAddress(event, index, state)} />
            </View>
            <View isOpen={downloadPdf} onClose={setDownloadPdf} centered={true} size={'lg'} title="Download Pdf">
                <PdfPreview data={state?.dataList || []} totalSection={true} title={"Quotation"}  rows={rows} itemFields={itemFields} ItemKey={"orderItem"} />
            </View>

            <SimpleCard >
                {/* <FormLayout ref={customerFormRef} defaultState={state} setDefaultState={setState} dynamicForm={quotationForm} noOfColumn={6} onSubmit={onSubmitForm} onSearch={() => handleSubmit()} /> */}
                {/* <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
                    <Tab eventKey="home" title="QUOTATION">
                        <CustomTable columns={column} data={itemList}  tableName={""} state={state} /></Tab>
                    <Tab eventKey="profile" title="DRAFTS">
                        <CustomTable columns={column} data={[]} tableName={""} state={state} />
                    </Tab>
                </Tabs> */}
                <MultiTabs tabs={tabs} /* handleTab={handleTabSelect} */ />

            </SimpleCard>
        </div>
    )
};

export default Quotation;
