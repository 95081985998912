import * as yup from "yup";
import { Formik } from "formik";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import DateTime from "react-datetime";
import { Form, Button } from "react-bootstrap";
import { DateRange } from "react-date-range";
import SimpleCard from 'app/components/atoms/simple-card/SimpleCard'
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { MdEdit, MdDelete } from "react-icons/md";
import swal from "sweetalert2";
import _ from "lodash";
import { pagePermission, showMessage } from "app/utils/app-functions";
import FormLayout from "app/components/render/formlayout";
import View from "app/components/atoms/modalView/Modalview";
import CustomTable from "app/components/atoms/custom-table/CustomTable";
import formRender from "app/components/render/formcomponent";
import { taxConfigForm, projectSiteDetailsForm } from "./formData";
import { FiEdit, FiSend, FiTrash2 } from 'react-icons/fi';
import TableLayout from "app/components/render/formlayout/tablelayout";
import CollapseView from "app/components/atoms/CollapseView/CollapseView";
import { getCountry } from "app/api/CountryApi";

import { getTaxConfig, createTaxConfig, updateTaxConfig } from "app/api/TaxConfigApi";
import Alert from "app/components/atoms/alert";
import { lightTheme } from "styles/theme";
import { asyncGetCountryInfo } from "app/api/CountryApi";
import Loading from "app/components/atoms/loading";

let editData = false;
let deleteModule = false;

const TaxConfig = ({ navigation }) => {

    const dispatch = useDispatch();
    const customerFormRef = useRef();

    const getTaxConfigSuccess = useSelector((state) => state.taxConfigReducer.getTaxConfigSuccess);
    const getTaxConfigList = useSelector((state) => state.taxConfigReducer.getTaxConfigList);
    const getTaxConfigFailure = useSelector((state) => state.taxConfigReducer.getTaxConfigFailure);
    const taxConfigErrorMessage = useSelector((state) => state.taxConfigReducer.errorMessage);

    const createTaxConfigSuccess = useSelector((state) => state.taxConfigReducer.createTaxConfigSuccess);
    const createTaxConfigData = useSelector((state) => state.taxConfigReducer.createTaxConfigData);
    const createTaxConfigFailure = useSelector((state) => state.taxConfigReducer.createTaxConfigFailure);

    const updateTaxConfigSuccess = useSelector((state) => state.taxConfigReducer.updateTaxConfigSuccess);
    const updateTaxConfigData = useSelector((state) => state.taxConfigReducer.updateTaxConfigData);
    const updateTaxConfigFailure = useSelector((state) => state.taxConfigReducer.updateTaxConfigFailure);



    const getLeadSourceTypeSuccess = useSelector((state) => state.utilityReducer.getLeadSourceTypeSuccess);
    const getLeadSourceTypeList = useSelector((state) => state.utilityReducer.getLeadSourceTypeList);
    const getLeadSourceTypeFailure = useSelector((state) => state.utilityReducer.getLeadSourceTypeFailure);
    const leadSourceErrorMessage = useSelector((state) => state.utilityReducer.errorMessage);



    const getCountrySuccess = useSelector((state) => state.countryReducer.getCountrySuccess);
    const getCountryList = useSelector((state) => state.countryReducer.getCountryList);
    const getCountryFailure = useSelector((state) => state.countryReducer.getCountryFailure);
    const countryErrorMessage = useSelector((state) => state.countryReducer.errorMessage);





    const errorMessage = useSelector((state) => state.taxConfigReducer.errorMessage);

    const [pageItems, setpageItems] = useState({});

    useEffect(() => {
        let data = pagePermission(pageItems, "Inventory", "Tax-Code")
        setpageItems(data)
    }, [navigation]);

    const column = [
        {
            "dataField": "country",
            "text": "Country",
            "sort": true
        },
        {
            "dataField": "countryCode",
            "text": "Country Code",
            "sort": true
        },


    ]
    if (/* pageItems.update || pageItems.delete */true) {
        column.push({
            text: "Action",
            sort: false,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => (
                <div className="icon-container">
                    {/* pageItems.update &&  */<FiEdit
                        className="text-primary cursor-pointer"
                        size={18}
                        onClick={() => onEditForm(row, index)}
                    ></FiEdit>}
                  


                </div>
            )
        },)
    }

    const [state, setState] = useState({
        departmentName: "",
        description: "",
        "customerList": [],
        "venderList": [],
        "leadSourceTypeList": [],
        deletedItems: [],

    });

    const [createModule, setCreateModule] = useState(false);
    const [loaderModule, setLoaderModule] = useState(false);
    const [editModule, setEditModule] = useState(false);
    const [viewModule, setViewModule] = useState(false);
    const [deleteModule, setDeleteModule] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [selectedItem, setSelectedItem] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);
    const [siteDetailsList, setsiteDetailsList] = useState([]);
    const [removeModule, setRemoveModule] = useState(false);



    useEffect(() => {
        setLoaderModule(true)
        const requestData = {
            isActive: "true"
        }
        dispatch(getTaxConfig(requestData))

        listFetching()
    }, [navigation]);

    const listFetching = async () => {
        const requestData = {
            isActive: "true"
        }
        let countryList = await asyncGetCountryInfo(requestData)
        console.log(countryList);
        
        setState({
            ...state,
            countryList: countryList.data
        })
        setLoaderModule(false)

    }


    const confromDialog = () => {
        return (
            swal
                .fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    type: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!",
                    cancelButtonText: "No",
                })
                .then((result) => {
                    if (result.value) {
                        onSubmitForm()
                    } else {
                        setDeleteModule(false)
                        swal.fire({
                            title: "Cancelled!",
                            text: "Permission denied.",
                            type: "error",
                            icon: "error",
                            timer: 1500,
                        });
                    }
                })
        )
    }

    useEffect(() => {
        if (deleteModule) {
            confromDialog()
        }
    }, [deleteModule])

    useEffect(() => {
        if (getCountrySuccess) {
            setState({
                ...state,
                countryList: getCountryList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_COUNTRY" })
        } else if (getCountryFailure) {
            setState({
                ...state,
                countryList: []
            })
            showMessage("warning", countryErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_COUNTRY" })
        }
    }, [getCountrySuccess, getCountryFailure]);



    useEffect(() => {
        if (getLeadSourceTypeSuccess) {
            setState({
                ...state,
                "leadSourceTypeList": getLeadSourceTypeList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_LEAD_SOURCE_TYPE" })
        } else if (getLeadSourceTypeFailure) {
            setState({
                ...state,
                "leadSourceTypeList": []
            })
            showMessage("warning", leadSourceErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_LEAD_SOURCE_TYPE" })
        }
    }, [getLeadSourceTypeSuccess, getLeadSourceTypeFailure]);

    useEffect(() => {
        if (getTaxConfigSuccess) {
            setState({
                ...state,
                "taxConfigList": getTaxConfigList
            })
            setItemList(getTaxConfigList)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_TAX_CONFIG" })
        } else if (getTaxConfigFailure) {
            setState({
                ...state,
                "taxConfigList": []
            })
            showMessage("warning", taxConfigErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_TAX_CONFIG" })
        }
    }, [getTaxConfigSuccess, getTaxConfigFailure]);



    useEffect(() => {
        if (createTaxConfigSuccess) {
            const temp_state = [...itemList, createTaxConfigData[0]];
            setItemList(temp_state)
            setLoaderModule(false)
            closeModule()
            Alert({ description: 'Created Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            dispatch({ type: "RESET_CREATE_TAX_CONFIG" })
        } else if (createTaxConfigFailure) {
            setLoaderModule(false)
            showMessage("warning", errorMessage?.message)
            dispatch({ type: "RESET_CREATE_TAX_CONFIG" })
        }
    }, [createTaxConfigSuccess, createTaxConfigFailure]);


    useEffect(() => {
        if (updateTaxConfigSuccess) {
            updateTable(updateTaxConfigData[0])
            dispatch({ type: "RESET_UPDATE_TAX_CONFIG" })
        } else if (updateTaxConfigFailure) {
            setLoaderModule(false)
            showMessage("warning", errorMessage?.message)
            dispatch({ type: "RESET_UPDATE_TAX_CONFIG" })
        }
    }, [updateTaxConfigSuccess, updateTaxConfigFailure]);

    const updateTable = (updatedItem) => {
        const temp_state = [...itemList];
        if (deleteModule) {
            let remainingList = _.remove(temp_state, function (n) {
                return n.taxConfigurationId != updatedItem.taxConfigurationId;
            });
            Alert({ description: 'Deleted Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(remainingList)
        } else {
            temp_state[selectedIndex] = updatedItem;
            Alert({ description: 'Updated Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(temp_state)
        }
        setLoaderModule(false)
        closeModule()
    }

    const closeModule = () => {
        editData = false;
        setCreateModule(false)
        setDeleteModule(false)
    }

    const resetState = () => {
        setState({
            ...state,
            countryId: "",
           
        })
        setsiteDetailsList([])

    }

    const onCreateForm = () => {
        resetState()
        editData = false;
        setCreateModule(true)
    }

    const onEditForm = (data, index) => {
        let countryList = state?.countryList || [];
        let countryObject = countryList.find((country) => country.countryName === (data?.country || ""));

        setState({
            ...state,
            countryId: countryObject?.countryId || "",

        })
        setsiteDetailsList(data?.["taxType"] || []);

        editData = true;
        setSelectedIndex(index)
        setSelectedItem(data)
        setCreateModule(true)
    }

    const onDeleteItem = (data, index) => {
        editData = false;
        deleteModule = true;
        setSelectedIndex(index)
        setSelectedItem(data)
        const deleteRequest = {
            isActive: "false"
        }
        setLoaderModule(true)
        dispatch(updateTaxConfig(deleteRequest, selectedItem.taxConfigurationId))
    }

    const validateForm = async () => {
        const validatefom = customerFormRef.current.validateForm();
    }

    const onSubmitForm = () => {
        let countryList = state?.countryList || [];
        let countryObject = countryList.find((country) => country.countryId === Number(state?.countryId || ""));


        const requestDate = {
            "country": countryObject?.countryName || "",
            "countryCode": countryObject?.countryCode || "",
            "taxType": siteDetailsList
        }
        const hasInvalidTaxMeta = requestDate.taxType.some(tax => !tax.code || !tax.name);


        if (!requestDate.country || hasInvalidTaxMeta) {
            // showMessage("warning","Please fill  all required fields."); // or show a custom message
            Alert({ description: 'Please fill  all required fields.', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });

            return; // Stop form submission
        }
        if (editData) {
            setLoaderModule(true)

            dispatch(updateTaxConfig(requestDate, selectedItem.taxConfigurationId))


        } else if (deleteModule) {
            const deleteRequest = {
                isActive: "false"
            }
            setLoaderModule(true)
            dispatch(updateTaxConfig(deleteRequest, selectedItem.taxConfigurationId))
        } else {
            setLoaderModule(true)
            dispatch(createTaxConfig(requestDate))
        }
    }

    const {
        departmentName,
        description,
        customerList
    } = state;

    const modelTitle = editData ? "Edit " : "Create ";
    const modelButtonLbl = editData ? "Update" : "Save";

    const addItem = () => {
        const defaultItem = {

            "code": "",
            "name": "",

        }
        setsiteDetailsList([...siteDetailsList, defaultItem])
    }
    const onDeletePress = (indexx) => {
        let temp_state = [...siteDetailsList];

        let addd = siteDetailsList.splice(indexx, 1);
        let remainingList = _.remove(temp_state, function (n, index) {
            return index != indexx
        });
        setsiteDetailsList(remainingList);

        let temp_arr = { ...state }
        setState({
            ...state,
            deletedItems: [...temp_arr.deletedItems, ...addd]
        })

    };

    const handleClick = (buttonName) => {
        if (buttonName == 'Add') {
            onCreateForm();
        }
    };

    return (
        <div>
            <Loading status={loaderModule}/>
            <div style={{ marginTop: "-10px" }}>
                <View isOpen={createModule} size="lg" savetitle={modelButtonLbl} onClose={setCreateModule} onSubmit={validateForm} title={`${modelTitle} TaxConfig`}>
                    <FormLayout ref={customerFormRef} defaultState={state} setDefaultState={setState} dynamicForm={taxConfigForm} noOfColumn={3} onSubmit={onSubmitForm} />
                    <Button className="me-3" style={{ backgroundColor: `${lightTheme.primaryColor}` ,border:"none" ,fontSize:'12px'}} variant="primary" onClick={() => addItem()}>
                        {"Add Item"}
                    </Button>
                    <div className="table-responsive">
                        <table className="table" style={{ textAlign: 'center' }}>
                            <thead >
                                <tr>
                                    <th scope="col">Tax Code</th>
                                    <th scope="col">Tax Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(siteDetailsList ? siteDetailsList : []).map((item, index) => {
                                    return (
                                        <TableLayout key={index} defaultState={state} setDefaultState={setsiteDetailsList} index={index} layoutType="table" dataList={siteDetailsList} dynamicForm={projectSiteDetailsForm} iconPress={{ "onDeletePress": onDeletePress }} />
                                    )
                                })}

                            </tbody>
                        </table>
                    </div>
                </View>

                {/* 
            <View centered={true} size={'sm'} isOpen={deleteModule} onClose={setDeleteModule} savetitle="Yes" onSubmit={onSubmitForm} title="Delete TaxConfig">
                <h6 className="text-capitalize mb-1">Are you sure ?</h6>
            </View> */}

                <SimpleCard >
                    <CustomTable buttonData={['add']} handleClick={handleClick} tableName={"TaxConfig"} columns={column} data={itemList} initialPage={1} />
                </SimpleCard>

            </div>
        </div>
    )
};

export default TaxConfig;
