import { returnApiCallPromise } from './ApiContent';
import { employee, employeeDetails, employeeReport, employeePromotion, employeeReporting } from './APIContainer';

//GET
export function getEmployee(request) {
  const requestObj = request ? { params: request } : "";
  return (dispatch) => {
    returnApiCallPromise("GET", employee, requestObj)
      .then(response => {
        dispatch({
          type: 'GET_EMPLOYEE_SUCCESS',
          payload: response.data
        })
      }).catch(error => {

        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'GET_EMPLOYEE_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

export function asyncGetEmployeeInfo(request) {
  const requestObj = request ? { params: request } : "";
  return  returnApiCallPromise("GET", employee, requestObj)
      .then(response => {
        console.log(response)
        return {
          error: false,
          message:"Succsss",
          data: response?.data?.data || []
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        return {
          error: true,
          data:[],
          message:errorMessage
        }
      })
}

export function getEmployeeReporting(request) {
  const requestObj = request ? { params: request } : "";
  return (dispatch) => {
    returnApiCallPromise("GET", employeeReporting, requestObj)
      .then(response => {
        dispatch({
          type: 'GET_EMPLOYEE_REPORTING_SUCCESS',
          payload: response.data
        })
      }).catch(error => {

        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'GET_EMPLOYEE_REPORTING_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

export function getEmployeeReportingInfo(request) {
  const requestObj = request ? { params: request } : "";
  return (
    returnApiCallPromise("GET", employeeReporting, requestObj)
    .then(response => {
      return {
        error: false,
        message:"Succsss",
        data: response?.data?.data || []
      }
    }).catch(error => {
      let errorMessage = error;
      if (error.response) {
        if (error.response.data.error) {
          errorMessage = error.response.data.message;
        }
      }
      return {
        error: true,
        data:[],
        message:errorMessage
      }
    })
  )
}

export function getEmployeeReport(request) {
  const requestObj = request ? { params: request } : "";
  return (dispatch) => {
    returnApiCallPromise("GET", employeeReport, requestObj)
      .then(response => {
        dispatch({
          type: 'GET_EMPLOYEE_REPORT_SUCCESS',
          payload: response.data
        })
      }).catch(error => {

        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'GET_EMPLOYEE_REPORT_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

export function getEmployeeDetails(request) {
  const requestObj = request ? { params: request } : "";
  return (dispatch) => {
    returnApiCallPromise("GET", employeeDetails, requestObj)
      .then(response => {
        dispatch({
          type: 'GET_EMPLOYEE_DETAILS_SUCCESS',
          payload: response.data
        })
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'GET_EMPLOYEE_DETAILS_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

export function asyncGetEmployeeDetailsInfo(request) {
  const requestObj = request ? { params: request } : "";
  return  returnApiCallPromise("GET", employeeDetails, requestObj)
      .then(response => {
        console.log(response)
        return {
          error: false,
          message:"Succsss",
          data: response?.data?.data || []
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        return {
          error: true,
          data:[],
          message:errorMessage
        }
      })
}


export function getEmployeePromotion(request) {
  const requestObj = request ? { params: request } : "";
  return (dispatch) => {
    returnApiCallPromise("GET", employeePromotion, requestObj)
      .then(response => {
        dispatch({
          type: 'GET_EMPLOYEE_PROMOTION_SUCCESS',
          payload: response.data
        })
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'GET_EMPLOYEE_PROMOTION_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

//POST
export function createEmployee(request) {
  return (dispatch) => {
    returnApiCallPromise("POST", employee, request)
      .then(response => {
        if (response.data.error) {
          dispatch({
            type: 'CREATE_EMPLOYEE_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'CREATE_EMPLOYEE_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data;
          }
        }
        dispatch({
          type: 'CREATE_EMPLOYEE_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}


export function updateEmployee(request, employeeId) {
  return (dispatch) => {
    returnApiCallPromise("PUT", employee + "/" + employeeId, request)
      .then(response => {
        if (response.data.error) {
          dispatch({
            type: 'UPDATE_EMPLOYEE_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'UPDATE_EMPLOYEE_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'UPDATE_EMPLOYEE_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}


//POST
export function createEmployeePromotion(request) {
  return (dispatch) => {
    returnApiCallPromise("POST", employeePromotion, request)
      .then(response => {
        if (response.data.error) {
          dispatch({
            type: 'CREATE_EMPLOYEE_PROMOTION_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'CREATE_EMPLOYEE_PROMOTION_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data;
          }
        }
        dispatch({
          type: 'CREATE_EMPLOYEE_PROMOTION_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

export function updateEmployeePromotion(request, positionId) {
  return (dispatch) => {
    returnApiCallPromise("PUT", employeePromotion + "/" + positionId, request)
      .then(response => {
        if (response.data.error) {
          dispatch({
            type: 'UPDATE_EMPLOYEE_PROMOTION_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'UPDATE_EMPLOYEE_PROMOTION_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'UPDATE_EMPLOYEE_PROMOTION_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}
