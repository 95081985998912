import * as yup from "yup";
import { Formik } from "formik";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import DateTime from "react-datetime";
import { Form, Button, Table } from "react-bootstrap";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { deliveryForm, createDeliveryForm, fieldList, sizeList, createCustomerForm } from "./formData";
import { MdEdit, MdDelete, MdVisibility } from "react-icons/md";
import swal from "sweetalert2";
import _ from "lodash";
// import SearchButton from "app/components/SearchButton";
import { Row, Col, Tab, Tabs, Nav } from "react-bootstrap";
import { createCustomer, getCustomer } from "app/api/CustomerInventoryApi";
import { getCreditTerm } from "app/api/CreditTermApi";
import { getAvailableMaterial, getMaterialInfo, getMaterial } from "app/api/ProductApi";
import { getMaterialType } from "app/api/MaterialTypeApi";
import { getPriceGroup, getAsyncPriceGroup } from "app/api/PriceGroupApi";
import { getTax } from "app/api/TaxApi";
import { getCurrency } from "app/api/CurrencyApi";
import { state } from "app/api/APIContainer";
import { getMeasurement, getUnit } from "app/api/MeasurementApi";
import { getSales } from "app/api/SalesApi";
import { createDelivery, getDelivery, updateDelivery } from "app/api/DeliveryApi";
import moment from "moment";
import { amountFormat, dateConversion, decimalAmount, pagePermission, showMessage, validate } from "app/utils/app-functions";
import FormLayout from "app/components/render/formlayout";
import View from "app/components/atoms/modalView/Modalview";
import CustomTable from "app/components/atoms/custom-table/CustomTable";
import formRender from "app/components/render/formcomponent";
import SimpleCard from 'app/components/atoms/simple-card/SimpleCard'
import { FiEdit, FiSend, FiTrash2 } from 'react-icons/fi';
import CollapseView from "app/components/atoms/CollapseView/CollapseView";
import TableLayout from "app/components/render/formlayout/tablelayout";
import Alert from "app/components/atoms/alert";
import { getShowMaterial } from "app/api/ShowMaterialApi";
import MultiTabs from 'app/components/atoms/multiTab/MultiTab';
import { getQuotationSlect } from "app/api/QuotationApi";
import { getMaterialCode } from "app/api/MaterialCodeApi";
import PdfPreview from "app/components/PdfPreview";

let editData = false;
let object = [];
let unitObject = [];

const Delivery = ({ navigation }) => {

    const dispatch = useDispatch();
    const customerFormRef = useRef();


    const getDeliverySuccess = useSelector((state) => state.deliveryReducer.getDeliverySuccess);
    const getDeliveryList = useSelector((state) => state.deliveryReducer.getDeliveryList);
    const getDeliveryFailure = useSelector((state) => state.deliveryReducer.getSalesFailure);

    const getSalesSuccess = useSelector((state) => state.salesReducer.getSalesSuccess);
    const getSalesList = useSelector((state) => state.salesReducer.getSalesList);
    const getSalesFailure = useSelector((state) => state.salesReducer.getSalesFailure);

    const createDeliverySuccess = useSelector((state) => state.deliveryReducer.createDeliverySuccess);
    const createDeliveryData = useSelector((state) => state.deliveryReducer.createDeliveryData);
    const createDeliveryFailure = useSelector((state) => state.deliveryReducer.createDeliveryFailure);

    const updateDeliverySuccess = useSelector((state) => state.deliveryReducer.updateDeliverySuccess);
    const updateDeliveryData = useSelector((state) => state.deliveryReducer.updateDeliveryData);
    const updateDeliveryFailure = useSelector((state) => state.deliveryReducer.updateDeliveryFailure);

    const getCustomerSuccess = useSelector((state) => state.customerInventoryReducer.getCustomerSuccess);
    const getCustomerList = useSelector((state) => state.customerInventoryReducer.getCustomerList);
    const getCustomerFailure = useSelector((state) => state.customerInventoryReducer.getCustomerFailure);

    const createCustomerSuccess = useSelector((state) => state.customerInventoryReducer.createCustomerSuccess);
    const createCustomerData = useSelector((state) => state.customerInventoryReducer.createCustomerData);
    const createCustomerFailure = useSelector((state) => state.customerInventoryReducer.createCustomerFailure);

    const getCreditTermSuccess = useSelector((state) => state.utilityReducer.getCreditTermSuccess);
    const getCreditTermList = useSelector((state) => state.utilityReducer.getCreditTermList);
    const getCreditTermFailure = useSelector((state) => state.utilityReducer.getCreditTermFailure);

    const getMaterialTypeSuccess = useSelector((state) => state.productReducer.getMaterialTypeSuccess);
    const getMaterialTypeList = useSelector((state) => state.productReducer.getMaterialTypeList);
    const getMaterialTypeFailure = useSelector((state) => state.productReducer.getMaterialTypeFailure);

    const getPricegroupSuccess = useSelector((state) => state.priceGroupRedecer.getPricegroupSuccess);
    const getPricegroupList = useSelector((state) => state.priceGroupRedecer.getPricegroupList);
    const getPricegroupFailure = useSelector((state) => state.priceGroupRedecer.getPricegroupFailure);

    const getTaxSuccess = useSelector((state) => state.taxReducer.getTaxSuccess);
    const getTaxList = useSelector((state) => state.taxReducer.getTaxList);
    const getTaxFailure = useSelector((state) => state.taxReducer.getTaxFailure);

    const getCurrencySuccess = useSelector((state) => state.currencyReducer.getCurrencySuccess);
    const getCurrencyList = useSelector((state) => state.currencyReducer.getCurrencyList);
    const getCurrencyFailure = useSelector((state) => state.currencyReducer.getCurrencyFailure);

    const getMaterialCodeSuccess = useSelector((state) => state.materialCodeReducer.getMaterialCodeSuccess);
    const getMaterialCodeList = useSelector((state) => state.materialCodeReducer.getMaterialCodeList);
    const getMaterialCodeFailure = useSelector((state) => state.materialCodeReducer.getMaterialCodeFailure);
    const materialCodeErrorMessage = useSelector((state) => state.materialCodeReducer.errorMessage);

    const getMeasurementSuccess = useSelector((state) => state.measurementReducer.getMeasurementSuccess);
    const getMeasurementList = useSelector((state) => state.measurementReducer.getMeasurementList);
    const getMeasurementFailure = useSelector((state) => state.measurementReducer.getMeasurementFailure);
    const measurementErrorMessage = useSelector((state) => state.measurementReducer.errorMessage);

    const getLocationSuccess = useSelector((state) => state.locationReducer.getLocationSuccess);
    const getLocationList = useSelector((state) => state.locationReducer.getLocationList);
    const getLocationFailure = useSelector((state) => state.locationReducer.getLocationFailure);

    const getWarehouseSuccess = useSelector((state) => state.warehouseReducer.getWarehouseSuccess);
    const getWarehouseList = useSelector((state) => state.warehouseReducer.getWarehouseList);
    const getWarehouseFailure = useSelector((state) => state.warehouseReducer.getWarehouseFailure);

    const getAvailableMaterialSuccess = useSelector((state) => state.productReducer.getAvailableMaterialSuccess);
    const getAvailableMaterialList = useSelector((state) => state.productReducer.getAvailableMaterialList);
    const getAvailableMaterialFailure = useSelector((state) => state.productReducer.getAvailableMaterialFailure);

    const customerErrorMessage = useSelector((state) => state.customerInventoryReducer.errorMessage);
    const deliveryErrorMessage = useSelector((state) => state.quotationReducer.errorMessage);
    const salesErrorMessage = useSelector((state) => state.salesReducer.errorMessage);
    const materialErrorMessage = useSelector((state) => state.productReducer.errorMessage);


    const [pageItems, setpageItems] = useState({});

    useEffect(() => {
        let data = pagePermission(pageItems, "Inventory", "Delivery" ,"Sales")
        setpageItems(data)
        // console.log(data);

    }, [navigation]);


    const column = [
        {
            "dataField": "billNumber",
            "text": "Order Number",
            "sort": true
        },
        {
            "dataField": "dateOfBill",
            "text": "DOC Date",
            "sort": false,
            formatter: (cellContent, row, index) => {
                return dateConversion(cellContent, "DD-MMM-YYYY")
            }
        },
        {
            "dataField": "customerName",
            "text": "Customer Name",
            "sort": false
        },
        {
            "dataField": "customerPhoneNo",
            "text": "Customer Phone Number",
            "sort": false
        },
        {
            "dataField": "Transfer From",
            "text": "Transfer From",
            "sort": false
        },
        {
            dataField: "status",
            text: "Status",
            sort: true,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => {
                const status = cellContent === 'close' ? false : true;
                const conInfo = { inputType: "status", status: status, succesName: cellContent, failureName: cellContent }
                return formRender(null, null, conInfo, null);
            }
        },
    ]
    if (pageItems.update || pageItems.status) {
        column.push({
            text: "Action",
            sort: false,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => {
                const editClass = row.status === 'open' ? 'text-primary' : 'text-danger';
                return (
                    <div className="icon-container">
                        {pageItems.view && <MdVisibility
                            className={`text-success cursor-pointer`}
                            size={20}
                            onClick={() => onViewForm(row, index)}
                        />}
                        {pageItems.update && <FiEdit
                            className={`${editClass} cursor-pointer`}
                            size={20}
                            onClick={() => onEditForm(row, index)}
                        />}

                        {/*  {row.status === 'open' && <MdDelete
                            className="text-danger cursor-pointer"
                            size={20}
                            onClick={() => {
                                swal.fire({
                                    title: "Are you sure?",
                                    text: "You won't be able to revert this!",
                                    icon: "warning",
                                    type: "question",
                                    showCancelButton: true,
                                    confirmButtonColor: "#3085d6",
                                    cancelButtonColor: "#d33",
                                    confirmButtonText: "Yes, delete it!",
                                    cancelButtonText: "No",
                                }).then((result) => {
                                    if (result.value) {
                                        onDeleteItem(row, index);
                                    } else {
                                        swal.fire({
                                            title: "Cancelled!",
                                            text: "Permission denied.",
                                            type: "error",
                                            icon: "error",
                                            timer: 1500,
                                        });
                                    }
                                });
                            }}
                        />} */}
                    </div>
                );
            }
        },)
    }

    const itemColumn = [
        {
            "dataField": "MaterialType",
            "text": "Material Type",
            "sort": true
        },
        {
            "dataField": "MaterialCode",
            "text": "Material Code",
            "sort": true
        },
        {
            "dataField": "MaterialName",
            "text": "Material Name",
            "sort": true
        },
        {
            "dataField": "Qty",
            "text": "Qty",
            "sort": true
        },
        {
            "dataField": "unit",
            "text": "Unit",
            "sort": true
        },
        {
            "dataField": "Total Tax",
            "text": "Description",
            "sort": true,
            "align": 'center'
        },
        {
            "dataField": "Action",
            "text": "Add Item Details",
            "sort": true,
        },
        {
            "dataField": "Action",
            "text": "Action",
            "sort": true,
            "align": 'center'
        },
    ]

    const itemHeader = [
        {
            "dataField": "status",
            "text": "",
            formatter: (cellContent, row, index, extraData) => {
                const conInfo = { inputType: "checkbox", status: cellContent }
                return formRender(extraData, null, conInfo, null, null, statusChange, index);
            }
        },
        {
            "dataField": "imageName",
            "text": "Image",
            "sort": true,
            formatter: (cellContent, row, index, extraData) => {
                return <img
                    className="avatar-lg active"
                    src={`/assets/images/no-image.png`}
                    alt=""
                    style={{ objectFit: "contain", height: "20px", width: "20px" }}

                />
            }
        },
        {
            "dataField": "serialNumber",
            "text": "Serial Number",
            "sort": true
        },
        {
            "dataField": "batchNumber",
            "text": "Batch Number",
            "sort": true
        },
        {
            "dataField": "locationName",
            "text": "Receipt Location",
            "sort": true
        },
        {
            "dataField": "warehouseName",
            "text": "Warehouse",
            "sort": true
        },

    ]

    const itemDetailsHeader = [
        {
            "dataField": "imageName",
            "text": "Image",
            "sort": true,
            formatter: (cellContent, row, index, extraData) => {
                return <img
                    className="avatar-lg active"
                    src={`/assets/images/no-image.png`}
                    alt=""
                    style={{ objectFit: "contain", height: "20px", width: "20px" }}

                />
            }
        },
        {
            "dataField": "serialNumber",
            "text": "Serial Number",
            "sort": true
        },
        {
            "dataField": "batchNumber",
            "text": "Batch Number",
            "sort": true
        },
        {
            "dataField": "locationName",
            "text": "Receipt Location",
            "sort": true
        },
        {
            "dataField": "warehouseName",
            "text": "Warehouse",
            "sort": true
        },
        {
            text: "Action",
            sort: false,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => (
                <div >
                    <MdDelete
                        className="text-danger cursor-pointer"
                        size={18}
                        onClick={() => {
                            onExchangeItem(row, index);
                        }}
                    ></MdDelete>
                </div>
            )
        },
    ]

    const [state, setState] = useState({
        /*  fromDate: new Date(),
         validDate: new Date(moment().add(10, 'd').format('YYYY-MM-DD')), */
        creditList: [],
        deletedItems: [],
        checkCount: 0,
        creditLimit: '',
        amount: 0,
        clickIndex: '',
        totalAmount: 0.00,
        totalTax: 0.0,
        osList: [
            {
                "osId": 1,
                "osName": "Sales"
            },
            {
                "osId": 2,
                "osName": "Invoice"
            },

        ],
        osId: 1,
        footerData: [
            { label: 'Amount (before tax)', value: 0 },
            { label: 'Tax :', value: 0 },
            { label: 'Total Amount:', value: 0 },
            { label: 'Local Currency:', value: 0 }
        ]
    });

    const [createOrder, setCreateOrder] = useState(createDeliveryForm)
    const [createModule, setCreateModule] = useState(false);
    const [loaderModule, setLoaderModule] = useState(false);
    const [editModule, setEditModule] = useState(false);
    const [viewModule, setViewModule] = useState(false);
    const [deleteModule, setDeleteModule] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [selectedItem, setSelectedItem] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [formList, setFieldList] = useState(fieldList)
    const [customerForm, setCustomerForm] = useState(false)
    const [currencySymbol, setCurrencySymbol] = useState(false)
    const [tableRows, setTableRows] = useState([])
    const [subIndex, setSubIndex] = useState(false);
    const [selectMaterial, setSelectMaterial] = useState([])
    const [downloadPdf, setDownloadPdf] = useState(false);


    useEffect(() => {
        setLoaderModule(true)
        const requestData = {
            isActive: "true",
            orderStatus: "delivery"
        }
        const req = {
            isActive: "true",
            orderStatus: "sales"
        }
        dispatch(getDelivery(requestData))
        // dispatch(getSales(req))
        // dispatch(getCustomer())
        // dispatch(getCreditTerm())
        // dispatch(getMaterialType())
        dispatch(getPriceGroup())
        // dispatch(getTax())
        dispatch(getMaterialInfo())
        // dispatch(getCurrency())
        dispatch(getMaterial())
        // dispatch(getMeasurement())
        listFetching()
    }, [navigation]);


    const listFetching = async () => {
        let creditTermList = await getCreditTerm({ isActive: true })
        let customerList = await getCustomer()
        let materialTypeList = await getMaterialType()
        let measurementList = await getMeasurement()
        let taxList = await getTax({
            isActive: "true",
        })
        let currencyList = await getCurrency()
        let quotationList = await getQuotationSlect({
            status: 'open',
            orderStatus: "sales"
        })
        object = customerList.getCustomerList
        unitObject = measurementList.getMeasurementList
        let tempArr = quotationList.getQuotationList
        const data = tempArr.map((itm) => {
            return { ...itm, itemInfo: `${itm.billNumber} - ${itm.customerName} - ${dateConversion(itm.dateOfBill, "YYYY/MM/DD")}` };
        });
        setState({
            ...state,
            unitsList: measurementList.getMeasurementList,
            creditList: creditTermList.getCreditTermList,
            customerList: customerList.getCustomerList,
            taxList: taxList.getTaxList,
            materialTypeList: materialTypeList.getMaterialTypeList,
            currencyList: currencyList.getCurrencyList,
            deliveryList: data,
        })

    }
    //CUSTOMER

    useEffect(() => {
        if (getCustomerSuccess) {
            setState({
                ...state,
                customerList: getCustomerList
            })
            dispatch({ type: "RESET_GET_CUSTOMER" })
        }
        else if (getCustomerFailure) {
            setState({
                ...state,
                customerList: []
            })
            setLoaderModule(false)
            showMessage("warning", customerErrorMessage?.message)
            dispatch({ type: "RESET_GET_CUSTOMER" })
        }
    }, [getCustomerSuccess, getCustomerFailure]);

    useEffect(() => {
        if (createCustomerSuccess) {
            setState({
                ...state,
                customerList: [...state.customerList, createCustomerData[0]]
            })
            setLoaderModule(false)
            // closeModule()
            setCustomerForm(false)
            Alert({ description: 'Created Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            dispatch({ type: "RESET_CREATE_CUSTOMER" })
        } else if (createCustomerFailure) {
            setState({
                ...state,
                customerList: []
            })
            setLoaderModule(false)
            showMessage("warning", customerErrorMessage?.message)
            dispatch({ type: "RESET_CREATE_CUSTOMER" })
        }
    }, [createCustomerSuccess, createCustomerFailure]);


    useEffect(() => {
        if (getCreditTermSuccess) {
            setState({
                ...state,
                creditList: getCreditTermList
            })
            dispatch({ type: "RESET_GET_CREDIT_TERM" })
        } else if (getCreditTermFailure) {
            setState({
                ...state,
                creditList: []
            })
            showMessage("warning", salesErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_CREDIT_TERM" })
        }
    }, [getCreditTermSuccess, getCreditTermFailure]);

    useEffect(() => {
        if (getPricegroupSuccess) {
            setState({
                ...state,
                priceGroupList: getPricegroupList
            })
            dispatch({ type: "RESET_GET_PRICE_GROUP" })
        }
        else if (getPricegroupFailure) {
            setState({
                ...state,
                priceGroupList: []
            })
            showMessage("warning", salesErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_PRICE_GROUP" })
        }
    }, [getPricegroupSuccess, getPricegroupFailure]);

    useEffect(() => {
        if (getTaxSuccess) {
            setState({
                ...state,
                taxList: getTaxList
            })
            dispatch({ type: "RESET_GET_TAX" })
        }
        else if (getTaxFailure) {
            setState({
                ...state,
                taxList: []
            })
            showMessage("warning", salesErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_TAX" })
        }
    }, [getTaxSuccess, getTaxFailure]);

    useEffect(() => {
        if (getCurrencySuccess) {
            setState({
                ...state,
                currencyList: getCurrencyList
            })
            dispatch({ type: "RESET_GET_CURRENCY" })
        }
        else if (getCurrencyFailure) {
            setState({
                ...state,
                currencyList: []
            })
            showMessage("warning", salesErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_CURRENCY" })
        }
    }, [getCurrencySuccess, getCurrencyFailure]);

    useEffect(() => {
        if (getMaterialCodeSuccess) {
            setState({
                ...state,
                materialCodeList: getMaterialCodeList
            })
            setSelectMaterial(getMaterialCodeList)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_MATERIALCODE" })
        } else if (getMaterialCodeFailure) {
            setState({
                ...state,
                materialCodeList: []
            })
            showMessage("warning", materialCodeErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_MATERIALCODE" })
        }
    }, [getMaterialCodeSuccess, getMaterialCodeFailure]);

    useEffect(() => {
        if (getMeasurementSuccess) {
            setState({
                ...state,
                unitsList: getMeasurementList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_MEASUREMENT" })
        } else if (getMeasurementFailure) {
            setState({
                ...state,
                unitsList: []
            })
            showMessage("warning", measurementErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_MEASUREMENT" })
        }
    }, [getMeasurementSuccess, getMeasurementFailure]);

    useEffect(() => {
        if (getLocationSuccess) {
            setSelectMaterial(getLocationList)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_LOCATION" })
        } else if (getLocationFailure) {
            setSelectMaterial([])
            showMessage("warning", deliveryErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_LOCATION" })
        }
    }, [getLocationSuccess, getLocationFailure]);

    useEffect(() => {
        if (getSalesSuccess) {
            const data = getSalesList.map((itm) => {
                return { ...itm, itemInfo: `${itm.billNumber} - ${itm.customerName} - ${dateConversion(itm.dateOfBill, "YYYY/MM/DD")}` }
            })
            setState({
                ...state,
                deliveryList: data
            });
            dispatch({ type: "RESET_GET_SALES" })
        }
    }, [getSalesSuccess, getSalesFailure]);

    useEffect(() => {
        if (getWarehouseSuccess) {
            setSelectMaterial(getWarehouseList)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_WAREHOUSE" })
        } else if (getWarehouseFailure) {
            setSelectMaterial([])
            showMessage("warning", deliveryErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_WAREHOUSE" })
        }
    }, [getWarehouseSuccess, getWarehouseFailure]);

    useEffect(() => {
        if (getMaterialTypeSuccess) {
            setState({
                ...state,
                materialTypeList: getMaterialTypeList
            })
            dispatch({ type: "RESET_GET_MATERIAL_TYPE" })
        }
        else if (getMaterialTypeFailure) {
            setState({
                ...state,
                materialTypeList: []
            })
            showMessage("warning", salesErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_MATERIAL_TYPE" })
        }
    }, [getMaterialTypeFailure, getMaterialTypeSuccess]);

    useEffect(() => {
        if (getAvailableMaterialSuccess) {
            const parentIndex = selectedIndex;
            const parentRows = [...tableRows];
            const itemInfo = parentRows[parentIndex]?.itemDetails || [];

            const filter_data = _.filter(getAvailableMaterialList, (item, index) =>
                _.findIndex(itemInfo, (n) => n.itemDetailsId === item.itemDetailsId) === -1
            )
            setSelectMaterial(filter_data)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_AVAILABEL_MATERIAL" })
        } else if (getAvailableMaterialFailure) {
            setSelectMaterial([])
            showMessage("warning", deliveryErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_AVAILABEL_MATERIAL" })
        }
    }, [getAvailableMaterialSuccess, getAvailableMaterialFailure]);

    useEffect(() => {
        if (getDeliverySuccess) {
            setItemList(getDeliveryList)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_DELIVERY" })
        } else if (getDeliveryFailure) {
            setItemList([])
            showMessage("warning", deliveryErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_DELIVERY" })
        }
    }, [getDeliverySuccess, getDeliveryFailure]);

    useEffect(() => {
        if (createDeliverySuccess) {
            const temp_state = [...itemList, createDeliveryData[0]];
            setItemList(temp_state)
            setLoaderModule(false)
            closeModule()
            Alert({ description: 'Created Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            dispatch({ type: "RESET_CREATE_DELIVERY" })
        } else if (createDeliveryFailure) {
            setLoaderModule(false)
            showMessage("warning", deliveryErrorMessage?.message)
            dispatch({ type: "RESET_CREATE_DELIVERY" })
        }
    }, [createDeliverySuccess, createDeliveryFailure]);

    useEffect(() => {
        if (updateDeliverySuccess) {
            updateTable(updateDeliveryData[0])
            dispatch({ type: "RESET_UPDATE_DELIVERY" })
        } else if (updateDeliveryFailure) {
            setLoaderModule(false)
            showMessage("warning", deliveryErrorMessage?.message)
            dispatch({ type: "RESET_UPDATE_DELIVERY" })
        }
    }, [updateDeliverySuccess, updateDeliveryFailure]);


    const updateTable = (updatedItem) => {
        const temp_state = [...itemList];
        if (deleteModule) {
            let remainingList = _.remove(temp_state, function (n) {
                return n.orderId != updatedItem.orderId;
            });
            Alert({ description: 'Deleted Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(remainingList)
        } else {
            temp_state[subIndex] = updatedItem;
            Alert({ description: 'Updated Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(temp_state)
        }
        setLoaderModule(false)
        closeModule()
    }

    const closeModule = () => {
        editData = false;
        setCreateModule(false)
        setDeleteModule(false)
    }

    const resetState = () => {
        setState({
            ...state,
            "orderId": [],
            "osId": 1,
            "customerName": [],
            "contactPerson": "",
            "phoneNo": "",
            "address": "",
            "creditTerm": "",
            "deletedItems": [],
            "salesPerson": "",
            "custPoNumber": "",
            "memo": "",
            "billingAddress": "",
            "deliveryAddress": "",
            "createDate": "",
            "productTax": false,
            "priceIncludingGST": false,
            "reserveStock": false,
            "currencyId": "",
            "fromDate": moment(new Date(), "YYYY-MM-DD"),
            "validDate": moment(new Date(), "YYYY-MM-DD"),
            "customerAddress": false,
            "sameBillingAddress": false,
            footerData: [
                { label: 'Amount (before tax)', value: 0 },
                { label: 'Tax :', value: 0 },
                { label: 'Total Amount:', value: 0 },
                { label: 'Local Currency:', value: 0 }
            ]
        })
        setTableRows([])

    }

    const onCreateForm = () => {
        resetState()
        editData = false;
        let requestQuote = {
            orderStatus: "sales",
            status: "open"
        }

        // dispatch(getSales(requestQuote))

        let formData = createDeliveryForm
        const object1 = [{
            label: "Order List",
            inputType: "radio",
            options: "osList",
            type: "block",
            uniqueKey: "osId",
            onChange: "onHandleRadio",
            displayKey: "osName",
            fieldName: "osId",
            defaultValue: "",
            config: {
                required: "",
            },
        },
        {
            label: "",
            inputType: "typedropdown",
            options: 'deliveryList',
            uniqueKey: "orderId",
            // isLoading:true,
            displayKey: "itemInfo",
            onChange: "handleOrder",
            fieldName: "orderId",
            key: "invoiceKey",
            defaultValue: "",
            placeholder: 'Order',
            config: {
                required: "required",
            },
        }]
        const form1 = formData[0].formBody[0].formField
        if (form1[0].fieldName != "osId") {
            form1.unshift({
                label: "Order List",
                inputType: "radio",
                options: "osList",
                uniqueKey: "osId",
                type: "block",
                onChange: "onHandleRadio",
                displayKey: "osName",
                fieldName: "osId",
                defaultValue: "",
                config: {
                    required: "",
                },
            }, {
                label: "",
                inputType: "typedropdown",
                options: 'invoiceList',
                uniqueKey: "orderId",
                // isLoading:true,
                displayKey: "itemInfo",
                onChange: "handleOrder",
                fieldName: "orderId",
                key: "invoicKey",
                defaultValue: "",
                placeholder: 'Order',
                config: {
                    required: "",
                },
            })
        }
        setCreateOrder(formData)
        setCreateModule(true)
    }

    const handleOrder = (event, name, index,) => {
        let temp_state = ''
        const temp_arr = state?.customerList || object
        const eventVal = Object.keys(event).length ? event : {};
        const filter_data = temp_arr.filter(item => item.customerId == eventVal?.customerId)
        if (!event.label || event.length === 0) {
            setTableRows([]);
            resetState()
            return;
        }

        if (Object.keys(eventVal).length) {
            temp_state = {
                ...state,
                [name]: event.label,
                customerName: filter_data[0]?.customerName || event.customerId,
                customerId: event.customerId,
                phoneNo: eventVal?.customerPhoneNo || "",
                address: eventVal?.customerAddress || "",
                creditTerm: eventVal?.creditTerm || "",
                salesPerson: eventVal?.salesPerson || "",
                contactPerson: filter_data[0]?.contactPerson || '',
                memo: eventVal?.memo || "",
                customerAddress: eventVal?.customerAddress == eventVal?.billingAddress ? true : false,
                sameBillingAddress: eventVal?.billingAddress == eventVal?.deliveryAddress ? true : false,
                custPoNumber: eventVal?.custPoNumber || "",
                billingAddress: eventVal?.billingAddress || '',
                deliveryAddress: eventVal?.deliveryAddress || '',
                productTax: eventVal?.productTax || false,
                priceIncludingGST: eventVal?.priceIncludingGST || false,
                reserveStock: eventVal?.reserveStock || false,
                orderNumber: eventVal?.orderNumber || '',
                "fromDate": moment(eventVal.dateOfBill, "YYYY-MM-DD"),
                "validDate": moment(eventVal.validTill, "YYYY-MM-DD"),
                currencyId: eventVal?.currencyId || '',
                // orderId:event
            }
            let temp_data = [...tableRows]
            const filteringData = eventVal?.orderItem || [];
            filteringData.map((item) => {
                const selMaterial = _.filter(state.materialInfoList, { materialId: item.materialId });
                const req = {
                    materialTypeId: item.materialTypeId,
                    materialCode: item.materialCode,
                    materialId: item.materialId,
                    materialName: item.materialName,
                    qty: item.qty,
                    unit: item.unit,
                    price: item.price,
                    total: item.total,
                    tax: item.tax,
                    totalTax: item.totalTax,
                    itemDetails: item?.itemDetails || []
                }
                temp_data.push(req)
            })
            const footerData = footerCalculation(temp_data);
            temp_state.footerData = footerData;
            setState(temp_state)
            setTableRows(temp_data)
        }

    }

    const onSubmitForm = () => {
        let data = [...tableRows];
        let newItems = [];

        if (data.length == 0) {
            // showMessage('warning', "Fill all the Item details")
            Alert({ description: 'Fill all the Item details.', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });

            return false;
        }
        let material_id = data.map((item, index) => {
            return data[index]?.materialId || ''
        })
        let check = false
        let filter_Data = data.forEach((item, index) => {
            if (item.qty == item.itemDetails.length) {
                let newItem = { ...item };
                let id = item.itemDetails.map(detail => detail.itemDetailsId).join(',');
                newItem.itemDetailsIds = id
                newItem.price = newItem.price;
                newItem.totalTax = parseFloat(newItem.totalTax)
                newItem.total = newItem.total
                newItem.materialId = material_id[index]
                /* delete newItem.materialCode
                delete newItem.materialName
                */
                delete newItem.UnitPrice;
                delete newItem.materialType;
                delete newItem.unitName;
                delete newItem?.remark;
                delete newItem?.dimension;
                delete newItem?.itemWidth;
                delete newItem?.itemHeight;
                delete newItem?.thickness;
                delete newItem?.orederStatus;
                delete newItem.itemDetails;
                newItems.push(newItem);
            }
            else {
                check = true
                Alert({ description: 'Quantity and Material Count Must be Same.', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
                return false;
            }
        });
        if (validate(newItems)) {
            Alert({ description: 'Fill the all the Item details.', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            return false;
        } else if (check) {
            return false;
        }

        const requestData = {
            "orderStatus": "delivery",
            "customerId": state?.customerId || 0,
            "creditTerm": state.creditTerm,
            "billingAddress": state.billingAddress,
            "deliveryAddress": state.deliveryAddress,
            "salesPerson": state.salesPerson,
            "memo": state.memo,
            "status": 'open',
            "linkType": state?.linkType || '',
            "custPoNumber": state.custPoNumber,
            "dateOfBill": dateConversion(state.fromDate, 'YYYY-MM-DD'),
            "validTill": dateConversion(state.validDate, 'YYYY-MM-DD'),
            "productTax": state?.productTax || false,
            "priceIncludingGST": state?.priceIncludingGST || false,
            "reserveStock": state?.reserveStock || false,
            "amount": state?.footerData[0].value || 0,
            "totalTax": state?.footerData[1].value || 0,
            "totalAmount": state?.footerData[2].value || 0,
            "orderNumber": state?.orderNumber || '',
            "currencyId": state?.currencyId || '',
            "orderItem": newItems,
            // "invoicePayment": [
            //     {
            //         "paymentType": "",
            //         "paidAmt": "",
            //         "balanceAmt": ""
            //     }
            // ]
        }
        if (editData) {
            let data = state.deletedItems
            let deletingRow = data.map(ele => {
                ele.isDelete = true
                requestData.orderItem.push(ele)
            })
            setLoaderModule(true)
            dispatch(updateDelivery(requestData, selectedItem.orderId))
        } else if (deleteModule) {
            const deleteRequest = {
                isActive: "false"
            }
            setLoaderModule(true)
            // dispatch(updateDepartment(deleteRequest, selectedItem.departmentId))
        } else {
            setLoaderModule(true)
            dispatch(createDelivery(requestData))
        }
    };

    const onSubmitCustomerForm = () => {
        const customerRequest = {
            "customerName": state.name,
            "contactPerson": state?.contact,
            "phoneNo": state.contactNo,
            "email": state.email,
            "postalCode": state.postalCode,
            "address": state.CusbillingAddress,
            "customerDetails": [
                {
                    "shippingAddress": state.shippingAddress,
                    "creditLimit": state.creditLimit,
                    "creditTerm": state?.creditTerm[0]?.creditTermName || '' || "",
                    "customerUEN": state.customerUen,
                    "tax": state.tax[0].taxCode,
                    "currencyId": state.currencyId,
                    "priceGroup": state.pricegroup[0].custPriceGroupTitle
                }

            ]
        }
        setLoaderModule(true)
        dispatch(createCustomer(customerRequest))

    }

    const onExchangeItem = (data, index) => {
        let idx = _.findIndex(tableRows, (e) => {
            return e.orderItemId == data.orderItemId;
        });
        const iddx = state?.clickIndex
        const temp_state = [...tableRows];
        temp_state[iddx].itemDetails.splice(index, 1);
        setTableRows(temp_state);
    }

    const onCloseForm = () => {
        setShowForm(false);
    }

    const handleCustomerChange = (event, name, index,) => {
        // alert(JSON.stringify(event))
        let temp_state = "";
        const creditTermData = state?.creditList || []
        const custData = state?.customerList || object
        const currency = state?.currencyList || []
        const selectedCreditName = event?.creditTerm || []

        const list = currency.filter((item) => {
            return item.currencyId === event.currencyId
        })
        const filterCredit = creditTermData.filter((item) => {
            return item.creditTermName === selectedCreditName
        })
        temp_state = {
            ...state,
            [name]: event.label,
            contactPerson: event?.contactPerson || "",
            phoneNo: event?.phoneNo || "",
            address: event?.address || "",
            productTax: event?.productTax || false,
            creditTerm: event?.creditTerm,
            creditTermId: filterCredit[0]?.creditTermId || "",
            currency: event?.currencyId || "",
            currencyId: event?.currencyId || "",
            customerId: event.value
        }
        setState(temp_state)
    }

    const fetchData = (value, index, name) => {

        let apiResponse = state?.materialCodeList || [];
        if (value.length < 3) {
            let tempstate = [...tableRows];
            tempstate[index].filteredData = [];
            tempstate[index].materialCodeId = value;
            tempstate[index].materialName = '';
            tempstate[index].materialId = '';
            setTableRows(tempstate);
            return;
        }

        let filterObject = (apiResponse ? apiResponse : []).find(item => item.materialCode.toLowerCase() === value.toLowerCase());
        const filteredResults = (apiResponse ? apiResponse : []).filter(material =>
            material.materialCode.toLowerCase().includes(value.toLowerCase())
        );

        let tempstate = [...tableRows];
        tempstate[index].filteredData = filteredResults;
        tempstate[index].materialCodeId = value;
        tempstate[index].materialName = filterObject ? filterObject.materialName : '';
        tempstate[index].materialId = filterObject ? filterObject.materialId : '';
        tempstate[index].materialTypeId = filterObject ? filterObject.materialTypeId : '';

        setTableRows(tempstate);

        if (value.length >= filterObject?.materialCode.length) {
            let tempstate = [...tableRows];
            tempstate[index].filteredData = [];
            setTableRows(tempstate);
        }

    };

    const handleMaterialChange = async (event, name, index) => {
        let value = event.target.value;
        const req = {
            materialCode: value
        };
        const response = await getMaterialCode(req);
        if (response.getMaterialCodeSuccess) {
            setState({
                ...state,
                materialCodeList: response.getMaterialCodeList.data
            });
        } else if (response.getMaterialCodeFailure) {
            setState({
                ...state,
                materialCodeList: []
            });
            showMessage("warning", response.materialCodeErrorMessage?.message);
        }

        if (value == "") {
            let tempstate = [...tableRows];
            tempstate[index].filteredData = [];
            setTableRows(tempstate);
        } else {
            fetchData(value, index, name);
        }
    };

    const sameAsCustomer = (event, index, name) => {
        if (event.target.name === "customerAddress" || event.target.name === "billingAddress" || event.target.name === "sameBillingAddress") {
            let customerAddress = ""
            let key = event.target.name === "customerAddress" ? "billingAddress" : "deliveryAddress"
            let stateKey = event.target.name === "customerAddress" ? state?.address : state?.billingAddress
            const result = event.target.name === "customerAddress" && event.target.checked === true || event.target.name === "sameBillingAddress" && event.target.checked === true ? customerAddress = stateKey : customerAddress = ""
            setState({
                ...state,
                [event.target.name]: event.target.checked,
                [key]: customerAddress
            })
        } else {
            setState({
                ...state,
                [event.target.name]: event.target.checked,
            })
        }



    }

    const sameCusBillingAddress = (event, index, name) => {
        setState({
            ...state,
            [event.target.name]: event.target.checked,
            shippingAddress: event.target.checked ? state.CusbillingAddress : ''
        })
    }

    const resetCustomer = () => {
        setState({
            ...state,
            "name": "",
            "creditTerm": '',
            "pricegroup": '',
            "contact": '',
            "customerUen": "",
            "tax": "",
            "creditLimit": "",
            "currency": "",
            "email": "",
            "contactNo": "",
            "postalCode": "",
            "CusbillingAddress": false,
            "CusCheckBillingAddress": false,
            "shippingAddress": false,
        })
    }

    const addCustomer = () => {
        resetCustomer()
        setCustomerForm(true)
    }

    var itemCount = 0;
    const addRow = () => {
        const defaultNewOrderObject = {
            materialCode: '',
            materialType: '',
            materialName: '',
            qty: "0",
            unitName: '',
            UnitPrice: "0.00",
            total: "0.00",
            // taxCode: 0,
            totalTax: 0,
            /* itemDetailsSize: [{
                length: '',
                width: '',
                thickness: '',
            }], */
            itemDetails: [
            ]
        };
        itemCount++;
        setTableRows([...tableRows, defaultNewOrderObject]);

    };

    const statusChange = (event, index, extraData) => {
        let items = [...selectMaterial]
        let count = parseFloat(state.checkCount);
        const materialQty = parseFloat(tableRows[selectedIndex]?.qty || 0);

        if (count < materialQty) {
            items[index].status = event.target.checked;
            const addingCount = event.target.checked ? 1 : -1;
            count = count + addingCount;
        } else if (count === materialQty && !event.target.checked) {
            items[index].status = event.target.checked;
            const addingCount = -1;
            count = count + addingCount;
        } else {
            Alert({ title: "Not Allowed", description: 'Not allowed to select more than material quantity.', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
        }

        setState({ ...state, checkCount: count });
        setSelectMaterial(items)
    }

    const confirmForm = () => {
        const temp_state = [...selectMaterial];
        const parentIndex = selectedIndex;
        let filteredData = _.filter(temp_state, function (item) {
            return item.status === true;
        });
        if (tableRows[parentIndex].itemDetails?.length) {
            tableRows[parentIndex].itemDetails = [...tableRows[parentIndex].itemDetails, ...filteredData];
        }
        else
            tableRows[parentIndex].itemDetails = filteredData;
        setTableRows(tableRows);
        setShowForm(false)
    }

    const removeItem = (itemIndex) => {
        let temp_state = [...tableRows];
        let addd = tableRows.splice(itemIndex, 1);
        let remainingList = _.remove(temp_state, function (n, index) {
            return index != itemIndex
        });
        let updatedState = footerCalculation(remainingList)
        setTableRows(remainingList);
        let temp_arr = { ...state }
        setState({
            ...state,
            footerData: updatedState,
            deletedItems: [...temp_arr.deletedItems, ...addd]
        })
    }

    const footerCalculation = (tableRows) => {
        const tableData = tableRows.length > 0 ? tableRows : [];
        let lineItemTotal = 0, lineItemTax = 0;
        tableData.map((row) => {
            lineItemTotal += parseFloat(row?.total || 0);
            lineItemTax += parseFloat(row?.totalTax || 0);
        })
        const totalAfterTax = lineItemTotal + lineItemTax;
        const calculationTitle = [
            { label: 'Amount (before tax)', value: lineItemTotal > 0 ? lineItemTotal : 0 },
            { label: 'Tax :', value: lineItemTax > 0 ? lineItemTax : 0 },
            { label: 'Total Amount:', value: totalAfterTax > 0 ? totalAfterTax : 0 },
            { label: 'Local Currency:', value: 0 }
        ]
        return calculationTitle;
    }

    const handleCurrencyChange = (event, name) => {
        const selectedCurrencyId = event.target.value;
        const selectedCurrency = state.currencyList.find(currency => currency.currencyId == selectedCurrencyId);
        const symbol = selectedCurrency?.symbol || '';
        setCurrencySymbol(symbol);
        setState({
            ...state,
            [event.target.name]: event.target.value
        })
    };

    const onEditForm = (data, index) => {
        if (data.status === 'open') {
            let formData = createDeliveryForm
            if (formData[0].formBody[0].formField[0].fieldName == 'osId')
                formData[0].formBody[0].formField.splice(0, 2)
            setCreateOrder(formData)
            const temp_data = state?.customerList || object
            const filter_data = temp_data.filter(item => item.customerId == data.customerId)
            const item_list = (data?.orderItem || []).map((o) => {
                const selMaterial = _.filter(state.materialInfoList, { materialId: o.materialId });
                return { ...o, materialCode: selMaterial, materialId: o.materialTypeId }
            })
            console.log(data?.customerId);
            setState({
                ...state,
                "orderStatus": "delivery",
                "customerId": data?.customerId || "" || "",
                "customerName": data?.customerName || "",
                "deletedItems": [],
                "contactPerson": filter_data[0]?.contactPerson || '',
                "phoneNo": filter_data[0]?.phoneNo || '',
                "address": filter_data[0]?.address || '',
                "creditTerm": data.creditTerm,
                "billingAddress": data.billingAddress,
                "deliveryAddress": data.deliveryAddress,
                "customerAddress": filter_data[0]?.address || '' == data.billingAddress ? true : false,
                "sameBillingAddress": data.billingAddress == data.deliveryAddress ? true : false,
                "salesPerson": data.salesPerson,
                "memo": data.memo,
                "custPoNumber": data.custPoNumber,
                "fromDate": moment(new Date(), "YYYY-MM-DD"),
                "validDate": moment(new Date(), "YYYY-MM-DD"),
                "productTax": data?.productTax || false,
                "priceIncludingGST": data?.priceIncludingGST || false,
                "reserveStock": data?.reserveStock || false,
                "currencyId": data?.currencyId,
                footerData: [
                    { label: 'Amount (before tax)', value: data.amount },
                    { label: 'Tax :', value: data.totalTax },
                    { label: 'Total Amount:', value: data.totalAmount },
                    { label: 'Local Currency:', value: 0 }
                ]
            })
            editData = true;
            setSubIndex(index)
            setSelectedItem(data)
            setTableRows(data?.orderItem || [])
            setCreateModule(true)
        }

    }

    const onHandleRadio = async (event, index) => {
        let requestQuote = {}
        if (event.target.value == 1) {
            requestQuote.orderStatus = "sales"
            requestQuote.status = "open"
        }
        else {
            requestQuote.orderStatus = "invoice"
            requestQuote.status = "inprogess"
        }
        setTableRows([])
        setState({
            ...state,
            customerName: [],
            orderId: [],
            "contactPerson": "",
            "phoneNo": "",
            "address": "",
            "creditTerm": "",
            "salesPerson": "",
            "deletedItems": [],
            "custPoNumber": "",
            "memo": "",
            "billingAddress": "",
            "deliveryAddress": "",
            "createDate": "",
            "productTax": false,
            "priceIncludingGST": false,
            "reserveStock": false,
            "currency": "",
            "customerAddress": false,
            "sameBillingAddress": false,
            "fromDate": moment(new Date(), "YYYY-MM-DD"),
            "validDate": moment(new Date(), "YYYY-MM-DD"),
            footerData: [
                { label: 'Amount (before tax)', value: 0 },
                { label: 'Tax :', value: 0 },
                { label: 'Total Amount:', value: 0 },
                { label: 'Local Currency:', value: 0 }
            ],
            [event.target.name]: event.target.value,
            deliveryList: [],
            linkType: requestQuote?.orderStatus
        })
        dispatch(getSales(requestQuote))
    }

    const onAddItem = async (index) => {
        setTableRows(tableRows.map(({ materialType, ...row }) => row));
        const material_Id = tableRows[index].materialId
        const qty = parseFloat(tableRows[index]?.qty || 0)
        setSelectedIndex(index)
        let count = parseInt(tableRows[index]?.itemDetails?.length || 0);
        setState({ ...state, checkCount: count })
        if (qty != 0 && (tableRows[index].materialCode != "" || tableRows[index].materialCodeId != "")) {
            setLoaderModule(true)
            setSelectMaterial([])
            let list = await getShowMaterial(null, material_Id)
            const parentIndex = selectedIndex
            const parentRows = [...tableRows];
            let tempArr = list.getShowMaterialList
            const itemInfo = parentRows[parentIndex]?.itemDetails || [];

            const filter_data = _.filter(tempArr, (item, index) =>
                _.findIndex(itemInfo, (n) => n.itemDetailsId === item.itemDetailsId) === -1
            )

            setSelectMaterial(filter_data)
            setShowForm(true);
        }
        else {
            Alert({ description: 'Enter the material quantity (or) Select the Material.', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
        }
    }

    const validateForm = async () => {
        const validatefom = customerFormRef.current.validateForm();
    }

    const expandView = (index) => {
        const data = tableRows[index]?.itemDetails || [];

        return (
            <div style={{ marginTop: "-25px" }}>
                {/* <h3 style={{ fontSize: '20px', fontWeight: '500',marginBottom:"-30px" }} className="label-color">Item Details</h3> */}
                <CustomTable data={data} tableName="Item Details" columns={itemDetailsHeader} ></CustomTable>
            </div>
        );
    };

    const handleAddButtonClick = () => {
        setCreateModule(true);
    }

    const addButton = (
        <button onClick={() => handleAddButtonClick()} className="add-button">
            Add New Row
        </button>
    );

    const modelTitle = editData ? "Edit " : "Create ";
    const modelButtonLbl = editData ? "" : "Save";

    const handleClick = (buttonName) => {
        if (buttonName == 'Add') {
            onCreateForm();
        }
    };

    const tabs = [
        {
            id: "delivery",
            title: "Delivery",
            content: (
                <div>
                    <CustomTable buttonData={pageItems.create ? ['add'] : []} handleClick={handleClick} tableName={"Delivery"} columns={column} data={itemList} state={state} />
                </div>
            )
        },
        {
            id: "draft",
            title: "Drafts",
            content: (
                <div>
                    <CustomTable columns={column} data={[]} tableName={""} state={state} />
                </div>
            ),
        }
    ];


    const rows = [
        { label: "Order Number", value: "orderNumber" },
        { label: "Bill Number", value: "billNumber" },
        { label: "Customer", value: "customerName" },
        { label: "Address", value: "customerAddress" },
        { label: "Phone", value: "customerPhoneNo" },
        { label: "Email", value: "customerEmail" },
        { label: "Billing Address", value: "billingAddress" },
        { label: "Delivery Address", value: "deliveryAddress" },
        { label: "Salesperson", value: "salesPerson" },
        { label: "Date of Bill", value: "dateOfBill", isDate: true },
        { label: "Valid Till", value: "validTill", isDate: true },
    ]

    const itemFields = [
        { label: "Material Name", value: "materialName" },
        { label: "Quantity", value: "qty", prefix: "Qty: " },
        { label: "Unit", value: "unitName", prefix: "Unit: " },
        { label: "Price", value: "price", prefix: "Price: $", isCurrency: true },
        { label: "Total", value: "total", prefix: "Total: $", isCurrency: true },
        { label: "Tax", value: "totalTax", prefix: "Tax: $", isCurrency: true },
    ];
    const onViewForm = (data) => {
        setState({
            ...state,
            dataList: data
        })
        setDownloadPdf(true)
    }

    return (
        <div>
            {/* <SmallLoader value={loaderModule} /> */}
            <View isOpen={createModule} onClose={setCreateModule} size="fullscreen" savetitle={modelButtonLbl} onSubmit={validateForm} title={`${modelTitle} Delivery`}>
                <FormLayout ref={customerFormRef} defaultState={state} setDefaultState={setState} dynamicForm={createDeliveryForm} noOfColumn={3} onSubmit={onSubmitForm} onSearch={addCustomer} onChangeCallback={{ "handleCustomerChange": handleCustomerChange, "handleCurrencyChange": handleCurrencyChange, "handleOrder": handleOrder, "onHandleRadio": onHandleRadio }} iconPress={(event, index, state) => sameAsCustomer(event, index, state)} />
                <CollapseView iconPress={{ "onAddItem": onAddItem, "onDeletePress": removeItem }} calculationTitle={state.footerData} currencySymbol={currencySymbol} onChangeCallback={{ "handleMaterialChange": handleMaterialChange }} state={state} setState={setState} header={itemColumn} fieldList={formList} expandView={expandView} btnName={"Add Item"} tableRows={tableRows} setTableRows={setTableRows} addRow={addRow} />
            </View>
            <View isOpen={showForm} onClose={setShowForm} size="xl" title={`Select Product`} onSubmit={confirmForm} count={true} checkCount={state?.checkCount || 0} quatityCount={selectedIndex === false ? 0 : tableRows[selectedIndex]?.qty || 0} /* onClose={onCloseForm} */ btnName={"Confirm"}>
                <CustomTable columns={itemHeader} data={selectMaterial} tableName={""} state={state} />
            </View>
            <View isOpen={downloadPdf} onClose={setDownloadPdf} centered={true} size={'lg'} title="Download Pdf">
                <PdfPreview data={state?.dataList || []} totalSection={true} ItemKey={"orderItem"} title={"Delivery"} subTitle={"Items"} rows={rows} itemFields={itemFields} />
            </View>
            <View isOpen={customerForm} onClose={setCustomerForm} size="lg" title={`Create New Customer`} onSubmit={validateForm}>
                <FormLayout ref={customerFormRef} defaultState={state} setDefaultState={setState} dynamicForm={createCustomerForm} onSubmit={onSubmitCustomerForm} noOfColumn={2} iconPress={(event, index, state) => sameCusBillingAddress(event, index, state)} />
            </View>

            <SimpleCard>
                {/*  <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
                    <Tab eventKey="home" title="DELIVERY">
                        <CustomTable columns={column}  data={itemList} tableName={""} state={state} /></Tab>
                    <Tab eventKey="profile" title="DRAFTS">
                        <CustomTable columns={column} data={[]} tableName={""} state={state} />
                    </Tab>
                </Tabs> */}
                <MultiTabs tabs={tabs} /* handleTab={handleTabSelect} */ />

            </SimpleCard>
        </div>
    )
};

export default Delivery;
