import { Form, Button } from "react-bootstrap";

const Text = ({ info, onChangeCallback, index, errorState, checkErrors, handleChange, removeErrors, valueField }) => {
    const isValid = valueField[info.fieldName] && valueField[info.fieldName].length > 0;

    return (
        <div>
            <input
                id={info.fieldName}
                name={info.fieldName}
                style={{
                    fontSize: "14px",
                    padding: "5px",
                    borderWidth: '1px',
                    borderRadius: '4px',
                    border:'0.2px solid gray',
                    marginBottom:'8px',
                    outline:   'none', // Prevent the default black outline on focus
                }}
                placeholder={info.placeholder || `Enter ${info.label}`}
                type={info.type || "text"}
                onChange={(event) =>
                    onChangeCallback?.[info.onChange]
                        ? onChangeCallback[info.onChange](event, info.fieldName, index)
                        : handleChange(event)
                }
                disabled={info.disable === true}
                onFocus={removeErrors}
                value={valueField[info.fieldName] || ''}
            />
            {checkErrors && errorState[info.fieldName] && (
                <div className="text-danger mt-1 ml-2">
                    {info.errorMsg || "* required"}
                </div>
            )}
        </div>
    );
};

export default Text;
