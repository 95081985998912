import React from "react";
import jsPDF from "jspdf";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { dateConversion } from "app/utils/app-functions";
import { employeePath } from "app/utils/constant";
import { imagePath } from "app/utils/constant";

const ProfileDetails = ({ employeeDetailsList=[], employeePromotionList=[] }) => {
  // Ensure there's data to display
  const employeeData = employeeDetailsList.length > 0 ? employeeDetailsList[0] : null;
  const positionInfoData = employeeDetailsList.length > 0 ? employeeDetailsList[0].empPosition : null;
  const leaveBalanceInfo = employeeDetailsList.length > 0 ? employeeDetailsList[0].leaveBalanceInfo : null;


  if (!employeeData) {
    return <div>No profile data available</div>;
  }

  // Destructure employee data
  const {
    firstName,
    lastName,
    email,
    phoneNo,
    dateOfBirth,
    genderId,
    profilePicture,
    employeeToken,
    nationalityName,
    bloodGroupName,
    dateOfJoin,
    motherName,
    fatherName,
    motherTongueName,
    maritalStatus,
    emergencyContactName,
    emergencyContactNumber,
    age,
    shiftName,
    dateOfRelieving

  } = employeeData;
  console.log(employeeData)
  let gender = ''
  if(genderId == 1){
    gender = "Male"
  }else if(genderId == 2){
    gender = "Female"
  }
  else if(genderId == 3){
    gender = "Others"
  }
  else {
    gender = "Not Specified"
  }

    const profile = `${imagePath}${employeePath}${employeeData?.employeeToken || ""}/${encodeURI(employeeData?.profilePicture || "")}`;
  console.log(gender);
  
  const handleExportPDF = () => {
    const doc = new jsPDF();

    doc.setFont("helvetica", "normal");
    doc.setFontSize(12);

    doc.setFontSize(18);
    doc.setTextColor(0, 0, 0);
    doc.text("Profile Details", 20, 20);

    // Employee Name
    doc.setFontSize(14);
    doc.text(`Employee Name: ${firstName} ${lastName || ""}`, 20, 30);

    // Personal Information Section
    doc.setFontSize(16);
    doc.setTextColor(0, 102, 204); // Blue color for headings
    doc.text("Personal Information", 20, 45);
    doc.setTextColor(0, 0, 0); // Reset to black for text content

    const rowSpacing = 10;
    let currentYPosition = 55;

    doc.setFontSize(12);
    doc.text(`Email: ${email || "No email provided"}`, 20, currentYPosition);
    currentYPosition += rowSpacing;

    doc.text(`Phone No: ${phoneNo || "Not available"}`, 20, currentYPosition);
    currentYPosition += rowSpacing;

    doc.text(`Date of Birth: ${dateConversion(dateOfBirth, "DD-MMM-YYYY") || "Not provided"}`, 20, currentYPosition);
    currentYPosition += rowSpacing;

    doc.text(`Gender: ${gender}
`, 20, currentYPosition);
    currentYPosition += rowSpacing;

    doc.text(`Blood Group: ${bloodGroupName || "Not specified"}`, 20, currentYPosition);
    currentYPosition += rowSpacing;

    doc.text(`Nationality: ${nationalityName || "Not specified"}`, 20, currentYPosition);
    currentYPosition += rowSpacing;

    doc.text(`Date of Join: ${dateConversion(dateOfJoin, "DD-MMM-YYYY") || "Not specified"}`, 20, currentYPosition);
    currentYPosition += rowSpacing;

    doc.text(`Mother Name: ${motherName || "Not specified"}`, 20, currentYPosition);
    currentYPosition += rowSpacing;

    doc.text(`Father Name: ${fatherName || "Not specified"}`, 20, currentYPosition);
    currentYPosition += rowSpacing;

    doc.text(`Mother Tongue: ${motherTongueName || "Not specified"}`, 20, currentYPosition);
    currentYPosition += rowSpacing;

    doc.text(`Emergency Contact Name: ${emergencyContactName || "Not available"}`, 20, currentYPosition);
    currentYPosition += rowSpacing;

    doc.text(`Emergency Contact Number: ${emergencyContactNumber || "Not available"}`, 20, currentYPosition);
    currentYPosition += rowSpacing;

    doc.text(`Age: ${age || "Not specified"}`, 20, currentYPosition);
    currentYPosition += rowSpacing;

    doc.text(`Employee Token: ${employeeToken || "Not available"}`, 20, currentYPosition);
    currentYPosition += rowSpacing;

    doc.text(`Marital Status: ${maritalStatus || "Not specified"}`, 20, currentYPosition);
    currentYPosition += rowSpacing;

    // Leave Info Section
    doc.setFontSize(16);
    doc.setTextColor(0, 102, 204); // Blue color for headings
    doc.text("Leave Info (2024)", 20, currentYPosition);
    currentYPosition += rowSpacing;

    const leaveData = [
      { leaveName: "Casual", balanceLeave: 3, totalLeave: 3 },
      { leaveName: "Emergency", balanceLeave: 0.5, totalLeave: 0.5 },
      { leaveName: "Sick", balanceLeave: 1.5, totalLeave: 1.5 },
    ];

    leaveData.forEach((leave, index) => {
      doc.text(`${index + 1}. ${leave.leaveName}: Balance - ${leave.balanceLeave}, Total - ${leave.totalLeave}`, 20, currentYPosition);
      currentYPosition += rowSpacing;
    });

    // Save the PDF
    doc.save("profile-details.pdf");
  };

  return (
    <div>
     {/*  <div style={{ marginLeft: "80%" }}>
        <label htmlFor="" style={{ marginRight: "5px" }}>Download</label>
        <FaCloudDownloadAlt onClick={handleExportPDF} className="export-icon">
          Export to PDF
        </FaCloudDownloadAlt>
      </div> */}

      <div className="profile-container">
        <div className="profile-header">
          <img
            src={profile || "/default-profile-pic.png"}
            alt={`${firstName} ${lastName || ""}`}
            className="profile-pic"
          />
          <div className="profile-info">
            <h2>{`${firstName} ${lastName || ""}`}</h2>
            <p>{phoneNo || "No email provided"}</p>
          </div>
        </div>

        <div className="profile-details">
          <table className="details-table">
            <thead>
              <tr>
                <th colSpan="2" style={{ color: "white" }}><h6>Basic Info</h6></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>First Name:</td>
                <td>{firstName || "Not provided"}</td>
              </tr>
              <tr>
                <td>Last Name:</td>
                <td>{lastName || "Not provided"}</td>
              </tr>
              <tr>
                <td>Email:</td>
                <td>{email || "Not provided"}</td>
              </tr>
              <tr>
                <td>Phone No:</td>
                <td>{phoneNo || "Not provided"}</td>
              </tr>
              <tr>
                <td>Gender:</td>
                <td>{gender || "Not specified"}</td>
              </tr>
              <tr>
                <td>Date of Joining:</td>
                <td>{dateConversion(dateOfJoin, "DD-MMM-YYYY") || "Not provided"}</td>
              </tr>
              <tr>
                <td>Shift:</td>
                <td>{shiftName || "Not specified"}</td>
              </tr>
              <tr>
                <td>Date of Relieving:</td>
                <td>{dateConversion(dateOfRelieving, "DD-MMM-YYYY") || "Not provided"}</td>
              </tr>
            </tbody>
          </table>

          {/* Personal info*/}

          <table className="details-table">
            <thead>
              <tr>
                <th colSpan="2"><h6>Personal Information</h6></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Date of Birth:</td>
                <td>{dateConversion(dateOfBirth, "DD-MMM-YYYY") || "Not provided"}</td>
              </tr>
              <tr>
                <td>Nationality:</td>
                <td>{nationalityName || "Indian"}</td>
              </tr>
              <tr>
                <td>Marital Status:</td>
                <td>{maritalStatus || "Married"}</td>
              </tr>
              <tr>
                <td>Father Name:</td>
                <td>{fatherName || "Not specified"}</td>
              </tr>
              <tr>
                <td>Emergency Contact Person:</td>
                <td>{emergencyContactName || "Not specified"}</td>
              </tr>
              <tr>
                <td>Mother Tongue:</td>
                <td>{motherTongueName || "English"}</td>
              </tr>
              <tr>
                <td>Age:</td>
                <td>{age || "Not specified"}</td>
              </tr>
              <tr>
                <td>Blood Group:</td>
                <td>{bloodGroupName || "Not specified"}</td>
              </tr>
              <tr>
                <td>No of Children:</td>
                <td>{"---"}</td>
              </tr>
              <tr>
                <td>Mother Name:</td>
                <td>{motherName || "Not specified"}</td>
              </tr>
              <tr>
                <td>Emergency Contact Number:</td>
                <td>{emergencyContactNumber || "Not specified"}</td>
              </tr>
            </tbody>
          </table>





          {/* Leave Info Section */}
          <h6>Leave Information </h6>
          <table className="details-table">
            <thead>
              <tr>
                <th>#</th>
                <th>Leave Name</th>
                <th>Balance Leave</th>
                <th>Total Leave</th>
              </tr>
            </thead>
            <tbody>
              {leaveBalanceInfo.map((leave, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{leave.leaveTypeName}</td>
                  <td>{leave.leaveBalance}</td>
                  <td>{leave.totalNoOfLeaves}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <h6>Position Information </h6>
          <table className="details-table">
            <thead>
              <tr>
                <th>#</th>
                <th>Designation</th>
                <th>Department</th>
                <th>Experience</th>
              </tr>
            </thead>
            <tbody>
              {positionInfoData.map((position, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{position.designationName || ""}</td>
                  <td>{position.departmentName || ""}</td>
                  <td>{position.totalLeave || ""}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <h6>Salary Information </h6>
          <table className="details-table">
            <thead>
              <tr>
                <th>#</th>
                <th>Salary Amount</th>
                <th>Salary Type</th>
                <th>Gross Pay</th>
                <th>Net Pay</th>
                <th>Commission Pct</th>
                <th>Deduction</th>
              </tr>
            </thead>
            <tbody>
              {positionInfoData.map((position, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{position.salaryAmt || ""}</td>
                  <td>{position.salaryTypeName || ""}</td>
                  <td>{position.grossPay || ""}</td>
                  <td>{position.netPay || ""}</td>
                  <td>{position.commissionPct || ""}</td>
                  <td>{position.deduction || ""}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <h6>Promotion Information</h6>
          <table className="details-table">
            <thead>
              <tr>
                <th>#</th>
                <th>Promotion Date</th>
                <th>Department</th>
                <th>Designation</th>
                <th>Salary</th>
              </tr>
            </thead>
            <tbody>
              {employeePromotionList && employeePromotionList.length > 0 ? (
                employeePromotionList.map((promotion, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{dateConversion(promotion.promotionDate, "DD-MMM-YYYY") || "Not provided"}</td>
                    <td>{promotion.departmentName || "Not specified"}</td>
                    <td>{promotion.designationName || "Not specified"}</td>
                    <td>{promotion.salaryAmt || "Not specified"}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7" style={{ textAlign: "center" }}>
                    No promotion details available
                  </td>
                </tr>
              )}
            </tbody>
          </table>

        </div>
      </div>

      <style jsx>{`
        .profile-container {
          font-family: Arial, sans-serif;
          margin: 30px;
        }
      
        .profile-header {
          display: flex;
          align-items: center;
        }
      
        .profile-pic {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          margin-right: 20px;
          border: 2px solid #007bff;
        }
      
        .profile-info {
          font-size: 18px;
        }
      
        .details-table {
          width: 100%;
          margin-top: 20px;
          border-collapse: collapse;
        }
      
        
      
        .details-table td {
          padding: 10px;
          border: 1px solid #ddd;
          text-align: left;
          background-color: #f9f9f9; /* Light gray for rows */
        }
      
        .details-table tbody tr:nth-child(even) td {
          background-color: #eaf4fc; /* Alternating light blue for even rows */
        }
      
        .details-table tbody tr:hover td {
          background-color: #d4e8fd; /* Slightly darker blue on hover */
          transition: background-color 0.3s ease;
        }
      
        .export-icon {
          font-size: 20px;
          cursor: pointer;
          color: #007bff;
        }
      
        h6 {
          margin-top: 20px;
          color: #007bff; /* Matching the table header color */
        }
      `}</style>

    </div>
  );
};

export default ProfileDetails;
