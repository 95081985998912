
const creditTermForm = [
    {
        title: "Basic Information",
        formBody : [
            {
                formField: [
                    {
                        label: "Credit Term Name",
                        inputType: "text",
                        fieldName:"creditTerm",
                        placeholder:"Enter Credit Term name",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Description",
                        inputType: "text",
                        fieldName:"description",
                        placeholder:"Enter description",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },                                                      
                ]
            },
        ]
    }
];

export {
    creditTermForm
};