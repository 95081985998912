import { returnApiCallPromise } from './ApiContent';
import { department } from './APIContainer';
//GET
export function getDepartment(request) {
  const requestObj = request ? { params: request } : "";
  return (dispatch) => {
    returnApiCallPromise("GET", department, requestObj)
      .then(response => {
        dispatch({
          type: 'GET_DEPARTMENT_SUCCESS',
          payload: response.data
        })
      }).catch(error => {

        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'GET_DEPARTMENT_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

export function asyncGetDepartmentInfo(request) {
  const requestObj = request ? { params: request } : "";
  return  returnApiCallPromise("GET", department, requestObj)
      .then(response => {
        return {
          error: false,
          message:"Succsss",
          data: response?.data?.data || []
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        return {
          error: true,
          data:[],
          message:errorMessage
        }
      })
}


//POST
export function createDepartment(request) {

  return (dispatch) => {
    returnApiCallPromise("POST",department , request)
      .then(response => {
        if (response.data.error) {
          dispatch({
            type: 'CREATE_DEPARTMENT_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'CREATE_DEPARTMENT_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'CREATE_DEPARTMENT_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

export function updateDepartment(request, departmentId) {
  return (dispatch) => {
    returnApiCallPromise("PUT",department+"/"+departmentId, request)
      .then(response => {

        if (response.data.error) {
          dispatch({
            type: 'UPDATE_DEPARTMENT_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'UPDATE_DEPARTMENT_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'UPDATE_DEPARTMENT_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}