const materialTypeForm = [
    {
        title: "",
        formBody : [
            {
                title: "",
                formField: [
                    {
                        label: "Material Type Name",
                        inputType: "text",                        
                        placeholder: "Enter Material Type Name",
                        fieldName:"name",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },    
                    {
                        label: "Description",
                        inputType: "textarea",
                        placeholder: "Enter description",
                        fieldName:"description",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }                                     
                ]
            }            
        ]
    }
];



export {
    materialTypeForm
};