import { returnApiCallPromise } from './ApiContent';
import { priceGroup } from './APIContainer';

//GET
export function getPriceGroup(request) {
  const requestObj = request ? { params: request } : "";
  return (dispatch) => {
    returnApiCallPromise("GET", priceGroup, requestObj)
      .then(response => {
        dispatch({
          type: 'GET_PRICE_GROUP_SUCCESS',
          payload: response.data
        })
      }).catch(error => {
        
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'GET_PRICE_GROUP_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

export function getAsyncPriceGroup(request) {
  const requestObj = request ? { params: request } : "";
  return (
    returnApiCallPromise("GET", priceGroup, requestObj)
    .then(response => {
      const result = {
        getDataSuccess: true,
        data: response.data.data
      }
     
      return result;
    }).catch(error => {

      let errorMessage = error;
      if (error.response) {
        if (error.response.data.error) {
          errorMessage = error.response.data.message;
        }
      }

      const result = {
        getDataFailed: true,
        errorMessage: errorMessage
      }
     
      return result;
    })
  )
}
