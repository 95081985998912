import React, { useState, useEffect, useRef } from "react";
import { useLocation, Link } from "react-router-dom";
import { sideNavbar } from "../../../navigationItems"; // Your children data
import {
  AiOutlineApartment, AiOutlineHome, AiOutlineSetting, AiOutlineFileText,
  AiOutlineBell, AiOutlineQuestionCircle, AiOutlineComment, AiOutlineBook, AiOutlineUser
} from "react-icons/ai";
import { BsCash, BsPeople, BsClipboardCheck } from 'react-icons/bs';
import { FaQuestionCircle, FaBook, FaBookOpen, FaCalendarAlt, FaCheckCircle, FaListAlt, FaRegCalendarCheck } from 'react-icons/fa';
import { HiOutlineDocumentReport } from 'react-icons/hi';
import { MdInventory, MdVisibility, MdLogout, MdOutlineAnalytics, MdAdminPanelSettings, MdDataset } from 'react-icons/md';
import { IoSettings } from "react-icons/io5";
import { RiAdminLine } from "react-icons/ri";
import { PiStudentFill } from "react-icons/pi";
import { HomeOutlined, ReadOutlined, FileTextOutlined, BookOutlined, AppstoreAddOutlined, CalendarOutlined, TeamOutlined, CloudUploadOutlined, FileDoneOutlined, TableOutlined, UserOutlined, ScheduleOutlined, ContainerOutlined, ShopOutlined, TagOutlined, StockOutlined, TransactionOutlined, FileAddOutlined, FileSearchOutlined, ShoppingCartOutlined, CreditCardOutlined, UnorderedListOutlined, BoxPlotOutlined, ToolOutlined } from '@ant-design/icons';

import {
  SSidebar,
  SLogo,
  SLinkContainer,
  SLink,
  SLinkIcon,
  Tooltip,
  SHoverLabel,
  SDiv,
  CardContainer,
  CardHeader,
  MenuList,
  MenuItem,
  SubMenuList,
  SubMenuItem,
  SubmenuToggle,
  CardLink,
  SLink_Icon
} from "./styles";
import { IoChevronForwardSharp, IoChevronDownSharp } from "react-icons/io5";
import { useSidebar } from "./sidebarContext";
import { iconMap } from "./icon";

const Sidebar = () => {
  const { pathname } = useLocation();
  const { sidebarOpen, toggleSidebar } = useSidebar();
  const sidebarRef = useRef(null);
  const hoverCardRef = useRef(null);
  const pageData = window.localStorage.getItem("page")
  const sideNavbar = JSON.parse(pageData)

  const [hoveredLinkIndex, setHoveredLinkIndex] = useState(null);
  const [hoveredIconPosition, setHoveredIconPosition] = useState({ left: 0, top: 0 });
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [isCardVisible, setIsCardVisible] = useState(false);
  const [menuListHeight, setMenuListHeight] = useState(50);
  const [tooltipText, setTooltipText] = useState("");
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const itemHeight = 50; // Set height of each children item (in px)

  useEffect(() => {
    const menuItemsCount = () => {
      const section = sideNavbar[hoveredLinkIndex];
      if (section && section.children) {
        const menuLength = section.children.length;
        return menuLength * itemHeight;
      }
      return 0;
    };

    if (hoveredLinkIndex !== null) {
      const calculatedHeight = menuItemsCount();
      setTimeout(() => {
        setMenuListHeight(calculatedHeight > 300 ? "300px" : `${calculatedHeight}px`);
      }, 50); // Adjust the timeout value as needed
    }
  }, [hoveredLinkIndex, sideNavbar]);


  const handleMouseEnterLink = (index, event) => {
    if (!sidebarOpen) {
      const iconRect = event.currentTarget.getBoundingClientRect(); // Icon's position
      const sidebarRect = sidebarRef.current.getBoundingClientRect(); // Sidebar's position (optional)
      const topPosition = iconRect.top; // Distance of icon from the top of the viewport
      const hoverCardHeight = 500;
      setTooltipText(sideNavbar[index]?.name || "");
      console.log()
      setHoveredLinkIndex(index);
      setHoveredIconPosition({
        left: iconRect.left + 50, // Horizontal adjustment
        top: topPosition >= 400 ? topPosition - 250 : topPosition < 102 ? topPosition + 30 : topPosition === 352 ? topPosition - 70 : topPosition        // top:topPosition            


        // Fallback case
      });
    } else if (sidebarOpen) {
      setHoveredLinkIndex(index);
      setTooltipText(sideNavbar[index]?.name || "");
      const iconRect = event.currentTarget.getBoundingClientRect(); // Icon's position
      const sidebarRect = sidebarRef.current.getBoundingClientRect(); // Sidebar's position (optional)
      const topPosition = iconRect.top;
      setHoveredIconPosition({
        left: iconRect.left + 65,
        top: topPosition
        // top:topPosition            


        // Fallback case
      });

    }

  };





  const getMenuPosition = () => {
    if (menuListHeight === 300) {
      return { transform: 'translateY(-100px)' }; // Adjust the value path suit your design
    }
    return {};
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (hoverCardRef.current && !hoverCardRef.current.contains(event.target)) {
        setHoveredLinkIndex(null);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleMouseLeave = () => {
    // Directly hide tooltip when the mouse leaves the icon or the hover card
    setHoveredLinkIndex(null);
    setTooltipText(""); // Clear tooltip text
  };


  const toggleSubMenu = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
    setIsCardVisible(true);
  };

  const handlesidebar = () => {
    if (sidebarOpen === false) {
      toggleSidebar();
    }
  };

  useEffect(() => {
    handlesidebar();
  }, []);

  const subNavData = sideNavbar[hoveredLinkIndex]?.type === "dropDown" ? sideNavbar[hoveredLinkIndex]?.children : sideNavbar[hoveredLinkIndex];
  let subNavArray = [];

  if (typeof subNavData === 'object' && !Array.isArray(subNavData) && Object.keys(subNavData).length) {
    // If subNavData is an object, push it to the array
    subNavArray.push(subNavData);
  } else {
    subNavArray = subNavData;
  }

  /*   {sidebarOpen && (
      <Tooltip
        ref={hoverCardRef}
        className={hoveredLinkIndex !== null ? "visible" : ""}
        style={{ left: hoveredIconPosition.left, top: hoveredIconPosition.top }}
        onMouseEnter={() => setHoveredLinkIndex(hoveredLinkIndex)}
        onMouseLeave={handleMouseLeave}
      >
        {tooltipText}
      </Tooltip>
    )}
   */
  return (
    <SSidebar ref={sidebarRef} isOpen={sidebarOpen}>


      <SDiv>
        {sideNavbar.map((item, index) => (
          <SLinkContainer
            key={item.label}
            isActive={pathname === item.path || item.children?.some(sub => pathname === sub.path)}
          >
            {console.log(item)
            }            <SLink
              to={item.path}
              onMouseEnter={(e) => handleMouseEnterLink(index, e)}
            >
              <SLinkIcon
                isActive={
                  pathname === item.path ||
                  item.children?.some(sub => pathname === sub.path) ||
                  item.children?.some(sub =>
                    sub.children?.some(subChildren => pathname === subChildren.path)
                  )
                }
                onClick={handlesidebar}
              >
                {iconMap[item.icon]}
              </SLinkIcon>

            </SLink>
          </SLinkContainer>
        ))}



        {!sidebarOpen && hoveredLinkIndex !== null && (
          <SHoverLabel
            ref={hoverCardRef}
            style={{ left: hoveredIconPosition.left, top: hoveredIconPosition.top }}
            onMouseEnter={() => setHoveredLinkIndex(hoveredLinkIndex)}
            onMouseLeave={handleMouseLeave}
          >
            <CardContainer visible={isCardVisible}>
              <CardHeader>{sideNavbar[hoveredLinkIndex]?.name}</CardHeader>
              <MenuList style={{
                height: menuListHeight, transition: "height 0.3s ease-in-out", ...getMenuPosition()
              }}>
                {subNavArray?.map((children, menuIndex) => (
                  <React.Fragment key={menuIndex}>
                    <MenuItem onClick={() => toggleSubMenu(menuIndex)} menuLength={children.length}>
                      <CardLink
                        to={children.path}
                        isActive={pathname === children.path}
                      >
                        <span style={{ display: "flex", alignItems: "center" }}>
                          <SLink_Icon
                            isActive={pathname === children.path || children.children?.some(sub => pathname === sub.path)}
                          >
                            {iconMap[children.icon]}

                          </SLink_Icon>
                          {children.label}
                        </span>
                        {children.submenu && children.submenu.length > 0 && (
                          <SubmenuToggle open={expandedIndex === menuIndex}>
                            {expandedIndex === menuIndex ? <IoChevronDownSharp /> : <IoChevronForwardSharp />}
                          </SubmenuToggle>
                        )}
                      </CardLink>
                    </MenuItem>
                    {children.submenu && (
                      <SubMenuList visible={expandedIndex === menuIndex}>
                        {children.submenu.map((subMenuItem, subMenuIndex) => (
                          <SubMenuItem key={subMenuIndex} isActive={pathname === subMenuItem.path}>
                            <CardLink
                              to={subMenuItem.path}
                              isActive={pathname === subMenuItem.path}
                            >
                              {subMenuItem.label}
                            </CardLink>
                          </SubMenuItem>
                        ))}
                      </SubMenuList>
                    )}
                  </React.Fragment>
                ))}
              </MenuList>
            </CardContainer>
          </SHoverLabel>
        )}
      </SDiv>

    </SSidebar>
  );
};

export default Sidebar;
