
const feesEnrollmentForm = [
    {
        title: "Basic Information",
        formBody: [
            {
                formField: [
                    {
                        label: "Fees Name",
                        inputType: "text",
                        // options: "feesTypeList",
                        // uniqueKey: "feesTypeId",
                        // displayKey: "feesTypeName",
                        fieldName: "feesName",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Fees Amount",
                        inputType: "text",
                        placeholder: "Enter Fees",
                        disabled: true,
                        fieldName: "feesAmount",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    }
                ]
            },
            {
                formField: [                    
                    {
                        label: "Batch Name",
                        inputType: "select",
                        options: "batchList",
                        uniqueKey: "batchId",
                        displayKey: "batchName",
                        multiple: false,
                        fieldName: "batchId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Class - Section",
                        inputType: "select",
                        options: "enrollmentDetailsList",
                        uniqueKey: "enrollmentId",
                        displayKey: "classTitle",
                        subDisplayKey: "sectionTitle",
                        multiple: true,
                        fieldName: "enrollmentIds",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }
                ]
            },
            {
                formField: [
                    {
                        label: "Start Date",
                        inputType: "date",
                        fieldName: "startDate",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "End Date",
                        inputType: "date",
                        fieldName: "dueDate",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            }
        ]
    }
];

const feesForm = [
    {
        // title: "Basic Information",
        formBody: [
            {
                formField: [
                    {
                        // label: "Fees Type",
                        inputType: "select",
                        options: "feesTypeList",
                        uniqueKey: "feesTypeId",
                        displayKey: "feesTypeName",
                        fieldName: "feesTypeId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },
            {
                formField: [
                    {
                        // label: "Fees Amount",
                        placeholder: "Enter Fees Amount",
                        inputType: "text",
                        type: "number",
                        fieldName: "subFees",
                        onChange: "onSubFees",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },
            {
                formField: [
                    {
                        label: "",
                        inputType: "icon",
                        defaultValue: "",
                        onClick: "onRemove",
                        config: {
                            required: "",
                        },
                    }
                ]
            },
        ]
    }
];
export {
    feesEnrollmentForm, feesForm
};