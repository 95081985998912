
const sectionForm = [
    {
        title: "Basic Information",
        formBody : [
            {
                formField: [
                    {
                        label: "Section Name",
                        inputType: "text",
                        placeholder: "Enter Section name",
                        fieldName:"sectionTitle",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }
                                                                     
                ]
            }
        ]
    }
];

export {
    sectionForm
};