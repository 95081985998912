const initialState = {
    CreditTermList: []  
  }
  
  const CreditTermReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_CREDIT_TERM_SUCCESS": {             
        return {
          ...state,
          getCreditTermSuccess: true,
          getCreditTermList: action.payload.data,
        };
      }
      case "GET_CREDIT_TERM_FAILURE": {
        return {
          ...state,
          getCreditTermFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_CREDIT_TERM": {
        return {
          ...state,
          getCreditTermSuccess: false,
          getCreditTermFailure: false,
          getCreditTermList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_CREDIT_TERM_SUCCESS": {             
        return {
          ...state,
          createCreditTermSuccess: true,
          createCreditTermData: action.payload.data,
        };
      }
      case "CREATE_CREDIT_TERM_FAILURE": {
        return {
          ...state,
          createCreditTermFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_CREDIT_TERM": {
        return {
          ...state,
          createCreditTermSuccess: false,
          createCreditTermFailure: false,
          createCreditTermData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_CREDIT_TERM_SUCCESS": {             
        return {
          ...state,
          updateCreditTermSuccess: true,
          updateCreditTermData: action.payload.data,
        };
      }
      case "UPDATE_CREDIT_TERM_FAILURE": {
        return {
          ...state,
          updateCreditTermFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_CREDIT_TERM": {
        return {
          ...state,
          updateCreditTermSuccess: false,
          updateCreditTermFailure: false,
          updateCreditTermData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default CreditTermReducer
  
  