import { returnApiCallPromise } from './ApiContent';
import { leaveMeta } from './APIContainer';
//GET
export function getLeaveMeta(request) {
  const requestObj = request ? { params: request } : "";
  return (dispatch) => {
    returnApiCallPromise("GET", leaveMeta, requestObj)
      .then(response => {
        dispatch({
          type: 'GET_LEAVE_META_SUCCESS',
          payload: response.data
        })
      }).catch(error => {

        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'GET_LEAVE_META_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

export function asyncgetLeaveMeta(request) {
  const requestObj = request ? { params: request } : "";
  return (
    returnApiCallPromise("GET", leaveMeta, requestObj)
    .then(response => {
      return {
        error: false,
        message:"Success",
        data: response?.data?.data || []
      }
    }).catch(error => {
      let errorMessage = error;
      if (error.response) {
        if (error.response.data.error) {
          errorMessage = error.response.data.message;
        }
      }
      return {
        error: true,
        data:[],
        message:errorMessage
      }
    })
  )
}

//POST
export function createLeaveMeta(request) {

  return (dispatch) => {
    returnApiCallPromise("POST", leaveMeta , request)
      .then(response => {
        if (response.data.error) {
          dispatch({
            type: 'CREATE_LEAVE_META_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'CREATE_LEAVE_META_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'CREATE_LEAVE_META_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

export function updateLeaveMeta(request, leaveMetaId) {
  return (dispatch) => {
    returnApiCallPromise("PUT", leaveMeta+"/"+leaveMetaId, request)
      .then(response => {

        if (response.data.error) {
          dispatch({
            type: 'UPDATE_LEAVE_META_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'UPDATE_LEAVE_META_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'UPDATE_LEAVE_META_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}