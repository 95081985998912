export const statusForm = [
    {
        formBody: [
            {
                formField: [
                    {
                        label: "Upload Image",
                        inputType: "imageUploader",
                        onChange: "onUploadImage",
                        placeholder: "Enter Admission No",
                        fieldName: "profilePic",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    /*  {
                         label: "Admission No",
                         inputType: "text",
                         placeholder: "Enter Admission No",
                         fieldName: "admissionNo",
                         defaultValue: "",
                         config: {
                             required: "required",
                         },
                     }, */

                ]
            },
            /*   {
                  title: "true",
                  formField: [
                      {
                          label: "Registration Number",
                          inputType: "text",
                          placeholder: "Enter Registration Number",
                          fieldName:"registrationNumber",
                          defaultValue: "",
                          config: {
                              required: "required",
                          },
                      },
                  ]
  
              }, */
            {
                formField: [
                    {
                        label: "Emis Number",
                        inputType: "text",
                        placeholder: "Enter Emis Number",
                        fieldName: "emisNumber",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    },
                    {
                        label: "Identification",
                        inputType: "text",
                        placeholder: "Enter Identification",
                        fieldName: "identification",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }
                ]

            },
            {
                formField: [
                    {
                        label: "Date Of Join",
                        inputType: "date",
                        fieldName: "dateOfJoin",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "School Transport",
                        inputType: "checkboxOld",
                        placeholder: "Enter School Transport",
                        fieldName: "schoolTransport",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    }
                ]

            },
            {
                formField: [



                ]

            },
        ]
    },
    {
        formBody: [
            {
                formField: [
                    {
                        label: "Batch",
                        inputType: "select",
                        options: "batchList",
                        uniqueKey: "batchId",
                        displayKey: "batchStart",
                        subDisplayKey: "batchEnd",
                        multiple: false,
                        fieldName: "batchId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },

                ]
            },
            {
                formField: [
                    {
                        label: "Class",
                        inputType: "select",
                        options: "classList",
                        uniqueKey: "classId",
                        displayKey: "classTitle",
                        multiple: false,
                        fieldName: "classId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },

                ]
            },
            {
                formField: [
                    {
                        label: "Section",
                        inputType: "select",
                        options: "sectionList",
                        uniqueKey: "sectionId",
                        displayKey: "sectionTitle",
                        multiple: false,
                        fieldName: "sectionId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },
        ],
    },

];
export const feesRecords = [
    {
        title: "Basic Information",
        formBody: [
            {
                formField: [
                    {
                        label: "Batch Name",
                        inputType: "select",
                        options: "list",
                        uniqueKey: "examStatusId",
                        displayKey: "examStatus",
                        multiple: false,
                        fieldName: "examStatusId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },






                ]
            },
            {
                formField: [


                    {
                        label: "Class Section",
                        inputType: "select",
                        options: "list",
                        uniqueKey: "examStatusId",
                        displayKey: "examStatus",
                        multiple: false,
                        fieldName: "examStatusId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },


                ]
            },


        ]
    }
]

export const studentBasicInfoForm = [
    {
        title: "Basic Information",
        formBody: [
            {
                formField: [
                    {
                        label: "First Name",
                        inputType: "text",
                        placeholder: "Enter First Name",
                        fieldName: "firstName",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Middle Name",
                        inputType: "text",
                        placeholder: "Enter Middle Name",
                        fieldName: "middleName",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    },
                    {
                        label: "Last Name",
                        inputType: "text",
                        placeholder: "Enter Last Name",
                        fieldName: "lastName",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "D.O.B",
                        inputType: "date",
                        fieldName: "dob",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Gender",
                        inputType: "select",
                        options: "genderList",
                        uniqueKey: "genderId",
                        displayKey: "genderName",
                        multiple: false,
                        fieldName: "genderId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },
            {
                formField: [
                    {
                        label: "Email",
                        inputType: "text",
                        fieldType: "email",
                        placeholder: "Enter Email",
                        fieldName: "email",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Phone No",
                        // inputType: "text",
                        inputType: "phonenumber",
                        type: "number",
                        errorMsg: "* Invalid Phone Number",
                        fieldName: "phone",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Blood Group",
                        inputType: "select",
                        options: "bloodGroupList",
                        uniqueKey: "bloodGroupId",
                        displayKey: "bloodGroupName",
                        multiple: false,
                        fieldName: "bloodGroupId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Mother Tongue",
                        inputType: "select",
                        options: "motherTongueList",
                        uniqueKey: "motherTongueId",
                        displayKey: "motherTongueName",
                        multiple: false,
                        fieldName: "motherTongueId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },

                ]
            },
            {
                formField: [
                    {
                        label: "Nationality",
                        inputType: "select",
                        options: "nationalityList",
                        onChange: "",
                        uniqueKey: "nationalityId",
                        displayKey: "nationalityName",
                        multiple: false,
                        fieldName: "nationalityId",
                        defaultValue: "Indian",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Religion",
                        inputType: "select",
                        options: "religionList",
                        uniqueKey: "religionId",
                        displayKey: "religionName",
                        multiple: false,
                        fieldName: "religionId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Caste",
                        inputType: "select",
                        options: "casteList",
                        uniqueKey: "casteId",
                        displayKey: "casteName",
                        // multiple: false,
                        fieldName: "casteId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Sub Caste",
                        inputType: "select",
                        options: "subCasteList",
                        uniqueKey: "subCasteId",
                        displayKey: "subCasteName",
                        multiple: false,
                        fieldName: "subCasteId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },

                ]
            },
            {
                formField: [
                    {
                        label: "Address",
                        inputType: "text",
                        placeholder: "Enter Address",
                        fieldName: "address",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Postal Code",
                        inputType: "text",
                        type: "number",
                        placeholder: "Enter Postal Code",
                        fieldName: "postalCode",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "State",
                        inputType: "select",
                        options: "stateList",
                        uniqueKey: "stateId",
                        displayKey: "stateName",
                        multiple: false,
                        fieldName: "stateId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Country",
                        inputType: "select",
                        options: "countryList",
                        uniqueKey: "countryId",
                        displayKey: "countryName",
                        multiple: false,
                        fieldName: "countryId",
                        defaultValue: "India",
                        config: {
                            required: "required",
                        },
                    }
                ]
            },
            {
                formField: [




                ]
            },
            {
                formField: [



                ]
            },

            {
                formField: [


                ]
            },
            {
                formField: [


                ]
            },
        ]
    }
];

export const studentParentForm = [
    {
        title: "Basic Information",
        formBody: [
            {
                formField: [
                    {
                        label: "Father Name",
                        inputType: "text",
                        placeholder: "Enter Father Name",
                        fieldName: "fatherName",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        description: "Illiterate",
                        inputType: "checkbox",
                        fieldName: "fatherIlliterate",
                        onChange: "onHandleCheckBox",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    },
                    {
                        label: "Father Qualification",
                        inputType: "select",
                        options: "qualificationList",
                        uniqueKey: "qualificationId",
                        displayKey: "qualificationName",
                        multiple: false,
                        disabled: false,
                        fieldName: "fatherQualificationId",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    },
                    {
                        label: "Father Occupation",
                        inputType: "text",
                        placeholder: "Enter Father Occupation",
                        fieldName: "fatherOccupation",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Annual Income",
                        inputType: "text",
                        type: "number",
                        placeholder: "Enter Annual Income",
                        fieldName: "annualIncome",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },

                ]
            },
            {
                formField: [
                    {
                        label: "Mother Name",
                        inputType: "text",
                        placeholder: "Enter Mother Name",
                        fieldName: "motherName",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        description: "Illiterate",
                        inputType: "checkbox",
                        fieldName: "motherIlliterate",
                        onChange: "onHandleCheckBox",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    },
                    {
                        label: "Mother Qualification",
                        inputType: "select",
                        options: "qualificationList",
                        uniqueKey: "qualificationId",
                        displayKey: "qualificationName",
                        multiple: false,
                        disabled: false,
                        fieldName: "motherQualificationId",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    },
                    {
                        label: "Mother Occupation",
                        inputType: "text",
                        placeholder: "Enter Mother Occupation",
                        fieldName: "motherOccupation",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },

                ]
            },
        ]
    }
];
export const siblingsForm = [
    {
        title: "Basic Information",
        formBody: [
           

            {
                formField: [

                    {
                        label: "Number of elders",
                        inputType: "text",
                        fieldName: "noOfElders",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },




                ]
            },
            {
                formField: [

                    {
                        label: "Number of younger",
                        inputType: "text",
                        fieldName: "noOfYoungers",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },



                ]
            },
           /*  {
                formField: [

                    {
                        description: "Siblings",
                        inputType: "checkbox",
                        onChange: "onHandleSiblings",
                        placeholder: "Enter School Transport",
                        fieldName: "siblingData",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    },



                ]
            }, */
           

        ]
    }
];
export const siblingsDetailsForm = [
    {
        title: "Basic Information",
        formBody: [
            {
                formField: [

                    {
                        // label: "Mother Occupation",
                        inputType: "text",
                        placeholder: "Sibling Class",
                        fieldName: "class",
                        disabled: true,
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },




                ]
            },

            {
                formField: [


                    {
                        // label: "Mother Occupation",
                        inputType: "text",
                        placeholder: "Sibling Name",
                        fieldName: "studentName",
                        disabled: true,

                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },



                ]
            },

            {
                formField: [

                    {
                        label: "",
                        inputType: "icon-table",
                        index: 'outline-clock',
                        fieldName: "outline",
                        onClick: "onAddStudents",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    },


                ]
            },
            {
                formField: [


                    {
                        label: "",
                        inputType: "icon",
                        defaultValue: "",
                        onClick: "onRemove",
                        fieldName: "jobHistoryRemove",
                        config: {
                            required: "required",
                        },
                    }


                ]
            },

        ]
    }
];
export const approvalForm = [
    {
        title: "Basic Information",
        formBody: [
            {
                formField: [
                    {
                        label: "Status",
                        inputType: "select",
                        options: "studentStatusList",

                        uniqueKey: "admissionStatusId",
                        displayKey: "admissionStatusName",
                        multiple: false,
                        fieldName: "admissionStatusId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },
        ]
    }
];


export const homeWorkForm = [
    {
        title: "Basic Information",
        formBody: [
            {
                formField: [
                    {
                        label: "Batch Name",
                        inputType: "select",
                        options: "batchList",
                        uniqueKey: "batchId",
                        onChange: "onSelectBatch",
                        displayKey: "batchName",
                        fieldName: "batch_Id",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },




            {
                formField: [
                    {
                        label: "Class -Section",
                        inputType: "select",
                        options: "enrollmentDetailsList",
                        uniqueKey: "enrollmentId",
                        displayKey: "classTitle",
                        onChange: "onSelectClass",
                        subDisplayKey: "sectionTitle",
                        multiple: true,
                        fieldName: "enrollment_Id",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },



        ]
    }
];
