import * as yup from "yup";
import { Formik } from "formik";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import DateTime from "react-datetime";
import { Form, Button } from "react-bootstrap";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import FormLayout from "app/views/formlayout";
import { employeeLeaveForm, assignStatusForm, employeeLeaveEditForm } from "./formData";
import { MdEdit, MdPending, MdViewModule } from "react-icons/md";
// import { getLeaveType } from "app/api/LeaveTypeApi";
import { getLeaveStatus } from "app/api/LeaveStatusApi";
import swal from "sweetalert2";
import _ from "lodash";
import formRender from "app/components/render/formcomponent";
import { getStudentLeave, updateStudentLeave } from "app/api/StudentLeaveApi"
import moment from 'moment'
import { dateConversion, isBeforeDate, showMessage, timeConversion } from "app/utils/app-functions";
import View from "app/components/atoms/modalView/Modalview";
import SimpleCard from "app/components/atoms/simple-card/SimpleCard";
import CustomTable from "app/components/atoms/custom-table/CustomTable";
import Alert from "app/components/atoms/alert";
import { pagePermission } from "app/utils/app-functions";

const ExcelJS = require('exceljs');
let editData = false;

const StudentLeave = ({ navigation }) => {

    const dispatch = useDispatch();
    const customerFormRef = useRef();

    const getStudentLeaveSuccess = useSelector((state) => state.studentLeaveReducer.getStudentLeaveSuccess);
    const getStudentLeaveList = useSelector((state) => state.studentLeaveReducer.getStudentLeaveList);
    const getStudentLeaveFailure = useSelector((state) => state.studentLeaveReducer.getStudentLeaveFailure);


    const updateStudentLeaveSuccess = useSelector((state) => state.studentLeaveReducer.updateStudentLeaveSuccess);
    const updateStudentLeaveData = useSelector((state) => state.studentLeaveReducer.updateStudentLeaveData);
    const updateStudentLeaveFailure = useSelector((state) => state.studentLeaveReducer.updateStudentLeaveFailure);


    const getLeaveStatusSuccess = useSelector((state) => state.leaveStatusReducer.getLeaveStatusSuccess);
    const getLeaveStatusList = useSelector((state) => state.leaveStatusReducer.getLeaveStatusList);
    const getLeaveStatusFailure = useSelector((state) => state.leaveStatusReducer.getLeaveStatusFailure);
    const leaveStatusErrorMessage = useSelector((state) => state.leaveStatusReducer.errorMessage);


    const studentLeaveErrorMessage = useSelector((state) => state.studentLeaveReducer.errorMessage);
    const [pageItems, setpageItems] = useState({
        "view": false,
        "create": true,
        "update": false,
        "delete": false
    }
    );

    useEffect(() => {
        let data = pagePermission(pageItems, "Student", "Student Leave")
        setpageItems(data)

    }, [navigation]);



    const column = [
        {
            "dataField": "studentId",
            "text": "Student Name",
            "sort": true,
            formatter: (cellContent, row, index) => (
                row.firstName + " " + row.lastName
            )
        },
        {
            "dataField": "requestedName",
            "text": "Requested to",
            "sort": true,
            /*  formatter: (cellContent, row, index) => (
                 row.empFirstName + " " + row.empLastName
             ) */
        },
        {
            "dataField": "acceptedName",
            "text": "Accepted By",
            "sort": true,
        },
        // {
        //     "dataField": "leaveStatusName",
        //     "text": "Leave Status Name",
        //     "sort": true,
        // },
       
        {
            "dataField": "startDate",
            "text": "Leave Date",
            "sort": true,
            formatter: (cellContent, row, index) => (
                dateConversion(row.startDate, "DD-MMM-YYYY") + "  To  " + dateConversion(row.endDate, "DD-MMM-YYYY")
            )
        },
        {
            "dataField": "updatedOn",
            "text": "Request Date",
            "sort": true,
            formatter: (cellContent, row, index) => (
                dateConversion(row.updatedOn, "DD-MMM-YYYY")
            )
        },
        {
            dataField: "leaveStatusName",
            text: "Status",
            sort: true,
            formatter: (cellContent, row, index) => {
                let status = ''
                if (cellContent === 'Approve') {
                    status = 'Approved';
                } else if (cellContent === 'Decline') {
                    status = 'Declined';
                } else if (cellContent === 'Pending') {
                    status = 'Pending';
                }
                const conInfo = { inputType: "checkInStatus", statusName: status }
                return formRender(null, null, conInfo, null);
            }
        },
        /* {
            text: "Action",
            sort: false,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => (
                <div className="icon-container">
                    <MdPending
                        className="text-warning cursor-pointer"
                        size={18}
                        onClick={() => onProcessForm(row, index)}>
                    </MdPending>
                </div>
            )
        }, */
    ]

    if (pageItems.process) {
        column.push({
            text: "Action",
            sort: false,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => (
                <div className="icon-container">
                    <MdPending
                        className="text-warning cursor-pointer"
                        size={18}
                        onClick={() => onProcessForm(row, index)}>
                    </MdPending>
                </div>
            )
        },);
    }

    const [state, setState] = useState({

    });

    const [createModule, setCreateModule] = useState(false);
    const [loaderModule, setLoaderModule] = useState(false);
    const [editModule, setEditModule] = useState(false);
    const [viewModule, setViewModule] = useState(false);
    const [deleteModule, setDeleteModule] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [assignModule, setAssignModule] = useState(false);
    const [selectedItem, setSelectedItem] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);
    const [balanceLeave, setBalanceLeave] = useState([])

    useEffect(() => {
        setLoaderModule(true)

        // const request = {
        //     empCode: 'ATIXI-029'
        // }

        let request = {
        }
        if (global.roleId == 5) {
            request.empCode = global.UserInfo?.userCode || '';
        }
        dispatch(getStudentLeave(request))
        dispatch(getLeaveStatus())


    }, [navigation]);

    useEffect(() => {
        if (getStudentLeaveSuccess) {
            setItemList(getStudentLeaveList)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_STUDENT_LEAVE" })
        } else if (getStudentLeaveFailure) {
            setItemList([])
            showMessage("warning", studentLeaveErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_STUDENT_LEAVE" })
        }
    }, [getStudentLeaveSuccess, getStudentLeaveFailure]);


    useEffect(() => {
        if (getLeaveStatusSuccess) {
            setState({
                ...state,
                leaveStatusList: getLeaveStatusList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_LEAVE_STATUS" })
        } else if (getLeaveStatusFailure) {
            setState({
                ...state,
                leaveStatusList: []
            })
            showMessage("warning", leaveStatusErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_LEAVE_STATUS" })
        }
    }, [getLeaveStatusSuccess, getLeaveStatusFailure]);



    useEffect(() => {
        if (updateStudentLeaveSuccess) {
            const request = {
                // empCode: 'ATIXI-029'

            }
            if (global.roleId == 5) {
                request.empCode = global.UserInfo?.userCode || '';
            }

            dispatch(getStudentLeave(request))
            updateTable(updateStudentLeaveData[0])
            resetState()
            dispatch({ type: "RESET_UPDATE_STUDENT_LEAVE" })
        } else if (updateStudentLeaveFailure) {
            setLoaderModule(false)
            showMessage("warning", studentLeaveErrorMessage?.message)
            dispatch({ type: "RESET_UPDATE_STUDENT_LEAVE" })
        }
    }, [updateStudentLeaveSuccess, updateStudentLeaveFailure]);

    const updateTable = (updatedItem) => {
        const temp_state = [...itemList];
        if (deleteModule || assignModule) {
            let remainingList = _.remove(temp_state ? temp_state : [], function (n) {
                return n.studentLeaveId != updatedItem.studentLeaveId;
            });
            showMessage("success", "Processed Successfully")
            Alert({ description: 'Processed Successfully', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });

            setItemList(remainingList)
        } else {
            temp_state[selectedIndex] = updatedItem;
            showMessage("success", "Updated Successfully")
            Alert({ description: 'Updated Successfully', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });

            setItemList(temp_state)
        }
        setLoaderModule(false)
        closeModule()
    }

    const closeModule = () => {
        editData = false;
        setCreateModule(false)
        setDeleteModule(false)
        setAssignModule(false)
        setViewModule(false)
    }

    const resetState = () => {
        setState({
            ...state,
            employeeId: "",
            leaveTypeId: "",
            requestedTo: "",
            leavesContent: '',
            leaveStatusId: "",
            startDate: "",
            endDate: "",
            description: "",
        })
    }

    const onCreateForm = () => {
        resetState()
        editData = false;
        setCreateModule(true)
    }

    const onProcessForm = (data, index) => {
        if (data?.leaveStatusId == 1) {
            setState({
                ...state,
                leaveStatusId: data.leaveStatusId,
            });
        } else {
            let status = data.leaveStatusId == 2 ? "Leave is Already Approved" : "Leave is Declined";
            showMessage("warning", status);
            Alert({ description: status, type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });

            return;
        }

        setSelectedItem(data);
        setSelectedIndex(index);
        setAssignModule(true);
    };

    const onSelectEndDate = (date) => {
        let start_Date = moment(dateConversion(state.startDate, "YYYY-MM-DD"));
        let end_Date = moment(dateConversion(date, "YYYY-MM-DD"));

        const temp_state = [...state.holidayList]
        var start = moment(start_Date)
        var end = moment(end_Date);
        var list = [];
        let daysList = []
        for (var current = start; current <= end; current.add(1, 'd')) {
            list.push(current.format("YYYY-MM-DD"))
            daysList.push(current.format("dddd"))
        }
        let holidaysCount = 0
        temp_state.forEach((item) => {
            list.forEach((ele) => {
                if (ele === item.holidayDate) {
                    holidaysCount += 1;
                }
                return holidaysCount
            })
        })


        // let weekOffDays = 0
        // let workingDaysList = (state?.workingDaysList || []).filter((itm) => { return itm.status === false })
        // daysList.forEach((ele) => {
        //     workingDaysList.forEach((itm) => {
        //         if (ele == itm.dayName) {
        //             weekOffDays = weekOffDays + 1;
        //         }
        //     })
        // })

        // const checkStartDate = state.startDate ? true : false
        // if (checkStartDate === false) {
        //     showMessage("warning", "Please Select the Start Date")
        // } else if (isBeforeDate(end_Date, start_Date)) {
        //     showMessage("warning", "End date is larger than start date")
        // }
        // else {
        //     let totalDays = end_Date.diff(start_Date, 'days')
        //     let days = totalDays + 1;
        //     let calDays = days - holidaysCount - weekOffDays
        //     setState({
        //         ...state,
        //         noOfDays: calDays,
        //         leavesContent: `No. of leave day(s):${calDays}, No. of holiday(s):${holidaysCount},No.Of WeekOffs: ${weekOffDays}`,
        //         endDate: date
        //     })
        // }
    }
    let filterBalance = []

    const onEditForm = (data, extra) => {
        let start_Date = moment(dateConversion(data.startDate, "YYYY-MM-DD"));
        let end_Date = moment(dateConversion(data.endDate, "YYYY-MM-DD"));
        let totalDays = end_Date.diff(start_Date, 'days')
        const temp_state = [...state.employeeList]
        const selectedEmpId = data.employeeId;
        const employeeFilter = _.filter(temp_state, ((o) => {
            return o.employeeId === selectedEmpId
        }))

        setState({
            ...state,
            employeeId: employeeFilter,
            leaveTypeId: data?.leaveTypeId || "",
            leaveStatusId: data?.leaveStatusId || "",
            startDate: new Date(data.startDate),
            endDate: new Date(data.endDate),
            description: data.description,
            noOfDays: data.noOfDays,

        })

        editData = true
        // setSelectedIndex(index)
        setSelectedItem(data)
        setViewModule(true)
    }

    const onDeleteItem = (data, index) => {
        editData = false;
        setSelectedIndex(index)
        setSelectedItem(data)
        setDeleteModule(true)
    }

    const validateForm = async () => {
        const validatefom = customerFormRef.current.validateForm();
    }

    let userInfo = window.localStorage.getItem("userInfo");
    let parsedData = JSON.parse(userInfo);

    const onSubmitForm = () => {

        const requestDate = {
            leaveStatusId: state?.leaveStatusId || "",
            description: state?.description || "",
            acceptedBy: selectedItem.requestedTo

        };

        const request = {
            // empCode: 'ATIXI-029'

        }
        if (requestDate?.leaveStatusId == 0 || requestDate?.leaveStatusId == null) {
            showMessage("warning", "Select Status")
            Alert({ description: 'Select Status', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });

            return false
        } else {
            if (requestDate?.leaveStatusId === 1) {
                showMessage("warning", "Leave status is already pending")
                Alert({ description: 'Leave status is already pending', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });

                return false
            } else {
                // if ( parsedData?.[0]?.roleId == 5) { }
                request.empCode = parsedData?.[0]?.userCode || '';
                setLoaderModule(true);
                dispatch(updateStudentLeave(requestDate, selectedItem.studentLeaveId));


            }
        }
    }

    const {
        employeeId,
        leaveTypeId,
        description,
        noOfDays,
    } = state;

    const exportExcel = () => {
        if (itemList.length == 0) {
            Alert({ description: 'No Data found to Export Excel', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            return false
        }
        const workbook = new ExcelJS.Workbook();
        const sheet = workbook.addWorksheet('My Sheet');
        sheet.properties.defaultRowHeight = 20;

        sheet.columns = [
            {
                header: "Student Name",
                key: "firstName",
                width: 30,
            },
            {
                header: "Requested To",
                key: "requestedName",
                width: 30,
            },
            {
                header: "Leave Status",
                key: "leaveStatusName",
                width: 30,
            },
            {
                header: "Start Date",
                key: "startDate",
                width: 30,
            },
            {
                header: "End Date",
                key: "endDate",
                width: 30,
            },

        ]

        itemList.map((item, index) => {
            console.log(JSON.stringify(item));
            sheet.addRow({
                firstName: item?.firstName + item?.lastName || "-",
                requestedName: item.requestedName ? item.requestedName : "-",
                leaveStatusName: item.leaveStatusName ? item.leaveStatusName : "-",
                endDate: item.endDate ? dateConversion(item.endDate, "YYYY-MM-DD") : "-",
                startDate: item.startDate ? dateConversion(item.startDate, "YYYY-MM-DD") : "-",

            })
            sheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
                row.eachCell(function (cell, colNumber) {
                    cell.font = {
                        name: 'Arial',
                        family: 2,
                        bold: false,
                        size: 10,
                    };
                    cell.alignment = {
                        vertical: 'middle', horizontal: 'center'
                    };
                    if (rowNumber <= 10) {
                        row.height = 20;
                        cell.font = {
                            bold: true,
                        };
                    }
                    if (rowNumber >= 10) {
                        for (var i = 1; i < 14; i++) {
                            if (rowNumber == 10) {
                                row.getCell(i).fill = {
                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'C7C7C7' }
                                };
                            }
                            row.getCell(i).border = {
                                top: { style: 'thin' },
                                left: { style: 'thin' },
                                bottom: { style: 'thin' },
                                right: { style: 'thin' }
                            };
                        }
                    }
                });
            });
        });

        workbook.xlsx.writeBuffer().then(data => {
            const blob = new Blob([data],
                {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheet.sheet",
                });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = 'Employee-Leave-Report.xlsx';
            anchor.click();
            window.URL.revokeObjectURL(url);
        })
    }

    const modelTitle = editData ? "Edit " : "Create ";
    const modelButtonLbl = editData ? "" : "Save";

    const handleClick = (buttonName) => {
        if (buttonName == 'Excel') {
            exportExcel();
        }
    };
    return (
        <div>
            <div style={{ marginTop: "-10px" }}>

                <View isOpen={createModule} size="md" savetitle={modelButtonLbl} onClose={setCreateModule} onSubmit={validateForm} title={`${modelTitle} Student Leave`}>
                    <FormLayout ref={customerFormRef} defaultState={state} onChangeCallback={{ "onSelectEndDate": onSelectEndDate, /* "resetState": resetFilledData */ }} setDefaultState={setState} dynamicForm={employeeLeaveForm} noOfColumn={1} /* onSubmit={onSubmitForm} */ />
                </View>

                <View isOpen={viewModule} size="md" savetitle={modelButtonLbl} onClose={setViewModule} onSubmit={validateForm} title={"Edit Student Leave"}>
                    <FormLayout ref={customerFormRef} defaultState={state} onChangeCallback={{ "onSelectEndDate": onSelectEndDate,/*  "resetState": resetFilledData */ }} setDefaultState={setState} dynamicForm={employeeLeaveEditForm} noOfColumn={1} /* onSubmit={onSubmitForm} */ />
                </View>

                <View centered={true} size={'sm'} isOpen={deleteModule} onClose={setDeleteModule} savetitle="Yes" /* onSubmit={onSubmitForm} */ title="Delete Student Leave">
                    <h6 className="text-capitalize mb-1">Are you sure ?</h6>
                </View>

                <View isOpen={assignModule} size="sm" savetitle={modelButtonLbl} onClose={setAssignModule} onSubmit={onSubmitForm} title={"Assign Student Leave"}>
                    <FormLayout ref={customerFormRef} defaultState={state} setDefaultState={setState} dynamicForm={assignStatusForm} noOfColumn={1} onSubmit={onSubmitForm} />
                </View>
                <div className="row">
                    {/*  <div className="col-lg-5 d-flex mb-2">
                   { pageItems.view ? <Button onClick={() => onSearch()}>Search</Button> : ''}&nbsp;&nbsp;
                    <Button onClick={() => onClear()}>Clear</Button>
                </div> */}
                    {/* <div className="col-lg-6"></div> */}

                </div>
                <SimpleCard /* onClick={onCreateForm} */>

                    <CustomTable buttonData={['excel']} columns={column} data={itemList} handleClick={handleClick} tableName={"Student Leave"} state={state} />
                </SimpleCard>
            </div>
        </div>
    )
};

export default StudentLeave;
