import React from "react";
import Sidebar from "../sidebar/sidebar";
import { SLayout, SMain } from "./styles";
import Submenu from "../sidebar/submenu";
import HeaderNavbar from "../header/Header";
import { useSidebar } from "../sidebar/sidebarContext";
import Logo from "../atoms/Logo";
const Layout = ({ children }) => {
    const { sidebarOpen } = useSidebar(); // Access sidebarOpen from context

    return (
        <div>
            <SLayout>
                <Sidebar />
                <Submenu />
                <HeaderNavbar />
            </SLayout>
            <Logo />
            <SMain isOpen={sidebarOpen}>{children}</SMain>
        </div>
    );
};

export default Layout;
