import React from 'react';
import { statusForm, feesRecords } from './formData';
import View from 'app/components/atoms/modalView/Modalview';
import CustomTable from 'app/components/atoms/custom-table/CustomTable';
import FormLayout from 'app/components/render/formlayout';
import SimpleCard from 'app/components/atoms/simple-card/SimpleCard';
import { getVisitor } from "app/api/VisitorApi"
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import swal from "sweetalert2";
import _ from "lodash";
import { showMessage, dateConversion } from "app/utils/app-functions";
import formRender from 'app/components/render/formcomponent';
import { useSidebar } from "app/components/sidebar/sidebarContext";
import { asyncGetEmployeeInfo } from 'app/api/EmployeeApi';

let editData = false;

const VisitorHistory = ({ navigation }) => {
    const dispatch = useDispatch();
    const customerFormRef = useRef();

    const getVisitorSuccess = useSelector((state) => state.visitorReducer.getVisitorSuccess);
    const getVisitorList = useSelector((state) => state.visitorReducer.getVisitorList);
    const getVisitorFailure = useSelector((state) => state.visitorReducer.getVisitorFailure);
    const visitorErrorMessage = useSelector((state) => state.visitorReducer.errorMessage);


    const [pageItems, setpageItems] = useState({});

    /*   useEffect(() => {
          let data = pagePermission(pageItems, "Masters", "VisitorLog")
          setpageItems(data)
  
      }, [navigation]); */

    const column = [
        {
            "dataField": "firstName",
            "text": "Visitor Name",
            "sort": true
        },
        {
            "dataField": "phoneNo",
            "text": "Phone Number ",
            "sort": false
        },
        {
            "dataField": "meetingDate",
            "text": "Meeting Date",
            "sort": false,
            "formatter": (cellContent, row, index) => {
                return dateConversion(cellContent);
            }
        },
        {
            "dataField": "WhomToMeet",
            "text": "Whom to Meet ",
            "sort": false,
            "formatter": (cellContent, row, index) => {
                let employeList = state?.employeeList || []
                let employeData = employeList.find((data, index) => (
                    row.whomToMeet === data.employeeId

                ))
                console.log(employeData)

                return employeData?.employeeName || "Not Specified";
            }
        },
        {
            dataField: "isActive",
            text: "Status",
            sort: true,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => {
                const conInfo = { inputType: "status", status: cellContent }
                return formRender(null, null, conInfo, null);
            }
        },
    ]


    const [state, setState] = useState({
        designationName: "",
        description: ""
    });

    const [createModule, setCreateModule] = useState(false);
    const [loaderModule, setLoaderModule] = useState(false);
    const [editModule, setEditModule] = useState(false);
    const [viewModule, setViewModule] = useState(false);
    const [deleteModule, setDeleteModule] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [selectedItem, setSelectedItem] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);
     const listFetching = async () => {
            const requestData = {
                isActive: "true",
            }
            const request = {
                isActive: "true",
                leaveStatusId: 1
            }
    
            let employeeList = await asyncGetEmployeeInfo(requestData)
    
    
            setState({
                ...state,
                employeeList: employeeList?.data || [],
    
    
            })
    
        }

    useEffect(() => {
        // setLoaderModule(true)
        const requestData = {
            logStatus: "history"
        };
        dispatch(getVisitor(requestData))
        listFetching()
    }, [navigation]);


    useEffect(() => {
        if (getVisitorSuccess) {
            setItemList(getVisitorList)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_VISITOR" })
        } else if (getVisitorFailure) {
            setItemList([])
            showMessage("warning", visitorErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_VISITOR" })
        }
    }, [getVisitorSuccess, getVisitorFailure]);


    const resetState = () => {
        setState({
            ...state,
            visitorLogName: "",
            description: ""
        })
    }



    const validateForm = async () => {
        const validatefom = customerFormRef.current.validateForm();
    }

    const modelTitle = editData ? "Edit " : "Create ";
    const modelButtonLbl = editData ? "" : "Save";
    const { sidebarOpen } = useSidebar();

    return (
        <div >
            <div style={sidebarOpen ? { marginLeft: "10px", marginTop: "-10px" } : { marginLeft: "10px", marginTop: "-50px" }}>
                <SimpleCard>

                    <CustomTable columns={column} data={itemList} tableName={"Visitor History"} state={state} />
                </SimpleCard>
            </div>
        </div>
    )
}

export default VisitorHistory;