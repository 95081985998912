import { returnApiCallPromise } from './ApiContent';
import { student,studentDetails } from './APIContainer';

//GET
export function getStudent(request) {
  const requestObj = request ? { params: request } : "";
  return (dispatch) => {
    returnApiCallPromise("GET", student, requestObj)
      .then(response => {
        dispatch({
          type: 'GET_STUDENT_SUCCESS',
          payload: response.data
        })
      }).catch(error => {
        
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'GET_STUDENT_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

export function getStudentDetails(request,studentId) {
  const requestObj = request ? { params: request } : "";
  return (dispatch) => {
    returnApiCallPromise("GET", studentDetails+"/"+studentId, requestObj)
      .then(response => {
        dispatch({
          type: 'GET_STUDENT_DETAILS_SUCCESS',
          payload: response.data
        })
      }).catch(error => {
        
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'GET_STUDENT_DETAILS_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

//POST
export function createStudent(request) {

  return (dispatch) => {
    returnApiCallPromise("POST", student, request)
      .then(response => {
        if (response.data.error) {
          dispatch({
            type: 'CREATE_STUDENT_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'CREATE_STUDENT_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'CREATE_STUDENT_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

export function updateStudent(request, studentId) {
  return (dispatch) => {
    returnApiCallPromise("PUT", student+"/"+studentId, request)
      .then(response => {

        if (response.data.error) {
          dispatch({
            type: 'UPDATE_STUDENT_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'UPDATE_STUDENT_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'UPDATE_STUDENT_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}