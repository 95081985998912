import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'antd';

const CustomCheckbox = ({ info, uniqueKey, isChecked, onChange, name, disabled, ...props }) => {
  return (
    <div
      style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
    >
      <Checkbox
        {...props}
        name={name}
        // className="text-no-wrap"
        disabled={info?.disabled || false}
        checked={isChecked}
        onChange={onChange}
      >
        {/* {info?.label || ''} */}
      </Checkbox>
      {info?.description && (
        <p
          id={`desc-${uniqueKey}`}
          className="checkbox-description"
          style={{ margin: 0 }}
        >
          {info?.description || ''}
        </p>
      )}
    </div>
  );
};

CustomCheckbox.propTypes = {
  info: PropTypes.shape({
    label: PropTypes.string.isRequired,
    description: PropTypes.string,
  }).isRequired,
  uniqueKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isChecked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  disabled: PropTypes.bool,
};

CustomCheckbox.defaultProps = {
  isChecked: false,
  disabled: false,
};

export default CustomCheckbox;