
const employeStatusForm = [
    {
        title: "Basic Information",
        formBody: [
            {
                // title: "Basic Information",
                formField: [
                    /* {
                        label: "Accepted By",
                        inputType: "text",
                        placeholder: "Enter acceptedBy",
                        fieldName: "acceptedBy",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }, */
                    /* {
                        label: "Leave Status",
                        inputType: "select",
                        options: "leaveStatusList",
                        uniqueKey: "leaveStatusId",
                        displayKey: "leaveStatusName",
                        multiple: false,
                        fieldName: "leaveStatusId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        }
                    }, */
                    {
                        label: "Employee",
                        inputType: "typedropdown",
                        options: "employeeList",
                        uniqueKey: "employeeId",
                        displayKey: "employeeCode",
                        subDisplayKey: "employeeName",
                        onChange: "handleEmployeeDetails",
                        fieldName: "employeeId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        }
                    },
                    {
                        label: "Leave Type",
                        inputType: "select",
                        options: "leaveTypeList",
                        uniqueKey: "leaveTypeId",
                        displayKey: "leaveTypeName",
                        multiple: false,
                        onChange: "resetState",
                        fieldName: "leaveTypeId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        }
                    },
                    {
                        fieldName: "balanceLeaveCount",
                        inputType: "label",
                    },
                    {
                        label: "Start Date",
                        inputType: "date",
                        fieldName: "startDate",
                        onChange: "resetState",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "End Date",
                        inputType: "date",
                        fieldName: "endDate",
                        onChange: "onSelectEndDate",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        fieldName: "leavesContent",
                        inputType: "label",
                    },
                    /* {
                        label: "Number Of Days",
                        inputType: "text",
                        disable:true,
                        fieldName: "noOfDays",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }, */
                    {
                        label: "Description",
                        inputType: "textarea",
                        fieldName: "description",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    }
                ]
            }
        ]
    }
];

const employeeStatusForm = [
    {
        title: "Basic Information",
        formBody: [
            {
                // title: "Basic Information",
                formField: [
                    {
                        label: "Employee Code",
                        inputType: "typedropdown",
                        icon: "MdSearch",
                        options: "employeeList",
                        subDisplayKey: "employeeName",
                        onChange: "handleEmployeeDetails",
                        uniqueKey: "employeeId",
                        displayKey: "employeeCode",
                        fieldName: "employeeId",
                        defaultValue: "",
                        config: {
                            required: "",
                        }
                    },

                ]
            }
        ]
    }
];


const employeeLeaveEditForm = [
    {
        title: "Basic Information",
        formBody: [
            {
                // title: "Basic Information",
                formField: [
                    /* {
                        label: "Accepted By",
                        inputType: "text",
                        placeholder: "Enter acceptedBy",
                        fieldName: "acceptedBy",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }, */
                    /* {
                        label: "Leave Status",
                        inputType: "select",
                        options: "leaveStatusList",
                        uniqueKey: "leaveStatusId",
                        displayKey: "leaveStatusName",
                        multiple: false,
                        fieldName: "leaveStatusId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        }
                    }, */
                    /* {
                        label: "Employee",
                        inputType: "typedropdown",
                        options: "employeeList",
                        uniqueKey: "employeeId",
                        displayKey: "firstName",
                        subDisplayKey:"lastName",
                        disable:true,
                        onChange:"resetState",
                        fieldName: "employeeId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        }
                    }, */
                    {
                        label: "Employee",
                        inputType: "typedropdown",
                        options: "employeeList",
                        uniqueKey: "employeeId",
                        displayKey: "employeeCode",
                        fieldName: "employeeId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        }
                    },
                    {
                        label: "Leave Type",
                        inputType: "select",
                        options: "leaveTypeList",
                        uniqueKey: "leaveTypeId",
                        displayKey: "leaveTypeName",
                        multiple: false,
                        onChange: "resetState",
                        fieldName: "leaveTypeId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        }
                    },
                    {
                        label: "Start Date",
                        inputType: "date",
                        fieldName: "startDate",
                        onChange: "resetState",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "End Date",
                        inputType: "date",
                        fieldName: "endDate",
                        onChange: "onSelectEndDate",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Description",
                        inputType: "textarea",
                        fieldName: "description",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    },
                    {
                        label: "Number Of Days",
                        inputType: "text",
                        disable: true,
                        fieldName: "noOfDays",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },

                ]
            }
        ]
    }
];
export {
    employeStatusForm,
    employeeStatusForm,
    employeeLeaveEditForm
};