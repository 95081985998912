import * as yup from "yup";
import { Formik } from "formik";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import DateTime from "react-datetime";
import { Form, Button } from "react-bootstrap";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import FormLayout from "app/views/formlayout";
import { studentListForm } from "./formData";
import { MdEdit, MdDelete } from "react-icons/md";
import swal from "sweetalert2";
import _ from "lodash";
import formRender from "app/utils/formcomponent";
import { getStudentList, getStudentClassList, getAsyncStudentClassList, createStudentList, updateStudentList } from "app/api/StudentListApi";
import { dateConversion, firstLetterCaptial, formatStr, showMessage, timeConversion } from "app/utils/app-functions";
import { getBatch } from "app/api/BatchApi";
import { getEnrollmentDetails } from "app/api/EnrollementDetailsApi";
import { getExamType } from "app/api/ExamTypeApi";
import FilterComponent from "app/components/FilterComponent";
import { getExam } from "app/api/ExamApi";
import { getExamListData } from "app/api/ExamApi";
import { getSubjectEnrollment } from "app/api/SubjectEnrollmentApi";
import CustomTable from "app/components/atoms/custom-table/CustomTable";
import SimpleCard from "app/components/atoms/simple-card/SimpleCard";
import { asyncGetBatchInfo } from "app/api/BatchApi";
import { useSidebar } from "app/components/sidebar/sidebarContext";
import Alert from "app/components/atoms/alert";
import { lightTheme } from "styles/theme";
const ExcelJS = require('exceljs');

let editData = false;
let columns = false

const StudentList = ({ navigation }) => {

    const dispatch = useDispatch();
    const customerFormRef = useRef();

    const getStudentListSuccess = useSelector((state) => state.studentListReducer.getStudentListSuccess);
    const getStudentListList = useSelector((state) => state.studentListReducer.getStudentListList);
    const getStudentListFailure = useSelector((state) => state.studentListReducer.getStudentListFailure);

    const getStudentClassListSuccess = useSelector((state) => state.studentListReducer.getStudentClassListSuccess);
    const getStudentClassListList = useSelector((state) => state.studentListReducer.getStudentClassListList);
    const getStudentClassListFailure = useSelector((state) => state.studentListReducer.getStudentClassListFailure);

    const getSubjectEnrollmentSuccess = useSelector((state) => state.subjectEnrollmentReducer.getSubjectEnrollmentSuccess);
    const getSubjectEnrollmentList = useSelector((state) => state.subjectEnrollmentReducer.getSubjectEnrollmentList);
    const getSubjectEnrollmentFailure = useSelector((state) => state.subjectEnrollmentReducer.getSubjectEnrollmentFailure);
    const subjectEnrollmentErrorMessage = useSelector((state) => state.subjectEnrollmentReducer.errorMessage);

    const getBatchSuccess = useSelector((state) => state.batchReducer.getBatchSuccess);
    const getBatchList = useSelector((state) => state.batchReducer.getBatchList);
    const getBatchFailure = useSelector((state) => state.batchReducer.getBatchFailure);
    const batchErrorMessage = useSelector((state) => state.batchReducer.errorMessage);

    const getEnrollmentDetailsSuccess = useSelector((state) => state.enrollmentDetailsReducer.getEnrollmentDetailsSuccess);
    const getEnrollmentDetailsList = useSelector((state) => state.enrollmentDetailsReducer.getEnrollmentDetailsList);
    const getEnrollmentDetailsFailure = useSelector((state) => state.enrollmentDetailsReducer.getEnrollmentDetailsFailure);
    const enrollmentDetailsErrorMessage = useSelector((state) => state.enrollmentDetailsReducer.errorMessage);

    const getExamTypeSuccess = useSelector((state) => state.examTypeReducer.getExamTypeSuccess);
    const getExamTypeList = useSelector((state) => state.examTypeReducer.getExamTypeList);
    const getExamTypeFailure = useSelector((state) => state.examTypeReducer.getExamTypeFailure);
    const examTypeErrorMessage = useSelector((state) => state.examTypeReducer.errorMessage);

    const getExamSuccess = useSelector((state) => state.examReducer.getExamSuccess);
    const getExamList = useSelector((state) => state.examReducer.getExamList);
    const getExamFailure = useSelector((state) => state.examReducer.getExamFailure);
    const examErrorMessage = useSelector((state) => state.examReducer.errorMessage);

    const getExamListSuccess = useSelector((state) => state.examReducer.getExamListSuccess);
    const getExamListList = useSelector((state) => state.examReducer.getExamListList);
    const getExamListFailure = useSelector((state) => state.examReducer.getExamListFailure);
    const examListErrorMessage = useSelector((state) => state.examReducer.errorMessage);

    const createStudentListSuccess = useSelector((state) => state.studentListReducer.createStudentListSuccess);
    const createStudentListData = useSelector((state) => state.studentListReducer.createStudentListData);
    const createStudentListFailure = useSelector((state) => state.studentListReducer.createStudentListFailure);

    const updateStudentListSuccess = useSelector((state) => state.studentListReducer.updateStudentListSuccess);
    const updateStudentListData = useSelector((state) => state.studentListReducer.updateStudentListData);
    const updateStudentListFailure = useSelector((state) => state.studentListReducer.updateStudentListFailure);

    const studentListErrorMessage = useSelector((state) => state.studentListReducer.errorMessage);
    const studentClassListErrorMessage = useSelector((state) => state.studentListReducer.errorMessage);


    const column = [
        /*  {
             "dataField": "studentId",
             "text": "Student Id",
             "sort": true
         }, */
        {
            "dataField": "studentName",
            "text": "Student Name",
            "sort": true
        },
        {
            "dataField": "class-section",
            "text": "Class - Section",
            "sort": false
        },
        {
            "dataField": "gender",
            "text": "Gender",
            "sort": false
        },
        {
            "dataField": "fatherName",
            "text": "Father Name",
            "sort": false
        },
    ]

    const [state, setState] = useState({
        subjectEnrollmentList: []
    });

    const [createModule, setCreateModule] = useState(false);
    const [loadingFinishModule, setLoadingFinishModule] = useState(false);
    const [loaderModule, setLoaderModule] = useState(false);
    const [editModule, setEditModule] = useState(false);
    const [viewModule, setViewModule] = useState(false);
    const [deleteModule, setDeleteModule] = useState(false);
    const [searchModule, setSearchModule] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [selectedItem, setSelectedItem] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);

    let userInfo = window.localStorage.getItem("userInfo");

    let parsedData = JSON.parse(userInfo);
    const listFetching = async () => {
        const requestData = {
            isActive: "true",
        }
        const req = {
        };
        const reqq = {
            examVariantId: 1
        };
        if (parsedData[0].roleId == 5) {
            req.empCode = parsedData[0]?.userCode || '';
        }

        let batchList = await asyncGetBatchInfo(requestData)
        let enrollData = await getAsyncStudentClassList(req);

        setState({
            ...state,
            // schoolList:schoolInfoList?.getSchoolInfoList || [],
            batchList: batchList?.data || [],
            studentClassList: enrollData?.data || [],


        })

    }

    useEffect(() => {
        setLoaderModule(true)
        const requestData = {
            isActive: "true"
        }
        const empData = {
            isActive: "true",
            empCode: global.UserInfo?.userCode || ''
        }
        // dispatch(getStudentClassList(empData))
        // dispatch(getBatch(requestData))
        listFetching()
    }, [navigation]);

    useEffect(() => {
        if (getStudentClassListSuccess) {
            setState({
                ...state,
                studentClassList: getStudentClassListList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_STUDENT_CLASS_LIST" })
        } else if (getStudentClassListFailure) {
            setState({
                ...state,
                studentClassList: []
            })
            showMessage("warning", studentClassListErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_STUDENT_CLASS_LIST" })
        }
    }, [getStudentClassListSuccess, getStudentClassListFailure]);

    useEffect(() => {
        if (getStudentListSuccess) {
            setItemList(getStudentListList)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_STUDENT_LIST" })
        } else if (getStudentListFailure) {
            setState({
                ...state,
                studentList: []
            })
            showMessage("warning", studentListErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_STUDENT_LIST" })
        }
    }, [getStudentListSuccess, getStudentListFailure]);


    useEffect(() => {
        if (getBatchSuccess) {
            setState({
                ...state,
                batchList: getBatchList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_BATCH" })
        } else if (getBatchFailure) {
            setState({
                ...state,
                batchList: []
            })
            showMessage("warning", batchErrorMessage?.message, "Batch")
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_BATCH" })
        }
    }, [getBatchSuccess, getBatchFailure]);


    const closeModule = () => {
        editData = false;
        setCreateModule(false)
        setDeleteModule(false)
    }

    const resetState = () => {
        setState({
            ...state,
            batchName: "",
            batchList: [],
            description: "",
            studentClassList: [],
            enrollmentId: '',
        })
    }

    const onCreateForm = () => {
        resetState()
        editData = false;
        setCreateModule(true)
    }

    const validateForm = async () => {
        const validatefom = customerFormRef.current.validateForm();
    }

    const onSearch = () => {
        if (state?.enrollmentId == null || state?.enrollmentId === 0 || state?.enrollmentId === "" || state?.enrollmentId === "0") {
            showMessage("warning", "Please Select Enrollment");
            setItemList([])
        } else if (state.batchId == null || state.batchId === 0 || state.batchId === "" || state.batchId === "0") {
            setItemList([])
            showMessage("warning", "Please Select Batch");
        }
        else {
            setSearchModule(true)
            const req = {
                "enrollmentId": state.enrollmentId,
                "batchId": state?.batchList?.batchId,
            };
            setLoaderModule(true)
            dispatch(getStudentList(req));
        }
    };

    const exportExcel = () => {
        if (itemList.length == 0) {
            Alert({ description: 'No Data found to Export Excel', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            return false
        }
        const workbook = new ExcelJS.Workbook();
        const sheet = workbook.addWorksheet('My Sheet');
        sheet.properties.defaultRowHeight = 20;

        sheet.columns = [
            {
                header: "Student Name",
                key: "studentName",
                width: 30,
            },
            {
                header: "Class Section",
                key: "class-section",
                width: 30,
            },

        ]

        itemList.map((item, index) => {
            sheet.addRow({
                studentName: item?.studentName || '',
                "class-section": item?.["class-section"] || "",
            })
            sheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
                row.eachCell(function (cell, colNumber) {
                    cell.font = {
                        name: 'Arial',
                        family: 2,
                        bold: false,
                        size: 10,
                    };
                    cell.alignment = {
                        vertical: 'middle', horizontal: 'center'
                    };
                    if (rowNumber <= 10) {
                        row.height = 20;
                        cell.font = {
                            bold: true,
                        };
                    }
                    if (rowNumber >= 10) {
                        for (var i = 1; i < 14; i++) {
                            if (rowNumber == 10) {
                                row.getCell(i).fill = {
                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'C7C7C7' }
                                };
                            }
                            row.getCell(i).border = {
                                top: { style: 'thin' },
                                left: { style: 'thin' },
                                bottom: { style: 'thin' },
                                right: { style: 'thin' }
                            };
                        }
                    }
                });
            });
        });

        workbook.xlsx.writeBuffer().then(data => {
            const blob = new Blob([data],
                {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheet.sheet",
                });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = 'Student-Report.xlsx';
            anchor.click();
            window.URL.revokeObjectURL(url);
        })
    }

    const modelTitle = editData ? "Edit " : "Create ";
    const modelButtonLbl = editData ? "" : "Save";

    const { sidebarOpen } = useSidebar();

    const handleClick = (buttonName) => {
        if (buttonName == 'Excel') {
            exportExcel()
        }
    };

    return (
        <div>
            {/* <div style={{ float: "right" }}>
            </div> */}
            <div style={sidebarOpen ? { marginLeft: "40px", marginTop: "50px", marginBottom: "-30px" } : { marginLeft: "90px", marginTop: "50px", marginBottom: "-50px" }}>
                <FormLayout defaultState={state} setDefaultState={setState} dynamicForm={studentListForm} noOfColumn={4} />
                <Button onClick={onSearch} className='mr-2' style={{ backgroundColor: lightTheme.primaryColor, border: "none", fontSize: "12px", marginTop: "-20px" }}>Search</Button>
                {/* <Button onClick={onClear} style={{ backgroundColor: "white", color: "gray", border: "1px solid gray", fontSize: "12px", marginTop: "-20px" }}>Clear</Button> */}
            </div>

            {searchModule && <SimpleCard>


                <CustomTable buttonData={['excel']} handleClick={handleClick} columns={column} data={itemList} tableName={"Student List"} state={state} />
            </SimpleCard>}
        </div>
    )
};

export default StudentList;
