import React, { useState } from "react";
import ImageCropper from "./ImageCropper/ImageCropper";
import noProfile from "app/pages/images/no_profile.png";

const AppImageCropper = (props) => {
  const { state,setState,close} = props;
  const [croppedImg, setCroppedImg] = useState(null);
  const [againCrop, setAgainCrop] = useState(false);

  const handleFileSelect = (event) => {
    let file = event.target.files[0]; 
    let src = URL.createObjectURL(file);
    setState({
      ...state,
      profilePicFileType: file,
      uploadData : true,
      isNew:true
    })
    setCroppedImg(src);
  };

  return (
    <div>
      {againCrop ? (
        <ImageCropper
          image={croppedImg}
          croppedImg={croppedImg}
          setCroppedImg={setCroppedImg}
          againCrop={againCrop}
          setAgainCrop={setAgainCrop}
          handleFileSelect={handleFileSelect}
          closeModule={close}
          state={state}
          setState={setState}
        />
      ) : (
        <ImageCropper
          image={croppedImg || noProfile}
          croppedImg={croppedImg}
          setCroppedImg={setCroppedImg}
          againCrop={againCrop}
          setAgainCrop={setAgainCrop}
          handleFileSelect={handleFileSelect}
          closeModule={close}
          state={state}
          setState={setState}
        />
      )}
    </div>
  );
};

export default AppImageCropper;
