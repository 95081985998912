import { returnApiCallPromise } from './ApiContent';
import { brand, category, material, materialInfo, materialType, project, projectMaterial, supplier,availableMaterial } from './APIContainer';

// Brand
export function getBrand(request) {
    const requestObj = request ? { params: request } : "";
    return (dispatch) => {
        returnApiCallPromise("GET", brand, requestObj)
            .then(response => {
                dispatch({
                    type: 'GET_BRAND_SUCCESS',
                    payload: response.data
                })
            }).catch(error => {

                let errorMessage = error;
                if (error.response) {
                    if (error.response.data.error) {
                        errorMessage = error.response.data.message;
                    }
                }
                dispatch({
                    type: 'GET_BRAND_FAILURE',
                    errorMessage: errorMessage
                })
            })
    }
}

export function createBrand(request) {

    return (dispatch) => {
        returnApiCallPromise("POST", brand, request)
            .then(response => {
                if (response.data.error) {
                    dispatch({
                        type: 'CREATE_BRAND_FAILURE',
                        errorMessage: response.data.message
                    })
                } else {
                    dispatch({
                        type: 'CREATE_BRAND_SUCCESS',
                        payload: response.data
                    })
                }
            }).catch(error => {
                let errorMessage = error;
                if (error.response) {
                    if (error.response.data.error) {
                        errorMessage = error.response.data.message;
                    }
                }
                dispatch({
                    type: 'CREATE_PROJECT_FAILURE',
                    errorMessage: errorMessage
                })
            })
    }
}

export function updateBrand(request, brandId) {
    return (dispatch) => {
        returnApiCallPromise("PUT", brand + "/" + brandId, request)
            .then(response => {

                if (response.data.error) {
                    dispatch({
                        type: 'UPDATE_BRAND_FAILURE',
                        errorMessage: response.data.message
                    })
                } else {
                    dispatch({
                        type: 'UPDATE_BRAND_SUCCESS',
                        payload: response.data
                    })
                }
            }).catch(error => {
                let errorMessage = error;
                if (error.response) {
                    if (error.response.data.error) {
                        errorMessage = error.response.data.message;
                    }
                }
                dispatch({
                    type: 'UPDATE_BRAND_FAILURE',
                    errorMessage: errorMessage
                })
            })
    }
}

// Category
export function getCategory(request) {
    const requestObj = request ? { params: request } : "";
    return (dispatch) => {
        returnApiCallPromise("GET", category, requestObj)
            .then(response => {
                dispatch({
                    type: 'GET_CATEGORY_SUCCESS',
                    payload: response.data
                })
            }).catch(error => {

                let errorMessage = error;
                if (error.response) {
                    if (error.response.data.error) {
                        errorMessage = error.response.data.message;
                    }
                }
                dispatch({
                    type: 'GET_CATEGORY_FAILURE',
                    errorMessage: errorMessage
                })
            })
    }
}

export function createCategory(request) {

    return (dispatch) => {
        returnApiCallPromise("POST", category, request)
            .then(response => {
                if (response.data.error) {
                    dispatch({
                        type: 'CREATE_CATEGORY_FAILURE',
                        errorMessage: response.data.message
                    })
                } else {
                    dispatch({
                        type: 'CREATE_CATEGORY_SUCCESS',
                        payload: response.data
                    })
                }
            }).catch(error => {
                let errorMessage = error;
                if (error.response) {
                    if (error.response.data.error) {
                        errorMessage = error.response.data.message;
                    }
                }
                dispatch({
                    type: 'CREATE_CATEGORY_FAILURE',
                    errorMessage: errorMessage
                })
            })
    }
}

export function updateCategory(request, categoryId) {
    return (dispatch) => {
        returnApiCallPromise("PUT", category + "/" + categoryId, request)
            .then(response => {

                if (response.data.error) {
                    dispatch({
                        type: 'UPDATE_CATEGORY_FAILURE',
                        errorMessage: response.data.message
                    })
                } else {
                    dispatch({
                        type: 'UPDATE_CATEGORY_SUCCESS',
                        payload: response.data
                    })
                }
            }).catch(error => {
                let errorMessage = error;
                if (error.response) {
                    if (error.response.data.error) {
                        errorMessage = error.response.data.message;
                    }
                }
                dispatch({
                    type: 'UPDATE_CATEGORY_FAILURE',
                    errorMessage: errorMessage
                })
            })
    }
}

// Material Type

export function getMaterialType(request) {
    const requestObj = request ? { params: request } : "";
    return (dispatch) => {
        returnApiCallPromise("GET", materialType, requestObj)
            .then(response => {
                dispatch({
                    type: 'GET_MATERIAL_TYPE_SUCCESS',
                    payload: response.data
                })
            }).catch(error => {

                let errorMessage = error;
                if (error.response) {
                    if (error.response.data.error) {
                        errorMessage = error.response.data.message;
                    }
                }
                dispatch({
                    type: 'GET_MATERIAL_TYPE_FAILURE',
                    errorMessage: errorMessage
                })
            })
    }
}

export function createMaterialType(request) {

    return (dispatch) => {
        returnApiCallPromise("POST", materialType, request)
            .then(response => {
                if (response.data.error) {
                    dispatch({
                        type: 'CREATE_MATERIAL_TYPE_FAILURE',
                        errorMessage: response.data.message
                    })
                } else {
                    dispatch({
                        type: 'CREATE_MATERIAL_TYPE_SUCCESS',
                        payload: response.data
                    })
                }
            }).catch(error => {
                let errorMessage = error;
                if (error.response) {
                    if (error.response.data.error) {
                        errorMessage = error.response.data.message;
                    }
                }
                dispatch({
                    type: 'CREATE_MATERIAL_TYPE_FAILURE',
                    errorMessage: errorMessage
                })
            })
    }
}

export function updateMaterialType(request, materialTypeId) {
    return (dispatch) => {
        returnApiCallPromise("PUT", materialType + "/" + materialTypeId, request)
            .then(response => {

                if (response.data.error) {
                    dispatch({
                        type: 'UPDATE_MATERIAL_TYPE_FAILURE',
                        errorMessage: response.data.message
                    })
                } else {
                    dispatch({
                        type: 'UPDATE_MATERIAL_TYPE_SUCCESS',
                        payload: response.data
                    })
                }
            }).catch(error => {
                let errorMessage = error;
                if (error.response) {
                    if (error.response.data.error) {
                        errorMessage = error.response.data.message;
                    }
                }
                dispatch({
                    type: 'UPDATE_MATERIAL_TYPE_FAILURE',
                    errorMessage: errorMessage
                })
            })
    }
}

// Material 

export function getMaterial(request) {
    const requestObj = request ? { params: request } : "";
    return (dispatch) => {
        returnApiCallPromise("GET", material, requestObj)
            .then(response => {                
                dispatch({
                    type: 'GET_MATERIAL_SUCCESS',
                    payload: response.data
                })
            }).catch(error => {

                let errorMessage = error;
                if (error.response) {
                    if (error.response.data.error) {
                        errorMessage = error.response.data.message;
                    }
                }                
                dispatch({
                    type: 'GET_MATERIAL_FAILURE',
                    errorMessage: errorMessage
                })
            })
    }
}

export function getAvailableMaterial(request,materialId) {
    // const requestObj = request ? { params: request } : "";
    return (dispatch) => {
        returnApiCallPromise("GET", availableMaterial+ "/" + materialId, request)
            .then(response => {                
                dispatch({
                    type: 'GET_AVAILABEL_MATERIAL_SUCCESS',
                    payload: response.data
                })
            }).catch(error => {

                let errorMessage = error;
                if (error.response) {
                    if (error.response.data.error) {
                        errorMessage = error.response.data.message;
                    }
                }                
                dispatch({
                    type: 'GET_AVAILABEL_MATERIAL_FAILURE',
                    errorMessage: errorMessage
                })
            })
    }
}

export function getMaterialInfo(request) {
    const requestObj = request ? { params: request } : "";
    return (dispatch) => {
        returnApiCallPromise("GET", materialInfo, requestObj)
            .then(response => {                
                dispatch({
                    type: 'GET_MATERIAL_INFO_SUCCESS',
                    payload: response.data
                })
            }).catch(error => {

                let errorMessage = error;
                if (error.response) {
                    if (error.response.data.error) {
                        errorMessage = error.response.data.message;
                    }
                }                
                dispatch({
                    type: 'RESET_GET_MATERIAL_INFO_FAILURE',
                    errorMessage: errorMessage
                })
            })
    }
}

export function createMaterial(request) {

    return (dispatch) => {
        returnApiCallPromise("POST", material, request)
            .then(response => {
                if (response.data.error) {
                    dispatch({
                        type: 'CREATE_MATERIAL_FAILURE',
                        errorMessage: response.data.message
                    })
                } else {
                    dispatch({
                        type: 'CREATE_MATERIAL_SUCCESS',
                        payload: response.data
                    })
                }
            }).catch(error => {
                let errorMessage = error;
                if (error.response) {
                    if (error.response.data.error) {
                        errorMessage = error.response.data.message;
                    }
                }
                dispatch({
                    type: 'CREATE_MATERIAL_FAILURE',
                    errorMessage: errorMessage
                })
            })
    }
}

export function updateMaterial(request, materialId) {
    return (dispatch) => {
        returnApiCallPromise("PUT", material + "/" + materialId, request)
            .then(response => {

                if (response.data.error) {
                    dispatch({
                        type: 'UPDATE_MATERIAL_FAILURE',
                        errorMessage: response.data.message
                    })
                } else {
                    dispatch({
                        type: 'UPDATE_MATERIAL_SUCCESS',
                        payload: response.data
                    })
                }
            }).catch(error => {
                let errorMessage = error;
                if (error.response) {
                    if (error.response.data.error) {
                        errorMessage = error.response.data.message;
                    }
                }
                dispatch({
                    type: 'UPDATE_MATERIAL_FAILURE',
                    errorMessage: errorMessage
                })
            })
    }
}

//

//GET
export function getSupplier(request) {
    const requestObj = request ? { params: request } : "";
    return (dispatch) => {
        returnApiCallPromise("GET", supplier, requestObj)
            .then(response => {                
                dispatch({
                    type: 'GET_SUPPLIER_SUCCESS',
                    payload: response.data
                })
            }).catch(error => {

                let errorMessage = error;
                if (error.response) {
                    if (error.response.data.error) {
                        errorMessage = error.response.data.message;
                    }
                }
                dispatch({
                    type: 'GET_SUPPLIER_FAILURE',
                    errorMessage: errorMessage
                })
            })
    }
}

//POST
export function createSupplier(request) {

    return (dispatch) => {
        returnApiCallPromise("POST", supplier, request)
            .then(response => {
                if (response.data.error) {
                    dispatch({
                        type: 'CREATE_SUPPLIER_FAILURE',
                        errorMessage: response.data.message
                    })
                } else {
                    dispatch({
                        type: 'CREATE_SUPPLIER_SUCCESS',
                        payload: response.data
                    })
                }
            }).catch(error => {
                let errorMessage = error;
                if (error.response) {
                    if (error.response.data.error) {
                        errorMessage = error.response.data.message;
                    }
                }
                dispatch({
                    type: 'CREATE_SUPPLIER_FAILURE',
                    errorMessage: errorMessage
                })
            })
    }
}

export function updateSupplier(request, supplierId) {
    return (dispatch) => {
        returnApiCallPromise("PUT", supplier + "/" + supplierId, request)
            .then(response => {

                if (response.data.error) {
                    dispatch({
                        type: 'UPDATE_SUPPLIER_FAILURE',
                        errorMessage: response.data.message
                    })
                } else {
                    dispatch({
                        type: 'UPDATE_SUPPLIER_SUCCESS',
                        payload: response.data
                    })
                }
            }).catch(error => {
                let errorMessage = error;
                if (error.response) {
                    if (error.response.data.error) {
                        errorMessage = error.response.data.message;
                    }
                }
                dispatch({
                    type: 'UPDATE_SUPPLIER_FAILURE',
                    errorMessage: errorMessage
                })
            })
    }
}
