
import React, { useState, useEffect } from 'react';
import { FaSearch } from "react-icons/fa";
import "./searchableInput.css"; // Import your CSS file for styling
 
const SearchableInput = ({ setState, state, index, onChange, displayKey, name, value }) => {
 
  const [filteredObjectsState, setFilteredObjectsState] = useState([]);
  const [input, setInput] = useState(value);
 
  useEffect(() => {
    if (state && state.length > 0 && state[index]) {
      setFilteredObjectsState(state[index]?.filteredData || [] );
    } else {
      setFilteredObjectsState(state?.filteredData || []);
    }
  }, [state, index]);
  console.log(filteredObjectsState)

  const handleSelect = (result) => {
    if (result && displayKey in result) {
      const selectedValue = result[displayKey];
      setInput(selectedValue);
 
      if (typeof onChange === 'function') {
        onChange({ target: { value: selectedValue } });
        setFilteredObjectsState([]);
      }
 
 
      if (state && state.length > 0 && state[index]) {
        const updatedState = [...state];
        updatedState[index].filteredData = [];
        setState(updatedState);
      }
    } else {
      console.error('Invalid result object or displayKey does not exist in result.');
    }
  };
 
 
  const handleClear = () => {
    if (state && state.length > 0 && state[index]) {
      setFilteredObjectsState(state[index].filteredData = []);
    }
  };
 
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === 'Backspace') {
      handleClear();
    }
  };
 
  const handleInputChange = (e) => {
    setInput(e.target.value);
    onChange(e, name);
 
    // Update filteredObjectsState based on input
    const updatedFilteredObjects = state[index]?.filteredData?.filter(obj =>
      obj[displayKey].toLowerCase().includes(e.target.value.toLowerCase())
    ) || [];
 
    setFilteredObjectsState(updatedFilteredObjects);
  };
 
 
 
 
  return (
    <div className="input-wrapper" style={{ border: "0.2px solid rgb(209, 202, 202)", position: 'relative' }}>
      <input
        placeholder="Type to search..."
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        value={input}
        name={name}
        autoComplete="off"
 
      />
      {filteredObjectsState.length > 0 && (
        <div className="results-list" style={{
          position: 'absolute', top: '100%', left: 0, right: 0, background: 'white', border: "0.2px solid rgb(209, 202, 202)",
          zIndex: 1, fontSize: "1rem"
        }}>
          {filteredObjectsState.map((result, idx) => (
            <div key={idx} className="result-item" onClick={() => handleSelect(result)} style={{ padding: '10px', cursor: 'pointer', fontSize: "0.8rem" }}>
              {result[displayKey]}
 
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
 
export default SearchableInput;
 
 