import React from 'react';
import styled from 'styled-components';

const CardView = ({ children, title, headerView, style, headerStyle }) => {
    // Styled components
    const Card = styled.div`    
        border-radius: 20px;
        box-shadow: 0 8px 30px rgba(0, 0, 0, 0.15);
        background: rgb(255, 255, 255);
        transition: box-shadow 0.3s ease, transform 0.3s ease, background-color 0.3s ease;
        overflow: hidden;
        margin: 20px;
        width: 100%;
        max-width: 380px;
        border: 1px solid #f0f0f0;

       
    `;

    const CardHeader = styled.div`         
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(to right, #3a3bff, #4f6fdb); /* Gradient background */
        color: #ffffff;
        font-size: 18px;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 1px;
        padding: 15px;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    `;

    const CardBody = styled.div`          
        padding: 20px;
        background: #ffffff;
        min-height: 150px;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        color: #333;
        font-size: 14px;
        line-height: 1.6;
        transition: padding 0.2s ease;
        
        
    `;

    const defaultStyle = style || {};
    const headerDefaultStyle = headerStyle || {};

    return (
        <Card style={defaultStyle}>
            {title && (
                <CardHeader style={headerDefaultStyle}>
                    {title}
                </CardHeader>
            )}

            {headerView && headerView}

            <CardBody>
                {children}
            </CardBody>
        </Card>
    );
};

export default CardView;
