import styled from 'styled-components';
// import childIcon from '../../../assets/icons/icon-submenu.svg';
import * as FaIcons from 'react-icons/fa';


const AreaData = styled.div`
width:100%;
height:100%;
overflow:hidden;
&.login {
    padding-left:40px;
    padding-right:40px;
    background-color:var(--inavitas008);
}

&.sidenav {  
    background-color:var(--inavitas008);
    overflow:initial;
    z-index:9;
}


&.open {
    max-width: 240px;
    background-color:var(--inavitas008);
    transition:0.5s;
}


&.close {
    max-width: 50px;
    background-color:var(--inavitas008);
    transition:0.5s; 
}

&.close>.sidenav-logo{
    width: 100%;
    height: 80px;
    background-position: 11%;
}

&.close>.main-menu>.inv-menu-row{
    position: relative;
}

&.close>.child-menu>.inv-menu-row>.icon-down1{
    display: none!important;
}

&.close>.child-menu>.inv-menu-row>.icon-up1{
    display: none!important;
}


&.close>.child-menu>.inv-menu-row>.inv-text{
    display: none!important;
}

&.close>.child-menu>.inv-menu-row:hover{
    overflow: visible;
}

&.close>.child-menu{
    position:relative!important;
    width:50px;
}
&.close>.child-menu>.inv-menu-row ~ div{
   display:none!important;
}


&.close>.child-menu:hover>.inv-menu-row ~ div{
    display:block!important;
    z-index:99999999999;
    position:absolute!important;
    overflow:visible!important;
    top:0;
    left:50px;
    min-width:200px;
    border-left: 5px solid rgba(0,0,0,0);
    height: auto!important;
}

&.close>.child-menu:hover>.inv-menu-row ~ div>div{
    border-bottom:1px solid #fff;
}

&.close>.main-menu>.inv-menu-row:hover{
    position: relative;
}

&.close>.main-menu>a>.inv-menu-row>.inv-text{
    display: none!important;
    width: 200px;
    height: 50px;
    line-height: 50px;
}

&.close>.main-menu>.inv-menu-row>.inv-text{
    display: none!important;
    min-width: 200px;
    height: 50px;
    line-height: 50px;
}

&.close>.main-menu>a>.inv-menu-row:hover>.inv-text{
    display: block!important;
    position: absolute;
    left:55px;
    z-index: 999999999999;
    background-color: var(--inavitas008);
}





.child-menu .main-menu>a>div{
    background-color:var(--inavitas008);
    padding-left:0px !important;
}

.child-menu .main-menu>a>div>.inv-image{
    display:none;
    background-image:url(${FaIcons.FaEnvelopeOpenText })!important;
    width: 25px;
    height: 25px;
    opacity: 0.5;
    background-size:cover
}

.child-menu .main-menu>a>div:hover{
    background-color:var(--inavitas007);
}





`;

export default AreaData;