
const batchForm = [
    {
        title: "Basic Information",
        formBody : [
            {
                formField: [
                    {
                        label: "Batch Name",
                        inputType: "text",
                        placeholder: "Enter Batch name",
                        fieldName:"batchName",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Batch Start",
                        inputType: "text",
                        type:'number',
                        placeholder: "YYYY",
                        fieldName:"batchStart",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Batch End",
                        inputType: "text",
                        type:'number',
                        placeholder: "YYYY",
                        fieldName:"batchEnd",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }
                                                                     
                ]
            }
        ]
    }
];

export {
    batchForm
};