import * as yup from "yup";
import { Formik } from "formik";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import DateTime from "react-datetime";
import { Form, Button } from "react-bootstrap";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import FormLayout from "app/views/formlayout";
import { feesTypeForm } from "./formData";
import { MdEdit, MdDelete } from "react-icons/md";
import { FiEdit } from "react-icons/fi";

import swal from "sweetalert2";
import _ from "lodash";
import formRender from "app/utils/formcomponent";
import { createFeesType, getFeesType, updateFeesType } from "app/api/FeesTypeApi";
import { pagePermission, showMessage } from "app/utils/app-functions";
import CustomTable from "app/components/atoms/custom-table/CustomTable";
import SimpleCard from "app/components/atoms/simple-card/SimpleCard";
import View from "app/components/atoms/modalView/Modalview";
import { useSidebar } from "app/components/sidebar/sidebarContext";


import Alert from "app/components/atoms/alert";
import Loading from "app/components/atoms/loading";
let editData = false;


const FeesType = ({ navigation }) => {

    const dispatch = useDispatch();
    const customerFormRef = useRef();

    const getFeesTypeSuccess = useSelector((state) => state.feesTypeReducer.getFeesTypeSuccess);
    const getFeesTypeList = useSelector((state) => state.feesTypeReducer.getFeesTypeList);
    const getFeesTypeFailure = useSelector((state) => state.feesTypeReducer.getFeesTypeFailure);

    const createFeesTypeSuccess = useSelector((state) => state.feesTypeReducer.createFeesTypeSuccess);
    const createFeesTypeData = useSelector((state) => state.feesTypeReducer.createFeesTypeData);
    const createFeesTypeFailure = useSelector((state) => state.feesTypeReducer.createFeesTypeFailure);

    const updateFeesTypeSuccess = useSelector((state) => state.feesTypeReducer.updateFeesTypeSuccess);
    const updateFeesTypeData = useSelector((state) => state.feesTypeReducer.updateFeesTypeData);
    const updateFeesTypeFailure = useSelector((state) => state.feesTypeReducer.updateFeesTypeFailure);

    const feesTypeErrorMessage = useSelector((state) => state.feesTypeReducer.errorMessage);

    const [pageItems, setpageItems] = useState({
        "view": true,
        "create": true,
        "update": true,
        "deleteOption": false
    }
    );

    useEffect(() => {
        let data = pagePermission(pageItems, "Administrator", "Fees Type")
        setpageItems(data)

    }, [navigation]);

    const column = [
        {
            "dataField": "index",
            "text": "S.No",
            "sort": true,
            formatter: (cellContent, row, index) => (
                index + 1
            )
        },
        {
            "dataField": "feesTypeName",
            "text": "Fees Type Name",
            "sort": true
        },
        {
            "dataField": "installment",
            "text": "Installment",
            "sort": true,
        },
        {
            dataField: "isActive",
            text: "Status",
            sort: true,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => {
                const conInfo = { inputType: "status", status: cellContent }
                return formRender(null, null, conInfo, null);
            }
        },
        /*   {
              text: "Action",
              sort: false,
              align: "center",
              headerAlign: "center",
              formatter: (cellContent, row, index) => (
                  <div >
                     { pageItems.update && <FiEdit
                          className="text-success cursor-pointer"
                          size={18}
                          onClick={() => onEditForm(row, index)}
                      ></FiEdit>}
                      {pageItems.deleteOption && <MdDelete
                          className="text-danger cursor-pointer"
                          size={18}
                          onClick={() => {
                              swal
                                  .fire({
                                      title: "Are you sure?",
                                      text: "You won't be able to revert this!",
                                      icon: "warning",
                                      type: "question",
                                      showCancelButton: true,
                                      confirmButtonColor: "#3085d6",
                                      cancelButtonColor: "#d33",
                                      confirmButtonText: "Yes, delete it!",
                                      cancelButtonText: "No",
                                  })
                                  .then((result) => {
                                      if (result.value) {
                                          
                                          onDeleteItem(row, index);
                                      } else {
                                          swal.fire({
                                              title: "Cancelled!",
                                              text: "Permission denied.",
                                              type: "error",
                                              icon: "error",
                                              timer: 1500,
                                          });
                                      }
                                  });
                          }}
                      ></MdDelete>}
                      {!pageItems.update && !pageItems.deleteOption && "No Option Found"}
                  </div>
              )
          }, */
    ]
    if (pageItems.update || pageItems.delete) {
        column.push({
            text: "Action",
            sort: false,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => (
                <div className="icon-container">
                    {pageItems.update && (
                        <FiEdit
                            className="text-primary cursor-pointer"
                            size={18}
                            onClick={() => onEditForm(row, index)}
                        />
                    )}
                    {pageItems.deleteOption && (
                        <MdDelete
                            className="text-danger cursor-pointer"
                            size={18}
                            onClick={() => {
                                swal
                                    .fire({
                                        title: "Are you sure?",
                                        text: "You won't be able to revert this!",
                                        icon: "warning",
                                        type: "question",
                                        showCancelButton: true,
                                        confirmButtonColor: "#3085d6",
                                        cancelButtonColor: "#d33",
                                        confirmButtonText: "Yes, delete it!",
                                        cancelButtonText: "No",
                                    })
                                    .then((result) => {
                                        if (result.value) {
                                            onDeleteItem(row, index);
                                        } else {
                                            swal.fire({
                                                title: "Cancelled!",
                                                text: "Permission denied.",
                                                type: "error",
                                                icon: "error",
                                                timer: 1500,
                                            });
                                        }
                                    });
                            }}
                        />
                    )}
                </div>
            )
        });
    }
    const [state, setState] = useState({
        feesTypeName: "",
        feesTypeStart: "",
        feesTypeEnd: ""
    });

    const [createModule, setCreateModule] = useState(false);
    const [loaderModule, setLoaderModule] = useState(false);
    const [editModule, setEditModule] = useState(false);
    const [viewModule, setViewModule] = useState(false);
    const [deleteModule, setDeleteModule] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [selectedItem, setSelectedItem] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);

    useEffect(() => {
        setLoaderModule(true)
        const requestData = {
            isActive: "true"
        }
        dispatch(getFeesType(requestData))
    }, [navigation]);

    useEffect(() => {
        if (getFeesTypeSuccess) {
            setItemList(getFeesTypeList)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_FEES_TYPE" })
        } else if (getFeesTypeFailure) {
            setItemList([])
            showMessage("warning", feesTypeErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_FEES_TYPE" })
        }
    }, [getFeesTypeSuccess, getFeesTypeFailure]);

    useEffect(() => {
        if (createFeesTypeSuccess) {
            const temp_state = [...itemList, createFeesTypeData[0]];
            setItemList(temp_state)
            closeModule()
            setLoaderModule(false)
            Alert({ description: 'Created Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            dispatch({ type: "RESET_CREATE_FEES_TYPE" })
        } else if (createFeesTypeFailure) {
            setLoaderModule(false)
            showMessage("warning", feesTypeErrorMessage?.message)
            dispatch({ type: "RESET_CREATE_FEES_TYPE" })
        }
    }, [createFeesTypeSuccess, createFeesTypeFailure]);

    useEffect(() => {
        if (updateFeesTypeSuccess) {
            updateTable(updateFeesTypeData[0])
            dispatch({ type: "RESET_UPDATE_FEES_TYPE" })

        } else if (updateFeesTypeFailure) {
            setLoaderModule(false)
            showMessage("warning", feesTypeErrorMessage?.message)
            dispatch({ type: "RESET_UPDATE_FEES_TYPE" })
        }
    }, [updateFeesTypeSuccess, updateFeesTypeFailure]);

    const updateTable = (updatedItem) => {
        const temp_state = [...itemList];
        if (deleteModule) {
            let remainingList = _.remove(temp_state, function (n) {
                return n.feesTypeId != updatedItem.feesTypeId;
            });
            Alert({ description: 'Deleted Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(remainingList)
        } else {
            temp_state[selectedIndex] = updatedItem;
            Alert({ description: 'Updated Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(temp_state)
        }
        setLoaderModule(false)
        closeModule()
    }

    const closeModule = () => {
        editData = false;
        setCreateModule(false)
        setDeleteModule(false)
    }

    const resetState = () => {
        setState({
            ...state,
            feesTypeName: "",
            installment: ""
        })
    }

    const onCreateForm = () => {
        resetState()
        editData = false;
        setCreateModule(true)
    }

    const onEditForm = (data, index) => {
        setState({
            ...state,
            feesTypeName: data?.feesTypeName || "",
            installment: data?.installment || ""
        })
        editData = true;
        setSelectedIndex(index)
        setSelectedItem(data)
        setCreateModule(true)
    }

    const onDeleteItem = (data, index) => {
        editData = false;
        setSelectedIndex(index)
        setSelectedItem(data)
        setDeleteModule(true)
    }

    const validateForm = async () => {
        const validatefom = customerFormRef.current.validateForm();
    }

    const onSubmitForm = () => {
        const requestDate = {
            feesTypeName: feesTypeName,
            installment: installment
        }
        if (editData) {
            setLoaderModule(true)
            dispatch(updateFeesType(requestDate, selectedItem.feesTypeId))
        } else if (deleteModule) {
            const deleteRequest = {
                isActive: false
            }
            setLoaderModule(true)
            dispatch(updateFeesType(deleteRequest, selectedItem.feesTypeId))
        } else {
            setLoaderModule(true)
            dispatch(createFeesType(requestDate))
        }
    }

    const handleClick = (buttonName) => {
        if (buttonName == 'Add') {
            onCreateForm();
        }
    };

    const {
        feesTypeName,
        installment
    } = state;

    const modelTitle = editData ? "Edit " : "Create ";
    const modelButtonLbl = editData ? "" : "Save";
    const { sidebarOpen } = useSidebar();


    return (
        <div>
            <Loading status={loaderModule} />
            <div style={sidebarOpen ? { marginLeft: "10px", marginTop: "-10px" } : { marginLeft: "10px", marginTop: "-50px" }}>
                <View size="sm" isOpen={createModule} onClose={setCreateModule} title={`${modelTitle} Fees Type`} onSubmit={validateForm}>
                    {/* <DynamicCheckBox options={options} onSelectionChange={handleSelectionChange} /> */}
                    <FormLayout ref={customerFormRef} defaultState={state} setDefaultState={setState} dynamicForm={feesTypeForm} noOfColumn={1} onSubmit={onSubmitForm} />

                </View>
                <View size="sm" isOpen={deleteModule} onClose={setDeleteModule} tableName="Create Modalbox">
                    {/* <DynamicCheckBox options={options} onSelectionChange={handleSelectionChange} /> */}
                    <h6 className="text-capitalize mb-1">Are you sure ?</h6>

                </View>
                <SimpleCard >
                    <CustomTable columns={column} buttonData={pageItems.create ? ['add'] : []} handleClick={handleClick} tableName={"Fees Type"} data={itemList} state={state} />
                </SimpleCard>
            </div>
        </div>
    )
}

export default FeesType;