import { returnApiCallPromise } from './ApiContent';
import { enquiryType, examList } from './APIContainer';

//GET
export function getEnquiryType(request) {
  const requestObj = request ? { params: request } : "";
  return (dispatch) => {
    returnApiCallPromise("GET", enquiryType, requestObj)
      .then(response => {
        dispatch({
          type: 'GET_ENQUIRY_TYPE_SUCCESS',
          payload: response.data
        })
      }).catch(error => {

        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'GET_ENQUIRY_TYPE_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}


export function asyncGetExamTypeInfo(request) {
  const requestObj = request ? { params: request } : "";
  return  returnApiCallPromise("GET", examList, requestObj)
      .then(response => {
        return {
          error: false,
          message:"Succsss",
          data: response?.data?.data || []
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        return {
          error: true,
          data:[],
          message:errorMessage
        }
      })
}

//GET enquiryType LIST
export function getEnquiryTypeListData(request) {
  const requestObj = request ? { params: request } : "";
  return (dispatch) => {
    returnApiCallPromise("GET", examList, requestObj)
      .then(response => {
        dispatch({
          type: 'GET_ENQUIRY_LIST_SUCCESS',
          payload: response.data
        })
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'GET_ENQUIRY_LIST_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

//GET
export function deleteEnquiryTypeDetails(request) {
  const requestObj = request ? { params: request } : "";
  return (dispatch) => {
    returnApiCallPromise("DELETE", enquiryType, requestObj)
      .then(response => {
        dispatch({
          type: 'DELETE_ENQUIRY_DETAILS_SUCCESS',
          payload: response.data
        })
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'DELETE_ENQUIRY_DETAILS_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

//POST
export function createEnquiryType(request) {

  return (dispatch) => {
    returnApiCallPromise("POST", enquiryType, request)
      .then(response => {
        if (response.data.error) {
          dispatch({
            type: 'CREATE_ENQUIRY_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'CREATE_ENQUIRY_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'CREATE_ENQUIRY_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

export function updateEnquiryType(request, enquiryId) {
  return (dispatch) => {
    returnApiCallPromise("PUT", enquiryType + "/" + enquiryId, request)
      .then(response => {
        if (response.data.error) {
          dispatch({
            type: 'UPDATE_ENQUIRY_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'UPDATE_ENQUIRY_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'UPDATE_ENQUIRY_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}