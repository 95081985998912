const employeeAttendanceForm = [
    {
        title: "Basic Information",
        formBody : [
            {
                formField: [
                    {
                        label: "",
                        inputType: "switch",
                        fieldName:"attendanceStatusName",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                                                                     
                ]
            }
        ]
    }
]; 

const employeeAttendanceFilterForm = [
    {
        title: "Basic Information",
        formBody : [           
            {
                formField: [
                    {
                        label: "Shift",
                        inputType: "select",
                        options: "shiftList",
                        uniqueKey: "shiftId",
                        displayKey: "shiftStartTime",
                        subDisplayKey:"shiftEndTime",
                        multiple: false,
                        fieldName: "shiftId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },                                                                                   
                ]
            },
            {
                formField: [
                    {
                        label: "Attendance Date",
                        inputType: "date",
                        fieldName:"attendanceDate",
                        disabled: true,
                        onChange:"onSelectAttendanceDate",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }                                                                                     
                ]
            },
            {
                formField: [
                    {
                        label: "Department",
                        inputType: "select",
                        options: "departmentList",
                        uniqueKey:"departmentId",
                        displayKey:"departmentName",
                        multiple:false,
                        fieldName:"departmentId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }                                                                                     
                ]
            },
        ]
    }
];

export {
    employeeAttendanceForm,
    employeeAttendanceFilterForm
};