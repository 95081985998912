import * as yup from "yup";
import { Formik } from "formik";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import DateTime from "react-datetime";
import { Form, Button } from "react-bootstrap";
import { DateRange } from "react-date-range";
import SimpleCard from 'app/components/atoms/simple-card/SimpleCard'
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { MdEdit, MdDelete } from "react-icons/md";
import swal from "sweetalert2";
import _ from "lodash";
import { pagePermission, showMessage } from "app/utils/app-functions";
import FormLayout from "app/components/render/formlayout";
import View from "app/components/atoms/modalView/Modalview";
import CustomTable from "app/components/atoms/custom-table/CustomTable";
import formRender from "app/components/render/formcomponent";
import { departmentForm } from "./formData";
import { createDepartment, getDepartment, updateDepartment } from "app/api/Department";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import Alert from "app/components/atoms/alert";
import { useSidebar } from "app/components/sidebar/sidebarContext";

let editData = false;

const Department = ({ navigation }) => {
    
    const dispatch = useDispatch();
    const customerFormRef = useRef();

    const getDepartmentSuccess = useSelector((state) => state.departmentReducer.getDepartmentSuccess);
    const getDepartmentList = useSelector((state) => state.departmentReducer.getDepartmentList);
    const getDepartmentFailure = useSelector((state) => state.departmentReducer.getDepartmentFailure);

    const createDepartmentSuccess = useSelector((state) => state.departmentReducer.createDepartmentSuccess);
    const createDepartmentData = useSelector((state) => state.departmentReducer.createDepartmentData);
    const createDepartmentFailure = useSelector((state) => state.departmentReducer.createDepartmentFailure);

    const updateDepartmentSuccess = useSelector((state) => state.departmentReducer.updateDepartmentSuccess);
    const updateDepartmentData = useSelector((state) => state.departmentReducer.updateDepartmentData);
    const updateDepartmentFailure = useSelector((state) => state.departmentReducer.updateDepartmentFailure);

    const departmentErrorMessage = useSelector((state) => state.departmentReducer.errorMessage);


    const [pageItems, setpageItems] = useState({
    }
    );

    useEffect(() => {
        let data = pagePermission(pageItems, "Masters", "Course")
        setpageItems(data)

    }, [navigation]);

    const column = [
        {
            "dataField": "sNo",
            "text": "S.No",
            "sort": true,
            formatter: (cellContent, row, index) => (
                index + 1
            )
        },
        {
            "dataField": "departmentName",
            "text": "Department Name",
            "sort": true
        },
        {
            "dataField": "description",
            "text": "Description ",
            "sort": false
        },
        {
            dataField: "isActive",
            text: "Status",
            sort: true,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => {
                const conInfo = { inputType: "status", status: cellContent }
                return formRender(null, null, conInfo, null);
            }
        }
    ]

    if (pageItems.delete || pageItems.update) {
        column.push({
            text: "Action",
            sort: false,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => (
                <div className="icon-container">
                    {pageItems.update && <FiEdit
                        className="text-primary cursor-pointer"
                        size={18}
                        onClick={() => onEditForm(row, index)}
                    ></FiEdit>}
                    {pageItems.delete && <FiTrash2
                        className="text-danger cursor-pointer"
                        size={18}
                        onClick={() => {
                            onDeleteItem(row, index);

                        }}
                    ></FiTrash2>}
                </div>
            )
        })
    }

    
    const [state, setState] = useState({
        departmentName: "",
        description: ""
    });

    const [createModule, setCreateModule] = useState(false);
    const [loaderModule, setLoaderModule] = useState(false);
    const [editModule, setEditModule] = useState(false);
    const [viewModule, setViewModule] = useState(false);
    const [deleteModule, setDeleteModule] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [selectedItem, setSelectedItem] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);

    useEffect(() => {
        setLoaderModule(true)
        const requestData = {
            isActive: "true"
        }
        dispatch(getDepartment(requestData))
    }, [navigation]);

    
        const confromDialog = () => {
            return (
                swal
                    .fire({
                        title: "Are you sure?",
                        text: "You won't be able to revert this!",
                        icon: "warning",
                        type: "question",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes, delete it!",
                        cancelButtonText: "No",
                    })
                    .then((result) => {
                        if (result.value) {
                            onSubmitForm()
                        } else {
                            setDeleteModule(false)
                            swal.fire({
                                title: "Cancelled!",
                                text: "Permission denied.",
                                type: "error",
                                icon: "error",
                                timer: 1500,
                            });
                        }
                    })
            )
        }
    
        useEffect(() => {
            if (deleteModule) {
                confromDialog()
            }
        }, [deleteModule])

    useEffect(() => {
        if (getDepartmentSuccess) {
            setItemList(getDepartmentList)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_DEPARTMENT" })
        } else if (getDepartmentFailure) {
            setItemList([])
            showMessage("warning", departmentErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_DEPARTMENT" })
        }
    }, [getDepartmentSuccess, getDepartmentFailure]);

    useEffect(() => {
        if (createDepartmentSuccess) {
            const temp_state = [...itemList, createDepartmentData[0]];
            setItemList(temp_state)
            setLoaderModule(false)
            closeModule()
            Alert({  description: 'Created Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            dispatch({ type: "RESET_CREATE_DEPARTMENT" })
        } else if (createDepartmentFailure) {
            setLoaderModule(false)
            showMessage("warning", departmentErrorMessage?.message)
            dispatch({ type: "RESET_CREATE_DEPARTMENT" })
        }
    }, [createDepartmentSuccess, createDepartmentFailure]);


    useEffect(() => {
        if (updateDepartmentSuccess) {
            updateTable(updateDepartmentData[0])
            dispatch({ type: "RESET_UPDATE_DEPARTMENT" })
        } else if (updateDepartmentFailure) {
            setLoaderModule(false)
            showMessage("warning", departmentErrorMessage?.message)
            dispatch({ type: "RESET_UPDATE_DEPARTMENT" })
        }
    }, [updateDepartmentSuccess, updateDepartmentFailure]);

    const updateTable = (updatedItem) => {
        const temp_state = [...itemList];
        if (deleteModule) {
            let remainingList = _.remove(temp_state, function (n) {
                return n.departmentId != updatedItem.departmentId;
            });
            Alert({  description: 'Deleted Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(remainingList)
        } else {
            temp_state[selectedIndex] = updatedItem;
            Alert({  description: 'Updated Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(temp_state)
        }
        setLoaderModule(false)
        closeModule()
    }

    const closeModule = () => {
        editData = false;
        setCreateModule(false)
        setDeleteModule(false)
    }

    const resetState = () => {
        setState({
            ...state,
            departmentName: "",
            description: ""
        })
    }

    const onCreateForm = () => {
        resetState()
        editData = false;
        setCreateModule(true)
    }

    const onEditForm = (data, index) => {
        setState({
            ...state,
            departmentName: data?.departmentName || "",
            description: data?.description || ""
        })
        editData = true;
        setSelectedIndex(index)
        setSelectedItem(data)
        setCreateModule(true)
    }

    const onDeleteItem = (data, index) => {
        editData = false;
        setSelectedIndex(index)
        setSelectedItem(data)
        setDeleteModule(true)
    }

    const validateForm = async () => {
        const validatefom = customerFormRef.current.validateForm();
    }

    const onSubmitForm = () => {
        const requestDate = {
            departmentName: departmentName,
            description: description
        }
        if (editData) {
            setLoaderModule(true)
            dispatch(updateDepartment(requestDate, selectedItem.departmentId))
        } else if (deleteModule) {
            const deleteRequest = {
                isActive: "false"
            }
            setLoaderModule(true)
            dispatch(updateDepartment(deleteRequest, selectedItem.departmentId))
        } else {
            setLoaderModule(true)
            dispatch(createDepartment(requestDate))
        }
    }

    const {
        departmentName,
        description,
    } = state;

    const handleCloseModal = () => {
        setCreateModule(false);
    };

    const handleClick = (buttonName) => {
        if (buttonName == 'Add') {
            onCreateForm();
        }
    };

    const modelTitle = editData ? "Edit " : "Create ";
    const modelButtonLbl = editData ? "" : "Save";
    const { sidebarOpen } = useSidebar();

    return (
        <div>
        <div style={sidebarOpen ? { marginLeft: "10px", marginTop: "-10px" } : { marginLeft: "10px", marginTop: "-50px" }}>
            {/* <SmallLoader value={loaderModule} /> */}
            <View isOpen={createModule} size="sm" savetitle={modelButtonLbl} onClose={handleCloseModal} onSubmit={validateForm} title={`${modelTitle} Department`}>
                <FormLayout ref={customerFormRef} defaultState={state} setDefaultState={setState} dynamicForm={departmentForm} noOfColumn={1} onSubmit={onSubmitForm} />
            </View>
            <View centered={true} size={'sm'} isOpen={deleteModule} onClose={setDeleteModule} savetitle="Yes" onSubmit={onSubmitForm} title="Delete Batch">
                <h6 className="text-capitalize mb-1">Are you sure ?</h6>
            </View>
            <SimpleCard >
                <CustomTable buttonData={pageItems.create ? ['add'] : []} handleClick={handleClick} tableName={"Department"} columns={column} data={itemList} initialPage={1}  />
            </SimpleCard>
            </div>
        </div>
    )
};

export default Department;
