
const subjectEnrollmentForm = [
    {
        title: "Basic Information",
        formBody : [
            {
                formField: [
                    {
                        label: "Class - Section",
                        inputType: "select",
                        options: "enrollmentDetailsList",
                        uniqueKey:"enrollmentId",
                        displayKey:"classTitle",
                        subDisplayKey:"sectionTitle",
                        onChange: "onChangeEnrollment",
                        multiple:true,
                        fieldName:"enrollmentIds",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    }, 
                    {
                        label: "Subject",
                        inputType: "select",
                        options: "subjectList",
                        uniqueKey:"subjectId",
                        displayKey:"subjectTitle",
                        multiple:true,
                        fieldName:"subjectIds",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    },                                                                     
                ]
            }
        ]
    }
];

export {
    subjectEnrollmentForm
};