import React from 'react';
import { eventForm } from './formData';
import View from 'app/components/atoms/modalView/Modalview';
import CustomTable from 'app/components/atoms/custom-table/CustomTable';
import FormLayout from 'app/components/render/formlayout';
import SimpleCard from 'app/components/atoms/simple-card/SimpleCard';
import { FiEdit, FiTrash2 } from "react-icons/fi";
import { Button } from 'react-bootstrap';
import RichTextEditor from 'app/components/atoms/rich-text-editor/RichTextEditor';
import ReactQuill from "react-quill";
import { getEvent, createEvent, updateEvent } from "app/api/EventApi";
import { uploadFiles } from "app/api/DocumentApi";
import { createSyllabus, getSyllabus, updateSyllabus } from "app/api/SyllabusApi"
import { getSubject, getAsyncSubject } from "app/api/SubjectApi";
import { getStudentList, getStudentClassList, getAsyncStudentClassList, createStudentList, updateStudentList } from "app/api/StudentListApi";
import { pagePermission, showMessage } from "app/utils/app-functions";
import Alert from 'app/components/atoms/alert';
import _ from "lodash";
import swal from "sweetalert2";
import { imagePath, syllabus } from "app/utils/constant";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { MdRemoveRedEye } from 'react-icons/md';
import { asyncGetStudentListInfo } from 'app/api/StudentListApi';
import { useSidebar } from "app/components/sidebar/sidebarContext";


let editData = false;

const Syllabus = ({ navigation }) => {

    const dispatch = useDispatch();
    const customerFormRef = useRef();

    const getSyllabusSuccess = useSelector((state) => state.syllabusReducer.getSyllabusSuccess);
    const getSyllabusList = useSelector((state) => state.syllabusReducer.getSyllabusList);
    const getSyllabusFailure = useSelector((state) => state.syllabusReducer.getSyllabusFailure);

    const getStudentClassListSuccess = useSelector((state) => state.studentListReducer.getStudentClassListSuccess);
    const getStudentClassListList = useSelector((state) => state.studentListReducer.getStudentClassListList);
    const getStudentClassListFailure = useSelector((state) => state.studentListReducer.getStudentClassListFailure);

    const getSubjectSuccess = useSelector((state) => state.subjectReducer.getSubjectSuccess);
    const getSubjectList = useSelector((state) => state.subjectReducer.getSubjectList);
    const getSubjectFailure = useSelector((state) => state.subjectReducer.getSubjectFailure);
    const subjectErrorMessage = useSelector((state) => state.subjectReducer.errorMessage);

    const createSyllabusSuccess = useSelector((state) => state.syllabusReducer.createSyllabusSuccess);
    const createSyllabusData = useSelector((state) => state.syllabusReducer.createSyllabusData);
    const createSyllabusFailure = useSelector((state) => state.syllabusReducer.createSyllabusFailure);
    const syllabusErrorMessage = useSelector((state) => state.syllabusReducer.errorMessage);


    const updateSyllabusSuccess = useSelector((state) => state.syllabusReducer.updateSyllabusSuccess);
    const updateSyllabusData = useSelector((state) => state.syllabusReducer.updateSyllabusData);
    const updateSyllabusFailure = useSelector((state) => state.syllabusReducer.updateSyllabusFailure);

    const eventErrorMessage = useSelector((state) => state.eventReducer.errorMessage);
    const studentListErrorMessage = useSelector((state) => state.studentListReducer.errorMessage);
    const studentClassListErrorMessage = useSelector((state) => state.studentListReducer.errorMessage);



    const [pageItems, setpageItems] = useState({
    });

    useEffect(() => {
        let data = pagePermission(pageItems, "Student", "Syllabus")
        setpageItems(data)

    }, [navigation]);

    const column = [
        {
            "dataField": "",
            "text": "S.No",
            "sort": true,
            formatter: (cellContent, row, index) => {
                return index + 1
            }
        },
        {
            "dataField": "subjectName",
            "text": "Subject Name",
            "sort": true,
        },
        {
            "dataField": "class-section",
            "text": "Class",
            "sort": true,
        },
        {
            "dataField": "material",
            "text": "Material",
            "sort": true
        },
    ]

    if (pageItems.view || pageItems.update) {
        column.push({
            text: "Action",
            sort: false,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index, extraData) => (
                <div className="icon-container">

                    {pageItems.update && <FiEdit
                        className="text-primary cursor-pointer"
                        size={18}
                        onClick={() => onEditForm(row, index, extraData)}
                    ></FiEdit>}
                    {pageItems.view && <MdRemoveRedEye
                        className="text-success cursor-pointer"
                        size={18}
                        onClick={() => onDocView(row, index)}
                    ></MdRemoveRedEye>}
                    {pageItems.delete && <FiTrash2
                        className="text-danger cursor-pointer"
                        size={18}
                        onClick={() => {
                            swal
                                .fire({
                                    title: "Are you sure?",
                                    text: "You won't be able to revert this!",
                                    icon: "warning",
                                    type: "question",
                                    showCancelButton: true,
                                    confirmButtonColor: "#3085d6",
                                    cancelButtonColor: "#d33",
                                    confirmButtonText: "Yes, delete it!",
                                    cancelButtonText: "No",
                                })
                                .then((result) => {
                                    if (result.value) {
                                        onDeleteItem(row, index);
                                    } else {
                                        swal.fire({
                                            title: "Cancelled!",
                                            text: "Permission denied.",
                                            type: "error",
                                            icon: "error",
                                            timer: 1500,
                                        });
                                    }
                                });
                        }}
                    ></FiTrash2>}

                </div>
            )
        },)
    }

    const [state, setState] = useState({});
    const [createModule, setCreateModule] = useState(false);
    const [loaderModule, setLoaderModule] = useState(false);
    const [deleteModule, setDeleteModule] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [selectedItem, setSelectedItem] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);
    const [viewModule, setViewModule] = useState(false);
    const [content, setContent] = useState("");
    const [fileState, fileSetState] = useState({
        file: '',
        type: '',
        isImage: false,
    });

    // const listFetching = async () => {
    //     const requestData = {
    //         isActive: "true",
    //     }
    //     // let studentClassList = await asyncGetStudentListInfo(requestData)



    //   /*   setState({
    //         ...state,
    //         studentClassList: studentClassList?.data || [],


    //     }) */

    // }

    let userInfo = window.localStorage.getItem("userInfo");
    let parsedData = JSON.parse(userInfo);

    useEffect(() => {
        // setLoaderModule(true)
        const requestData = {
            isActive: "true",
        }
        // dispatch(getEvent(requestData))
        dispatch(getSyllabus(requestData))
        const empData = {
            isActive: "true",
            empCode: global.UserInfo?.userCode || ''
        }
        // dispatch(getStudentClassList(empData))
        // dispatch(getSubject(requestData))
        listFetching()
    }, [navigation]);


    const listFetching = async () => {

        const req1 = {
            isActive: "true",
        };
        let subjectList = await getAsyncSubject(req1);
        const empData = {
        }

        if (parsedData[0].roleId == 5) {
            empData.empCode = parsedData[0]?.userCode || '';
        }
        let studentClassList = await getAsyncStudentClassList(empData);

        setState({
            ...state,
            subjectList: subjectList?.data || [],
            studentClassList: studentClassList?.data || [],
        })

    }

    useEffect(() => {
        if (getSyllabusSuccess) {
            setItemList(getSyllabusList)
            setState({
                ...state,
                syllabusList: getSyllabusList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_SYLLABUS" })
        } else if (getSyllabusFailure) {
            setItemList([])
            showMessage("warning", eventErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_SYLLABUS" })
        }
    }, [getSyllabusSuccess, getSyllabusFailure]);


    useEffect(() => {
        if (getStudentClassListSuccess) {
            setState({
                ...state,
                studentClassList: getStudentClassListList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_STUDENT_CLASS_LIST" })
        } else if (getStudentClassListFailure) {
            setState({
                ...state,
                studentClassList: []
            })
            showMessage("warning", studentClassListErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_STUDENT_CLASS_LIST" })
        }
    }, [getStudentClassListSuccess, getStudentClassListFailure]);

    useEffect(() => {
        if (getSubjectSuccess) {
            setState({
                ...state,
                subjectList: getSubjectList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_SUBJECT" })
        } else if (getSubjectFailure) {
            setState({
                ...state,
                subjectList: []
            })
            showMessage("warning", subjectErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_SUBJECT" })
        }
    }, [getSubjectSuccess, getSubjectFailure]);

    useEffect(() => {
        if (createSyllabusSuccess) {
            const temp_state = [...itemList, createSyllabusData[0]];

            setItemList(temp_state)
            setLoaderModule(false)
            closeModule()
            Alert({  description: 'Created Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            dispatch({ type: "RESET_CREATE_SYLLABUS" })
        } else if (createSyllabusFailure) {
            setLoaderModule(false)
            showMessage("warning", syllabusErrorMessage)
            dispatch({ type: "RESET_CREATE_SYLLABUS" })
        }
    }, [createSyllabusSuccess, createSyllabusFailure]);


    useEffect(() => {
        if (updateSyllabusSuccess) {
            updateTable(updateSyllabusData[0])
            dispatch({ type: "RESET_UPDATE_SYLLABUS" })
        } else if (updateSyllabusFailure) {
            setLoaderModule(false)
            showMessage("warning", eventErrorMessage)
            dispatch({ type: "RESET_UPDATE_SYLLABUS" })
        }
    }, [updateSyllabusSuccess, updateSyllabusFailure]);

    const updateTable = (updatedItem) => {
        const temp_state = [...itemList];
        if (deleteModule) {
            let remainingList = _.remove(temp_state, function (n) {
                return n.syllabusId != updatedItem.syllabusId;
            });
            Alert({  description: 'Deleted Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(remainingList)
        } else {
            temp_state[selectedIndex] = updatedItem;
            Alert({  description: 'Updated Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(temp_state)
        }
        setLoaderModule(false)
        closeModule()
    }

    const closeModule = () => {
        editData = false;
        setCreateModule(false)
        setDeleteModule(false)
    }

    const resetState = () => {
        setContent([])
        setState({
            ...state,
            eventName: "",
            file: "",
            description: "",
            imageName: "",
            enrollmentId: "",
            subjectId: "",
            eventDate: new Date()

        })
    }

    const onViewForm = (data, index) => {
        const id = data.eventId
        setViewModule(true)
        editData = false;
        setState({
            ...state,
            eventIdValue: id
        })
        const requestData = {
            isActive: "true",
            // unitId: 1
        }
        dispatch(getEvent(requestData))
    }

    const handleEnrollmentId = (event) => {
        let id = event

        setState({
            ...state,
            enrollmentId: event

        })
    };
    const handleSubjectId = (event) => {
        let id = event

        setState({
            ...state,
            subjectId: id

        })
    };

    const handleContentChange = (content, aa, bbb, cc) => {
        console.log(aa);
        console.log(content);
        console.log(cc.getHTML);

        // const strippedContent = content.replace(/<[^>]*>/g, '').replace(/&nbsp;/g, ' ').replace(/\\/g, '').replace(/'/g, '');
        setContent(content)
        setState({
            ...state,
            description: content
        });
    };

    const formDataAppend = (data) => {
        const formData = new FormData();
        formData.append('document', data);
        dispatch(uploadFiles(formData, `${syllabus}`));
    }


    const onCreateForm = () => {
        resetState()
        eventForm[0].formBody[0].formField[1].disable = false;
        eventForm[0].formBody[0].formField[2].disable = false
        editData = false;
        setCreateModule(true)
    }

    const onEditForm = (data, index) => {
        eventForm[0].formBody[0].formField[1].disable = true;
        eventForm[0].formBody[0].formField[2].disable = true
        setState({
            ...state,
            enrollmentId: data?.enrollmentId || "",
            subjectId: data?.subjectId || ""


        })
        editData = true;
        setContent(data?.description)
        setSelectedIndex(index)
        setSelectedItem(data)
        setCreateModule(true)
    }

    const onDeleteItem = (data, index) => {
        editData = false;
        setSelectedIndex(index)
        setSelectedItem(data)
        setDeleteModule(true)
    }

    const validateForm = async () => {
        const validatefom = customerFormRef.current.validateForm();
    }

    const onWriteDocument = (event) => {

        let file = event.target.files[0];
        let fileName = file.name;
        if (fileName.split('.').pop() == 'pdf') {
            const now = new Date();
            const year = now.getFullYear().toString();
            const fileExtension = fileName?.split('.').pop() || "";
            const uniqueIdentifier = Math.floor(1000 + Math.random() * 9000).toString();
            const day = now.getDate().toString().padStart(2, '0');
            const docName = `${year}${day}${uniqueIdentifier}.${fileExtension}`;
            const renamedFile = new File(
                [file],
                docName,
                {
                    type: file?.type,
                    name: docName,
                    lastModified: file?.lastModified,
                }
            );
            // formDataAppend(renamedFile)
            setState({
                ...state,
                [event.target.name]: docName,
                file: renamedFile
            })
        }
        else {
            setState({
                ...state,
                [event.target.name]: ''
            })
            showMessage('warning', "upload pdf format")
        }

    };

    let isurl = ''
    function isValidURL(string) {
        try {
            isurl = new URL(string);
            return true;
        } catch (err) {
            return false;
        }
    }

    const onDocView = (data, index) => {
        let url;
        url = isValidURL(data?.material)
        if (data && !url) {
            let fileName = data?.material || '';
            const fileNameWithoutExt = fileName.split('.').slice(0, -1).join('.');
            const fileExtension = fileName.split('.').pop();
            const type = `${fileExtension}`;
            const file = `${imagePath}${syllabus}/${encodeURI(fileNameWithoutExt)}.${fileExtension}`;
            const imageFormats = ["png", "jpg", "jpeg", "bmp", "svg", "webp", "pdf"];
            const isImage = imageFormats.includes(type.toLowerCase());
            fileSetState({
                isDocument: isImage ? true : false,
                isVideo: type == 'mp4' ? true : false,
                file: file,
                type: type
            })
            setViewModule(true);
        } else if (url) {
            fileSetState({
                isDocument: false,
                file: data?.material || ''
            })
            setViewModule(true);
        }
        else {
            console.log("File not found for ID:");
        }
    };


    const onClose = () => {
        setViewModule(false)
    }

    const onError = e => {
        console.log(e, "error in file-viewer");
    };


    const onSubmitForm = () => {
        const requestDate = {
            subjectId: state?.subjectId || 0,
            description: state?.description || "",
            enrollmentId: state?.enrollmentId || 0,
            material: state.isUrl ? (state?.url || state.imageName) : (state?.imageName || state.url),
        }


        // return

        let current = state.file || ""
        let selectedMaterial = selectedItem?.material || ""
        if (current.name === selectedMaterial) {
            setState({
                ...state,
                file: ""
            })
        } else if (current == "") {
            setState({
                ...state,
                file: ""
            })
        } else {
            formDataAppend(state?.file || "")
        }

        function validateFormData(requestData) {
            for (let key in requestData) {
                if (requestData.hasOwnProperty(key) && key !== "middleName" && key !== "emisNumber") {
                    if (
                        requestData[key] === null ||
                        requestData[key] === undefined ||
                        requestData[key] === "" ||
                        requestData[key] === "0" ||
                        requestData[key] === 0 ||
                        (typeof requestData[key] === "string" && requestData[key].trim() === "") ||
                        (typeof requestData[key] === "number" && isNaN(requestData[key]))
                    ) {
                        return false;
                    }
                }
            }
            return true;
        }
        console.log(JSON.stringify(requestDate));
        // return

        if (editData) {
            setLoaderModule(true)
            dispatch(updateSyllabus(requestDate, selectedItem.syllabusId))
        } else if (deleteModule) {
            const deleteRequest = {
                isActive: "false"
            }
            setLoaderModule(true)
            dispatch(updateSyllabus(deleteRequest, selectedItem.syllabusId))
        } else {
            setLoaderModule(true)
            dispatch(createSyllabus(requestDate))
        }

    }
    const getYouTubeEmbedURL = (url) => {
        try {
            const parsedURL = new URL(url);
            const videoId = parsedURL.searchParams.get('v');
            if (videoId) {
                return `https://www.youtube.com/embed/${videoId}`;
            } else {
                throw new Error('Invalid YouTube URL: Video ID not found');
            }
        } catch (err) {
            console.error(err.message);
            return '';
        }
    }
    let url = fileState?.file || ''
    const embedURL = getYouTubeEmbedURL(url);
    <h1 style={{ fontStyle: "initial" }}></h1>
    const modelTitle = editData ? "Edit " : "Create ";
    const modelButtonLbl = editData ? "" : "Save";

    const handleClick = (buttonName) => {
        if (buttonName == 'Add') {
            onCreateForm();
        }
    };
    const { sidebarOpen } = useSidebar();

    return (
        <div>
            <div style={sidebarOpen ? { marginLeft: "10px", marginTop: "-10px" } : { marginLeft: "10px", marginTop: "-50px" }}>

                <View isOpen={createModule} size="lg" savetitle={modelButtonLbl} onClose={setCreateModule} onSubmit={onSubmitForm} title={`${modelTitle} Syllabus`}>
                    <FormLayout ref={customerFormRef} defaultState={state} setDefaultState={setState} dynamicForm={eventForm} noOfColumn={2} onChangeCallback={{ "handleEnrollmentId": handleEnrollmentId, "handleSubjectId": handleSubjectId, "onWriteDocument": onWriteDocument }} />
                    <RichTextEditor
                        toolbarClassName="demo-toolbar"
                        editorClassName="demo-editor"
                        content={content}
                        placeholder="Enter text here..."
                        handleContentChange={handleContentChange}
                        onSubmit={validateForm}
                        value={content}
                    />
                </View>
                <View centered={true} size={'lg'} /* fullscreen={(fileState.isDocument) ? false : true} */ savetitle="Close" isOpen={viewModule} onClose={setViewModule} onSubmit={onClose} title="Syllabus">
                    <div className="overflow-auto" style={fileState.isDocument ? { height: "500px" } : { width: "auto", height: "auto" }}>
                        {
                            (fileState.isDocument || fileState.isVideo) ? <object data={fileState?.file} type="application/pdf" width="100%" height="100%" allowFullScreen={true}>
                                <a href={fileState?.file}></a>
                            </object> : <iframe width="100%" height="500" src={embedURL} title="YouTube video player" /* frameborder="0" */ allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen={true}></iframe>
                        }
                    </div>
                </View>
                <View centered={true} size={'sm'} isOpen={deleteModule} onClose={setDeleteModule} savetitle="Yes" onSubmit={onSubmitForm} title="Delete Book">
                    <h6 className="text-capitalize mb-1">Are you sure ?</h6>
                </View>
                <SimpleCard>
                    <CustomTable columns={column} data={itemList} buttonData={pageItems.create ? ['add'] : []} handleClick={handleClick} tableName={"Syllabus"} state={state} />
                </SimpleCard>

            </div>
        </div>
    )
}

export default Syllabus;