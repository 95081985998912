
const materialForm = [
    {
        title: "",
        formBody : [
            {
                title: "",
                formField: [
                    {
                        label: "Material Code",
                        inputType: "text",                        
                        placeholder: "Enter Material Code",
                        fieldName:"materialCode",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Material Name",
                        inputType: "text",                        
                        placeholder: "Enter Material Name",
                        fieldName:"materialName",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },    
                    {
                        label: "Description",
                        inputType: "textarea",
                        placeholder: "Enter description",
                        fieldName:"description",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "HSN number",
                        inputType: "text",
                        placeholder: "Enter HSN Number",
                        fieldName:"hsnNo",
                        defaultValue: "", 
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Material Type",
                        inputType: "select",
                        options: "materialTypeList",
                        onChange:"materialId",
                        uniqueKey: "materialTypeId",
                        displayKey: "materialName",
                        multiple: false,
                        fieldName: "materialTypeId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                   /*  {
                        label: "Tax",
                        inputType: "select",
                        options: "taxList",
                        uniqueKey: "id",
                        displayKey: "name",
                        multiple: false,
                        fieldName: "taxId",
                        defaultValue: "",
                        config: {
                            // required: "required",
                        },
                    }   */                                 
                ]
            }            
        ]
    }
];

export {
    materialForm
};