
// const spLoginAPI = "access-management/sp-login";
const spLoginAPI = "access-management/login";
// const userLogin = "access-management/login";
const dashboard= "home-management/dashboard"
const permission = "access-management/permission"
const userDetails = "/access-management/user"
const userPermission = "access-management/user-permission"
const serviceProviderDetails = "access-management/service-provider-details";

const uploadFile = "/document-management/file/upload"
const uploadFileS3 = "/document-management/file/s3/upload"
const uploadDocuments = "/document-management/document/upload"
const uploadStreams = "/document-management/document/stream"

const department="employee-management/department"
const designation="employee-management/designation"
const salaryType="employee-management/salary-type"
const employeeProof="employee-management/employee-proof"
const employeeDocument="employee-management/employee-document"

const employee="employee-management/employee"
const employeeReport="employee-management/employee-report"
const employeeReporting ="/employee-management/employee-reporting"
const employeeDetails="employee-management/employee-details"
const employeeBasicInfo="employee-management/basic-info"

const city="employee-management/city"
const leaveMeta="employee-management/leave-meta"
const shift="employee-management/shift"
const nationality="employee-management/nationality"
const roles = "access-management/roles";
const bloodGroup="employee-management/blood-group"
const qualification="employee-management/qualification"
const deleteQualification="employee-management/employee-qualification"
const motherTongue="employee-management/mother-tongue"
const state="employee-management/state"
const country="inventory-management/country"
const gender="employee-management/gender"
const proofType="employee-management/proof-type"
const employeeLeave="employee-management/employee-leave"
const leaveStatus="employee-management/leave-status"
const employeeType="employee-management/employee-type"
const holidays="employee-management/holidays"
const user="employee-management/user"
const leaveType="employee-management/leave-type"
const leaveSettings="employee-management/leave-settings"
const employeeAttendance="employee-management/employee-attendance"
const employeeAttendanceDetails="employee-management/employee-attendance-details"
const deleteJobHistory="/employee-management/job-history"
const employeePromotion="/employee-management/promotion"
const workingDays="/employee-management/working-days"
const month="/employee-management/month"
const otherLeave ="/employee-management/other-leave"
const leaveBalance="/employee-management/employee-leavebalance-report"
const syllabus ="/student-management/syllabus"
const student="student-management/student"
const studentDetails="student-management/student-details"
const employePerformance="student-management/employee-teaching-performance"
const studentPerformance="student-management/student-performance"
const caste="student-management/caste"
const subCaste="student-management/subcaste"
const religion="student-management/religion"
const classs="student-management/class"
const batch="student-management/batch"
const enrollmentDetails="student-management/enrollment-details"
// const studentStatus="student-management/student-status"
const studentStatus="/student-management/admission-status"
const section="student-management/section"
const studentAttendance="student-management/student-attendance"
const studentAttendanceType="student-management/student-attendance-type"
const exam="student-management/exam"
const examType="student-management/exam-type"
const dailyExam ="/student-management/daily-exam"
const subject="student-management/subject"
const homework="student-management/homework"
const analytics="student-management/student-analytics"
const examAnalytics="student-management/student-analytics"
const homeWorkType="student-management/homework-type"
const studentEnrollment="student-management/student-enrollment"
const studentEnrollmentDetails="student-management/student-enrollment-details"
const course="student-management/course"
const periodTime="student-management/period-time"
const enrollment="student-management/enrollment"
const grade="student-management/grade"
const subjectEnrollment="student-management/subject-enrollment"
const markSheet="student-management/mark-sheet"
const studentClassList ="/student-management/student-class-list"
const studentList = "/student-management/student-list-service"
const examList="/student-management/exam-list"
const subjectTeachers="/student-management/teachers"
const timeTable="/student-management/time-table"
const timeTableInfo="/student-management/time-table-info"
const role = "/access-management/role"
const access="/student-management/access"
const periodDays="/student-management/period-days"
const feesType="/student-management/fees-type"
const feesEnrollment="/student-management/fees-enrollment"
const studentFeesPaymentDetails="/student-management/student-fees-payment-details"
const studentFeesPayment="/student-management/student-fees-payment"
const unit="/unit-management/units"
const unitFilter ="/unit-management/unit-filter"
const unitType ="/unit-management/unit-type"
const circular="/student-management/circular"
const assignType="student-management/assign-type"
const schoolInfo="student-management/school-info"
const knowledge ="/student-management/knowledge-material"
const examVariant ="/student-management/exam-variant-type"
const knowledgeMaterialType ="/student-management/knowledge-material-type"
const knowledgeBookType ="/student-management/knowledge-book-type"
const event ="employee-management/event"
const enquiry ="employee-management/enquiry"
const enquiryType="employee-management/enquiry-type"
const examStatus="/student-management/exam-status"
const studentLeave="/student-management/student-leave"
const schoolDashboard="/employee-management/dashboard"
const books ="/student-management/books"
const bookType ="/student-management/book-type"
const dashboardAttendance ="employee-management/attendance"
const quiz="/student-management/quiz"

//Inventory Mangement
const pettyCash = "/inventory-management/petty-cash"
const pettyCashType = "/inventory-management/petty-cash-type"
const pettyCashDetails = "/inventory-management/petty-cash-details"
const currency = "/inventory-management/currency"


const location = "/inventory-management/location"
const materialType = "inventory-management/material-type"
const warehouse = "/inventory-management/warehouse"
const customerInventory = "inventory-management/customer"
const customerOrder = "inventory-management/customer/order"
const brand = "inventory-management/brand";
const category = "inventory-management/category";
const material = "/inventory-management/material";
const availableMaterial = "/inventory-management/material"
const materialInfo = "inventory-management/material-info";
const supplier = "inventory-management/supplier"
const priceGroup = "inventory-management/pricegroup"
const tax = "inventory-management/tax"
const sales = "/inventory-management/salesorder"
const measurement = "/inventory-management/unit"
const materialCode = "inventory-management/material-info"
const quotation = "/inventory-management/salesorder"
const delivery = "/inventory-management/salesorder"
const taxCode = "/inventory-management/tax"
const purchase = "/inventory-management/purchase/order"
const goodsReceived = "/inventory-management/goodsreceived";
const inventoryMaterial = "/inventory-management/materials"
const inventoryTransfer = "/inventory-management/inventory-transfer"
const leadSourceType = "project-management/lead-source-type";
const creditTerm = "inventory-management/creditTerm"
const invoice = "/inventory-management/salesorder"
const receiptList = "/inventory-management/customerreceipt"
const payment = "/inventory-management/payment-method"
const documentType = "inventory-management/document-type"
const creditNotes = "/inventory-management/creditnotes"
const inventoryAdjustment = "/inventory-management/inventory-adjustment"
const showMaterial="/inventory-management/material"
const taxConfig = "/inventory-management/tax-config"



const visitorType="visitor-management/visitor-type"
const visitor = "visitor-management/visitor"
const visitorLog = ""





export {
    examAnalytics,
    location,
    materialType,
    warehouse,
    customerInventory,
    customerOrder,
    brand,
    category,
    material,
    availableMaterial,
    currency,
    materialInfo,
    supplier,
    priceGroup,
    tax,
    sales,
    measurement,
    materialCode,
    quotation,
    delivery,
    taxCode,
    purchase,
    goodsReceived,
    inventoryMaterial,
    inventoryTransfer,
    leadSourceType,
    creditTerm,
    invoice,
    receiptList,
    payment,
    documentType,
    creditNotes,
    inventoryAdjustment,
    showMaterial,
    spLoginAPI,
    // userLogin,
    otherLeave,
    studentFeesPaymentDetails,
    studentFeesPayment,
    knowledgeBookType,
    bookType,
    uploadFileS3,
    books,
    examStatus,
    schoolDashboard,
    userDetails,
    examVariant,
    examList,
    syllabus,
    feesType,
    periodDays,
    feesEnrollment,
    access,
    timeTable,
    dailyExam,
    markSheet,
    subjectEnrollment,
    timeTableInfo,
    subjectTeachers,
    studentEnrollment,
    studentEnrollmentDetails,
    role,
    roles,
    userPermission,
    periodTime,
    // studentClassList,
    exam,
    examType,
    grade,
    homework,
    enrollment,
    homeWorkType,
    subject,
    caste,
    course,
    deleteJobHistory,
    studentStatus,
    studentAttendance,
    religion,
    permission,
    // studentList,
    batch,
    workingDays,
    studentDetails,
    student,
    classs,
    dashboard,
    studentAttendanceType,
    leaveBalance,
    month,
    section,
    employeeDocument,
    enrollmentDetails,
    employeePromotion,
    deleteQualification,
    employeeProof,
    user,
    proofType,
    analytics,
    leaveSettings,
    serviceProviderDetails,
    employeeLeave,
    employeeType,
    leaveMeta,
    employeeReport,
    employeeAttendance,
    leaveStatus,
    employeeAttendanceDetails,
    unit,
    unitFilter,
    unitType,
    //home-management
    city,
    nationality,
    bloodGroup,
    qualification,
    leaveType,
    holidays,
    subCaste,
    motherTongue,
    state,
    country,
    gender,
    shift,
    circular,
    assignType,
    schoolInfo,
    studentLeave,

    knowledge ,
    knowledgeMaterialType,
    //employee-mamangement
    department,
    designation,
    salaryType,
    employee,
    employeeDetails,
    employeeReporting,
    employeeBasicInfo,
    visitorLog,
    //document-management
    uploadFile,
    uploadDocuments,
    uploadStreams,
    // /event
    event,
    // enquiry
    enquiry,
    enquiryType,
    studentClassList,
    studentList,
    dashboardAttendance,
    // quiz
    quiz,
    //Inventory
    pettyCash,
    pettyCashDetails,
    pettyCashType,
    visitorType,
    visitor,
    taxConfig,
    employePerformance,
    studentPerformance
}
