import React from 'react';
import { employeeLeaveForm, assignStatusForm, employeeLeaveEditForm, employeeAttendanceFilterForm } from './formData';
import View from 'app/components/atoms/modalView/Modalview';
import CustomTable from 'app/components/atoms/custom-table/CustomTable';
import FormLayout from "app/components/render/formlayout";
import SimpleCard from 'app/components/atoms/simple-card/SimpleCard';
import { FiEdit, FiTrash2 } from "react-icons/fi";
import { Button } from 'react-bootstrap';
import _ from "lodash";
import formRender from "app/components/render/formcomponent";
import { getShift } from "app/api/EmployeeShiftApi";
import { getDepartment } from "app/api/Department";
import { createEmployeeAttendance, getEmployeeAttendance, updateEmployeeAttendance } from "app/api/EmployeeAttendanceApi";
import { dateConversion, pagePermission, showMessage, timeConversion } from "app/utils/app-functions";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Alert from 'app/components/atoms/alert';
import { asyncGetShiftInfo } from 'app/api/EmployeeShiftApi';
import { asyncGetDepartmentInfo } from 'app/api/Department';
import { useSidebar } from "app/components/sidebar/sidebarContext";
import Loading from 'app/components/atoms/loading';
import { lightTheme } from 'styles/theme';

const ExcelJS = require('exceljs');

let editData = false;
let searchData = false;

const AttendanceReport = ({ navigation }) => {

    const dispatch = useDispatch();
    const customerFormRef = useRef();

    const getEmployeeAttendanceSuccess = useSelector((state) => state.employeeAttendanceReducer.getEmployeeAttendanceSuccess);
    const getEmployeeAttendanceList = useSelector((state) => state.employeeAttendanceReducer.getEmployeeAttendanceList);
    const getEmployeeAttendanceFailure = useSelector((state) => state.employeeAttendanceReducer.getEmployeeAttendanceFailure);

    const getDepartmentSuccess = useSelector((state) => state.departmentReducer.getDepartmentSuccess);
    const getDepartmentList = useSelector((state) => state.departmentReducer.getDepartmentList);
    const getDepartmentFailure = useSelector((state) => state.departmentReducer.getDepartmentFailure);
    const departmentErrorMessage = useSelector((state) => state.departmentReducer.errorMessage);

    const getShiftSuccess = useSelector((state) => state.shiftReducer.getShiftSuccess);
    const getShiftList = useSelector((state) => state.shiftReducer.getShiftList);
    const getShiftFailure = useSelector((state) => state.shiftReducer.getShiftFailure);
    const shiftErrorMessage = useSelector((state) => state.shiftReducer.errorMessage);

    const employeeAttendanceErrorMessage = useSelector((state) => state.employeeAttendanceReducer.errorMessage);
    const [pageItems, setpageItems] = useState({});

    useEffect(() => {
        let data = pagePermission(pageItems, "Report", "Employee Report")
        setpageItems(data)

    }, [navigation]);

    const column = [
        {
            "dataField": "name",
            "text": "Employee Name",
            "sort": true,
            formatter: (cellContent, row, index) => (
                row.firstName + " " + row.lastName
            )
        },
        {
            "dataField": "checkIn",
            "text": "Check In",
            "sort": true,
           
        },
        {
            "dataField": "checkOut",
            "text": "Check Out",
            "sort": true,
            
        },
        {
            dataField: "checkOut",
            text: "Status",
            sort: true,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => {
                // console.log(getAttendance(row));
                
                const conInfo = { inputType: "checkInStatus", statusName: getAttendance(row), status: cellContent }
                return formRender(null, null, conInfo, null);
            }
        },
        {
            "dataField": "markedBy",
            "text": "Marked By",
            "sort": true
        },

    ]

    const [state, setState] = useState({
        departmentId: "",
        // attendanceDate: new Date(),
        departmentId: "",
        shiftList: [],
        departmentList: []
    });

    const [createModule, setCreateModule] = useState(false);
    const [loaderModule, setLoaderModule] = useState(false);
    const [editModule, setEditModule] = useState(false);
    const [viewModule, setViewModule] = useState(false);
    const [deleteModule, setDeleteModule] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [selectedItem, setSelectedItem] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);


    const listFetching = async () => {
        const requestData = {
            isActive: "true",
        }
        let shiftList = await asyncGetShiftInfo(requestData)
        let departmentList = await asyncGetDepartmentInfo(requestData)



        setState({
            ...state,
            shiftList: shiftList?.data || [],
            departmentList: departmentList?.data || [],

        })

    }


    useEffect(() => {
        setLoaderModule(true)
        const req = {
            attendanceDate: dateConversion(new Date(), "YYYY-MM-DD"),
            shiftId: 1
        }
        dispatch(getEmployeeAttendance(req))
        const req2 = {
            isActive: true
        }
        /*  dispatch(getShift(req2))
         dispatch(getDepartment(req2)) */
        listFetching()
    }, [navigation]);

    useEffect(() => {
        if (getEmployeeAttendanceSuccess) {
            setItemList(getEmployeeAttendanceList)
            if (getEmployeeAttendanceList.length > 0) {
                if (searchData) {
                    showMessage("success", "Searched SuccessFully")
                }
            } else {
                showMessage("warning", "No Data Found")
            }

            setLoaderModule(false)
            dispatch({ type: "RESET_GET_EMPLOYEE_ATTENDANCE" })
        } else if (getEmployeeAttendanceFailure) {
            setItemList([])
            showMessage("warning", employeeAttendanceErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_EMPLOYEE_ATTENDANCE" })
        }
    }, [getEmployeeAttendanceSuccess, getEmployeeAttendanceFailure]);

    useEffect(() => {
        if (getShiftSuccess) {
            setState({
                ...state,
                shiftList: getShiftList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_SHIFT" })
        } else if (getShiftFailure) {
            setState({
                ...state,
                shiftList: []
            })
            showMessage("warning", shiftErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_SHIFT" })
        }
    }, [getShiftSuccess, getShiftFailure]);

    useEffect(() => {
        if (getDepartmentSuccess) {
            setState({
                ...state,
                departmentList: getDepartmentList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_DEPARTMENT" })
        } else if (getDepartmentFailure) {
            setState({
                ...state,
                departmentList: []
            })
            showMessage("warning", departmentErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_DEPARTMENT" })
        }
    }, [getDepartmentSuccess, getDepartmentFailure]);

    const getAttendance = (info) => {
        let status = ""
        if (info.checkIn === null) {
            status = "Not Checked"
        } else if (info.checkOut) {
            status = "Checked Out"
        } else if (info.checkIn != null) {
            status = "Checked In"
        }
        return status
    }

    const onSearch = () => {
        let selectedShiftId = state?.shiftId || 0
        let selectedDepartmentId = state?.departmentId || ""
        if (state.attendanceDate == null) {
            Alert({ description: 'Please Select Date', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });

            setItemList([])
            return false
        }
        var requestData = {};
        if (selectedShiftId != 0) {
            requestData.shiftId = state.shiftId;
            requestData.departmentId = selectedDepartmentId == 0 ? "" : state.departmentId;
            requestData.isActive = true
        } else if (selectedDepartmentId === "" || selectedDepartmentId === 0) {
            requestData.shiftId = state.shiftId;
            requestData.isActive = true
        } else {
            requestData.departmentId = selectedDepartmentId;
            requestData.isActive = true
        }
        requestData.attendanceDate = dateConversion(state.attendanceDate, "YYYY-MM-DD")
        let shift = requestData.shiftId;
        let dept = requestData.departmentId;
        if (shift === undefined || shift === 0 || shift === ' ' || dept === undefined || dept === 0) {
            Alert({ description: 'Please Select Shift/Department', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList([]);
            return false;
        } else {
            setLoaderModule(true);
            dispatch(getEmployeeAttendance(requestData));
            searchData = true;
        }
    }

    const onClear = () => {
        setState({
            ...state,
            departmentId: "",
            shiftId: "",
            attendanceDate: ''
        })
        setItemList([])
        Alert({ description: 'Cleared Successfully', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });

    }

    const exportExcel = () => {
        // alert(itemList.length > 0)

        if (itemList.length == 0) {
            Alert({ description: 'No Data found to Export Excel', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            return false
        }

        const workbook = new ExcelJS.Workbook();
        const sheet = workbook.addWorksheet('My Sheet');
        sheet.properties.defaultRowHeight = 20;

        sheet.columns = [
            {
                header: "Employee Name",
                key: "employeeName",
                width: 30,
            },
            {
                header: "Check In Date",
                key: "checkInDate",
                width: 30,
            },
            {
                header: "Check Out Date",
                key: "checkOutDate",
                width: 30,
            },
            {
                header: "Check In Time",
                key: "checkInTime",
                width: 30,
            },
            {
                header: "Check Out Time",
                key: "checkOutTime",
                width: 30,
            },
            {
                header: "Marked By",
                key: "markedBy",
                width: 30,
            },
        ]

        itemList.map((item, index) => {
            sheet.addRow({
                employeeName: item?.firstName + item?.lastName || "-",
                checkInDate: item.checkInDate ? dateConversion(item.checkInDate, "YYYY-MM-DD") : "-",
                checkOutDate: item.checkOutDate ? dateConversion(item.checkOutDate, "YYYY-MM-DD") : "-",
                checkInTime: item.checkIn ? timeConversion(item.checkIn, "hh:mm:ss") : "-",
                checkOutTime: item.checkOut ? timeConversion(item.checkOut, "hh:mm:ss") : "-",
                markedBy: item?.markedBy || "-",
            })
            sheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
                row.eachCell(function (cell, colNumber) {
                    cell.font = {
                        name: 'Arial',
                        family: 2,
                        bold: false,
                        size: 10,
                    };
                    cell.alignment = {
                        vertical: 'middle', horizontal: 'center'
                    };
                    if (rowNumber <= 10) {
                        row.height = 20;
                        cell.font = {
                            bold: true,
                        };
                    }
                    if (rowNumber >= 10) {
                        for (var i = 1; i < 14; i++) {
                            if (rowNumber == 10) {
                                row.getCell(i).fill = {
                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'C7C7C7' }
                                };
                            }
                            row.getCell(i).border = {
                                top: { style: 'thin' },
                                left: { style: 'thin' },
                                bottom: { style: 'thin' },
                                right: { style: 'thin' }
                            };
                        }
                    }
                });
            });
        });

        workbook.xlsx.writeBuffer().then(data => {
            const blob = new Blob([data],
                {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheet.sheet",
                });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = 'Employee-Attendance-Report.xlsx';
            anchor.click();
            window.URL.revokeObjectURL(url);
        })
    }
    const { sidebarOpen } = useSidebar();

    const handleClick = (buttonName) => {
        if (buttonName == 'Excel') {
            exportExcel();
        }
    };
    return (
        <div>
            <Loading status={loaderModule} />

            <div style={sidebarOpen ? { marginLeft: "50px", marginTop: "50px", marginBottom: "-20px" } : { marginLeft: "90px", marginTop: "50px", marginBottom: "-50px" }}>
                <FormLayout defaultState={state} setDefaultState={setState} dynamicForm={employeeAttendanceFilterForm} noOfColumn={4} />
                <Button onClick={onSearch} className='mr-2' style={{ backgroundColor: `${lightTheme.primaryColor}`, border: "none", fontSize: "12px", marginTop: "-20px" }}>Search</Button>
                <Button onClick={onClear} style={{ backgroundColor: "white", color: "gray", border: "1px solid gray", fontSize: "12px", marginTop: "-20px" }}>Clear</Button>
            </div>
            <SimpleCard >
                <CustomTable columns={column} handleClick={handleClick} buttonData={['excel']} data={itemList} tableName={"Employee Attendance Report"} state={state} />
            </SimpleCard>


        </div>
    )
}

export default AttendanceReport;