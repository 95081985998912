import * as yup from "yup";
import { Formik } from "formik";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import DateTime from "react-datetime";
import { Form, Button } from "react-bootstrap";
import { DateRange } from "react-date-range";
import SimpleCard from 'app/components/atoms/simple-card/SimpleCard'
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { MdEdit, MdDelete } from "react-icons/md";
import swal from "sweetalert2";
import _ from "lodash";
import { pagePermission, showMessage } from "app/utils/app-functions";
import FormLayout from "app/components/render/formlayout";
import View from "app/components/atoms/modalView/Modalview";
import CustomTable from "app/components/atoms/custom-table/CustomTable";
import formRender from "app/components/render/formcomponent";
import { sectionForm } from "./formData";
import { createSection, getSection, updateSection } from "app/api/SectionApi";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import Alert from "app/components/atoms/alert";
import { useSidebar } from "app/components/sidebar/sidebarContext";

let editData = false;

const Section = ({ navigation }) => {
    const dispatch = useDispatch();
    const customerFormRef = useRef();

    const getSectionSuccess = useSelector((state) => state.sectionReducer.getSectionSuccess);
    const getSectionList = useSelector((state) => state.sectionReducer.getSectionList);
    const getSectionFailure = useSelector((state) => state.sectionReducer.getSectionFailure);

    const createSectionSuccess = useSelector((state) => state.sectionReducer.createSectionSuccess);
    const createSectionData = useSelector((state) => state.sectionReducer.createSectionData);
    const createSectionFailure = useSelector((state) => state.sectionReducer.createSectionFailure);

    const updateSectionSuccess = useSelector((state) => state.sectionReducer.updateSectionSuccess);
    const updateSectionData = useSelector((state) => state.sectionReducer.updateSectionData);
    const updateSectionFailure = useSelector((state) => state.sectionReducer.updateSectionFailure);

    const sectionErrorMessage = useSelector((state) => state.sectionReducer.errorMessage);

    const [pageItems, setpageItems] = useState({});

    useEffect(() => {
        let data = pagePermission(pageItems, "Masters", "Section")
        setpageItems(data)

    }, [navigation]);

    const column = [
        {
            "dataField": "sNo",
            "text": "S.No",
            "sort": true,
            formatter: (cellContent, row, index) => (
                index + 1
            )
        },
        {
            "dataField": "sectionTitle",
            "text": "Section Name",
            "sort": true
        },
        {
            dataField: "isActive",
            text: "Status",
            sort: true,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => {
                const conInfo = { inputType: "status", status: cellContent }
                return formRender(null, null, conInfo, null);
            }
        }
    ]

    if (pageItems.delete || pageItems.update) {
        column.push({
            text: "Action",
            sort: false,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => (
                <div className="icon-container">
                    {pageItems.update && <FiEdit
                        className="text-primary cursor-pointer"
                        size={18}
                        onClick={() => onEditForm(row, index)}
                    ></FiEdit>}
                    {pageItems.delete && <FiTrash2
                        className="text-danger cursor-pointer"
                        size={18}
                        onClick={() => {
                            onDeleteItem(row, index);
                        }}
                    ></FiTrash2>}
                </div>
            )
        })
    }

    const [state, setState] = useState({
        sectionTitle: ""
    });

    const [createModule, setCreateModule] = useState(false);
    const [loaderModule, setLoaderModule] = useState(false);
    const [editModule, setEditModule] = useState(false);
    const [viewModule, setViewModule] = useState(false);
    const [deleteModule, setDeleteModule] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [selectedItem, setSelectedItem] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);

    useEffect(() => {
        setLoaderModule(true)
        const requestData = {
            isActive: "true"
        }
        dispatch(getSection(requestData))
    }, [navigation]);

    const confromDialog = () => {
        return (
            swal
                .fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    type: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!",
                    cancelButtonText: "No",
                })
                .then((result) => {
                    if (result.value) {
                        onSubmitForm()
                    } else {
                        setDeleteModule(false)
                        swal.fire({
                            title: "Cancelled!",
                            text: "Permission denied.",
                            type: "error",
                            icon: "error",
                            timer: 1500,
                        });
                    }
                })
        )
    }

    useEffect(() => {
        if (deleteModule) {
            confromDialog()
        }
    }, [deleteModule])

    useEffect(() => {
        if (getSectionSuccess) {
            setItemList(getSectionList)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_SECTION" })
        } else if (getSectionFailure) {
            setItemList([])
            showMessage("warning", sectionErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_SECTION" })
        }
    }, [getSectionSuccess, getSectionFailure]);

    useEffect(() => {
        if (createSectionSuccess) {
            const temp_state = [...itemList, createSectionData[0]];
            setItemList(temp_state)
            setLoaderModule(false)
            Alert({ description: 'Created Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            closeModule()
            dispatch({ type: "RESET_CREATE_SECTION" })
        } else if (createSectionFailure) {
            setLoaderModule(false)
            showMessage("warning", sectionErrorMessage?.message)
            dispatch({ type: "RESET_CREATE_SECTION" })
        }
    }, [createSectionSuccess, createSectionFailure]);

    useEffect(() => {
        if (updateSectionSuccess) {
            updateTable(updateSectionData[0])
            dispatch({ type: "RESET_UPDATE_SECTION" })
        } else if (updateSectionFailure) {
            setLoaderModule(false)
            showMessage("warning", sectionErrorMessage?.message)
            dispatch({ type: "RESET_UPDATE_SECTION" })
        }
    }, [updateSectionSuccess, updateSectionFailure]);

    const updateTable = (updatedItem) => {
        const temp_state = [...itemList];
        if (deleteModule) {
            let remainingList = _.remove(temp_state, function (n) {
                return n.sectionId != updatedItem.sectionId;
            });
            Alert({ description: 'Deleted Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(remainingList)
        } else {
            temp_state[selectedIndex] = updatedItem;
            Alert({ description: 'Updated Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(temp_state)
        }
        setLoaderModule(false)
        closeModule()
    }

    const closeModule = () => {
        editData = false;
        setCreateModule(false)
        setDeleteModule(false)
    }

    const resetState = () => {
        setState({
            ...state,
            sectionTitle: ""
        })
    }

    const onCreateForm = () => {
        resetState()
        editData = false;
        setCreateModule(true)
    }

    const onEditForm = (data, index) => {
        setState({
            ...state,
            sectionTitle: data?.sectionTitle || ""
        })
        editData = true;
        setSelectedIndex(index)
        setSelectedItem(data)
        setCreateModule(true)
    }

    const onDeleteItem = (data, index) => {
        editData = false;
        setSelectedIndex(index)
        setSelectedItem(data)
        setDeleteModule(true)
    }

    const validateForm = async () => {
        const validatefom = customerFormRef.current.validateForm();
    }

    const onSubmitForm = () => {
        const requestDate = {
            sectionTitle: sectionTitle
        }
        if (editData) {
            setLoaderModule(true)
            dispatch(updateSection(requestDate, selectedItem.sectionId))
        } else if (deleteModule) {
            const deleteRequest = {
                isActive: false
            }
            setLoaderModule(true)
            dispatch(updateSection(deleteRequest, selectedItem.sectionId))
        } else {
            setLoaderModule(true)
            dispatch(createSection(requestDate))
        }

    }
    const handleCloseModal = () => {
        setCreateModule(false);
    };

    const handleClick = (buttonName) => {
        if (buttonName == 'Add') {
            onCreateForm();
        }
    };

    const {
        sectionTitle
    } = state;

    const modelTitle = editData ? "Edit " : "Create ";
    const modelButtonLbl = editData ? "" : "Save";
    const { sidebarOpen } = useSidebar();

    return (
        <div>
        <div style={sidebarOpen ? { marginLeft: "10px", marginTop: "-10px" } : { marginLeft: "10px", marginTop: "-50px" }}>
                {/* <SmallLoader value={loaderModule} /> */}
                <View isOpen={createModule} size="sm" savetitle={modelButtonLbl} onClose={handleCloseModal} onSubmit={validateForm} title={`${modelTitle} Section`}>
                    <FormLayout ref={customerFormRef} defaultState={state} setDefaultState={setState} dynamicForm={sectionForm} noOfColumn={1} onSubmit={onSubmitForm} />
                </View>
                <View centered={true} size={'sm'} isOpen={deleteModule} onClose={setDeleteModule} savetitle="Yes" onSubmit={onSubmitForm} title="Delete Section">
                    <h6 className="text-capitalize mb-1">Are you sure ?</h6>
                </View>
                <SimpleCard >
                    <CustomTable buttonData={pageItems.create ? ['add'] : []} handleClick={handleClick} tableName={"Section"} columns={column} data={itemList} initialPage={1} />
                </SimpleCard>
            </div>
        </div>
    )
};

export default Section;
