const initialState = {
    taxConfigList: []  
  }
  
  const TaxConfigReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_TAX_CONFIG_SUCCESS": {             
        return {
          ...state,
          getTaxConfigSuccess: true,
          getTaxConfigList: action.payload.data,
        };
      }
      case "GET_TAX_CONFIG_FAILURE": {
        return {
          ...state,
          getTaxConfigFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_TAX_CONFIG": {
        return {
          ...state,
          getTaxConfigSuccess: false,
          getTaxConfigFailure: false,
          getTaxConfigList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_TAX_CONFIG_SUCCESS": {             
        return {
          ...state,
          createTaxConfigSuccess: true,
          createTaxConfigData: action.payload.data,
        };
      }
      case "CREATE_TAX_CONFIG_FAILURE": {
        return {
          ...state,
          createTaxConfigFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_TAX_CONFIG": {
        return {
          ...state,
          createTaxConfigSuccess: false,
          createTaxConfigFailure: false,
          createTaxConfigData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_TAX_CONFIG_SUCCESS": {             
        return {
          ...state,
          updateTaxConfigSuccess: true,
          updateTaxConfigData: action.payload.data,
        };
      }
      case "UPDATE_TAX_CONFIG_FAILURE": {
        return {
          ...state,
          updateTaxConfigFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_TAX_CONFIG": {
        return {
          ...state,
          updateTaxConfigSuccess: false,
          updateTaxConfigFailure: false,
          updateTaxConfigData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default TaxConfigReducer
  
  