import * as yup from "yup";
import { Formik } from "formik";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import DateTime from "react-datetime";
import { Form, Button } from "react-bootstrap";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import FormLayout from "app/views/formlayout";
import { departmentForm, employeePromotionForm } from "./formData";
import { MdEdit, MdDelete } from "react-icons/md";
import swal from "sweetalert2";
import _ from "lodash";
import formRender from "app/utils/formcomponent";
import { getDepartment } from "app/api/Department";
import { dateConversion, experienceCalculator, pagePermission, showMessage } from "app/utils/app-functions";
import { createEmployeePromotion, getEmployee, getEmployeePromotion, updateEmployeePromotion } from "app/api/EmployeeApi";
import { currencySymbol } from "app/utils/constant";
import { getDesignation } from "app/api/Designation";
import { getEmployeeSalaryType } from "app/api/EmployeeSalaryTypeApi";
import View from "app/components/atoms/modalView/Modalview";
import CustomTable from "app/components/atoms/custom-table/CustomTable";
import SimpleCard from "app/components/atoms/simple-card/SimpleCard";
import moment from "moment";
import { asyncGetEmployeeInfo } from "app/api/EmployeeApi";
import { asyncGetDepartmentInfo } from "app/api/Department";
import { asyncGetDesignationInfo } from "app/api/Designation";
import Alert from "app/components/atoms/alert";
import { asyncGetSalaryTypeInfo } from "app/api/EmployeeSalaryTypeApi";
import { useSidebar } from "app/components/sidebar/sidebarContext";
import { lightTheme } from "styles/theme";
import Loading from "app/components/atoms/loading";

let editData = false;
let promotionData = [];


const EmployeePromotion = ({ navigation }) => {

    const dispatch = useDispatch();
    const customerFormRef = useRef();

    const getDepartmentSuccess = useSelector((state) => state.departmentReducer.getDepartmentSuccess);
    const getDepartmentList = useSelector((state) => state.departmentReducer.getDepartmentList);
    const getDepartmentFailure = useSelector((state) => state.departmentReducer.getDepartmentFailure);
    const departmentErrorMessage = useSelector((state) => state.departmentReducer.errorMessage);

    const getEmployeeSuccess = useSelector((state) => state.employeeReducer.getEmployeeSuccess);
    const getEmployeeList = useSelector((state) => state.employeeReducer.getEmployeeList);
    const getEmployeeFailure = useSelector((state) => state.employeeReducer.getEmployeeFailure);
    const employeeErrorMessage = useSelector((state) => state.employeeReducer.errorMessage);

    const getEmployeePromotionSuccess = useSelector((state) => state.employeeReducer.getEmployeePromotionSuccess);
    const getEmployeePromotionList = useSelector((state) => state.employeeReducer.getEmployeePromotionList);
    const getEmployeePromotionFailure = useSelector((state) => state.employeeReducer.getEmployeePromotionFailure);
    const employeePromotionErrorMessage = useSelector((state) => state.employeeReducer.errorMessage);

    const getDesignationSuccess = useSelector((state) => state.designationReducer.getDesignationSuccess);
    const getDesignationList = useSelector((state) => state.designationReducer.getDesignationList);
    const getDesignationFailure = useSelector((state) => state.designationReducer.getDesignationFailure);
    const designationErrorMessage = useSelector((state) => state.designationReducer.errorMessage);

    const getEmployeeSalaryTypeSuccess = useSelector((state) => state.employeeSalaryTypeReducer.getEmployeeSalaryTypeSuccess);
    const getEmployeeSalaryTypeList = useSelector((state) => state.employeeSalaryTypeReducer.getEmployeeSalaryTypeList);
    const getEmployeeSalaryTypeFailure = useSelector((state) => state.employeeSalaryTypeReducer.getEmployeeSalaryTypeFailure);
    const employeeSalaryTypeErrorMessage = useSelector((state) => state.employeeSalaryTypeReducer.errorMessage);

    const createEmployeePromotionSuccess = useSelector((state) => state.employeeReducer.createEmployeePromotionSuccess);
    const createEmployeePromotionData = useSelector((state) => state.employeeReducer.createEmployeePromotionData);
    const createEmployeePromotionFailure = useSelector((state) => state.employeeReducer.createEmployeePromotionFailure);

    const updateEmployeePromotionSuccess = useSelector((state) => state.employeeReducer.updateEmployeePromotionSuccess);
    const updateEmployeePromotionData = useSelector((state) => state.employeeReducer.updateEmployeePromotionData);
    const updateEmployeePromotionFailure = useSelector((state) => state.employeeReducer.updateEmployeePromotionFailure);

    const [pageItems, setpageItems] = useState({
        "viewOption": false,
        "addOption": true,
        "updateOption": false,
        "deleteOption": false
    }
    );

    useEffect(() => {
        let data = pagePermission(pageItems, "Employee", "Promotion")
        setpageItems(data)

    }, [navigation]);

    const column = [
        {
            "dataField": "departmentName",
            "text": "Department Name",
            "sort": true
        },
        {
            "dataField": "description",
            "text": "Description ",
            "sort": false
        },
        {
            dataField: "isActive",
            text: "Status",
            sort: true,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => {
                const conInfo = { inputType: "status", status: cellContent }
                return formRender(null, null, conInfo, null);
            }
        },
        {
            text: "Action",
            sort: false,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => (
                <div >
                    <MdEdit
                        className="text-success cursor-pointer"
                        size={18}
                        onClick={() => onEditForm(row, index)}
                    ></MdEdit>
                    <MdDelete
                        className="text-danger cursor-pointer"
                        size={18}
                        onClick={() => {
                            swal
                                .fire({
                                    title: "Are you sure?",
                                    text: "You won't be able to revert this!",
                                    icon: "warning",
                                    type: "question",
                                    showCancelButton: true,
                                    confirmButtonColor: "#3085d6",
                                    cancelButtonColor: "#d33",
                                    confirmButtonText: "Yes, delete it!",
                                    cancelButtonText: "No",
                                })
                                .then((result) => {
                                    if (result.value) {
                                        onDeleteItem(row, index);
                                    } else {
                                        swal.fire({
                                            title: "Cancelled!",
                                            text: "Permission denied.",
                                            type: "error",
                                            icon: "error",
                                            timer: 1500,
                                        });
                                    }
                                });
                        }}
                    ></MdDelete>
                </div>
            )
        },
    ]

    const [state, setState] = useState({
        employeeList: [],
        departmentList: [],
        designationList: [],
        employeePromotionList: [],
        employeeSalaryTypeList: [],
        promotionDate: moment(new Date(), 'YYYY-MM-DD'),
    });

    const [createModule, setCreateModule] = useState(false);
    const [loaderModule, setLoaderModule] = useState(false);
    const [editModule, setEditModule] = useState(false);
    const [viewModule, setViewModule] = useState(false);
    const [notFoundModule, setNotFoundModule] = useState(false);
    const [deleteModule, setDeleteModule] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [selectedItem, setSelectedItem] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);


    const listFetching = async () => {
        const requestData = {
            isActive: "true",
        }
        let employeeList = await asyncGetEmployeeInfo(requestData)
        let departmentList = await asyncGetDepartmentInfo(requestData)
        let designationList = await asyncGetDesignationInfo(requestData)
        let employeeSalaryTypeList = await asyncGetSalaryTypeInfo(requestData)


        setState({
            ...state,
            employeeList: employeeList?.data || [],
            departmentList: departmentList?.data || [],
            designationList: designationList?.data || [],
            employeeSalaryTypeList: employeeSalaryTypeList?.data || [],


        })

    }

    useEffect(() => {
        setLoaderModule(true)
        const requestData = {
            isActive: "true"
        }
        dispatch(getDepartment(requestData))
        dispatch(getEmployee(requestData))
        dispatch(getDesignation(requestData))
        dispatch(getEmployeeSalaryType(requestData))
        listFetching()
    }, [navigation]);

    useEffect(() => {
        if (getEmployeePromotionSuccess) {
            if (getEmployeePromotionList.length > 0) {
                setState({
                    ...state,
                    employeePromotionList: getEmployeePromotionList,
                    dateOfJoining: getEmployeePromotionList[0].dateOfJoining,
                    departmentId: getEmployeePromotionList[0].departmentId,
                    employeeId: getEmployeePromotionList[0].employeeId,
                    // promotedById:getEmployeePromotionList[0].promotedById,       //save login userId
                })
                setViewModule(true)
            } else {
                setNotFoundModule(true)
                showMessage("warning", "Not Found")
                setViewModule(false)

            }
            setSelectedItem(getEmployeePromotionList)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_EMPLOYEE_PROMOTION" })
        } else if (getEmployeePromotionFailure) {
            setState({
                ...state,
                employeePromotionList: []
            })
            showMessage("warning", employeePromotionErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_EMPLOYEE_PROMOTION" })
        }
    }, [getEmployeePromotionSuccess, getEmployeePromotionFailure]);

    useEffect(() => {
        if (getDepartmentSuccess) {
            setState({
                ...state,
                departmentList: getDepartmentList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_DEPARTMENT" })
        } else if (getDepartmentFailure) {
            setState({
                ...state,
                departmentList: []
            })
            showMessage("warning", departmentErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_DEPARTMENT" })
        }
    }, [getDepartmentSuccess, getDepartmentFailure]);

    useEffect(() => {
        if (getEmployeeSuccess) {
            setState({
                ...state,
                employeeList: getEmployeeList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_EMPLOYEE" })
        } else if (getEmployeeFailure) {
            setState({
                ...state,
                employeeList: []
            })
            showMessage("warning", employeeErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_EMPLOYEE" })
        }
    }, [getEmployeeSuccess, getEmployeeFailure]);

    useEffect(() => {
        if (getDesignationSuccess) {
            setState({
                ...state,
                designationList: getDesignationList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_DESIGNATION" })
        } else if (getDesignationFailure) {
            setState({
                ...state,
                designationList: []
            })
            showMessage("warning", designationErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_DESIGNATION" })
        }
    }, [getDesignationSuccess, getDesignationFailure]);

    useEffect(() => {
        if (getEmployeeSalaryTypeSuccess) {
            setState({
                ...state,
                employeeSalaryTypeList: getEmployeeSalaryTypeList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_EMPLOYEE_SALARY_TYPE" })
        } else if (getEmployeeSalaryTypeFailure) {
            setState({
                ...state,
                employeeSalaryTypeList: []
            })
            showMessage("warning", employeeSalaryTypeErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_EMPLOYEE_SALARY_TYPE" })
        }
    }, [getEmployeeSalaryTypeSuccess, getEmployeeSalaryTypeFailure]);

    useEffect(() => {
        if (createEmployeePromotionSuccess) {
            setLoaderModule(false)
            setState({
                ...state,
                empCode: "",
                promotionDate: new Date(),
                departmentId: "",
                promotedById: "",
                designationId: "",
                empSalaryTypeId: "",
                grossPay: "",
                commissionPct: "",
                salaryAmt: "",
                netPay: "",
                deduction: "",
            })
            setViewModule(false)
            Alert({ description: 'Promoted Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            closeModule()
            dispatch({ type: "RESET_CREATE_EMPLOYEE_PROMOTION" })
        } else if (createEmployeePromotionFailure) {
            setLoaderModule(false)
            showMessage("warning", employeePromotionErrorMessage?.message)
            dispatch({ type: "RESET_CREATE_EMPLOYEE_PROMOTION" })
        }
    }, [createEmployeePromotionSuccess, createEmployeePromotionFailure]);

    useEffect(() => {
        if (updateEmployeePromotionSuccess) {
            setLoaderModule(false)

            dispatch({ type: "RESET_UPDATE_EMPLOYEE_PROMOTION" })
        } else if (updateEmployeePromotionFailure) {
            closeModule()
            setLoaderModule(false)
            dispatch({ type: "RESET_UPDATE_EMPLOYEE_PROMOTION" })
        }
    }, [updateEmployeePromotionSuccess, updateEmployeePromotionFailure]);

    const onSearch = () => {
        if (!state.empCode) {
            showMessage("warning", "Please Enter The Employee Id/Code")
            return false;
        }
        const req = {
            "empCode": state?.empCode || "",
            "currentStatus": true
        }
        dispatch(getEmployeePromotion(req))
    }

    const closeModule = () => {
        editData = false;
        setCreateModule(false)
        setDeleteModule(false)
    }

    const resetState = () => {
        setState({
            ...state,
            departmentId: "",
            employeeId: ""
        })
    }

    const onCreateForm = () => {
        resetState()
        editData = false;
        setCreateModule(true)
    }

    const onEditForm = (data, index) => {
        setState({
            ...state,
            departmentName: data?.departmentName || "",
            description: data?.description || ""
        })
        editData = true;
        setSelectedIndex(index)
        setSelectedItem(data)
        setCreateModule(true)
    }

    const onDeleteItem = (data, index) => {
        editData = false;
        setSelectedIndex(index)
        setSelectedItem(data)
        setDeleteModule(true)
    }

    const validateForm = async () => {
        const validatefom = customerFormRef.current.validateForm();
    }



    const clearPromotion = () => {
        setState({
            ...state,
            employeePromotionList: [],
            dateOfJoining: "",
            departmentId: "",
            empCode: "",
            promotionDate: new Date(),
            promotedById: "",
            designationId: "",
            empSalaryTypeId: "",
            grossPay: "",
            commissionPct: "",
            salaryAmt: "",
            netPay: "",
            deduction: ""
        })
        setViewModule(false);
        setNotFoundModule(false);
    }

    const onSubmitForm = () => {
        const requestData = {
            employeeId: employeeId,
            departmentId: departmentId,
            designationId: designationId,
            salaryTypeId: empSalaryTypeId,
            grossPay: grossPay,
            commissionPct: commissionPct,
            salaryAmt: salaryAmt,
            netPay: netPay,
            deduction: deduction,
            promotionDate: dateConversion(promotionDate, "YYYY-MM-DD"),
        }
        setLoaderModule(true)
        dispatch(createEmployeePromotion(requestData))
        const updateRequest = {
            currentStatus: false
        }
        dispatch(updateEmployeePromotion(updateRequest, selectedItem[0].empPositionId))
    }

    const {
        departmentId,
        deduction,
        netPay,
        salaryAmt,
        empSalaryTypeId,
        designationId,
        commissionPct,
        grossPay,
        promotionDate,
        employeeId,
    } = state;

    const modelTitle = editData ? "Edit " : "Create ";
    const modelButtonLbl = editData ? "" : "Save";

    const { sidebarOpen } = useSidebar();

    return (
        <div style={sidebarOpen ? { marginLeft: "10px", marginTop: "-10px" } : { marginLeft: "10px", marginTop: "-50px" }}>

            <Loading status={loaderModule} />

            <SimpleCard >
                <div style={{ marginLeft: "50px", color: lightTheme.primaryColor }} className="card-description">Employee Promotion</div>
                <div style={{ marginLeft: "50px" }}>
                    <FormLayout ref={customerFormRef} defaultState={state} setDefaultState={setState} dynamicForm={departmentForm} noOfColumn={6} onSearch={() => onSearch()} />

                </div>
                <div>
                    {viewModule ? (
                        <>
                            <div
                                className="container mt-5"
                                style={sidebarOpen ? { marginLeft: "10px", marginTop: "-30px" } : { marginLeft: "10px", marginTop: "-50px",height:"300px" }}
                            >
                                <div className="row">
                                    {/* Employee Details Section */}
                                    <div className="col-lg-6" style={{height:"400px",overflowY:"scroll",scrollbarWidth:"0"}}>
                                        <h5 className="text-primary mb-4">Employee Details</h5>
                                        <div className="table-responsive">
                                            <table className="table table-bordered">
                                                <tbody>
                                                    {[
                                                        { label: "Employee Id", value: state.employeePromotionList[0]?.employeeCode },
                                                        { label: "Employee Name", value: state.employeePromotionList[0]?.employeeName },
                                                        { label: "Contact Number", value: state.employeePromotionList[0]?.contactNo },
                                                        { label: "Email", value: state.employeePromotionList[0]?.email },
                                                        { label: "Date Of Joining", value: state?.dateOfJoining ? dateConversion(state.dateOfJoining, "DD-MMM-YYYY") : "-" },
                                                        { label: "Experience", value: state?.dateOfJoining ? experienceCalculator(state.dateOfJoining) : "-" },
                                                        { label: "Department", value: state.employeePromotionList[0]?.departmentName },
                                                        { label: "Designation", value: state.employeePromotionList[0]?.designationName },
                                                        { label: "Salary", value: `${currencySymbol} ${state.employeePromotionList[0]?.salaryAmt}` },
                                                        { label: "Deduction", value: `${currencySymbol} ${state.employeePromotionList[0]?.deduction}` },
                                                        { label: "Net Pay", value: `${currencySymbol} ${state.employeePromotionList[0]?.netPay}` },
                                                        { label: "Gross Pay", value: `${currencySymbol} ${state.employeePromotionList[0]?.grossPay}` },
                                                    ].map((item, index) => (
                                                        <tr key={index}>
                                                            <td style={{ fontSize: "14px", fontWeight: "bold", color: "#555" }}>{item.label}</td>
                                                            <td style={{ fontSize: "14px", color: "#333" }}>{item.value || "-"}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    {/* Form Section */}
                                    <div className="col-lg-6">
                                        <h5 className="text-primary mb-4">Promotion Form</h5>
                                        <FormLayout
                                            ref={customerFormRef}
                                            defaultState={state}
                                            setDefaultState={setState}
                                            dynamicForm={employeePromotionForm}
                                            noOfColumn={2}
                                            onSearch={onSearch}
                                            onSubmit={onSubmitForm}
                                        />
                                        <div className="d-flex justify-content-end mt-1" style={{ marginBottom: "60px" }}>
                                            {pageItems.create && (
                                                <>
                                                    <Button
                                                        className="mr-3"
                                                        style={{
                                                            backgroundColor: "white",
                                                            border: "1px solid #ccc",
                                                            padding: "8px 15px",
                                                            fontSize: "12px",
                                                            color: "#333",
                                                        }}
                                                        onClick={clearPromotion}
                                                    >
                                                        Clear
                                                    </Button>
                                                    <Button
                                                        style={{
                                                            backgroundColor: lightTheme.primaryColor,
                                                            border: "none",
                                                            padding: "8px 15px",
                                                            fontSize: "12px",
                                                            color: "white",
                                                        }}
                                                        onClick={validateForm}
                                                    >
                                                        Submit
                                                    </Button>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : notFoundModule ? (
                        <div className="text-center" style={{ opacity: 0.6 }}>
                            <i className="i-Thumbs-Down-Smiley" style={{ fontSize: "35px" }}></i>
                            <p className="mt-3" style={{ fontSize: "18px" }}>Data Not Found!</p>
                        </div>
                    ) : (
                        <div className="text-center" style={{ opacity: 0.6 }}>
                            <i className="i-Magnifi-Glass1" style={{ fontSize: "35px" }}></i>
                            <p className="mt-3" style={{ fontSize: "18px" }}>Search Employee ID / Code</p>
                        </div>
                    )}
                </div>



            </SimpleCard>


        </div>
    )
}

export default EmployeePromotion;