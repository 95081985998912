import React, { useEffect, useState } from "react";
import { ThemeProvider } from "styled-components";
import { darkTheme, lightTheme } from "./styles/theme";
import { Helmet } from "react-helmet";
import 'bootstrap/dist/css/bootstrap.min.css';
import { GlobalStyle } from "./styles/globalStyles";
import Loader from "./app/components/atoms/loader/Loader";
import AppRoutes from "./Routes";
import { Routes, Route, Navigate } from "react-router-dom";
import { SidebarProvider } from "./app/components/sidebar/sidebarContext";
import Layout from "./app/components/layout/Layout";
import { Provider } from "react-redux";
import { store } from "./app/store";
import { AuthForm } from "app/pages/sessions/AuthForm";
import { AuthProvider, useAuth } from 'app/contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

export const ThemeContext = React.createContext(null);

const App = () => {
  const [theme, setTheme] = useState("light");
  const themeStyle = theme === "light" ? lightTheme : darkTheme;

  const navigate = useNavigate();
  const isAuthenticated = localStorage.getItem("isAuthenticated") === "true";

  // Do not use useEffect for redirect, just render routes based on authentication state
  let content;

  if (isAuthenticated) {
    content = (
      <Layout>
        <AppRoutes />
      </Layout>
    );
  } else {
    content = (
      <Routes>
        <Route path="/sessions/authform" element={<AuthForm />} />
        <Route path="*" element={<Navigate to="/sessions/authform" />} />
      </Routes>
    );
  }
 
  return (
    <Provider store={store}>
      <AuthProvider>
        <ThemeContext.Provider value={{ setTheme, theme }}>
          <SidebarProvider>
            <ThemeProvider theme={themeStyle}>
              <GlobalStyle />
              <Helmet>
                <title>Scholer Plus</title>
              </Helmet>

              {content}

            </ThemeProvider>
          </SidebarProvider>
        </ThemeContext.Provider>
      </AuthProvider>
    </Provider>
  );
};

export default App;