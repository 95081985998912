import * as yup from "yup";
import { Formik } from "formik";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import DateTime from "react-datetime";
import { Form, Button } from "react-bootstrap";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { homeWorkForm, homeWorkDetailsForm, homeWorkEditForm } from "./formData";
import { MdEdit, MdDelete } from "react-icons/md";
import swal from "sweetalert2";
import _ from "lodash";
import formRender from "app/components/render/formcomponent";
import { createHomeWork, deleteHomeWorkDetails, getHomeWork, updateHomeWork } from "app/api/HomeWorkApi";
import { getSubject, getAsyncSubject } from "app/api/SubjectApi";
import { getHomeWorkType, getAsyncHomeWorkType } from "app/api/HomeWorkTypeApi";
import { getEmployee, asyncGetEmployeeInfo } from "app/api/EmployeeApi";
import { getEnrollmentDetails } from "app/api/EnrollementDetailsApi";
import { getStudent, getStudentDetails } from "app/api/StudentApi";
import { getStudentEnrollment } from "app/api/StudentEnrollmentApi";
import { dateConversion, pagePermission, showMessage } from "app/utils/app-functions";
import { getBatch, asyncGetBatchInfo } from "app/api/BatchApi";
import CustomAlignButton from "app/components/CustomAlignButton";
import { getStudentClassList, getStudentList, asyncGetStudentListInfo, getAsyncStudentClassList } from "app/api/StudentListApi";
import View from "app/components/atoms/modalView/Modalview";
import CustomTable from "app/components/atoms/custom-table/CustomTable";
import SimpleCard from "app/components/atoms/simple-card/SimpleCard";
import TableLayout from "app/components/render/formlayout/tablelayout";
import FormLayout from "app/components/render/formlayout";
import moment from "moment";
import Alert from "app/components/atoms/alert";
import { asyncGetStudentEnrollmentInfo } from 'app/api/StudentEnrollmentApi';
import { asyncGetHomeWorkTypeInfo } from 'app/api/HomeWorkTypeApi';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import { lightTheme } from "styles/theme";
import { asyncgetSubjectTeachers } from "app/api/SubjectTeachersApi";

// import { asyncGetStudentListInfo } from 'app/api/StudentListApi';




let editData = false;
let filterEnrollmentIds = "";
let filterStudentEnrollmentIds = "";
let checkData = false;
let studentArr = [];
let enrollArr = [];

const HomeWork = ({ navigation }) => {

    const dispatch = useDispatch();
    const customerFormRef = useRef();

    const getHomeWorkSuccess = useSelector((state) => state.homeWorkReducer.getHomeWorkSuccess);
    const getHomeWorkList = useSelector((state) => state.homeWorkReducer.getHomeWorkList);
    const getHomeWorkFailure = useSelector((state) => state.homeWorkReducer.getHomeWorkFailure);
    const homeWorkErrorMessage = useSelector((state) => state.homeWorkReducer.errorMessage);

    const getSubjectSuccess = useSelector((state) => state.subjectReducer.getSubjectSuccess);
    const getSubjectList = useSelector((state) => state.subjectReducer.getSubjectList);
    const getSubjectFailure = useSelector((state) => state.subjectReducer.getSubjectFailure);
    const subjectErrorMessage = useSelector((state) => state.subjectReducer.errorMessage);

    const getEnrollmentDetailsSuccess = useSelector((state) => state.enrollmentDetailsReducer.getEnrollmentDetailsSuccess);
    const getEnrollmentDetailsList = useSelector((state) => state.enrollmentDetailsReducer.getEnrollmentDetailsList);
    const getEnrollmentDetailsFailure = useSelector((state) => state.enrollmentDetailsReducer.getEnrollmentDetailsFailure);
    const enrollmentDetailsErrorMessage = useSelector((state) => state.enrollmentDetailsReducer.errorMessage);

    const getHomeWorkTypeSuccess = useSelector((state) => state.homeWorkTypeReducer.getHomeWorkTypeSuccess);
    const getHomeWorkTypeList = useSelector((state) => state.homeWorkTypeReducer.getHomeWorkTypeList);
    const getHomeWorkTypeFailure = useSelector((state) => state.homeWorkTypeReducer.getHomeWorkTypeFailure);
    const homeWorkTypeErrorMessage = useSelector((state) => state.homeWorkTypeReducer.errorMessage);

    const getEmployeeSuccess = useSelector((state) => state.employeeReducer.getEmployeeSuccess);
    const getEmployeeList = useSelector((state) => state.employeeReducer.getEmployeeList);
    const getEmployeeFailure = useSelector((state) => state.employeeReducer.getEmployeeFailure);
    const employeeErrorMessage = useSelector((state) => state.employeeReducer.errorMessage);

    const getStudentEnrollmentSuccess = useSelector((state) => state.studentEnrollmentReducer.getStudentEnrollmentSuccess);
    const getStudentEnrollmentList = useSelector((state) => state.studentEnrollmentReducer.getStudentEnrollmentList);
    const getStudentEnrollmentFailure = useSelector((state) => state.studentEnrollmentReducer.getStudentEnrollmentFailure);
    const studentEnrollmentErrorMessage = useSelector((state) => state.studentEnrollmentReducer.errorMessage);

    const createHomeWorkSuccess = useSelector((state) => state.homeWorkReducer.createHomeWorkSuccess);
    const createHomeWorkData = useSelector((state) => state.homeWorkReducer.createHomeWorkData);
    const createHomeWorkFailure = useSelector((state) => state.homeWorkReducer.createHomeWorkFailure);

    const updateHomeWorkSuccess = useSelector((state) => state.homeWorkReducer.updateHomeWorkSuccess);
    const updateHomeWorkData = useSelector((state) => state.homeWorkReducer.updateHomeWorkData);
    const updateHomeWorkFailure = useSelector((state) => state.homeWorkReducer.updateHomeWorkFailure);

    const getStudentListSuccess = useSelector((state) => state.studentListReducer.getStudentListSuccess);
    const getStudentListList = useSelector((state) => state.studentListReducer.getStudentListList);
    const getStudentListFailure = useSelector((state) => state.studentListReducer.getStudentListFailure);
    const studentListErrorMessage = useSelector((state) => state.studentListReducer.errorMessage);

    const getStudentClassListSuccess = useSelector((state) => state.studentListReducer.getStudentClassListSuccess);
    const getStudentClassListList = useSelector((state) => state.studentListReducer.getStudentClassListList);
    const getStudentClassListFailure = useSelector((state) => state.studentListReducer.getStudentClassListFailure);
    const studentClassListErrorMessage = useSelector((state) => state.studentListReducer.errorMessage);

    const getBatchSuccess = useSelector((state) => state.batchReducer.getBatchSuccess);
    const getBatchList = useSelector((state) => state.batchReducer.getBatchList);
    const getBatchFailure = useSelector((state) => state.batchReducer.getBatchFailure);
    const batchErrorMessage = useSelector((state) => state.batchReducer.errorMessage);

    const deleteHomeWorkDetailsSuccess = useSelector((state) => state.homeWorkReducer.deleteHomeWorkDetailsSuccess);
    const deleteHomeWorkDetailsFailure = useSelector((state) => state.homeWorkReducer.deleteHomeWorkDetailsFailure);
    const deleteHomeWorkDetailsErrorMessage = useSelector((state) => state.homeWorkReducer.errorMessage);

    const [pageItems, setpageItems] = useState({});

    useEffect(() => {
        let data = pagePermission(pageItems, "Student", "Home Work")
        setpageItems(data)

    }, [navigation]);

    const column = [
        {
            "dataField": "homeWorkTypeName",
            "text": "Home Work Type",
            "sort": true
        },
        {
            "dataField": "submissionDate",
            "text": "Submission Date",
            "sort": true
        },
        {
            "dataField": "employeeCode",
            "text": "Created By",
            "sort": true
        },
        {
            "dataField": "enrollmentDetails",
            "text": "Class - Section",
            "sort": true,
            formatter: (cellContent, row, index) => {
                const deatils = row?.enrollmentDetails || []
                return deatils.map((itm) => {
                    return `${itm?.classTitle || "NA"} - ${itm?.sectionTitle || "NA"},`
                })
            }
        },

    ]

    if (pageItems.delete || pageItems.update) {
        column.push({
            // dataField: "age",
            text: "Action",
            sort: false,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => (
                <div className="icon-container">
                    {pageItems.update && <FiEdit
                        className="text-primary cursor-pointer"
                        size={18}
                        onClick={() => onEditForm(row, index)}
                    ></FiEdit>}
                    {pageItems.delete && <FiTrash2
                        className="text-danger cursor-pointer"
                        size={18}
                        onClick={() => {
                            onDeleteItem(row, index);
                        }}
                    ></FiTrash2>}
                </div>
            )
        },)
    }

    const itemHeader = [
        {
            "dataField": "status",
            "text": "#",
            formatter: (cellContent, row, index, extraData) => {
                const conInfo = { inputType: "checkbox", status: cellContent }
                return formRender(extraData, null, conInfo, null, null, statusChange, index);
            }
        },
        {
            "dataField": "studentName",
            "text": "Student Name",
            "sort": true
        },
        {
            "dataField": "class-section",
            "text": "Class - Section",
            "sort": true
        },
    ]

    const [state, setState] = useState({
        subjectList: [],
        isCheck: true,
        isConfirm: true,
        homeWorkTypeList: [],
        employeeList: [],
        enrollmentDetailsList: [],
        studentEnrollmentList: [],
        // submissionDate: new Date(),
        filterStudentEnrollment: []
    });

    const [createModule, setCreateModule] = useState(false);
    const [editModule, setEditModule] = useState(false);
    const [list, setList] = useState([]);
    const [viewModule, setViewModule] = useState(false);
    const [deleteModule, setDeleteModule] = useState(false);
    const [removeModule, setRemoveModule] = useState(false);
    const [loaderModule, setLoaderModule] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [homeWorkItemList, setHomeWorkItemList] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [selectedItem, setSelectedItem] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);
    const [editIndex, setEditIndex] = useState(false);


    const deletDialog = () => {
        return (
            swal
                .fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    type: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!",
                    cancelButtonText: "No",
                })
                .then((result) => {
                    if (result.value) {
                        onSubmitForm()
                    } else {
                        setDeleteModule(false)
                        swal.fire({
                            title: "Cancelled!",
                            text: "Permission denied.",
                            type: "error",
                            icon: "error",
                            timer: 1500,
                        });
                    }
                })
        )
    }

    useEffect(() => {
        if (deleteModule) {
            deletDialog()
        }
    }, [deleteModule])
    
    let userInfo = window.localStorage.getItem("userInfo");
    let parsedData = JSON.parse(userInfo);

    useEffect(() => {
        const requestData = {
            isActive: "true",
            empCode: parsedData?.[0]?.userCode || ''
        }
        const req = {
            isActive: "true",
        };
        if (parsedData?.[0]?.roleId == 5) {
            req.empCode = parsedData?.[0]?.userCode || ''
        }

        setLoaderModule(true)

        // dispatch(getSubject(requestData))
        // dispatch(getHomeWorkType(requestData))
        // dispatch(getEmployee(req))
        dispatch(getHomeWork(req))
        // dispatch(getBatch(requestData))
        listFetching()

    }, [navigation]);

    const listFetching = async () => {
        const requestData = {
            isActive: true,
        }
        const req = {
            isActive: true,
        };
        if (parsedData?.[0]?.roleId == 5) {
            req.empCode = parsedData?.[0]?.userCode || ''
        }

        let subjectList = await getAsyncSubject(requestData)
        let homeWorkTypeList = await getAsyncHomeWorkType(requestData)
        let batchList = await asyncGetBatchInfo(requestData)
        let employeeList = await asyncgetSubjectTeachers(req)

        setState({
            ...state,
            // schoolList:schoolInfoList?.getSchoolInfoList || [],
            subjectList: subjectList?.data || [],
            batchList: batchList?.data || [],
            homeWorkTypeList: homeWorkTypeList?.data || [],
            employeeList: employeeList?.data || [],

        })

    }

    useEffect(() => {
        if (getSubjectSuccess) {
            setState({
                ...state,
                subjectList: getSubjectList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_SUBJECT" })
        } else if (getSubjectFailure) {
            setState({
                ...state,
                subjectList: []
            })
            showMessage("warning", subjectErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_SUBJECT" })
        }
    }, [getSubjectSuccess, getSubjectFailure]);

    useEffect(() => {
        if (getBatchSuccess) {
            setState({
                ...state,
                batchList: getBatchList
            })
            console.log(JSON.stringify(state.batchList));
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_BATCH" })
        } else if (getBatchFailure) {
            setState({
                ...state,
                batchList: []
            })
            showMessage("warning", batchErrorMessage?.message, "Batch")
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_BATCH" })
        }
    }, [getBatchSuccess, getBatchFailure]);

    useEffect(() => {
        if (getHomeWorkTypeSuccess) {
            setState({
                ...state,
                homeWorkTypeList: getHomeWorkTypeList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_HOME_WORK_TYPE" })
        } else if (getHomeWorkTypeFailure) {
            setState({
                ...state,
                homeWorkTypeList: []
            })
            showMessage("warning", homeWorkTypeErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_HOME_WORK_TYPE" })
        }
    }, [getHomeWorkTypeSuccess, getHomeWorkTypeFailure]);

    useEffect(() => {
        if (getEnrollmentDetailsSuccess) {
            setState({
                ...state,
                enrollmentDetailsList: [...getEnrollmentDetailsList]
            })
            dispatch(getStudentEnrollment({ "batchId": state?.batchId || 0 }))

            setLoaderModule(false)
            dispatch({ type: "RESET_GET_ENROLLMENT_DETAILS" })

        } else if (getEnrollmentDetailsFailure) {
            setState({
                ...state,
                enrollmentDetailsList: []
            })
            showMessage("warning", enrollmentDetailsErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_ENROLLMENT_DETAILS" })
        }
    }, [getEnrollmentDetailsSuccess, getEnrollmentDetailsFailure]);

    useEffect(() => {
        if (getStudentEnrollmentSuccess) {
            setState({
                ...state,
                studentEnrollmentList: getStudentEnrollmentList,
            })
            setList(getStudentEnrollmentList)

            if (editData) {
                onEdit(state.editClickdata, getStudentEnrollmentList);
            }
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_STUDENT_ENROLLMENT" })
        } else if (getStudentEnrollmentFailure) {
            setState({
                ...state,
                studentEnrollmentList: []
            })
            showMessage("warning", studentEnrollmentErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_STUDENT_ENROLLMENT" })
        }
    }, [getStudentEnrollmentSuccess, getStudentEnrollmentFailure]);

    useEffect(() => {
        if (getEmployeeSuccess) {
            setState({
                ...state,
                employeeList: getEmployeeList,
                employeeId: getEmployeeList.map((item) => item.employeeId),

            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_EMPLOYEE" })
        } else if (getEmployeeFailure) {
            setState({
                ...state,
                employeeList: []
            })
            showMessage("warning", employeeErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_EMPLOYEE" })
        }
    }, [getEmployeeSuccess, getEmployeeFailure]);

    useEffect(() => {
        if (getStudentClassListSuccess) {
            setState({
                ...state,
                enrollmentDetailsList: getStudentClassListList
            })
            dispatch(getStudentList({ "batchId": state?.batchId || 0 }))
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_STUDENT_CLASS_LIST" })
        } else if (getStudentClassListFailure) {
            setState({
                ...state,
                studentClassList: []
            })
            showMessage("warning", studentClassListErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_STUDENT_CLASS_LIST" })
        }
    }, [getStudentClassListSuccess, getStudentClassListFailure]);

    useEffect(() => {
        if (getStudentListSuccess) {
            setState({
                ...state,
                studentEnrollmentList: getStudentListList
            })
            setList(getStudentListList)

            if (editData) {
                onEdit(state.editClickdata, getStudentListList);
            }
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_STUDENT_LIST" })
        } else if (getStudentListFailure) {
            setState({
                ...state,
                studentList: []
            })
            showMessage("warning", studentListErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_STUDENT_LIST" })
        }
    }, [getStudentListSuccess, getStudentListFailure]);

    useEffect(() => {
        if (getHomeWorkSuccess) {
            setItemList(getHomeWorkList)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_HOME_WORK" })
        } else if (getHomeWorkFailure) {
            setItemList([])
            showMessage("warning", homeWorkErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_HOME_WORK" })
        }
    }, [getHomeWorkSuccess, getHomeWorkFailure]);

    useEffect(() => {
        if (createHomeWorkSuccess) {
            const temp_state = [...itemList, createHomeWorkData[0]];

            setItemList(temp_state)
            setLoaderModule(false)
            closeModule()
            Alert({ description: 'Created Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            dispatch({ type: "RESET_CREATE_HOME_WORK" })
        } else if (createHomeWorkFailure) {
            setLoaderModule(false)
            showMessage("warning", homeWorkErrorMessage?.message)
            dispatch({ type: "RESET_CREATE_HOME_WORK" })
        }
    }, [createHomeWorkSuccess, createHomeWorkFailure]);

    useEffect(() => {
        if (deleteHomeWorkDetailsSuccess) {
            Alert({ description: 'Deleted Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            dispatch({ type: "RESET_DELETE_HOME_WORK_DETAILS" })
        } else if (deleteHomeWorkDetailsFailure) {
            showMessage("warning", deleteHomeWorkDetailsErrorMessage?.message)
            dispatch({ type: "RESET_DELETE_HOME_WORK_DETAILS" })
        }
    }, [deleteHomeWorkDetailsSuccess, deleteHomeWorkDetailsFailure]);

    useEffect(() => {
        if (updateHomeWorkSuccess) {

            updateTable(updateHomeWorkData[0])
            dispatch({ type: "RESET_UPDATE_HOME_WORK" })
        } else if (updateHomeWorkFailure) {
            setLoaderModule(false)
            showMessage("warning", homeWorkErrorMessage?.message)
            dispatch({ type: "RESET_UPDATE_HOME_WORK" })
        }
    }, [updateHomeWorkSuccess, updateHomeWorkFailure]);

    useEffect(() => {
        let data = state?.employeeList || []
        console.log(data);
        if (parsedData[0].roleId === 5) {
            setState({
                ...state,
                employee_id: parsedData[0].roleId == 5 ? (data && data.length && data[0].employeeId) : null,
                employeeId: parsedData[0].roleId == 5 ? (data && data.length ? `${data[0].employeeName} ${data[0].employeeCode}` : "") : "",
                // subjectList: parsedData[0].roleId == 5 ? (data && data.length && data[0].subjectList) : "",
            })
        }

    }, []);


    const updateTable = (updatedItem) => {
        const temp_state = [...itemList];
        if (deleteModule) {
            let remainingList = _.remove(temp_state, function (n) {
                return n.homeWorkId != updatedItem.homeWorkId;
            });
            Alert({ description: 'Deleted Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setDeleteModule(false)
            setItemList(remainingList)
        } else {
            temp_state[selectedIndex] = updatedItem;
            Alert({ description: 'Updated Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(temp_state)
        }
        setLoaderModule(false)
        closeModule()
    }

    const closeModule = () => {
        editData = false;
        setCreateModule(false)
        setDeleteModule(false)
        setState({
            ...state,
            isCheck: true,
            isConfirm: true,
        })
    }

    const resetState = () => {
        // alert('in')
        /* if(parsedData[0]?.roleId != 5){
          state.employee_id = ''  
          state.employeeCode = ''  
        } */
        setState({
            ...state,
            studentEnrollmentId: "",
            batchId: "",
            submissionDate: moment(new Date(), 'YYYY-MM-DD'),
            enrollmentId: "",
            employeeId: "",
            typeId: "",
            filterStudentEnrollment: [],
            enrollmentDetailsList: [],
            createdAt: moment(new Date(), 'YYYY-MM-DD'),
        })
        setHomeWorkItemList([])
    }

    const onCreateForm = () => {
        resetState()
        editData = false;
        setCreateModule(true)
    }

   
    const onEditForm = async (data, index) => {
        let request = { "batchId": data.batchId, "enrollmentId": data.enrollmentIds, "statusId": 2, }

        const req = {
        };
        if (parsedData[0].roleId == 5) {
            req.empCode = (parsedData[0]?.userCode || '');
        }
        let enrollData = await getAsyncStudentClassList(req);
        let studentData = await asyncGetStudentListInfo(request);
        editData = true;
        // dispatch(getStudentClassList({ "isActive": true }))
        const temp_state = enrollData.data;
        const temp_state_arr = studentData.data
        setList(temp_state_arr)


        const fiterStudentEnrollmentData = (data?.studentEnrollmentIds || "").split(",");
        const fiterEnrollmentData = data?.enrollmentIds?.split(",").map(Number) || [];

        const enrollList = temp_state.filter((item) => { return data?.enrollmentIds.includes((item.enrollmentId).toString()) });
        const stdList = temp_state_arr.map((item) => {
            const status = fiterStudentEnrollmentData.includes(item.studentEnrollmentId.toString());
            return { ...item, status };
        });
        const obj = [];

        stdList.forEach(ele => {
            enrollList.forEach(o => {
                if (o.enrollmentId == ele.enrollmentId) {
                    obj.push(ele);
                }
            });
        });

        //    console.log(JSON.stringify(obj))
        studentArr = obj
        setState(prevState => ({
            ...prevState,
            batchId: data?.batchId || 0,
            isConfirm: true,
            isCheck: true,
            studentEnrollmentList: studentData.data,
            enrollmentDetailsList: enrollData.data,
            filterStudentEnrollment: obj,
            submissionDate: data ? moment(data.submissionDate, "YYYY-MM-DD") : "",
            createdAt: data ? moment(data.createdAt, "YYYY-MM-DD") : "",
            enrollmentId: fiterEnrollmentData,
            employeeId: (data.employeeCode || "") + " " + `(${(data.employeeName || "")})`,
            employee_id: data?.createdBy || "",
            typeId: data?.homeWorkTypeId || "",
        }));
        setHomeWorkItemList(data?.homeWorkDetails || [])
        setSelectedIndex(index)
        setSelectedItem(data)
        setCreateModule(true)
    }

    const onEdit = (data, studentData) => {
        const temp_state = state.enrollmentDetailsList;
        const temp_state_arr = studentData

        // const fiterEnrollmentData = (data?.enrollmentIds || "").split(",");
        // alert(JSON.stringify(fiterEnrollmentData))
        const fiterStudentEnrollmentData = (data?.studentEnrollmentIds || "").split(",");
        const fiterEnrollmentData = data?.enrollmentIds?.split(",").map(Number) || [];

        const enrollList = temp_state.filter((item) => { return data?.enrollmentIds.includes((item.enrollmentId).toString()) });
        const stdList = temp_state_arr.map((item) => {
            const status = fiterStudentEnrollmentData.includes(item.studentEnrollmentId.toString());
            return { ...item, status };
        });
        const obj = [];

        stdList.forEach(ele => {
            enrollList.forEach(o => {
                if (o.enrollmentId == ele.enrollmentId) {
                    obj.push(ele);
                }
            });
        });

        studentArr = obj
        setState(prevState => ({
            ...prevState,
            batchId: data?.batchId || 0,
            isConfirm: true,
            isCheck: true,
            filterStudentEnrollment: obj,
            /*  submissionDate: data ? moment(data.submissionDate,"YYYY-MM-DD") : "",
             createdAt: data ? moment(data.createdAt,"YYYY-MM-DD") : "", */
            enrollmentId: fiterEnrollmentData,
            employee_id: data?.createdBy || "",
            // employee_id: data?.createdBy || "",
            typeId: data?.homeWorkTypeId || "",
        }));
    }

    const onDeleteItem = (data, index) => {
        editData = false;
        setSelectedIndex(index)
        setSelectedItem(data)
        setDeleteModule(true)
    }

    const statusChange = (event, index, extraData) => {
        let temp_state = [...state.filterStudentEnrollment]
        if(temp_state[index].statusId == 1){
            Alert({ description: 'Student Admission is still pending. !', type: 'error'});
            return false
        }
        else if(temp_state[index].statusId == 3){
            Alert({ description: 'Student is De-activated. !', type: 'error'});
            return false
        }
        temp_state[index].status = event.target.checked
        setState({
            ...state,
            filterStudentEnrollment: temp_state
        })

    }

    const onSelectClass = async (data, name) => {


        // console.log(JSON.stringify(data));
        const employeeId = state?.employee_id || '';
        const batchId = state?.batchId || '';
        if (!employeeId) {
            Alert({ description: 'Select Staff Before Selecting Class.', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            return false;
        }
        else if (!batchId) {
            Alert({ description: 'Select Batch Before Selecting Class.', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            return false;
        }
        let rquest = {
            "batchId": batchId,
            "enrollmentId": data.join(',')
        }
        let studentData = await asyncGetStudentListInfo(rquest);
        const temp_state = state?.enrollmentDetailsList || []
        const filteredData = temp_state.filter(item => data.includes(Number(item.enrollmentId)));
        const tempArr = studentData?.data || [];
        //  else {
        let obj = [];

        (filteredData ? filteredData : []).forEach(ele => {
            const filterArr = tempArr.filter(item => item.enrollmentId === ele.enrollmentId);
            if (filterArr.length) {
                obj = [...obj, ...filterArr];
            }

            if (editData) {
                filterArr.forEach((tempStudent) => {
                    const isPresent = studentArr.some(
                        (student) =>
                            student.studentId === tempStudent.studentId &&
                            student.enrollmentId === tempStudent.enrollmentId && // Check tempStudent's enrollmentId
                            tempStudent.enrollmentId === ele.enrollmentId // Ensure ele.enrollmentId matches tempStudent's enrollmentId
                    );

                    if (!isPresent) {
                        studentArr.push(tempStudent); // Add if not already present
                    }
                });

                studentArr = studentArr.filter((student) =>
                    filteredData.some((data) => data.enrollmentId === student.enrollmentId)
                );
                obj = [...studentArr]; // Spread operator to create a new array
            }




        });

        setState(prevState => ({
            ...prevState,
            [name]: data,
            filterStudentEnrollment: obj
        }));
        // }
    }

    const onSelectBatch = async (event, name) => {
        let request = {
            "batchId": event, isActive: "true",
        }

        if (request.batchId != 0) {
            const req = {
                isActive: "true",
            };
            if (parsedData[0].roleId == 5) {
                req.empCode = parsedData[0]?.userCode || '';
            }
            // let enrollList = await getAsyncStudentClassList(req);
            // let studentList = await asyncGetStudentListInfo(request);
            // console.log(enrollList);
            /*  let newList = studentList.data
             setList(newList) */
            // console.log(newList);

            setState({
                ...state,
                // enrollmentDetailsList: enrollList.data,
                // studentEnrollmentList: studentList.data,
                [name]: event

            })

            // dispatch(getStudentClassList(req))
            // dispatch(getStudentList(request))
        }


    }

    const addItem = () => {
        const defaultItem = {
            "subjectId": "",
            "homeWorkTitle": "",
            "description": "",
        }
        if (state.employee_id == null) {
            Alert({ description: 'Select Created By.', type: 'warning', placement: 'topRight', duration: 3, });
            return false;
        }
        setHomeWorkItemList([...homeWorkItemList, defaultItem])
    }

    const onRemoveItem = (index) => {
        let temp_state = [...homeWorkItemList];
        console.log("studentArr ---- > " + JSON.stringify(temp_state[index]));

        if (temp_state[index].homeWorkDetailsId) {
            setSelectedItem(temp_state[index])
            setEditIndex(index)
            setRemoveModule(true)
        } else {
            temp_state.splice(index, 1);
            setHomeWorkItemList(temp_state)
        }
    }

    const onDeleteForm = () => {
        let temp_state = [...homeWorkItemList];
        let mainArray = [...itemList];
        temp_state.splice(editIndex, 1);
        mainArray[selectedIndex].homeWorkDetails.splice(editIndex, 1);
        setHomeWorkItemList(temp_state)
        setItemList(mainArray)
        setRemoveModule(false)
        const homeWorkDetailsId = selectedItem.homeWorkDetailsId
        const req = {
            "homeWorkDetailsId": homeWorkDetailsId
        }
        dispatch(deleteHomeWorkDetails(req, homeWorkDetailsId))
        // const result = onSearch(jobHistoryId, "jobHistory")
    }
    console.log(JSON.stringify(homeWorkItemList));



    const onCheck = async () => {
        let emptyTitle = false

        filterEnrollmentIds = (state.enrollmentId || []).map((itm) => {
            return itm
        })
        filterStudentEnrollmentIds = (state.studentEnrollmentId || []).map((itm) => {
            return itm.studentEnrollmentId
        })
        checkData = false
        if (filterStudentEnrollmentIds.length) {
            checkData = true
        }
        (homeWorkItemList).map((itm) => {
            if (itm.homeWorkTitle == "" || itm.subjectId == "" || itm.subjectId == 0 || itm.subjectId == null || itm.subjectId == undefined) {
                emptyTitle = true
            }
        })
        if (emptyTitle && filterEnrollmentIds != "" || homeWorkItemList.length == 0) {
            Alert({ description: 'Please Fill Home Work Details.', type: 'warning', placement: 'topRight', duration: 3, });

            // showMessage('warning', "Please Fill Home Work Details")
        } else {
            setViewModule(true)
        }
    }

    const validateForm = async () => {
        const validatefom = customerFormRef.current.validateForm();
    }

    const onSubmitForm = () => {
        setViewModule(false)

        const requestData = {
            enrollmentIds: filterEnrollmentIds.toString() ? filterEnrollmentIds.join(',') : 0,
            submitionDate: submissionDate,
            studentEnrollmentIds: filterStudentEnrollmentIds.toString() ? filterStudentEnrollmentIds.toString() : 0,
            createdBy: parseInt(employee_id),
            typeId: parseInt(typeId),
            homeWorkDetails: homeWorkItemList,
            batchId: state.batchId,
            createdAt: dateConversion(state?.createdAt || new Date(), "YYYY-MM-DD")
        }

        console.log(JSON.stringify(requestData));
        // return

        function validateFormData(requestData) {
            for (let key in requestData) {
                if (requestData.hasOwnProperty(key)) {
                    if (
                        requestData[key] === null ||
                        requestData[key] === undefined ||
                        requestData[key] === "" ||
                        requestData[key] === "0" ||
                        requestData[key] === 0 ||
                        (typeof requestData[key] === "string" && requestData[key].trim() === "") ||
                        (typeof requestData[key] === "number" && isNaN(requestData[key]))
                    ) {
                        return false;
                    }
                }
            }

            return true;
        }

        if ((requestData.studentEnrollmentIds == 0 && !deleteModule) || (state.isCheck && state.isConfirm && !deleteModule)) {
            // showMessage('warning', "Save the Student list")
            Alert({ description: 'Save the Student list.', type: 'warning', placement: 'topRight', duration: 3, });

            return
        }
        if (!validateFormData(requestData) && !deleteModule) {
            Alert({ description: 'Fill all the details.', type: 'warning', placement: 'topRight', duration: 3, });

            // showMessage('warning', "Fill all the details")
        }
        else {
            if (editData) {
                setLoaderModule(true)
                dispatch(updateHomeWork(requestData, selectedItem.homeWorkId))
            } else if (deleteModule) {
                const deleteRequest = {
                    isActive: false
                }
                setLoaderModule(true)
                dispatch(updateHomeWork(deleteRequest, selectedItem.homeWorkId))
                return
            }
            else {
                setLoaderModule(true)
                dispatch(createHomeWork(requestData))
            }
        }

    }

    const onCloseForm = () => {
        setShowForm(false);
        setState({
            ...state,
            isCheck: true,
            isConfirm: true,
        })
    }

    const confirmForm = () => {
        // alert('in')
        let temp_state = [...state.filterStudentEnrollment]
        let filteredData = _.filter(temp_state, function (item) {
            return item.status === true && item.statusId !== 1 && item.statusId !== 3;
        });

        setState({
            ...state,
            isConfirm: false,
            studentEnrollmentId: filteredData
        })
        setShowForm(false);

    }

    const confromDialog = () => {
        return (
            swal
                .fire({
                    title: "Are you sure?",
                    text: `Please confirm that you are assigning homework to ${checkTitle} on the saved student list.!`,
                    icon: "warning",
                    type: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                })
                .then((result) => {
                    if (result.value) {
                        onSubmitForm()
                    } else {
                        setViewModule(false)
                        swal.fire({
                            title: "Cancelled!",
                            text: "Permission denied.",
                            type: "error",
                            icon: "error",
                            timer: 1500,
                        });
                    }
                })
        )
    }

    useEffect(() => {
        if (viewModule) {
            confromDialog()
        }
    }, [viewModule])

    const confromremoveDialog = () => {
        return (
            swal
                .fire({
                    title: "Are you sure?",
                    text: `Are you sure ? You won't be able to revert this!`,
                    icon: "warning",
                    type: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                })
                .then((result) => {
                    if (result.value) {
                        onDeleteForm()
                    } else {
                        setRemoveModule(false)
                        swal.fire({
                            title: "Cancelled!",
                            text: "Permission denied.",
                            type: "error",
                            icon: "error",
                            timer: 1500,
                        });
                    }
                })
        )
    }

    useEffect(() => {
        if (removeModule) {
            confromremoveDialog()
        }
    }, [removeModule])

    const onSelectStudentForm = () => {
        let temp_state = [...state.filterStudentEnrollment]
        temp_state.map(item => {
            item.status = true && item.statusId !== 1 && item.statusId !== 3;
        })
        setState({
            ...state,
            isCheck: false,
            filterStudentEnrollment: temp_state
        })
    }

    const onSelectStudent = (event) => {
        setShowForm(true)
    }

    const {
        submissionDate,
        employee_id = null,
        employeeId = null,
        typeId = null
    } = state;
    const modelTitle = editData ? "Edit " : "Create ";
    const modelButtonLbl = editData ? "" : "Save";
    const checkTitle = !checkData ? "class " : "student ";

    const handleSubjectTeachers = async (event, name) => {
        if (event?.employeeCode) {
            const req = {};
            req.empCode = event?.employeeCode || '';
            let enrollList = await getAsyncStudentClassList(req);
        
            setState({
                ...state,
                enrollmentDetailsList: enrollList.data,
                [name]: event.label,
                employee_id: event.value,
                // employeeCode: event.value,
            });
        } else {
            // Reset the relevant state fields
            setState({
                ...state,
                enrollmentDetailsList: [],
                [name]: '', // Clear the label if applicable
                employee_id: null, // Reset employee ID
                // employeeCode: null, // Uncomment if needed to reset employeeCode
            });
        }
        

    };

    const handleClick = (buttonName) => {
        if (buttonName == 'Add') {
            onCreateForm();
        }
    };

    return (
        <div>
            <div style={{ marginTop: "-10px" }}>
                {/* <View centered={true} size={'sm'} isOpen={removeModule} onClose={setRemoveModule} savetitle="Yes" onSubmit={onDeleteForm} title="Delete Exam Details" style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}>
                    <h6 className="text-capitalize mb-1">Are you sure ? You won't be able to revert this!</h6>
                </View> */}
                <View isOpen={createModule} size="fullscreen" savetitle={modelButtonLbl} onClose={setCreateModule} onSubmit={onCheck} title={`${modelTitle} Home Work`} >
                    {editData ? <FormLayout defaultState={state} onChangeCallback={{ "onSelectClass": onSelectClass, "onSelectBatch": onSelectBatch }} setDefaultState={setState} dynamicForm={homeWorkEditForm} noOfColumn={6} /* onSearch={onSelectStudent} */ />
                        : <FormLayout defaultState={state} onChangeCallback={{ "onSelectClass": onSelectClass, "onSelectBatch": onSelectBatch, "handleSubjectTeachers": handleSubjectTeachers }} setDefaultState={setState} dynamicForm={homeWorkForm} noOfColumn={6} /* onSearch={onSelectStudent} */ />}

                    <Button className="me-3" style={{ backgroundColor: lightTheme.primaryColor, border: "none", fontSize: "12px" }} onClick={() => addItem()}>
                        {"Add Item"}
                    </Button>
                    <Button className="me-3" style={{ backgroundColor: lightTheme.primaryColor, border: "none", fontSize: "12px" }} onClick={() => onSelectStudent()}>
                        {"Select Student"}
                    </Button>
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">Subject</th>
                                    <th scope="col">HomeWork Title</th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(homeWorkItemList ? homeWorkItemList : []).map((item, index) => {
                                    return (
                                        <TableLayout key={index} defaultState={state} setDefaultState={setHomeWorkItemList} index={index} layoutType="table" dataList={homeWorkItemList} dynamicForm={homeWorkDetailsForm} iconPress={{ "onRemove": onRemoveItem }} />
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </View>
                <View centered={true} size={'sm'} isOpen={deleteModule} onClose={setDeleteModule} savetitle="Yes" onSubmit={onSubmitForm} title="Delete Home Work">
                    <h6 className="text-capitalize mb-1">Are you sure ?</h6>
                </View>
                <View isOpen={showForm} size="lg" onClose={setShowForm} title={`Select Student`} onSubmit={confirmForm} /* onClose={onCloseForm} */ >
                    {/* <CustomAlignButton alignName={"start"} onClick={onSelectStudentForm} btnName={"Select All"}></CustomAlignButton> */}
                    <Button className="me-3" style={{ backgroundColor: lightTheme.primaryColor, border: "none", fontSize: "12px" }} onClick={() => onSelectStudentForm()}>
                        {"Select All"}
                    </Button>
                    <CustomTable columns={itemHeader} data={editData ? studentArr : (state?.filterStudentEnrollment || [])} tableName={""} state={state} />
                </View>

                <SimpleCard >
                    <CustomTable columns={column} buttonData={pageItems.create ? ['add'] : []} data={itemList} handleClick={handleClick} tableName={"Home Work"} state={state} />
                </SimpleCard>
            </div>
        </div>
    )
};

export default HomeWork;