
const bookForm = [
    {
        title: "Basic Information",
        formBody : [
            {
                formField: [
                    {
                        label: "Title",
                        inputType: "text",
                        placeholder: "Enter Title",
                        fieldName:"title",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Book Name",
                        inputType: "text",
                        placeholder: "Enter Book name",
                        fieldName:"bookName",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Author Name",
                        inputType: "text",
                        placeholder: "Enter Author Name",
                        fieldName:"authorName",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                                                              
                ]
            },
            {
                formField: [
                    {
                        label: "Edition",
                        inputType: "text",
                        placeholder: "Enter Edition",
                        fieldName:"edition",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    },
                    {
                        label: "Short Description",
                        inputType: "text",
                        placeholder: "Enter Short Description",
                        fieldName:"shortDescription",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Book Type",
                        inputType: "select",
                        options:'bookTypeList',
                        fieldName:"bookTypeId",
                        displayKey:"booksType",
                        uniqueKey:"bookTypeId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                 
                                                              
                ]
            },
            {
                formField: [
                   
                 
                    {
                        label: "Material Type",
                        inputType: "select",
                        options: "materialTypeList",
                        uniqueKey: "knowledgeMaterialTypeId",
                        onChange:"onSelectMaterialType",
                        displayKey: "knowledgeMaterialType",
                        fieldName: "knowledgeMaterialTypeId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                   
                    {
                        label: "Document",
                        inputType: "file",
                        fieldName: "imageName",
                        fileFormat : "application/pdf" ,
                        onChange:"onWriteDocument",
                        defaultValue: "",
                        disabled: false,
                        config: {
                            required: "",
                        },
                    },
                    {
                        label: "Url",
                        inputType: "text",
                        disabled: false,
                        fieldName: "url",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    },
                    /* {
                        label: "Material",
                        inputType: "text",
                        placeholder: "Enter Material",
                        fieldName:"material",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }, */
                    
                   
                    /* {
                        label: "Batch End",
                        inputType: "text",
                        type:'number',
                        placeholder: "YYYY",
                        fieldName:"batchEnd",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }
                        */                                              
                ]
            }
        ]
    }
];

export {
    bookForm
};