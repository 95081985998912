import { dateConversion, decimalAmount } from "app/utils/app-functions";

export const printInvoice = ({ row }) => {
  const data = row;
  const printWindow = window.open('', '_blank');

  // Generate table rows for purchase order items
  const itemRows = data?.purchaseOrderItem?.map(item => `
    <tr>
      <td>${item.materialName || "N/A"}</td>
      <td>${item.qty || 0}</td>
      <td>${decimalAmount(item.price) || 0}</td>
      <td>${item.unitName || ""}</td>
      <td>${decimalAmount(item.total) || ""}</td>
      <td>${item.taxVal || ""}%</td>
      <td>${decimalAmount(item.totalTax) || ""}</td>
    </tr>
  `).join('') || '<tr><td colspan="7">No items found.</td></tr>'; // Fallback if no items

  // Extract and display taxMeta details for all items
  const allTaxMeta = data?.purchaseOrderItem?.reduce((acc, item) => {
    if (item.taxMeta && item.taxMeta.length > 0) {
      acc.push(...item.taxMeta);
    }
    return acc;
  }, []);

  // Conditionally generate the tax breakdown table if allTaxMeta is not empty
  const taxMetaSection = allTaxMeta.length > 0 ? `
    <h3>Tax Breakdown</h3>
    <table class="table">
      <thead>
        <tr>
          <th>Tax Code</th>
          <th>Tax Name</th>
          <th>Tax Percentage</th>
        </tr>
      </thead>
      <tbody>
        ${allTaxMeta.map(tax => `
          <tr>
            <td>${tax.code}</td>
            <td>${tax.name}</td>
            <td>${tax.taxValue}%</td>
          </tr>
        `).join('')}
      </tbody>
    </table>
  ` : ''; // Empty string if no taxMeta

  printWindow.document.write(`
    <html>
      <head>
        <title>Purchase List</title>
        <style>
          body { font-family: 'Arial', sans-serif; }
          .invoice { padding: 20px; border: 1px solid #000; }
          h4 { color: #4A90E2; text-align: center; }
          span { color: #4A90E2;  }
          h2 { color: #333; }
          h3 { color: #E94E77; }
          table { 
            width: 100%; 
            border-collapse: collapse; 
            margin-top: 20px; 
          }
          th { 
            background-color: #007bff; 
            color: white; 
            padding: 10px; 
            border: 1px solid #dee2e6; 
          }
          td { 
            border: 1px solid #dee2e6; 
            padding: 8px; 
            text-align: left; 
          }
          tr:nth-child(even) { 
            background-color: #f8f9fa; 
          }
          tr:hover {
            background-color: #f1f1f1; 
          }
          .top-right {
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
            align-items: flex-end;
          }
           .bottom-right {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
          }
        </style>
      </head>
      <body>
        <div class="invoice">
          <h4>Purchase Order List</h4>
          <div class="top-right">
            <p><span>Purchase Date:</span> ${dateConversion(data?.purchaseOrderDate, "DD-MMM-YYYY")}</p>
            <p><span>Supplier Contact:</span> ${data?.primaryNo || ""}</p>
          </div>
        <p>Purchase Number:<span style="color: #4A90E2;">${data?.purchaseNumber || ""}</span></p>
        <p>Supplier Name:<span style="color: #4A90E2;">${data?.supplierName || ""}</span></p>
        <p>Supplier Email:<span style="color: #4A90E2;">${data?.supplierEmail || ""}</span></p>
        <p>Supplier Address:<span style="color: #4A90E2;">${data?.supplierAddress || ""}</span></p>
        
          
          <h2>Items</h2>
          <table class="table">
            <thead>
              <tr>
                <th>Material Name</th>
                <th>Quantity</th>
                <th>Price</th>
                <th>Unit Name</th>
                <th>Total Price</th>
                <th>Tax Percentage</th>
                <th>Total Tax</th>
              </tr>
            </thead>
            <tbody>
              ${itemRows}
            </tbody>
          </table>

          ${taxMetaSection}

          <div class="bottom-right">
          <h3>Subtotal Price: ₹${decimalAmount(data.totalAmount) || 0}</h3>
          <h3>Total Price: ₹${decimalAmount(data.amount) || 0}</h3>
          <h3>Total Tax Amount: ₹${decimalAmount(data.taxAmount) || 0}</h3>
        </div>
        </div>
      </body>
    </html>
  `);

  printWindow.document.close();
  printWindow.print();
  if (printWindow) {
    printWindow.close();
  }
};
