
import { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

const DataTable = (props) => {
  const { data, column } = props

  const [state, setState] = useState({
    itemList: [],
    sortableColumn: column ? column : [{
      dataField: "No Data",
      text: "Table",
      sort: true
    }],
  });

  useEffect(() => {
    if (data) {      
      setState({
        ...state,
        itemList: data
      })
    }
  }, [data]);

  let { itemList, sortableColumn } = state;
  return (
    <ToolkitProvider
      striped
      keyField="id"
      data={sortableColumn.length ? itemList : []}
      columns={sortableColumn}
      search={false}
      hover={false}
      condensed
      cellEdit={() => alert("")}
    >
      {(props) => (
        <>
          <BootstrapTable
            {...props.baseProps}
            bootstrap4
            noDataIndication={"Table is empty"}
            striped={true}
          />
        </>
      )}
    </ToolkitProvider>
  );
};

export default DataTable;
