import { returnApiCallPromise } from './ApiContent';
import { periodDays } from './APIContainer';

//GET
export function getPeriodDays(request) {
  const requestObj = request ? { params: request } : "";
  return (dispatch) => {
    returnApiCallPromise("GET", periodDays, requestObj)
      .then(response => {
        dispatch({
          type: 'GET_PERIOD_DAYS_SUCCESS',
          payload: response.data
        })
      }).catch(error => {
        
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'GET_PERIOD_DAYS_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

export function getAsyncPeriodDays(request) {
  const requestObj = request ? { params: request } : "";
  return (
    returnApiCallPromise("GET", periodDays, requestObj)
    .then(response => {
      const result = {
        getSubjectSuccess: true,
        data: response.data.data
      }

      return result;
    }).catch(error => {

      let errorMessage = error;
      if (error.response) {
        if (error.response.data.error) {
          errorMessage = error.response.data.message;
        }
      }

      const result = {
        getTaxFailed: true,
        errorMessage: errorMessage
      }

      return result;
    })
  )
}

//POST
export function createPeriodDays(request) {

  return (dispatch) => {
    returnApiCallPromise("POST",  periodDays, request)
      .then(response => {
        if (response.data.error) {
          dispatch({
            type: 'CREATE_PERIOD_DAYS_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'CREATE_PERIOD_DAYS_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'CREATE_PERIOD_DAYS_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

export function updatePeriodDays(request, periodDaysId) {
  return (dispatch) => {
    returnApiCallPromise("PUT",  periodDays+"/"+periodDaysId , request)
      .then(response => {

        if (response.data.error) {
          dispatch({
            type: 'UPDATE_PERIOD_DAYS_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'UPDATE_PERIOD_DAYS_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'UPDATE_PERIOD_DAYS_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}