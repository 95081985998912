import React from "react";
import { Tabs, Tab } from "react-tabs-scrollable";
import "react-tabs-scrollable/dist/rts.css";

const FormWizardBody = (props) => {

    return (
        <div className="overflow-auto" style={{ height: window.innerHeight - 200 }} >
            {props.children}
        </div>
    );
};

export default FormWizardBody;


