import React, { useState, useEffect } from 'react';
import { Grid, Box, Paper, Typography } from '@mui/material'; // Using MUI Grid and Paper components
import CustomTable from "app/components/atoms/custom-table/CustomTable";
import formRender from "app/components/render/formcomponent";
import { getEvent } from "app/api/EventApi";
import { useDispatch, useSelector } from 'react-redux';
import { lightTheme } from 'styles/theme';
import moment from 'moment';
import { event, imagePath } from "app/utils/constant";
import { useNavigate } from 'react-router-dom';
import FormLayout from 'app/components/render/formlayout';
import { asyncGetBatchInfo } from 'app/api/BatchApi';
import { asyncGetBestEmployeList } from 'app/api/EmployePerformanceApi';
import { getBatch } from "app/api/BatchApi";
import ProgressBar from "app/components/atoms/ProgressBar";
import Loading from "app/components/atoms/loading";

function AutoLayoutExample({ eventData, navigation }) {
    const dispatch = useDispatch();



    const itemsPerPage = 5; // Set the number of items per page to 5
    const [currentPage, setCurrentPage] = useState(1);
    const [state, setState] = useState({});
    const [itemList, setItemList] = useState([]);
    const [loaderModule, setLoaderModule] = useState(false);
    const [showEvents, setshowEvents] = useState(false);

    const [batchList, setBatchList] = useState([

    ]);

    const [selectedOption, setSelectedOption] = useState('');

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
        console.log('Selected Option:', event.target.value);
    };




    const column = [
        {
            "dataField": "sNo",
            "text": "S.No",
            "sort": true,
            formatter: (cellContent, row, index) => (
                index + 1
            )
        },
        {
            "dataField": "employeeName",
            "text": "Employee Name",
            "sort": true
        },
        {
            "dataField": "subjectName",
            "text": "Subject ",
            "sort": false
        },
        {
            dataField: "average",
            text: "Average",
            sort: false,
            formatter: (cellContent, row, index) => {
                return (
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                        <ProgressBar percent={row.average} status="normal" />
                        <span style={{ color: "#006400", }}>{row.average}%</span>
                    </div>
                );
            }
        }



    ];


    const listFetching = async () => {
        const currentYear = new Date().getFullYear(); // Get the current year dynamically
        const batchList = await asyncGetBatchInfo();
        const batchData = batchList?.data || [];
        console.log("hi bro")

        // Find batch where batchStart matches the current year
        const matchingBatch = batchData.find(batch => batch.batchStart === currentYear);
        const requestData = { batchId: matchingBatch?.batchId || "" };

        const employeList = await asyncGetBestEmployeList(requestData);
        setItemList(employeList?.data || [])
        console.log(matchingBatch)


    };



    useEffect(() => {
        setLoaderModule(true);

        // Dispatch actions and stop loader after 3 seconds
        const timer = setTimeout(() => {
            const requestData = { isActive: "true" };

            listFetching()
            setshowEvents(true)
            // Stop loader after dispatching actions
            setLoaderModule(false);
        }, 3000);

        // Cleanup function to clear the timeout on unmount
        return () => clearTimeout(timer);
    }, []);

    const eventImageStyle = {
        width: '60px',
        height: '60px',
        borderRadius: '50%',
        objectFit: 'cover',
        border: "0.5px solid `${lightTheme.primaryColor}`"
    };

    const eventCardStyle = {
        display: "flex",
        alignItems: "center",
        gap: "15px",
        padding: "15px",
        borderBottom: "1px solid #ddd",
        borderRadius: "8px",
        transition: "all 0.3s ease",
        '&:hover': {
            backgroundColor: "#f9f9f9",
            transform: "scale(1.02)",
        },
    };

    const getEventSuccess = useSelector((state) => state.eventReducer.getEventSuccess);
    const getEventList = useSelector((state) => state.eventReducer.getEventList);
    const getEventFailure = useSelector((state) => state.eventReducer.getEventFailure);

    useEffect(() => {
        const requestData = {
            isActive: "true",
            unitId: 1
        }
        dispatch(getEvent(requestData))
    }, []);

    useEffect(() => {
        if (getEventSuccess) {
            setState({
                ...state,
                eventList: getEventList
            })
            dispatch({ type: "RESET_GET_EVENT" })
        } else if (getEventFailure) {
            dispatch({ type: "RESET_GET_EVENT" })
        }
    }, [getEventSuccess, getEventFailure]);




    const columnStyle = {
        height: '500px', // Set a fixed height for both columns
        borderRadius: '8px', // Optionally, apply border radius for smooth corners
        backgroundColor: '#fff', // Set background color for columns
        padding: '2px', // Add padding to columns
        marginBottom: '10px',
        overflowY: 'auto',
        overflowX: 'hidden',
    };

    const containerStyle = {

    };

    const scrollbarStyle = {
        '::-webkit-scrollbar': {
            display: "hidden"

        },

    };

    const onHandleBatch = async (event) => {
        alert("hi")

    }

    // Paginate the data based on the current page
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = itemList.slice(indexOfFirstItem, indexOfLastItem);

    // Handle page change
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // Calculate total pages
    const totalPages = Math.ceil(itemList.length / itemsPerPage);
    const navigate = useNavigate();

    return (
        <Box sx={{ ...containerStyle }}>
            <Loading status={loaderModule} />

            <Grid container spacing={2}>
                {/* Column 1: Upcoming Events Card */}
                <Grid item xs={12} sm={4}>
                    <Paper sx={{ ...columnStyle, ...scrollbarStyle }}>
                        <Typography variant="h6" sx={{ marginBottom: '15px', color: lightTheme.primaryColor, textAlign: "center" }}>Upcoming Events</Typography>
                        {eventData.map((events, index) => (
                            <Box key={index} sx={eventCardStyle} onClick={() => navigate(`/event`)}>
                                {console.log(`${imagePath}${event}/${encodeURI(events.image)}`)}
                                <Typography variant="body1" >{index + 1}.</Typography>
                                <Box>
                                    <Typography variant="subtitle1" color={lightTheme.primaryColor} fontWeight="bold">
                                        {events.title}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Event Date: {moment(events.eventDate).format('MMMM Do, YYYY')}
                                    </Typography>
                                </Box>
                                {showEvents &&
                                    <img src={`${imagePath}${event}/${encodeURI(events.image)}`}
                                        style={eventImageStyle}
                                    />}
                            </Box>
                        ))}

                    </Paper>
                </Grid>

                {/* Column 2: Teacher Details Table */}
                <Grid item xs={12} sm={8}>
                    <Paper sx={{ ...columnStyle, ...scrollbarStyle }}>


                        <CustomTable tableName={"Best Performer Employe's Details"} columns={column} data={itemList} initialPage={1} />
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
}

export default AutoLayoutExample;
