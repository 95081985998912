import React, { useState, useEffect } from "react";
import { BsPencilSquare } from "react-icons/bs";
import _ from "lodash";
import { firstLetterCaptial, showMessage } from "app/utils/app-functions";
import View from "./atoms/modalView/Modalview";
// import SimpleCard from "./cards/SimpleCard";
import SimpleCard from "./atoms/simple-card/SimpleCard";
import Alert from "./atoms/alert";
import { Button } from "react-bootstrap";
import { lightTheme } from "styles/theme";
import swal from "sweetalert2";

const TableCustomeCellEditor = (props) => {
  const { heading = [], columns = [], data = [], defaultState, examDetails = [], state, onSubmit, titleName, forms, subjectList, isDaily = false, permissionData } = props;

  const [editEnableModule, setEditEnableModule] = useState(defaultState.isValidated ? defaultState.isValidated : false);
  const [tempState, setTempState] = useState(false);
  const [viewModule, setViewModule] = useState(false);
  const [states, setState] = useState({
    markList: [],
    addingMarkList: [],
    totalMark: 0
  });
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [subIndex, setSubIndex] = useState(null);
  const [currentMarks, setCurrentMarks] = useState([]);
  const [removeModule, setRemoveModule] = useState(false);


  const confromremoveDialog = () => {
    return (
      swal
        .fire({
          title: "Verify!",
          text: `Verify that you have entered all the information!`,
          icon: "warning",
          type: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        })
        .then((result) => {
          if (result.value) {
            onSubmit();
            setEditEnableModule(false);
            setRemoveModule(false)
          } else {
            setRemoveModule(false)
            setEditEnableModule(false);

            swal.fire({
              title: "Cancelled!",
              text: "Permission denied.",
              type: "error",
              icon: "error",
              timer: 1500,
            });
          }
        })
    )
  }

  useEffect(() => {
    if (removeModule) {
      confromremoveDialog()
    }
  }, [removeModule])

  const onCreateForm = () => {
    let subjectTempArr = [];
    columns.map((ele) => {
      return ele.subjectName != null ? subjectTempArr.push(ele.subjectName) : "";
    });
    setTempState({
      ...tempState,
      subjectTempArr: subjectTempArr,
    });
    if (editEnableModule === true || defaultState.isValidated) {
      setRemoveModule(true)
    } else {
      setEditEnableModule(true);
    }
  };

  const calculateTotal = (marks) => {
    let total = 0;
    marks.forEach((mark) => {
      total += isNaN(parseInt(mark)) ? 0 : parseInt(mark);
    });
    return total;
  };

  const handleChange = (event, index, subjectId, name) => {

    let subjectIdx = _.findIndex(examDetails, (e) => {
      return e.subjectId == subjectId;
    });
    if (/[^\w\s]/.test(event.target.value)) {
      showMessage("warning", "Invalid Input Type...!");
      Alert({ description: 'Invalid Input Type...!', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });

    } else {
      const formatStr = (str) => {
        return str ? str.toLowerCase().replace(/\s+/g, '') : ''; // Check if str is not null before calling toLowerCase
      };
      if (examDetails[subjectIdx].examMark >= Number(event.target.value)) {
        let result = "Pass";
        let temp_state = [...data];

        temp_state[index][event.target.name] = event.target.value;
        let allMarks = [];

        tempState.subjectTempArr.forEach((subjectName) => {
          let name = subjectName.replace(/\s+/g, '');
          allMarks.push(temp_state[index][subjectName] || "0");

          let subjectMarks = temp_state[index][`${(subjectName)}Marks`] || [];

          subjectMarks.forEach(mark => {
            allMarks.push(mark.value);
          });
        });


        let total = 0
        if (event.target.name == 'mark') {
          total = event.target.value
          if (total < examDetails[subjectIdx].minimumMark) {
            result = "Fail";
          }
        }
        else {
          total = calculateTotal(allMarks);

          tempState.subjectTempArr.forEach((element) => {
            if (temp_state[index][element] !== "") {
              let mark = parseInt(temp_state[index][element]);
              let newIndex = _.findIndex(examDetails, (e) => {
                return formatStr(e.subjectTitle) == element;
              });

              if (newIndex >=0 && Number(mark) < Number(examDetails[newIndex].minimumMark)) {
                result = "Fail";
              }
            }
          });
          if (total === 0) {
            result = "";
          }
        }

        temp_state[index].total = total;
        temp_state[index].subjectId = subjectId;
        temp_state[index].result = result;
        console.log(temp_state);

        defaultState(temp_state);
      } else {
        Alert({ description: 'Please Enter the Mark Below (or) Equal to Maximum Mark', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });

      }
    }
  };

  const modelTitle = editEnableModule ? "Submit " : "Edit ";
  if (!Array.isArray(columns)) {
    console.error("Columns is not an array:", columns);
    return null;
  }

  const onInternal = (index, field, subIndex) => {
    setSelectedIndex(index);
    setSubIndex(subIndex);
    const subjectMarks = data[index][`${field}Marks`];
    let isAssigned = false;

    examDetails.map(item => {
      // alert((item.subjectTitle.replace(/\s+/g, '')).toLowerCase())
      if (((item.subjectTitle.replace(/\s+/g, '')).toLowerCase() === field.toLowerCase()) &&
        ((item.internalMark != 0 || item.practicalMark > 0) && (item.practicalMark != 0 || item.internalMark > 0))) {
        isAssigned = true;
      }
    });
    if (subjectMarks != undefined && isAssigned) {
      setViewModule(true);
      setCurrentMarks(subjectMarks ? subjectMarks : []);
    } else {
      showMessage("warning", "Internal and Practical is not Assigned...!");
      Alert({ description: 'Internal and Practical is not Assigned...!', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });

    }
  };

  const handleModalChange = (event, markType, name) => {
    if (/[^\w\s]/.test(event.target.value)) {
      showMessage("warning", "Invalid Input Type...!");
      Alert({ description: 'Invalid Input Type...!', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });

    } else {
      let check = false;

      examDetails.map(ele => {
        if (name == ele.subjectTitle) {
          if (ele[`${markType}`] >= event.target.value) {
            check = true;
          }
        }
      });
      if (check) {

        const updatedMarks = currentMarks.map((mark) =>
          mark.dataField === markType ? { ...mark, value: event.target.value } : mark
        );

        setCurrentMarks(updatedMarks);
      } else {
        showMessage("warning", "Please Enter the Mark Below (or) Equal to Maximum Mark");
        Alert({ description: 'Please Enter the Mark Below (or) Equal to Maximum Mark', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });

      }
    }
  };

  const onSubmitForm = () => {
    const updatedData = [...data];
    const subjectName = columns[subIndex].dataField;
    let subjectMarks = currentMarks;

    updatedData[selectedIndex][`${(subjectName)}Marks`] = subjectMarks;

    let allMarks = [];
    tempState.subjectTempArr.forEach((subjectName) => {
      allMarks.push(updatedData[selectedIndex][subjectName] || "0");
      let marks = updatedData[selectedIndex][`${(subjectName)}Marks`] || [];
      marks.forEach(mark => {
        allMarks.push(mark.value);
      });
    });

    let total = calculateTotal(allMarks);
    updatedData[selectedIndex].total = total;

    defaultState(updatedData);
    setViewModule(false);
  };

  return (
    <>
      <View size={'sm'} isOpen={viewModule} onClose={setViewModule} saveTitle="Yes" onSubmit={onSubmitForm} title="Marks">
        <div>
          {currentMarks.map((mark) => (
            <div key={mark.dataField}>
              <label>{mark.dataField == "internalMark" ? "Internal Mark" : "Practical Mark"}</label>
              <input
                type="text"
                id={mark.dataField}
                name={mark.dataField}
                className="form-control"
                placeholder="Enter Mark"
                value={mark.value}
                onChange={(event) => handleModalChange(event, mark.dataField, mark.text)}
                disabled={!mark.editable}
              />
            </div>
          ))}
        </div>
      </View>

      <SimpleCard  >
        <div className="card-description" style={{ color: lightTheme.primaryColor }}>{titleName}</div>
        {permissionData.create &&
          <Button onClick={onCreateForm} className='mr-2' style={{ display: "flex", float: "right", backgroundColor: lightTheme.primaryColor, border: "none", fontSize: "12px", marginTop: "-40px" }}>{modelTitle}</Button>
        }

        <div className="table-responsive">
          <table id="user_table" className="table table-bordered text-center">
            <thead>
              <tr>
                {columns.map((ele) => {
                  return <th key={ele.dataField} scope="col">{ele.text}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {!editEnableModule &&
                data.map((item, index) => (
                  <tr key={index}>
                    {columns.map((ele, idx) => (
                      idx === 0 ? (
                        <th key={ele.dataField} scope="col">{item[ele.dataField]}</th>
                      ) : (
                        <td key={ele.dataField} scope="col">{item[ele.dataField]}</td>
                      )
                    ))}
                  </tr>
                ))}
              {editEnableModule &&
                data.map((item, index) => (
                  <tr key={index}>
                    {columns.map((ele, idx) => {
                      if (idx === 0) {
                        return <th key={ele.dataField} className="text-nowrap" scope="col">{item[ele.dataField]}</th>;
                      } else {
                        const valueField = data[index][ele.dataField];
                        const subjectId = ele?.subjectId || "";
                        if (ele.dataField === "result") {
                          const colour = data[index][ele.dataField] === "" ? "" : data[index][ele.dataField] !== "Pass" ? "red" : "green";
                          return <th key={ele.dataField} style={{ backgroundColor: colour }}><span style={{ padding: "3px", paddingRight: "5px", paddingLeft: "5px", color: "white" }}>{data[index][ele.dataField]}</span></th>;
                        } else {
                          return (
                            <th key={ele.dataField} scope="col">
                              <div style={{ display: "flex", alignItems: "center" }}>
                                <input
                                  id={ele.dataField}
                                  name={ele.dataField}
                                  className="form-control"
                                  placeholder="Enter Mark"
                                  type="text"
                                  onChange={(event) => handleChange(event, index, subjectId, ele.dataField)}
                                  disabled={ele.editable === false || item.statusId == 1 || item.statusId == 3}
                                  value={valueField}
                                  style={{ marginRight: "8px" }}
                                />
                                {ele.dataField !== "total" && !isDaily && <BsPencilSquare size={20} className="text-danger cursor-pointer" onClick={() => onInternal(index, ele.dataField, idx)} />}
                              </div>
                            </th>
                          );
                        }
                      }
                    })}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </SimpleCard>
    </>
  );
};

export default TableCustomeCellEditor;