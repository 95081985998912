const supplierForm = [{

    title: "Basic Information",
    formBody: [
        {
            formField: [

                {
                    label: 'Primary Phone',
                    inputType: "text",
                    placeholder: 'Primary Phone',
                    fieldName: "primaryNumber",
                    defaultValue: "",
                    config: {
                        required: "required",
                    },
                },
                {
                    label: 'Email',
                    inputType: "email",
                    placeholder: 'Email',
                    uniqueKey: "Supplier Code",
                    displayKey: "Primary Phone",
                    fieldName: "email",
                    defaultValue: "",
                    config: {
                        required: "required",
                    },
                },
                {   
                    label: 'address',
                    inputType: "textarea",
                    placeholder: 'address',
                    fieldName: "address",
                    defaultValue: "",
                    config: {
                        required: "required",
                    },
                },
                {   
                    label: 'Primary Phone',
                    inputType: "text",
                    type: "number",
                    placeholder: 'Postcode',
                    uniqueKey: "Supplier Code",
                    displayKey: "Primary Phone",
                    fieldName: "postCode",
                    defaultValue: "",
                    config: {
                        required: "required",
                    },
                },

            ]
        },
        {
            formField: [
                {
                    label: 'Supplier Name',
                    inputType: "text",
                    placeholder: 'Supplier Name',
                    fieldName: "supplierName",
                    defaultValue: "",
                    config: {
                        required: "required",
                    },
                },
                {
                    label: 'Secondary Phone',
                    inputType: "text",
                    placeholder: 'Secondary Phone',
                    uniqueKey: "Supplier Code",
                    displayKey: "Primary Phone",
                    fieldName: "secondaryNumber",
                    defaultValue: "",
                    config: {
                        required: "required",
                    },
                },
                {
                    label: 'Contact  Person',
                    inputType: "text",
                    placeholder: 'Contact  Person',
                    fieldName: "contactName",
                    defaultValue: "",
                    config: {
                        required: "required",
                    },
                },
                {
                    label: 'Comment',
                    inputType: "textarea",
                    placeholder: 'Comment',
                    fieldName: "comment",
                    defaultValue: "",
                    config: {
                        required: "required",
                    },
                },
            ]
        },

    ]
}]

export { supplierForm }
