

const enquiryForm = [
  {
      title: "Basic Information",
      formBody: [
          {
              formField: [
                  {
                      label: "Enquiry Type",
                      inputType: "select",
                      options: "enquiryTypeList",
                      uniqueKey:"enquiryTypeId",
                      displayKey: "enquiryTypeName",
                      onChange:"onSelectEnquiryId",
                      placeholder: "Select Enquiry Type",
                      fieldName: "enquiryTypeId",
                      defaultValue: "",
                      config: {
                          required: "required",
                      },
                  },
                 
                  {
                      label: "Title",
                      inputType: "text",
                      placeholder: "Enter Title",
                      fieldName: "title",
                      defaultValue: "",
                      config: {
                          required: "required",
                      },
                  },
                 
                 
                  /* {
                      label: "Url",
                      inputType: "text",
                      disable: false,
                      fieldName: "url",
                      defaultValue: "",
                      config: {
                          required: "",
                      },
                  },
                  {
                      label: "Name",
                      inputType: "text",
                      fieldName: "name",
                      defaultValue: "",
                      config: {
                          required: "",
                      },
                  }, */
              ]
          },
          {
              formField: [
                {
                    label: "Description",
                    inputType: "text",
                    placeholder: "Enter Description",
                    fieldName: "description",
                    defaultValue: "",
                    config: {
                        required: "required",
                    },
                },
                {
                    label: "Phone Number",
                    inputType: "phonenumber",
                   /*  options: "materialTypeList",
                    uniqueKey: "knowledgeMaterialTypeId",
                    onChange:"onSelectMaterialType",
                    displayKey: "knowledgeMaterialType", */
                    placeholder: "Enter phonenumber",
                    fieldName: "phoneNumber",
                    defaultValue: "",
                    config: {
                        required: "required",
                    },
                },
              ]
          },

      ]
  }
];

export {
  enquiryForm
};