// import { options } from "@fullcalendar/core/preact";

import { Description } from "@mui/icons-material";

const purchaseForm = [
    {
        title: "Basic Information",
        formBody: [

            {
                formField: [
                    {
                        label: "From Date",
                        inputType: "date",
                        fieldName: "fromDate",
                        // onChange:"onSelectAttendanceDate",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }
                ]
            },
            {
                formField: [
                    {
                        label: "To Date",
                        inputType: "date",
                        fieldName: "toDate",
                        // onChange:"onSelectAttendanceDate",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }
                ]
            },
            {
                formField: [
                    {
                        label: "Purchase Number",
                        inputType: "text",
                        placeholder: 'Purchase Number',
                        uniqueKey: "departmentId",
                        displayKey: "departmentName",
                        multiple: false,
                        fieldName: "purchaseNumber",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    }
                ]
            },
            {
                formField: [
                    {
                        label: "Select Supplier",
                        inputType: "select",
                        placeholder: 'Select Supplier',
                        options: "qualificationId",
                        uniqueKey: "departmentId",
                        displayKey: "departmentName",
                        multiple: false,
                        fieldName: "selectSupplier",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }
                ]
            },
            {
                formField: [
                    {
                        label: "Submit",
                        inputType: "button",
                        fieldName: "submit",
                        className: "mt-4",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    }
                ]
            },
        ]
    }
];

const purchaseAddForm = [
    {
        title: "Basic Information",
        formBody: [
            {
                formField: [
                    {
                        placeholder: 'Supplier',
                        inputType: "typedropdown",
                        options: "supplierList",
                        onChange: "handleSupplierChange",
                        uniqueKey: "supplierId",
                        displayKey: "supplierName",
                        icon: "i-Add",
                        fieldName: "supplierId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {

                        placeholder: 'Supplier Invoice',
                        inputType: "text",
                        fieldName: "inVoiceData",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {

                        placeholder: 'Supplier Contact',
                        inputType: "text",
                        fieldName: "contactNumber",
                        // onChange:"onSelectAttendanceDate",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {

                        placeholder: 'Contact Person',
                        inputType: "text",
                        fieldName: "contactPerson",
                        // onChange:"onSelectAttendanceDate",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {

                        placeholder: 'Supplier Address',
                        inputType: "textarea",
                        fieldName: "supplierAddress",
                        // onChange:"onSelectAttendanceDate",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },

                ]
            },
            {
                formField: [
                    {

                        inputType: "text",
                        placeholder: "Name",
                        fieldName: "name",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {

                        placeholder: 'Delivery Phone',
                        inputType: "text",
                        type:"number",
                        uniqueKey: "id",
                        displayKey: "number",
                        // type:'number',
                        fieldName: "phoneNumber",
                        // onChange:"onSelectAttendanceDate",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {

                        placeholder: 'Delivery Email',
                        inputType: "text",
                        fieldName: "emailId",
                        // onChange:"onSelectAttendanceDate",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {

                        placeholder: 'Delivery Contact Person',
                        inputType: "text",
                        fieldName: "contactPersonName",

                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {

                        placeholder: 'Delivery Address',
                        inputType: "textarea",
                        fieldName: "deliveryAddress",

                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },

                ]
            },
            {
                formField: [
                    {

                        placeholder: 'Permit Number',
                        inputType: "text",
                        fieldName: "permitId",
                        // onChange:"onSelectAttendanceDate",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {

                        placeholder: 'Ship Via',
                        inputType: "text",
                        fieldName: "shipVia",
                        // onChange:"onSelectAttendanceDate",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {

                        placeholder: 'FOB',
                        inputType: "text",
                        fieldName: "fob",
                        // onChange:"onSelectAttendanceDate",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {

                        placeholder: 'Debit Term',
                        inputType: "text",
                        fieldName: "debitTerm",
                        // onChange:"onSelectAttendanceDate",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {

                        placeholder: 'Memo',
                        inputType: "textarea",
                        fieldName: "memo",
                        // onChange:"onSelectAttendanceDate",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },

                ]
            },
            {
                formField: [
                    {

                        placeholder: 'Purchase Order Date',
                        inputType: "date",
                        fieldName: "purchaseOrderDate",
                        // onChange:"onSelectAttendanceDate",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {

                        placeholder: 'Delivery Date',
                        inputType: "date",
                        fieldName: "deliveryOrderDate",
                        // onChange:"onSelectAttendanceDate",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    
                    {

                        description: 'Price Including Gst',
                        inputType: "checkbox",
                        fieldName: "priceGst",
                        onChange: "sameAsCustomer",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    },
                    {

                        placeholder: 'Approved By',
                        inputType: "text",
                        fieldName: "approvedBy",
                        // onChange:"onSelectAttendanceDate",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    },
                    {
                        placeholder: 'Select Country',
                        inputType: "select",
                        options: "currencyList",
                        onChange: "handleCurrencyChange",
                        uniqueKey: "currencyId",
                        displayKey: "countryName",
                        fieldName: "countryName",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },

                ]
            },






        ]
    }
];



const purchasingForm = [
    {
        title: "Basic Information",
        formBody: [

            {
                formField: [
                    {

                        placeholder: 'Insurance',
                        inputType: "text",
                        uniqueKey: "currencyId",

                        fieldName: "insuranceAmount",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    },


                ]
            },
            {
                formField: [

                    {

                        placeholder: 'Logistic',
                        inputType: "text",
                        uniqueKey: "currencyId",
                        fieldName: "logisticAmount",
                        // onChange:"onSelectAttendanceDate",
                        defaultValue: "",
                        config: {
                            required: "r",
                        },
                    },

                ]
            },
            {
                formField: [

                    {

                        placeholder: 'Handling Cost',
                        inputType: "text",
                        uniqueKey: "currencyId",
                        fieldName: "handlingCost",
                        // onChange:"onSelectAttendanceDate",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    },

                ]
            },
            {
                formField: [

                    {

                        placeholder: 'Other Cost',
                        inputType: "text",

                        fieldName: "otherCost",
                        // onChange:"onSelectAttendanceDate",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    },


                ]
            },






        ]
    }
];


const innerClickForm = [{

    title: "Basic Information",
    formBody: [
        {
            formField: [
                {
                    inputType: "text",
                    placeholder: 'Supplier Code',
                    uniqueKey: "departmentId",
                    displayKey: "departmentName",
                    fieldName: "supplierCode",
                    defaultValue: "",
                    config: {
                        required: "",
                    },
                },
                {
                    inputType: "text",
                    type:"number",
                    placeholder: 'Primary Phone',
                    uniqueKey: "Supplier Code",
                    displayKey: "Primary Phone",
                    fieldName: "primaryNumber",
                    defaultValue: "",
                    config: {
                        required: "",
                    },
                },
                {
                    inputType: "text",
                    placeholder: 'Email',
                    uniqueKey: "Supplier Code",
                    displayKey: "Primary Phone",
                    fieldName: "email",
                    defaultValue: "",
                    config: {
                        required: "",
                    },
                },
                {
                    inputType: "textarea",
                    placeholder: 'address',
                    uniqueKey: "Supplier Code",
                    displayKey: "Primary Phone",
                    fieldName: "address",
                    defaultValue: "",
                    config: {
                        required: "",
                    },
                },
                {
                    inputType: "text",
                    type: "number",
                    placeholder: 'Postcode',
                    uniqueKey: "Supplier Code",
                    displayKey: "Primary Phone",
                    fieldName: "postCode",
                    defaultValue: "",
                    config: {
                        required: "",
                    },
                },

            ]
        },
        {
            formField: [
                {
                    inputType: "text",
                    placeholder: 'Supplier Name',
                    uniqueKey: "Supplier Code",
                    displayKey: "Primary Phone",
                    fieldName: "suplierNameValue",
                    defaultValue: "",
                    config: {
                        required: "",
                    },
                },
                {
                    inputType: "text",
                    type: "number",
                    placeholder: 'Secondary Phone',
                    uniqueKey: "Supplier Code",
                    displayKey: "Primary Phone",
                    fieldName: "secondaryPhone",
                    defaultValue: "",
                    config: {
                        required: "",
                    },
                },
                {
                    inputType: "text",
                    placeholder: 'Contact  Person',
                    uniqueKey: "Supplier Code",
                    displayKey: "Primary Phone",
                    fieldName: "contact",
                    defaultValue: "",
                    config: {
                        required: "",
                    },
                },
                {
                    inputType: "textarea",
                    placeholder: 'Comment',
                    uniqueKey: "Supplier Code",
                    displayKey: "Primary Phone",
                    fieldName: "comment",
                    defaultValue: "",
                    config: {
                        required: "",
                    },
                },
            ]
        },

    ]
}]


const inner = [
    {
        placeholder: 'Warehouse',
        inputType: "select",
        options: "warehouseList",
        uniqueKey: "id",
        displayKey: "warehouseName",
        fieldName: "warehouseId",
        onChange: "handleTypeChange",
        defaultValue: "",
        config: {
            required: "required",
        },
    },
    {
        placeholder: 'Location',
        inputType: "select",
        options: "locationList",
        uniqueKey: "id",
        displayKey: "name",
        fieldName: "locationId",
        onChange: "handleTypeChange",
        defaultValue: "",
        config: {
            required: "required",
        },
    },
    {
        label: "",
        inputType: "text",
        placeholder: "Length",
        fieldName: "length",
        onChange: "handleChange",
        defaultValue: "",
        config: {
            required: "",
        },
    },
    {
        label: "",
        inputType: "text",
        placeholder: "Width",
        fieldName: "width",
        onChange: "handleChange",
        defaultValue: "",
        config: {
            required: "",
        },
    },
    {
        label: "",
        inputType: "text",
        placeholder: "Thickness",
        fieldName: "thickness",
        onChange: "handleChange",
        defaultValue: "",
        config: {
            required: "",
        },
    },
    {
        label: "",
        inputType: "icon-table",
        index: 'delete',
        fieldName: "deleteInner",
        onClick: "onDeleteInner",
        defaultValue: "",
        config: {
            required: "required",
        },
    },
]
const subInner = [
    
    {
        label: "",
        inputType: "text",
        placeholder: "Length",
        fieldName: "length",
        onChange: "handlewarehousechange",
        defaultValue: "",
        config: {
            required: "",
        },
    },
    {
        label: "",
        inputType: "text",
        placeholder: "Width",
        fieldName: "width",
        onChange: "handlewarehousechange",
        defaultValue: "",
        config: {
            required: "",
        },
    },
    {
        label: "",
        inputType: "text",
        placeholder: "Thickness",
        fieldName: "thickness",
        onChange: "handlewarehousechange",
        defaultValue: "",
        config: {
            required: "",
        },
    },
    {
        placeholder: 'Warehouse',
        inputType: "select",
        options: "warehouseList",
        uniqueKey: "id",
        // onRight: "20px",
        onChange: "handlewarehousechange",
        displayKey: "warehouseName",
        fieldName: "warehouseId",
        defaultValue: "",
        config: {
            required: "required",
        },
    },
    {
        placeholder: 'Location',
        inputType: "select",
        options: "locationList",
        onChange: "handlelocationchange",
        uniqueKey: "id",
        displayKey: "name",
        fieldName: "locationId",
        defaultValue: "",
        config: {
            required: "required",
        },
        
    },
    {
        label: "Apply to all",
        inputType: "button",

        fieldName: "applyButton",
        onClick: "onSubmitItemDetails",
        defaultValue: "",
        config: {
            required: "required",
        },
    },




]


const fieldList = [
    {
        label: "",
        inputType: "select",
        // placeholder: "Material Type",
        options: "materialTypeList",
        onChange: "getMaterialId",
        uniqueKey: "materialTypeId",
        displayKey: "materialName",
        fieldName: "materialTypeId",
        disabled: true,

        defaultValue: "",
        config: {
            required: "",
        },
    },
    {
        label: "",
        inputType: "searchableInput",
        options: "materialCodeList",
        placeholder: "materialCodeId",
        fieldName: "materialCodeId",
        onChange:"getMaterialName",
        displayKey: "materialCode",
        defaultValue: "",
        config: {
            required: "",
        },
    },

    {
        label: "",
        inputType: "text",
        placeholder: "MaterialName",
        fieldName: "materialName",
        disabled: true,

        defaultValue: "",
        config: {
            required: "",
        },
    },
    {
        label: "",
        inputType: "text",
        type:"number",
        placeholder: "Qty*",
        onChange: "onPriceChange",
        fieldName: "qty",
        defaultValue: "",
        config: {
            required: "required",
        },
    },
    {
        placeholder: 'Unit',
        inputType: "select",
        options: "unitsList",
        // onChange: "handleUnitsChange",
        uniqueKey: "unitId",
        displayKey: "unitName",

        fieldName: "unit",
        defaultValue: "",
        config: {
            required: "",
        },
    },

    {
        label: "",
        inputType: "text",
        type: "number",
        placeholder: "0",
        fieldName: "price",
        onChange: "onPriceChange",
        defaultValue: "",
        config: {
            required: "",
        },
    },

    {
        label: "",
        inputType: "text",
        uniqueKey: "poMaterialId",

        placeholder: "Amount",
        fieldName: "total",
        disabled: true,
        defaultValue: "",
        config: {
            required: "",
        },
    },
    {
        label: "",
        options: "taxCodeList",
        uniqueKey: "taxId",
        onChange: "handletaxChange",
        displayKey: "taxCode",
        inputType: "select",
        placeholder: "Tax code",
        fieldName: "taxCode",
        defaultValue: "",
        config: {
            required: "",
        },
    },
    {
        label: "",
        inputType: "text",
        placeholder: "Tax",
        uniqueKey: "taxId",
        disabled: true,

        fieldName: "totalTax",
        defaultValue: "",
        config: {
            required: "",
        },
    },

    {
        label: "",
        inputType: "icon-table",
        index: 'add',
        fieldName: "outline",
        onClick: "onAddItem",
        defaultValue: "",
        config: {
            required: "",
        },
    },
    {
        label: "",
        inputType: "icon-table",
        index: 'delete',
        fieldName: "delete",
        onRight: "40px",
        onClick: "onDeletePress",
        defaultValue: "",
        config: {
            required: "",
        },
    },
]






export {
    purchasingForm,
    purchaseAddForm,
    inner,
    innerClickForm,
    fieldList,
    subInner,
};